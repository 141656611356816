<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/test/test-log' }"
            >检测记录</el-breadcrumb-item
          >
          <el-breadcrumb-item>发票查询</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">发票查询</template>
      </main-header>
    </div>
    <div class="filter-body">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="form-item">
            <div class="label">企业名称:</div>
            <el-input
              v-model="filter.companyName"
              placeholder="请输入"
              clearable
              @input="searchGetAudiList"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">查询时间：</div>
            <el-date-picker
              v-model="filter.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="searchGetAudiList"
            ></el-date-picker>
          </div>
          <div class="form-item">
            <div class="label">发票类型:</div>
            <el-select
              v-model="filter.invoiceClass"
              placeholder="请选择"
              filterable
              @change="searchGetAudiList"
            >
              <el-option
                v-for="item in invoiceClassList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="form-item">
            <div class="label">发票种类:</div>
            <el-select
              v-model="filter.invoiceType"
              clearable
              placeholder="请选择"
              filterable
              @change="searchGetAudiList"
            >
              <el-option
                v-for="item in invoiceTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="form-item">
            <div class="label">发票状态:</div>
            <el-select
              v-model="filter.invoiceStatus"
              clearable
              placeholder="请选择"
              filterable
              @change="searchGetAudiList"
            >
              <el-option
                v-for="item in invoiceStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="btn-look-up ease">搜索</div>
    </div>

    <div class="content-body">
      <template>
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column
            prop="billingDate"
            label="开票日期"
            sortable
            :show-overflow-tooltip="true"
            min-width="12%"
          ></el-table-column>
          <el-table-column
            prop="invoiceCode"
            label="发票代码"
            :show-overflow-tooltip="true"
            min-width="14%"
          ></el-table-column>
          <el-table-column
            prop="invoiceNumber"
            label="发票号码"
            :show-overflow-tooltip="true"
            min-width="14%"
          ></el-table-column>
          <el-table-column
            prop="companyName"
            :label="filter.invoiceClass == 0 ? '供应商企业名称' : '客户企业名称'"
            :show-overflow-tooltip="true"
            min-width="28%"
          ></el-table-column>
          <el-table-column
            prop="invoiceType"
            label="发票种类"
            :show-overflow-tooltip="true"
            min-width="10%"
          >
          </el-table-column>
          <el-table-column
            prop="invoiceStatus"
            label="发票状态"
            :show-overflow-tooltip="true"
            min-width="8%"
          >
          </el-table-column>
        </el-table>
      </template>
    </div>

    <el-pagination
      class="pagination-low"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :current-page="pageno"
      layout="prev, pager, next, total, jumper, sizes"
      :total="totalNumber"
      :page-sizes="[10, 20, 50, 100]"
      :haveTotal="haveTotal"
    ></el-pagination>
    <create-custom ref="bill" @supplement="supplement" :uuid="uuid"></create-custom>
  </div>
</template>

<script>
import {
  getCustomListNew,
  auditList,
  getexportInvoiceURL,
  getexportAnalysisResults,
  ptInvoiceBasicInfoList,
  ptInvoiceBasicInfoDelete,
  ptInvoiceBasicInfoFindInvoice,
  ptInvoiceBasicInfoExpire,
  getUUIDAi,
  getPageOfInvoice,
} from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import createCustom from "@/pages/Test/UploadTable/components/autoMethod";
// import taxNo from "./components/addTaxno";

export default {
  components: {
    MainHeader,
    BreadCrumb,
    createCustom,
  },

  data() {
    return {
      statusListArr: ["待生成", "已生成", "生成出错", "已删除"],
      invoiceClassList: [
        {
          value: "0",
          label: "进项",
        },
        {
          value: "1",
          label: "销项",
        },
      ],
      invoiceTypeList: [
        {
          value: "1",
          label: "专用",
        },
        {
          value: "2",
          label: "普通",
        },
        {
          value: "3",
          label: "其他",
        },
      ],
      invoiceStatusList: [
        {
          value: "0",
          label: "正常",
        },
        {
          value: "1",
          label: "作废",
        },
        {
          value: "2",
          label: "红冲",
        },
        {
          value: "3",
          label: "失控",
        },
        {
          value: "4",
          label: "异常",
        },
      ],
      haveTotal: true,
      totalNumber: 10, //总数
      totalElements: 0,
      pageno: 1,
      filter: {
        companyName: "",
        projectManager: "",
        taxNo: "",
        invoiceClass: "0",
        invoiceType: "",
        invoiceStatus: "",
        time: "",
      },
      tableDataList: null,
      taxBox: false,
      pageSize: 10,
      uuid: "",
    };
  },

  mounted() {
    // this.getAuditList();
    this.getBillList();
    this.getuuid();
  },

  methods: {
    getuuid() {
      getUUIDAi().then((res) => {
        this.uuid = res.data.uuid;
      });
    },
    supplement(val) {
      // console.log(val);
      this.$router.push({
        path: "/tools/billGetData",
        query: { info: JSON.stringify(val.newInfo), uuid: this.uuid },
      });
    },
    deleteBill(id) {
      this.$confirm("删除后将无法恢复，是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          ptInvoiceBasicInfoDelete(id).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.getBillList();
            }
          });
        })
        .catch(() => {});
    },
    getBillList() {
      let params = {
        invoiceClass: this.filter.invoiceClass,
        invoiceStatus: this.filter.invoiceStatus,
        invoiceType: this.filter.invoiceType,
        taxNo: this.$route.query.taxNo,
        companyName: this.filter.companyName,
        pageNum: this.pageno,
        pageSize: this.pageSize,
        startDate: this.filter.time ? this.filter.time[0] : "",
        endDate: this.filter.time ? this.filter.time[1] : "",
      };
      getPageOfInvoice(params).then((res) => {
        this.tableDataList = res.list;
        this.totalNumber = Number(res.pageTotal);
      });
    },
    getTableDetail(val) {
      //   let taxNo = code.code;
      ptInvoiceBasicInfoExpire(val.id).then((res) => {
        if (!res.data) {
          this.$router.push({
            path: "/tools/billDetail",
            query: {
              taxNo: val.taxNo,
              companyName: val.companyName,
              createTime: val.updateTime,
            },
          });
        } else {
          this.$confirm(
            "为了保证数据安全，系统对发票数据设置了一定的查看期限，当前已超过可查看期限，请重新查询。",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              showCancelButton: false,
            }
          )
            .then(() => {})
            .catch(() => {});
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getBillList();
    },
    addBox(code) {
      let taxNo = code.code;
      this.$router.push({ path: "/tools/authorization", query: { taxNo } });
    },
    closeBox() {
      this.taxBox = false;
    },
    analysis(programId) {
      this.$router.push({ path: "/tools/analysisResult", query: { programId } });
    },
    addTest() {
      ptInvoiceBasicInfoFindInvoice().then((res) => {
        if (res.data) {
          this.$refs.bill.dialogTableVisible = true;
        } else {
          this.$confirm("发票查询为增值服务，如需使用请联系销售人员开通。", "温馨提示", {
            confirmButtonText: "关闭",
            type: "warning",
            showCancelButton: false,
          })
            .then(() => {})
            .catch(() => {});
        }
      });
    },
    search() {},
    // 翻页
    handleCurrentChange(val) {
      this.pageno = val;
      this.getBillList();
    },
    searchGetAudiList() {
      this.pageno = 1;
      this.getBillList();
    },
    // // 获取客户列表
    // getAuditList() {
    //   let params = {
    //     companyName: this.filter.companyName,
    //     projectManager: this.filter.projectManager,
    //     showStatus: this.filter.showStatus,
    //     taxNo: this.filter.taxNo,
    //     pageNum: this.pageno,
    //     pageSize: this.pageSize,
    //   };
    //   auditList(params).then((res) => {
    //     this.tableDataList = res.list;
    //     this.totalNumber = Number(res.pageTotal);
    //   });
    // },
  },
};
</script>

<style lang="stylus" scoped>
.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

.filter-body >>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.filter-body {
  margin: 20px;
  padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .inputs-wrapper {
    width: 92%;

    .inputs-wraper-row {
      display: flex;
      flex-wrap: wrap;

      .form-item {
        margin-right: 30px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        width: auto;

        .label {
          white-space: nowrap;
          width: 69px;
          text-align: right;
          margin-right: 8px;
        }

        .labelCode {
          width: 100px;
        }

        display: flex;
      }

      .form-itemCode {
        width: 336px;
      }
    }
  }

  .btn-look-up {
    margin-right: 16px;
    width: 76px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #2F54EB;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.addItem {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 20px;
  cursor: pointer;
  display: inline-block;
  margin-right: 16px;
}

.addItem:hover {
  background: rgba(47, 34, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.filter-body >>> .form-item .el-input__icon {
  line-height: 36px;
  height: 36px;
}

.el-icon-edit-outline {
  color: #2f54eb;
  font-size: 18px;
  margin-left: 4px;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.pagination-low {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}

.filter-body >>>.el-select {
  width: 240px;
}

.filter-body >>>.el-input {
  width: 240px;
}

.filter-body >>>.el-range-editor.el-input__inner {
  width: 240px;
}

.filter-body >>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

.filter-body >>>.el-pagination__editor.el-input {
  width: 50px;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.filter-body >>>.el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

.filter-body >>>.el-input__icon {
  width: 14px;
}

.filter-body >>>.el-range-editor.el-input__inner {
  padding: 3px 8px;
}

.filter-body >>>.el-date-editor .el-range-separator {
  line-height: 29px;
}

.filter-body >>>.form-item .el-input__icon {
  line-height: 38px;
}

.export {
  cursor: pointer;
  color: #2f22eb;
  margin-right: 8px;
}

.export-delete {
  color: rgba(0,0,0,0.6000);
}

.bill-set {
  margin-right: 4px;
}
</style>
