<template>
  <div class="deleteProject">
    <div class="delete-title">废除项目</div>
    <el-input type="textarea" :rows="1" placeholder="请输入备注" v-model="textarea" autosize></el-input>
    <div class="delete-btn">
      <div class="cancel-btn btns" @click="cancel">取消</div>
      <div class="confirm-btn btns" @click="confirm">废除</div>
    </div>
  </div>
</template>

<script>
import { closeProject } from "@/api/api";

export default {
  props: {
    id: [String, Number],
  },
  data() {
    return {
      textarea: "",
    };
  },

  methods: {
    cancel() {
      this.$emit("cancels");
    },

    confirm() {
      const loading = this.$loading({
        lock: true,
        text: "正在废弃项目",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        projectId: this.id,
        remark: this.textarea,
      };
      closeProject(params).then((res) => {
        loading.close();
        if (res.msgCode == 1) {
          this.$notify({
            title: "成功",
            message: "项目废除成功！",
            type: "success",
          });
          this.$emit("comfirms");
        } else {
          this.$message.error(res.msgInfo);
        }
      });
    },
  },
};
</script>

<style scoped>
.deleteProject {
  width: 400px;
  /* height: 158px; */
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding-bottom: 16px;
  text-align: left;
}

.delete-title {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 22px;
  padding: 16px 0 0 24px;
}

.deleteProject >>> .el-textarea__inner {
  width: 88%;
  margin-left: 24px;
  margin-top: 16px;
}

.delete-btn {
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
  margin-top: 16px;
}

.btns {
  width: 76px;
  height: 36px;
  line-height: 34px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(47, 84, 235, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
}

.confirm-btn {
  margin-left: 16px;
  background: rgba(47, 84, 235, 1);
  color: #fff;
}
</style>