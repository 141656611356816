<template>
  <div>
    <bread-crumb>
      <el-breadcrumb-item :to="{ name: 'goods' }">产品中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'order' }">我的订单</el-breadcrumb-item>
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </bread-crumb>
    <div class="submit-title">订单详情</div>
    <div class="submit-infos" :class="{ispay:(status==4||status==5)}">
      <div class="info-text info-text1">
        订单状态：{{orderList&&orderStatus[status]}}
        <span
          v-if="orderList&&orderList.payChannel&&status!=1&&status!=2"
        >（{{orderList&&orderMethods[orderList.payChannel]}}）</span>
        <span
          class="addgrays"
          v-if="status==1||status==2"
        >（请在 {{formatDateTes(orderList&&orderList.lastPaymentTime)}}前完成支付，以免订单超时取消）</span>
      </div>
      <div class="flex-bettew">
        <div class="info-text2">订单编号：{{orderList&&orderList.ordernumber}}</div>
        <div class="info-discount info-discount1">
          优惠：
          <span class="addred">{{Number(priceDiscount).toFixed(2)}}</span>元
        </div>
      </div>
      <div class="flex-bettew">
        <div
          class="info-text2"
          :class="{ispays:(status==4||status==5)}"
        >创建时间：{{formatDateTes(orderList&&orderList.createTime)}}</div>
        <div class="info-text info-discount">
          付款金额：
          <span class="addred">{{orderList&&Number(orderList.totalAmount).toFixed(2)}}</span>元
        </div>
      </div>
      <div v-if="status==5||status==4">支付时间：{{formatDateTes(orderList&&orderList.updateTime)}}</div>
    </div>
    <div class="submit-table" :class="{submittables:status==1 || status==2}">
      <div class="submit-t">服务订购</div>
      <el-table :data="goodList" style="width: 100%">
        <el-table-column width="14"></el-table-column>
        <el-table-column min-width="200px" prop="goodName" label="行业" style="margin-left: 40px">
          <template slot-scope="scope">
            <img class="title-icon" v-if="scope.row.face_img" :src="scope.row.face_img" />
            <span class="title-left" style="margin-left: 16px">{{ scope.row.goodName }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="200px" prop="companyName" label="服务类型">
          <template slot-scope="scope">
            <span>
              <span class="addred">{{Number(scope.row.price).toFixed(2)}}/年</span>
              （{{scope.row.useNumber>10000? "无限": scope.row.useNumber}}次）
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column min-width="200px" prop="payment_method" label="优惠方式"></el-table-column> -->
        <el-table-column min-width="200px" prop="price" label="应付金额" width="140px">
          <template slot-scope="scope">
            <span>
              <span class="addred">{{Number(scope.row.price).toFixed(2)}}元</span>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="info-bottom" v-if="status==2">
        <div class="info-dis">
          订单优惠：
          <span class="addgray" v-if="discountMethod==0">满{{satisfy}}减{{price}}</span>
          <span class="addgray" v-if="discountMethod==1">满{{satisfy}}打{{discounts}}折</span>
          <span class="addgray" v-if="discountMethod==2">无使用优惠</span>
        </div>
        <div class="info-price">
          优惠金额：
          <span class="addred">{{Number(priceDiscount).toFixed(2)}}元</span>
        </div>
        <div class="info-total">
          应付总计：
          <span class="addred">{{orderList&&Number(orderList.totalAmount).toFixed(2)}}元</span>
        </div>
      </div>
    </div>
    <div class="submit-price" v-if="status==1 || status==2">
      <div class="submit-left">
        <!-- <div class="submit-check" @click="isActive = !isActive">
          <div :class="{ 'submit-circle': isActive }"></div>
        </div>
        <div class="submit-text">我已阅读并同意《购买协议》</div>-->
      </div>
      <div class="submit-right">
        <div class="submit-cancel" @click="cancelOrder">取消订单</div>
        <div class="submit-btn ease" @click="toPay">立即支付</div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import { viewOrder, cancelOrder } from "../../api/api";
export default {
  components: {
    BreadCrumb
  },
  data() {
    return {
      status: this.$route.query.status,
      isActive: true,
      id: this.$route.query.id,
      orderList: null,
      goodList: null,
      orderStatus: ["已取消", "待支付", "待支付", "已支付", "已支付", "已支付"],
      orderMethods: {
        alipay_pc_direct: "支付宝",
        alipay_qr: "支付宝",
        wx_pub_qr: "微信支付"
      },
      orderId: null,
      priceDiscount: 0,
      discountMethod: 0,
      satisfy: 0,
      price: 0,
      discounts: 0
    };
  },
  created() {
    this.vieworder();
  },
  methods: {
    vieworder() {
      let params = {
        orderId: this.id
      };
      viewOrder(params).then(res => {
        this.orderList = res.data;
        this.orderId = res.data.id;
        this.goodList = res.data.buyGoodsList;
        let priceList = res.data.buyGoodsList.map(item => {
          return item.price;
        });
        this.priceDiscount = this.sum(priceList) - res.data.totalAmount;
        if (res.data.useDiscount && res.data.useDiscount.discount != 1) {
          this.discountMethod = 1;
          this.satisfy = res.data.useDiscount.satisfy;
          this.discounts = Number(res.data.useDiscount.discount) * 10;
        } else if (res.data.useDiscount && res.data.useDiscount.discount == 1) {
          this.discountMethod = 0;
          this.satisfy = res.data.useDiscount.satisfy;
          this.price = res.data.useDiscount.price;
        } else if (!res.data.useDiscount) {
          this.discountMethod = 2;
        }
      });
    },
    // 去支付
    toPay() {
      localStorage.setItem("totalPrice", this.orderList.totalAmount);
      this.$router.push({
        path: "/product/pay",
        query: {
          id: this.orderId,
          num: this.orderList.ordernumber
        }
      });
    },
    // 取消订单
    cancelorder(id) {
      let params = {
        orderId: id
      };
      cancelOrder(params).then(res => {
        if (res.msgCode == 1) {
          this.$router.push({
            path: "/product/record"
          });
          this.$message({
            type: "success",
            message: "取消订单成功!"
          });
        } else {
          this.$message({
            type: "info",
            message: "取消订单失败!"
          });
        }
      });
    },
    // 取消订单
    cancelOrder(val) {
      this.$confirm("是否取消该订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "返回",
        type: "warning"
      })
        .then(() => {
          this.cancelorder(this.orderId);
        })
        .catch(() => {});
    },
    formatDateTes(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + " " + h + m + s;
    },
    sum(arr) {
      return eval(arr.join("+"));
    }
  }
};
</script>
<style lang="stylus" scoped>
.submit-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.8);
  padding: 8px 0 0 30px;
  font-weight: 500;
}

.submit-infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // min-width: 1100px;
  height: 129px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  margin: 24px 30px 0 30px;
  padding-left: 23px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
}

.info-text {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
}

.info-text1 {
  line-height: 1;
  margin-bottom: 12px;
}

.info-text2 {
  line-height: 1;
  margin-bottom: 12px;
}

.info-discount {
  width: 210px;
  margin-right: 6px;
}

.info-discount1 {
  margin-right: 5px;
}

.flex-bettew {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
  line-height: 1.8;
}

.submit-table {
  position: relative;
  // min-width: 1100px;
  margin: 16px 30px 30px 30px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  background: #fff;
  overflow: hidden;
  border-radius: 8px;
}

.title-icon {
  width: 28px;
  height: 28px;
}

>>>.cell {
  display: flex;
  align-items: center;
}

.submit-t {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  padding: 24px 0 25px 24px;
}

.submit-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 180px;
  height: 80px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  font-size: 14px;
}

.submit-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.submit-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.submit-check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border: 1px solid rgba(116, 96, 238, 1);
  border-radius: 50%;
  cursor: pointer;
  margin-left: 30px;
  margin-right: 8px;
}

.submit-circle {
  width: 8px;
  height: 8px;
  background: rgba(116, 96, 238, 1);
  border-radius: 50%;
}

.submit-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.submit-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.addred {
  color: red;
}

.info-dis {
  position: absolute;
  right: 440px;
  bottom: 68px;
}

.info-price {
  width: 160px;
  position: absolute;
  right: 46px;
  bottom: 68px;
}

.info-bottom {
  height: 112px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
}

.info-total {
  width: 160px;
  position: absolute;
  right: 46px;
  bottom: 30px;
}

.addgray {
  color: rgba(0, 0, 0, 0.6);
}

.addgrays {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
}

.submit-text {
  color: rgba(0, 0, 0, 0.6);
}

.submit-btn {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  margin-right: 30px;
  margin-left: 20px;
}

.submit-btn:hover {
  background: rgba(98, 97, 242, 1);
}

.submit-cancel {
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.submittables {
  margin-bottom: 110px;
}

.ispay {
  height: 157px;
}

.ispays {
  margin-bottom: 12px;
}
</style>