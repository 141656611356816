<template>
  <div>
    <div class="z-header">
      <el-breadcrumb separator="/" class="z-breadcrumb">
        <el-breadcrumb-item>指标模型</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/editor/menuData' }"
          >元数据管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>添加元数据</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="z-title">添加元数据</div>
    </div>
    <div class="z-detail">
      <div class="z-detail-title">元数据信息</div>
      <div class="z-detail-content">
        <div class="z-detail-left">
          <div class="z-detail-input">
            <div class="z-input-name">元数据表：</div>
            <el-select
              v-model="metadata"
              placeholder="请选择"
              filterable
              @change="metadataChange"
            >
              <el-option
                v-for="item in metadataList"
                :key="item.tableNameEn"
                :label="item.tableNameCn"
                :value="item.tableNameEn"
              >
              </el-option>
            </el-select>
          </div>
          <div class="z-detail-input">
            <div class="z-input-name">表字段：</div>
            <el-select v-model="tableFields" placeholder="请选择" filterable>
              <el-option
                v-for="item in fieldList"
                :key="item.mergeColumnEn"
                :label="item.mergeColumnCn"
                :value="item.mergeColumnEn"
              >
              </el-option>
            </el-select>
          </div>
          <div class="z-detail-input">
            <div class="z-input-name">类型：</div>
            <div>数值型</div>
            <!-- <el-select v-model="metaType" placeholder="请选择">
              <el-option
                v-for="item in typeData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
          </div>
        </div>
        <div class="z-detail-left">
          <div class="z-detail-input">
            <div class="z-input-name">名称：</div>
            <el-input
              v-model.trim="metaName"
              placeholder="请输入内容"
              maxlength="40"
              show-word-limit
            ></el-input>
          </div>
          <div class="z-detail-input z-detail-cascader">
            <div class="z-input-name">目录：</div>
            <el-cascader
              :options="getTreeList"
              :props="{ checkStrictly: true, expandTrigger: 'hover' }"
              clearable
              v-model="metaMenu"
              ref="refHandle"
            ></el-cascader>
            <div class="z-addMenu" @click="dialogTableVisible = true">
              +新增
            </div>
          </div>
          <div class="z-detail-inputF">
            <div class="z-input-name">描述：</div>
            <el-input
              type="textarea"
              :rows="2"
              v-model.trim="short"
              placeholder="请输入内容"
              maxlength="100"
              show-word-limit
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="z-editorDeital-footer">
      <div
        class="z-editorDeital-editBtn ease btn-right"
        @click="addMetadataBtn"
      >
        添加元数据
      </div>
      <div class="z-editorDeital-cancelBtn ease btn-right" @click="back">
        取消
      </div>
    </div>
    <!-- 新增元数据 -->
    <transition name="fades">
      <div
        class="containerMask"
        v-if="dialogTableVisible"
        @click="dialogTableVisible = false"
      ></div>
    </transition>
    <transition name="fadee">
      <add-menu
        v-if="dialogTableVisible"
        @closeAddBox="closeAddBox"
        @addRoleSuccess="addRoleSuccess"
        @addSuccess="addSuccess"
        @deleteType="deleteType"
        @getType="getType"
        :menuList="menuList"
        :addMenuType="0"
      ></add-menu>
    </transition>
  </div>
</template>

<script>
import addMenu from "@/pages/metadata/addmenu";
import { getMenu, getAllMeta, getMetaDetail, addMetaData } from "@/api/editor";
import {
  typeList,
  typeData,
  metaType,
  menuData,
  transformation,
  checkMetaItem,
} from "@/utils/constant";

export default {
  components: {
    addMenu,
  },
  data() {
    return {
      metadataList: null,
      metadata: "",
      fieldList: null,
      tableFields: "",
      metaType: "",
      metaName: "",
      metaMenu: "",
      getTreeList: null,
      short: "",

      dialogTableVisible: false,
      name: "",
      input: "",
      type: "",
      menu: "",
      menuList: null,
      typeData: typeData,
    };
  },
  mounted() {
    this.getMenuList();
    this.getAllMetas();
    // 获取目录树
    this.getTree();
  },
  methods: {
    // 新增元数据
    addMetadataBtn() {
      if (!this.checkItem(this)) return;
      let params = {
        catalog: this.metaMenu.join(","),
        catalogId: this.metaMenu[this.metaMenu.length - 1], //目录ID
        createUserId: JSON.parse(localStorage.getItem("user")).id,
        createUserName: JSON.parse(localStorage.getItem("user")).realName,
        dataType: "0", //数据类型
        entityType: "PF.YSJ",
        metaDescription: this.short, //描述
        metaName: this.metaName, //名称
        metaTableKey: this.metadata, //元数据表名
        metaTableFieldKey: this.tableFields, //表字段
      };
      addMetaData(params).then((res) => {
        if (res.msgCode == 1) {
          this.$message({
            message: "添加元数据成功！",
            type: "success",
          });
          setTimeout(() => {
            this.$router.go(-1);
          }, 500);
        } else {
          this.$message.error(res.msgInfo);
        }
      });
    },
    getTree() {
      let params = {
        catalogType: "PF.YSJ",
        catalogSelectUse: false,
      };
      getMenu(params).then((res) => {
        if (res.msgCode == 1) {
          this.getTreeList = res.data;
          if (!this.getTreeList || this.getTreeList.length < 1) return;
          this.getTreeList = transformation(this.getTreeList);
        }
      });
    },
    metadataChange() {
      this.tableFields = "";
      this.getMetaDetails(this.metadata);
    },
    // 通过表获取表字段
    getMetaDetails(id) {
      let params = {
        tableNameEn: id,
      };
      getMetaDetail(params).then((res) => {
        if (res.code == 200) {
          this.fieldList = res.data;
        }
      });
    },
    // 获取所有表格
    getAllMetas() {
      getAllMeta().then((res) => {
        if (res.code == 200) {
          this.metadataList = res.data;
        }
      });
    },
    deleteType() {
      this.menuList = null;
    },
    getType(type) {
      this.getMenus(type);
    },
    getMenus(type) {
      let params = {
        catalogType: type,
        catalogSelectUse: true,
      };
      getMenu(params).then((res) => {
        if (res.msgCode == 1) {
          this.menuList = res.data;
        }
      });
    },
    addSuccess() {
      this.getTree();
      this.dialogTableVisible = false;
    },
    getMenuList() {
      let params = {
        catalogType: "PF.YSJ",
        catalogSelectUse: true,
      };
      getMenu(params).then((res) => {
        if (res.msgCode == 1) {
          this.menuList = res.data;
        }
      });
    },
    closeAddBox() {
      this.dialogTableVisible = false;
    },
    addRoleSuccess() {
      this.dialogTableVisible = false;
    },
    back() {
      this.$router.go(-1);
    },
    checkItem(item) {
      return checkMetaItem(item);
    },
  },
  watch: {
    metaMenu() {
      if (this.$refs.refHandle) {
        this.$refs.refHandle.dropDownVisible = false; //监听值发生变化就关闭它
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.z-detail {
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 16px 30px 0 30px;
}

.z-detail-content {
  padding: 8px 0 24px 16px;
  margin-top: 16px;
}

.z-detail-title {
  height: 54px;
  line-height: 54px;
  padding-left: 24px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #eeeeee;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.z-detail-left {
  vertical-align: top;
  display: inline-block;
  width: 50%;
}

.z-detail-input {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 16px;
}

.z-input-name {
  width: 80px;
  text-align: right;
}

.z-addMenu {
  font-size: 14px;
  color: #2F54EB;
  margin-left: 8px;
  cursor: pointer;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-input__icon {
  line-height: 36px;
}

>>>.z-detail-cascader .el-input__icon {
  line-height: 40px;
}

>>>.el-select {
  width: 400px;
}

>>>.el-input {
  width: 400px;
}

>>>.el-textarea {
  width: 400px;
}

.z-addMenu {
  white-space: nowrap;
}

.z-detail-inputF {
  display: flex;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 16px;
}
</style>