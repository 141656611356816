<template>
  <div>
    <div id="chartColumns" class="chart"></div>
  </div>
</template>

<script>
import echarts from "echarts";
export default {
  data() {
    return {};
  },
  mounted() {
    // this.pieChartData();
  },
  methods: {
    pieChartData() {
      var chartDom = document.getElementById("chartColumns");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        xAxis: {
          type: "category",
          data: ["Mon", "Tue"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [120, 200],
            type: "bar",
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="stylus" scoped>
#chartColumns {
  width: 300px;
  height: 200px;
}
</style>
