<template>
  <div>
    <div class="wrapper">
      <div class="btns-wrapper">
        <!-- <AwaitButton
          :icon="true"
          @click="handleBtnStatusChange"
          data-status-to="1019"
          ><i class="iconfont iconNuclear"></i> 一键还原(测试用)</AwaitButton
        > -->
        <AwaitButton
          :icon="true"
          @click="handleBtnDownloadWithoutCensor"
          data-status-to="1026"
          >下载</AwaitButton
        >

        <AwaitButton
          :icon="true"
          @click="handleBtnReloadClick"
          data-status-to="1020"
          id="btn-reload"
          >刷新</AwaitButton
        >

        <AwaitButton
          :icon="true"
          @click="handleBtnSaveClick"
          data-status-to="1020"
          id="btn-save"
          >保存</AwaitButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, cloneDeep } from "lodash";
import { saveAs } from "file-saver";
import { mapState, mapActions, mapMutations } from "vuex";
import { updatePdfCatalogData, createPdf } from "@/api/shiwusuo";
import AwaitButton from "await-button";
export default {
  components: {
    AwaitButton,
  },
  mounted() {
    // setTimeout(() => {
    //  this.changeStatus(1019);
    // }, 2000);
  },
  data: function () {
    return {
      activeTab: 0,
    };
  },
  methods: {
    ...mapActions("ResultEditor", [
      "updateReviewStatus",
      "updateUnReviewStatus",
      "refresh",
    ]),
    ...mapMutations("ResultEditor", ["setIsLoading"]),
    //无需审核下载PDF
    async dowloadPdf(e) {
      //下载PDF
      const a = document.createElement("a");
      a.href = this.downloadUrl;
      a.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      // const loading = this.$loading({
      //   lock: true,
      //   text: "PDF下载中,请稍后",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      //
      // const resData = await createPdf({
      //   nsrsbh: this.$route.params.nsrsbh||this.$route.query.nsrsbh,
      //   uuid: this.$route.params.uuid,
      //   isSimple: 1,
      // });

      // if (resData.msgCode == "1") {
      //   //saveAs(resData.data, "税务预警风险检测报告(新)");
      //
      // } else {
      //   this.$alert(res.msgInfo, "提示", {
      //     type: "error",
      //   });
      // }
      // loading.close();
    },
    async handleBtnDownloadWithoutCensor(e) {
      //审核通过则无法修改报告内容,是否确定通过

      // try {
      //   await this.$confirm(
      //     "无需审核下载将会越过审核流程,是否确定下载",
      //     "提示",
      //     {
      //       confirmButtonText: "确定",
      //       cancelButtonText: "取消",
      //       type: "warning",
      //     }
      //   );
      //更新审核状态

      // const status = e.target.dataset.statusTo;
      // const resData = await this.changeUnReviewStatus(status);
      // //debugger
      // if (resData.msgCode == 1) {
      //   await this.dowloadPdf(e);
      // } else {
      //   this.$alert(resData.msgInfo);
      // }
      await this.dowloadPdf(e);
      // } catch (error) {
      //   if (error != "cancel") {
      //     throw error;
      //   }
      // }
    },

    async handleBtnReloadClick(e) {
      console.log("reload");
      let data = this.convertTreeToList();

      data.push(this.overall);

      //更新审核状态
      const status = e.target.dataset.statusTo;
      await this.changeStatus(status);

      this.reload();
    },
    async handleBtnSaveClick(e) {
      this.setIsLoading(true);
      let data = this.convertTreeToList();

      data.push(this.overall);

      try {
        const resData = await updatePdfCatalogData(
          data,
          this.$route.params.action
        );

        if (resData.msgCode == "1") {
          //更新审核状态
          const status = e.target.dataset.statusTo;
          await this.changeStatus(status);

          // this.$alert("保存成功", "提示", {
          //   type: "success",
          // }).finally(() => {
          //   this.reload();
          //   //location.reload();
          // });

          this.reload();
          this.setIsLoading(false);
        } else {
          this.$alert(resData.msgInfo, "提示", {
            type: "error",
          });
        }
      } catch (error) {
        throw error;
      }
    },
    async handleBtnStatusChange(e) {
      const status = e.target.dataset.statusTo;
      await this.changeStatus(status);
    },
    async handleSensorPassChange(e) {
      try {
        await this.$confirm("审核通过则无法修改报告内容,是否确定通过", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        await this.handleBtnStatusChange(e);
      } catch (error) {
        if (error != "cancel") {
          throw error;
        }
      }
    },
    //无需审核更改状态
    async changeUnReviewStatus(status) {
      const action = this.$route.params.action;
      if (!status) {
        alert("error");
      }
      const resData = await this.updateUnReviewStatus({
        nsrsbh: this.$route.params.nsrsbh||this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
        status: status,
        action: action,
        isSimple: 1,
      });

      return resData;
    },
    async changeStatus(status) {
      const action = this.$route.params.action;
      if (!status) {
        alert("error");
      }
      await this.updateReviewStatus({
        nsrsbh: this.$route.params.nsrsbh||this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
        status: status,
        action: action,
        isSimple: 1,
      });
    },
    convertTreeToList() {
      let ret = [];

      function convert(list) {
        list.forEach((item) => {
          ret.push(item);
          if (item.children && item.children.length > 0) {
            convert(item.children);
          }
          delete item.children;
        });
      }
      convert(cloneDeep(this.treeData));
      //convert(cloneDeep(this.discountPolicyTree));
      ret = cloneDeep(ret).map((v) => {
        if (v.id < 0) {
          v.id = null;
        }
        return v;
      });

      return ret;
    },
    /*重新加载数据*/
    reload: async function () {
      await this.refresh({
        uuid: this.$route.params.uuid,
        nsrsbh: this.$route.params.nsrsbh||this.$route.query.nsrsbh,
        action: this.$route.params.action,
        isSimple: 1,
      });
    },
    switchTab(id) {
      this.activeTab = id;
      this.$emit("switchTab", id);
    },
  },

  computed: {
    ...mapState("ResultEditor", [
      "treeData",
      "overall",
      "isDataModified",
      "ReviewStatus",
      "discountPolicyTree",
    ]),
    action() {
      return this.$route.params.action;
    },

    isContentModified() {
      return this.isDataModified;
    },
  },
};
</script>

<style lang="stylus" scoped>
.tabs {
  display: flex;

  .tab {
    width: 180px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
  }

  .tab-home {
    width: 30px;
    font-size: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active {
    color: rgba(47, 84, 235, 1);
    border-bottom: 4px solid rgba(47, 84, 235, 1);
    box-sizing: border-box;
  }
}

.wrapper {
  z-index: 9;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  height: 56px;

  .btns-wrapper {
    padding-right: 30px;
    display: flex;

    .btn {
      height: 32px;
      font-weight: bold;
      border-radius: 16px;
      background: #2F54EB;
      padding: 0px 24px;
      font-size: 14px;
      line-height: 1.5;
      color: #fff;
      margin: 0 8px;
    }

    .btn.disabled {
      background: rgba(217, 217, 217, 1);
      cursor: not-allowed;
    }
  }
}

#btn-reload, #btn-save {
  display: none;
}
</style>
