<template>
  <div class="section-box">
    <div class="title">
      企业信息
      <div class="btnsInTitle">
        <!-- <el-button type="text" class="code_btn" @click="checkCompanyClick">
          <i class="el-icon-refresh"></i>切换公司
        </el-button>

        <el-button type="text" class="before" @click="addCompany">
          <i class="el-icon-plus"></i>新增企业
        </el-button>-->
      </div>
    </div>
    <div class="usercard">
      <!-- <div class="box">
        <div class="qyname">用户ID：</div>
        <div class="name">{{FormInfo.id}}</div>
      </div>-->

      <div class="box">
        <div class="qyname">当前角色：</div>
        <div class="name">
          <img class="name-logo" v-if="userStatus==1" src="@/assets/test/usericon.png" alt />
          <img class="name-logo" v-if="userStatus==2" src="@/assets/test/financeicon.png" alt />
          <span class="font-600 name-right" v-if="userStatus==1">企业主</span>
          <span class="font-600 name-right" v-if="userStatus==2">财务人员</span>
          <el-button type="text" class="code_btn" @click="checkParts=true">
            <i class="el-icon-refresh"></i>切换角色
          </el-button>
        </div>
      </div>

      <div class="box-flex">
        <div class="box">
          <div class="qyname field">企业名称：</div>
          <div class="name">
            <span class="name-right">{{FormInfo.companyName}}</span>
            <el-button type="text" class="code_btn" @click="checkCompanyClick">
              <i class="el-icon-refresh"></i>切换公司
            </el-button>

            <el-button type="text" class="before" @click="addCompany">
              <i class="el-icon-plus"></i>新增企业
            </el-button>
          </div>
        </div>

        <div class="box box1">
          <div class="qyname field">所属地区：</div>
          <div class="name">
            <el-cascader :options="city" v-model="filters.dq" @change="handleDqChange"></el-cascader>
          </div>
        </div>
      </div>

      <div class="box-flex" v-if="fatherData==true">
        <div class="box box1">
          <div class="qyname">会计制度：</div>
          <div class="name">
            <el-select v-model="filters.kjzd" placeholder="请选择" @change="handleChangeKJZD">
              <el-option
                v-for="item in kjzd"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="box">
          <div class="qyname">从业人数平均值：</div>
          <div class="qytotal">
            <el-input placeholder="请输入从业人数平均值" v-model="filters.average" @change="changeAverage">
              <template slot="append">个</template>
            </el-input>
          </div>
        </div>
      </div>

      <div class="box-flex" v-if="fatherData==true">
        <div class="box box1">
          <div class="qyname">所属1级行业：</div>
          <div class="name">
            <el-select
              v-model="filters.hy1"
              placeholder="请选择"
              @change="handleIndustry1SelectChange"
            >
              <el-option
                v-for="item in hyList1"
                :key="item.hyDm"
                :label="item.hyMc"
                :value="item.hyDm"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="box">
          <div class="qyname">资产总额平均值：</div>
          <div class="qytotal">
            <el-input
              maxlength="9"
              placeholder="请输入资产总额平均值"
              v-model="filters.all"
              @change="changeAll"
            >
              <template slot="append">万元</template>
            </el-input>
          </div>
        </div>
      </div>
      <div class="box box1" v-if="fatherData==true">
        <div class="qyname">所属2级行业：</div>
        <div class="name">
          <el-select
            v-model="filters.hy"
            placeholder="请选择"
            filterable
            @change="handleIndustry2SelectChange"
          >
            <el-option
              v-for="item in morehylist"
              :key="item.hyDm"
              :label="item.dmMc"
              :value="item.hyDm"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="box-radio" v-if="fatherData==true">
        <div>
          <div class="box box-auto">
            <div class="qyname">企业类型：</div>
            <div class="name">
              <span>高新技术企业：</span>
              <el-radio-group v-model="filters.com1" @change="changeComa">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </div>
          </div>

          <div class="box box-auto">
            <div class="qyname"></div>
            <div class="name">
              <span>小型微利企业：</span>
              <el-radio-group v-model="filters.com2" @change="changeComb">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>

        <div>
          <div class="box box-auto">
            <div class="qyname qynamelong">存在外资投资股东：</div>
            <div class="name qynameposi">
              <el-radio-group v-model="filters.com3" @change="changeComc">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="box box-auto">
            <div class="qyname qynamelong">从事国家限制或禁止行业：</div>
            <div class="name qynameposi">
              <el-radio-group v-model="filters.com4" @change="changeComd">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div class="card-more" v-if="fatherData==false">
        <el-button type="text" class="code_btn" @click="fatherData=true">
          <span class="card-row">
            <i class="el-icon-d-arrow-right"></i>
          </span>
          更多信息
        </el-button>
      </div>
      <div class="card-more" v-if="fatherData==true">
        <el-button type="text" class="code_btn" @click="fatherData=false">
          <span class="card-row card-row-t">
            <i class="el-icon-d-arrow-right"></i>
          </span>
          点击收起
        </el-button>
      </div>
    </div>
    <!-- 切换企业弹框 -->
    <el-dialog :visible.sync="checkCompany" id="auto2dialog" :show-close="true">
      <div class="check_company">切换企业</div>
      <div class="check_companybg">
        <el-radio-group
          v-model="filters.companyvalue"
          class="auto_radio"
          v-for="(item,index) in companyList"
          :key="index"
        >
          <el-radio :label="item.companyId">
            <span>{{item.companyId}}</span>
            {{item.companyName}}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="autotabbtn">
        <el-button type="primary" @click="changecomp">确定</el-button>
      </div>
    </el-dialog>
    <!-- /切换企业弹框 -->
    <!-- 切换角色弹窗 -->
    <el-dialog
      :visible.sync="checkParts"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      id="partdialog"
      :show-close="false"
    >
      <div class="part-check font-600">
        <span>角色选择</span>
        <img class="part-close" src="@/assets/test/part-close.png" alt @click="checkClose" />
      </div>
      <div class="part-icon">
        <span class="part-user part-userR">
          <span class="part-circle" :class="{partactive:act==1}" @click="act=1">
            <img class="part-iconl" src="@/assets/test/part-u.png" alt />
          </span>
          <span class="part-checktext" :class="{partactivet:act==1}">企业主</span>
        </span>
        <span class="part-user">
          <span class="part-circle" :class="{partactive:act==2}" @click="act=2">
            <img src="@/assets/test/part-c.png" alt />
          </span>
          <span class="part-checktext" :class="{partactivet:act==2}">财务人员</span>
        </span>
      </div>
      <div>
        <div class="part-btn ease" @click="checkSubmit">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  findhydm,
  getHy,
  getCompanyId,
  getbasicinfo,
  dmHyMore,
  changecompanyselect,
  changecompany,
  changekjzd,
  changehyaix,
  saveCompanyInfo
} from "@/api/api";
import { regionData } from "@/dependencies/element-china-area-data.js";

export default {
  components: {},
  data() {
    return {
      city: regionData,
      companyList: [],
      filters: {
        dq: [],
        hy: "",
        hy1: "",
        kjzd: "",
        companyvalue: "",
        all: "",
        average: "",
        com1: "1",
        com2: "1",
        com3: "1",
        com4: "1"
      },
      province1: "",
      city1: "",
      checkCompany: false,
      radio: "",
      value: "",
      hasPwd: false,
      FormInfo: {},
      morehylist: [],
      hyList1: [],
      hyList2: [],
      Form: {},
      kjzd: [
        { value: "qykjzd", label: "企业会计制度" },
        { value: "qykjzz", label: "企业会计准则" },
        { value: "xqykjzz", label: "小企业会计准则" }
      ],
      hyDm: "",
      res: [],
      fatherData: false,
      userStatus: 0,
      act: 1,
      checkParts: false
    };
  },
  mounted() {
    // this.basicinfo();
  },
  methods: {
    checkClose() {
      this.checkParts = false;
    },
    checkSubmit() {
      this.userStatus = this.act;
      this.checkParts = false;
      this.$emit("setUsername", this.userStatus);
      this.saveInfo();
    },

    // 修改之后保存
    saveInfo() {
      let token = JSON.parse(localStorage.getItem("token")).token;
      let para = {
        companyId: this.res.companyId,
        dq: this.filters.dq.toString(),
        userToken: token,
        number: this.filters.average,
        generalAssets: this.filters.all,
        highNewTechnology: this.filters.com1,
        miniature: this.filters.com2,
        foreignInvestment: this.filters.com3,
        restrictedIndustries: this.filters.com4,
        type: this.userStatus
      };
      saveCompanyInfo(para).then(res => {
        //console.log(res);
      });
    },
    // 修改资产总额
    changeAll() {
      this.saveInfo();
    },
    // 修改从业人数
    changeAverage() {
      this.saveInfo();
    },
    // 修改企业类型
    changeComa() {
      this.saveInfo();
    },
    changeComb() {
      this.saveInfo();
    },
    changeComc() {
      this.saveInfo();
    },
    changeComd() {
      this.saveInfo();
    },
    async deleteCache() {
      this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
      this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
      await this.$store.cache.dispatch("loadCompanyRenzheng");
      await this.$store.cache.dispatch("loadCompanyInfo");
    },
    addCompany() {
      const redirectToURL = encodeURIComponent(this.$route.fullPath);
      this.$router.push(
        `/companyApply?addnewcompanystatus=true&redirectToURL=${redirectToURL}`
      );
    },
    //获取企业相关信息
    basicinfo() {
      let token = JSON.parse(localStorage.getItem("token")).token;

      getCompanyId(token).then(async data => {
        if (data.msgCode == 1) {
          this.res = data.data;
          localStorage.setItem("hasPwd", data.data.hasPwd);
          this.hasPwd = data.data.hasPwd;

          if (this.res) {
            getbasicinfo(this.res.companyId).then(async data => {
              //修改localstorage里的user里的内容
              const user = JSON.parse(localStorage.getItem("user"));
              user.channalId = data.data.channalId;
              user.channalUserId = data.data.channalUserId;
              user.companyId = data.data.id;
              user.nsrsbm = data.data.nsrsbm;
              this.userStatus = data.data.type;
              this.$emit("setUsername", this.userStatus);
              localStorage.setItem("user", JSON.stringify(user));
              await this.deleteCache();

              this.FormInfo = data.data;
              this.filters.kjzd = this.FormInfo.kjzd;
              this.filters.dq = this.FormInfo.dq.split(",");
              this.filters.average = this.FormInfo.number;
              this.filters.all = this.FormInfo.generalAssets;
              this.filters.com1 = this.FormInfo.highNewTechnology;
              this.filters.com2 = this.FormInfo.miniature;
              this.filters.com3 = this.FormInfo.foreignInvestment;
              this.filters.com4 = this.FormInfo.restrictedIndustries;

              for (let k in this.city) {
                // if (this.city[k].label == this.filters.dq[0]) {
                //   this.filters.dq[0] = this.city[k].value;
                // }
                for (let k1 in this.city[k].children) {
                  if (this.city[k].children[k1].value == this.filters.dq) {
                    this.filters.dq = [
                      this.city[k].value,
                      this.city[k].children[k1].value
                    ];
                  }
                }
              }
              this.Form = data.data;
              let params = {
                hyDm: data.data.hy
              };
              if (data.data.hy) {
                findhydm(params).then(res => {
                  // this.filters.hy = res.data.hyMc;
                  this.filters.hy1 = res.data.mlhyDm;
                  this.basehy(res.data.mlhyDm, res.data.hyDm);
                });
              }
              getHy().then(data => {
                let hyList = data.data;
                this.hyList1 = data.data;
              });
            });
          } else {
            //console.log("未认证");
          }
        }
      });
    },
    // 获取2级行业
    basehy(e, dm) {
      this.filters.hy = "";
      let params = {
        hy: e,
        hycode: ""
      };
      dmHyMore(params).then(res => {
        if (res.msgCode == 1) {
          this.morehylist = res.data;
          if (dm) {
            for (var i in this.morehylist) {
              if (this.morehylist[i].hyDm == dm) {
                this.filters.hy = this.morehylist[i].dmMc;
              }
            }
          }
        }
      });
    },
    //省市触发
    // handleChange(value) {
    //   this.province1_code = value[0];
    //   for (let key in value) {
    //     for (let k in this.city) {
    //       if (this.city[k].value == value[key]) {
    //         this.province1 = this.city[k].label;
    //       }
    //       for (let k1 in this.city[k].children) {
    //         if (this.city[k].children[k1].value == value[key]) {
    //           this.city1 = this.city[k].children[k1].label;
    //         }
    //       }
    //     }
    //   }
    // },
    //企业下拉框
    changecompanyselect() {
      let user = JSON.parse(localStorage.getItem("user")).id;
      let params = {
        id: user
      };
      changecompanyselect(params).then(res => {
        if (res.msgCode == "1") {
          this.companyList = res.data;
        }
      });
    },
    //点击切换企业显示弹框
    checkCompanyClick() {
      this.checkCompany = true;
      this.changecompanyselect();
    },
    //确认切换企业
    changecomp() {
      if (!this.filters.companyvalue) {
        this.$message.error({
          message: "请选择需要切换的企业"
        });
        return;
      }
      let params = {
        companyId: this.filters.companyvalue,
        userId: JSON.parse(localStorage.getItem("user")).id
      };
      changecompany(params).then(async res => {
        if (res.msgCode == "1") {
          this.$message.success({
            message: "切换企业成功",
            duration: 1500
          });

          this.basicinfo();
          this.FormInfo.companyName = this.companyvalue;
          this.checkCompany = false;
        } else {
          this.$message.success({
            message: res.msgInfo,
            duration: 1500
          });
          return;
        }
      });
    },
    changekjzd() {
      let token = JSON.parse(localStorage.getItem("token")).token;
      let params = {
        companyId: this.res.companyId,
        kjzd: this.filters.kjzd,
        token
      };
      changekjzd(params).then(async res => {
        if (res.msgCode == 1) {
          this.$message.success({
            message: "切换成功",
            duration: 1500
          });
          await this.deleteCache();
          //this.basicinfo();
        }
      });
    },
    changehy() {
      let token = JSON.parse(localStorage.getItem("token")).token;
      let params = {
        token,
        hyDm: this.filters.hy
      };
      changehyaix(params).then(async res => {
        if (res.msgCode == "1") {
          this.$message.success({
            message: "修改成功",
            duration: 1500
          });
          await this.deleteCache();
        } else {
          this.$message.error({
            message: "行业缺少必要选项",
            duration: 1500
          });
        }
      });
    },

    //会计制度改变
    handleChangeKJZD() {
      this.changekjzd();
    },
    //一级菜单改变
    handleIndustry1SelectChange(e, dm) {
      this.basehy(e, dm);
      this.$message.warning({
        message: "需要继续选择二级行业才能生效",
        duration: 1500
      });
    },
    //二级菜单改变
    handleIndustry2SelectChange() {
      this.changehy();
      this.basicinfo();
    },

    handleDqChange() {
      let token = JSON.parse(localStorage.getItem("token")).token;
      let para = {
        companyId: this.res.companyId,
        dq: this.filters.dq.toString(),
        userToken: token
      };
      saveCompanyInfo(para).then(res => {
        if (res.msgCode == "1") {
          this.$message.success({
            message: "切换成功",
            duration: 1500
          });
          this.basicinfo();
        }
      });
    }
  }
};
</script>
<style src="@/styles/index.scss" lang="scss" scoped></style>
<style lang="stylus" scoped>
.title {
  display: flex;
  align-items: center;

  .btnsInTitle {
    margin-left: 25px;
  }
}

>>> .el-button--text {
  color: #2F54EB;
}

.usercard {
  // margin-top: 26px;
  border: none;
  font-size: 14px;
  // display: flex;
  font-weight: 400;

  // flex-wrap: wrap;
  // flex-direction: column;
  // height: 360px;
  .radio {
    color: rgba(0, 0, 0, 0.8);

    .el-radio__input.is-checked {
      .el-radio__inner {
        border-color: #2f54eb;
        background: #2f54eb;
      }
    }

    .el-radio__input.is-checked + .el-radio__label {
      color: #2f54eb;
    }
  }

  .el-card__body {
    height: 724px;
    padding: 36px 20px 20px 20px;
  }

  .save {
    width: 76px;
    height: 36px;
    background: rgba(47, 84, 235, 1);
    border-radius: 4px;
    border-color: rgba(47, 84, 235, 1);
    line-height: 36px;
    margin-left: 140px;
    margin-top: 38px;
  }

  .before {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(47, 84, 235, 1);
    margin-left: 24px;
  }

  .box {
    height: 50px !important;
    line-height: 50px !important;
    width: 50%;
    display: flex;

    .qyname {
      width: 133px;
      display: inline-block;
      text-align: right;
      flex-shrink: 0;
    }

    .savedata {
      width: 400px;
      height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      line-height: 20px;
    }

    .name {
      padding: 0 4px 0 8px;
      flex-grow: 1;
    }
  }
}

.autotabbtn {
  height: 54px;
  width: 100%;

  .el-button--text {
    color: #007AFF;
  }

  .el-button--primary {
    width: 100%;
    height: 54px;
    font-size: 16px;
    background-color: #2F54EB;
    margin-top: 40px;
    border-radius: 0px 0px 8px 8px;
    border: none;
  }
}

>>>#auto2dialog {
  .el-dialog {
    width: 480px;
    border-radius: 10px;

    .el-dialog__header {
      // display: none;
    }

    .el-dialog__body {
      padding: 0;
    }
  }
}

// 全局css
.company_dq {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(107, 120, 151, 1);
  margin-right: 35px;
}

.msg_input {
  margin-left: 67px !important;
}

.compant_mdf {
  margin-bottom: 33px;
  min-height: 40px;
}

.company_kjzd {
  padding-bottom: 39px;
}

.date_hy {
  margin-top: 20px;
}

>>>.date_selet_dq .el-input .el-input__suffix {
  top: 5px;

  i:before {
    position: relative;
    top: -4px;
  }
}

.check_company {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  height: 92px;
  line-height: 92px;
  margin-top: -30px;
  text-align: center;
}

.check_companybg {
}

.msg_input {
  margin-left: 67px !important;
}

.compant_mdf {
  margin-bottom: 33px;
  min-height: 100px;
}

.company_kjzd {
  margin-bottom: 39px;
}

.date1 {
  width: 42%;
  margin-left: 99px;

  .company_dq {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(107, 120, 151, 1);
    margin-right: 35px;
  }

  .company_hy {
    margin-right: 10px;
  }
}

.box {
  display: flex;

  .name {
    flex-grow: 1;
  }
}

>>> .el-cascader {
  width: 80% !important;

  .el-input {
    width: 80% !important;
  }
}

>>> .el-select {
  width: 80% !important;

  .el-input {
    width: 80% !important;
  }
}

>>>.el-input el-input-group el-input-group--append {
  width: 80%;
}

.qytotal {
  padding: 0 4px 0 8px;
  flex-grow: 1;
}

>>>.el-input-group {
  width: 80%;
  height: 36px;
}

>>>.el-input__inner {
  height: 36px;
}

.usercard .box .qynamelong {
  position: relative;
  right: -70px;
  width: 182px;
}

.usercard .box .qynameposi {
  position: relative;
  right: -60px;
}

.usercard .box .qynameright {
  margin-right: 15px;
}

.field:before {
  content: '*';
  color: red;
  position: relative;
  right: 4px;
}

>>>.el-input-group {
  width: 64%;
}

.box-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.box-radio {
  display: flex;
}

.usercard .box-auto {
  width: auto;
}

.card-more {
  padding-left: 138px;
}

.card-row {
  display: inline-block;
  transform: rotate(90deg);
}

.card-row-t {
  display: inline-block;
  transform: rotate(270deg);
}

.name-logo {
  position: relative;
  top: 5px;
  margin-right: 4px;
}

.el-icon-refresh {
  margin-right: 6px;
}

.name-right {
  margin-right: 16px;
}

.part-check {
  width: 100%;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid #EEEEEE;
  padding-left: 24px;
  box-sizing: border-box;
  align-item: center;
}

.part-close {
  position: relative;
  top: 18px;
  float: right;
  padding-right: 24px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.part-icon {
  display: flex;
  justify-content: center;
  height: 180px;
  text-align: center;
  padding-top: 30px;
  border-bottom: 1px solid #EEEEEE;
  box-sizing: border-box;
}

.part-iconl {
  width: 74px;
  height: 89px;
}

.part-btn {
  position: relative;
  right: 24px;
  top: 16px;
  width: 76px;
  height: 36px;
  line-height: 36px;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  float: right;
  cursor: pointer;
}

.part-btn:hover {
  background: rgba(47, 60, 235, 1);
}

.part-check {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
}

.partactive {
  background: rgba(240, 245, 255, 1);
  border: 2px solid rgba(47, 84, 235, 1);
}

.partactivet {
  color: rgba(47, 84, 235, 1);
}

>>> #partdialog .el-dialog {
  width: 400px;
  height: 304px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

>>> #partdialog .el-dialog__header {
  padding: 0;
}

>>> #partdialog .el-dialog__body {
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.part-circle {
  display: inline-block;
  width: 88px;
  height: 88px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(217, 217, 217, 1);
  border-radius: 50%;
  margin-bottom: 10px;
}

.part-circle:hover {
  transform: scale(1.1);
}

.part-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.part-userR {
  margin-right: 64px;
}

.partactive {
  background: rgba(240, 245, 255, 1);
  border: 2px solid rgba(47, 84, 235, 1);
}

.partactivet {
  color: rgba(47, 84, 235, 1);
}
</style>
