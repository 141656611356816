<template>
  <div class="page-wrapper" :key="actualZq" v-if="actualZq">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb-item>风险检测</el-breadcrumb-item>
        <!-- <el-breadcrumb-item :to="{ name: 'test-hub' }">检测中心</el-breadcrumb-item> -->
        <el-breadcrumb-item>开始检测</el-breadcrumb-item>
        <el-breadcrumb-item>数据读取</el-breadcrumb-item>
      </bread-crumb>
      <div class="main-top">
        <main-header>
          <template slot="title">余额表上传</template>
        </main-header>
        <div class="processContainer">
          <span>
            <img class="processLImg" src="@/pages/Test/Invoice/images/1.png" alt />
          </span>
          <span class="process1">申报数据读取</span>
          <span class="process-status"></span>
          <span>
            <img class="processLImg" src="@/pages/Test/Invoice/images/2.png" alt />
          </span>
          <span class="process1">余额表上传</span>
          <span class="process-status process-statusr"></span>
          <span>
            <img class="processLImg" src="@/pages/Test/Invoice/images/4.png" alt />
          </span>
          <span class="process2">发票数据采集</span>
        </div>
        <div class="rightProcess"></div>
        <!-- <ProgressDiagram
        class="progressdis"
        @editUser="editUser"
        :userInfo="userInfo"
        :progress="4"
        :version="version"
        :nochange="nochange"
        ></ProgressDiagram>-->
      </div>
    </div>

    <div class="content-body" :class="{ nomargin: userInfo == 1 }">
      <div v-if="userInfo == 2">
        <div>
          <div class="content-body-flex" v-if="userInfo == 2">
            <div class="content-top-1" v-if="!showTableDetail">余额表</div>
          </div>
          <div class="onUpload-flex">
            <uploaderb
              class="onUpload-flex-item onUpload-flex-iteml"
              @onUpload="onUpload"
              :uuid="uuid"
              v-if="actualZq"
              :zq="zq"
            ></uploaderb>
            <uploaders
              class="onUpload-flex-item"
              @onUpload="onUpload"
              :uuid="uuid"
              v-if="actualZq"
              :zq="zq"
            ></uploaders>
          </div>
        </div>

        <table-lists
          @deletes="deletes"
          class="table-lists"
          :balanceData="balanceData"
          :uuid="uuid"
        ></table-lists>
      </div>
      <report-method
        @openlicense="openlicense"
        @setName="changeName"
        v-if="userInfo == 1"
      ></report-method>
      <sa-footer>
        <div class="footer">
          <!-- <button
            class="btn btn-purge-data"
            @click="handleBtnPurgeDataClicker"
            v-if="hasItemsInList&&userInfo==2"
          >清除数据</button>-->
          <!-- <button
            class="btn btn-default btn-next btn-right-none"
            @click="handleShowAllSDSTablesBtnClick"
            v-if="userInfo==2"
          >全部所得税申报表</button>-->
          <template>
            <!-- <router-link
              :to="{name:'choose-table', params: { zqLocation }}"
              class="btn btn-default btn-next btn-right-none"
            >上一步</router-link>-->
            <router-link
              :to="{ name: 'upload-table' }"
              class="btn btn-default btn-next btn-right-none"
              >上一步</router-link
            >

            <button class="btn btn-primary btn-next" @click="handleBtnNext">
              下一步
            </button>
          </template>
          <!-- <template v-else-if="dataPeriod==1">
            <router-link
              :to="{ name: 'upload-table', params: { zq,zqLocation,dataPeriod:0}}"
              class="btn btn-default btn-next btn-right-none"
            >上一步</router-link>
            <button @click="handleBtnNext2" class="btn btn-primary btn-next">下一步</button>
          </template>-->

          <!-- <router-link
          :to="{ name: 'upload-table', params: { id:tableCards[0].id,zq:tableCards[0].zq,zqLocation:zqs[0]}}"
          class="btn btn-primary btn-next"
          >下一步</router-link>-->
        </div>
      </sa-footer>
    </div>
    <!--温馨提示弹出框-->
    <el-dialog
      :visible.sync="tipDialogVisible"
      width="576px"
      :show-close="false"
      class="tipDialog"
      top="32vh"
    >
      <div class="inner">
        <img src="@/assets/test/tip.png" class="img" />
        <div class="right-wrapper">
          <div class="title">温馨提示</div>
          <div class="desc">
            为保证数据准确提取，需尽量保证文件命名标准，若一个Excel内包含多个sheet则sheet页命名需规范
          </div>
          <button class="btn btn-primary btn-i-get" @click="handleTipDialogBtnClick">
            知道了
          </button>
        </div>
      </div>
    </el-dialog>
    <!--温馨提示弹出框-->
    <!--上传错误提示弹出框-->
    <el-dialog
      :visible.sync="correctDialogVisible"
      width="80%"
      :show-close="true"
      class="correctDialog"
      top="1vh"
      @close="handleCorrectDialogClose"
    >
      <SectionTitle>报表匹配</SectionTitle>
      <div class="modify-table-wrapper">
        <modify-table></modify-table>
      </div>
    </el-dialog>
    <!--/上传错误提示弹出框-->
    <!--纠错弹出框-->
    <el-dialog
      :visible.sync="uploadErrorDialogVisible"
      width="800px"
      :show-close="true"
      top="25vh"
      title="抱歉,以下表无法解析"
    >
      <el-table :data="uploadErrors" style="width: 100%">
        <el-table-column prop="userFileName" label="表名"></el-table-column>
        <el-table-column prop="reason" label="异常原因"></el-table-column>
      </el-table>
    </el-dialog>
    <!--/纠错弹出框-->
    <!--上传须知提示弹出框-->
    <el-dialog
      :visible.sync="acknowledgeDialogVisible"
      width="62.5%"
      :show-close="true"
      class="correctDialog"
    >
      <SectionTitle>上传须知</SectionTitle>
      <div class="modify-table-wrapper">
        <acknowledage-tab
          v-on:acknowledgeDialogVisible="clickbtn"
          :bankId="bankId"
        ></acknowledage-tab>
      </div>
    </el-dialog>
    <!--/上传须知提示弹出框-->

    <!--数据上传组件-->
    <TestDataUploader
      ref="testDataUploader"
      :bankId="bankId"
      :productID="productID"
      :productName="productName"
      :uuid="uuid"
      :zq="zq"
      :version="version"
    ></TestDataUploader>
    <!--/数据上传组件-->

    <loading
      :active.sync="isLoading"
      backgroundColor="#000"
      :can-cancel="true"
      :is-full-page="fullPage"
      loader="Dots"
      color="#2f54eb"
    ></loading>

    <!--所得税填报选择-->
    <el-dialog
      :visible.sync="showSDSDialog"
      :close-on-click-modal="false"
      width="800px"
      :show-close="false"
      class="correctDialog"
    >
      <SectionTitle>所得税填报选择</SectionTitle>
      <SDSFilter :tables="SDStables" @onDialogSubmit="handleSDSDialogSubmit"></SDSFilter>
    </el-dialog>
    <!--/所得税填报选择-->
    <!-- 补全信息弹窗 -->
    <supple-ment
      :code="code"
      :checkParts="checkParts"
      @submit="submit"
      @cancel="cancel"
    ></supple-ment>
    <!-- 补全信息弹窗 -->
    <licen ref="opendialog"></licen>
  </div>
</template>

<script>
import {
  useAuthorize,
  checkCompany,
  checkNew,
  breezeOuter,
  getBalanceInfo,
} from "@/api/api";
import { cloneDeep } from "lodash";
import BreadCrumb from "@/components/BreadCrumb";
import Uploaders from "./components/Uploaders";
import Uploaderb from "./components/Uploaderb";
import TableLists from "./components/TableLists";
import ModifyTable from "./components/ModifyTable";
import SectionTitle from "@/components/SectionTitle";
import AcknowledageTab from "./components/AcknowledageTab";
import suppleMent from "./components/supplement";
import SDSFilter from "./components/SDSFilter";
import MainHeader from "@/pages/components/MainHeader";
import ProgressDiagram from "@/pages/components/ProgressDiagram";
import Footer from "../ChooseTable/components/Footer";
import TestDataUploader from "../Components/TestDataUploader";
import { getUserTables, clearUploadedTableforTest } from "@/api/table";
import { clearUploadedTableforTestAi, deleteTableAi } from "@/api/api";
import { mapState } from "vuex";
import { zq2name, previousZq } from "@/utils/helper";
import reportMethod from "../../Test/ChooseTable/components/report-method";
import licen from "./components/licen-dialog";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      deleteList: [
        localStorage.getItem("zqY") + "004",
        +localStorage.getItem("zqY") - 1 + "004",
      ],
      nochange: true,
      tables: {},
      tipDialogVisible: false, //刚开始进入的提示框
      fullPage: true,
      isLoading: false,
      correctDialogVisible: false, //控制手动修正的表的弹窗
      acknowledgeDialogVisible: false,
      uploadErrorDialogVisible: false, //控制无法识别的表的弹窗
      uploadErrors: [], //错误的表名及原因
      templateDownloadURL: "",
      hasItemsInList: false,
      actualZq: 0,
      zqs: [],
      showSDSDialog: false,
      userInfo: 2,
      checkParts: false,
      code: 9996,
      index: -1,
      step2Msg: null,
      valueMsg: "",
      area: "",
      bizNo: "",
      radioIndex: "",
      passwordInput: "",
      clickQr: "",
      index1Data: "",
      indexJylsh: "",
      mobile: "",
      mobileCode: "",
      showTableDetail: false,
      balanceData: [
        { index: 0, name: localStorage.getItem("zqY") + "余额表", status: 1 },
        {
          index: 1,
          name: localStorage.getItem("zqY") - 1 + "余额表",
          status: 1,
        },
      ],
    };
  },
  components: {
    Loading,
    BreadCrumb,
    Uploaders,
    Uploaderb,
    TableLists,
    SectionTitle,
    ModifyTable,
    AcknowledageTab,
    MainHeader,
    ProgressDiagram,
    saFooter: Footer,
    TestDataUploader,
    SDSFilter,
    reportMethod,
    suppleMent,
    licen,
  },
  props: {
    zq: [Number, String],
    productName: String,
    productID: [Number, String],
    zqLocation: [Number, String],
    bankId: String,
    uuid: String,
    dataPeriod: [Number, String],
    version: [Number, String],
    nsrsbhIndex: [Number, String],
    qykjzdIndex: [Number, String],
    selectvalueIndex: [Number, String],
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID", "SDSSelection"]),
    zqName: function () {
      return zq2name(this.actualZq);
    },
    // progress: function() {
    //   let ret = 2;
    //   if (this.dataPeriod == 0) {
    //     ret = 2;
    //   } else if (this.dataPeriod == 1) {
    //     ret = 3;
    //   }
    //   return ret;
    // },
    SDStables() {
      let ret = [];
      if (this.tables.所得税表) {
        ret = this.tables.所得税表.list;
      }
      return ret;
    },
  },
  async mounted() {
    // 如果是报税版本，默认拉表方式
    // if (this.version == 3) {
    //   this.userInfo = 2;
    // }

    await this.init();
    await this.getbalanceInfo();
    // 如果是报税版本，初始化判断是否应该补全信息
    // if (this.userInfo == 2 || this.version == 3) {
    //   this.checkcompany();
    //   return;
    // }
  },
  methods: {
    deletes(val) {
      this.$confirm("是否删除已上传余额表?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteTableAi(
            sessionStorage.getItem("nsrsbh"),
            val.zq,
            "YEB",
            localStorage.getItem("authorization"),
            this.uuid
          ).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.loadList();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
      // this.deleteList[val];
      // this.handleBtnPurgeDataClicker(this.deleteList[val.index], val.name);
    },
    // showMoreUpload() {
    //   this.showTableDetail = true;
    // },

    // 打开授权协议
    openlicense() {
      this.$refs.opendialog.openDialog();
    },
    //根据会计制度获取不同的报表弹窗
    toast() {
      if (this.dataPeriod == 0 && this.userInfo == 2) {
        this.showSDSFilterDialog();
      }
    },
    // 补全信息确定
    submit() {
      this.checkParts = false;
      // 补全信息后更新企业信息
      this.deleteCache();
    },
    async deleteCache() {
      this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
      this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
      await this.$store.cache.dispatch("loadCompanyRenzheng");
      await this.$store.cache.dispatch("loadCompanyInfo");
      await this.loadList();
      await this.toast();
    },
    // 关闭补全信息
    cancel() {
      this.checkParts = false;
      this.editUser();
      // 如果是报税版，关闭补全信息，则跳转到基础设置页面
      // if (this.version == 3) {
      //   this.$router.go(-1);
      // }
      // 如果是综合体检，关闭补全信息，则跳转到输入税号密码格式
      // if (this.version == 4) {
      //   this.editUser();
      // }
    },
    changeName(val) {
      this.taxSiteUsername = val.name;
      this.taxSitePwd = val.password;
      this.activeRead = val.active;
      this.index = val.index;
      this.step2Msg = val.step2Msg;
      this.valueMsg = val.value;
      this.area = val.province;
      this.bizNo = val.bizNo;
      this.radioIndex = val.radio;
      this.passwordInput = val.passwordInput;
      this.clickQr = val.clickQr;
      this.indexJylsh = val.indexJylsh;
      this.index1Data = val.index1Data;
      this.mobile = val.mobile;
      this.mobileCode = val.mobileCode;
    },
    // 判断是否需要补全信息，如果是返回1，走checkAmount
    checkcompany() {
      let res = {
        msgCode: 1,
      };
      this.checkAmount(res);
      // let params = {
      //   userToken: JSON.parse(localStorage.getItem("token")).token
      // };
      // checkCompany(params).then(res => {
      //   console.log(res);
      //   this.code = res.msgCode;
      //   if (res.msgCode == 9996) {
      //     this.checkParts = true;
      //   }
      //   if (res.msgCode == 9997) {
      //     this.checkParts = true;
      //   }
      //   if (res.msgCode == 9998) {
      //     this.checkParts = true;
      //   }

      // });
    },
    // 如果不需要补全信息，则请求表格接口，然后显示表格信息
    async checkAmount(res) {
      if (res.msgCode == 1) {
        if (this.dataPeriod == 0 && this.userInfo == 2) {
          await this.loadList();
          await this.showSDSFilterDialog();
        }
      }
    },
    // 切换上传方式
    async editUser(val) {
      this.userInfo = val;
      // if(this.userInfo == 1) this.userInfo = 1;
      // if(this.userInfo == 2) this.userInfo = 2;
      // this.userInfo == 1 ? (this.userInfo = 2) : (this.userInfo = 1);
      sessionStorage.setItem("method", this.userInfo);
      // 综合体检方式才有切换上传按钮，点击切换上传按钮之后，去判断是否需要补全信息
      if (this.userInfo == 2) {
        if (this.dataPeriod == 0 && this.userInfo == 2) {
          await this.checkcompany();
        }
      }
      //首次进入显示温馨提示
      const hideWenxinTip = localStorage.getItem("hideWenxinTip");
      if (!hideWenxinTip) {
        this.tipDialogVisible = true;
      }
    },
    handleShowAllSDSTablesBtnClick() {
      this.$store.commit("UploadTable/setSDSSelection", {
        SDSlist: this.tables.所得税表.list,
      });
    },
    handleSDSDialogSubmit() {
      this.showSDSDialog = false;
    },
    //第一阶段显示所得税填报选择
    showSDSFilterDialog() {
      if (this.SDSSelection === null) {
        this.showSDSDialog = true;
      }
    },
    checkIfAllFiltedTableFilled() {
      //把所有列表展平
      const tables = [];
      for (const key in this.tables) {
        if (this.tables.hasOwnProperty(key)) {
          const tablesInkey = this.tables[key].list;
          for (const table of tablesInkey) {
            tables.push(table);
          }
        }
      }
      return this.SDSSelection.every((aSDSSelection) => {
        const templateId = aSDSSelection.templateId;
        const found = tables.find((table) => {
          return templateId == table.templateId && table.status != "未上传";
        });
        return !!found;
      });
    },
    //本期的下一步按钮
    handleBtnNext() {
      this.$router.push({
        name: "test-invoice",
        params: { userName: this.taxSiteUsername },
        // 将账号带过去
      });
      // 模拟跳转余额标
      // this.$router.push({
      //   name: "test-Balance",
      //   params: { userName: this.taxSiteUsername }
      //   // 将账号带过去
      // });

      //如果选择所得税数据表数量不等于所有所得税数据表的数量 并且 不是每张选择的所得税都被填了 则提示
      // if (
      //   this.SDSSelection &&
      //   this.tables.所得税数据.list.length != this.SDSSelection.length &&
      //   !this.checkIfAllFiltedTableFilled()
      // ) {
      //   this.$confirm(
      //     "检测到您勾选所得税填报，未全部填写完成是否继续填写",
      //     "温馨提示",
      //     {
      //       confirmButtonText: "下一步",
      //       cancelButtonText: "继续填写",
      //       type: "warning"
      //     }
      //   ).then(() => {
      //     this.$router.push({
      //       name: "upload-table",
      //       params: { zq: this.zq, zqLocation: this.zqLocation, dataPeriod: 1 }
      //     });
      //   });
      // } else {
      //   this.$router.push({
      //     name: "upload-table",
      //     params: { zq: this.zq, zqLocation: this.zqLocation, dataPeriod: 1 }
      //   });
      // }
    },
    //上期的下一步按钮点击
    handleBtnNext2() {
      // console.log(this.userInfo);
      // return;
      // 模拟跳转余额标
      if (this.userInfo == 1) {
        this.$router.push({
          name: "test-Balance",
          params: { userName: this.taxSiteUsername },
          // 将账号带过去
        });
      }
      return;
      // 模拟跳转余额标

      //如果选择所得税数据表数量不等于所有所得税数据表的数量 并且 不是每张选择的所得税都被填了 则提示

      if (
        this.SDSSelection &&
        this.tables.所得税表.list.length != this.SDSSelection.length &&
        !this.checkIfAllFiltedTableFilled()
      ) {
        this.$confirm("检测到您勾选所得税填报，未全部填写完成是否继续填写", "温馨提示", {
          confirmButtonText: "下一步",
          cancelButtonText: "继续填写",
          type: "warning",
        }).then(() => {
          //如果是发票版或者定制版则调招到发票检测页面
          if (this.version > 3) {
            this.$router.push({
              name: "test-invoice",
            });
          } else {
            //否则直接开始上传流程
            this.$refs["testDataUploader"].handleBtnNext2Click();
          }
        });
      } else {
        //如果是发票版或者定制版则调招到发票检测页面
        if (this.version > 3) {
          this.$router.push({
            name: "test-invoice",
          });
        } else {
          //否则直接开始上传流程
          this.$refs["testDataUploader"].handleBtnNext2Click();
        }
      }
    },

    getbalanceInfo() {
      let params = {
        uuid: this.uuid,
        zq: this.zq,
      };
      getBalanceInfo(params).then((res) => {
        this.balanceData[0].status = res.data.bq.status;
        this.balanceData[1].status = res.data.sq.status;
        this.balanceData[0].zq = res.data.bq.zq;
        this.balanceData[1].zq = res.data.sq.zq;
      });
    },

    async init() {
      //如果没有permission id就退出
      // if (!this.$store.state.UploadTable.permissionID) {
      //   this.$router.push({ name: "homePage" });
      // }

      this.isLoading = true;

      if (this.dataPeriod == 1) {
        //上个征期
        this.actualZq = previousZq(this.zq);
        this.zqs.push(this.zq);
        this.zqs.push(this.actualZq);
      } else if (this.dataPeriod == 0) {
        //当前征期
        this.actualZq = this.zq;
      }

      this.$store.commit("UploadTable/setZq", { zq: this.actualZq });
      this.$store.commit("UploadTable/setZqList", { zq: this.zq });

      await this.$store.cache.dispatch("loadCompanyInfo");
      await this.loadList();
      this.$store.commit("UploadTable/setProduct", {
        name: this.productName,
        id: this.productID,
        bankID: this.bankId,
      });

      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    clickbtn() {
      this.acknowledgeDialogVisible = false;
    },
    handleTipDialogBtnClick() {
      this.tipDialogVisible = false;
      localStorage.setItem("hideWenxinTip", true);
    },
    listChanged() {
      this.loadList();
    },
    //清空所有已经上传的报表
    handleBtnPurgeDataClicker(zq) {
      this.$confirm(`所有文件将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await clearUploadedTableforTestAi(
          sessionStorage.getItem("nsrsbh"),
          zq,
          this.authorization,
          this.uuid
        );

        if (result.code == 200) {
          this.$emit("listChanged");
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.loadList();
        } else {
          this.$message.error(result.message);
        }
      });
    },
    // 删除单个文件
    // handleBtnRemove(zq, name) {
    //   const _this = this;
    //   this.$confirm(`${name}将被删除, 是否继续?`, "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning"
    //   })
    //     .then(async () => {
    //       console.log("dsadsa");
    //       //   _this.companyInfo.nsrsbm,
    //       //   _this.zq,
    //       //   templateId,
    //       //   _this.authorization
    //       //   console.log(_this.authorization);
    //       // debugger;

    //       const result = await deleteTableAi(
    //         // _this.companyInfo.nsrsbm,
    //         sessionStorage.getItem("nsrsbh"),
    //         zq,
    //         templateId,
    //         localStorage.getItem("authorization"),
    //           this.uuid
    //       );
    //       console.log(result);

    //       if (result.msgCode == 1) {
    //         _this.$emit("listChanged");
    //         _this.$message({
    //           type: "success",
    //           message: "删除成功!"
    //         });
    //       } else {
    //         _this.$message.error(result.msgInfo);
    //       }
    //     })
    //     .catch(() => {});
    // },
    handleBtnRemove(zq, name) {
      // console.log(filename);
      const _this = this;
      this.$confirm(`${name}将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          //   _this.companyInfo.nsrsbm,
          //   _this.zq,
          //   templateId,
          //   _this.authorization
          //   console.log(_this.authorization);
          // debugger;

          const result = await deleteTableAi(
            // _this.companyInfo.nsrsbm,
            sessionStorage.getItem("nsrsbh"),
            zq,
            templateId,
            localStorage.getItem("authorization"),
            this.uuid
          );

          if (result.msgCode == 1) {
            _this.$emit("listChanged");
            _this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            _this.$message.error(result.msgInfo);
          }
        })
        .catch(() => {});
    },
    //修正表格的弹出框
    showCorrectDialog() {
      this.correctDialogVisible = true;
    },
    //错误原因弹出框
    showUploadErrorDialog() {
      this.uploadErrorDialogVisible = true;
    },
    submitData() {
      this.correctDialogVisible = true;
    },
    async loadList() {
      //bankId, qykjzd, nsrsbh, zq
      // const tables = await getUserTables(
      //   this.bankId,
      //   this.companyInfo.kjzd,
      //   this.companyInfo.nsrsbm,
      //   this.actualZq,
      //   false
      // );
      // AI事务所
      const tables = await getUserTables(
        this.bankId,
        this.$route.params.version,
        sessionStorage.getItem("nsrsbh"),
        this.actualZq,
        this.uuid,
        false
      );
      await this.getbalanceInfo();

      //过滤未上传的表格
      this.tables = {};

      for (const grpName in tables.data) {
        const grpData = tables.data[grpName];
        const newGrp = [];
        for (const table of grpData.tableInfo) {
          //if (table.uploadStatus != "未上传") {
          const {
            tableNameCn,
            tableNameEn: templateId,
            uploadStatus: status,
            tableNum,
          } = table;

          const item = {
            name: `${tableNameCn}`,
            status,
            templateId,
            tableNum,
          };

          newGrp.push(item);
          //}
        }

        if (newGrp.length > 0) {
          this.tables[grpName] = {
            list: newGrp,
            totalNum: grpData.totalNum,
            uploadNum: grpData.uploadNum,
          };
        }
      }
      if (Object.keys(this.tables).length > 0) {
        this.hasItemsInList = true;
      } else {
        this.hasItemsInList = false;
      }
    },
    async onUpload(file, data) {
      //如果有错误的表格则弹出弹出框
      if (data.data.errorData.length > 0) {
        this.uploadErrors = data.data.errorData;
        this.showUploadErrorDialog();
      }

      const groups = data.data.succData;

      //根据上传的excel构建本地对应的数组

      //上传文件后需要纠错的表格信息
      const tables4correction = [];

      for (const groupName in groups) {
        const tables = groups[groupName]; //分组里包含的表

        for (const table of tables) {
          const status = table.uploadStatus;
          const { templateId, sysFileName, userFileName } = table;

          if (status === "待修改") {
            table.data.templateId = templateId;
            table.data.groupName = groupName;
            table.data.sysFileName = sysFileName;
            table.data.userFileName = userFileName;

            tables4correction.push(table.data);
          }
        }
      }
      if (tables4correction.length > 0) {
        this.$store.commit("UploadTable/setCorrectionTableData", {
          tables4correctionData: tables4correction,
        });
        this.showCorrectDialog();
      }
      this.loadList();
    },
    handleCorrectDialogClose() {
      this.loadList();
    },
  },
  watch: {
    $route: function (to, from) {
      window.scrollTo(0, 0);
      this.init();
      // 拿到目标参数 to.query.id 去再次请求数据接口
      //this.loadPageData(to.query.id);
      //this.dataPeriod;
      //debugger;
    },
  },
};
</script>

<style lang="stylus" scoped>
.modify-table-wrapper {
  margin-top: 30px;
}

.correctDialog {
  padding: 0;

  >>> .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }
}

.tipDialog {
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;

    .img {
      width: 174px;
      flex-shrink: 0;
    }

    .right-wrapper {
      margin-left: 50px;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #062340;
      }

      .desc {
        margin-top: 16px;
        color: #6b7897;
        font-size: 16px;
        line-height: 1.5;
      }

      .btn-i-get {
        height: 40px;
        line-height: 40px;
        margin-top: 17px;
      }
    }
  }
}

.the-title {
  margin: 30px 30px 30px 0;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 20px;
  padding-top: 0;
  margin-top: 16px;
}

.upload-footer {
  margin-top: 16px;

  .tip {
    float: left;
    color: #FAAD14;
    font-size: 14px;
  }

  .acknowlege, .btn-download {
    background: #fff;
    font-size: 14px;
    float: right;
    color: #2F54EB;
    vertical-align: middle;

    .icon {
      vertical-align: middle;
      font-size: 22px;
    }
  }

  .acknowlege {
    margin-right: 48px;
    cursor: pointer;
    float: right;
  }

  .acknowlege:hover, .btn-download:hover {
  }
}

.table-list {
  margin-top: 18px;
  padding-left: 24px;
}

.footer {
  margin-top: 20px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 10px;
  }

  .btn-purge-data {
    color: #ff6464;
  }
}

// >>> .el-dialog__header {
// padding: 0;
// }

// >>> .section-title {
// margin-top: 10px;
// }
.page-wrapper {
  padding-bottom: 84px;
}

.progressdis {
  width: 40%;
  // margin: 24px auto 45px;
  margin-left: 150px;
  margin-top: 2px;
}

.btn-right-none {
  margin-right: 6px !important;
}

// .report-method {
// margin: 20px;
// }
.nomargin {
  margin: 0 30px;
  padding: 0;
}

.main-top {
  display: flex;
  justify-content: space-between;
}

.content-body-flex {
  display: flex;
  align-item: center;
  justify-content: space-between;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 24px;
  background: rgba(255, 255, 255, 1);
  // padding: 0 20px;
  // margin: 0 20px;
}

.content-body-flexr {
  display: flex;
  align-item: center;
  font-size: 14px;
  color: rgba(47, 84, 235, 1);
}

.content-top-1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.content-top-2 {
  margin-right: 24px;
}

.content-body-tab {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.content-body-tabl, .content-body-tabr {
  width: 174px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 18px 18px 0px 0px;
  cursor: pointer;
}

.content-body-tabl {
  margin-right: 24px;
}

.zqLocation {
  color: #2F54EB;
}

.arrow-icon {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 28px;
}

.uploadFlex {
  display: flex;
  width: 100%;
}

.uploadFlexLeft {
  width: 25%;
  border-right: 1px solid rgba(217, 217, 217, 1);
  padding-right: 32px;
  box-sizing: border-box;
}

.uploadFlexRight {
  width: 75%;
}

.uploadFlexLefts {
  border-bottom: none;
  margin-bottom: 0;
}

.onUpload-flex {
  display: flex;
}

.onUpload-flex .onUpload-flex-item {
  flex: 1;
}

.onUpload-flex-iteml {
  margin-right: 16px;
}

.table-lists {
  margin-top: 24px;
}

.process1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.process2 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.processLImg {
  position: relative;
  bottom: 1px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.processContainer {
  display: flex;
  align-item: center;
  position: relative;
  top: 6px;
}

.process-status {
  width: 120px;
  height: 2px;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  top: 8px;
  margin: 0 24px;
}

.process-statusr {
  background: rgba(217, 217, 217, 1);
}

.mainheader {
  display: flex;
  justify-content: space-between;
}

.rightProcess {
  width: 10%;
  margin-left: 100px;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}
</style>
