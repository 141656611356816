<template>
  <div>
    <div class="z-header">
      <el-breadcrumb separator="/" class="z-breadcrumb">
        <el-breadcrumb-item>指标模型</el-breadcrumb-item>
        <el-breadcrumb-item>元数据管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="z-title">元数据管理</div>
    </div>
    <div class="z-filters">
      <div class="z-filter">
        <div class="z-inputItem">
          <div>名称：</div>
          <el-input
            v-model.trim="name"
            clearable
            placeholder="请输入"
            @input="changeName"
            @keyup.enter.native="reset"
          ></el-input>
        </div>
        <div class="z-inputItem">
          <div>添加人：</div>
          <el-input
            v-model.trim="person"
            clearable
            placeholder="请输入"
            @input="changePerson"
            @keyup.enter.native="reset"
          ></el-input>
        </div>
        <div class="z-inputItem">
          <div>创建时间：</div>
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="reset"
          >
          </el-date-picker>
        </div>
        <div class="z-inputItem z-inputItems">
          <div>目录：</div>
          <el-cascader
            :options="getTreeList"
            :props="{ checkStrictly: true, expandTrigger: 'hover' }"
            clearable
            v-model="menu"
            @change="reset"
            ref="refHandle"
          ></el-cascader>
        </div>
      </div>
      <div class="z-search">
        <div class="z-searchBtn ease" @click="reset">搜索</div>
      </div>
    </div>
    <div class="z-addItem ease" @click="addTarget">添加元数据</div>
    <div class="z-table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column width="14"> </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          min-width="8%"
          sortable
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="metaName"
          label="名称"
          min-width="14%"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="catalogName"
          label="目录"
          min-width="12%"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="metaTableName"
          label="元数据表"
          min-width="16%"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="metaTableField"
          label="表字段"
          min-width="16%"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="createUserName"
          label="添加人"
          min-width="6%"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="address" label="操作" width="110">
          <template slot-scope="scope">
            <span class="z-table-show" @click="toDetail(scope.row.metaId)"
              >查看</span
            >
            <span
              class="z-table-delete"
              @click="toDelete(scope.row.metaId, scope.row)"
              >删除</span
            >
          </template>
        </el-table-column>
        <el-table-column width="14"> </el-table-column>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageno"
        layout="total, prev, pager, next, jumper"
        :total="totalPages"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getDataList,
  getMenu,
  deleteMeta,
  findPfMetaDataOccupy,
} from "@/api/editor";
import { formatDateTes } from "./help.js";
import { transformation } from "@/utils/constant";

export default {
  data() {
    return {
      showDetail: false,
      dialogTableVisible: false,
      name: "",
      person: "",
      time: "",
      menu: "",
      tableData: null,
      pageno: 1,
      totalPages: 100,
      getTreeList: null,
    };
  },
  mounted() {
    this.getList();
    this.getTree();
  },
  methods: {
    getTree() {
      let params = {
        catalogType: "PF.YSJ",
        catalogSelectUse: false,
      };
      getMenu(params).then((res) => {
        if (res.msgCode == 1) {
          this.getTreeList = res.data;
          if (!this.getTreeList || this.getTreeList.length < 1) return;
          this.getTreeList = transformation(this.getTreeList);
        }
      });
    },
    getList() {
      let params = {
        catalogId: this.menu[this.menu.length - 1],
        createUserName: this.person,
        endCreateTime: this.time ? this.time[1] : "",
        metaName: this.name,
        pageNo: this.pageno,
        pageSize: 10,
        startCreateTime: this.time ? this.time[0] : "",
      };
      getDataList(params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.list;
          this.totalPages = res.pageTotal;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].createTime = formatDateTes(
              this.tableData[i].createTime
            );
          }
        }
      });
    },
    deleteMeta(id) {
      let params = {
        metaId: id,
      };
      deleteMeta(params).then((res) => {
        if (res.msgCode == 1) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.pageno = 1;
          this.getList();
        }
      });
    },
    toDelete(id, data) {
      let params = {
        entityType: "PF.YSJ",
        metaId: id,
      };
      findPfMetaDataOccupy(params).then((res) => {
        if (res.msgCode == 1) {
          if (!res.data.occupy) {
            this.$confirm("是否删除该元数据?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.deleteMeta(id);
              })
              .catch(() => {});
          } else {
            this.$alert(
              "当前指标正在被" +
                res.data.occupyZBSize +
                "个指标使用," +
                res.data.occupyMXSize +
                "个模型使用",
              "温馨提示",
              {
                confirmButtonText: "知道了",
                callback: (action) => {},
              }
            );
          }
        }
      });
    },
    hiddenDetail() {
      this.showDetail = false;
    },
    toDetail(id) {
      this.$router.push({
        path: "/editor/menuDataDetail",
        query: {
          id: id,
        },
      });
    },
    addTarget() {
      this.$router.push({ path: "/editor/menuDataAdd" });
    },
    reset() {
      this.pageno = 1;
      this.getList();
    },
    changeName() {
      if (!this.name) {
        this.reset();
      }
    },
    changePerson() {
      if (!this.person) {
        this.reset();
      }
    },
    handleCurrentChange(val) {
      this.pageno = val;
      this.getList();
    },
  },
  watch: {
    menu() {
      if (this.$refs.refHandle) {
        this.$refs.refHandle.dropDownVisible = false; //监听值发生变化就关闭它
      }
    },
  },
};
</script>

<style scoped lang="stylus">
@import './common.css';

>>>.z-filters .el-input__inner {
  width: 240px;
  height: 36px;
  line-height: 36px;
}

>>>.z-filters .el-input {
  width: 240px;
  height: 36px;
  line-height: 36px;
}

>>>.z-filters .el-input__icon {
  line-height: 30px;
}

.z-inputItems >>>.el-input__icon {
  line-height: 36px;
}

>>>.z-filters .el-date-editor .el-range-separator {
  line-height: 30px;
}

>>>.el-date-editor .el-range__close-icon {
  width: 10px;
}

>>>.el-cascader {
  line-height: 36px;
}
</style>