<template>
  <div>
    <div class="z-header">
      <el-breadcrumb separator="/" class="z-breadcrumb">
        <el-breadcrumb-item>指标模型</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/editor/target' }">指标管理</el-breadcrumb-item>
        <el-breadcrumb-item>新增指标</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="z-title">新增指标</div>
    </div>
    <div class="z-detail">
      <div class="z-detail-title">基础信息</div>
      <div class="z-detail-items z-detail-flex">
        <div class="z-detail-left">
          <div class="z-detail-item">
            <div class="z-detail-name">名称：</div>
            <el-input
              v-model.trim="editor.name"
              placeholder="请输入内容"
              maxlength="60"
              show-word-limit
            ></el-input>
          </div>
          <div class="z-detail-item">
            <div class="z-detail-name">目录：</div>
            <el-cascader
              :options="getTreeList"
              :props="{ checkStrictly: true, expandTrigger: 'hover' }"
              clearable
              v-model="editor.menu"
              ref="refHandle"
            ></el-cascader>
            <div class="z-edit" @click="showAddMenu = true">+新增</div>
          </div>
          <div class="z-detail-item">
            <div class="z-detail-name">适用范围：</div>
            <el-checkbox-group v-model="editor.scope" @change="checkChange">
              <el-checkbox label="本期"></el-checkbox>
              <el-checkbox label="上期"></el-checkbox>
              <el-checkbox label="上上期"></el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="z-detail-left">
          <div class="z-detail-item z-detail-itemH">
            <div class="z-detail-name">类型：</div>
            <div>数值型</div>
            <!-- <el-select v-model="editor.type" placeholder="请选择">
              <el-option
                v-for="item in typeData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
          </div>
          <div class="z-detail-item z-detail-itemH">
            <div class="z-detail-name">添加人：</div>
            <div>{{ realName }}</div>
          </div>
          <div class="z-detail-itemF">
            <div class="z-detail-name">描述：</div>
            <el-input
              v-model.trim="editor.short"
              type="textarea"
              maxlength="100"
              show-word-limit
              placeholder="请输入"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="z-detail">
      <div class="z-detail-title">
        指标编辑
        <span class="z-detail-icon" @click="addTargetData">
          <i class="el-icon-edit-outline"></i>编辑
        </span>
      </div>
      <div class="z-detail-items">
        <div class="z-detail-index" v-for="(item, index) in indexList" :key="index">
          {{ item }}
        </div>
      </div>
    </div>
    <div class="z-detail z-detail-bottom">
      <div class="z-detail-title">
        特殊情况处理
        <span class="z-detail-icon" @click="addDefault">
          <i class="el-icon-edit-outline"></i>编辑
        </span>
      </div>
      <div class="z-detail-items">
        <div v-if="indexListBans.length > 0" class="z-detail-index">
          <span v-for="(item, index) in indexListBans" :key="index">
            {{
              item.name == " && "
                ? " and "
                : item.name && item.name == " || "
                ? " or "
                : item.name
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="z-editorDeital-footer">
      <div class="z-editorDeital-editBtn ease btn-right" @click="publish" data-index="1">
        发布
      </div>
      <div
        class="z-editorDeital-cancelBtn ease btn-right"
        @click="publish"
        data-index="2"
      >
        存为草稿
      </div>
    </div>
    <!-- 创建目录 -->
    <transition name="fades">
      <div class="containerMask" v-if="showAddMenu" @click="showAddMenu = false"></div>
    </transition>
    <transition name="fadee">
      <add-menu
        v-if="showAddMenu"
        @closeAddBox="closeMenuBox"
        @addSuccess="addSuccess"
        :addMenuType="1"
      ></add-menu>
    </transition>
    <!-- 创建指标 -->
    <transition name="fades">
      <div
        class="containerMask"
        v-show="showAddTarget"
        @click="showAddTarget = false"
      ></div>
    </transition>
    <transition name="fadee">
      <add-target
        v-show="showAddTarget"
        @closeAddBox="closeAddBoxs"
        :itemListData="itemListDatas"
        :addName="addName"
        @saveItem="saveItem"
        :typeAddType="addType"
        @saveAbnItem="saveAbnItem"
      ></add-target>
    </transition>
  </div>
</template>

<script>
import addMenu from "@/pages/metadata/addmenu";
import addTarget from "./addTarget";
import { itemList, itemListBan } from "./data.js";
import { typeData, transformation, checkRequiredData } from "@/utils/constant";
import { getMenu, addIndex, addReleaseIndex } from "@/api/editor";

export default {
  components: {
    addMenu,
    addTarget,
  },
  data() {
    return {
      showAddMenu: false,
      indexList: [],
      indexListBan: [],
      indexListBans: [],
      editor: {
        scope: ["本期"],
        type: "0",
      },
      getTreeList: null,
      addName: "指标编辑",
      itemListData: itemList,
      itemListDatas: null,
      dataType: "PF.YSJ",
      showAddTarget: false,
      typeData: typeData,
      composite: false,
      compositeBoolean: [false, true, true, true],
      realName: JSON.parse(localStorage.getItem("user")).realName,
      relations: [],
      relationsAbn: [],
      composites: [],
      exceptions: [],
      expressionAbn: [],
      expression: "",
      scopeList: ["上期", "上上期"],
      addType: 0,
      detaList: null,
      exceptionList: null,
      publishType: 1,
      needCreate: [],
    };
  },
  mounted() {
    this.getTree();
  },
  methods: {
    checkChange() {
      this.checkPeriod(this.editor.scope);
      this.composite = this.compositeBoolean[this.editor.scope.length - 1];
    },
    // 组合适用范围参数
    checkPeriod(data) {
      if (data.length == 3) {
        this.composites = [
          {
            code: "",
            compositeType: "SQ", //
            compositeCount: 1, //
            expression: this.expression,
            name: this.editor.name + "(上期)",
            relations: this.relations,
            showText: this.indexList[0],
          },
          {
            code: "",
            compositeType: "SQ",
            compositeCount: 2,
            expression: this.expression,
            name: this.editor.name + "(上上期)",
            relations: this.relations,
            showText: this.indexList[0],
          },
        ];
      }
      if (data.length == 2 && this.scopeList[0] == data[1]) {
        this.composites = [
          {
            code: "",
            compositeType: "SQ",
            compositeCount: 1,
            expression: this.expression,
            name: this.editor.name + "(上期)",
            relations: this.relations,
            showText: this.indexList[0],
          },
        ];
      }
      if (data.length == 2 && this.scopeList[1] == data[1]) {
        this.composites = [
          {
            code: "",
            compositeType: "SQ",
            compositeCount: 2,
            expression: this.expression,
            name: this.editor.name + "(上上期)",
            relations: this.relations,
            showText: this.indexList[0],
          },
        ];
      }
    },
    // 新增异常情况
    saveAbnItem(item) {
      this.indexListBan[0] = item.formulaItemAbn;
      this.indexListBans = item.dataListAbn;
      this.relationsAbn = item.relationsAbn;
      this.expressionAbn = item.expressionAbn.join("");
      this.showAddTarget = false;
      this.exceptions = [
        {
          condition: {
            expression: this.expressionAbn,
            relations: this.relationsAbn,
            showText: this.indexListBan[0],
          },
          value: {},
        },
      ];
      this.detaList = item.dataList;
      this.exceptionList = item.dataListAbn;
    },
    // 新增指标
    saveItem(item) {
      this.indexList[0] = item.formulaItem;
      this.relations = item.relations;
      this.expression = item.expression.join("");
      this.showAddTarget = false;
      this.detaList = item.dataList;
      this.exceptionList = item.dataListAbn;
    },
    // 发布或存为草稿
    publish(e) {
      this.publishType = e.target.dataset.index;
      if (this.publishType == 1) {
        if (!this.checkRequired()) return;
      } else if (!this.editor.name) {
        this.$message({
          message: "存为草稿时，名称不能为空！",
          type: "warning",
        });
        return;
      }
      if (this.editor.scope.length < 1) {
        this.$message({
          message: "适用范围不能为空！",
          type: "warning",
        });
        return;
      }

      let arr = [];
      let arr2 = {
        本期: 1,
        上期: 2,
        上上期: 3,
      };
      for (var item in this.editor.scope) {
        arr[item] = arr2[this.editor.scope[item]];
      }

      this.checkPeriod(this.editor.scope);
      let params = {
        catalog: this.editor.menu && this.editor.menu.toString(),
        catalogId: this.editor.menu && this.editor.menu[this.editor.menu.length - 1],
        code: "", //指标ID
        needCreate: arr,
        composite: arr.toString() == 1 ? false : true, //只有本期传false
        dataType: this.editor.type, //基础信息类型
        zbProperty: {
          // composites: this.composites,
          exceptions: this.exceptions,
          formula: {
            expression: this.expression,
            relations: this.relations,
            showText: this.indexList[0],
          },
        },
        entityType: "PF.ZB", //元数据还是指标添加的
        list: this.detaList,
        exceptionList: this.exceptionList,
        metaDescription: this.editor.short, //基础信息描述
        name: this.editor.name, //基础信息指标名称
        relations: this.relations,
        userId: JSON.parse(localStorage.getItem("user")).id, //	创建或修改人员id
        userName: this.realName, //	创建或修改人员名称
      };
      if (this.exceptions.length == 0 || !this.exceptions[0].condition.expression) {
        params.zbProperty.exceptions = [];
      }
      console.log(params);
      if (this.publishType == 1) {
        addReleaseIndex(params).then((res) => {
          if (res.msgCode == 1) {
            this.$message({
              type: "success",
              message: "新增指标成功!",
            });
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          } else {
            this.$message({
              type: "error",
              message: res.msgInfo,
            });
          }
        });
      } else {
        addIndex(params).then((res) => {
          if (res.msgCode == 1) {
            this.$message({
              type: "success",
              message: "存为草稿成功!",
            });
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          } else {
            this.$message({
              type: "error",
              message: res.msgInfo,
            });
          }
        });
      }
    },
    // 获取三级目录树
    getTree() {
      let params = {
        catalogType: "PF.ZB",
        catalogSelectUse: false,
      };
      getMenu(params).then((res) => {
        if (res.msgCode == 1) {
          this.getTreeList = res.data;
          if (!this.getTreeList || this.getTreeList.length < 1) return;
          this.getTreeList = transformation(this.getTreeList);
        }
      });
    },
    // 添加指标
    addTargetData() {
      this.addType = 0;
      this.addName = "指标编辑";
      this.itemListDatas = itemList;
      this.showAddTarget = true;
    },
    // 添加指标特殊情况
    addDefault() {
      this.addType = 1;
      this.addName = "特殊情况处理";
      this.itemListDatas = itemListBan;
      this.showAddTarget = true;
    },
    // 添加目录成功
    addSuccess() {
      this.getTree();
      this.showAddMenu = false;
    },
    // 关闭目录弹窗
    closeMenuBox() {
      this.showAddMenu = false;
    },
    // 关闭新增指标弹窗
    closeAddBoxs() {
      this.showAddTarget = false;
    },
    checkRequired() {
      return checkRequiredData(this.editor, this.indexList);
    },
  },
  watch: {
    editor: {
      handler() {
        if (this.$refs.refHandle) {
          this.$refs.refHandle.dropDownVisible = false; //监听值发生变化就关闭它
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="stylus">
.z-detail {
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 16px 30px 0 30px;
}

.z-detail-title {
  height: 54px;
  line-height: 54px;
  padding-left: 24px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #eeeeee;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.z-detail-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  margin-left: 24px;
  margin-top: 16px;
}

.z-detail-target {
  margin-top: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  margin-left: 24px;
}

.z-detail-un {
  margin-top: 2px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  margin-left: 24px;
}

.z-detail-target24, .z-detail-un24 {
  margin-top: 16px;
}

.z-detail-items {
  padding: 8px 0 24px 0;
  color: rgba(0, 0, 0, 0.6);
}

.z-detail-name {
  color: rgba(0, 0, 0, 0.4);
}

.z-detail-left {
  width: 50%;
}

.z-detail-flex {
  display: flex;
}

.z-detail-bottom {
  margin-bottom: 100px;
}

.z-detail >>> .el-input__inner {
  height: 36px;
  line-height: 36px;
  width: 400px;
}

.z-detail >>> .el-input {
  width: 400px;
}

.z-detail >>> .el-input__icon {
  line-height: 36px;
}

.z-detail >>> .el-checkbox-group {
  font-size: inherit;
}

.z-detail >>> .el-checkbox {
  color: rgba(0, 0, 0, 0.6);
}

.z-detail >>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #2f54eb;
}

.z-detail >>> .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #2f54eb;
  border-color: #2f54eb;
}

.z-edit {
  font-size: 14px;
  color: #2f54eb;
  margin-left: 8px;
  cursor: pointer;
}

.z-detail-icon {
  font-size: 14px;
  color: #2f54eb;
  line-height: 20px;
  margin-left: 8px;
  cursor: pointer;
}

.el-icon-edit-outline {
  margin-right: 4px;
}

.z-detail-index {
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  margin: 8px 0 0 24px;
}

>>>.el-textarea {
  width: 400px;
}

.z-detail-itemH {
  line-height: 36px;
}

.z-detail-itemF {
  display: flex;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  margin-left: 24px;
  margin-top: 16px;
}
</style>
