import "./style.styl";
//import "animate.css/animate.css";

//表单校验器
import { ValidationProvider, extend,ValidationObserver } from "vee-validate";
import { required,email,max } from "vee-validate/dist/rules";

import { configure } from "vee-validate";

configure({
  classes: {
    valid: "validation-is-valid",
    invalid: "validation-is-invalid ",
    dirty: ["is-dirty", "is-dirty"] // multiple classes per flag!
    // ...
  }
});

extend("required", {
  ...required,
  message: "{_field_}不能为空"
});

extend("max", {
  ...max,
});
extend("taxpayerID", {
  validate: value => {
    return (
      value.toString().length >= 15 &&
      value.toString().length <= 20 &&
      /^[\da-zA-Z]+$/.test(value)
    );
  },
  message: "{_field_}最低15位最多20位数字+字母组合"
});

extend("mobile", {
  validate: value => {
    return (
      /^1[\d]{10}$/.test(value)
    );
  },
  message: "请输入正确的手机号码"
});

extend("email", email);

extend("url", {
  validate: value => {
    return (
      /(((http|ftp|https|file):)?\/\/([\w\-]+\.)+[\w\-]+(\/[\w\u4e00-\u9fa5\-\.\/?\@\%\!\&=\+\~\:\#\;\,]*)?)/ig.test(value)
    );
  },
  message: "请输入正确的URL"
});

export { ValidationProvider,ValidationObserver };
