<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>帮助中心</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">帮助中心</template>
      </main-header>
    </div>
    <div class="title" style="height: calc(100vh - 197px)">
      <div class="content-left">
        <div class="demo-input-suffix">
          <el-input
            placeholder="请输入问题"
            prefix-icon="el-icon-search"
            v-model="problem"
            clearable
            @blur="loadData"
            @clear="loadData"
          >
          </el-input>
        </div>
        <div class="contentItem">
          <div style="overflow: auto" v-for="item in tableDataList" :key="item.id">
            <div class="itemTitle">{{ item.directory }}</div>
            <div
              v-for="items in item.helpNewInfoResults"
              :key="items.id"
              @click="clickDetail(items.id)"
            >
              <div class="itemData">{{ items.title }}:{{ items.contentInfo }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="tableDetail-title">{{ tableDetail.title }}</div>
        <div class="tableDetail-createTime">发布时间：{{ tableDetail.createTime }}</div>
        <div class="tableDetail-contentInfo">
          {{ tableDetail.contentInfo }}
        </div>
        <!-- <video controls id="myVideo" autoplay>
          <source :src="tableDetail.externalLinks" type="video/mp4" autoplay />
        </video> -->
        <img
          v-if="isShowImg"
          border="0"
          id="myVideo"
          :src="tableDetail.externalLinks"
          width="160"
          height="100"
        />
        <video
          controls
          id="myVideo"
          v-if="isShowVideo"
          else
          autoplay
          :src="tableDetail.externalLinks"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import { getInfoApi, getListApi } from "@/api/api";
export default {
  components: {
    MainHeader,
    BreadCrumb,
  },
  async mounted() {
    await this.loadData();
    await this.clickDetail();
  },
  data() {
    return {
      tableDataList: {},
      problem: "",
      tableDetail: {},
      tableDataListId: null,
      isShowImg: true,
      isShowVideo: true,
    };
  },
  methods: {
    async loadData() {
      const res = await getListApi(this.problem);
      this.tableDataList = res.data;
      this.tableDataListId = res.data[0].helpNewInfoResults[0].id;
    },

    async clickDetail(id) {
      const res3 = await getInfoApi(id ? id : this.tableDataListId);
      if (res3.code == 200) {
        this.tableDetail = res3.data;
        let videos = document.getElementById("myVideo");
        if (this.tableDetail.externalLinks) {
          videos.src = this.tableDetail.externalLinks;
          let reg = /(.*)\.(jpg|bmp|gif|ico|pcx|jpeg|tif|png|raw|tga)$/;
          if (reg.test(videos.src)) {
            this.isShowVideo = false;
            this.isShowImg = true;
          } else {
            this.isShowImg = false;
            this.isShowVideo = true;
          }
        }
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
 .title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px -1px 0px 0px #EEEEEE;
}
.title{
  display: flex;
  justify-content: space-between
  margin-bottom:24px;
  //background: #dedede;
  margin:20px ;

 }
.content-left {
  width:400px;
  //height:100%;;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  margin-right: 24px;
  display: flex;
  flex-direction: column
  padding 16px;
}
.contentItem{
  height 100%
  overflow: auto;

  .itemTitle{
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: rgba(0,0,0,0.8);
    line-height: 24px;
    margin: 8px 0;
    width: 360px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .itemData{
    //height: 42px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: rgba(0,0,0,0.65);
    line-height: 26px;
  }
}
.itemData:hover{
  text-decorate:underline
  cursor pointer
}
.content-body {
  width:calc(100vw - 300px);
  //height:100%;;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: column
  padding 40px
  overflow: auto;
  .tableDetail-title{
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: rgba(0,0,0,0.8);
    line-height: 30px;
    text-align: center
    margin 0px auto
  }
  .tableDetail-createTime{
    text-align: center
    margin 17px auto
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: rgba(0,0,0,0.5);
    line-height: 21px;
  }
  .tableDetail-contentInfo{
    font-size: 15px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    color: rgba(0,0,0,0.5);
    line-height: 26px;
  }
  #myVideo{
    width: 100%;
    height: 500px;
    margin-top: 20px;
  }
}
.demo-input-suffix{
  margin-bottom: 8px
}
</style>
