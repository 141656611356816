<template>
  <div>
    <el-dialog
      :visible.sync="checkParts"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      id="supple"
      :show-close="false"
    >
      <div class="part-check font-600">
        <span>温馨提示</span>
        <span class="title-font">系统检测到您未填写相关信息，请完善后继续</span>
        <img class="part-close" src="@/assets/test/part-close.png" alt @click="cancel" />
      </div>
      <div class="part-icon">
        <div class="box box1 box-bottom" v-if="code == 9996 || code == 9998">
          <div class="qyname">会计制度：</div>
          <div class="name">
            <el-select v-model="zhidu" placeholder="请选择">
              <el-option
                v-for="item in kjzd"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="box box1 box-bottom" v-if="code == 9997 || code == 9998">
          <div class="qyname">所属1级行业：</div>
          <div class="name">
            <el-select v-model="hangye" placeholder="请选择" filterable @change="changeHy">
              <el-option
                v-for="item in hyList"
                :key="item.hyDm"
                :label="item.hyMc"
                :value="item.hyDm"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="box box1 box-bottom" v-if="code == 9997 || code == 9998">
          <div class="qyname">所属2级行业：</div>
          <div class="name">
            <el-select v-model="morehy" placeholder="请选择，支持模糊搜索" filterable>
              <el-option
                v-for="item in hyList2"
                :key="item.hyDm"
                :label="item.dmMc"
                :value="item.hyDm"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="part-btnC">
        <div class="part-btn ease" @click="sumbit">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { saveCompanyInfo, getHy, dmHyMore } from "@/api/api";
export default {
  data() {
    return {
      hangye: "",
      morehy: "",
      zhidu: "",
      hyList: [],
      hyList2: [],
      kjzd: [
        { value: "qykjzd", label: "企业会计制度" },
        { value: "qykjzz", label: "企业会计准则" },
        { value: "xqykjzz", label: "小企业会计准则" }
      ]
    };
  },
  props: ["code", "checkParts"],
  created() {
    // this.gethy();
  },
  methods: {
    dmHymore() {
      let params = {
        hy: this.hangye,
        hycode: ""
      };
      dmHyMore(params).then(res => {
        this.hyList2 = res.data;
      });
    },
    gethy() {
      getHy().then(res => {
        this.hyList = res.data;
      });
    },
    changeHy() {
      this.dmHymore();
    },
    sumbit() {
      if (this.code == 9997 && !this.morehy) {
        this.$message.error({
          message: "二级行业不能为空！"
        });
        return;
      }
      if (this.code == 9996 && !this.zhidu) {
        this.$message.error({
          message: "会计制度不能为空！"
        });
        return;
      }
      if (this.code == 9998 && !this.zhidu) {
        this.$message.error({
          message: "会计制度不能为空！"
        });
        return;
      }
      if (this.code == 9998 && !this.morehy) {
        this.$message.error({
          message: "二级行业不能为空！"
        });
        return;
      }
      // 保存企业信息
      if (this.code == 9996) {
        let token = JSON.parse(localStorage.getItem("token")).token;
        let companyCode = JSON.parse(localStorage.getItem("user")).companyId;
        let params = {
          companyId: companyCode,
          kjzd: this.zhidu,
          userToken: token
        };
        this.saveCompany(params);
      }
      if (this.code == 9997) {
        let token = JSON.parse(localStorage.getItem("token")).token;
        let companyCode = JSON.parse(localStorage.getItem("user")).companyId;
        let params = {
          companyId: companyCode,
          hyDm: this.morehy,
          userToken: token
        };
        this.saveCompany(params);
      }
      if (this.code == 9998) {
        let token = JSON.parse(localStorage.getItem("token")).token;
        let companyCode = JSON.parse(localStorage.getItem("user")).companyId;
        let params = {
          companyId: companyCode,
          kjzd: this.zhidu,
          hyDm: this.morehy,
          userToken: token
        };
        this.saveCompany(params);
      }
      this.$emit("submit", this.checkParts);
    },
    cancel() {
      this.$emit("cancel", this.checkParts);
    },
    saveCompany(params) {
      saveCompanyInfo(params).then(res => {
        if (res.msgCode == 1) {
          this.$message.success({
            message: "补全企业信息成功!"
          });
        } else {
          this.$message.error({
            message: res.msgInfo
          });
        }
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
>>> #supple .el-dialog {
  width: 480px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

>>> #supple .el-dialog__header {
  padding: 0;
}

>>> #supple .el-dialog__body {
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.part-check {
  width: 100%;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid #eeeeee;
  padding-left: 24px;
  box-sizing: border-box;
  align-item: center;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

.part-close {
  position: relative;
  top: 18px;
  float: right;
  padding-right: 24px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.part-icon {
  text-align: center;
  border-bottom: 1px solid #eeeeee;
  box-sizing: border-box;
  padding-left: 50px;
  padding-top: 32px;
  padding-bottom: 16px;
}

.box {
  display: flex;
}

.part-btn {
  position: relative;
  right: 24px;
  top: 16px;
  width: 76px;
  height: 36px;
  line-height: 36px;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  float: right;
  cursor: pointer;
}

.part-btn:hover {
  background: rgba(47, 60, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.title-font {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 8px;
}

.qyname {
  width: 100px;
  line-height: 40px;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
}

.el-select {
  width: 284px;
}

.part-btnC {
  height: 68px;
}

.box-bottom {
  margin-bottom: 16px;
}
</style>