<template>
  <div class="page-title-wrapper">
    <div class="page-title">
      <slot name="title"></slot>
    </div>
    <div class="page-desc" v-if="$slots.desc">
      <slot name="desc"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page-title-wrapper {
  padding: 0px 30px 0;
  
  .page-title {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 28px;
  }
  .page-desc {
    margin-top: 8px;
    font-size: 14px;
    color: #999;
    line-height: 20px;
  }
}
</style>