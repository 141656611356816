<template>
  <div class="container">
    <div class="header">
      <div class="title">项目概况</div>
      <div class="select"></div>
    </div>
    <div class="content" v-if="list">
      <div class="item">项目名称：{{list.projectName}}</div>
      <div class="item">项目地址：{{list.projectArea}}</div>
      <div class="item">开发商：{{list.consumerName}}</div>
      <div class="item item-last">开发商纳税人识别号：{{list.taxNo}}</div>
    </div>
  </div>
</template>

<script>
import { homePageGetInfo } from "@/api/fri";

export default {
  data() {
    return {
      list: null,
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      let projectId = this.$route.query.id;
      homePageGetInfo(projectId).then((res) => {
        this.list = res.data;
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.container {
  background: rgba(255, 255, 255, 1);
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding-bottom: 20px;
  min-height: 90px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 54px;
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid rgba(218, 213, 232, 0.6);

    .title {
      font-size: 18px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      margin-left: 24px;
    }

    .select {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .content {
    display: flex;
    align-items: center;
    padding: 16px 0 0 24px;
    color: rgba(0,0,0,0.65);

    .item {
      margin-right: 30px;
    }

    .item-last {
      margin-right: 0;
    }
  }
}
</style>
