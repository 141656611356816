var render = function render(){var _vm=this,_c=_vm._self._c;return _c('scroll-bar',[_c('router-link',{staticClass:"logo",attrs:{"to":{ name: 'homePage' }}},[(_vm.icon)?_c('img',{staticClass:"logo-pic",attrs:{"src":_vm.icon}}):_vm._e()]),_c('div',{staticClass:"sider-bar-menu-wrapper"},[_c('div',{staticClass:"scrollbar-inner"},[_vm._l((_vm.asideMenuConfig),function(item,index){return [(!item.children && _vm.menuLists[item.id])?_c('div',{key:index,staticClass:"item",class:{
            actived: !!item.routeNames && item.routeNames.includes(_vm.$route.name),
          }},[_c('router-link',{attrs:{"to":item.path}},[_c('i',{staticClass:"icon",class:item.icon}),_vm._v(" "+_vm._s(item.name)+" ")])],1):(item.children)?_c('div',{key:index,staticClass:"group"},[(_vm.menuLists[item.id])?_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]):_vm._e(),_vm._l((item.children),function(item2,index2){return [(_vm.menuLists[item2.id])?_c('div',{key:index2,staticClass:"item",class:{
                actived: !!item2.routeNames && item2.routeNames.includes(_vm.$route.name),
              },attrs:{"data-index":item2.id}},[(item2.path != '/test/products')?_c('router-link',{attrs:{"to":item2.path}},[_c('i',{staticClass:"icon",class:item2.icon}),_vm._v(" "+_vm._s(item2.name)+" ")]):_c('span',{staticClass:"item-btn",on:{"click":_vm.add}},[_c('i',{staticClass:"icon",class:item2.icon}),_vm._v(" "+_vm._s(item2.name)+" ")])],1):_vm._e()]})],2):(item.id == '1')?_c('div',{key:index,staticClass:"item",class:{
            actived: !!item.routeNames && item.routeNames.includes(_vm.$route.name),
          }},[_c('router-link',{attrs:{"to":item.path}},[_c('i',{staticClass:"icon",class:item.icon}),_vm._v(" "+_vm._s(item.name)+" ")])],1):_vm._e()]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }