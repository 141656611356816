<template>
  <div>
    <bread-crumb>
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>个税计算器</el-breadcrumb-item>
    </bread-crumb>
    <div class="title">个税计算器</div>
    <div class="counter flex-space">
      <div class="counter-item">
        <div class="counter-item-t">
          <div class="block-t">
            <span class="demonstration">所在城市：</span>
            <el-cascader
              v-model="city"
              :options="options"
              :props="{ expandTrigger: 'hover' }"
              @change="handleChange"
              filterable
              clearable
              separator="-"
              placeholder="浙江省-杭州"
            ></el-cascader>
          </div>
          <div class="block-b">
            <span>税前工资：</span>
            <div class="block-append">
              <el-input
                placeholder="请输入税前工资"
                v-model="salary"
                oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
              >
                <template slot="append">元</template>
              </el-input>
            </div>
          </div>
        </div>
        <div class="counter-item-b">
          <div class="item-t-container">
            <div class="item-title item-title1" :class="{act: isAct==1}" @click="isAct=1">社保公积金</div>
            <div class="item-title item-title2" :class="{act: isAct==2}" @click="isAct=2">专项扣除</div>
          </div>
          <div v-show="isAct==1" class="counter-center">
            <div class="flex-end input-item">
              <span>社保基数：</span>
              <div class="block-append-b">
                <el-input
                  placeholder="默认与工资一样"
                  v-model="socialbase"
                  @input="socialbaseC"
                  oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                >
                  <template slot="append">元</template>
                </el-input>
              </div>
            </div>
            <div class="flex-end input-item">
              <span>公积金基数：</span>
              <div class="block-append-b">
                <el-input
                  placeholder="默认与工资一样"
                  v-model="salarybase"
                  @input="salarybaseC"
                  oninput="value=value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                >
                  <template slot="append">元</template>
                </el-input>
              </div>
            </div>
            <div class="flex-end input-item">
              <span>养老：</span>
              <div class="block-append-b">
                <el-input
                  v-model="rate1"
                  placeholder="请输入内容"
                  oninput="value=value.replace(/[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                  @input="rate1C"
                >
                  <template slot="append">%</template>
                </el-input>
              </div>
            </div>
            <div class="flex-end input-item">
              <span>医疗：</span>
              <div class="block-append-b">
                <el-input
                  v-model="rate2"
                  placeholder="请输入内容"
                  oninput="value=value.replace(/[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                  @input="rate2C"
                >
                  <template slot="append">%</template>
                </el-input>
              </div>
            </div>
            <div class="flex-end input-item">
              <span>失业：</span>
              <div class="block-append-b">
                <el-input
                  v-model="rate3"
                  placeholder="请输入内容"
                  oninput="value=value.replace(/[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                  @input="rate3C"
                >
                  <template slot="append">%</template>
                </el-input>
              </div>
            </div>
            <!-- <div class="flex-end input-item">
              <span>工商：</span>
              <div class="block-append-b">
                <el-input
                  v-model="rate4"
                  placeholder="请输入内容"
                  oninput="value=value.replace(/[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                  @input="rate4C"
                >
                  <template slot="append">%</template>
                </el-input>
              </div>
            </div>
            <div class="flex-end input-item">
              <span>生育：</span>
              <div class="block-append-b">
                <el-input
                  v-model="rate5"
                  placeholder="请输入内容"
                  oninput="value=value.replace(/[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                  @input="rate5C"
                >
                  <template slot="append">%</template>
                </el-input>
              </div>
            </div>-->
            <div class="flex-end input-item">
              <span>公积金：</span>
              <div class="block-append-b">
                <el-input
                  v-model="rate6"
                  placeholder="请输入内容"
                  @change="rateChange6"
                  oninput="value=value.replace(/[^0-9]{0,1}(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                  @input="rate6C"
                >
                  <template slot="append">%</template>
                </el-input>
              </div>
            </div>
          </div>
          <div v-show="isAct==2">
            <div class="block-t block-dis">
              <span class="demonstration">住房租金：</span>
              <el-cascader
                v-model="discount11"
                :options="options1"
                :props="{ expandTrigger: 'hover' }"
                @change="handleChange1"
                filterable
                clearable
                separator="-"
                placeholder="无住房租金专项"
              ></el-cascader>
            </div>
            <div class="block-t block-dis">
              <span class="demonstration">首套房贷：</span>
              <el-cascader
                v-model="discount12"
                :options="options2"
                :props="{ expandTrigger: 'hover' }"
                @change="handleChange2"
                filterable
                clearable
                separator="-"
                placeholder="无首套房贷专项"
              ></el-cascader>
            </div>
            <div class="block-t block-dis" title="选择扣除方式和子女数量">
              <span class="demonstration">子女教育：</span>
              <el-cascader
                v-model="discount13"
                :options="options3"
                :props="{ expandTrigger: 'hover' }"
                @change="handleChange3"
                filterable
                clearable
                separator="-"
                placeholder="无子女教育专项"
              ></el-cascader>
            </div>
            <div class="block-t block-dis">
              <span class="demonstration">继续教育：</span>
              <el-cascader
                v-model="discount14"
                :options="options4"
                :props="{ expandTrigger: 'hover' }"
                @change="handleChange4"
                filterable
                clearable
                separator="-"
                placeholder="无继续教育专项"
              ></el-cascader>
            </div>
            <div class="block-t block-dis">
              <span class="demonstration">赡养老人：</span>
              <el-cascader
                v-model="discount15"
                :options="options5"
                :props="{ expandTrigger: 'hover' }"
                @change="handleChange5"
                filterable
                clearable
                separator="-"
                placeholder="无赡养老人专项"
              ></el-cascader>
            </div>
            <div class="block-t block-dis">
              <span class="demonstration">大病医疗：</span>
              <el-cascader
                v-model="discount16"
                :options="options6"
                :props="{ expandTrigger: 'hover' }"
                @change="handleChange6"
                filterable
                clearable
                separator="-"
                placeholder="无大病医疗扣除项"
              ></el-cascader>
              <transition name="slide-fade">
                <div class="block-append block-appends" v-show="haveMedical">
                  <el-input placeholder="超出金额" v-model="medical" @change="priceChange">
                    <template slot="append">元</template>
                  </el-input>
                </div>
              </transition>
            </div>
          </div>
          <div class="item-btn ease" @click="calculate">马上计算</div>
        </div>
      </div>
      <div class="counter-item" v-show="resultPage">
        <div class="counter-data">
          <div class="circle-container">
            <div class="circle" id="circle"></div>
            <div class="circle-bottom">
              <div class="circle-text">
                <span class="circle-span"></span>
                <span class="circle-mine">税后工资：{{(result).toFixed(2)}}元</span>
              </div>
              <div class="circle-text">
                <span class="circle-span circle-span1"></span>
                <span class="circle-mine">个人所得税：{{income.toFixed(2)}}元</span>
              </div>
              <div class="circle-text">
                <span class="circle-span circle-span2"></span>
                <span class="circle-mine">五险一金：{{(social+accumulation).toFixed(2)}}元</span>
              </div>
            </div>
          </div>
          <div class="item-t-container item-t-right">
            <div
              class="item-title item-title1"
              :class="{act: isActShow==1}"
              @click="isActShow=1"
            >社保公积金</div>
            <div
              class="item-title item-title2"
              :class="{act: isActShow==2}"
              @click="isActShow=2"
            >专项扣除</div>
          </div>
          <div v-show="isActShow==1">
            <div class="social">
              <div class="item">
                <span>养老({{rate1}}%)</span>
                <span>{{base1.toFixed(2)}}元</span>
              </div>
              <div class="item">
                <span>医疗({{rate2}}%)</span>
                <span>{{base2.toFixed(2)}}元</span>
              </div>
              <div class="item">
                <span>失业({{rate3}}%)</span>
                <span>{{base3.toFixed(2)}}元</span>
              </div>
              <div class="item">
                <span>工商({{rate4}}%)</span>
                <span>{{base4.toFixed(2)}}元</span>
              </div>
              <div class="item">
                <span>生育({{rate5}}%)</span>
                <span>{{base5.toFixed(2)}}元</span>
              </div>
              <div class="item">
                <span>公积金({{rate6}}%)</span>
                <span>{{accumulation.toFixed(2)}}元</span>
              </div>
              <div class="item item-border">
                <span class="weight">合计扣除（元）</span>
                <span>{{(this.accumulation+this.social).toFixed(2)}}元</span>
              </div>
              <div class="result">
                <span class="result-txt weight">税后所得</span>
              </div>
              <div class="result">
                <span class="result-price">{{result.toFixed(2)}}元</span>
              </div>
            </div>
          </div>
          <div v-show="isActShow==2">
            <div class="social">
              <div class="item">
                <span>住房租金</span>
                <span>{{discountShow1}}元</span>
              </div>
              <div class="item">
                <span>首套房贷</span>
                <span>{{discountShow2}}元</span>
              </div>
              <div class="item">
                <span>子女教育</span>
                <span>{{discountShow3}}元</span>
              </div>
              <div class="item">
                <span>继续教育</span>
                <span>{{discountShow4}}元</span>
              </div>
              <div class="item">
                <span>赡养老人</span>
                <span>{{discountShow5}}元</span>
              </div>
              <div class="item">
                <span>大病医疗</span>
                <span>{{discountShow6}}元</span>
              </div>
              <div class="item">
                <span class="weight">合计扣除（元）</span>
                <span>{{(discountShow1+discountShow2+discountShow3+discountShow4+discountShow5+discountShow6).toFixed(2)}}元</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import { orderRecord } from "../../api/api";
import echarts from "echarts";
export default {
  components: {
    BreadCrumb
  },
  data() {
    return {
      //社保五项
      provide: 0,
      medicals: 0,
      unemployment: 0,
      industrial: 0,
      fertility: 0,
      //
      resultPage: false,
      haveMedical: false,
      quickNum: "",
      Percentage: "",
      city: [],
      socialbase: "",
      social: 0,
      accumulation: 0,
      salarybase: "",
      income: 0,
      result: 0,
      medical: null,
      rate1: 8,
      rate2: 2,
      rate3: 0.5,
      rate4: 0,
      rate5: 0,
      rate6: 12,
      base1: 0,
      base2: 0,
      base3: 0,
      base4: 0,
      base5: 0,
      base6: 0,
      baseAll: 0,
      result: 0,
      isAct: 1,
      isActShow: 1,
      salary: "",
      discount1: 1500,
      discount2: 0,
      discount3: 0,
      discount4: 0,
      discount5: 0,
      discount5: 0,
      discount6: 0,
      // 绑定数据
      discount11: 1500,
      discount12: 0,
      discount13: 0,
      discount14: 0,
      discount15: 0,
      discount15: 0,
      discount16: 0,
      discountShow1: 0,
      discountShow2: 0,
      discountShow3: 0,
      discountShow4: 0,
      discountShow5: 0,
      discountShow5: 0,
      discountShow6: 0,
      discountAll: 0,
      input1: 0.08,
      haveCity: [],
      input: "",
      options: [
        {
          value: "北京市",
          label: "北京市",
          children: [
            {
              value: "北京市",
              label: "北京市"
            }
          ]
        },
        {
          value: "天津市",
          label: "天津市",
          children: [
            {
              value: "天津市",
              label: "天津市"
            }
          ]
        },
        {
          value: "上海市",
          label: "上海市",
          children: [
            {
              value: "上海市",
              label: "上海市"
            }
          ]
        },
        {
          value: "重庆市",
          label: "重庆市",
          children: [
            {
              value: "重庆市",
              label: "重庆市"
            }
          ]
        },
        {
          value: "广东省",
          label: "广东省",
          children: [
            {
              value: "广州市",
              label: "广州市"
            },
            {
              value: "深圳市",
              label: "深圳市"
            },
            {
              value: "东莞市",
              label: "东莞市"
            },
            {
              value: "中山市",
              label: "中山市"
            },
            {
              value: "潮州市",
              label: "潮州市"
            },
            {
              value: "揭阳市",
              label: "揭阳市"
            },
            {
              value: "云浮市",
              label: "云浮市"
            },
            {
              value: "云浮市",
              label: "云浮市"
            },
            {
              name: "珠海市",
              label: "珠海市"
            },
            {
              name: "汕头市",
              label: "汕头市"
            },
            {
              name: "韶关市",
              label: "韶关市"
            },
            {
              name: "佛山市",
              label: "佛山市"
            },
            {
              name: "江门市",
              label: "江门市"
            },
            {
              name: "湛江市",
              label: "湛江市"
            },
            {
              name: "茂名市",
              label: "茂名市"
            },
            {
              name: "肇庆市",
              label: "肇庆市"
            },
            {
              name: "惠州市",
              label: "惠州市"
            },
            {
              name: "梅州市",
              label: "梅州市"
            },
            {
              name: "汕尾市",
              label: "汕尾市"
            },
            {
              name: "河源市",
              label: "河源市"
            },
            {
              name: "阳江市",
              label: "阳江市"
            },
            {
              name: "清远市",
              label: "清远市"
            }
          ]
        },
        {
          value: "浙江省",
          label: "浙江省",
          children: [
            {
              value: "杭州市",
              label: "杭州市"
            },
            {
              value: "宁波市",
              label: "宁波市"
            },
            {
              value: "温州市",
              label: "温州市"
            },
            {
              value: "嘉兴市",
              label: "嘉兴市"
            },
            {
              value: "湖州市",
              label: "湖州市"
            },
            {
              value: "绍兴市",
              label: "绍兴市"
            },
            {
              value: "金华市",
              label: "金华市"
            },
            {
              value: "衢州市",
              label: "衢州市"
            },
            {
              value: "舟山市",
              label: "舟山市"
            },
            {
              value: "台州市",
              label: "台州市"
            },
            {
              value: "丽水市",
              label: "丽水市"
            }
          ]
        },
        {
          value: "江苏省",
          label: "江苏省",
          children: [
            {
              value: "南京市",
              label: "南京市"
            },
            {
              value: "苏州市",
              label: "苏州市"
            },
            {
              value: "无锡市",
              label: "无锡市"
            },
            {
              value: "常州市",
              label: "常州市"
            },
            {
              value: "镇江市",
              label: "镇江市"
            },
            {
              value: "南通市",
              label: "南通市"
            },
            {
              value: "泰州市",
              label: "泰州市"
            },
            {
              value: "扬州市",
              label: "扬州市"
            },
            {
              value: "盐城市",
              label: "盐城市"
            },
            {
              value: "连云港市",
              label: "连云港市"
            },
            {
              value: "徐州市",
              label: "徐州市"
            },
            {
              value: "淮安市",
              label: "淮安市"
            },
            {
              value: "宿迁市",
              label: "宿迁市"
            }
          ]
        },
        {
          value: "福建省",
          label: "福建省",
          children: [
            {
              value: "福州市",
              label: "福州市"
            },
            {
              value: "厦门市",
              label: "厦门市"
            },
            {
              value: "莆田市",
              label: "莆田市"
            },
            {
              value: "三明市",
              label: "三明市"
            },
            {
              value: "泉州市",
              label: "泉州市"
            },
            {
              value: "漳州市",
              label: "漳州市"
            },
            {
              value: "南平市",
              label: "南平市"
            },
            {
              value: "龙岩市",
              label: "龙岩市"
            },
            {
              value: "宁德市",
              label: "宁德市"
            }
          ]
        },
        {
          value: "河北省",
          label: "河北省",
          children: [
            {
              value: "石家庄市",
              label: "石家庄市"
            },
            {
              value: "唐山市",
              label: "唐山市"
            },
            {
              value: "秦皇岛市",
              label: "秦皇岛市"
            },
            {
              value: "邯郸市",
              label: "邯郸市"
            },
            {
              value: "邢台市",
              label: "邢台市"
            },
            {
              value: "保定市",
              label: "保定市"
            },
            {
              value: "张家口市",
              label: "张家口市"
            },
            {
              value: "承德市",
              label: "承德市"
            },
            {
              value: "沧州市",
              label: "沧州市"
            },
            {
              value: "廊坊市",
              label: "廊坊市"
            },
            {
              value: "衡水市",
              label: "衡水市"
            }
          ]
        },
        {
          value: "河南省",
          label: "河南省",
          children: [
            {
              value: "郑州市",
              label: "郑州市"
            },
            {
              value: "开封市",
              label: "开封市"
            },
            {
              value: "洛阳市",
              label: "洛阳市"
            },
            {
              value: "平顶山市",
              label: "平顶山市"
            },
            {
              value: "安阳市",
              label: "安阳市"
            },
            {
              value: "鹤壁市",
              label: "鹤壁市"
            },
            {
              value: "新乡市",
              label: "新乡市"
            },
            {
              value: "焦作市",
              label: "焦作市"
            },
            {
              value: "濮阳市",
              label: "濮阳市"
            },
            {
              value: "许昌市",
              label: "许昌市"
            },
            {
              value: "漯河市",
              label: "漯河市"
            },
            {
              value: "三门峡市",
              label: "三门峡市"
            },
            {
              value: "南阳市",
              label: "南阳市"
            },
            {
              value: "商丘市",
              label: "商丘市"
            },
            {
              value: "信阳市",
              label: "信阳市"
            },
            {
              value: "周口市",
              label: "周口市"
            },
            {
              value: "驻马店市",
              label: "驻马店市"
            },
            {
              value: "焦作市",
              label: "焦作市"
            }
          ]
        },
        {
          value: "山西省",
          label: "山西省",
          children: [
            {
              value: "太原市",
              label: "太原市"
            },
            {
              value: "大同市",
              label: "大同市"
            },
            {
              value: "阳泉市",
              label: "阳泉市"
            },
            {
              value: "长治市",
              label: "长治市"
            },
            {
              value: "晋城市",
              label: "晋城市"
            },
            {
              value: "朔州市",
              label: "朔州市"
            },
            {
              value: "忻州市",
              label: "忻州市"
            },
            {
              value: "吕梁市",
              label: "吕梁市"
            },
            {
              value: "晋中市",
              label: "晋中市"
            },
            {
              value: "临汾市",
              label: "临汾市"
            },
            {
              value: "运城市",
              label: "运城市"
            }
          ]
        },
        {
          value: "山东省",
          label: "山东省",
          children: [
            {
              value: "济南市",
              label: "济南市"
            },
            {
              value: "青岛市",
              label: "青岛市"
            },
            {
              value: "淄博市",
              label: "淄博市"
            },
            {
              value: "枣庄市",
              label: "枣庄市"
            },
            {
              value: "东营市",
              label: "东营市"
            },
            {
              value: "烟台市",
              label: "烟台市"
            },
            {
              value: "潍坊市",
              label: "潍坊市"
            },
            {
              value: "济宁市",
              label: "济宁市"
            },
            {
              value: "泰安市",
              label: "泰安市"
            },
            {
              value: "威海市",
              label: "威海市"
            },
            {
              value: "日照市",
              label: "日照市"
            },

            {
              value: "莱芜市",
              label: "莱芜市"
            },
            {
              value: "临沂市",
              label: "临沂市"
            },
            {
              value: "德州市",
              label: "德州市"
            },
            {
              value: "聊城市",
              label: "聊城市"
            },
            {
              value: "滨州市",
              label: "滨州市"
            },
            {
              value: "菏泽市",
              label: "菏泽市"
            }
          ]
        },
        {
          value: "辽宁省",
          label: "辽宁省",
          children: [
            {
              value: "沈阳市",
              label: "沈阳市"
            },
            {
              value: "大连市",
              label: "大连市"
            },
            {
              value: "鞍山市",
              label: "鞍山市"
            },
            {
              value: "抚顺市",
              label: "抚顺市"
            },
            {
              value: "本溪市",
              label: "本溪市"
            },
            {
              value: "丹东市",
              label: "丹东市"
            },
            {
              value: "锦州市",
              label: "锦州市"
            },
            {
              value: "营口市",
              label: "营口市"
            },
            {
              value: "阜新市",
              label: "阜新市"
            },
            {
              value: "辽阳市",
              label: "辽阳市"
            },
            {
              value: "盘锦市",
              label: "盘锦市"
            },

            {
              value: "铁岭市",
              label: "铁岭市"
            },
            {
              value: "朝阳市",
              label: "朝阳市"
            },
            {
              value: "葫芦岛市",
              label: "葫芦岛市"
            }
          ]
        },
        {
          value: "吉林省",
          label: "吉林省",
          children: [
            {
              value: "长春市",
              label: "长春市"
            },
            {
              value: "吉林市",
              label: "吉林市"
            },
            {
              value: "四平市",
              label: "四平市"
            },
            {
              value: "辽源市",
              label: "辽源市"
            },
            {
              value: "通化市",
              label: "通化市"
            },
            {
              value: "白山市",
              label: "白山市"
            },
            {
              value: "松原市",
              label: "松原市"
            },
            {
              value: "白城市",
              label: "白城市"
            },
            {
              value: "延边朝鲜族自治州",
              label: "延边朝鲜族自治州"
            }
          ]
        },
        {
          value: "黑龙江省",
          label: "黑龙江省",
          children: [
            {
              value: "哈尔滨市",
              label: "哈尔滨市"
            },
            {
              value: "齐齐哈尔市",
              label: "齐齐哈尔市"
            },
            {
              value: "鹤岗市",
              label: "鹤岗市"
            },
            {
              value: "双鸭山市",
              label: "双鸭山市"
            },
            {
              value: "鸡西市",
              label: "鸡西市"
            },
            {
              value: "大庆市",
              label: "大庆市"
            },
            {
              value: "伊春市",
              label: "伊春市"
            },
            {
              value: "牡丹江市",
              label: "牡丹江市"
            },
            {
              value: "佳木斯市",
              label: "佳木斯市"
            },
            {
              value: "七台河市",
              label: "七台河市"
            },
            {
              value: "黑河市",
              label: "黑河市"
            },
            {
              value: "绥化市",
              label: "绥化市"
            },
            {
              value: "大兴安岭地区市",
              label: "大兴安岭地区市"
            }
          ]
        },
        {
          value: "陕西省",
          label: "陕西省",
          children: [
            {
              value: "西安市",
              label: "西安市"
            },
            {
              value: "铜川市",
              label: "铜川市"
            },
            {
              value: "宝鸡市",
              label: "宝鸡市"
            },
            {
              value: "咸阳市",
              label: "咸阳市"
            },
            {
              value: "渭南市",
              label: "渭南市"
            },
            {
              value: "延安市",
              label: "延安市"
            },
            {
              value: "汉中市",
              label: "汉中市"
            },
            {
              value: "榆林市",
              label: "榆林市"
            },
            {
              value: "安康市",
              label: "安康市"
            },
            {
              value: "商洛市",
              label: "商洛市"
            }
          ]
        },
        {
          value: "安徽省",
          label: "安徽省",
          children: [
            {
              value: "合肥市",
              label: "合肥市"
            },
            {
              value: "芜湖市",
              label: "芜湖市"
            },
            {
              value: "蚌埠市",
              label: "蚌埠市"
            },
            {
              value: "淮南市",
              label: "淮南市"
            },
            {
              value: "马鞍山市",
              label: "马鞍山市"
            },
            {
              value: "淮北市",
              label: "淮北市"
            },
            {
              value: "铜陵市",
              label: "铜陵市"
            },
            {
              value: "安庆市",
              label: "安庆市"
            },
            {
              value: "黄山市",
              label: "黄山市"
            },
            {
              value: "滁州市",
              label: "滁州市"
            },
            {
              value: "阜阳市",
              label: "阜阳市"
            },
            {
              value: "宿州市",
              label: "宿州市"
            },
            {
              value: "巢湖市",
              label: "巢湖市"
            },
            {
              value: "六安市",
              label: "六安市"
            },
            {
              value: "亳州市",
              label: "亳州市"
            },
            {
              value: "池州市",
              label: "池州市"
            },
            {
              value: "宣城市",
              label: "宣城市"
            }
          ]
        },
        {
          value: "江西省",
          label: "江西省",
          children: [
            {
              value: "南昌市",
              label: "南昌市"
            },
            {
              value: "景德镇市",
              label: "景德镇市"
            },
            {
              value: "萍乡市",
              label: "萍乡市"
            },
            {
              value: "九江市",
              label: "九江市"
            },
            {
              value: "新余市",
              label: "新余市"
            },
            {
              value: "鹰潭市",
              label: "鹰潭市"
            },
            {
              value: "赣州市",
              label: "赣州市"
            },
            {
              value: "吉安市",
              label: "吉安市"
            },
            {
              value: "宜春市",
              label: "宜春市"
            },
            {
              value: "抚州市",
              label: "抚州市"
            },
            {
              value: "上饶市",
              label: "上饶市"
            }
          ]
        },
        {
          value: "湖北省",
          label: "湖北省",
          children: [
            {
              value: "武汉市",
              label: "武汉市"
            },
            {
              value: "黄石市",
              label: "黄石市"
            },
            {
              value: "十堰市",
              label: "十堰市"
            },
            {
              value: "荆州市",
              label: "荆州市"
            },
            {
              value: "宜昌市",
              label: "宜昌市"
            },
            {
              value: "襄樊市",
              label: "襄樊市"
            },
            {
              value: "鄂州市",
              label: "鄂州市"
            },
            {
              value: "荆门市",
              label: "荆门市"
            },
            {
              value: "孝感市",
              label: "孝感市"
            },
            {
              value: "黄冈市",
              label: "黄冈市"
            },
            {
              value: "咸宁市",
              label: "咸宁市"
            },

            {
              value: "随州市",
              label: "随州市"
            },
            {
              value: "恩施土家族苗族自治州",
              label: "恩施土家族苗族自治州"
            },
            {
              value: "仙桃市",
              label: "仙桃市"
            },
            {
              value: "天门市",
              label: "天门市"
            },
            {
              value: "潜江市",
              label: "潜江市"
            },
            {
              value: "神农架林区市",
              label: "神农架林区市"
            }
          ]
        },
        {
          value: "湖南省",
          label: "湖南省",
          children: [
            {
              value: "长沙市",
              label: "长沙市"
            },
            {
              value: "株洲市",
              label: "株洲市"
            },
            {
              value: "湘潭市",
              label: "湘潭市"
            },
            {
              value: "衡阳市",
              label: "衡阳市"
            },
            {
              value: "邵阳市",
              label: "邵阳市"
            },
            {
              value: "岳阳市",
              label: "岳阳市"
            },
            {
              value: "常德市",
              label: "常德市"
            },
            {
              value: "张家界市",
              label: "张家界市"
            },
            {
              value: "益阳市",
              label: "益阳市"
            },
            {
              value: "郴州市",
              label: "郴州市"
            },
            {
              value: "永州市",
              label: "永州市"
            },

            {
              value: "怀化市",
              label: "怀化市"
            },
            {
              value: "娄底市",
              label: "娄底市"
            },
            {
              value: "湘西土家族苗族自治州",
              label: "湘西土家族苗族自治州"
            }
          ]
        },
        {
          value: "四川省",
          label: "四川省",
          children: [
            {
              value: "成都市",
              label: "成都市"
            },
            {
              value: "自贡市",
              label: "自贡市"
            },
            {
              value: "攀枝花市",
              label: "攀枝花市"
            },
            {
              value: "泸州市",
              label: "泸州市"
            },
            {
              value: "德阳市",
              label: "德阳市"
            },
            {
              value: "绵阳市",
              label: "绵阳市"
            },
            {
              value: "广元市",
              label: "广元市"
            },
            {
              value: "遂宁市",
              label: "遂宁市"
            },
            {
              value: "内江市",
              label: "内江市"
            },
            {
              value: "乐山市",
              label: "乐山市"
            },
            {
              value: "南充市",
              label: "南充市"
            },

            {
              value: "眉山市",
              label: "眉山市"
            },
            {
              value: "宜宾市",
              label: "宜宾市"
            },
            {
              value: "广安市",
              label: "广安市"
            },

            {
              value: "达州市",
              label: "达州市"
            },
            {
              value: "雅安市",
              label: "雅安市"
            },
            {
              value: "巴中市",
              label: "巴中市"
            },
            {
              value: "资阳市",
              label: "资阳市"
            },
            {
              value: "阿坝藏族羌族自治州",
              label: "阿坝藏族羌族自治州"
            },
            {
              value: "甘孜藏族自治州",
              label: "甘孜藏族自治州"
            },
            {
              value: "凉山彝族自治州",
              label: "凉山彝族自治州"
            }
          ]
        },
        {
          value: "贵州省",
          label: "贵州省",
          children: [
            {
              value: "贵阳市",
              label: "贵阳市"
            },
            {
              value: "六盘水市",
              label: "六盘水市"
            },
            {
              value: "遵义市",
              label: "遵义市"
            },
            {
              value: "安顺市",
              label: "安顺市"
            },
            {
              value: "铜仁地区市",
              label: "铜仁地区市"
            },
            {
              value: "毕节地区市",
              label: "毕节地区市"
            },
            {
              value: "黔西南布依族苗族自治州",
              label: "黔西南布依族苗族自治州"
            },
            {
              value: "黔东南苗族侗族自治州",
              label: "黔东南苗族侗族自治州"
            },
            {
              value: "黔南布依族苗族自治州",
              label: "黔南布依族苗族自治州"
            }
          ]
        },
        {
          value: "云南省",
          label: "云南省",
          children: [
            {
              value: "昆明市",
              label: "昆明市"
            },
            {
              value: "曲靖市",
              label: "曲靖市"
            },
            {
              value: "玉溪市",
              label: "玉溪市"
            },
            {
              value: "保山市",
              label: "保山市"
            },
            {
              value: "昭通市",
              label: "昭通市"
            },
            {
              value: "丽江市",
              label: "丽江市"
            },
            {
              value: "普洱市",
              label: "普洱市"
            },
            {
              value: "临沧市",
              label: "临沧市"
            },
            {
              value: "德宏傣族景颇族自治州",
              label: "德宏傣族景颇族自治州"
            },

            {
              value: "怒江傈僳族自治州",
              label: "怒江傈僳族自治州"
            },
            {
              value: "迪庆藏族自治州",
              label: "迪庆藏族自治州"
            },
            {
              value: "大理白族自治州",
              label: "大理白族自治州"
            },
            {
              value: "楚雄彝族自治州",
              label: "楚雄彝族自治州"
            },
            {
              value: "红河哈尼族彝族自治州",
              label: "红河哈尼族彝族自治州"
            },
            {
              value: "文山壮族苗族自治州",
              label: "文山壮族苗族自治州"
            },
            {
              value: "西双版纳傣族自治州",
              label: "西双版纳傣族自治州"
            }
          ]
        },
        {
          value: "广西省",
          label: "广西省",
          children: [
            {
              value: "南宁市",
              label: "南宁市"
            },
            {
              value: "柳州市",
              label: "柳州市"
            },
            {
              value: "桂林市",
              label: "桂林市"
            },
            {
              value: "梧州市",
              label: "梧州市"
            },
            {
              value: "北海市",
              label: "北海市"
            },
            {
              value: "防城港市",
              label: "防城港市"
            },
            {
              value: "钦州市",
              label: "钦州市"
            },
            {
              value: "贵港市",
              label: "贵港市"
            },
            {
              value: "玉林市",
              label: "玉林市"
            },

            {
              value: "百色市",
              label: "百色市"
            },
            {
              value: "贺州市",
              label: "贺州市"
            },
            {
              value: "河池市",
              label: "河池市"
            },
            {
              value: "来宾市",
              label: "来宾市"
            },
            {
              value: "崇左市",
              label: "崇左市"
            }
          ]
        },
        {
          value: "海南省",
          label: "海南省",
          children: [
            {
              value: "海口市",
              label: "海口市"
            },
            {
              value: "三亚市",
              label: "三亚市"
            },
            {
              value: "五指山市",
              label: "五指山市"
            },
            {
              value: "琼海市",
              label: "琼海市"
            },
            {
              value: "儋州市",
              label: "儋州市"
            },
            {
              value: "文昌市",
              label: "文昌市"
            },
            {
              value: "万宁市",
              label: "万宁市"
            },
            {
              value: "东方市",
              label: "东方市"
            },
            {
              value: "澄迈县",
              label: "澄迈县"
            },

            {
              value: "定安县",
              label: "定安县"
            },
            {
              value: "屯昌县",
              label: "屯昌县"
            },
            {
              value: "临高县",
              label: "临高县"
            },
            {
              value: "白沙黎族自治县",
              label: "白沙黎族自治县"
            },
            {
              value: "昌江黎族自治县",
              label: "昌江黎族自治县"
            },
            {
              value: "乐东黎族自治县",
              label: "乐东黎族自治县"
            },
            {
              value: "陵水黎族自治县",
              label: "陵水黎族自治县"
            },
            {
              value: "保亭黎族苗族自治县",
              label: "保亭黎族苗族自治县"
            },
            {
              value: "琼中黎族苗族自治县",
              label: "琼中黎族苗族自治县"
            }
          ]
        },
        {
          value: "甘肃省",
          label: "甘肃省",
          children: [
            {
              value: "兰州市",
              label: "兰州市"
            },
            {
              value: "嘉峪关市",
              label: "嘉峪关市"
            },
            {
              value: "金昌市",
              label: "金昌市"
            },
            {
              value: "白银市",
              label: "白银市"
            },
            {
              value: "天水市",
              label: "天水市"
            },
            {
              value: "武威市",
              label: "武威市"
            },
            {
              value: "酒泉市",
              label: "酒泉市"
            },
            {
              value: "张掖市",
              label: "张掖市"
            },
            {
              value: "庆阳市",
              label: "庆阳市"
            },

            {
              value: "平凉市",
              label: "平凉市"
            },
            {
              value: "定西市",
              label: "定西市"
            },
            {
              value: "陇南市",
              label: "陇南市"
            },
            {
              value: "临夏回族自治州",
              label: "临夏回族自治州"
            },
            {
              value: "甘南藏族自治州",
              label: "甘南藏族自治州"
            }
          ]
        },
        {
          value: "青海省",
          label: "青海省",
          children: [
            {
              value: "西宁市",
              label: "西宁市"
            },
            {
              value: "海东地区市",
              label: "海东地区市"
            },
            {
              value: "海北藏族自治州",
              label: "海北藏族自治州"
            },
            {
              value: "海南藏族自治州",
              label: "海南藏族自治州"
            },
            {
              value: "黄南藏族自治州",
              label: "黄南藏族自治州"
            },
            {
              value: "果洛藏族自治州",
              label: "果洛藏族自治州"
            },
            {
              value: "玉树藏族自治州",
              label: "玉树藏族自治州"
            },
            {
              value: "海西蒙古族藏族自治州",
              label: "海西蒙古族藏族自治州"
            }
          ]
        },
        {
          value: "内蒙古",
          label: "内蒙古",
          children: [
            {
              value: "呼和浩特市",
              label: "呼和浩特市"
            },
            {
              value: "包头市",
              label: "包头市"
            },
            {
              value: "乌海市",
              label: "乌海市"
            },
            {
              value: "赤峰市",
              label: "赤峰市"
            },
            {
              value: "呼伦贝尔市",
              label: "呼伦贝尔市"
            },
            {
              value: "兴安盟市",
              label: "兴安盟市"
            },
            {
              value: "通辽市",
              label: "通辽市"
            },
            {
              value: "锡林郭勒盟市",
              label: "锡林郭勒盟市"
            },
            {
              value: "乌兰察布盟市",
              label: "乌兰察布盟市"
            },
            {
              value: "伊克昭盟市",
              label: "伊克昭盟市"
            },
            {
              value: "巴彦淖尔盟市",
              label: "巴彦淖尔盟市"
            },
            {
              value: "阿拉善盟市",
              label: "阿拉善盟市"
            }
          ]
        },
        {
          value: "宁夏",
          label: "宁夏",
          children: [
            {
              value: "银川市",
              label: "银川市"
            },
            {
              value: "石嘴山市",
              label: "石嘴山市"
            },
            {
              value: "吴忠市",
              label: "吴忠市"
            },
            {
              value: "固原市",
              label: "固原市"
            },
            {
              value: "中卫市",
              label: "中卫市"
            }
          ]
        },
        {
          value: "西藏",
          label: "西藏",
          children: [
            {
              value: "拉萨市",
              label: "拉萨市"
            },
            {
              value: "那曲地区市",
              label: "那曲地区市"
            },
            {
              value: "昌都地区市",
              label: "昌都地区市"
            },
            {
              value: "林芝地区市",
              label: "林芝地区市"
            },
            {
              value: "山南地区市",
              label: "山南地区市"
            },
            {
              value: "日喀则地区市",
              label: "日喀则地区市"
            },
            {
              value: "阿里地区市",
              label: "阿里地区市"
            }
          ]
        },
        {
          value: "新疆",
          label: "新疆",
          children: [
            {
              value: "乌鲁木齐市",
              label: "乌鲁木齐市"
            },
            {
              value: "克拉玛依市",
              label: "克拉玛依市"
            },
            {
              value: "吐鲁番地区市",
              label: "吐鲁番地区市"
            },
            {
              value: "哈密地区市",
              label: "哈密地区市"
            },
            {
              value: "和田地区市",
              label: "和田地区市"
            },
            {
              value: "阿克苏地区市",
              label: "阿克苏地区市"
            },
            {
              value: "喀什地区市",
              label: "喀什地区市"
            },
            {
              value: "克孜勒苏柯尔克孜自治州",
              label: "克孜勒苏柯尔克孜自治州"
            },
            {
              value: "巴音郭楞蒙古自治州",
              label: "巴音郭楞蒙古自治州"
            },
            {
              value: "昌吉回族自治州",
              label: "昌吉回族自治州"
            },

            {
              value: "博尔塔拉蒙古自治州",
              label: "博尔塔拉蒙古自治州"
            },
            {
              value: "石河子市",
              label: "石河子市"
            },
            {
              value: "阿拉尔市",
              label: "阿拉尔市"
            },
            {
              value: "图木舒克市",
              label: "图木舒克市"
            },
            {
              value: "五家渠市",
              label: "五家渠市"
            },
            {
              value: "伊犁哈萨克自治州",
              label: "伊犁哈萨克自治州"
            }
          ]
        },
        {
          value: "台湾",
          label: "台湾"
        },
        {
          value: "澳门",
          label: "澳门"
        },
        {
          value: "香港",
          label: "香港"
        }
      ],
      options1: [
        {
          value: "1",
          label: "当前城市扣除" + this.input1
        }
      ],
      options2: [
        {
          value: "1000",
          label: "住房贷款利息扣除1000元/月"
        }
      ],
      options3: [
        {
          value: "0.5",
          label: "双方各扣50%",
          children: [
            {
              value: "1000",
              label: "1"
            },
            {
              value: "2000",
              label: "2"
            },
            {
              value: "3000",
              label: "3"
            },
            {
              value: "4000",
              label: "4"
            },
            {
              value: "5000",
              label: "5"
            }
          ]
        },
        {
          value: "1",
          label: "一方扣除100%",
          children: [
            {
              value: "1000",
              label: "1"
            },
            {
              value: "2000",
              label: "2"
            },
            {
              value: "3000",
              label: "3"
            },
            {
              value: "4000",
              label: "4"
            },
            {
              value: "5000",
              label: "5"
            }
          ]
        }
      ],
      options4: [
        {
          value: "400",
          label: "学历继续教育扣除400元/月"
        },
        {
          value: "300",
          label: "职业资格继续教育扣除300元/月"
        }
      ],
      options5: [
        {
          value: "2000",
          label: "独生子女扣除2000元/月"
        },
        {
          value: "1000",
          label: "两位子女平均分摊扣除1000元/月"
        },
        {
          value: "2000",
          label: "赡养人约定分摊金额比例",
          children: [
            {
              value: "1",
              label: "1%"
            },
            {
              value: "2",
              label: "2%"
            },
            {
              value: "3",
              label: "3%"
            },
            {
              value: "4",
              label: "4%"
            },
            {
              value: "5",
              label: "5%"
            },
            {
              value: "6",
              label: "6%"
            },
            {
              value: "7",
              label: "7%"
            },
            {
              value: "8",
              label: "8%"
            },
            {
              value: "9",
              label: "9%"
            },
            {
              value: "10",
              label: "10%"
            },
            {
              value: "11",
              label: "11%"
            },
            {
              value: "12",
              label: "12%"
            },
            {
              value: "13",
              label: "13%"
            },
            {
              value: "14",
              label: "14%"
            },
            {
              value: "15",
              label: "15%"
            },
            {
              value: "16",
              label: "16%"
            },
            {
              value: "17",
              label: "17%"
            },
            {
              value: "18",
              label: "18%"
            },
            {
              value: "19",
              label: "19%"
            },
            {
              value: "20",
              label: "20%"
            },
            {
              value: "21",
              label: "21%"
            },
            {
              value: "22",
              label: "22%"
            },
            {
              value: "23",
              label: "23%"
            },
            {
              value: "24",
              label: "24%"
            },
            {
              value: "25",
              label: "25%"
            },
            {
              value: "26",
              label: "26%"
            },
            {
              value: "27",
              label: "27%"
            },
            {
              value: "28",
              label: "28%"
            },
            {
              value: "29",
              label: "29%"
            },
            {
              value: "30",
              label: "30%"
            },
            {
              value: "31",
              label: "31%"
            },
            {
              value: "32",
              label: "32%"
            },
            {
              value: "33",
              label: "33%"
            },
            {
              value: "34",
              label: "34%"
            },
            {
              value: "35",
              label: "35%"
            },
            {
              value: "36",
              label: "36%"
            },
            {
              value: "37",
              label: "37%"
            },
            {
              value: "38",
              label: "38%"
            },
            {
              value: "39",
              label: "39%"
            },
            {
              value: "40",
              label: "40%"
            },
            {
              value: "41",
              label: "41%"
            },
            {
              value: "42",
              label: "42%"
            },
            {
              value: "43",
              label: "43%"
            },
            {
              value: "44",
              label: "44%"
            },
            {
              value: "45",
              label: "45%"
            },
            {
              value: "46",
              label: "46%"
            },
            {
              value: "47",
              label: "47%"
            },
            {
              value: "48",
              label: "48%"
            },
            {
              value: "49",
              label: "49%"
            },
            {
              value: "50",
              label: "50%"
            },
            {
              value: "51",
              label: "51%"
            },
            {
              value: "52",
              label: "52%"
            },
            {
              value: "53",
              label: "53%"
            },
            {
              value: "54",
              label: "54%"
            },
            {
              value: "55",
              label: "55%"
            },
            {
              value: "56",
              label: "56%"
            },
            {
              value: "57",
              label: "57%"
            },
            {
              value: "58",
              label: "58%"
            },
            {
              value: "59",
              label: "59%"
            },
            {
              value: "60",
              label: "60%"
            },
            {
              value: "61",
              label: "61%"
            },
            {
              value: "62",
              label: "62%"
            },
            {
              value: "63",
              label: "63%"
            },
            {
              value: "64",
              label: "64%"
            },
            {
              value: "65",
              label: "65%"
            },
            {
              value: "66",
              label: "66%"
            },
            {
              value: "67",
              label: "67%"
            },
            {
              value: "68",
              label: "68%"
            },
            {
              value: "69",
              label: "69%"
            },
            {
              value: "70",
              label: "70%"
            },
            {
              value: "71",
              label: "71%"
            },
            {
              value: "72",
              label: "72%"
            },
            {
              value: "73",
              label: "73%"
            },
            {
              value: "74",
              label: "74%"
            },
            {
              value: "75",
              label: "75%"
            },
            {
              value: "76",
              label: "76%"
            },
            {
              value: "77",
              label: "77%"
            },
            {
              value: "78",
              label: "78%"
            },
            {
              value: "79",
              label: "79%"
            },
            {
              value: "80",
              label: "80%"
            },
            {
              value: "81",
              label: "81%"
            },
            {
              value: "82",
              label: "82%"
            },
            {
              value: "83",
              label: "83%"
            },
            {
              value: "84",
              label: "84%"
            },
            {
              value: "85",
              label: "85%"
            },
            {
              value: "86",
              label: "86%"
            },
            {
              value: "87",
              label: "87%"
            },
            {
              value: "88",
              label: "88%"
            },
            {
              value: "89",
              label: "89%"
            },
            {
              value: "90",
              label: "90%"
            },
            {
              value: "91",
              label: "91%"
            },
            {
              value: "92",
              label: "92%"
            },
            {
              value: "93",
              label: "93%"
            },
            {
              value: "94",
              label: "94%"
            },
            {
              value: "95",
              label: "95%"
            },
            {
              value: "96",
              label: "96%"
            },
            {
              value: "97",
              label: "97%"
            },
            {
              value: "98",
              label: "98%"
            },
            {
              value: "99",
              label: "99%"
            },
            {
              value: "100",
              label: "100%"
            }
          ]
        }
      ],
      options6: [
        {
          value: "15000",
          label: "本年度医疗自费超过1.5万元金额，超出部分填写在右侧"
        }
      ]
    };
  },
  mounted() {
    this.options1 = [
      {
        value: "1",
        label: "当前城市扣除" + this.discount1 + "元/月"
      }
    ];
  },
  watch: {
    discount1(newVal, oldVal) {
      this.options1 = [
        {
          value: "1",
          label: "当前城市扣除" + newVal + "元/月"
        }
      ];
      if (this.haveCity.length) {
        this.discountShow1 = newVal;
      }
    }
  },
  methods: {
    rate1C() {
      if (this.rate1 == "00") {
        this.rate1 = 0;
      }
      // if (this.rate1 > 8) {
      //   this.rate1 = 8;
      //   this.$message({
      //     message: "养老保险比例最高为8%!",
      //     type: "warning"
      //   });
      // }
    },
    rate2C() {
      if (this.rate2 == "00") {
        this.rate2 = 0;
      }
    },
    rate3C() {
      if (this.rate3 == "00") {
        this.rate3 = 0;
      }
    },
    rate4C() {
      if (this.rate4 == "00") {
        this.rate4 = 0;
      }
    },
    rate5C() {
      if (this.rate5 == "00") {
        this.rate5 = 0;
      }
    },
    rate6C() {
      if (this.rate6 == "00") {
        this.rate6 = 0;
      }
    },
    // 修改公积金基数
    salarybaseC() {
      // if (this.salary && (this.salarybase > this.salary)) {
      //   this.$message({
      //     message: "公积金基数不能超过税前工资!",
      //     type: "warning"
      //   });
      //   this.salarybase = "";
      //   return;
      // }
      // this.accumulation = Number((this.salarybase * this.rate6) / 100);
    },

    // 修改社保基数
    socialbaseC() {
     
      // if (this.salary && (this.socialbase > this.salary)) {
      //   this.socialbase = "";
      //   this.$message({
      //     message: "社保基数不能超过税前工资!",
      //     type: "warning"
      //   });
      //   return;
      // }
      // this.provide = Number((this.socialbase * this.rate1) / 100);
      // this.medicals = Number((this.socialbase * this.rate2) / 100);
      // this.unemployment = Number((this.socialbase * this.rate3) / 100);
      // this.industrial = Number((this.socialbase * this.rate4) / 100);
      // this.fertility = Number((this.socialbase * this.rate5) / 100);
      // this.social =
      //   this.provide +
      //   this.medicals +
      //   this.unemployment +
      //   this.industrial +
      //   this.fertility;
    },

    // 修改公积金比例
    rateChange6() {
      this.accumulation = Number((this.salarybase * this.rate6) / 100);
    },

    priceChange() {
      this.discountShow6 = Number((this.medical / 12).toFixed(2));
    },
    calculate() {
      if (!this.salary || this.salary == 0) {
        this.$message({
          message: "请输入税前工资!",
          type: "warning"
        });
        return;
      }
      if (Number(this.salary) < Number(this.socialbase)) {
        this.$message({
          message: "社保基数不能超过税前工资!",
          type: "warning"
        });
        this.socialbase = "";
        return;
      }
      if (Number(this.salary) < Number(this.salarybase)) {
        this.$message({
          message: "公积金基数不能超过税前工资!",
          type: "warning"
        });
        this.salarybase = "";
        return;
      }
      if (this.salary) {
        this.base1 = Number(
          ((this.socialbase || this.salary) * this.rate1) / 100
        );
        this.base2 = Number(
          ((this.socialbase || this.salary) * this.rate2) / 100
        );
        this.base3 = Number(
          ((this.socialbase || this.salary) * this.rate3) / 100
        );
        this.base4 = Number(
          ((this.socialbase || this.salary) * this.rate4) / 100
        );
        this.base5 = Number(
          ((this.socialbase || this.salary) * this.rate5) / 100
        );
        this.accumulation = Number(
          ((this.salarybase || this.salary) * this.rate6) / 100
        );
        this.social =
          this.base1 + this.base2 + this.base3 + this.base4 + this.base5;
        let quickNum =
          this.salary -
          this.social -
          this.accumulation -
          5000 -
          (this.discountShow1 +
            this.discountShow2 +
            this.discountShow3 +
            this.discountShow4 +
            this.discountShow5 +
            this.discountShow6);
        if (quickNum > 0) {
          this.quick(quickNum);
          let income = Number(
            (quickNum * this.Percentage - this.quickNum).toFixed(2)
          );
          this.income = income;
          this.result = Number(
            (
              this.salary -
              this.income -
              this.social -
              this.accumulation
            ).toFixed(2)
          );
          this.showMap();
          this.resultPage = true;
        } else {
          this.income = 0;
          this.result = Number(
            (this.salary - this.social - this.accumulation).toFixed(2)
          );
          this.showMap();
          this.resultPage = true;
        }
      }
    },

    handleChange(value) {
      this.searchCity(value[1]);
    },
    handleChange1(value) {
      this.haveCity = value;
      if (value.length) {
        this.discountShow1 = Number(this.discount1);
      } else {
        this.discountShow1 = 0;
      }
    },
    handleChange2(value) {
      if (value.length) {
        this.discountShow2 = Number(value[0]);
      } else {
        this.discountShow2 = 0;
      }
    },
    handleChange3(value) {
      if (value.length) {
        this.discountShow3 = Number(value[0] * value[1]);
      } else {
        this.discountShow3 = 0;
      }
    },
    handleChange4(value) {
      if (value.length) {
        this.discountShow4 = Number(value[0]);
      } else {
        this.discountShow4 = 0;
      }
    },
    handleChange5(value) {
      if (value.length == 1) {
        this.discountShow5 = Number(value[0]);
      } else if (value.length == 2) {
        this.discountShow5 = Number(value[0] * (value[1] / 100));
      } else if (!value.length) {
        this.discountShow5 = 0;
      }
    },
    handleChange6(value) {
      if (value.length) {
        this.haveMedical = true;
        this.discountShow6 = Number((this.medical / 12).toFixed(2));
      } else {
        this.discountShow6 = 0;
        this.haveMedical = false;
      }
    },

    // 查找租房折扣金额
    searchCity(name) {
      let city1 = [
        "北京市",
        "天津市",
        "石家庄市",
        "太原市",
        "呼和浩特",
        "沈阳市",
        "大连市",
        "长春市",
        "哈尔滨市",
        "上海市",
        "南京市",
        "杭州市",
        "宁波市",
        "合肥市",
        "福州市",
        "厦门市",
        "南昌市",
        "济南市",
        "青岛市",
        "郑州市",
        "长沙市",
        "广州市",
        "深圳市",
        "南宁市",
        "海口市",
        "重庆市",
        "成都市",
        "贵阳市",
        "昆明市",
        "拉萨市",
        "西安市",
        "兰州市",
        "西宁市",
        "银川市",
        "乌鲁木齐市"
      ];
      let city2 = [
        "唐山市",
        "秦皇岛市",
        "邯郸市",
        "保定市",
        "张家口市",
        "大同市",
        "包头市",
        "赤峰市",
        "鞍山市",
        "抚顺市",
        "盘锦市",
        "吉林市",
        "齐齐哈尔市",
        "大庆市",
        "无锡市",
        "徐州市",
        "常州市",
        "苏州市",
        "南通市",
        "连云港市",
        "淮安市",
        "盐城市",
        "扬州市",
        "镇江市",
        "泰州市",
        "宿迁市",
        "温州市",
        "湖州市",
        "绍兴市",
        "台州市",
        "芜湖市",
        "蚌埠市",
        "淮南市",
        "淮北市",
        "阜阳市",
        "宿州市",
        "六安市",
        "毫州市",
        "莆田市",
        "泉州市",
        "龙岩市",
        "赣州市",
        "宜春市",
        "抚州市",
        "上饶市",
        "淄博市",
        "枣庄市",
        "东营市",
        "烟台市",
        "廊坊市",
        "济宁市",
        "泰安市",
        "威海市",
        "日照市",
        "莱芜市",
        "临沂市",
        "德州市",
        "聊城市",
        "滨州市",
        "菏泽市",
        "开封市",
        "洛阳市",
        "平顶山市",
        "安阳市",
        "新乡市",
        "漯河市",
        "南阳市",
        "商丘市",
        "信阳市",
        "十堰市",
        "宜昌市",
        "襄阳市",
        "鄂州市",
        "荆州市",
        "衡阳市",
        "岳阳市",
        "常德市",
        "益阳市",
        "永州市",
        "珠海市",
        "汕头市",
        "佛山市",
        "江门市",
        "湛江市",
        "茂名市",
        "肇庆市",
        "惠州市",
        "阳江市",
        "清远市",
        "东莞市",
        "中山市",
        "潮州市",
        "揭阳市",
        "柳州市",
        "桂林市",
        "钦州市",
        "贵港市",
        "玉林市",
        "贺州市",
        "来宾市",
        "自贡市",
        "泸州市",
        "绵阳市",
        "遂宁市",
        "内江市",
        "乐山市",
        "南充市",
        "眉山市",
        "宜宾市",
        "广安市",
        "达州市",
        "巴中市",
        "资阳市",
        "六盘水市",
        "遵义市",
        "安顺市",
        "毕节市",
        "曲靖市",
        "宝鸡市",
        "咸阳市",
        "渭南市",
        "安康市",
        "天水市",
        "武威市"
      ];
      if (!this.city[0]) {
        name = "杭州市";
      }
      var user;
      user = city1.find(user => {
        return user === name;
      });
      var user2;
      user2 = city2.find(user2 => {
        return user2 === name;
      });
      if (user) {
        this.discount1 = 1500;
      } else if (user2) {
        this.discount1 = 1100;
      } else {
        this.discount1 = 800;
      }
    },
    // 计算速算扣除数
    quick(num) {
      if (num <= 3000) {
        (this.quickNum = 0), (this.Percentage = 0.03);
      } else if (num > 3000 && num <= 12000) {
        (this.quickNum = 210), (this.Percentage = 0.1);
      } else if (num > 12000 && num <= 25000) {
        (this.quickNum = 1410), (this.Percentage = 0.2);
      } else if (num > 25000 && num <= 35000) {
        (this.quickNum = 2660), (this.Percentage = 0.25);
      } else if (num > 35000 && num <= 55000) {
        (this.quickNum = 4410), (this.Percentage = 0.3);
      } else if (num > 55000 && num <= 80000) {
        (this.quickNum = 7160), (this.Percentage = 0.35);
      } else if (num > 80000) {
        (this.quickNum = 15160), (this.Percentage = 0.45);
      }
    },

    // 生成饼图
    //echart
    showMap() {
      this.chartColumn = echarts.init(document.getElementById("circle"));
      this.chartColumn.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        toolbox: {
          show: true,
          feature: {
            magicType: {
              show: true,
              type: ["pie", "funnel"],
              option: {
                funnel: {
                  x: "25%",
                  width: "50%",
                  funnelAlign: "left",
                  max: 1548
                }
              }
            }
          }
        },
        calculable: true,
        series: [
          {
            name: "个税计算器",
            type: "pie",
            radius: "80%",
            center: ["50%", "50%"],
            color: ["rgba(82, 196, 26, 1)", "#FAAD14", "rgba(245, 34, 45, 1)"],
            label: {
              normal: {
                position: "inner",
                show: false
              }
            },
            data: [
              {
                value: this.result ? this.result : 0,
                name: "税后工资"
              },
              {
                // value: this.list3.taxableIncome ? this.list3.taxableIncome : 0,
                value: this.income ? this.income : 0,
                name: "个人所得税"
              },
              {
                // value: this.list3.taxableAmount ? this.list3.taxableAmount : 0,
                value:
                  this.accumulation + this.social
                    ? this.accumulation + this.social
                    : 0,
                name: "五险一金"
              }
            ]
          }
        ]
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
.title {
  font-size: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  margin: 24px 0 0 30px;
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.counter {
  min-width: 1070px;
  margin: 30px 30px 0 30px;
}

.counter-item {
  position: relative;
  width: 49.5%;
  height: 690px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.item-t-container {
  margin-top: 24px;
  margin-bottom: 16px;
}

.counter-item-t {
  height: 136px;
  border-bottom: 1px solid #D9D9D9;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
}

.counter-item-b {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block-t {
  padding: 24px 0 0 30px;
}

.block-b {
  padding: 16px 0 0 30px;
}

>>>.el-cascader {
  width: 240px;
  height: 36px;
  line-height: 36px;
}

>>>.el-input__inner {
  height: 36px;
}

>>>.el-input__icon {
  line-height: 36px;
}

>>>.el-input-group__append, .el-input-group__prepend {
  padding: 0 16px;
}

.block-append {
  display: inline-block;
  width: 240px;
}

.block-appends {
  width: 135px;
  margin-left: 16px;
}

.block-append-b {
  display: inline-block;
  width: 425px;
}

.item-title {
  width: 102px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px 0px 0px 4px;
  border: 1px solid rgba(217, 217, 217, 1);
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box;
}

.item-title1 {
  border-right: 1px solid rgba(255, 255, 255, 1);
}

.item-title2 {
  border-radius: 0px 4px 4px 0px;
  border-left: 1px solid rgba(255, 255, 255, 1);
}

.counter-center {
  width: 510px;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.input-item {
  margin-bottom: 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.item-btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 54px;
  line-height: 54px;
  background: rgba(98, 97, 242, 1);
  border-radius: 0px 0px 8px 8px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.act {
  background: rgba(67, 70, 211, 0.08);
  border: 1px solid rgba(98, 97, 242, 1);
  color: #6261F2;
}

.circle {
  position: relative;
  left: 20px;
  width: 150px;
  height: 150px;
  background: #fff;
}

.circle-text {
  display: flex;
  align-item: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 12px;
}

.counter-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-t-right {
  margin-top: 48px;
}

.social {
  width: 330px;
}

.item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
}

.weight {
  font-weight: 600;
}

.result {
  width: 340px;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
}

.item-border {
  padding-bottom: 16px;
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 10px;
}

.result-txt {
  margin-bottom: 7px;
}

.result-price {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(98, 97, 242, 1);
}

.block-dis {
  padding: 0 0 16px 0;
  position: relative;
  left: -30px;
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(15px);
  opacity: 0;
}

.item-btn:hover {
  background: rgba(98, 150, 255, 1);
}

.ease {
  transition: all 0.3s;
}

.circle-span {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: rgba(82, 196, 26, 1);
  margin-right: 8px;
  border-radius: 3px;
}

.circle-span1 {
  background: #FAAD14;
}

.circle-span2 {
  background: rgba(245, 34, 45, 1);
}

.circle-container {
  display: flex;
  align-item: center;
  font-size: 0;
  margin-top: 48px;
}

.circle-bottom {
  position: relative;
  left: 20px;
  margin-top: 40px;
  margin-left: 35px;
}

.circle-mine {
  display: inline-block;
  width: 160px;
}
</style>