<template>
  <div @click="handleEnterBtnClick(buyStatus)" class="card" v-if="ifGood== true">
    <div class="bought-icon" v-if="buyStatus== 'Y'">已购买</div>
    <div class="card-head">
      <img :src="faceImg" class="icon" />
      <div class="title">{{name}}</div>
    </div>
    <div class="desc">{{remark}}</div>
  </div>
  <div @click="handleEnterUtil(outUrl)" class="card" v-else-if="ifGood== false">
    <div class="bought-icon" v-if="buyStatus== 'Y'">已购买</div>
    <div class="card-head">
      <img :src="imgUrl" class="icon" />
      <div class="title">{{name}}</div>
    </div>
    <div class="desc">{{introduce}}</div>
  </div>
</template>

<script>
import { checkProductPermission, getUUID } from "@/api/api";
import { mapState } from "vuex";
export default {
  props: {
    ifGood: Boolean,
    name: String,
    remark: String,
    faceImg: String,
    id: Number,
    bankId: String,
    specs: Array,
    imgUrl: String,
    outUrl: String,
    introduce: String,
    buyStatus: String
  },
  computed: {
    ...mapState(["companyInfo", "authorization"])
  },
  data: function() {
    return {
      uuid: null
    };
  },
  methods: {
    handleEnterUtil(row) {
      this.$router.push(row);
    },
    async getUUID() {
      const uuidData = await getUUID();
      if (uuidData.msgCode == 1) {
        this.uuid = uuidData.data.uuid;
      } else {
        alert(uuidData.msgInfo);
      }
    },
    async handleEnterBtnClick(buyStatus) {
      //const specId = this.specs[0].id;

      // const permission = await checkProductPermission(
      //   this.authorization,
      //   this.id,
      //   // buyStatus == "Y" ? null : "onTrain" //如果已经购买 则不传onTrain
      //   buyStatus == "Y" //如果已经购买 则不传onTrain
      // );

      if (permission && permission.msgCode == 1) {
        //设置permissionID
        this.$store.commit("UploadTable/setPermissionID", {
          permissionID: permission.data.id
        });
        await this.getUUID();
        this.$router.push({
          name: "choose-table",
          params: {
            productID: this.id,
            bankId: this.bankId,
            productName: this.name,
            uuid: this.uuid
          }
        });
      } else if (permission.msgCode == "1002") {
        this.$store.commit("UploadTable/setPermissionID", {
          permissionID: ""
        });
        this.$confirm("用户未进行企业认证！是否前去认证", "提示", {
          confirmButtonText: "前往完善",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$router.push({ name: "companyApply" });
        });
      } else if (permission.msgCode == "1007") {
        this.$confirm(permission.msgInfo, {
          confirmButtonText: "前往完善",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.$router.push({
              path: "/setUser"
            });
          })
          .catch(() => {});
      } else {
        this.$store.commit("UploadTable/setPermissionID", {
          permissionID: ""
        });
        this.$alert(permission.msgInfo, "错误", {
          type: "error"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  flex-shrink: 0;
  display: block;
  padding: 30px 27px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  .bought-icon {
    width: 68px;
    height: 17px;
    background: rgba(47, 84, 235, 1);
    border-radius: 0px 8px 0px 8px;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 17px;
    font-size: 12px;
    text-align: center;
  }

  .card-head {
    display: flex;
    .icon {
      height: 38px;
    }
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;
      margin-left: 10px;
    }
  }

  .desc {
    margin-top: 5px;
    width: 100%;
    height: 88px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999;
    line-height: 1.7;
  }
}
</style>