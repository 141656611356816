<template>
  <div>
    <div class="z-header">
      <el-breadcrumb separator="/" class="z-breadcrumb">
        <el-breadcrumb-item>指标模型</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/editor/target' }"
          >指标管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>指标详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="z-title">指标详情</div>
    </div>
    <div class="z-detail" v-if="targetDetail">
      <div class="z-detail-title">基础信息</div>
      <div class="z-detail-items z-detail-flex">
        <div class="z-detail-left">
          <div class="z-detail-item">
            <div class="z-detail-names">名称：</div>
            <div v-if="!edit">{{ targetDetail.metaName }}</div>
            <el-input
              v-if="edit"
              v-model.trim="targetDetailEdit.metaName"
              placeholder="请输入内容"
              maxlength="30"
              show-word-limit
            ></el-input>
          </div>
          <div class="z-detail-item cascader">
            <div class="z-detail-names">目录：</div>
            <div v-if="!edit">{{ targetDetail.catalogName }}</div>
            <el-cascader
              v-if="edit"
              :options="homeMenu"
              :props="{ checkStrictly: true, expandTrigger: 'hover' }"
              clearable
              v-model="targetDetailEdit.catalogId"
              ref="refHandle"
            ></el-cascader>
            <div v-if="edit" class="z-edit" @click="addTarget">+新增</div>
          </div>
          <div class="z-detail-item">
            <div class="z-detail-names">适用范围：</div>
            <div>{{ periodList[targetDetail.period] }}</div>
          </div>
        </div>
        <div class="z-detail-left">
          <div class="z-detail-item" :class="{ 'z-detail-itemH': edit }">
            <div class="z-detail-name">类型：</div>
            <div>{{ typeList[targetDetail.dataType] }}</div>
            <!-- <el-select
              v-model="targetDetailEdit.dataType"
              placeholder="请选择"
              v-if="edit"
            >
              <el-option
                v-for="item in typeData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
          </div>
          <div class="z-detail-item" :class="{ 'z-detail-itemH': edit }">
            <div class="z-detail-name">添加人：</div>
            <div>{{ targetDetail.createUserName }}</div>
          </div>
          <div class="z-detail-itemF">
            <div class="z-detail-name">描述：</div>
            <div class="z-detail-nameR" v-if="!edit">
              {{ targetDetail.metaDescription }}
            </div>
            <el-input
              v-if="edit"
              v-model.trim="targetDetailEdit.metaDescription"
              placeholder="请输入"
              type="textarea"
              :rows="2"
              maxlength="100"
              show-word-limit
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="z-detail" v-if="targetDetail">
      <div class="z-detail-title">
        指标编辑
        <span class="z-detail-icon" @click="addTargetData" v-if="edit">
          <i class="el-icon-edit-outline"></i>编辑
        </span>
      </div>
      <div class="z-detail-items">
        <div class="z-detail-target">
          <div v-if="IndexFormula">{{ IndexFormula }}</div>
        </div>
      </div>
    </div>
    <div class="z-detail z-detail-bottom" v-if="targetDetail">
      <div class="z-detail-title">
        特殊情况处理
        <span class="z-detail-icon" @click="addDefault" v-if="edit">
          <i class="el-icon-edit-outline"></i>编辑
        </span>
      </div>
      <div class="z-detail-items">
        <div class="z-detail-un">
          <!-- <div v-if="IndexFormulaAbn">{{ IndexFormulaAbn }}</div> -->
          <div v-if="IndexFormulaAbns.length">
            <span v-for="(item, index) in IndexFormulaAbns" :key="index">
              {{
                item.name == " && "
                  ? " and "
                  : item.name && item.name == " || "
                  ? " or "
                  : item.name
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="z-editorDeital-footer">
      <div
        class="z-editorDeital-editBtn ease btn-right"
        v-if="edit"
        @click="publish"
        data-index="2"
      >
        发布
      </div>
      <div
        class="z-editorDeital-cancelBtn ease btn-right"
        v-if="edit && this.targetDetailEdit.status == 0"
        @click="publish"
        data-index="0"
      >
        存为草稿
      </div>
      <div
        class="z-editorDeital-cancelBtn ease btn-right"
        @click="findPfMeta"
        v-if="!edit"
      >
        编辑
      </div>
      <div
        class="z-editorDeital-cancelBtn ease btn-right"
        @click="findPfMetaData"
        v-if="!edit"
      >
        删除
      </div>
      <div
        class="z-editorDeital-cancelBtn ease btn-right"
        @click="edit = false"
        v-if="edit"
      >
        取消
      </div>
    </div>
    <!-- 创建目录 -->
    <transition name="fades">
      <div
        class="containerMask"
        v-if="showAddMenu"
        @click="showAddMenu = false"
      ></div>
    </transition>
    <transition name="fadee">
      <add-menu
        v-if="showAddMenu"
        @closeAddBox="closeAddBox"
        @addRoleSuccess="addRoleSuccess"
        @addSuccess="addSuccess"
      ></add-menu>
    </transition>
    <!-- 创建指标 -->
    <transition name="fades">
      <div
        class="containerMask"
        v-show="showAddTarget"
        @click="showAddTarget = false"
      ></div>
    </transition>
    <transition name="fadee">
      <add-target
        v-show="showAddTarget"
        @closeAddBoxs="closeAddBoxs"
        :itemListData="itemListDatas"
        :addName="addName"
        @saveItem="saveItem"
        :typeAddType="addType"
        @saveAbnItem="saveAbnItem"
        :list="indexData.list"
        :exceptionList="indexData.exceptionList"
        :id="$route.query.id"
      ></add-target>
    </transition>
  </div>
</template>

<script>
import addMenu from "@/pages/metadata/addmenu";
import addTarget from "./alternative";
import {
  deleteMeta,
  metaDetail,
  getMenu,
  addReleaseIndex,
  updateIndex,
  addIndex,
  findPfMetaDataOccupy,
} from "@/api/editor";
import { itemList, itemListBan } from "./data.js";
import {
  typeList,
  typeData,
  periodList,
  transformation,
} from "@/utils/constant";

export default {
  components: {
    addMenu,
    addTarget,
  },
  data() {
    return {
      type: "",
      input: "",
      edit: false,
      showAddMenu: false,
      checkList: [],
      typeData: typeData,
      typeList: typeList,
      targetDetail: {
        range: [],
      },
      targetDetailEdit: null,
      homeMenu: null,
      indexData: {},
      IndexFormula: "",
      IndexFormulaAbn: "",
      scopeList: ["上期", "上上期"],
      realName: JSON.parse(localStorage.getItem("user")).realName,
      periodList: periodList,
      addTargetType: 2,
      showAddTarget: false,
      itemListDatas: null,
      addName: "",
      addType: 0,
      targetDetailType: 0,
      periodList: ["本期", "本期", "上期", "上上期"],
      IndexFormulaAbns: [],
    };
  },
  mounted() {
    this.getDetail();
    this.getPageMenu();
  },
  methods: {
    saveItem(item) {
      console.log(item);
      // 修改后赋值
      this.IndexFormula = item.formulaItem;
      this.targetDetailEdit.data.zbProperty.formula.expression = item.expression.join(
        ""
      );
      this.targetDetailEdit.data.relations = item.relations;
      this.targetDetailEdit.data.list = item.dataList;
      this.targetDetailEdit.data.exceptionList = item.dataListAbn;
      this.showAddTarget = false;
      this.targetDetailEdit.data.zbProperty.formula.showText = item.formulaItem;
    },
    saveAbnItem(item) {
      console.log(item);
      this.relationsAbnTer = item.relationsAbn;
      this.expressionAbnTer = item.expressionAbn.join("");
      this.showAddTargetTer = false;
      this.exceptionsTer = [
        {
          condition: {
            expression: this.expressionAbnTer,
            relations: this.relationsAbnTer,
            showText: item.formulaItemAbn,
          },
          value: {},
        },
      ];
      if (this.relationsAbnTer.length == 0) {
        this.exceptionsTer = [];
      }
      this.targetDetailEdit.data.zbProperty.exceptions = this.exceptionsTer;
      this.targetDetailEdit.data.list = item.dataList;
      this.targetDetailEdit.data.exceptionList = item.dataListAbn;
      this.detaListTer = item.dataList;
      this.exceptionListTer = item.dataListAbn;
      this.IndexFormulaAbn = item.formulaItemAbn;
      this.showAddTarget = false;
      this.IndexFormulaAbns = item.dataListAbn;
    },
    closeAddBoxs() {
      this.showAddTarget = false;
    },
    addTargetData() {
      this.addType = 0;
      this.addName = "指标编辑";
      this.itemListDatas = itemList;
      this.showAddTarget = true;
    },
    addDefault() {
      this.addType = 1;
      this.addName = "特殊情况处理";
      this.itemListDatas = itemListBan;
      this.showAddTarget = true;
    },
    // 查看是否被使用
    findPfMetaData() {
      let params = {
        entityType: "PF.ZB",
        metaId: this.$route.query.id,
      };
      findPfMetaDataOccupy(params).then((res) => {
        if (res.msgCode == 1) {
          if (!res.data.occupy) {
            this.toDelete();
          } else {
            this.$alert(
              "当前指标正在被" +
                res.data.occupyZBSize +
                "个指标使用," +
                res.data.occupyMXSize +
                "个模型使用",
              "温馨提示",
              {
                confirmButtonText: "知道了",
                callback: (action) => {},
              }
            );
          }
        }
      });
    },
    findPfMeta() {
      let params = {
        entityType: "PF.ZB",
        metaId: this.$route.query.id,
      };
      findPfMetaDataOccupy(params).then((res) => {
        if (res.msgCode == 1) {
          if (!res.data.occupy) {
            this.editIndex();
          } else {
            this.$alert(
              "当前指标正在被" +
                res.data.occupyZBSize +
                "个指标使用," +
                res.data.occupyMXSize +
                "个模型使用",
              "温馨提示",
              {
                confirmButtonText: "知道了",
                callback: (action) => {},
              }
            );
            return false;
          }
        }
      });
    },
    // 编辑指标
    editIndex() {
      this.targetDetail.checkList = [];
      if (!this.indexData.composite) {
        this.targetDetail.compositeBoolean = false;
      } else {
        this.targetDetail.compositeBoolean = true;
      }

      this.targetDetailEdit = JSON.parse(JSON.stringify(this.targetDetail));
      this.targetDetailEdit.data = JSON.parse(this.targetDetailEdit.data);
      if (this.targetDetailEdit.catalog) {
        this.targetDetailEdit.catalogId = this.targetDetailEdit.catalog.split(
          ","
        );
      } else {
        this.targetDetailEdit.catalogId = "";
      }

      this.edit = true;
    },
    addSuccess() {
      this.showAddMenu = false;
      this.getPageMenu();
    },
    getPageMenu() {
      let params = {
        catalogType: "PF.ZB",
        catalogSelectUse: false,
      };
      getMenu(params).then((res) => {
        if (res.msgCode == 1) {
          this.homeMenu = res.data;
          if (!this.homeMenu || this.homeMenu.length < 1) return;
          this.homeMenu = transformation(this.homeMenu);
        }
      });
    },
    checkChange() {
      this.checkPeriod(this.targetDetailEdit.checkList);
      this.composite = this.compositeBoolean[
        this.targetDetailEdit.checkList.length - 1
      ];
    },
    getDetail() {
      let params = {
        metaId: this.$route.query.id,
      };
      metaDetail(params).then((res) => {
        if (res.msgCode == 1) {
          this.targetDetailType = res.data.status;
          this.targetDetail = res.data;
          this.indexData = JSON.parse(res.data.data);
          console.log(this.indexData);
          // 判断征期
          // this.checkPeriodList(this.indexData);
          this.IndexFormula = this.indexData.zbProperty.formula.showText;
          if (
            this.indexData.exceptionList &&
            this.indexData.exceptionList.length > 0
          ) {
            this.IndexFormulaAbn = this.indexData.zbProperty.exceptions[0].condition.showText;
            this.IndexFormulaAbns = this.indexData.exceptionList;
          }
        }
      });
    },
    closeAddBox() {
      this.showAddMenu = false;
    },
    addRoleSuccess() {},
    addTarget() {
      this.showAddMenu = true;
    },
    toDelete() {
      this.$confirm("是否删除该指标?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            metaId: this.$route.query.id,
          };
          deleteMeta(params).then((res) => {
            if (res.msgCode == 1) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              setTimeout(() => {
                this.$router.go(-1);
              }, 500);
            }
          });
        })
        .catch(() => {});
    },
    // 判断本期上期上上期
    checkPeriod(data) {
      if (data.length == 1) {
        this.targetDetailEdit.compositeBoolean = false;
      } else {
        this.targetDetailEdit.compositeBoolean = true;
      }
      if (data.length == 3) {
        this.composites = [
          // this.targetDetailEdit.data.zbProperty.formula.expression
          {
            code:
              this.targetDetailEdit.data.zbProperty.composites.length > 0
                ? this.targetDetailEdit.data.zbProperty.composites[0].code
                : "", //指标ID,修改的时候要带上
            compositeType: "SQ",
            compositeCount: 1, //
            expression: this.targetDetailEdit.data.zbProperty.formula
              .expression, //表达式
            name: this.targetDetailEdit.metaName + "(上期)",
            relations: this.targetDetailEdit.data.relations, //用到的指标和元数据ID
            showText: this.targetDetailEdit.data.zbProperty.formula.showText, //表达式
          },
          {
            code:
              this.targetDetailEdit.data.zbProperty.composites &&
              this.targetDetailEdit.data.zbProperty.composites.length > 1
                ? this.targetDetailEdit.data.zbProperty.composites[1].code
                : "", //指标ID,修改的时候要带上
            compositeType: "SQ",
            compositeCount: 2, //
            expression: this.targetDetailEdit.data.zbProperty.formula
              .expression, //表达式
            name: this.targetDetailEdit.metaName + "(上上期)",
            relations: this.targetDetailEdit.data.relations, //用到的指标和元数据ID
            showText: this.targetDetailEdit.data.zbProperty.formula.showText, //表达式
          },
        ];
      }
      if (data.length == 2 && this.scopeList[0] == data[1]) {
        this.composites = [
          {
            code: this.targetDetailEdit.data.zbProperty.composites
              ? this.targetDetailEdit.data.zbProperty.composites[0].code
              : "", //指标ID,修改的时候要带上
            compositeType: "SQ",
            compositeCount: 1, //
            expression: this.targetDetailEdit.data.zbProperty.formula
              .expression, //表达式
            name: this.targetDetailEdit.metaName + "(上期)",
            relations: this.targetDetailEdit.data.relations, //用到的指标和元数据ID
            showText: this.targetDetailEdit.data.zbProperty.formula.showText, //表达式
          },
        ];
      }
      if (data.length == 2 && this.scopeList[1] == data[1]) {
        this.composites = [
          {
            code: this.targetDetailEdit.data.zbProperty.composites
              ? this.targetDetailEdit.data.zbProperty.composites[0].code
              : "", //指标ID,修改的时候要带上
            compositeType: "SQ",
            compositeCount: 2, //
            expression: this.targetDetailEdit.data.zbProperty.formula
              .expression, //表达式
            name: this.targetDetailEdit.metaName + "(上上期)",
            relations: this.targetDetailEdit.data.relations, //用到的指标和元数据ID
            showText: this.targetDetailEdit.data.zbProperty.formula.showText, //表达式
          },
        ];
      }
    },
    // 发布
    publish(e) {
      // console.log(this.targetDetailEdit.data.relations);
      this.targetDetailEdit.data.relations = this.targetDetailEdit.data.relations.filter(
        (value) => Object.keys(value).length !== 0
      );
      // console.log(newArray);
      this.addTargetType = e.target.dataset.index;
      if (this.addTargetType == 2) {
        if (!this.checkRequired()) return;
      } else {
        if (!this.targetDetailEdit.metaName) {
          this.$message({
            message: "存为草稿时，名称不能为空！",
            type: "warning",
          });
          return;
        }
      }
      this.checkPeriod(this.targetDetailEdit.checkList);
      let params = {
        catalog: this.targetDetailEdit.catalogId.toString(),
        catalogId: this.targetDetailEdit.catalogId[
          this.targetDetailEdit.catalogId.length - 1
        ],
        code: this.$route.query.id, //指标ID
        composite: this.targetDetail.period == 1 ? false : true, //只有本期传false
        dataType: this.targetDetailEdit.dataType, //基础信息类型
        zbProperty: {
          // composites: this.composites || [],
          exceptions: this.targetDetailEdit.data.zbProperty.exceptions || [],
          formula: {
            expression: this.targetDetailEdit.data.zbProperty.formula
              .expression,
            relations: this.targetDetailEdit.data.relations,
            showText: this.IndexFormula,
          },
        },
        entityType: "PF.ZB", //元数据还是指标添加的
        list: this.targetDetailEdit.data.list,
        exceptionList: this.targetDetailEdit.data.exceptionList,
        metaDescription: this.targetDetailEdit.metaDescription, //基础信息描述
        name: this.targetDetailEdit.metaName, //基础信息指标名称
        relations: this.targetDetailEdit.data.relations,
        userId: JSON.parse(localStorage.getItem("user")).id, //	创建或修改人员id
        userName: this.realName, //	创建或修改人员名称
        status: this.addTargetType,
        needCreate: [this.targetDetail.period],
        period: this.targetDetail.period,
      };
      if (this.addTargetType == 0 && this.targetDetailType == 0) {
        // 草稿-草稿走修改
        // 草稿-发布走发布
        // 发布-发布走修改
        updateIndex(params).then((res) => {
          if (res.msgCode == 1) {
            this.$message({
              type: "success",
              message: "修改指标成功!",
            });
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          } else {
            this.$message({
              type: "error",
              message: res.msgInfo,
            });
          }
        });
      } else if (this.addTargetType == 2 && this.targetDetailType == 0) {
        addReleaseIndex(params).then((res) => {
          if (res.msgCode == 1) {
            this.$message({
              type: "success",
              message: "修改指标成功!",
            });
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          } else {
            this.$message({
              type: "error",
              message: res.msgInfo,
            });
          }
        });
      } else if (this.addTargetType == 2 && this.targetDetailType == 2) {
        updateIndex(params).then((res) => {
          if (res.msgCode == 1) {
            this.$message({
              type: "success",
              message: "修改指标成功!",
            });
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          } else {
            this.$message({
              type: "error",
              message: res.msgInfo,
            });
          }
        });
      }
    },

    checkRequired() {
      if (!this.targetDetailEdit.metaName) {
        this.$message({
          message: "请输入名称！",
          type: "warning",
        });
        return false;
      }
      if (this.targetDetailEdit.catalogId.length < 1) {
        this.$message({
          message: "请选择目录！",
          type: "warning",
        });
        return false;
      }
      if (
        !this.targetDetailEdit.dataType &&
        this.targetDetailEdit.dataType != 0
      ) {
        this.$message({
          message: "请选择类型！",
          type: "warning",
        });
        return false;
      }
      if (!this.targetDetailEdit.metaDescription) {
        this.$message({
          message: "请输入描述！",
          type: "warning",
        });
        return false;
      }
      if (!this.IndexFormula) {
        this.$message({
          message: "请输入指标公式！",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    checkPeriodList(item) {
      if (!item.composite) {
        this.targetDetail.range = ["本期"];
        return;
      }
      if (item.composite) {
        let composite = item.zbProperty.composites.map((item) => {
          return item.compositeCount;
        });
        if (composite.join(",") == "1") {
          this.targetDetail.range = this.periodList[1];
        }
        if (composite.join(",") == "2") {
          this.targetDetail.range = this.periodList[2];
        }
        if (composite.join(",") == "1,2") {
          this.targetDetail.range = this.periodList[3];
        }
      }
    },
  },
  watch: {
    targetDetailEdit: {
      handler() {
        if (this.$refs.refHandle) {
          this.$refs.refHandle.dropDownVisible = false; //监听值发生变化就关闭它
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="stylus">
.z-detail {
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 16px 30px 0 30px;
}

.z-detail-title {
  height: 54px;
  line-height: 54px;
  padding-left: 24px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #eeeeee;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.z-detail-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  margin-left: 24px;
  margin-top: 16px;
}

.z-detail-target {
  margin-top: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  margin-left: 24px;
}

.z-detail-un {
  margin-top: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  margin-left: 24px;
}

.z-detail-target24, .z-detail-un24 {
  margin-top: 16px;
}

.z-detail-items {
  padding: 8px 0 24px 0;
  color: rgba(0, 0, 0, 0.6);
}

.z-detail-name {
  width: 75px;
  text-align: right;
  color: rgba(0, 0, 0, 0.4);
}

.z-detail-left {
  width: 50%;
}

.z-detail-flex {
  display: flex;
}

.z-detail-bottom {
  margin-bottom: 100px;
}

.z-detail >>> .el-input__inner {
  height: 36px;
  line-height: 36px;
  width: 400px;
}

.z-detail >>> .el-input {
  width: 400px;
}

.z-detail >>> .el-input__icon {
  line-height: 36px;
}

.cascader >>> .el-input__icon {
  line-height: 40px;
}

.z-detail >>> .el-checkbox-group {
  font-size: inherit;
}

.z-detail >>> .el-checkbox {
  color: rgba(0, 0, 0, 0.6);
}

.z-detail >>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #2f54eb;
}

.z-detail >>> .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #2f54eb;
  border-color: #2f54eb;
}

.z-edit {
  font-size: 14px;
  color: #2f54eb;
  margin-left: 8px;
  cursor: pointer;
}

.z-detail-icon {
  font-size: 14px;
  color: #2f54eb;
  line-height: 20px;
  margin-left: 8px;
  cursor: pointer;
}

.el-icon-edit-outline {
  margin-right: 4px;
}

.range {
  margin-right: 12px;
}

>>>.el-textarea {
  width: 400px;
}

.z-detail-nameR {
  max-width: 550px;
}

.z-detail-itemF {
  display: flex;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  margin-left: 24px;
  margin-top: 16px;
}

.z-detail-itemH {
  line-height: 36px;
}

.z-detail-names {
  text-align: right;
  color: rgba(0, 0, 0, 0.4);
}
</style>