<template>
  <div class="masker">
    <button class="view-more" @click="handleViewMoreClick">
      <i class="el-icon-arrow-down"></i> 查看更多
    </button>
  </div>
</template>

<script>
import router from "@/router";

export default {
  router,
  methods: {
    handleViewMoreClick2() {
      this.onClick();
    }
  }
};
</script>

<style lang="stylus" scoped>
.masker {
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 300px;
  width: 100%;
  background: linear-gradient(rgba(238, 238, 238, 0), rgba(238, 238, 238, 0) 20%, rgba(238, 238, 238, 0.7) 50%, rgba(238, 238, 238, 1));
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .view-more {
    cursor: pointer;
    background: none;
    border-radius: 4px;
    border: 1px solid #2f54eb;
    color: #2f54eb;
    padding: 8px 24px;
    outline: none !important;
    position: absolute;
    bottom: 90px;
    background: #fff;
  }

  .view-more:hover {
    background: #2f54eb;
    color: #fff;
  }
}
</style>