<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
          <el-breadcrumb-item>检测记录</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">检测记录</template>
      </main-header>
    </div>
    <el-form class="filter-body" @submit.native.prevent="search">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="form-item">
            <div class="label">企业名称：</div>
            <el-input
              class="standard-input input"
              v-model="filter.companyName"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeConsumer"
            ></el-input>
          </div>
          <div class="form-item large">
            <div class="label">检测时间：</div>
            <el-date-picker
              class="input"
              v-model="filter.submitTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="search"
            ></el-date-picker>
          </div>
          <div class="form-item">
            <div class="label">所属行业：</div>
            <div class="form-control">
              <el-select
                filterable
                class="input"
                v-model="filter.hydm"
                clearable
                placeholder="请选择"
                @change="search"
              >
                <el-option
                  v-for="item in sencorStatusOptions"
                  :key="item.hyDm"
                  :label="item.hymc"
                  :value="item.hyDm"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="form-item">
            <div class="label">报告状态：</div>
            <div class="form-control">
              <el-select
                class="input"
                v-model="filter.showStatus"
                clearable
                placeholder="请选择"
                @change="search"
              >
                <el-option
                  v-for="item in generateStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <AwaitButton class="btn btn-primary btn-look-up" @click="search">
          查询
        </AwaitButton>
      </div>
    </el-form>
    <div class="content-body">
      <template>
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column width="60px" type="index" label="序号"></el-table-column>
          <el-table-column
            prop="exeTime"
            label="检测时间"
            min-width="12%"
            :show-overflow-tooltip="true"
            sortable
          ></el-table-column>
          <el-table-column
            prop="companyName"
            label="企业名称"
            min-width="30%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="hymc"
            label="所属行业"
            min-width="18%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="estiblishTime"
            label="成立日期"
            min-width="10%"
          ></el-table-column>
          <el-table-column
            prop="interval"
            label="检测区间"
            min-width="10%"
          ></el-table-column>
          <el-table-column prop="reviewer" label="报告状态" min-width="10%">
            <template slot-scope="scope">
              <div v-if="scope.row.showStatus == 0" class="text-gray">
                <span class="circle gray"></span> 待生成
              </div>
              <div v-if="scope.row.showStatus == 1" class="text-cyan">
                <span class="circle blue"></span> 已生成
              </div>
              <div v-if="scope.row.showStatus == 2" class="text-gray-darker">
                <span class="circle gray-darker"></span> 待发布
              </div>
              <div v-if="scope.row.showStatus == 3" class="text-red">
                <span class="circle red"></span> 已删除
              </div>
              <div v-if="scope.row.showStatus == 5" class="text-red">
                <span class="circle red"></span> 生成失败
              </div>
              <div v-if="scope.row.showStatus == 6" class="text-red">
                <span class="circle red"></span> 缺失项目基本信息
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="viewReport" label="操作" width="120">
            <template slot-scope="scope">
              <template>
                <router-link
                  v-if="scope.row.showStatus == 1"
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.programId,
                      action: 'edit',
                    },
                  }"
                  class="manipulator-btn"
                  >查看</router-link
                >
                <button
                  v-if="scope.row.showStatus != 3"
                  @click="handleDeleteClick(scope.row.id)"
                  class="manipulator-btn"
                >
                  删除
                </button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="pagination-wrapper">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[2, 10, 20, 100]"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="totalSize"
      ></el-pagination>
    </div>
    <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false">
      <CensorHistory :uuid="uuid" :nsrsbh="nsrsbh"></CensorHistory>
    </el-drawer>
    <!-- <perfect-info
      :checkParts="checkParts"
      @cancel="cancel"
      :fixData="fixData"
      :formData="formData"
      @fixSuccess="fixSuccess"
    ></perfect-info> -->
    <div class="process-statusC" v-if="locks">
      <processStatus
        :uuid="uuid"
        :nsrsbh="nsrsbh"
        v-loading="coverLoading"
        class="process-statuss"
        :uploadStatus="uploadStatus"
        :classObject="classObject"
        :processInfoStatus="processInfoStatus"
        :processFalse="processFalse"
        @closeProcess="closeProcess"
        :showStatusPro="showStatusPro"
      ></processStatus>
    </div>
  </div>
</template>

<script>
import AwaitButton from "await-button";
import filterCacheMixin from "@/mixins/filterCacheMixin";
import {
  getPdfDataList,
  progressBar,
  retryFlowAdd,
  getDelRuleIdApi,
  getTradeList,
} from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import CensorHistory from "../../components/CensorHistory/CensorHistory";
import processStatus from "./components/processStatus";

import perfectInfo from "./components/perfectInfo.vue";
import { getInvoiceUrl } from "../../../api/api";

const moment = require("moment");

export default {
  mixins: [filterCacheMixin],
  components: {
    AwaitButton,
    MainHeader,
    BreadCrumb,
    CensorHistory,
    perfectInfo,
    processStatus,
  },

  mounted() {
    this.getPdfDataList();
    sessionStorage.removeItem("isSuccessUpload");
    // 查看是否有查看报告权限
    this.checkShowIndex(17);
    //
    if (this.canChangeBillList.includes(this.canChangeBill)) {
      this.canChangeBillData = true;
    }
    this.getTrade();

    sessionStorage.removeItem("threeActiveIndex");
    sessionStorage.removeItem("dataPeriod");
  },

  data() {
    return {
      //
      canChangeBillList: [22, 29, 49],
      canChangeBill:
        localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).firmId,
      canChangeBillData: false,
      //
      showStatusPro: false,
      checkParts: false,
      dataVal: null,
      uploadStatus: null,
      classObject: "",
      processInfoStatus: false,
      processFalse: false,
      isSuccessUpload: sessionStorage.getItem("isSuccessUpload") || false,
      timestamp: "",
      locks: false,
      drawer: false,
      totalSize: 10, //总数
      pageSize: 10,
      pageNo: sessionStorage.getItem("testPageNo")
        ? Number(sessionStorage.getItem("testPageNo"))
        : 0,
      uuid: "",
      nsrsbh: "",
      sencorStatusOptions: [],
      generateStatusOptions: [
        {
          value: 0,
          label: "待生成",
        },
        {
          value: 1,
          label: "已生成",
        },
        // {
        //   value: 2,
        //   label: "待发布",
        // },
        {
          value: 5,
          label: "生成失败",
        },
        {
          value: 6,
          label: "缺失项目基本信息",
        },
      ],
      filter: {
        companyName: "",
        submitTime: [],
        hydm: "",
        showStatus: "",
      },
      tableDataList: null,
      fixData: null,
      // 是否需要补全
      formData: {
        areaBoolean: false,
        kjzdBoolean: false,
        hyBoolean: false,
      },
      closeProcessTag: true,
      coverLoading: false,
      haveShowAuth: true,
      adminStatusNum: localStorage.getItem("adminStatus"),
      // adminStatusNum2: localStorage.getItem("user"),
    };
  },
  methods: {
    getTrade() {
      getTradeList().then((res) => {
        let data = JSON.parse(res.data);
        this.sencorStatusOptions = data;
      });
    },
    reportInvoice(taxNo) {
      this.$router.push({
        path: "/test/test-reportBill",
        query: {
          taxNo: taxNo,
        },
      });
    },
    reportInvoiceBill(val) {
      this.$router.push({
        path: "/tools/billDetail",
        query: {
          taxNo: val.taxNo,
          type: 2,
        },
      });
    },
    getInvoiceUrl(taxNo) {
      return getInvoiceUrl({ taxNo });
    },
    // 权限方法
    checkAuthMethod(id) {
      let getUserAuth = JSON.parse(localStorage.getItem("user")).menuList;
      if (getUserAuth.length == 0) {
        return "none";
      }
      this.roleList = JSON.parse(localStorage.getItem("user")).menuList;
      let roleListId = this.roleList.map((item) => {
        return item.id;
      });
      if (roleListId.indexOf(id) == -1) {
        return false;
      } else {
        return true;
      }
    },
    // 判断是否有查看报告权限
    checkShowIndex(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveShowAuth = false;
      }
    },
    retryAdd(data) {
      //
      this.$confirm("是否重试?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            nsrsbh: data.nsrsbh,
            uuid: data.uuid,
            invoiceAccessStatus: data.invoice_access_status,
            taxAccessStatus: data.tax_access_status,
            taxNo: data.taxNo,
          };
          retryFlowAdd(params).then((res) => {
            if (res.msgCode == 1) {
              this.$message({
                message: "重试成功，请耐心等待！",
                type: "success",
              });
              this.getPdfDataList();
            } else {
              this.$message({
                message: res.msgInfo,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {});
      //
    },
    fixSuccess() {
      this.checkParts = false;
      this.reset();
    },
    showFixFlow(data) {
      if (!data.city_code && !data.province_code && !data.district_code) {
        this.formData.areaBoolean = true;
      }
      if (!data.kjzd) {
        this.formData.kjzdBoolean = true;
      }
      if (!data.industry) {
        this.formData.hyBoolean = true;
      }
      this.fixData = data;
      this.checkParts = true;
    },
    cancel() {
      this.checkParts = false;
    },

    handleCensorBtnLogClick({ uuid, nsrsbh }) {
      this.uuid = uuid;
      this.nsrsbh = nsrsbh;
      this.drawer = true;
    },
    /* 删除功能 */
    async handleDeleteClick(e) {
      this.$confirm("此操作将永久删除该报告，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          getDelRuleIdApi(e).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: res.message,
                type: "success",
              });
              this.getPdfDataList();
            } else {
              this.$message({
                message: res.message,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {});
    },

    search() {
      this.pageNo = 0;
      this.saveFilterToCache(this.filter);
      this.getPdfDataList();
    },
    changeConsumer() {
      if (!this.filter.consumer) {
        this.search();
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPdfDataList();
    },
    handleCurrentChange(val) {
      //debugger
      this.pageNo = val;
      sessionStorage.setItem("testPageNo", this.pageNo);

      this.getPdfDataList();
    },
    reset() {
      this.filter = {
        companyName: "",
        submitTime: [],
      };
      this.getPdfDataList();
    },
    dateFormatter(row, column) {
      return moment(row.exeTime).format("YYYY-MM-DD HH:mm:ss");
    },
    async getPdfDataList() {
      let params = {
        ...this.filter,
        startDate:
          this.filter.submitTime && this.filter.submitTime[0]
            ? moment(this.filter.submitTime[0]).format("YYYY-MM-DD HH:mm:ss")
            : "",
        endDate:
          this.filter.submitTime && this.filter.submitTime[1]
            ? moment(this.filter.submitTime[1]).format("YYYY-MM-DD HH:mm:ss")
            : "",
        pageNum: this.pageNo,
        pageSize: this.pageSize,
      };

      const res = await getPdfDataList(params);
      const resData = res.data;

      this.tableDataList = resData.data.list;
      this.totalSize = resData.data.pageTotal;
    },
  },
  computed: {
    //某些账号ID显示下载按钮
    isShowDownloadInvoice() {
      return false;
      const userInfo = JSON.parse(localStorage.getItem("user"));
      if (process.env.NODE_ENV === "production") {
        return userInfo && (userInfo.id === 249 || userInfo.id === 215);
      } else {
        return userInfo && userInfo.id === 208;
      }
    },
  },
  destroyed() {
    this.locks = false;
    sessionStorage.removeItem("timestamp");
    sessionStorage.removeItem("taxpayerIdProcess");
    clearTimeout(this.n);
  },
};
</script>

<style lang="stylus" scoped>
.pagination-wrapper {
  text-align: right;
  align-items: center;
  height: 36px;
  line-height: 36px;
  margin: 20px 30px 20px 0;
}

.project-input-b {
  position: absolute;
  right: 40px;
  top: 40px;
  font-size: 12px;
  color: red;
}

.project-input .el-input {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.done, .pedding, .delete, .perfect {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 7px;
  margin-bottom: 2px;
}

.done {
  background: rgba(82, 196, 26, 1);
}

.pedding {
  background: rgba(217, 217, 217, 1);
}

.delete {
  background: rgba(245, 34, 45, 1);
}

.perfect {
  background: rgba(250, 173, 20, 1);
}

.paginations {
  float: right;
  margin-right: 20px;
  margin-bottom: 30px;
}

.fixed-item {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 496px;
  height: 100%;
  z-index: 1000;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(251, 251, 251, 1);
  box-shadow: -4px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.ease {
  transition: all 0.3s;
}

.cursion {
  cursor: pointer;
}

.content-isEdit {
  margin-bottom: 16px;
}

>>> #content-isEdit .el-input {
  width: 416px;
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #content-isEdit .el-cascader {
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input__icon {
  line-height: 36px;
}

>>> .form-item .el-input__icon {
  line-height: 36px;
  height: 36px;
}

.paginationNew {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 40px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(238, 238, 238, 1);
  display: inline-block;
}

.circle.gray {
  background: rgba(0, 0, 0, 0.2);
}

.circle.gray-darker {
  background: rgba(0, 0, 0, 0.4);
}

.circle.blue {
  background: #2F54EB;
}

.circle.cyan {
  background: rgba(24, 144, 255, 1);
}

.circle.green {
  background: rgba(82, 196, 26, 1);
}

.circle.red {
  background: rgba(245, 34, 45, 1);
}

.circle.purple {
  background: rgba(153, 47, 235, 1);
}

.circle.yellow {
  background: rgba(250, 173, 20, 1);
}

.text-gray {
  color: rgba(0, 0, 0, 0.2);
}

.text-gray-darker {
  color: rgba(0, 0, 0, 0.4);
}

.text-blue {
  color: rgba(98, 97, 242, 1);
}

.text-cyan {
  color: #2F54EB;
}

.text-green {
  color: rgba(82, 196, 26, 1);
}

.text-red {
  color: rgba(245, 34, 45, 1);
}

.text-purple {
  color: rgba(153, 47, 235, 1);
}

.text-yellow {
  color: rgba(250, 173, 20, 1);
}

.manipulator-btn {
  color: rgba(47, 84, 235, 1);
  background: none;
  border: 0 none;
  cursor: pointer;
  outline-style: none;
  box-shadow: none;
  display: inline-block;
  padding: 0 12px;
  padding-left: 0;
}

.manipulator-btn:hover {
  text-decoration: underline;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px -1px 0px 0px #EEEEEE;
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

>>>.el-range-editor.el-input__inner {
  width: 240px;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.el-input__icon {
  line-height: 28px;
}

>>>.el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

>>>.el-input__icon {
  width: 14px;
}

>>>.el-range-editor.el-input__inner {
  padding: 3px 8px;
}

.showProce {
  color: #2f54eb;
  cursor: pointer;
}

.process-statuss {
  position: fixed;
  top: 20vh;
  left: 50%;
  margin-left: -400px;
}

.process-statusC {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2001;
}

.showProce {
  color: #2f54eb;
  cursor: pointer;
}

.gener {
  color: #2F54EB;
}
.filter-body .inputs-wrapper .inputs-wraper-row .form-item .label{
  width: 85px;
}
</style>
