import { render, staticRenderFns } from "./SetUser.vue?vue&type=template&id=54997ec8&scoped=true&"
import script from "./SetUser.vue?vue&type=script&lang=js&"
export * from "./SetUser.vue?vue&type=script&lang=js&"
import style0 from "@/styles/index.scss?vue&type=style&index=0&id=54997ec8&prod&lang=scss&scoped=true&"
import style1 from "./SetUser.vue?vue&type=style&index=1&id=54997ec8&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54997ec8",
  null
  
)

export default component.exports