<template>
  <div class="detail-item" v-loading="detailLoading">
    <div class="detail-item-top">
      <img
        @click="hiddenDetail"
        class="fixed-item-icon cursion"
        src="@/assets/test/arrow_right.png"
        alt
      />
      <div>目录详情</div>
    </div>
    <div class="detail-content">
      <div class="detail-content-item">
        <div class="content-title">名称：</div>
        <div class="content-text" v-if="!isEditCustom">
          {{ customItems.catalogName }}
        </div>
        <div
          class="content-text content-isEdit addForms"
          id="content-isEdit"
          v-if="isEditCustom"
        >
          <el-input
            v-model="currentEdits.catalogName"
            placeholder="请输入"
            maxlength="20"
            show-word-limit
          ></el-input>
        </div>
      </div>
      <div class="detail-content-item">
        <div class="content-title">类型：</div>
        <div class="content-text" v-if="!isEditCustom">
          {{ menuType[customItems.catalogType] }}
        </div>
        <div
          class="content-text content-isEdit addForms"
          id="content-isEdit"
          v-if="isEditCustom"
        >
          <el-select
            v-model="currentEdits.catalogType"
            placeholder="请选择"
            @change="changeType"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="detail-content-item">
        <div class="content-title">父级：</div>
        <div class="content-text" v-if="!isEditCustom">
          {{ customItems.parentName }}
        </div>
        <div
          class="content-text content-isEdit addForms"
          id="content-isEdit"
          v-if="isEditCustom"
        >
          <el-cascader
            :options="homeMenu"
            :props="{ checkStrictly: true, expandTrigger: 'hover' }"
            clearable
            v-model="currentEdits.catalogList"
            :key="cascaderKey"
          ></el-cascader>
        </div>
      </div>
    </div>
    <div class="content-btn ease" @click="detailEdit" v-show="!isEditCustom">
      编辑
    </div>
    <div class="content-btn ease detailContent" v-show="isEditCustom">
      <div class="detailcancel" @click="removeEdits">取消</div>
      <div class="detailSaves" @click="holds">保存</div>
    </div>
  </div>
</template>

<script>
import { qyStatusList, kjzd, kjzdList } from "./help.js";
import { regionData } from "@/dependencies/element-china-area-data.js";
import { veriFiItem, veriFiCustom, area } from "@/pages/product/utils/limit.js";
import { editCustom } from "@/api/api";
import { getMenu, updateMenuDetail, getMenuDetail } from "@/api/editor";
import {
  typeList,
  typeData,
  metaType,
  menuData,
  transformation,
} from "@/utils/constant";

export default {
  props: {
    customItems: [Object, Array],
    hyData: [Object, Array],
    dataList: [Object, Array],
    conversionArea: [String],
    haveCusEdit: [Boolean],
    toDetailId: [String, Number],
  },
  data() {
    return {
      isEditCustom: false,
      detailLoading: false,
      currentEdits: null,
      addForm: [0, 0, 0, 0, 0, 0, 0],
      qyStatusList: qyStatusList,
      kjzd: kjzd,
      kjzdList: kjzdList,
      city: regionData,
      menuType: menuData,
      options: metaType,
      typeData: ["PF.YSJ", "PF.ZB", "PF.MX"],
      homeMenu: null,
      cascaderKey: 0,
      realName: JSON.parse(localStorage.getItem("user")).realName,
      menuDetailDataName: "",
    };
  },
  methods: {
    changeType() {
      ++this.cascaderKey;
      this.currentEdits.catalogList = "";
      this.getPageMenu(this.currentEdits.catalogType);
    },
    holds() {
      this.detailLoading = true;
      console.log(this.currentEdits.catalogList);
      let params = {
        catalog:
          this.currentEdits.catalogList.length > 0
            ? this.currentEdits.catalogList.join(",")
            : "",
        catalogDescription: "",
        catalogId: this.currentEdits.catalogId,
        catalogName: this.currentEdits.catalogName,
        catalogType: this.currentEdits.catalogType,
        parentId:
          this.currentEdits.catalogList.length > 0
            ? this.currentEdits.catalogList[
                this.currentEdits.catalogList.length - 1
              ]
            : "0",
        updateUserId: JSON.parse(localStorage.getItem("user")).id,
        updateUserName: this.realName,
      };
      updateMenuDetail(params).then((res) => {
        if (res.msgCode == 1) {
          this.currentEdits.catalog =
            this.currentEdits.catalogList.length > 0
              ? this.currentEdits.catalogList.join(",")
              : "";
          this.currentEdits.parentId =
            this.currentEdits.catalogList.length > 0
              ? this.currentEdits.catalogList[
                  this.currentEdits.catalogList.length - 1
                ]
              : "";

          this.getMenuDetails(this.toDetailId);
        } else {
          this.detailLoading = false;
        }
      });
    },
    hiddenDetail() {
      this.$emit("hiddenDetail");
    },
    detailEdit() {
      this.currentEdits = JSON.parse(JSON.stringify(this.customItems));
      if (this.currentEdits.catalog) {
        this.currentEdits.catalogList = this.currentEdits.catalog.split(",");
      } else {
        this.currentEdits.catalogList = "";
      }

      this.getPageMenu(this.currentEdits.catalogType);
      this.isEditCustom = true;
    },
    // 取消编辑
    removeEdits() {
      this.isEditCustom = false;
      this.currentEdits = {};
    },
    getPageMenu(type) {
      let params = {
        catalogType: type,
        catalogSelectUse: true,
      };
      getMenu(params).then((res) => {
        if (res.msgCode == 1) {
          this.homeMenu = res.data;
          if (!this.homeMenu || this.homeMenu.length < 1) return;
          this.homeMenu = transformation(this.homeMenu);
        }
      });
    },

    getMenuDetails(type) {
      if (!type) return;
      let params = {
        catalogId: type,
      };
      getMenuDetail(params).then((res) => {
        this.detailLoading = false;
        if (res.msgCode == 1) {
          this.menuDetailDataName = res.data.parentName;
          this.currentEdits.parentName = this.menuDetailDataName;
          this.$emit("changeSuccess", this.currentEdits);
          this.isEditCustom = false;
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import './common.css';

.detail-item {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  width: 496px;
  height: 100%;
  z-index: 1003;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(251, 251, 251, 1);
  box-shadow: -4px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.detail-item-top {
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid rgba(218, 213, 232, 0.6);
}

.detail-content {
  width: 464px;
  background: rgba(240, 245, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(214, 228, 255, 1);
  margin: 16px 0 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 24px;
  padding-top: 8px;
  box-sizing: border-box;
}

// 屏幕适配
@media screen and (max-height: 800px) {
  .detail-content {
    max-height: 455px;
    overflow-x: hidden;
  }
}

.content-title {
  margin-left: 24px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
}

.content-text {
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.content-isEdit {
}

>>> #content-isEdit .el-input {
  width: 350px;
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #content-isEdit .el-cascader {
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input__icon {
  line-height: 36px;
}

.project-input, .addForms {
  position: relative;
}

.project-input-b {
  position: absolute;
  right: 6px;
  top: 34px;
  font-size: 12px;
  color: red;
}

.content-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  line-height: 60px;
  background: rgba(47, 84, 235, 1);
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  cursor: pointer;
}

.content-btn:hover {
  background: rgba(47, 34, 235, 1);
}

.detailContent {
  display: flex;
  align-item: center;
}

.detailcancel {
  width: 50%;
}

.detailcancel:after {
  content: '|';
  position: relative;
  left: 108px;
  top: 1px;
}

.detailSaves {
  width: 50%;
}

.fixed-item-icon {
  width: 30px;
  height: 30px;
  padding: 20px 8px 16px 20px;
}

.detail-content-item {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}
</style>