<template>
  <div class="main section" v-if="loaded">
    <div class="edit-company" v-if="roleIdList.indexOf(22) > -1 || authorizationFromHouTai">
      <el-button v-if="editType" @click="cancelCompany">取消</el-button>
      <el-button v-if="editType" type="primary" @click="submitCompany">确定</el-button>
      <i v-if="!editType" @click="editCompany" class="editCompany el-icon-edit"></i>
    </div>
    <div class="title-wrapper">
      <!-- <div class="title">
        财税风险检测报告
      </div> -->
      <div class="release-date">
        报告生成时间：{{ data.exeDate }} 检测区间:{{ data.period }}
      </div>
    </div>
    <div class="company-name">企业名称： {{ data.companyName }}</div>
    <div class="basic-infos">
      <div class="basic-info-item">
        <div class="label">所属行业：</div>
        <div class="value" v-if="!editType">{{ data.hymc }}</div>
        <div v-if="editType">
          <el-input v-model="dataEdit.hymc" placeholder="请输入内容"></el-input>
        </div>
      </div>
      <div class="basic-info-item">
        <div class="label">注册资本：</div>
        <div class="value" v-if="!editType">{{ data.regCapital }}</div>
        <div v-if="editType">
          <el-input v-model="dataEdit.regCapital" placeholder="请输入内容"></el-input>
        </div>
      </div>
      <div class="basic-info-item">
        <div class="label">纳税人识别号：</div>
        <div class="value">{{ data.taxNo }}</div>
      </div>

      <div class="basic-info-item">
        <div class="label">纳税人资质：</div>
        <div class="value" v-if="!editType">{{ data.taxpayerQualificationType }}</div>
        <div v-if="editType">
          <el-select v-model="dataEdit.taxpayerQualificationType" placeholder="请选择">
            <el-option
              v-for="item in taxpayerOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="basic-info-item">
        <div class="label">实收资本：</div>
        <div class="value" v-if="!editType">{{ data.actualCapital }}</div>
        <div v-if="editType">
          <el-input v-model="dataEdit.actualCapital" placeholder="请输入内容"></el-input>
        </div>
      </div>
      <div class="basic-info-item">
        <div class="label">进出口信用：</div>
        <div class="value" v-if="!editType">{{ data.managementCategory }}</div>
        <div v-if="editType">
          <el-input
            v-model="dataEdit.managementCategory"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <div class="basic-info-item">
        <div class="label">成立日期：</div>
        <div class="value" v-if="!editType">{{ data.estiblishTime }}</div>
        <div v-if="editType">
          <el-input v-model="dataEdit.estiblishTime" placeholder="请输入内容"></el-input>
        </div>
      </div>
      <div class="basic-info-item">
        <div class="label">核准日期：</div>
        <div class="value" v-if="!editType">{{ data.approvedTime }}</div>
        <div v-if="editType">
          <el-input v-model="dataEdit.approvedTime" placeholder="请输入内容"></el-input>
        </div>
      </div>
      <div class="basic-info-item">
        <div class="label">参保人数：</div>
        <div class="value" v-if="!editType">{{ data.socialStaffNum }}</div>
        <div v-if="editType">
          <el-input v-model="dataEdit.socialStaffNum" placeholder="请输入内容"></el-input>
        </div>
      </div>

      <!--                  <template v-if="data.managementCategory!='无'">-->
      <!--                    <div class="basic-info-item">报关有效期：{{ data.validityDate }}</div>-->
      <!--                    <div class="basic-info-item">海关注销标志：{{ data.status}}</div>-->
      <!--                  </template>-->
    </div>
  </div>
  <div class="main section" v-else>
    <BigPowerLoading height="210px"></BigPowerLoading>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import { getCompanyInfo } from "@/api/threeYears";
export default {
  name: "Summary",
  data() {
    return {
      authorizationFromHouTai: this.$route.query.fromHouTai || "",
      roleIdList: [],
      data: {},
      loaded: false,
      editType: false,
      dataEdit: {},
      taxpayerOptions: [
        {
          value: "增值税小规模纳税人",
          label: "增值税小规模纳税人",
        },
        {
          value: "增值税一般纳税人",
          label: "增值税一般纳税人",
        },
      ],
    };
  },
  async mounted() {
    this.loaded = false;
    await this.loadCompanyInfo();
    this.loaded = true;
    // 
    this.getAuth();
  },
  methods: {
    // 报告权限获取
    getAuth() {
      if (!localStorage.getItem("user")) return;
      var roList = JSON.parse(localStorage.getItem("user")).roleList;
      this.roleIdList = roList.map((item) => {
        if(item) {
          return item.id;
        }
      });
      // 21报告下载  22报告编辑
    },
    async loadCompanyInfo() {
      const res = await getCompanyInfo({
        programId: this.$route.params.uuid,
        desensitizationStatus: this.$route.query.desensitization,
      });
      this.data = res.data;
      this.$emit("companyInfo", this.data);
    },
    submitCompany() {
      this.data = this.dataEdit;
      this.$emit("companyInfo", this.dataEdit);
      this.editType = false;
    },
    editCompany() {
      this.dataEdit = JSON.parse(JSON.stringify(this.data));
      this.editType = true;
    },
    cancelCompany() {
      this.dataEdit = JSON.parse(JSON.stringify(this.data));
      this.editType = false;
      this.$emit("companyInfo", this.data);
    },
    saveCompany() {
      this.editType = false;
    }
  },
};
</script>

<style lang="stylus" scoped>
.title-wrapper
  display flex
  align-items center
  justify-content space-between

  .title
    font-size: 18px;
    font-weight: 400;
    color: #1D2129;
    line-height: 25px;

  .release-date
    font-weight: 400;
    color: #86909C;
    line-height: 20px;

.company-name
  font-size: 24px;
  font-weight: 600;
  color: #1D2129;
  line-height: 33px;
  margin-top 16px
  margin-bottom 24px

.basic-infos
  margin-top 40px
  display flex;
  flex-wrap wrap;

  .basic-info-item
    width 33.3%
    margin-bottom 16px;
    display flex
    align-items center

    .label
      font-size: 14px;
      color: #86909C;
    .value
      font-size: 14px;
      color: #1D2129;

.main{
  width:95%;
  position: relative;

  >>> .el-input {
    width: 240px;
  }
}

.edit-company {
  position: absolute;
  top: 25px;
  right: 25px;
}

.editCompany {
  color: rgb(47, 84, 235);
}
</style>
