<template>
  <div class="app-wrapper">
    <side-bar :icon="icon" class="sidebar-container" />
    <div class="main-container">
      <nav-bar />
      <app-main />
    </div>
    <el-dialog title="通知" :visible.sync="dialogVisible" width="30%" >
      <span>{{ notice.noticeId?notice.content:'' }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="noPrompts">不再提醒</el-button>
        <el-button type="primary" @click="dialogVisible = false">已知晓</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { SideBar, AppMain } from "./components";
import NavBar from "./components/NavBar/NavBar";
import { getAgent, getAgentAi, getLatestNotice,noPrompt } from "@/api/api";
export default {
  name: "Layout",
  data() {
    return {
      icon: "",
      dialogVisible: false,
      notice:{}
    };
  },
  components: {
    NavBar,
    SideBar,
    AppMain,
  },
  mounted() {
    // this.getagent();
    if(localStorage.getItem('islog')){
      this.getNotice()
    }
    window.addEventListener('beforeunload', e => this.destroy())
  },
  destroyed(){
    this.destroy()
  },
  methods: {
    destroy(){
      localStorage.removeItem('islog')
      
    },
    async noPrompts(){
     const res= await noPrompt(this.notice.noticeId)
     if(res.code==200){
      this.dialogVisible=false
     }
    },
    async getNotice() {
      const res = await getLatestNotice()
      console.log(res);
       if(res.data.noticeId!==null){
        this.notice=res.data
        this.dialogVisible=true
       }
    },
    getagent() {
      let params = {
        token: localStorage.getItem("authorization"),
      };
      getAgentAi(params).then((res) => {
        if (res) {
          this.icon = res.backgroundColorImage;
          localStorage.setItem("wx_logo", res.contactQrCode);
          localStorage.setItem("index_logo", res.backgroundColorImage);
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.app-wrapper {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 1280px;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .sidebar-container {
    display: flex;
    flex-direction: column;
    background: #fff;

    // box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
    transition: width 0.28s;
    width: 180px;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    // overflow: hidden;
    a {
      display: inline-block;
      width: 100%;
    }
  }
}

.main-container {
  min-height: 100vh;
  overflow: hidden;
  transition: margin-left 0.28s;
  margin-left: 180px;
  background-color: #fbfbfb;
}
</style>
