import { render, staticRenderFns } from "./AcknowledageTab.vue?vue&type=template&id=f3b99540&scoped=true&"
import script from "./AcknowledageTab.vue?vue&type=script&lang=js&"
export * from "./AcknowledageTab.vue?vue&type=script&lang=js&"
import style0 from "./AcknowledageTab.vue?vue&type=style&index=0&id=f3b99540&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3b99540",
  null
  
)

export default component.exports