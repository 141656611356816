<template>
  <div>
    <div class="wrapper">
      <div class="tabs">
        <div
          class="tab"
          :class="{ active: activeTab == 0 }"
          @click="switchTab(0)"
          v-if="reportType != 3"
        >
          风险检测报告
        </div>
      </div>
      <div class="btns-wrapper" :class="{ reportTypeClass: reportType == 3 }">
        <AwaitButton
          v-if="this.$route.name != 'ResultEditor'"
          :icon="true"
          @click="handleBtnRelease"
          data-status-to="1020"
          >发布
        </AwaitButton>
        <!-- <AwaitButton :icon="true" data-status-to="1020">已发布 </AwaitButton> -->

        <AwaitButton
          v-if="roleIdList.indexOf(22) > -1 || authorizationFromHouTai"
          :icon="true"
          @click="handleBtnSaveClick"
          data-status-to="1020"
          >保存
        </AwaitButton>
        <AwaitButton
          v-if="roleIdList.indexOf(21) > -1 || authorizationFromHouTai"
          :icon="true"
          @click="handleBtnSaveWord"
          >下载word
        </AwaitButton>
        <!-- <div class="add-data">
          房地产行业:
          <span @click="addData">补充数据</span>
        </div> -->
      </div>
    </div>
    <DesensitizationDialog
      ref="DesensitizationDialog"
      @confirm="handleDesensitizationDialogConfirm"
    ></DesensitizationDialog>

    <fangdichan ref="fangdiChan" :zq="companyInfo.zq"></fangdichan>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { saveAs } from "file-saver";
import { mapState, mapActions } from "vuex";
import { updatePdfCatalogData, createPdf } from "@/api/shiwusuo";
import AwaitButton from "await-button";
import DesensitizationDialog from "./DesensitizationDialog";
import { getCheckLogList, updatePdfCompanyInfo } from "../../../api/shiwusuo";
import {
  byTokenGetUser,
  getDesensitizationStatus,
  getIndex,
  postPublishApi,
} from "../../../api/api";
import "mdn-polyfills/MouseEvent";

import fangdichan from "@/pages/Test/UploadTable/components/fangdichan.vue";

const pdfBase = process.env.VUE_APP_PDF_ROOT;
export default {
  props: {
    reportType: [Number, String],
    companyInfo: [Object],
    dataText: [String],
  },
  components: {
    DesensitizationDialog,
    AwaitButton,
    fangdichan
  },
  async mounted() {
    // setTimeout(() => {
    //  this.changeStatus(1019);
    // }, 2000);
    //载入用户信息
    // const res = await byTokenGetUser()
    // this.userInfo = res.data.user;
    this.getAuth();
  },
  data: function () {
    return {
      activeTab: 0,
      desensitizationAvailable: false,
      userInfo: null,
      forceOldDownload: false,
      statistics: null,
      releaseStatus: true,
      releaseStatusOk: "发布",
      roleIdList: [],
      authorizationFromHouTai: this.$route.query.fromHouTai || ""
    };
  },
  methods: {
    addData() {
      this.$refs.fangdiChan.getBaseInfo();
    },
    // 报告权限获取
    getAuth() {
      if (!localStorage.getItem("user")) return;
      var roList = JSON.parse(localStorage.getItem("user")).roleList;
      this.roleIdList = roList.map((item) => {
        if(item) {
          return item.id;
        }
      });
      // 21报告下载  22报告编辑
    },
    // 保存企业信息
    saveCompanyInfo() {
      let params = this.companyInfo;
      params.taxText = this.dataText;
      updatePdfCompanyInfo(params).then((res) => {
        if (res.code != 200) {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    handleBtnSaveWord() {
      // let url = this.$router.push({
      //   name: 'wordResult',
      //   params: {
      //     uuid: this.$route.params.uuid
      //   },
      // });
      // window.open()
      let routeData = this.$router.resolve({
        name: "wordResult",
        params: {
          uuid: this.$route.params.uuid,
        },
      });
      window.open(routeData.href, "_blank");
    },
    ...mapActions("ResultEditor", [
      "updateReviewStatus",
      "updateUnReviewStatus",
      "refresh",
    ]),
    async previewPdf() {
      this.downloadPdf(true);
    },
    //无需审核下载PDF
    async dowloadPdfCheck(e) {
      /**
       * 是否修改了
       * @type {*}
       */
      getIndex(localStorage.getItem("authorization")).then((res) => {
        this.statistics = res.data;
        if (this.statistics.checkNumber == 0 || !this.statistics.checkNumber) {
          this.$confirm(
            "您的检测次数已用完，暂无下载权限，请联系您的专属客户经理续费后即可下载报告！",
            "提示",
            {
              confirmButtonText: "确定",
              type: "warning",
            }
          );
        } else {
          this.dowloadPdfCheckNew();
        }
      });
    },
    async dowloadPdfCheckNew(e) {
      const resData = await getCheckLogList({
        nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
      });
      //脱敏报告是否可用
      const desensitizationRes = await getDesensitizationStatus({
        uuid: this.$route.params.uuid,
      });
      if (desensitizationRes.data && resData.data && resData.data.length == 0) {
        await this.$refs["DesensitizationDialog"].open();
      } else {
        await this.downloadPdf();
      }
    },

    handleDesensitizationDialogConfirm(isDesensitization) {
      this.downloadPdf();
      this.$refs["DesensitizationDialog"].close();
    },
    //无需审核下载PDF/下载（旧）
    async dowloadOldPdfCheck(e) {
      this.forceOldDownload = true;
      await this.dowloadPdfCheck();
    },
    async downloadOldPdf() {
      const loading = this.$loading({
        lock: true,
        text: "PDF下载中,请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const resCreatePdf = await createPdf({
        nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
      });
      loading.close();
      const a = document.createElement("a");
      a.href = this.downloadUrl;
      if (this.$refs["DesensitizationDialog"].form.desensitization) {
        a.href = a.href + "&desensitizationStatus=1";
      } else {
        a.href = a.href + "&desensitizationStatus=0";
      }

      const event = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      a.dispatchEvent(event);
    },
    async downloadPdf(isPreview = false) {
      //三年期打开报告的方式
      if (
        this.$store.state.ResultEditor.openMode === "threeYears" &&
        !this.forceOldDownload
      ) {
        const isDesensitization = this.$refs["DesensitizationDialog"].form.desensitization
          ? 1
          : 0;
        // const token = localStorage.getItem("authorization");
        //
        var token = "";
        if (window.location.href.indexOf("fromHouTai") > -1) {
          token = localStorage.getItem("authorizationFromHouTai");
        } else {
          token = localStorage.getItem("authorization");
        }
        //
        let pdfDownloadUrl = `${pdfBase}/pdfFrontGenerator/shiwusuo/${this.$route.params.uuid}/${this.$route.params.nsrsbh}?token=${token}&desensitization=${isDesensitization}`;
        if (isPreview) {
          pdfDownloadUrl = `${pdfDownloadUrl}&isPreview=1`;
        }
        window.open(
          pdfDownloadUrl,
          "_blank" // <- This is what makes it open in a new window.
        );
        // this.$emit("previewThreeYearsPdf",{
        //   desensitization:this.$refs["DesensitizationDialog"].form.desensitization
        // })
      } else {
        const loading = this.$loading({
          lock: true,
          text: "PDF下载中,请稍后",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        const resCreatePdf = await createPdf({
          nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
          uuid: this.$route.params.uuid,
        });
        loading.close();
        const a = document.createElement("a");
        a.href = this.downloadUrl;
        if (this.$refs["DesensitizationDialog"].form.desensitization) {
          a.href = a.href + "&desensitizationStatus=1";
        } else {
          a.href = a.href + "&desensitizationStatus=0";
        }

        const event = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        a.dispatchEvent(event);
      }
      this.forceOldDownload = false;
    },
    /* 无需审核下载 */
    async handleBtnDownloadWithoutCensor(e) {
      getIndex(localStorage.getItem("authorization")).then((res) => {
        this.statistics = res.data;
        if (this.statistics.checkNumber == 0 || !this.statistics.checkNumber) {
          this.$confirm(
            "您的检测次数已用完，暂无下载权限，请联系您的专属客户经理续费后即可下载报告！",
            "提示",
            {
              confirmButtonText: "确定",
              type: "warning",
            }
          );
        } else {
          this.handleBtnDownloadWithoutCensorOk(e);
        }
      });
    },
    async handleBtnDownloadWithoutCensorOk(e) {
      //审核通过则无法修改报告内容,是否确定通过
      try {
        await this.$confirm("无需审核下载将会越过审核流程,是否确定下载", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        //更新审核状态

        const status = e.target.dataset.statusTo;
        const resData = await this.changeUnReviewStatus(status);
        //debugger
        if (resData.msgCode == 1) {
          await this.dowloadPdfCheck(e);
        } else {
          this.$alert(resData.msgInfo);
        }
      } catch (error) {
        if (error != "cancel") {
          throw error;
        }
      }
    },

    async handleBtnSaveClick(e) {
      let data = this.convertTreeToList();
      await this.saveCompanyInfo();
      // data.push(this.overall);

      try {
        const resData = await updatePdfCatalogData(data, this.$route.params.action);

        if (resData.msgCode == "1") {
          //更新审核状态
          // const status = e.target.dataset.statusTo;

          // await this.changeStatus(status);

          this.$alert("保存成功", "提示", {
            type: "success",
          }).finally(() => {
            this.reload();
            //location.reload();
          });
        } else {
          this.$alert(resData.msgInfo, "提示", {
            type: "error",
          });
        }
      } catch (error) {
        throw error;
      }
    },
    async handleBtnStatusChange(e) {
      const status = e.target.dataset.statusTo;
      await this.changeStatus(status);
    },
    async handleSensorPassChange(e) {
      try {
        await this.$confirm("审核通过则无法修改报告内容,是否确定通过", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        await this.handleBtnStatusChange(e);
      } catch (error) {
        if (error != "cancel") {
          throw error;
        }
      }
    },
    //无需审核更改状态
    async changeUnReviewStatus(status) {
      const action = this.$route.params.action;
      if (!status) {
        alert("error");
      }
      const resData = await this.updateUnReviewStatus({
        nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
        status: status,
        action: action,
      });

      return resData;
    },
    async changeStatus(status) {
      const action = this.$route.params.action;
      if (!status) {
        alert("error");
      }
      await this.updateReviewStatus({
        nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
        status: status,
        action: action,
      });
    },
    convertTreeToList() {
      let ret = [];

      function convert(list) {
        list.forEach((item) => {
          ret.push(item);
          if (item.children && item.children.length > 0) {
            convert(item.children);
          }
          delete item.children;
        });
      }

      convert(cloneDeep(this.treeData));
      //convert(cloneDeep(this.discountPolicyTree));
      ret = cloneDeep(ret).map((v) => {
        if (v.id < 0) {
          v.id = null;
        }
        return v;
      });

      return ret;
    },
    /*重新加载数据*/
    async reload() {
      await this.refresh({
        uuid: this.$route.params.uuid,
        nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
        action: this.$route.params.action,
        isSimple: 0,
        shuijuType: "Sencor",
      });
    },
    switchTab(id) {
      this.activeTab = id;
      this.$emit("switchTab", id);
    },

    async handleBtnRelease() {
      const resData = await postPublishApi(this.$route.params.uuid);
      if (resData.msgCode == "1") {
        // this.releaseStatusOk = "已发布";
        this.$alert("发布成功", "提示", {
          type: "success",
        }).finally(() => {
          this.reload();
          //location.reload();
        });
      } else {
        // this.releaseStatusOk = "已发布";
        // this.$alert(resData.msgInfo, "提示", {
        //   type: "error",
        // });
      }
    },
    // async load() {
    //   const resData = await postPublishApi(this.$route.params.uuid);

    // },
  },

  computed: {
    ...mapState("ResultEditor", [
      "treeData",
      "overall",
      "isDataModified",
      "ReviewStatus",
      "discountPolicyTree",
      "downloadUrl",
    ]),
    isSuperRole() {
      // return this.userInfo && this.userInfo.authorities && this.userInfo.authorities.findIndex(v => v.remark == 1) >= 0
    },
    action() {
      return this.$route.params.action;
    },

    isContentModified() {
      return this.isDataModified;
    },
  },
};
</script>

<style lang="stylus" scoped>
.tabs {
  display: flex;

  .tab {
    width: 180px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
  }

  .tab-home {
    width: 30px;
    font-size: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active {
    color: rgba(47, 84, 235, 1);
    border-bottom: 4px solid rgba(47, 84, 235, 1);
    box-sizing: border-box;
  }
}


.wrapper {
  z-index: 209;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;

  .btns-wrapper {
    padding: 12px 0;
    display: flex;

    .btn {
      background: #2F54EB;
      border-radius: 4px;
      padding: 8px 24px;
      font-size: 14px;
      line-height: 1.5;
      color: #fff;
      margin: 0 8px;
    }

    .btn.disabled {
      background: rgba(217, 217, 217, 1);
      cursor: not-allowed;
    }
  }
}

.reportTypeClass {
  position: fixed;
  background: none;
  box-shadow: 0 0px 0px;
  right: 10px;
  top: -10px;
}

.add-data {
  position: relative;
  top: 6px;
  font-size: 14px;
  color: #86909f;
  margin-right: 6px;

  span {
    color: rgb(47, 84, 235);
    cursor: pointer;
    margin-left: 4px;
  }
}
</style>
