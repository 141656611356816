<template>
  <div>
    <div
      class="loading-wrapper"
      v-loading="loading"
      v-if="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
    ></div>
    <div v-show="haveIndexAuth">
      <div class="container1">
        <div class="item">
          <el-form class="filter-body">
            <div class="inputs-wrapper">
              <div class="inputs-wraper-row">
                <div class="form-item large">
                  <div class="label">检测时间：</div>
                  <el-date-picker
                    class="input"
                    v-model="formItem"
                    @blur="blurDate"
                    @change="getAnalysisDistributeApi"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </div>
                <div class="form-item">
                  <el-radio-group
                    v-model="radio1"
                    @change="changeGroupRadioOne"
                    style="margin-right: 20px"
                  >
                    <el-radio-button label="0">近7天</el-radio-button>
                    <el-radio-button label="1">近30天</el-radio-button>
                  </el-radio-group>
                  <el-radio-group v-model="radio1" @change="changeGroupRadioOne">
                    <el-radio-button label="2">历史累计</el-radio-button>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <div class="item" style="margin-left: 12px">
          <div class="item01">
            <div class="itemNum">
              <div class="item1">检测企业数</div>
              <div class="item2">{{ this.lineData.sectionCheckCompanyNumber }}</div>
            </div>
            <div class="itemNum">
              <div class="item1">涉及行业</div>
              <div class="item2">{{ this.lineData.sectionHyNumber }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="item-chart">
          <div id="chartColumnLine" class="chart" :key="radio1"></div>
        </div>
        <div class="item-chart">
          <div id="chartColumnPie" ref="echarts" class="chartPie" :key="radio1"></div>
          <div id="pieRatio">
            <div class="pieRatioList" v-for="item in pieRatio" :key="item.value">
              <span class="pieRatioItem1">{{ item.value }}</span
              ><span class="pieRatioItem2">{{ item.ratio }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="logo">
          <img src="@/assets/brand/checkLogoNew.png" alt />
        </div>
        <div class="start">
          <div style="margin-bottom: 23px">一键智能检测企业财税系统</div>
          <div class="btn ease" @click="add">立即检测</div>
        </div>
      </div>
    </div>
    <div v-show="!haveIndexAuth" class="noIndex">
      <div class="noIndexImg">
        <img src="@/assets/brand/noIndex.png" alt />
      </div>
      <div>无首页权限</div>
    </div>
  </div>
</template>

<script>
import ECharts from "vue-echarts";
import { getIndexData, getAnalysisDistributeApi } from "@/api/api.js";
import echarts from "echarts";
import moment from "moment";

export default {
  data() {
    return {
      radio1: 2,
      formItem: [],
      lineData: {},
      pieData: {},
      pieDataNumber: {},
      loading: true,
      pieRatio: "",
      haveIndexAuth: true,
    };
  },
  components: { "v-chart": ECharts },
  async mounted() {
    // 判断是否有首页权限
    await this.checkIndex();
    await this.getAnalysisDistributeApi();
    await this.changeGroupRadioOne();
  },
  methods: {
    /* 近七天 */
    async changeGroupRadioOne(v) {
      if (v == 0 || this.radio1 == 0) {
        let times = [];
        this.times = [
          moment().subtract(7, "days").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ];
        this.formItem = this.times;
        this.getAnalysisDistributeApi(this.formItem);
      }
      if (v == 1 || this.radio1 == 1) {
        let times = [];
        this.times = [
          moment().subtract(30, "days").format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ];
        this.formItem = this.times;
        this.getAnalysisDistributeApi(this.formItem);
        // console.log(this.formItem);
      }
      if (v == 2 || this.radio1 == "") {
        this.formItem = "";
        this.getAnalysisDistributeApi(this.formItem);
        // console.log(this.formItem);
      }
    },

    async add() {
      this.$router.push({
        name: "choose-table",
        params: {
          productName: "风险检测",
        },
      });
    },

    blurDate(v) {
      this.radio1 = "";
      this.getAnalysisDistributeApi();
    },
    /* 折线图 */
    async getAnalysisDistributeApi(v) {
      let params = {
        startDate: this.formItem[0],
        endDate: this.formItem[1],
      };
      getAnalysisDistributeApi(params).then((res) => {
        if (res.code == 200 && res.message == "操作成功") {
          this.loading = false;
          this.lineData = res.data;
          this.xdata = res.data.analysisTrendChart2Vo.xdata;
          this.ydataVoList = res.data.analysisTrendChart2Vo.ydataVoList;
          this.pieData = res.data.hyTop10List;
          var chartDomLine = document.getElementById("chartColumnLine");
          var myChartLine = echarts.init(chartDomLine);
          var optionLine;
          optionLine = {
            color: [
              "#73A0FA",
              "#73DEB3",
              "#7585A2",
              "#F7C739",
              "#E8684A",
              "#269A99",
              "#26609A",
              "#F5B46F",
              "#C7CC8A",
              "#F48787",
              "#A28BEE",
              "#F5A34A",
              "#F899D0",
              "#6F82EF",
              "#6FC1AF",
              "#D19EE1",
              "#F5966F",
              "#B5D4A3",
            ],
            title: {
              text: "检测企业数",
              left: "5%",
              top: "5%",
            },
            legend: {
              data: this.legend,
              itemHeight: 3,
              itemWidth: 10,
              icon: "oblong",
              top: "5%",
              right: "6%",
              textStyle: {
                lineHeight: 20,
              },
              itemHeight: 3,
              pageIconSize: 14,
            },

            tooltip: {
              trigger: "axis",
            },
            grid: {
              top: "20%",
              left: "5%",
              right: "5%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: this.xdata,
            },
            yAxis: {
              type: "value",
              axisLabel: {
                formatter: "{value}",
              },
            },
            series: this.ydataVoList.map((v) => {
              return {
                name: v.name,
                type: "line",
                data: v.data.map((i) => {
                  return i.showData;
                }),
              };
            }),
          };
          /* 饼图 */
          var chartDom = document.getElementById("chartColumnPie");
          var myChart = echarts.init(chartDom);
          var option;
          const pieData = [];
          res.data.hyTop10List.map((item) => {
            pieData.push({
              name: item.name,
              value: item.number * 1,
              ratio: item.ratio,
            });
          });
          this.pieRatio = pieData;
          option = {
            color: [
              "#73A0FA",
              "#73DEB3",
              "#7585A2",
              "#F7C739",
              "#E8684A",
              "#269A99",
              "#26609A",
              "#F5B46F",
              "#C7CC8A",
              "#F48787",
              "#A28BEE",
              "#F5A34A",
              "#F899D0",
              "#6F82EF",
              "#6FC1AF",
              "#D19EE1",
              "#F5966F",
              "#B5D4A3",
            ],
            title: {
              text: "前十大行业分布图",
              left: "5%",
              top: "5%",
            },
            tooltip: {
              trigger: "item",
            },
            legend: {
              top: "10%",
              orient: "vertical",
              type: "scroll",
              right: 0,
              top: 130,
              bottom: 20,
              icon: "circle",
              textStyle: {
                color: "#8C8C8C", // 图例文字颜色
                fontSize: 14,
              },
              // formatter: function (name) {
              //   let number, percentage;
              //   for (let i = 0; i < pieData.length; i++) {
              //     if (pieData[i].name === name) {
              //       number = pieData[i].value;
              //       percentage = pieData[i].ratio;
              //     }
              //   }
              //   let arr = [
              //     name + " " + " " + " ",
              //     " " + " " + " " + " " + number,
              //     " " + " " + " " + percentage,
              //   ];
              //   return arr.join(" ");
              // },
            },
            series: [
              {
                name: "前十大行业分布图",
                center: ["30%", "55%"],
                type: "pie",
                radius: ["30%", "50%"],
                label: {
                  show: false,
                  position: "center",
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 24,
                    fontWeight: "bold",
                  },
                },
                data: pieData,
              },
            ],
          };
          option && myChart.setOption(option, true);
          optionLine && myChartLine.setOption(optionLine, true);
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    //
    checkIndex() {
      if (!localStorage.getItem("user")) return;
      let getUserAuth = JSON.parse(localStorage.getItem("user")).menuList;
      if (getUserAuth.length == 0) {
        return false;
      }
      this.roleList = JSON.parse(localStorage.getItem("user")).menuList;
      let roleListId = this.roleList.map((item) => {
        if (item.isSelect) return item.id;
      });
      if (roleListId.indexOf(1) == -1) {
        this.haveIndexAuth = false;
      } else {
        this.haveIndexAuth = true;
      }
    },
  },
  computed: {
    legend() {
      this.xdata.map((v) => {
        return v.name;
      });
    },
    // series() {
    //   return this.ydataVoList.map((v) => {
    //     console.log(v.data);
    //     return {
    //       name: v.name,
    //       type: "line",
    //       data: v.data.map((i) => {
    //         console.log(i.showData);
    //         return i.showData;
    //       }),
    //     };
    //   });
    // },
  },
};
</script>

<style scoped>
.status {
  margin: 16px 30px 0 30px;
}

.noIndex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 792px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 24px 30px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.noIndexImg {
  width: 470px;
  height: 270px;
  margin-bottom: 16px;
}

.noIndexImg img {
  width: 100%;
  height: 100%;
}
/* 新 */
.container1 {
  display: flex;
  margin: 20px 14px 0 20px;
}

.item {
  flex: 1;
  /* height: 84px; */
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-right: 10px;
  /* padding: 0 48px; */
}
.item01 {
  display: flex;
  justify-content: space-between;
  margin: 12px 79px 8px 72px;
}
.itemNum {
  display: flex;
}
.item1 {
  width: 90px;
  height: 18px;
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 73px;
}

.item2 {
  height: 40px;
  font-size: 40px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 60px;
  margin-left: 12px;
}
.item-chart {
  flex: 1;
  height: 420px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-right: 20px;
  /* padding: 0 48px; */
}
.item-chart:last-child {
  margin-right: 0px;
  display: flex;
  justify-content: space-between;
}

/* 下面 */
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
  height: 279px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.logo {
  margin-right: 280px;
}

.start {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.4;
}

.btn {
  /* width: 480px;
  height: 52px; */
  line-height: 79px;
  text-align: center;
  color: #fff;
  text-align: center;
  /* background: rgba(47, 84, 235, 1); */
  /* border-radius: 30px; */
  margin-top: 16px;
  cursor: pointer;
  width: 298px;
  height: 79px;
  background: linear-gradient(180deg, #5c8df7 0%, #2f54eb 100%);
  border-radius: 77px;

  font-size: 24px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
}

.ease {
  transition: all 0.3s;
}
</style>
<style lang="stylus" scoped>
.chartContainer {
  width: 100%;
  height: 468px;
}

.echarts {
  width: 100%;
  height: 100%;
}

.chart {
  width: 100%;
  height: 400px;
}
#chartColumnPie{
  width: 80%;
  height: 400px;
   //width: 60%;
   //height: 340px;
}
#chartColumnLine{
  width: 100%;
  height: 400px;
}
.container {
  //height: 522px;
  background: rgba(255, 255, 255, 1);
  //box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin 20px
  display: flex
}
.title {
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  margin-left: 24px;
}

.time {
  margin: 0 16px;
}
.el-range-editor.el-input__inner {
  height: 36px;
}

>>> .time .el-date-editor .el-range__icon {
  line-height: 28px;
}

>>> .el-date-editor .el-range-separator {
  line-height: 28px;
}

>>> .el-date-editor .el-range__close-icon {
  line-height: 28px;
}

>>> .el-date-editor {
  width: 350px;
  height: 36px;
  line-height: 36px;
}

>>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-input__icon {
  line-height: 36px;
}

.rangeClass {
  padding: 0 10px;
}
.headerOne{
  background: #fff
  margin:10px 0
  border-radius: 8px;
  padding:16px;
  display: flex;
  .tabTime{
    margin 0 18px
  }
}
>>>.el-radio-button__orig-radio:checked+.el-radio-button__inner{
  background-color: #2F54EB;
  border-color: #2F54EB;
}
.label{
  line-height: 35px;
}
.loading-wrapper{
  height 100vh
  position fixed
  z-index 1;
  top 0
  left 0
  bottom 0
  right 0
}
.filter-body .inputs-wrapper .inputs-wraper-row .form-item .label{
  width: 85px;
}
.filter-body{
  margin: 0 0 0 24px;
  -webkit-box-shadow:none;
  margin-top: 0px;
  margin-bottom: null;
}
#pieRatio{
  position:relative
  width: 200px;
  left:20px;
  fontSize: 12px;
    margin-top: 125px;
    color: #8C8C8C;

    .pieRatioList{
      display: flex

      .pieRatioItem1{
        width:70px;
        overflow hidden
        text-overflow: ellipsis
      }
      .pieRatioItem2{
        width:50px;

      }
    }
}
</style>
