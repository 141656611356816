<template>
  <div>
    <bread-crumb>
      <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>风险检测</el-breadcrumb-item>
    </bread-crumb>
    <main-header>
      <template slot="title">风险检测</template>
      <template slot="desc">平台针对不同类型/规模/行业/地区的企业设置多种风险库供企业选择，企业可选择符合自身企业性质的风险库进行税务风险检测</template>
    </main-header>
    <div class="cards" v-loading="isLoading" element-loading-text="拼命加载中">
      <evaluate-card class="card" v-for="table in tables" v-bind="table" :key="table.id"></evaluate-card>

      <div class="placeholder"></div>
      <div class="placeholder"></div>
      <div class="placeholder"></div>
      <div class="placeholder"></div>
      <div class="placeholder"></div>
      <div class="placeholder"></div>
      <div class="placeholder"></div>
      <div class="placeholder"></div>
      <div class="placeholder"></div>
      <div class="placeholder"></div>
    </div>
  </div>
</template>

<script>
import EvaluateCard from "./components/EvaluateCard";

import { requestTableInfo,alreadyBuy } from "@/api/api.js";

import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "../../components/MainHeader";


export default {
  components: {
    EvaluateCard,
    BreadCrumb,
    MainHeader
  },
  data() {
    return {
      tables: [],
      isLoading:true
    };
  },
  async mounted() {
    
    let token = JSON.parse(localStorage.getItem("token")).token;
    const tables = await requestTableInfo({
      token
    });
    this.isLoading = false;
    this.tables = [...tables.data.content];
  }
};
</script>

<style lang="scss" scoped>
.cards {
  display: flex;
  flex-wrap: wrap;
  min-height: 300px;
  justify-content: space-between;
  padding: 9px 30px 0;
  .card {
    width: 288px;
    height: 172px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    padding: 17px;
    box-sizing: border-box;
    margin: 20px 0px;
    transition: 0.3s all;
  }
  .card:hover {
    background: rgba(47, 84, 235, 0.04);
    // border:1px solid rgba(47,84,235,0.48);
  }
  .placeholder {
    width: 288px;
    box-sizing: border-box;
    margin: 20px 0px;
  }
}
</style>