<template>
  <div class="mask-wrapper">
    <div class="mask" v-if="!disable&&show">
      <button class="btn btn-primary" @click="handleBtnEditClick">编辑</button>
      <button class="btn btn-default" @click="handleBtnResetClick">重置</button>
      <button class="btn btn-default" @click="handleBtnHideClick">隐藏</button>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    disable:Boolean,
  },
  methods: {
    handleBtnEditClick() {
      this.$emit("edit");
    },
    handleBtnResetClick() {
      this.$emit("reset");
    },
    handleBtnHideClick() {
      this.$emit("hide");
    },
    handleBtnSaveClick() {
      this.$emit("save");
    },
  },
};
</script>

<style lang="stylus" scoped>
.mask-wrapper {
//   background: rgba(255, 255, 255, 1);
//   box-sizing: border-box;
//   box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
//   border-radius: 16px;
  width: 100%;
  overflow: hidden;
  position: relative;

  .mask {
    position: absolute;
    display: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background: rgba(217, 217, 217, 0.8);
    align-items: center;
    justify-content: center;

    .btn {
      margin: 0 8px;
    }
  }
}

.mask-wrapper:hover {
  >.mask {
    display: flex;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: 16px;
  padding-bottom: 16px;
}
</style>