<template>
  <el-dialog id="status_upload" title="报表确认" :visible.sync="visible" width="772">
    
    <el-tabs class="headercss" type="card">
      <el-tab-pane :label="item.zqZw" v-for="(item,key) in uploadList.data" :key="key">
        <el-tabs class="status_upload1">
          <el-tab-pane
            :label="index+'('+item1.number+'/'+item1.length+')'"
            v-if="typeof item1 == 'object'"
            v-for="(item1,index) in item"
            :key="index"
          >
            <div class="table-container">
              <table class="table">
                <tr>
                  <th v-if="index=='所得税表'">表单编号</th>
                  <th>表单名称</th>
                  <th>状态</th>
                </tr>
                <tr v-for="(v,index1) in item1" :key="index1">
                  <td v-if="index=='所得税表'">{{v.tableNum}}</td>
                  <td>{{v.tableNameCn}}</td>
                  <td v-if="v.uploadStatus== true" class="statusup statusup1">已上传</td>
                  <td v-else class="statusup">未上传</td>
                </tr>
              </table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <div class="redfont">*请仔细检查您上传的报表是否完整</div>
      <el-button
        type="primary"
        class="btnGenerate"
        @click="handleBtnStartClick"
        v-loading="loading1"
      >生成测评报告</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { confirmUploadedTable } from "@/api/api";
import { mapState } from "vuex";

export default {
  data: function() {
    return {
      visible: false,
      newuploadList: [],
      uploadList: [],
      loading1: false
    };
  },
  props: {
    bankId: [String],
    zqs: Array,
    productID: [String, Number]
  },
  computed: {
    ...mapState(["companyInfo"])
  },
  async mounted() {
    //企业信息
    await this.$store.cache.dispatch("loadCompanyInfo");
  },
  methods: {
    async genUploadList() {
      //上传的表格信息
      const params = {
        bankId: this.bankId,
        qykjzd: this.companyInfo.kjzd,
        nsrsbh: this.companyInfo.nsrsbm,
        zqList: this.zqs,
        goodsId: this.productID
      };

      const uploadedTableData = await confirmUploadedTable(params);

      //生成的弹框显示报表
      this.uploadList = uploadedTableData;
      for (var i in this.uploadList.data) {
        for (var j in this.uploadList.data[i]) {
          if (typeof this.uploadList.data[i][j] == "object") {
            this.newuploadList.push(this.uploadList.data[i][j]);
            let aa = 0;
            for (var m in this.uploadList.data[i][j]) {
              if (this.uploadList.data[i][j][m].uploadStatus == true) {
                aa += 1;
              }
            }
            this.uploadList.data[i][j].number = aa;
          }
        }
      }
    },
    handleBtnStartClick() {
      this.$emit("BtnGenClicked");
    },
    async open() {
      await this.genUploadList();
      this.visible = true;
    }
  }
};
</script>

<style lang="stylus" scoped>
>>> .el-dialog__header {
  
  .el-dialog__title {
    font-size: 16px;
    
    padding: 16px 24px;
    display block;
  }

  border-bottom: 1px solid #EEEEEE;
}

.table-container {
  max-height: 450px;
  overflow-y: auto;

  .table {
    width: 100%;
    margin: 0 auto;
    border-spacing: 0;

    tr {
      th {
        background: #EAEDF7;
        color: #7F9BC9;
        font-weight: normal;
        padding: 15px;
      }

      td {
        padding: 15px;
        border-left: 1px solid #EAEDF7;
      }

      td:last-child {
        border-right: 1px solid #EAEDF7;
      }
    }

    tr:nth-child(odd) {
      td {
        background: #F5F7FC;
      }
    }

    tr:last-child {
      td {
        border-bottom: 1px solid #F5F7FC;
      }
    }

    .statusup {
      text-align: center;
    }

    .statusup1 {
      color: #56D756;
      font-weight: bold;
    }
  }
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .redfont {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(241, 58, 76, 1);
    margin-right: 16px;
  }

  .btnGenerate {
    background: rgba(47, 84, 235, 1);
    border-radius: 4px;
    border: 0 none;
  }

  .btnGenerate:hover {
    background: rgba(47, 84, 235, 0.9);
  }
}
</style>