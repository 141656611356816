<template>
  <div>
    <div class="z-header">
      <el-breadcrumb separator="/" class="z-breadcrumb">
        <el-breadcrumb-item>指标模型</el-breadcrumb-item>
        <el-breadcrumb-item>模型分类</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="z-title">模型分类</div>
    </div>
    <div class="z-filters">
      <div class="z-filter">
        <div class="z-inputItem">
          <div>创建时间：</div>
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="reset"
          >
          </el-date-picker>
        </div>
        <div class="z-inputItem">
          <div>名称：</div>
          <el-input
            v-model.trim="person"
            clearable
            placeholder="请输入"
            @input="changePerson"
            @keyup.enter.native="reset"
          ></el-input>
        </div>
        <div class="z-inputItem">
          <div>添加人：</div>
          <el-input
            v-model.trim="name"
            clearable
            placeholder="请输入"
            @input="changeName"
            @keyup.enter.native="reset"
          ></el-input>
        </div>
      </div>
      <div class="z-search">
        <div class="z-searchBtn ease" @click="reset">搜索</div>
      </div>
    </div>
    <div class="z-addItem ease" @click="addTarget">添加分类</div>
    <div class="z-table">
      <el-table :data="menuDataList" style="width: 100%">
        <el-table-column width="14"> </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          sortable
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="className"
          label="名称"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="createUserName"
          label="添加人"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="catalogName" label="操作" width="150">
          <template slot-scope="scope">
            <span class="z-table-show" @click="toDetail(scope.row)">
              查看
            </span>
            <span class="z-table-delete" @click="deleteItem(scope.row.id)">
              删除
            </span>
          </template>
        </el-table-column>
        <el-table-column width="14"> </el-table-column>
      </el-table>
    </div>
    <!-- 创建目录 -->
    <transition name="fades">
      <div
        class="containerMask"
        v-if="showAddMenu"
        @click="showAddMenu = false"
      ></div>
    </transition>
    <transition name="fadee">
      <add-class
        v-if="showAddMenu"
        @closeAddBox="closeAddBox"
        @addSuccess="addSuccess"
        :type="type"
        :classId="classId"
      ></add-class>
    </transition>

    <div class="pagina">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageno"
        layout="total, prev, pager, next, jumper"
        :total="totalPages"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import addClass from "@/pages/modelClass/addClass";

import {
  getMenuList,
  getMenu,
  addMenuList,
  getMenuDetail,
  deletePfMetaCatalog,
  getClassList,
  deletePfClass,
} from "@/api/editor";
import { formatDateTes } from "./help.js";
import { typeList, typeData, metaType, menuData } from "@/utils/constant";

export default {
  components: {
    addClass,
  },
  data() {
    return {
      type: 1,
      classId: 0,
      menuDataList: null,
      menuList: null,
      showDetail: false,
      showAddMenu: false,
      name: "",
      person: "",
      time: "",
      menu: "",
      pageno: 1,
      totalPages: 0,
      typeData: metaType,
      menuType: menuData,
      menuDetail: null,
      menuLevel: ["暂无层级", "一级目录", "二级目录", "三级目录"],
      toDetailId: "",
    };
  },
  mounted() {
    this.getMenuLists();
  },
  methods: {
    deleteItem(data) {
      this.$confirm("是否删除当前分类？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: data,
          };
          deletePfClass(params).then((res) => {
            if (res.msgCode == 1) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.pageno = 1;
              this.getMenuLists();
            } else {
              this.$message({
                message: res.msgInfo,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {});
    },
    getMenuDetails(type) {
      if (!type) return;
      let params = {
        catalogId: type,
      };
      getMenuDetail(params).then((res) => {
        if (res.msgCode == 1) {
          this.menuDetailData = res.data;
          this.showDetail = true;
        }
      });
    },
    addSuccess() {
      this.showAddMenu = false;
      this.getMenuLists();
    },
    getMenuLists() {
      let params = {
        createUserName: this.name,
        className: this.person,
        endCreateTime: this.time ? this.time[1] : "",
        pageNum: this.pageno,
        pageSize: 10,
        startCreateTime: this.time ? this.time[0] : "",
      };
      getClassList(params).then((res) => {
        if (res.code == 200) {
          this.menuDataList = res.list;
          this.totalPages = res.pageTotal;
          for (let i = 0; i < this.menuDataList.length; i++) {
            this.menuDataList[i].createTime = formatDateTes(
              this.menuDataList[i].createTime
            );
          }
        }
      });
    },
    hiddenDetail() {
      this.showDetail = false;
    },
    toDetail(data) {
      console.log(data);
      this.type = 2;
      this.classId = data.id;
      this.showAddMenu = true;
    },
    addTarget() {
      this.type = 1;
      this.showAddMenu = true;
    },
    closeAddBox() {
      this.showAddMenu = false;
    },
    /***滑动限制***/
    stop() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    /***取消滑动限制***/
    move() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, true); //页面可以滑动
    },
    changeSuccess(val) {
      this.menuDetailData = val;
      this.getMenuLists();
    },
    reset() {
      this.pageno = 1;
      this.getMenuLists();
    },
    changePerson() {
      if (!this.person) {
        this.reset();
      }
    },
    clearTime() {
      if (!this.time) {
        this.reset();
      }
    },
    changeName() {
      if (!this.name) {
        this.reset();
      }
    },
    handleCurrentChange(val) {
      this.pageno = val;
      this.getMenuLists();
    },
  },
  watch: {
    showAddMenu(newData) {
      if (newData) {
        this.stop();
      } else {
        this.move();
      }
    },
  },
};
</script>

<style scoped lang="stylus">
// @import './common.css';
>>>.z-filters .el-input__inner {
  width: 240px;
  height: 36px;
  line-height: 36px;
}

>>>.z-filters .el-input {
  width: 240px;
  height: 36px;
  line-height: 36px;
}

>>>.z-filters .el-input__icon {
  line-height: 30px;
}

.z-inputItems >>>.el-input__icon {
  line-height: 36px;
}

>>>.z-filters .el-date-editor .el-range-separator {
  line-height: 30px;
}

>>>.el-date-editor .el-range__close-icon {
  width: 10px;
}
</style>