<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th v-for="(label, index) in labels" :key="index">{{ label }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in tableData" :key="index">
          <td v-for="(col, index2) in row" :key="index2">

            <span v-if="col == 'button{detail}'"
            class="clickable"
              @click="handleCellClick(index)"
              ><a
                href="#"
                @click.prevent="handleToggleDetailCLick"
                class="link"
                >{{ toggleLabel }}</a
              ></span
            >
            <span v-else v-html="col"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    drawerTriggerIndex: Number,
    showDetails: Boolean,
  },
  computed: {
    tableData() {
      return this.data.data;
    },
    labels() {
      return this.data.labels;
    },
    toggleLabel() {
      return this.showDetails ? "收起" : "展开";
    },
  },
  data() {
    return {};
  },
  methods: {
    handleCellClick(row) {
      this.$emit("cellClick", row);
    },
    handleToggleDetailCLick() {
      this.$emit("update:showDetails", !this.showDetails);
    },
  },
};
</script>

<style lang="stylus" scoped>
.table {
  border-collapse: collapse;
  border-spacing: 0;
  min-width: 819px;
  max-width: 1200px;
  margin-top: 22px;

  td, th {
    text-align: justify;
    font-size: 14px;
    padding: 8px 16px;
    border: 1px solid rgba(238, 238, 238, 1);
    color: rgba(0, 0, 0, 0.6);
    vertical-align: top;
    min-width: 100px;

    .clickable {
      color: #2F54EB;
      cursor: pointer;
    }
  }

  th {
    font-weight: 600;
    background: rgba(240, 245, 255, 1);
  }

  tr:nth-child(even) {
    td {
      background: rgba(245, 245, 245, 1);
    }
  }

  .link {
    color: rgba(47, 84, 235, 1);
  }
}
</style>
