import Vue from 'vue';
import {
  Message,
} from 'element-ui';
// Vue.use(Message);
Vue.prototype.$message = Message;


export const typeList = ["数值型", "字符型", "逻辑型", "日期型"];
export const typeData = [
  {
    value: 0,
    label: "数值型",
  },
  {
    value: 1,
    label: "字符型",
  },
  {
    value: 2,
    label: "逻辑型",
  },
  {
    value: 3,
    label: "日期型",
  },
];
export const metaType = [
  {
    value: "PF.YSJ",
    label: "元数据",
  },
  {
    value: "PF.ZB",
    label: "指标",
  },
  {
    value: "PF.MX",
    label: "模型",
  },
];

export const menuData = {
  "PF.YSJ": "元数据",
  "PF.ZB": "指标",
  "PF.MX": "模型",
};

export const metaTypeList = [
  {
    value: 0,
    label: "数值型",
  },
  {
    value: 1,
    label: "字符型",
  },
  {
    value: 2,
    label: "逻辑型",
  },
  {
    value: 3,
    label: "日期型",
  },
];

export const metaStatusList = [
  {
    value: 2,
    label: "已发布",
  },
  {
    value: 0,
    label: "草稿",
  },
];

export const periodList = [
  ["本期"],
  ["本期", "上期"],
  ["本期", "上上期"],
  ["本期", "上期", "上上期"],
]

export const transformation = function (item) {
  let trees = [];
  for (let i = 0; i < item.length; i++) {
    trees[i] = {};
    trees[i].value = item[i].catalogId;
    trees[i].label = item[i].catalogName;
    if (
      item[i].childNode &&
      item[i].childNode.length > 0
    ) {
      trees[i].children = [];
      for (let j = 0; j < item[i].childNode.length; j++) {
        trees[i].children[j] = {};
        trees[i].children[j].value = item[i].childNode[
          j
        ].catalogId;
        trees[i].children[j].label = item[i].childNode[
          j
        ].catalogName;
        if (
          item[i].childNode[j].childNode &&
          item[i].childNode[j].childNode.length > 0
        ) {
          trees[i].children[j].children = [];
          for (
            let k = 0;
            k < item[i].childNode[j].childNode.length;
            k++
          ) {
            trees[i].children[j].children[k] = {};
            trees[i].children[j].children[k].value = item[
              i
            ].childNode[j].childNode[k].catalogId;
            trees[i].children[j].children[k].label = item[
              i
            ].childNode[j].childNode[k].catalogName;
          }
        }
      }
    }
  }
  return trees;
}

export const transformationMenu = function (item) {
  let trees = [];
  for (let i = 0; i < item.length; i++) {
    trees[i] = {};
    trees[i].value = item[i].catalogId;
    trees[i].label = item[i].catalogName;
    if (
      item[i].childNode &&
      item[i].childNode.length > 0
    ) {
      trees[i].children = [];
      for (let j = 0; j < item[i].childNode.length; j++) {
        trees[i].children[j] = {};
        trees[i].children[j].value = item[i].childNode[
          j
        ].catalogId;
        trees[i].children[j].label = item[i].childNode[
          j
        ].catalogName;
      }
    }
  }
  return trees;
}

export const checkRequiredData = function (item, indexList) {
  console.log(item)
  if (!item.name) {
    Message({
      message: "请输入名称！",
      type: "warning",
    });
    return false;
  }
  if (!item.menu) {
    Message({
      message: "请选择目录！",
      type: "warning",
    });
    return false;
  }
  if (!item.short) {
    Message({
      message: "请输入描述！",
      type: "warning",
    });
    return false;
  }
  if (!item.type && item.type != 0) {
    Message({
      message: "请选择类型！",
      type: "warning",
    });
    return false;
  }
  if (indexList.length < 1) {
    Message({
      message: "请输入指标公式！",
      type: "warning",
    });
    return false;
  }
  return true;
}

export const checkMetaItem = function (item) {
  if (!item.metadata) {
    Message({
      message: "请选择元数据表！",
      type: "warning",
    });
    return false;
  }
  if (!item.tableFields) {
    Message({
      message: "请选择表字段！",
      type: "warning",
    });
    return false;
  }
  if (!item.metaType && item.metaType != 0) {
    Message({
      message: "请选择类型！",
      type: "warning",
    });
    return false;
  }
  if (!item.metaName) {
    Message({
      message: "请输入名称！",
      type: "warning",
    });
    return false;
  }
  if (!item.metaMenu) {
    Message({
      message: "请选择目录！",
      type: "warning",
    });
    return false;
  }
  if (!item.short) {
    Message({
      message: "请输入描述！",
      type: "warning",
    });
    return false;
  }
  return true;
}
