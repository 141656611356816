<template>
  <div>
    <div class="wrapper">
      <ValidationObserver ref="validationObserver">
        <div class="content-wrapper">
          <!-- {{grade[pdfData.grade]}} -->
          <div class="item-content">
            <ValidationProvider class="input-wrapper" name="大标题：" rules="required">
              <template slot-scope="{ classes,errors }">
                <el-input :class="classes" v-model="titleName" placeholder="请输入大标题"></el-input>
                <p class="validate-error">{{ errors[0] }}</p>
              </template>
            </ValidationProvider>
          </div>
        </div>
        <div class="item-right">
          <div class="item-right-title">
            <span class="item-flex-l item-color-l">摘要：</span>
            <span class="item-flex-1 item-color-r">
              <ValidationProvider class="input-wrapper" name="摘要：" rules="required">
                <template slot-scope="{ classes,errors }">
                  <el-input :class="classes" v-model="brief" placeholder="请输入摘要"></el-input>
                  <p class="validate-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </span>
          </div>
          <div class="item-right-title">
            <span class="item-flex-l item-color-l">标题：</span>
            <span class="item-flex-1 item-color-r">
              <ValidationProvider class="input-wrapper" name="标题：" rules="required">
                <template slot-scope="{ classes,errors }">
                  <el-input :class="classes" v-model="title" placeholder="请输入标题"></el-input>
                  <p class="validate-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </span>
          </div>
          <div class="item-right-title">
            <span class="item-flex-l item-color-l">文号：</span>
            <span class="item-flex-1 item-color-r">
              <ValidationProvider class="input-wrapper" name="文号：" rules="required">
                <template slot-scope="{ classes,errors }">
                  <el-input :class="classes" v-model="fileSN" placeholder="请输入文号"></el-input>
                  <p class="validate-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </span>
          </div>
          <div class="item-right-title">
            <span class="item-flex-l item-color-l">出台单位：</span>
            <span class="item-flex-1 item-color-r">
              <ValidationProvider class="input-wrapper" name="出台单位：" rules="required">
                <template slot-scope="{ classes,errors }">
                  <el-input :class="classes" v-model="publishInstitution" placeholder="请输入出台单位"></el-input>
                  <p class="validate-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </span>
          </div>
          <div class="item-right-title">
            <span class="item-flex-l item-color-l">官方公告：</span>
            <span class="item-flex-1 item-color-b">
              <ValidationProvider class="input-wrapper" name="官方公告链接：" rules="">
                <template slot-scope="{ classes,errors }">
                  <el-input
                    :class="classes"
                    v-model="officeAnnouncementLink"
                    placeholder="请输入官方公告链接"
                  ></el-input>
                  <p class="validate-error">{{ errors[0] }}</p>
                </template>
              </ValidationProvider>
            </span>
          </div>
        </div>
        <div class="btn-wrapper">
          <el-button @click="handleBtnCancelClick">取 消</el-button>
          <el-button type="primary" @click="handleBtnAddClick">确 定</el-button>

          <!-- <button class="btn btn-default" @click="handleBtnAddClick">
            <i class="el-icon-circle-plus-outline"></i> 添加优惠政策
          </button>-->
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { cloneDeep, random } from "lodash";
import { mapState, mapMutations } from "vuex";

export default {
  data: function() {
    return {
      titleName: "",
      brief: "",
      title: "",
      fileSN: "",
      publishInstitution: "",
      officeAnnouncementLink: ""
    };
  },
  computed: {
    ...mapState("ResultEditor", ["discountPolicyTree"])
  },
  methods: {
    ...mapMutations("ResultEditor", ["refineOrder", "setDiscountPolicyTree"]),
    resetFrom() {
      this.titleName = "";
      this.brief = "";
      this.title = "";
      this.fileSN = "";
      this.publishInstitution = "";
      this.officeAnnouncementLink = "";
    },
    handleBtnCancelClick() {
      this.$emit("cancel");
    },
    handleBtnAddClick() {
      const discountPolicyTree = cloneDeep(this.discountPolicyTree);
      const children = discountPolicyTree[0].children;

      const _this = this;
      this.$refs["validationObserver"].validate().then(async success => {
        if (!success) {
          return;
        }
        //const children = _this.nodeData.children;
        const pdfData = {
          policyDigest: _this.brief,
          policyTitle: _this.title,
          policySymbol: _this.fileSN,
          promulgatedCompany: _this.publishInstitution,
          relatedLinks: _this.officeAnnouncementLink
        };

        //     const policyItem = {
        //     //id: "",
        //     orderNumber: "",
        //     titleName: "杭州市降低住房公积金比例(市级)",
        //     //gradeMark: "B",
        //     sort: 1,
        //     //parentId: 4254,
        //     //contrastKey: "YHZC",
        //     //uuid: "3cae368294d941788449a82c98638e7d",
        //     //pageNumber: "17",
        //     //saNsrsbh: "1586656166002",
        //     //oldDataText: null,
        //     //dataText: null,
        //     oldPdfData:
        //       '{"city":"杭州市","createTime":1586658264000,"delete":0,"dmCity":"330100","dmProvince":"330000","grade":3,"id":33,"level":5,"policyContent":"各缴存单位：\\n　　为贯彻落实中共杭州市委、杭州市人民政府《关于严格做好疫情防控帮助企业复工复产的若干政策》（市委发〔 2020〕2号）的要求，切实减轻疫情对企业和职工的影响，现就做好疫情防控降低企业住房公积金缴存比例等有关事项通知如下：\\n　　一、受疫情影响、缴存住房公积金确有困难的企业，可申请降低住房公积金缴存比例最低至3%，或申请缓缴住房公积金，期限均不超过12个月。\\n　　二、企业申请时需提交职工代表大会或工会决议书，并说明存在的困难及职工审议表决通过等情况。\\n　　三、降低缴存比例期间或缓缴到期后完成补缴的企业，其所属职工住房公积金视同正常缴存。\\n　　四、本通知自发文之日起实施，有效期至2020年12月31日。中央、省、市出台相关新的支持政策，一并遵照执行。\\n杭州住房公积金管理委员会","policyDigest":"受疫情影响、缴存公积金有困难的企业可申请降低住房公积金缴存比例或缓缴不超过12个月。","policyShortTitle":"杭州市降低住房公积金比例","policySymbol":"杭房公委〔2020〕1号","policyTitle":"杭州住房公积金管理委员会关于做好疫情防控降低企业住房公积金缴存比例等有关事项的通知","promulgatedCompany":"杭州住房公积金管理委员会","province":"浙江省","relatedLinks":"http://gjj.hangzhou.gov.cn/art/2020/2/12/art_1571023_41912891.html","updateTime":1586658264000}',
        //     pdfData:
        //       '{"city":"杭州市","createTime":1586658264000,"delete":0,"dmCity":"330100","dmProvince":"330000","grade":3,"id":33,"level":5,"policyContent":"各缴存单位：\\n　　为贯彻落实中共杭州市委、杭州市人民政府《关于严格做好疫情防控帮助企业复工复产的若干政策》（市委发〔 2020〕2号）的要求，切实减轻疫情对企业和职工的影响，现就做好疫情防控降低企业住房公积金缴存比例等有关事项通知如下：\\n　　一、受疫情影响、缴存住房公积金确有困难的企业，可申请降低住房公积金缴存比例最低至3%，或申请缓缴住房公积金，期限均不超过12个月。\\n　　二、企业申请时需提交职工代表大会或工会决议书，并说明存在的困难及职工审议表决通过等情况。\\n　　三、降低缴存比例期间或缓缴到期后完成补缴的企业，其所属职工住房公积金视同正常缴存。\\n　　四、本通知自发文之日起实施，有效期至2020年12月31日。中央、省、市出台相关新的支持政策，一并遵照执行。\\n杭州住房公积金管理委员会","policyDigest":"受疫情影响、缴存公积金有困难的企业可申请降低住房公积金缴存比例或缓缴不超过12个月。","policyShortTitle":"杭州市降低住房公积金比例","policySymbol":"杭房公委〔2020〕1号","policyTitle":"杭州住房公积金管理委员会关于做好疫情防控降低企业住房公积金缴存比例等有关事项的通知","promulgatedCompany":"杭州住房公积金管理委员会","province":"浙江省","relatedLinks":"http://gjj.hangzhou.gov.cn/art/2020/2/12/art_1571023_41912891.html","updateTime":1586658264000}'
        //     //createTime: 1594483200000,
        //     //updateTime: 1594626780412,
        //     //isDisplay: 1,
        //     //warningLevelId: null,
        //     //notes: null,
        //     //children: [],
        //     //type: 5,
        //     //swzbNoData: null,
        //     //yebYesOrNoData: 0,
        //     //zbSq: null,
        //     //zbBq: null,
        //     //userData: 0,
        //     //editSnapshot: null,
        //     //censorSnapshot: null,
        //     //updateSort: 0
        //   };

        const policyItem = {
          id: "-" + random(0, 9999999),
          orderNumber: this.nextOrderLabel,
          titleName: this.titleName,
          isDisplay: 1,
          type:5,
          userData:1,
          //uuid: this.$route.params.uuid,
          //saNsrsbh: this.$route.params.nsrsbh||this.$route.query.nsrsbh,
          pdfData: JSON.stringify(pdfData),
          oldPdfData: JSON.stringify(pdfData)
          // sort: ++this.nodeData.children[this.nodeData.children.length - 1].sort
        };

        children.push(policyItem);

        this.setDiscountPolicyTree({
          data: discountPolicyTree
        });
        this.refineOrder({ target: "discountPolicyTree" });
        this.$emit("cancel");
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.btn-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding: 10px 6px 20px;
  box-sizing: border-box;
}



.wrapper {
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
}

.content-wrapper {
  display: flex;
  box-sizing: border-box;
  padding: 0 0 16px;
}

.item-right {
  flex-direction: column;
  justify-content: space-between;
  background: rgba(245, 34, 45, 0.03);
  font-size: 14px;
  padding: 24px 28px 34px 18px;
}

.validate-error {
  margin: 0;
  font-size: 12px;
  text-align: left;
}

.item-content {
  padding: 0 28px 0 0;
  display: flex;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.5;
  align-items: flex-start;
  width: 100%;

  .label {
    line-height: 40px;
    font-weight: bold;
    margin-right: 5px;
    color: #606266;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-right: 15px;
  }

  .input-wrapper {
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    flex-direction: column;
  }

  .icon {
    margin-right: 5px;
  }
}

.item-bottom {
  margin-top: 28px;
}

.item-bottom-btn {
  padding: 8px 16px;
  background: rgba(251, 251, 251, 1);
  border-radius: 4px;
  margin-right: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.item-i-margin {
  margin-right: 8px;
}

.item-right-title {
  display: flex;
  line-height: 1.5;
  margin: 4px 0;
}

.item-flex-l {
  display: inline-block;
  width: 85px;
  text-align: right;
}

.item-flex-1 {
  flex: 1;
  line-height: 1.5;
}

.item-color-l {
  color: rgba(0, 0, 0, 0.4);
  line-height: 40px;
}

.item-color-r {
  color: rgba(0, 0, 0, 0.8);
}

.item-color-b {
  color: rgba(47, 84, 235, 1);
  cursor: pointer;
}
</style>
