<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="500px"
    :before-close="close">
    <div class="tuomin">
      <el-checkbox v-model="form.desensitization">对报告中关键的公司名称、税号、地址进行脱敏</el-checkbox>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="handleBtnConfirmClick">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import {getDesensitizationInfo, updateDesensitizationInfo} from "../../../api/api";

export default {
  name: "DesensitizationDialog",
  data() {
    return {
      dialogVisible: false,
      form: {
        desensitization: false
      }
    };
  },
  async mounted() {

  },
  methods: {
    async open() {
      const token = localStorage.getItem("authorization");
      const res = await getDesensitizationInfo({token});
      this.form.desensitization = !!res.data.desensitizationStatus
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    async handleBtnConfirmClick() {
      this.dialogVisible = true
      const token = localStorage.getItem("authorization");
      await updateDesensitizationInfo({
        token, desensitizationStatus: this.form.desensitization ? 1 : 0
      })
      this.$emit("confirm", this.form.desensitization)
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> .el-dialog__body {
  padding 20px 20px
}

>>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color inherit
}
</style>
