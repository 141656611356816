<template>
  <div class="page-flex">
    <div class="page-left ease" @click="select" ref="box">
      <span class="page-select">{{present}}条/页</span>
      <i class="el-icon-caret-bottom page-top" :class="[showSelect ? 'tranformtop': 'tranformbom']"></i>
      <div class="tranPosition">
        <transition name="fade">
          <div class="transionTop" v-if="showSelect"></div>
        </transition>
        <transition name="fade">
          <div class="page-select-bot" v-if="showSelect">
            <div
              class="page-hover ease"
              v-for="(item, index) in selectArr"
              :key="item.index"
              @click.stop="changeItem(index)"
              :class="{addAct: index==addAct}"
            >{{item}}条/页</div>
          </div>
        </transition>
      </div>
    </div>
    <div class="previous circle ease" @click="toPrevious">
      <i class="el-icon-caret-left page43"></i>
    </div>
    <div class="paging">第{{current}}/{{totalPage}}页</div>
    <div class="next circle ease" @click="toNext">
      <i class="el-icon-caret-right page43"></i>
    </div>
    <div class="circle circle-input ease">
      <input
        type="text"
        v-model="input"
        oninput="this.value = this.value.replace(/[^0-9]/g, '');"
        @blur="inputObBlur"
      />
    </div>
    <div class="btn-circle ease">跳转</div>
  </div>
</template>

<script>
export default {
  props: {
    totalNumber: [String, Number],
    selectArr: [Array]
  },

  data() {
    return {
      showSelect: false, //显示隐藏切换每页条数
      // selectArr: ["10", "20", "50", "100"], //可切换每页条数
      present: 10, //当前每页条数
      input: "01", //当前输入框页码
      current: 1, //当前所在页数
      // totalNumber: 200, //总数量
      totalPage: 1, //总页数
      addAct: 0
    };
  },
  created() {
    this.totalPage = Math.floor(this.totalNumber / this.present);
    document.addEventListener("click", e => {
      if (this.$refs.box && !this.$refs.box.contains(e.target)) {
        this.showSelect = false;
      }
    });
  },
  methods: {
    select() {
      this.showSelect = !this.showSelect;
    },

    changeItem(index) {
      this.addAct = index;
      this.present = this.selectArr[index];
      this.showSelect = false;
      this.totalPage = Math.floor(this.totalNumber / this.present);
      if (this.totalPage < 1) {
        this.totalPage = 1;
      }
      this.current = 1;
      this.current < 10
        ? (this.input = "0" + this.current)
        : (this.input = this.current);
    },

    toNext() {
      if (this.current == this.totalPage) {
        return;
      }
      this.current = +this.current + 1;
      this.current < 10
        ? (this.input = "0" + this.current)
        : (this.input = this.current);
    },

    toPrevious() {
      if (this.current == 1) {
        return;
      }
      this.current = +this.current - 1;
      this.current < 10
        ? (this.input = "0" + this.current)
        : (this.input = this.current);
    },

    inputObBlur() {
      if (Number(this.input) < 1) {
        this.current = 1;
        this.input = "01";
        return;
      }
      if (Number(this.input) > this.totalPage) {
        this.current = this.totalPage;
        this.input < 10
          ? (this.input = "0" + this.totalPage)
          : (this.input = "0" + this.totalPage);
        return;
      }
      if (this.input < 10) {
        this.current = Number(this.input);
        this.input = "0" + Number(this.input);
      } else {
        this.current = "0" + Number(this.input);
      }
    }
  }
};
</script>

<style scoped>
.page-flex {
  display: flex;
  align-items: center;
}

.page-left {
  position: relative;
  width: 100px;
  height: 36px;
  line-height: 34px;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px solid rgba(217, 217, 217, 1);
  text-align: center;
}

.page-select {
  margin-right: 4px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.43);
  cursor: pointer;
  text-align: right;
  width: 52px;
  display: inline-block;
}

.page-top {
  position: relative;
  top: 1px;
  color: rgba(0, 0, 0, 0.43);
  cursor: pointer;
}

.page-select-bot {
  position: absolute;
  width: 100px;
  bottom: 44px;
  text-align: center;
  /* border: 1px solid #e4e7ed; */
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin: 5px 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.43);
  z-index: 1005;
  padding: 4px 0;
}

.page-hover {
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1003;
}

.page-hover:hover {
  background: rgba(0, 0, 0, 0.1);
}

.ease {
  transition: all 0.3s;
}

.tranformtop {
  transform: rotate(-180deg);
  transition: 0.3s;
}

.tranformbom {
  transform: rotate(0);
  transition: 0.3s;
}

.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.tranPosition {
  position: relative;
}

.transionTop {
  position: absolute;
  bottom: 34px;
  left: 27px;
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
  transform: rotate(270deg); /*顺时针旋转90°*/
  z-index: 1008;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px solid rgba(217, 217, 217, 1);
  cursor: pointer;
}

.previous {
  margin-left: 24px;
}

.page43 {
  color: rgba(0, 0, 0, 0.43);
}

.paging {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  width: 72px;
  text-align: right;
}

.next {
  margin-right: 24px;
  margin-left: 8px;
}

.circle input {
  width: 100%;
  height: 100%;
  text-align: center;
  background: none;
  outline: none;
  border: none;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.circle input:focus {
  border: none;
}

.circle-input {
  width: 48px;
  height: 36px;
}

.btn-circle {
  width: 56px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(217, 217, 217, 1);
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 8px;
  border-radius: 3px;
  cursor: pointer;
}

.page-left:hover {
  border: 1px solid rgba(0, 0, 0, 0.45);
}

.btn-circle:hover {
  border: 1px solid rgba(0, 0, 0, 0.45);
}

.circle:hover {
  border: 1px solid rgba(0, 0, 0, 0.45);
}

.ease {
  transition: 0.2s all;
}

.addAct {
  color: rgba(0, 0, 0, 0.75);
}
</style>