<template>
  <div>
    <div class="row">
      <div class="item">
        <div class="label">修改人:</div>
        <div class="value">{{recordOfPerson.updateName}}-{{recordOfPerson.updateIdentity}}</div>
      </div>
      <div class="item">
        <div class="label">修改时间：</div>
        <div class="value">{{timeFormater(recordOfPerson.updateTime)}}</div>
      </div>
    </div>
    <div class="row">
      <div class="item">
        <div class="label">修改内容：</div>

        <div class="list-wrapper">
          <template v-if="show">
            <div class="list-item" v-for="log in recordOfPerson.pdfUpdateDataLogList" :key="log.id">
              <i class="el-icon-s-help icon"></i>
              {{log.updateData}}
              <div class="label-box" v-if="log.isUpdateTitleName">标题修改</div>
              <div class="label-box" v-if="log.isUpdateText">文本修改</div>
              <div class="label-box" v-if="log.isUpdateSort">顺序修改</div>
              <div class="label-box" v-if="log.isDisplay">显示</div>
              <div class="label-box" v-if="log.isHide">隐藏</div>
              <div class="label-box" v-if="log.isAdd">新增</div>
              <div class="label-box" v-if="log.isDelete">删除</div>
              <div class="label-box" v-if="log.riskChange">修改风险等级</div>
            </div>
          </template>

          <template>
            <div class="toggle" v-if="!show" @click="toggleVisible">
              <i class="el-icon-arrow-down"></i> 点击展开
            </div>
            <div class="toggle" v-else @click="toggleVisible">
              <i class="el-icon-arrow-up"></i> 点击收起
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require("moment");

export default {
  props: {
    recordOfPerson: Object
  },
  methods: {
    timeFormater(timestamp) {
      return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
    },
    toggleVisible() {
      this.show = !this.show;
    }
  },
  data: function() {
    return {
      show: false
    };
  }
};
</script>

<style lang="stylus" scoped>
.label {
  color: rgba(0, 0, 0, 0.4);
  width: 100px;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;

  .item {
    display: flex;
  }

  .list-wrapper {
    margin-top: 0px;

    .toggle {
      color: #2F54EB;
      cursor: pointer;
      position: relative;
      left: -6px;
    }

    .list-item {
      padding: 12px 0;
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      border-left: 2px solid rgba(214, 228, 255, 1);
      position: relative;
      padding-left: 20px;

      .icon {
        color: #2F54EB;
        position: absolute;
        left: -8px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        background: #fff;
      }

      .label-box {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        line-height: 21px;
        padding: 0px 3px;
        display: inline-block;
        margin: 0 5px;
        background: rgba(251, 251, 251, 1);
        text-align: center;
        border-radius: 4px;
        border: 1px solid rgba(217, 217, 217, 1);
      }
    }
  }
}
</style>
