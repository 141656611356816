<template>
  <div class="block">
    <el-dialog
      title="按商品或劳务分类查询"
      :visible.sync="dialogVisible"
      width="30%"
      :modal="false"
    >
      <el-cascader
        :options="ClassData0"
        :props="setProps"
        clearable
        v-model="classValue1"
        collapse-tags
        v-show="classValueIndex == 0"
        filterable
      ></el-cascader>
      <el-cascader
        :options="ClassData1"
        :props="setProps"
        clearable
        v-model="classValue2"
        collapse-tags
        v-show="classValueIndex == 1"
        filterable
      ></el-cascader>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibleFalse">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    ClassData0: [Array],
    ClassData1: [Array],
    classValueIndex: [Number, String],
  },
  data() {
    return {
      setProps: {
        value: "mergeCoding",
        label: "goodsName",
        multiple: true,
      },
      classValue1: [],
      classValue2: [],
      dialogVisible: false,
      props: { multiple: true },
      options: [
        {
          value: 1,
          label: "东南",
          children: [
            {
              value: 2,
              label: "上海",
              children: [
                { value: 3, label: "普陀" },
                { value: 4, label: "黄埔" },
                { value: 5, label: "徐汇" },
              ],
            },
            {
              value: 7,
              label: "江苏",
              children: [
                { value: 8, label: "南京" },
                { value: 9, label: "苏州" },
                { value: 10, label: "无锡" },
              ],
            },
            {
              value: 12,
              label: "浙江",
              children: [
                { value: 13, label: "杭州" },
                { value: 14, label: "宁波" },
                { value: 15, label: "嘉兴" },
              ],
            },
          ],
        },
        {
          value: 17,
          label: "西北",
          children: [
            {
              value: 18,
              label: "陕西",
              children: [
                { value: 19, label: "西安" },
                { value: 20, label: "延安" },
              ],
            },
            {
              value: 21,
              label: "新疆维吾尔族自治区",
              children: [
                { value: 22, label: "乌鲁木齐" },
                { value: 23, label: "克拉玛依" },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    dialogVisibleFalse() {
      if (this.classValueIndex == 0) {
        var classValue = this.classValue1;
      } else {
        var classValue = this.classValue2;
      }
      let classValueList = [];
      for (let i = 0; i < classValue.length; i++) {
        classValueList.push(classValue[i][[classValue[i].length] - 1]);
      }
      this.$emit("classChange", classValueList);
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.block {
  >>> .el-cascader {
    width: 400px;
  }
}
</style>
