<template>
  <div>
    <div class="bread-crumb-wrapper">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <slot></slot>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.bread-crumb-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  // margin-top 9px;
  padding: 16px 0 16px 30px;
  line-height: 20px;

  >>> .el-breadcrumb {
    .el-breadcrumb__inner.is-link {
      color: #999;
      font-weight: normal;
    }

    .el-breadcrumb__inner.is-link:hover {
      color: #1679d4;
    }

    .el-breadcrumb__inner {
      font-size: 14px;
      color: #666;
      line-height: 20px;
      font-weight: normal;
    }
  }

  >>> .el-breadcrumb__item:last-child {
    .el-breadcrumb__inner {
      font-weight: bold;
    }
  }
}
</style>