import { render, staticRenderFns } from "./SimpleTextContent.vue?vue&type=template&id=5d5282e2&scoped=true&"
import script from "./SimpleTextContent.vue?vue&type=script&lang=js&"
export * from "./SimpleTextContent.vue?vue&type=script&lang=js&"
import style0 from "./SimpleTextContent.vue?vue&type=style&index=0&id=5d5282e2&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d5282e2",
  null
  
)

export default component.exports