import Vue from "vue";
import Vuex from "vuex";
import { state, mutations, actions } from "./store";
import createCache from "vuex-cache";
import VuexReset from "@ianwalter/vuex-reset";

import UploadTable from "@/pages/Test/UploadTable/store";

import ThreeYearResult from "@/pages/Result/ThreeYearResult/store";
import ResultEditor from "@/pages/ResultEditor/store";
import modelEdit from "@/pages/modelEdit/store.js";
//import createLogger from "../../../src/plugins/logger";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    UploadTable,
    ResultEditor,
    modelEdit,
    ThreeYearResult
  },
  state,
  mutations,
  actions,
  strict: debug,
  plugins: [createCache(), VuexReset()]
});
