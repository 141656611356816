<template>
    <div class="mdfpassword">
        <el-card class="usercard">
            <div class="box box1" v-if="hasPwd == 'true' || hasPwd == true">
                <div class="qyname">原密码：</div>
                <div class="name">
                    <el-input placeholder="请输入原密码" type="password" v-model="oldupwd" ></el-input>
                </div>
            </div>
             <div class="box box1">
                <div class="qyname">新密码：</div>
                <div class="name">
                    <el-input placeholder="请输入新密码" type="password" v-model="newupwd"></el-input>
                </div>
            </div>
             <div class="box box1">
                <div class="qyname">确认新密码：</div>
                <div class="name">
                     <el-input placeholder="请再次输入新密码" type="password" v-model="newupwd2"></el-input>                    
                </div>
            </div>
             <el-button type="primary" class="save"  @click="changeupwd">保存</el-button>
        </el-card>
    </div>
</template>
<script>
import {changepw,changepwnew} from '@/api/api'
export default {
    data(){
        return{
            oldupwd:'',
            newupwd:'',
            newupwd2:'',
            hasPwd:false,
        }
    },
    mounted(){
        this.hasPwd = localStorage.getItem('hasPwd');
    },
    methods:{
        changeupwd(){
            
            if(this.hasPwd == 'true' || this.hasPwd == true){
                 if(!this.submit())return 
                let token=JSON.parse(localStorage.getItem('token')).token
                let params={
                    "token":token,
                    "passwdOld":this.oldupwd,	
                    "passwd":this.newupwd
                }
                changepw(params).then(data=>{
                    if(data.msgCode!=='-1'){
                        this.$message.success('修改密码成功,请重新登录');
                        localStorage.setItem('hasPwd',true)
                        this.$router.push('/passport/login')
                    }else{
                        this.$message.error(data.msgInfo);
                    }
                })
            }else{
                   if(!this.submit1())return 
                let token=JSON.parse(localStorage.getItem('token')).token
                let params={
                    "token":token,
                    "password":this.newupwd,	
                }
                changepwnew(params).then(data=>{
                    if(data.msgCode!=='-1'){
                        this.$message.success('设置密码成功,请重新登录')
                         localStorage.setItem('hasPwd',true)
                        this.$router.push('/passport/login')
                    }else{
                        this.$message.error(data.msgInfo)
                    }
                })
            }
        },
        submit(){
            let reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
            if (!this.oldupwd) {
                this.$message.error('请输入旧密码!')
                return false
            }
            if (!this.newupwd) {
                this.$message.error('请输入新密码!')
                return false
            }
            if (!this.newupwd2) {
                this.$message.error('请再次输入密码!')
                return false
            }
            if(this.newupwd!=this.newupwd2){
                this.$message.error('两次输入的密码不一致')
                return false
            }
            return true
        },
        submit1(){
            let reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
            if (!this.newupwd) {
                this.$message.error('请输入新密码!')
                return false
            }
            if (!this.newupwd2) {
                this.$message.error('请再次输入密码!')
                return false
            }
            if(this.newupwd!=this.newupwd2){
                this.$message.error('两次输入的密码不一致')
                return false
            }
            return true
        }
    }
}
</script>
<style src="@/styles/index.scss" lang="scss" scoped></style>
<style lang="stylus" >

</style>