import { Message } from 'element-ui'

export const checkCountList = function (item) {
    // 判断括号是否配对
    let aSignLeft = item.map((item) => {
        return item.name == "(";
    });
    let aSignRight = item.map((item) => {
        return item.name == ")";
    });
    let aSignLeftArr = aSignLeft.filter((item) => {
        return item == true;
    });
    let aSignRightArr = aSignRight.filter((item) => {
        return item == true;
    });
    if (aSignLeftArr.toString() != aSignRightArr.toString()) {
        Message.warning({
            message: "请查看括号是否闭合！",
            type: "warning",
        });
        return;
    }
    for (let i = 0; i < item.length; i++) { }
    // 只输入一个字符
    // if (item.length == 1) {
    //     Message.warning({
    //         message: "请查看指标是否编辑完整！",
    //         type: "warning",
    //     });
    //     return;
    // }
    for (let i = 0; i < item.length; i++) {
        // 1、判断E类型指标元数据前后规则
        if (isChinese(item[i].name)) {
            if (item[i - 1] && isChinese(item[i - 1].name)) {
                Message.warning({
                    message: "两个指标/元数据不能连在一块！",
                    type: "warning",
                });
                return;
            }
            if (item[i + 1] && isChinese(item[i + 1].name)) {
                Message.warning({
                    message: "两个指标/元数据不能连在一块！",
                    type: "warning",
                });
                return;
            }
            // console.log(item[i - 1] ? item[i - 1].name : undefined);
            // console.log(item[i + 1] ? item[i + 1].name : undefined);
        }
        // 2、判断A类型+-*/前后规则
        if (isExit.indexOf(item[i].name) > -1) {
            if (!item[i - 1]) {
                Message.warning({
                    message: "运算符位置有误！",
                    type: "warning",
                });
                return;
            }
            if (!item[i + 1]) {
                Message.warning({
                    message: "运算符位置有误！",
                    type: "warning",
                });
                return;
            }
            if (item[i - 1] && isExit.indexOf(item[i - 1].name) > -1) {
                Message.warning({
                    message: "不能连续两个运算符！",
                    type: "warning",
                });
                return;
            }
            if (item[i + 1] && isExit.indexOf(item[i + 1].name) > -1) {
                Message.warning({
                    message: "不能连续两个运算符！",
                    type: "warning",
                });
                return;
            }
            if (item[i - 1] && isDot.indexOf(item[i - 1].name) > -1) {
                Message.warning({
                    message: "运算符不能与点号连接！",
                    type: "warning",
                });
                return;
            }
            if (item[i + 1] && isDot.indexOf(item[i + 1].name) > -1) {
                Message.warning({
                    message: "运算符不能与点号连接！",
                    type: "warning",
                });
                return;
            }
        }
        // 3、点号的左右规则
        if (isDot.indexOf(item[i].name) > -1) {
            if (!item[i - 1]) {
                Message.warning({
                    message: "点号不能放在最前面！",
                    type: "warning",
                });
                return;
            }
            if (!item[i + 1]) {
                Message.warning({
                    message: "点号后面不能为空！",
                    type: "warning",
                });
                return;
            }
            if (item[i - 1] && isDot.indexOf(item[i - 1].name) > -1) {
                Message.warning({
                    message: "不能有连续两个点号！",
                    type: "warning",
                });
                return;
            }
            if (item[i + 1] && isDot.indexOf(item[i + 1].name) > -1) {
                Message.warning({
                    message: "不能有连续两个点号！",
                    type: "warning",
                });
                return;
            }
            if (item[i - 1] && isChinese(item[i - 1].name)) {
                Message.warning({
                    message: "注意点号与指标/元数据的位置！",
                    type: "warning",
                });
                return;
            }
            if (item[i + 1] && isChinese(item[i + 1].name)) {
                Message.warning({
                    message: "注意点号与指标/元数据的位置2！",
                    type: "warning",
                });
                return;
            }
        }
        // 4、数字1，2，3，4的前后位置
        if (isNumber.indexOf(item[i].name) > -1) {
            if (item[i - 1] && isChinese(item[i - 1].name)) {
                Message.warning({
                    message: "数值与指标/元数据不能连在一块！",
                    type: "warning",
                });
                return;
            }
            if (item[i + 1] && isChinese(item[i + 1].name)) {
                Message.warning({
                    message: "数值与指标/元数据不能连在一块！",
                    type: "warning",
                });
                return;
            }
        }
        // 5、左括号
        if (item[i].name == "(") {
            if (item[i - 1] && item[i - 1].name == ")") {
                Message.warning({
                    message: "括号位置有误！",
                    type: "warning",
                });
                return;
            }
            if (item[i - 1] && isNumber.indexOf(item[i - 1].name) > -1) {
                Message.warning({
                    message: "左括号前不能紧连数值！",
                    type: "warning",
                });
                return;
            }
            if (item[i + 1] && item[i + 1].name == ")") {
                Message.warning({
                    message: "括号内不能为空！",
                    type: "warning",
                });
                return;
            }
            if (item[i - 1] && (item[i - 1].name) == ".") {
                Message.warning({
                    message: "左括号前不能紧连点号！",
                    type: "warning",
                });
                return;
            }
        }
        // 6、右括号
        if (item[i].name == ")") {
            if (item[i + 1] && isNumber.indexOf(item[i + 1].name) > -1) {
                Message.warning({
                    message: "右括号后不能紧连数值！",
                    type: "warning",
                });
                return;
            }
            if (item[i + 1] && (item[i + 1].name) == ".") {
                Message.warning({
                    message: "右括号后不能紧连点号！",
                    type: "warning",
                });
                return;
            }
        }
        // 7、Math.abs
        if (item[i].name == "Math.abs") {
            if (item[i + 1] && item[i + 1].name != "(") {
                Message.warning({
                    message: "Math.abs绝对值计算必须使用括号包裹！",
                    type: "warning",
                });
                return;
            }
            if (!item[i + 1]) {
                Message.warning({
                    message: "Math.abs绝对值计算必须使用括号包裹！",
                    type: "warning",
                });
                return;
            }
        }
    }
    return true;
};
var isChinese = function (temp) {
    var re = /.*[\u4e00-\u9fa5]+.*$/;
    if (re.test(temp)) return true;
    return false;
};
var isExit = ["+", "-", "*", "/"];
var isDot = ["."];
var isNumber = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];