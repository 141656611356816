<template>
  <div class="msgmain">
        <bread-crumb>
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item >消息中心</el-breadcrumb-item>
          </bread-crumb>
    <div class="magtab">
           <div class="qymc">消息中心</div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(item,key) in tagdata2" :name="key.toString()" :key="key" ref="tabpen">
          <span slot="label" class="tabitems">
            <i class="cycle" v-if="readnum[key]>0"></i>
            {{item.cateName}}
          </span>
          <el-collapse
            @change="handleChange(items.id)"
            v-for="(items,keys) in item.childList"
            :key="keys"
          >
            <el-collapse-item>
              <template slot="title">
                <div :class="[items.readStatus != '1'?'active':'','tabtitle']">
                  <i class="cycle" v-if="items.readStatus == '1'"></i>
                  <b>{{items.title}}</b>
                  <span>展开详情</span>
                  <div class="time">{{formatDateTime(items.updateTime)}}</div>
                </div>
              </template>
              <div v-html="items.detail"></div>
            </el-collapse-item>
          </el-collapse>
          <el-pagination
            background
            class="pagecss"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :page-size="pagesize"
            :current-page="currentPage"
            :total="tagdata[key].childList.length"
          ></el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
const base_content = process.env.VUE_APP_API_ROOT;
import axios from "axios";
import { messageList } from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";

import { async } from "q";
export default {
  data() {
    return {
      activeNames: [],
      activeName: "0",
      tagdata: "",
      currentPage: 1, //初始页
      pagesize: 5, //    每页的数据
      readnum: [],
      tagdata2: ""
    };
  },
  watch: {
    readnum: function() {
      this.calcNum(this.pagesize);
      this.$forceUpdate();
    }
  },
  components: {BreadCrumb},
  methods: {
    handleSizeChange: function(size) {
      this.pagesize = size;
      this.calcNum(this.pagesize);
    },
    handleCurrentChange: function(currentPage) {
      this.currentPage = currentPage;
      this.calcNum(this.pagesize);
    },
    handleClick() {
      this.currentPage = 1; //初始页
      this.pagesize = 5; //    每页的数据
      this.calcNum(this.pagesize);
    },
    calcNum(pagesize) {
      let newPage = JSON.parse(JSON.stringify(this.tagdata));
      newPage.map((item, key) => {
        item.childList = item.childList.slice(
          pagesize * this.currentPage - 5,
          pagesize * this.currentPage
        );
      });
      this.tagdata2 = newPage;
    },
    handleChange(val) {
      let that = this;
      axios
        .post(`${base_content}/news/updateStatus/${val}`, {
          // page: 1,
          // size: 9999,
          // type: "2"
        })
        .then(async function(res) {
          if (res.data.msgCode == "1") {
            that.baseTag();
            console.log(that.$refs.tabpen);
            that.$refs.tabpen.map(item => {
              item.$forceUpdate();
            });
          }
        });
      this.$forceUpdate()
    },
    // is-focus
    formatDateTime(inputTime) {
      let date = new Date(inputTime);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let minute = date.getMinutes();
      let second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + "    " + h + ":" + minute + ":" + second;
    },
    baseTag() {
      let that = this;
      let para = {
        type: "2"
      };
      messageList(para)
        .then(async function(res) {
          //消息中心
            try {
              await that.$store.dispatch("loadMessageNumber");
            } catch (error) {
              console.error(error);
            }
          let tagtitles = [];
          let bastdata = res.data.content;
          res.data.content.map((item, key) => {
            item.childList.map((items, keys) => {
              tagtitles.push(items);
            });
          });
          bastdata.unshift({
            cateName: "全部消息",
            childList: tagtitles
          });
          function sortarr(arr) {
            for (var i = 0; i < arr.length - 1; i++) {
              for (var j = 0; j < arr.length - 1 - i; j++) {
                if (arr[j].createTime < arr[j + 1].createTime) {
                  var temp = arr[j];
                  arr[j] = arr[j + 1];
                  arr[j + 1] = temp;
                }
              }
            }
            return arr;
          }
          bastdata.map((item, key) => {
            sortarr(item.childList);
          });
          that.tagdata = bastdata;
          let newPage = JSON.parse(JSON.stringify(that.tagdata));
          newPage.map((item, key) => {
            item.childList = item.childList.slice(
              that.pagesize * that.currentPage - 5,
              that.pagesize * that.currentPage
            );
          });
          that.tagdata2 = newPage;
          let readunm = [];
          bastdata.map((item, key) => {
            readunm[key] = 0;
            item.childList.map(child => {
              if (item.childList.length > 0) {
                if (child.readStatus == "1") {
                  readunm[key] += 1;
                }
              }
            });
          });

          that.readnum = readunm;
          // console.log(JSON.parse(JSON.stringify(readunm)),'778899')
        })
        .then(res => {});
      this.$forceUpdate();
    }
  },

  mounted() {
    this.baseTag();
  }
};
</script>
<style src="@/styles/index.scss" lang="scss" scoped></style>

<style lang="stylus" scoped>
.qymc{
  margin-bottom:10px;
}
.magtab {
  border-radius: 8px;
  width: 96%;
  margin: 10px auto 30px auto;

  .pagecss {
    text-align: right;
    margin :30px 0 60px 0;
    >>>.el-pager {
       .number.active {
        background-color :#2644f7
      }
      .number.active:hover{
        color :#fff
      }
      .number:hover{
        color :#2644f7
      }
    }
  }

  >>>.el-tabs__nav-scroll {
    padding: 0 30px;
  }

  >>>.el-tabs__active-bar {
    height: 36px;
    background-color: #2644f7;
    left:-20px;
    padding:0 20px;
    border-radius:18px;
    bottom :8px;
  }

  >>>.el-tabs__item {
    height: 55px;
    line-height: 55px;
    font-size: 16px;
    color: #555555;
  }
   >>>.el-tabs__item.is-active{
     color :#fff;
     z-index :3;
  }

  >>>.el-tabs__nav-wrap::after {
    height: 0px;
    background-color: #fff;
  }

  >>>.el-tabs__content {
    padding: 0 60px;
    background-color :#fff;
    border-radius:8px;
  }

  >>>.el-collapse-item__header {
    height: auto;
    line-height: 24px;
     .el-collapse-item__arrow{
      margin:-31px 0 8px 3px;
    }
  }

  >>>.el-collapse {
    border: none;
  }

  .tabtitle {
    width: 100%;
    font-size: 14px;
    color: #1a1a1a;
    font-weight: bold;
    position: relative;

    &.active {
      b {
        color: #555555;
      }
    }

    &:hover {
      b {
        transition: all 0.3s ease-in-out 0s;
        color: #2644f7;
      }
    }

    .cycle {
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #f13a4c;
      left: -10px;
      top: 10px;
    }

    span {
      font-weight: normal;
      float: right;
      color: #2644f7;
    }

    .time {
      color: #1a1a1a;
      font-weight: normal;
      display: block;
      color: #555555;
      margin-top: 15px;
    }
  }

  .el-collapse-item__arrow {
    color: #2644f7;
    font-weight: bold;
  }

  .el-collapse-item {
    margin-top: 30px;
  }

  .el-collapse-item__wrap {
    border-bottom: 0px;
  }

  .el-collapse-item__header {
    align-items: baseline;
    border-bottom: 1px dashed #c6c6c6;
    padding-bottom: 20px;
    margin-bottom: 25px;
   
  }

  .el-tabs__content {
    overflow: visible;
  }

  .tabitems {
    position: relative;

    i {
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #f13a4c;
      left: -10px;
      top: 7px;
    }
  }
}
</style>
