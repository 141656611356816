<template>
  <div>
    <div class="z-header">
      <el-breadcrumb separator="/" class="z-breadcrumb">
        <el-breadcrumb-item>指标模型</el-breadcrumb-item>
        <el-breadcrumb-item>目录管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="z-title">目录管理</div>
    </div>
    <div class="z-filters">
      <div class="z-filter">
        <div class="z-inputItem">
          <div>名称：</div>
          <el-input
            v-model.trim="name"
            clearable
            placeholder="请输入"
            @input="changeName"
            @keyup.enter.native="reset"
          ></el-input>
        </div>
        <!-- <div class="z-inputItem">
          <div>添加人：</div>
          <el-input
            v-model.trim="person"
            clearable
            placeholder="请输入"
            @input="changePerson"
            @keyup.enter.native="reset"
          ></el-input>
        </div> -->
        <!-- <div class="z-inputItem">
          <div>创建时间：</div>
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="reset"
          >
          </el-date-picker>
        </div> -->
        <!-- <div class="z-inputItem z-inputItems">
          <div>类型：</div>
          <el-select
            v-model="menu"
            clearable
            placeholder="请选择"
            @change="reset"
          >
            <el-option
              v-for="item in typeData"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
      </div>
      <div class="z-search">
        <div class="z-searchBtn ease" @click="reset">搜索</div>
      </div>
    </div>

    <div class="tabPosition">
      <el-radio-group v-model="tabPosition" @change="handleClicks">
        <el-radio-button label="0">元数据目录</el-radio-button>
        <el-radio-button label="1">指标目录</el-radio-button>
        <el-radio-button label="2">模型目录</el-radio-button>
      </el-radio-group>
      <div class="z-addItem ease z-addMenu" @click="addTarget">添加目录</div>
    </div>
    <div class="z-table">
      <div class="z-table-left roleListOver">
        <el-tree
          :data="menusData"
          :props="defaultProps"
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
      <el-table :data="menuDataList" style="width: 100%">
        <el-table-column width="14"> </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          min-width="12%"
          sortable
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="catalogName"
          label="名称"
          min-width="16%"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="catalogName"
          label="层级"
          min-width="12%"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>{{ menuLevel[scope.row.level] }}</div>
          </template>
        </el-table-column>
        <el-table-column label="类型" min-width="12%">
          <template slot-scope="scope">
            <div>{{ menuType[scope.row.catalogType] }}</div>
          </template>
        </el-table-column> -->
        <el-table-column prop="createUserName" label="添加人" min-width="12%">
        </el-table-column>
        <el-table-column prop="catalogName" label="操作" width="100">
          <template slot-scope="scope">
            <span class="z-table-show" @click="toDetail(scope.row.catalogId)">
              查看
            </span>
            <span
              class="z-table-delete"
              @click="deleteItem(scope.row.catalogId)"
            >
              删除
            </span>
          </template>
        </el-table-column>
        <el-table-column width="14"> </el-table-column>
      </el-table>
    </div>
    <!-- 创建目录 -->
    <transition name="fades">
      <div
        class="containerMask"
        v-if="showAddMenu"
        @click="showAddMenu = false"
      ></div>
    </transition>
    <transition name="fadee">
      <add-menu
        v-if="showAddMenu"
        @closeAddBox="closeAddBox"
        @addSuccess="addSuccess"
      ></add-menu>
    </transition>
    <!-- 目录详情 -->
    <transition name="fades">
      <div
        class="containerMask"
        v-if="showDetail"
        @click="showDetail = false"
      ></div>
    </transition>
    <transition name="slide-fade">
      <menu-detail
        v-if="showDetail"
        :customItems="menuDetailData"
        @hiddenDetail="hiddenDetail"
        @changeSuccess="changeSuccess"
        :toDetailId="toDetailId"
      ></menu-detail>
    </transition>
    <div class="pagina">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageno"
        layout="total, prev, pager, next, jumper"
        :total="totalPages"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import addMenu from "@/pages/metadata/addmenu";
import menuDetail from "./menuDetail.vue";
import {
  getMenuList,
  getMenu,
  addMenuList,
  getMenuDetail,
  deletePfMetaCatalog,
} from "@/api/editor";
import { formatDateTes } from "./help.js";
import { typeList, typeData, metaType, menuData } from "@/utils/constant";

export default {
  components: {
    addMenu,
    menuDetail,
  },
  data() {
    return {
      tabPosition: 0,
      menuDataList: null,
      menuList: null,
      showDetail: false,
      showAddMenu: false,
      name: "",
      person: "",
      time: "",
      menu: "PF.YSJ",
      pageno: 1,
      totalPages: 0,
      typeData: metaType,
      menuType: menuData,
      menuDetail: null,
      menuLevel: ["暂无层级", "一级目录", "二级目录", "三级目录"],
      toDetailId: "",
      menusData: null,
      catalogType: "PF.YSJ",
      typeData: ["PF.YSJ", "PF.ZB", "PF.MX"],
      defaultProps: {
        children: "childNode",
        label: "catalogName",
      },
    };
  },
  mounted() {
    this.getMenuLists();
    this.getTree();
  },
  methods: {
    handleClicks() {
      this.name = "";
      this.catalogType = this.typeData[this.tabPosition];
      this.getTree();
      this.menu = this.typeData[this.tabPosition];
      this.getMenuLists();
    },
    handleNodeClick(data) {
      // console.log(data);
      this.name = data.catalogName;
      this.getMenuLists();
    },
    getTree() {
      let params = {
        catalogType: this.catalogType,
        catalogSelectUse: false,
      };
      getMenu(params).then((res) => {
        if (res.msgCode == 1) {
          this.menusData = res.data;
        }
      });
    },
    deleteItem(data) {
      this.$confirm("是否删除当前目录？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            catalogId: data,
          };
          deletePfMetaCatalog(params).then((res) => {
            if (res.msgCode == 1) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.pageno = 1;
              this.getMenuLists();
              this.getTree();
            } else {
              this.$message({
                message: res.msgInfo,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {});
    },
    getMenuDetails(type) {
      if (!type) return;
      let params = {
        catalogId: type,
      };
      getMenuDetail(params).then((res) => {
        if (res.msgCode == 1) {
          this.menuDetailData = res.data;
          this.showDetail = true;
        }
      });
    },
    addSuccess() {
      this.showAddMenu = false;
      this.getMenuLists();
      this.getTree();
    },
    getMenuLists() {
      let params = {
        catalogName: this.name,
        catalogType: this.menu,
        createUserName: this.person,
        endCreateTime: this.time ? this.time[1] : "",
        pageNo: this.pageno,
        pageSize: 10,
        startCreateTime: this.time ? this.time[0] : "",
      };
      getMenuList(params).then((res) => {
        if (res.code == 200) {
          this.menuDataList = res.list;
          this.totalPages = res.pageTotal;
          for (let i = 0; i < this.menuDataList.length; i++) {
            this.menuDataList[i].createTime = formatDateTes(
              this.menuDataList[i].createTime
            );
          }
        }
      });
    },
    hiddenDetail() {
      this.showDetail = false;
    },
    toDetail(toDetail) {
      this.toDetailId = toDetail;
      this.getMenuDetails(toDetail);
    },
    addTarget() {
      this.showAddMenu = true;
    },
    closeAddBox() {
      this.showAddMenu = false;
    },
    /***滑动限制***/
    stop() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    /***取消滑动限制***/
    move() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, true); //页面可以滑动
    },
    changeSuccess(val) {
      this.menuDetailData = val;
      this.getMenuLists();
    },
    reset() {
      this.pageno = 1;
      this.getMenuLists();
    },
    changePerson() {
      if (!this.person) {
        this.reset();
      }
    },
    clearTime() {
      if (!this.time) {
        this.reset();
      }
    },
    changeName() {
      if (!this.name) {
        this.reset();
      }
    },
    handleCurrentChange(val) {
      this.pageno = val;
      this.getMenuLists();
    },
  },
  watch: {
    showAddMenu(newData) {
      if (newData) {
        this.stop();
      } else {
        this.move();
      }
    },
  },
};
</script>

<style scoped lang="stylus">
@import './common.css';

>>>.z-filters .el-input__inner {
  width: 240px;
  height: 36px;
  line-height: 36px;
}

>>>.z-filters .el-input {
  width: 240px;
  height: 36px;
  line-height: 36px;
}

>>>.z-filters .el-input__icon {
  line-height: 30px;
}

.z-inputItems >>>.el-input__icon {
  line-height: 36px;
}

>>>.z-filters .el-date-editor .el-range-separator {
  line-height: 30px;
}

>>>.el-date-editor .el-range__close-icon {
  width: 10px;
}

.z-table-left {
  width: 360px;
  margin-right: 16px;
  max-height: 540px;
  overflow-x: auto;
}

>>>.el-tree-node__content {
  height: 52px;
}

.tabPosition {
  display: flex;
  margin: 16px 0 16px 30px;
}

>>>.el-radio-button__inner {
  padding: 10px 29px;
}

>>>.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-color: #2F54EB;
  border-color: #2F54EB;
  box-shadow: -1px 0 0 0 #2F54EB;
}

>>>.el-table {
  height: 100%;
}

.z-addMenu {
  margin: 0;
  margin-left: 16px;
}
</style>