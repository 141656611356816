<template>
  <div class="page-wrapper">
    <div class="title-back">
      <bread-crumb>
        <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>风险检测补救流程</el-breadcrumb-item>
      </bread-crumb>

      <div class="mainheader">
        <main-header>
          <template slot="title">数据采集补救</template>
        </main-header>
      </div>
    </div>

    <div class="section-box">
      <ValidationObserver ref="validationObserver">
        <div class="title">发票数据补救</div>
        <div class="form-wrapper">
          <div class="left-wrapper left-100">
            <div class="item">
              <ValidationProvider name="纳税人识别号：" rules="required|taxpayerID">
                <div slot-scope="{ classes,errors }">
                  <div class="label">
                    <span class="required-icon">*</span> 纳税人识别号：
                  </div>
                  <div class="input-wrapper">
                    <input
                      type="text"
                      class="input"
                      placeholder="请输入"
                      :class="classes"
                      v-model.lazy="invoiceNsrsbh"
                      @blur="changeBlur"
                    />
                    <p class="validate-error">{{ errors[0] }}</p>
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="item">
              <div class="label">
                <span class="required-icon"></span>自动取数：
              </div>
              <div class="input-wrapper input-bottom">
                <el-radio v-model="radio" label="1">是</el-radio>
                <el-radio v-model="radio" label="0">否</el-radio>
              </div>
            </div>
          </div>
          <div class="left-wrapper"></div>
        </div>
      </ValidationObserver>
    </div>
    <!--发票提取-->
    <InvoiceExtractGuide></InvoiceExtractGuide>
    <!--/发票提取-->

    <loading
      :active.sync="isLoading"
      backgroundColor="#000"
      :can-cancel="true"
      :is-full-page="fullPage"
      loader="Dots"
      color="#2f54eb"
    ></loading>

    <sa-footer>
      <div class="footer">
        <button @click="handleBtnNext2" class="btn btn-primary btn-next">完成提交</button>
      </div>
    </sa-footer>
    <div class="process-statusC" v-if="locks">
      <process-status
        :uuid="uuid"
        :nsrsbh="invoiceNsrsbh"
        class="process-statuss"
        :uploadStatus="uploadStatus"
        @closeProcess="closeProcess"
        :classObject="classObject"
        :processInfoStatus="processInfoStatus"
        :processFalse="processFalse"
      ></process-status>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { mapState } from "vuex";
import { zq2name } from "@/utils/helper";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import InvoiceExtractGuide from "@/pages/Test/Invoice/components/InvoiceExtractGuide";
import processStatus from "@/pages/fixProcess/components/processStatus";
import Footer from "@/pages/Test/ChooseTable/components/Footer";
import { localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_CN", zh_CN);

//在加载的样式
import "vue-loading-overlay/dist/vue-loading.css";

import {
  genTestReport,
  mateGoods,
  mateBill,
  checkNew,
  genTestReportAi,
  mateBillAi,
  checkNewAi,
  mateGoodsAi,
  progressBar,
  genTestReportAiFix
} from "@/api/api";
import { async } from "q";

export default {
  components: {
    Loading,
    BreadCrumb,
    MainHeader,
    InvoiceExtractGuide,
    saFooter: Footer,
    processStatus,
  },
  //   props: {
  //     bankId: String,
  //     productID: [String, Number],
  //     productName: String,
  //     zqLocation: [String, Number],
  //     uuid: String,
  //     zq: String,
  //     version: [Number, String],
  //     bill: [Number],
  //     selectvalueIndex: [Number, String],
  //   },
  data: function () {
    return {
      isLoading: false,
      fullPage: true,
      companyName: "",
      email: "",
      taxPayerID:
        localStorage.getItem("taxSiteUsername") ||
        sessionStorage.getItem("isTaxpayerId") ||
        "",
      mobile: "",
      radio: "1",
      productid: "",
      bankid: "",
      userInfo: sessionStorage.getItem("method") || 1,
      uploadStatus: null,
      locks: false,
      classObject: "",
      timestamp: sessionStorage.getItem("timestamp") || "", //提交成功时候的时间戳
      processInfoStatus: false,
      processFalse: false,
      version: this.$route.query.tag,
      uuid: this.$route.query.uuid,
      zq: this.$route.query.zq,
      invoice_nsrsbh: this.$route.query.invoice_nsrsbh,
      bankId: this.$route.query.bankId,
      goodsId: this.$route.query.goodsId,
      fix: 0,
      invoiceNsrsbh: "",
      timestampData: this.$route.query.timestamp,
      isSuccessUpload: sessionStorage.getItem("isSuccessUpload") || false,
    };
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID"]),
    zqName: function () {
      return zq2name(this.zq);
    },
  },
  mounted() {
    this.invoiceNsrsbh = this.invoice_nsrsbh;
    sessionStorage.setItem("timestamp", this.timestampData);
    //   刷新后如果进度条跑进度中就显示进度条
    let toProcess = sessionStorage.getItem("toProcess2");
    let fix = sessionStorage.getItem("fix");
    if (toProcess && fix) {
      this.progressbar();
      this.locks = true;
    }

    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      history.pushState(null, null, document.URL);
    });
    let params = {
      nsrsbm: sessionStorage.getItem("nsrsbh"),
    };
    mateGoodsAi(params).then((res) => {
      if (res.msgCode == 1) {
        this.productid = res.data.id;
        this.bankid = res.data.bankId;
      } else {
        this.$message({
          message: res.msgInfo,
          type: "error",
        });
      }
    });
    this.isLoading = true;

    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },
  methods: {
    closeProcess() {
      this.$confirm("是否关闭进度条，关闭之后可以前往检测记录查看", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.locks = false;
          clearTimeout(this.n);
          sessionStorage.removeItem("timestamp");
          sessionStorage.removeItem("toProcess");
          sessionStorage.removeItem("toProcess2");
          sessionStorage.removeItem("taxpayerIdProcess");
          this.$router.push({
            name: "test-InvoiceUpload",
            params:{
              nsrsbh:this.invoiceNsrsbh,
              uuid:this.uuid
            }
          });
        })
        .catch(() => {});
    },
    progressbar() {
      var that = this;

      this.n = setTimeout(function cb() {
        let params = {
          taxpayerId:
            sessionStorage.getItem("taxpayerIdProcess") ||
            that.invoiceNsrsbh ||
            sessionStorage.getItem("isTaxpayerId"),
          nsrsbm: sessionStorage.getItem("nsrsbh"),
          zq: that.zq,
          type: that.userInfo == 2 ? 1 : 2,
        };
        progressBar(params, localStorage.getItem("authorization")).then(
          (res) => {
            if (res.msgCode == -1) {
              that.$message({
                type: "error",
                message: res.msgInfo + "查询进度出错",
                duration: 10000,
              });
              // that.$router.push({
              //   path: "/",
              // });
              return;
            }
            that.locks = true;
            that.uploadStatus = res.data;
            if (that.isSuccessUpload) {
              that.uploadStatus.sb = 3;
            }
            let timestamp = Date.parse(new Date());
            let add = Number(timestamp) - Number(that.timestamp);
            let data2 = ((add / 2400000) * 100).toFixed(2);
            that.classObject = data2;
            if (that.classObject > 90) {
              that.classObject = 90;
            }
            if (
              add >= 2400000 &&
              that.uploadStatus.fp != 2 &&
              that.uploadStatus.sb != 2 &&
              that.uploadStatus.sb != 3 &&
              that.uploadStatus.sb != 4
            ) {
              that.uploadStatus = {
                fp: "0",
                sb: "0",
                yeb: res.data.yeb,
              };
              return;
            }
            if (add >= 2400000 && that.uploadStatus.fp != 2) {
              that.uploadStatus = {
                fp: "0",
                sb: res.data.sb,
                yeb: res.data.yeb,
              };
              return;
            }
            if (add >= 2400000 && that.uploadStatus.sb != 2) {
              that.uploadStatus = {
                fp: res.data.fp,
                sb: "0",
                yeb: res.data.yeb,
              };
              return;
            }
            if (that.uploadStatus.fp == 2 && that.uploadStatus.sb == 2) {
              that.processInfoStatus = true;
            } else if (that.uploadStatus.fp == 0 && that.uploadStatus.sb == 0) {
            } else if (that.uploadStatus.fp == 0 && that.uploadStatus.sb == 3) {
              // 发票报错，申报已上传
            } else if (that.uploadStatus.fp == 0 && that.uploadStatus.sb == 4) {
              // 发票报错，申报未上传
            } else {
              if (sessionStorage.getItem("toProcess2")) {
                this.n = setTimeout(cb, 10000);
              }
            }
          }
        );
      }, 0);
    },

    toPrevious() {
      let status = sessionStorage.getItem("method");
      if (status == 1) {
        this.$router.push({
          name: "test-Balance",
        });
      } else {
        this.$router.push({
          name: "upload-table",
        });
      }
    },

    changeBlur() {
      this.taxPayerID = this.taxPayerID.trim();
    },

    // 自动取数接口
    matebills() {
      const loading = this.$loading({
        lock: true,
        text: "正在提交数据！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        invoice_nsrsbh: this.taxPayerID,
        isAutoData: this.radio,
      };
      mateBillAi(params).then((res) => {
        if (res.msgCode == 1) {
          this.fix = 1;
          sessionStorage.setItem("fix", 1);
          localStorage.removeItem("taxSiteUsername");
          // this.newCheck();
          loading.close();
          //设置permissionID
          this.$store.commit("UploadTable/setPermissionID", {
            permissionID: "",
          });
        } else if (res.msgCode == -1) {
          loading.close();

          //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
          this.$alert(res.msgInfo, "错误", {
            confirmButtonText: "确定",
            type: "error",
            callback: (action) => {
              //   this.$router.push({ name: "homePage" });
            },
          });
        } else {
          loading.close();

          this.$message({
            message: res.msgInfo,
            type: "error",
            duration: 5000,
            callback: (action) => {
              alert("dasdsa");
            },
          });
        }
      });
    },
    // 拉表走check接口
    checkOld() {
      let data = this.$store.state;
      const loading = this.$loading({
        lock: true,
        text: "正在提交数据！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      genTestReportAiFix({
        bankId: this.bankid,
        goodsId: this.productid,
        kjzd: sessionStorage.getItem("qykjzd"),
        token: localStorage.getItem("authorization"),
        nsrsbh: sessionStorage.getItem("nsrsbh"),
        tag: this.version,
        id: "123",
        uuid: this.uuid,
        type: "",
        zq: this.zq,
        invoice_nsrsbh: this.invoiceNsrsbh,
        invoice_company_name: "",
        invoice_email: "",
        invoice_phone: "",
        isAutoData: this.radio,
        isUpdateResultList: 1
      }).then((res) => {
        if (res.msgCode == 1) {
          this.fix = 1;
          sessionStorage.setItem("fix", 1);
          this.timestamp =
            sessionStorage.getItem("timestamp") || Date.parse(new Date());
          sessionStorage.setItem("timestamp", this.timestamp);
          sessionStorage.setItem("toProcess2", 100);
          this.progressbar();

          sessionStorage.setItem("taxpayerIdProcess", this.taxPayerID);

          localStorage.removeItem("taxSiteUsername");
          loading.close();
          //设置permissionID
          this.$store.commit("UploadTable/setPermissionID", {
            permissionID: "",
          });
        } else if (res.msgCode == -1) {
          loading.close();
          //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
          this.$alert(res.msgInfo, "错误", {
            confirmButtonText: "确定",
            type: "error",
            callback: (action) => {
              //   this.$router.push({ name: "homePage" });
            },
          });
        } else {
          loading.close();
          this.$message({
            message: res.msgInfo,
            type: "error",
            duration: 5000,
            callback: (action) => {
              alert("dasdsa");
            },
          });
        }
      });
    },
    // 新的check接口
    newCheck() {
      let params = {
        token: localStorage.getItem("authorization"),
        nsrsbh: sessionStorage.getItem("nsrsbh"),
        tag: this.version,
        uuid: this.uuid,
        zq: this.zq,
        invoice_nsrsbh: this.taxPayerID,
        invoice_company_name: sessionStorage.getItem("companyName"), //客户名称
      };
      checkNewAi(params).then((res) => {
        if (res.msgCode == 1) {
          this.timestamp =
            sessionStorage.getItem("timestamp") || Date.parse(new Date());
          sessionStorage.setItem("timestamp", this.timestamp);
          sessionStorage.setItem("toProcess2", 100);
          this.progressbar();
          sessionStorage.setItem("taxpayerIdProcess", this.taxPayerID);
        } else {
          //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
          this.$alert(res.msgInfo, "错误", {
            confirmButtonText: "确定",
            type: "error",
            callback: (action) => {
              //   this.$router.push({ name: "homePage" });
            },
          });
        }
      });
    },
    handleBtnNext2() {
      this.$refs["validationObserver"].validate().then((success) => {
        if (!success) {
          return;
        }
        if (this.userInfo == 1) {
          this.matebills();
        } else {
          this.checkOld();
        }
      });
    },
  },

  destroyed() {
    this.locks = false;
    sessionStorage.removeItem("timestamp");
    sessionStorage.removeItem("toProcess");
    sessionStorage.removeItem("toProcess2");
    sessionStorage.removeItem("taxpayerIdProcess");
    sessionStorage.removeItem("fix");
    sessionStorage.removeItem("isTaxpayerId");

    clearTimeout(this.n);
  },
};
</script>

<style lang="stylus" scoped>
.page-wrapper {
  padding-bottom: 100px;
}

.form-wrapper {
  display: flex;
  margin-top: 16px;

  .left-wrapper {
    width: 50%;
  }

  .item {
    color: #666;
    font-size: 14px;
    margin-top: 16px;

    .required-icon {
      color: #F5222D;
      margin-right: 4px;
    }

    .label {
      width: 150px;
      display: inline-block;
      text-align: right;
      margin-right: 8px;
      vertical-align: top;
      line-height: 36px;
    }

    .input {
      border: 1px solid #D9D9D9;
      width: 400px;
      height: 36px;
      line-height: 36px;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 3px;
      outline: none;
    }

    .input-wrapper {
      display: inline-block;
    }

    .big {
      font-size: 24px;
      margin: 16px auto;
      color: #333;
    }
  }

  .info {
    margin-left: 50px;
    margin-top: 25px;
  }
}

.progressdis {
  width: 90%;
  margin: 24px auto;
}

.btn-right-none {
  margin-right: 16px !important;
}

.input-bottom {
  position: relative;
  top: 10px;
}

.form-wrapper .left-100 {
  width: 100%;
}

.process-status {
  width: 120px;
  height: 2px;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  top: 8px;
  margin: 0 24px;
}

.mainheader {
  display: flex;
  justify-content: space-between;
}

.section-box {
  margin: 16px 30px 0;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 0 0 16px 0;
  box-sizing: border-box;
}

.title {
  height: 54px;
  line-height: 54px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  border-radius: 8px 8px 0px 0px;
  padding-left: 24px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.form-wrapper .item {
  margin-left: 60px;
}

.title-back {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.process-statuss {
  position: fixed;
  top: 20vh;
  left: 50%;
  margin-left: -400px;
}

.process-statusC {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2001;
}
</style>
