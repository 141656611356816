<template>
  <div>
    <bread-crumb>
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户设置</el-breadcrumb-item>
    </bread-crumb>
    <div class="setuser">
      <div class="qymc">用户设置</div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="企业信息" name="first">
          <company-msg></company-msg>
        </el-tab-pane>
        <el-tab-pane label="安全手机" name="second">
          <safe-phone v-if="!isShow"></safe-phone>
          <modify-phone v-on:listenmsg="magphone" v-if="isShow"></modify-phone>
        </el-tab-pane>
        <el-tab-pane label="修改密码" name="third" v-if="hasPwd=='true' || hasPwd==true">
          <mdf-password></mdf-password>
        </el-tab-pane>
        <el-tab-pane label="设置密码" name="third" v-else>
          <mdf-password></mdf-password>
        </el-tab-pane>
        <!-- <el-tab-pane label="微信绑定" name="fourth">
          <WechatBind></WechatBind>
        </el-tab-pane>
        <el-tab-pane label="数据保存" name="fifth">
          <save-date></save-date>
        </el-tab-pane> -->
      </el-tabs>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import SaveDate from "./components/Savedate";
import MdfPassword from "./components/mdfPassword";
import SafePhone from "./components/safePhone";
import companyMsg from "./components/companyMsg";
import modifyPhone from "./components/modifyPhone";
import WechatBind from "./components/WechatBind/WechatBind";

export default {
  components: {
    BreadCrumb,
    SaveDate,
    MdfPassword,
    SafePhone,
    companyMsg,
    modifyPhone,
    WechatBind
  },
  data() {
    return {
      activeName: "first",
      hasPwd: false,
      isShow: true
    };
  },
  mounted() {
    this.hasPwd = localStorage.getItem("hasPwd");
    const bindwx = this.$route.query.bindwx;
    if (bindwx) {
      this.activeName = "fourth";
    }
  },
  methods: {
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    magphone(e) {
      this.isShow = false;
    }
  }
};
</script>
<style src="@/styles/index.scss" lang="scss" scoped></style>
<style lang="stylus" scoped>
.qymc {
  margin-bottom: 10px;
}

>>>.setuser {
  margin: 0px auto 30px auto;
  padding: 10px;
  box-sizing: border-box;
  width: 96%;

  .code_btn {
    margin-left: 20px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(47, 84, 235, 1);
    }
  }

  .el-tabs .el-tabs__header {
    margin: 0;

    .el-tabs__nav-wrap::after {
      background-color: none;
      height: 0;
    }

    .el-tabs__nav-wrap {
      .el-tabs__nav-scroll {
        .el-tabs__nav {
          height: 67px;
          line-height: 67px;

          .el-tabs__active-bar {
            background: #2F54EB;
            height: 36px;
            bottom: 15px;
            border-radius: 18px;
            width: 88px !important;
          }

          .el-tabs__item {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.6);
          }

          .el-tabs__item.is-active {
            margin-left: 16px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            // transition :  all .3s;
            z-index: 3;
          }
        }
      }
    }
  }
}

>>>.mdf_content {
  width: 100%;
  margin-left: 99px;
  min-height: 400px;

  .mdf_c1 {
    margin-bottom: 20px;

    span {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(107, 120, 151, 1);
    }

    .mdf_input {
      display: inline-block;
      width: 28%;
      margin-left: 52px;

      .el-input__inner {
        height: 40px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(118, 180, 255, 1);
        border-radius: 4px;
      }
    }

    .mdf_input1 {
      margin-left: 20px;
    }
  }
}
</style>