<template>
  <div>
    <div class="btn-append-wrapper">
      <button class="btn-append" @click="dialogVisible = true">
        <i class="el-icon-circle-plus-outline"></i> 添加优惠政策
      </button>
    </div>

    <el-dialog
      title="添加优惠政策"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @closed="handleDialogClosed"
      width="800px"
    >
      <div>
        <DiscountPolicyForm ref="discountPolicyForm" @cancel="handleDialogCancel"></DiscountPolicyForm>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>-->
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { addDiscountPolicy } from "@/api/shiwusuo";
import DiscountPolicyForm from "./DiscountPolicyForm";

import { localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_CN", zh_CN);

export default {
  props: {
    //indexes: Array
  },
  data: function() {
    return {
      dialogVisible: false
    };
  },
  components: {
    DiscountPolicyForm
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    ...mapState("ResultEditor", ["setTreeDataByIndexes"]),
    nodeData() {
      return this.findTheNodeByIndexes(this.indexes);
    },
    getNextOrderLabel() {
      const lastOrderNumber =
        this.nodeData.orderNumber + "." + this.nodeData.children.length;
      const orderNumber = lastOrderNumber.split(".");
      let lastDigtal = orderNumber.pop();
      orderNumber.push(++lastDigtal);
      return orderNumber;
    },
    nextOrderLabel() {
      return this.getNextOrderLabel.join(".");
    }
  },
  methods: {
    ...mapActions("ResultEditor", ["refresh"]),

    handleDialogCancel() {
      this.dialogVisible = false;
    },
    handleDialogClosed() {
      this.$refs["discountPolicyForm"].resetFrom();
      
    },
    
  }
};
</script>

<style lang="stylus" scoped>
.btn-append-wrapper {
  text-align: center;
  margin: 24px 0 200px;

  .btn-append {
    padding: 8px 24px;
    background: #2f54eb;
    border: 1px solid #2f54eb;
    border-radius: 6px;
    color: #fff;
    box-sizing: border-box;
    cursor: pointer;
  }

  .btn-append:hover {
    background: #122d9c;
    border: 1px solid #122d9c;
    border-radius: 6px;
    color: #fff;
    box-sizing: border-box;
  }
}

>>> .el-dialog {
  border-radius: 5px;
}

>>> .el-dialog__body {
  padding: 30px 15px 0;
}
</style>