<template>
  <div class="page-wrapper">
    <div class="wrapper" v-loading="isLoading" element-loading-text="拼命加载中">
      <div class="wrapper-inner">
        <CensorItem
          class="censorItem"
          v-for="(recordOfPerson) in records"
          :key="recordOfPerson.id"
          :recordOfPerson="recordOfPerson"
        ></CensorItem>
      </div>
    </div>
  </div>
</template>

<script>
import { getCheckLogList } from "@/api/shiwusuo";
import { mapState } from "vuex";

import CensorItem from "./CensorItem";

export default {
  props: {
    nsrsbh: String,
    uuid: String,
  },
  components: {
    CensorItem,
  },
  mounted() {},
  computed: {
    ...mapState("ResultEditor", ["records"]),
    listenChange() {
      const { nsrsbh, uuid } = this;
      return { nsrsbh, uuid };
    },
    isLoading() {
      return !this.records;
    },
  },
  watch: {
    listenChange: {
      async handler(newData) {
        const { nsrsbh, uuid } = newData;
        await this.$store.dispatch("ResultEditor/loadCheckLogList", {
          nsrsbh,
          uuid,
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {},
};
</script>

<style lang="stylus" scoped>
.censorItem {
  width: 100%;
  background: rgba(245, 34, 45, 0.04);
  border-radius: 4px;
  border: 1px solid rgba(245, 34, 45, 0.16);
  padding: 24px;
  box-sizing: border-box;
  margin-bottom: 16px;
}

.censorItem:nth-child(even) {
  background: rgba(250, 173, 20, 0.04);
  border-radius: 4px;
  border: 1px solid rgba(250, 173, 20, 0.16);
}

.page-wrapper {
  overflow: auto;

  .wrapper {
    box-sizing: border-box;
    margin: 16px;
    height: 100vh;

    .wrapper-inner {
      padding-bottom: 16px;
    }
  }

  .ps {
    height: 100vh;
  }
}
</style>