<template>
  <div class="handsonTable-wrapper">
    <HotTable
      v-bind="dataTable"
      ref="theTable"
      class="hot handsontable htRowHeaders htColumnHeaders"
      style="height: 800px; overflow: hidden"
      data-originalstyle="height: 800px; overflow: hidden; "
      :key="tableId"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
//import { HotTable } from "@/common/js/handsontable/vue-handsontable.js";
//import "@/common/js/handsontable/languages/zh-CN";
import { HotTable } from "@handsontable/vue";
import "handsontable/languages/zh-CN";
import { merge } from "lodash";
import { isNumeric, parseNumber, round } from "@/utils/format";
import Onfire from "onfire.js";

export default {
  components: {
    HotTable,
  },
  props: [
    "table",
    "getTableData",
    "onChange",
    "enableAutoCorrect",
    "zq",
    "tableId",
    "templateID",
  ],
  mounted() {
    this.dataTable.data = [];
    //const instance = this.$refs.theTable.hotInstance;
    merge(this.dataTable, cloneDeep(this.table));

    this.$refs.theTable.hotInstance.triggerAutoCorrect = () => {
      this.dataTable.autoCorrections.forEach((correctRule) => {
        this.correct(correctRule);
      });
    };
    this.handleTableDataChange();
    // const plugin = instance.getPlugin("autoColumnSize");

    // setTimeout(()=>{
    //   instance.render();
    // },1000)
    this.matchTemplateIDHeight();
  },
  watch: {
    table: {
      handler: function () {
        this.handleTableDataChange();
      },
      deep: true,
    },
  },
  methods: {
    //
    matchTemplateIDHeight() {
      if (this.matchHeightId.indexOf(this.templateID) > -1) {
        this.dataTable.height = this.matchHeightList[this.templateID];
      }
      if (this.matchColumnId.indexOf(this.templateID) > -1) {
        this.dataTable.colWidths = this.matchColumnList[this.templateID];
      }
    },
    //
    handleTableDataChange() {
      merge(this.dataTable, this.table);

      //替换#{year} 为指定年份
      if (this.zq) {
        for (const key in this.dataTable.data) {
          if (this.dataTable.data.hasOwnProperty(key)) {
            const row = this.dataTable.data[key];
            for (const key2 in row) {
              if (row.hasOwnProperty(key2)) {
                let col = row[key2];
                const found = /\#{year(-[\d]+)?\}/.exec(String(col));
                if (found) {
                  let exp = "";
                  if (found[1]) {
                    exp = found[1];
                  }
                  const theYear = String(this.zq).substr(0, 4);
                  const year = parseInt(theYear) + exp;

                  this.dataTable.data[key][key2] = col.replace(
                    /\#{year(-[\d]+)?\}/,
                    eval(year)
                  );
                }
              }
            }
          }
        }
      }

      const instance = this.$refs.theTable.hotInstance;

      instance.updateSettings(this.dataTable);

      //获取自动修正的单元格
      const autoCells = [];
      this.dataTable.autoCorrections.forEach((rule) => {
        const data = rule.split("=");
        let target = data[0];

        const matches = target.match(/d\(([0-9]+),([0-9]+)\)/);
        const row = parseInt(matches[1]);
        const col = parseInt(matches[2]);
        autoCells.push(`${row},${col}`);
      });

      //只读单元格
      instance.updateSettings({
        cells: (row, col) => {
          var cellProperties = {};
          if (this.enableAutoCorrect && autoCells.indexOf(`${row},${col}`) >= 0) {
            cellProperties.readOnly = true;
            cellProperties.className = "handson-table-cell-auto-correct";
          } else if (this.dataTable.readOnlyCells.indexOf(`${row},${col}`) >= 0) {
            cellProperties.readOnly = true;
            cellProperties.className = "handson-table-cell-edit-disabled";
          } else if (
            this.dataTable.columns[col] &&
            !this.dataTable.columns[col].readOnly
          ) {
            cellProperties.readOnly = false;
            cellProperties.className = "";
          }

          return cellProperties;
        },
      });

      //表达式的左边的row和col
      const getTargetRowCol = (targetStr) => {
        const matches = targetStr.match(/d\(([0-9]+),([0-9]+)\)/);
        const targetRow = parseInt(matches[1]);
        const targetCol = parseInt(matches[2]);
        return { targetRow, targetCol };
      };

      //表达式右边的row和col
      const getRightRowCol = (expression) => {
        let ret = [];
        var regex1 = /d\(([0-9]+),([0-9]+)\)/g;

        var array1;

        while ((array1 = regex1.exec(expression)) !== null) {
          ret.push([array1[1], array1[2]]);
        }
        return ret;
      };

      //把validation设置成发布订阅模式

      //if (this.autoCorrect) {
      this.autoCorrectPubSub = new Onfire();
      this.autoCorrectPubSub.list = {};

      //把关系表加入到list
      this.dataTable.autoCorrections.map((v) => {
        let [, expression] = v.split("=");

        const cellsInRight = getRightRowCol(expression);

        cellsInRight.forEach((cell) => {
          const key = `d(${cell[0]},${cell[1]})`;

          if (!this.autoCorrectPubSub.list[key]) {
            this.autoCorrectPubSub.list[key] = [];
          }
          this.autoCorrectPubSub.list[key].push(v);
        });
      });

      this.autoCorrectPubSub.on("correct", (targetCell) => {
        //d(15,2)
        const { targetRow, targetCol } = getTargetRowCol(targetCell);
        const key = `d(${targetRow},${targetCol})`;
        if (this.autoCorrectPubSub.list[key]) {
          this.autoCorrectPubSub.list[key].forEach((rule) => {
            this.correct(rule);
          });
        }
      });
      //}

      instance.render();
      // const plugin = instance.getPlugin('autoColumnSize');
      // plugin.recalculateAllColumnsWidth();
    },
    obj2Data: (data) => {
      return data.map((row) => {
        return Object.entries(row).map((aData) => {
          const [, val] = aData;
          return val;
        });
      });
    },
    data2Obj: (data, colHeaders) => {
      return data.map((aData) => {
        let item = {};
        colHeaders.forEach((colHeader, j) => {
          item[colHeader] = aData[j];
        });
        return item;
      });
    },
    correct(rule) {
      //const sourceCode.data= sourceCode.data
      const currentData = this.obj2Data(this.dataTable.data);
      const instance = this.$refs.theTable.hotInstance;
      const data = rule.match(/([^=]+)=([\w\W]+)/);
      let [, target, expression] = data;
      expression = expression.replace(/d\(([0-9]+),([0-9]+)\)/g, (match, row, col) => {
        let val = 0;
        if (isNumeric(currentData[row][col])) {
          val = parseNumber(currentData[row][col]);
        }
        return `(${val})`;
      });

      const matches = target.match(/d\(([0-9]+),([0-9]+)\)/);
      const row = parseInt(matches[1]);
      const col = parseInt(matches[2]);

      let expressionResult = eval(expression);

      if (isNumeric(expressionResult)) {
        expressionResult = round(expressionResult); //四舍五入
      }

      if (currentData[row][col] !== expressionResult.toFixed(2)) {
        currentData[row][col] = expressionResult.toFixed(2);

        this.dataTable.data = this.data2Obj(currentData, this.dataTable.colHeaders);
        //似乎没什么用 去掉了 20200813
        //instance.setDataAtCell(row, col, eval(expression), "autoCorrect");
        if (this.autoCorrectPubSub) {
          this.autoCorrectPubSub.fire("correct", target);
        }

        return true;
      } else {
        //instance.render();
        return false;
      }
    },
  },
  data() {
    return {
      autoCorrectPubSub: null,
      dataTable: {
        afterGetColHeader: (column, TH) => {
          if (this.dataTable.hideHeader == true) {
            TH.classList.add("hide");
          }
        },
        invalidCells: [],
        stretchH: "all",
        licenseKey: "non-commercial-and-evaluation",
        className: "htLeft",
        data: [],
        allowInsertColumn: false,
        allowInsertRow: false,
        colHeaders: [],
        columns: [],
        language: "zh-CN",
        mergeCells: [],
        height: 800,
        colWidths: undefined,
        rowHeights: 30,
        validations: [],
        autoCorrections: [],
        //编辑时默认全选
        afterBeginEditing: () => {
          const instance = this.$refs.theTable.hotInstance;
          instance.getActiveEditor().TEXTAREA.select();
        },
        //转化复制的千分号为数字，去除两边空格
        beforePaste: (data, coords) => {},
        afterPaste: () => {
          //const instance = this.$refs.theTable.hotInstance;
          //instance.render();
          setTimeout(() => {
            this.$emit("onChange", this.onChange);
          });
        },
        afterInit: () => {
          const instance = this.$refs.theTable.hotInstance;
          instance.CustomErrorCells = [];
          this.$emit("getInstance", instance);
        },
        beforeChange: (data, source) => {
          if (
            source === "edit" ||
            source === "Autofill.fill" ||
            source === "CopyPaste.paste"
          ) {
            for (let i in data) {
              const [row, colName, oldVal, newVal] = data[i];
              const col = this.dataTable.colHeaders.indexOf(colName);
              if (typeof newVal === "string") {
                //过滤空白
                let val = newVal.replace(/(?:^[\s]+|[\s]+$)/, "");

                //在数字类型的列中过滤特殊字符串

                if (
                  this.dataTable.columns[col] &&
                  this.dataTable.columns[col].type === "numeric"
                ) {
                  //过滤掉单独一个的字符
                  // val = newVal.replace(
                  //   /^[`~!@#$%^&*+=|':;''\\-\\—()（）{}{}\\\]】,.<>/?~！@#￥%……&*——+|‘；：”“’。，、？]*/,
                  //   ""
                  // );
                  val = parseNumber(val);

                  if (!val) {
                    val = 0;
                  }
                }

                data[i][3] = val;
              }
            }
          }

          //changes[0] = null;
        },
        beforeValidate: (value, row, prop, source) => {
          const instance = this.$refs.theTable.hotInstance;
          const theRow = instance.getCellMetaAtRow(row);
          const cellMeta = theRow.find((v) => v.prop == prop);

          // const cellMeta = instance.getCellMeta(row, prop);
          //如果为星号或者只读时关闭验证
          if (/[*\-─]+/.test(value) || cellMeta.readOnly) {
            return 0;
          } else {
            return value;
          }
        },
        afterChange: (changes, source) => {
          if (!this.dataTable.data) {
            return;
          }
          const instance = this.$refs.theTable.hotInstance;

          //自动修正值
          if (this.enableAutoCorrect) {
            if (
              source !== "autoCorrect" &&
              source !== "populateFromArray" &&
              source !== "loadData"
            ) {
              //20200814
              this.dataTable.autoCorrections.forEach((correctRule) => {
                this.correct(correctRule);
              });
            }
          }
          if (source === "edit" || source === "Autofill.fill") {
            this.$emit("onChange", this.onChange);
          }

          //异步验证单元格 关闭
          // setTimeout(() => {
          //   instance.validateCells((valid) => {});
          // });

          //instance.render
          //this.onChange();
        },
        // beforeRender() {
        //   if (this.$refs) {
        //     const instance = this.$refs.theTable.hotInstance;

        //     const handsonTableWrapper = document.querySelector(
        //       ".handsonTable-wrapper"
        //     );

        //     const children = instance.table.querySelectorAll("*");

        //     children.forEach(element => {
        //       element.style.width = element.style.width+"!important";
        //       element.style.height = element.style.height+"!important";
        //     });

        //     const children2 = handsonTableWrapper.querySelectorAll(
        //       ".wtHolder,.ht_clone_top,.wtHider"
        //     );

        //     children2.forEach(element => {
        //       element.style.width = element.style.width+"!important";
        //       element.style.height = element.style.height+"!important";
        //     });
        //   }
        // },

        afterValidate: (isValid, value, row, prop) => {
          if (!isValid) {
            const col = this.dataTable.columns.findIndex((v) => v.data == prop);
            //  const cellMeta = instance.getCellMeta(row,prop);
            //  debugger

            // const dataTable = cloneDeep(this.dataTable);
            //this.dataTable = dataTable;
            this.dataTable.invalidCells.push([row, col]);
            this.$forceUpdate();
          }
          return !!isValid;
        },
        //效验数据
        afterRender: () => {
          if (!this.dataTable.data) {
            return;
          }
          const clearCellError = (instance) => {
            instance.table.querySelectorAll("td").forEach((e) => {
              e.removeAttribute("title");
            });
          };
          const markCellError = (instance, row, col, msg = "") => {
            const theCell = instance.getCell(row, col);
            if (theCell) {
              theCell.classList.add("htInvalid");
              theCell.title = msg;
            }
          };
          const instance = this.$refs.theTable.hotInstance;
          clearCellError(instance);

          instance.CustomErrorCells = [];

          //验证规则
          const validate = (rule) => {
            let expression;
            //expression = rule.replace(/(?<![><=])=/g, "===");
            expression = rule.replace(/=/g, "===");
            expression = expression.replace(/([><!])[=]+/g, `$1=`);

            expression = expression.replace(
              /d\(([0-9]+),([0-9]+)\)/g,
              (match, row, col) => {
                return (
                  "(" + parseFloat(instance.getDataAtCell(row, col)).toFixed(2) + ")"
                );
              }
            );
            return eval(expression);
          };
          //得到要标红的位置
          const getValidationCellCor = (rule) => {
            let ret = {};
            const matches = rule.match(/d\(([0-9]+),([0-9]+)\)/);
            ret.row = matches[1];
            ret.col = matches[2];

            return ret;
          };
          if (this.enableAutoCorrect) {
            this.dataTable.validations.forEach((validation) => {
              const result = validate(validation.rule);

              const coordnation = getValidationCellCor(validation.rule);
              if (!result) {
                instance.CustomErrorCells.push([coordnation.row, coordnation.col]);

                markCellError(instance, coordnation.row, coordnation.col, validation.msg);
              }
            });
          }

          //对齐方式
          if (this.dataTable.alignments) {
            for (const [k, v] of Object.entries(this.dataTable.alignments)) {
              const [rowNum, colNum] = k.split("_");
              const cla = Object.entries(v).map((v) => v[1]);
              if (cla) {
                const cell = instance.getCell(rowNum, colNum);

                if (cell) {
                  cell.classList.remove(
                    ...[
                      "htLeft",
                      "htCenter",
                      "htRight",
                      "htJustify",
                      "htTop",
                      "htMiddle",
                      "htBottom",
                    ]
                  );
                  cell.classList.add(...cla);
                }
              }
            }
          }
        },
      },
      matchHeightList: {
        A105040: 420,
        A105020: 700,
        A105030: 585,
        A105050: 635,
        A105060: 620,
        A105070: 630,
        A105080: 800,
        A105090: 800,
        A106000: 605,
        A107030: 800,
        A109000: 740,
        A107011: 690,
        A108020: 560,
        A105040: 460,
        A107042: 750
      },
      matchHeightId: [
        "A105040",
        "A105020",
        "A105030",
        "A105050",
        "A105060",
        "A105070",
        "A105080",
        "A105090",
        "A106000",
        "A107030",
        "A109000",
        "A107011",
        "A108020",
        "A105040",
        "A107042"
      ],
      matchColumnList: {
        A105030: [60, 190, 100, 100, 130, 160, 160, 160, 160, 180, 160, 140, 140],
        A105070: [60, 190, 100, 190, 190, 120, 160, 160, 200],
        A105080: [60, 120, 300, 120, 160, 160, 160, 160, 300, 160, 160, 160],
        A105090: [60, 200, 160, 200, 160, 120, 150, 160, 130],
        A106000: [60, 100, 100, 150, 150, 150, 150, 150, 200, 160, 200, 200, 200, 160],
        A107030: [240, 260, 160, 160, 160],
        A107011: [60, 100, 100, 80, 80, 80],
        A105040: [60, 120, 100, 120, 80, 100, 100,100,100,100,100,100,120, 100, 120, 120]
      },
      matchColumnId: ["A105030", "A105070", "A105080", "A105090", "A106000", "A107030", "A107011", "A105040"]
    };
  },
  beforeDestroy() {
    //this.$refs.theTable.hotInstance.destroy();
  },
};
</script>

<style lang="stylus">
@import '~handsontable/dist/handsontable.full.css';

// @import '~element-ui/lib/theme-chalk/index.css';

// .handsonTable-wrapper .wrapper {
// width: 100%;
// padding: 0 20px;
// }
.handsonTable-wrapper {
  overflow: hidden;
  // height: 800px;
}
</style>
