<template>
  <div class="page-wrapper" :key="actualZq" v-if="actualZq">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item
          v-if="!isTaxAutoProcessing"
          :to="{
            name: 'choose-table',
            params: { productID, bankId, productName },
          }"
          >风险检测流程
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="dataPeriod != 3"
          ><span @click="changeUserInfo1" class="changeUserInfo1"
            >申报数据采集</span
          ></el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ zqName }}</el-breadcrumb-item>
      </bread-crumb>

      <div class="main-top">
        <main-header>
          <template slot="title"
            >{{ dataPeriod == 3 ? "数据读取" : "数据补充" }}
          </template>
        </main-header>
        <div
          class="baseVersion"
          @click="fangdichanDialogVisible = true"
          v-if="version == baseVersion || version == baseVersion21"
        >
          基础信息表单
        </div>
      </div>
    </div>
    <div class="congtent-title-tab" v-if="dataPeriod != 3">数据补充</div>
    <div class="el-alert-warning" v-if="dataPeriod != 3 && warningText">
      <div class="el-alert-warningText">
        为了检测结果更加全面准确，请上传本期和上期的{{ warningText }}
      </div>
    </div>
    <div class="content-body-tab" v-if="dataPeriod != 3">
      <div v-if="dataPeriod == 1">
        <router-link
          :to="{
            name: 'upload-table-fdc',
            params: {
              zq,
              zqLocation,
              dataPeriod: 0,
              nsrsbhIndex: this.nsrsbhIndex,
              qykjzdIndex: this.qykjzdIndex,
            },
            query: {
              info: this.$route.query.info,
              available: this.$route.query.available,
              thirdDataSource: this.$route.query.thirdDataSource,
              taxRateCalculation: this.$route.query.taxRateCalculation,
              taxfirmTag: this.$route.query.taxfirmTag,
            },
          }"
        >
          <div class="content-body-tabl" :class="{ zqLocation: dataPeriod == 0 }">
            {{ zq.substring(0, 4) }}年报表
          </div>
        </router-link>
      </div>
      <div
        v-if="dataPeriod == 0"
        class="content-body-tabl"
        :class="{ zqLocation: dataPeriod == 0 }"
      >
        {{ zq.substring(0, 4) }}年报表
      </div>
      <div
        class="content-body-tabr"
        :class="{ zqLocation: dataPeriod == 1 }"
        @click="handleBtnNext"
        v-if="dataPeriod == 0"
      >
        {{ zq.substring(0, 4) - 1 }}年报表
      </div>
      <div
        class="content-body-tabr"
        :class="{ zqLocation: dataPeriod == 1 }"
        v-if="dataPeriod == 1"
      >
        {{ zq.substring(0, 4) - 1 }}年报表
      </div>
    </div>

    <div class="content-body" :class="{ nomargin: dataPeriod == 3 }">
      <div v-if="dataPeriod != 3" :class="{ uploadFlex: showTableDetail }">
        <div :class="{ uploadFlexLeft: showTableDetail }">
          <div
            class="content-body-flex"
            :class="{ uploadFlexLefts: showTableDetail }"
            v-if="dataPeriod != 3"
          >
            <div class="content-top-1" v-if="!showTableDetail">
              <span v-if="dataPeriod == 0">{{ zq.substring(0, 4) }}年报表上传</span>
              <span v-if="dataPeriod == 1">{{ zq.substring(0, 4) - 1 }}年报表上传</span>
            </div>
            <div class="content-body-flexr">
              <div
                class="acknowlege content-top-2"
                @click="acknowledgeDialogVisible = true"
              >
                查看上传须知
              </div>
              <a
                href="//shuian-file.oss-cn-hangzhou.aliyuncs.com/user/%E6%8A%A5%E8%A1%A8%E6%A8%A1%E6%9D%BF.zip"
                class="btn-download content-top-2"
                download
                >下载模板</a
              >
            </div>
          </div>
          <div>
            <uploader
              :nsrsbhIndex="nsrsbhIndex"
              @onUpload="onUpload"
              :uuid="uuid"
              :showTableDetail="showTableDetail"
              v-if="actualZq"
              @showDetailBox="showDetailBox"
              @changeLength="changeLength"
              @overUpload="overUpload"
            ></uploader>
          </div>

          <div class="arrow-icon" v-if="!showTableDetail" @click="showMoreUpload">
            手动填写报表
            <i class="el-icon-d-arrow-left"></i>
          </div>
        </div>

        <table-list
          v-show="showTableDetail"
          :tables="tables"
          @listChanged="listChanged"
          manner="upload"
          class="table-list"
          :class="{ uploadFlexRight: showTableDetail }"
          @showDetailBalance="showDetailBalance"
          :nsrsbhIndex="nsrsbhIndex"
        ></table-list>
      </div>

      <template v-if="dataPeriod == 3">
        <report-method
          @openlicense="openlicense"
          @setName="changeName"
          ref="showReport"
          v-if="!isTaxAutoProcessing"
          :zq="zq"
          :uuid="uuid"
          :nsrsbhIndex="nsrsbhIndex"
        ></report-method>
        <div v-else>
          <div class="data-loading-wrapper">
            <img src="@/assets/test/data-loading.png" style="width: 465px" />
            <div class="text">数据读取中…</div>
          </div>
        </div>
      </template>

      <sa-footer v-if="dataPeriod != 3">
        <div class="footer">
          <button
            class="btn btn-purge-data"
            @click="handleBtnPurgeDataClicker"
            v-if="dataPeriod != 3"
          >
            清除数据
          </button>
          <!-- <button
            class="btn btn-default btn-next btn-right-none ease"
            @click="changeUserInfo1"
          >
            上一步
          </button> -->
          <button
            @click="handleBtnNext2"
            class="btn-primary new-next-btn ease new-next-btn360"
          >
            提交
          </button>
        </div>
      </sa-footer>
      <sa-footer v-if="dataPeriod == 3">
        <button
          @click="handleBtnNextAuto"
          class="btn-primary new-next-btn new-next-btn360 ease"
        >
          下一步
        </button>
      </sa-footer>
    </div>
    <!--温馨提示弹出框-->
    <el-dialog
      :visible.sync="tipDialogVisible"
      width="576px"
      :show-close="false"
      class="tipDialog"
      top="32vh"
    >
      <div class="inner">
        <img src="@/assets/test/tip.png" class="img" />
        <div class="right-wrapper">
          <div class="title">温馨提示</div>
          <div class="desc">
            为保证数据准确提取，需尽量保证文件命名标准，若一个Excel内包含多个sheet则sheet页命名需规范
          </div>
          <button class="btn btn-primary btn-i-get" @click="handleTipDialogBtnClick">
            知道了
          </button>
        </div>
      </div>
    </el-dialog>
    <!--温馨提示弹出框-->
    <!--上传错误提示弹出框-->
    <el-dialog
      :visible.sync="correctDialogVisible"
      width="80%"
      :show-close="true"
      class="correctDialog"
      top="1vh"
      @close="handleCorrectDialogClose"
    >
      <SectionTitle>报表匹配</SectionTitle>
      <div class="modify-table-wrapper">
        <modify-table v-if="correctDialogVisible"></modify-table>
      </div>
    </el-dialog>
    <!--/上传错误提示弹出框-->
    <!--纠错弹出框-->
    <el-dialog
      :visible.sync="uploadErrorDialogVisible"
      width="800px"
      :show-close="true"
      top="25vh"
      title="抱歉,以下表无法解析"
    >
      <el-table :data="uploadErrors" style="width: 100%">
        <el-table-column prop="userFileName" label="表名"></el-table-column>
        <el-table-column prop="reason" label="异常原因"></el-table-column>
      </el-table>
    </el-dialog>
    <!--/纠错弹出框-->
    <!--上传须知提示弹出框-->
    <el-dialog
      :visible.sync="acknowledgeDialogVisible"
      width="62.5%"
      :show-close="true"
      class="correctDialog"
    >
      <SectionTitle>上传须知</SectionTitle>
      <div class="modify-table-wrapper">
        <acknowledage-tab
          v-on:acknowledgeDialogVisible="clickbtn"
          :bankId="bankId"
        ></acknowledage-tab>
      </div>
    </el-dialog>
    <!--/上传须知提示弹出框-->

    <!--数据上传组件-->
    <TestDataUploader
      ref="testDataUploader"
      :bankId="bankId"
      :productID="productID"
      :productName="productName"
      :uuid="uuid"
      :zq="zq"
      :version="version"
    ></TestDataUploader>
    <!--/数据上传组件-->

    <loading
      :active.sync="isLoading"
      backgroundColor="#000"
      :can-cancel="true"
      :is-full-page="fullPage"
      loader="Dots"
      color="#2f54eb"
    ></loading>

    <!--所得税填报选择-->
    <el-dialog
      :visible.sync="showSDSDialog"
      :close-on-click-modal="false"
      width="800px"
      :show-close="false"
      class="correctDialog"
    >
      <SectionTitle>所得税填报选择</SectionTitle>
      <SDSFilter :tables="SDStables" @onDialogSubmit="handleSDSDialogSubmit"></SDSFilter>
    </el-dialog>
    <!--/所得税填报选择-->
    <licen ref="opendialog"></licen>
    <div v-if="showTables" class="containertables">
      <div class="tables">
        <div class="title">{{ balanceName }}</div>
        <div class="closes" @click="deleteTable">×</div>
        <div class="content">
          <el-table
            :data="tableData"
            style="width: 100%"
            height="620"
            row-key="subBsDm"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column
              prop="subBsDm"
              label="科目编码"
              width="160"
            ></el-table-column>
            <el-table-column
              prop="subBsMc"
              label="科目名称"
              width="150"
            ></el-table-column>
            <el-table-column label="年初余额" align="center">
              <el-table-column
                prop="ncBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="ncLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="期初余额" align="center">
              <el-table-column
                prop="beforeBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="beforeLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="本期发生额" align="center">
              <el-table-column
                prop="currentBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="currentLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="本年累计" align="center">
              <el-table-column
                prop="bnljBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="bnljLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="期末余额" align="center">
              <el-table-column
                prop="afterBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="afterLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="progressUpload" v-if="progressUpload">
      <div class="progressBox">
        <img class="undraw_upload" src="@/assets/result/undraw_upload_87y9@2x.png" alt />
        <el-progress
          type="circle"
          :percentage="progressUploadStatus"
          :width="286"
          :status="processTexted ? 'success' : null"
        ></el-progress>
        <div class="processText" v-if="processTexting">上传中...</div>
        <div class="processTexted processTextedNo" v-if="processTexted">上传成功</div>
      </div>
    </div>
    <fangdichan
      :zq="zq"
      :uuid="uuid"
      @closeBase="closeBase"
      :nsrsbm="nsrsbhIndex"
      v-if="fangdichanDialogVisible"
      @cancelBaseinfo="cancelBaseinfo"
      :dialogVisible="fangdichanDialogVisible"
    ></fangdichan>
  </div>
</template>

<script>
import {
  useAuthorize,
  breezeOuterAi,
  addReportValue,
  findTaxAutoStatusByUuid,
  getInfoByCompanyNsrsbm,
} from "@/api/api";
import { cloneDeep } from "lodash";
import BreadCrumb from "@/components/BreadCrumb";
import Uploader from "./components/Uploader";
import TableList from "./components/TableList";
import ModifyTable from "./components/ModifyTable";
import SectionTitle from "@/components/SectionTitle";
import AcknowledageTab from "./components/AcknowledageTab";
import SDSFilter from "./components/SDSFilter";
import MainHeader from "@/pages/components/MainHeader";
import ProgressDiagram from "@/pages/components/ProgressDiagram";
import Footer from "../ChooseTable/components/Footer";
import TestDataUploader from "../Components/TestDataUploader";
import { getUserTables, clearUploadedTableforTest } from "@/api/table";

import fangdichan from "./components/fangdichan";

import {
  clearUploadedTableforTestAi,
  useAuthorizeAi,
  genTestReportAi,
  findNeedExcel2,
  findNeedExcel3,
  zjBreezeLoginCreateTask,
} from "@/api/api";

import { getFdcInfo } from "@/api/fdc";

import { loadTableData } from "@/api/table";

import { mapState, mapMutations } from "vuex";
import { zq2name, previousZq } from "@/utils/helper";
import reportMethod from "../../Test/ChooseTable/components/report-method";
import licen from "./components/licen-dialog";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      checkBaseInfoData: "",
      baseVersion: 12, //房地产tag 需要基础信息表
      baseVersion21: 21, //房地产tag 需要基础信息表
      fangdichanDialogVisible: false,
      warningText: "",
      isTaxAutoProcessing: false,
      nameListArr: null,
      nameInfoList: "",
      identityValue: "",
      jsmobileCode: "",
      jscookies: "",
      jsinfo: "",
      canUpload: false,
      processTexting: true,
      processTexted: false,
      progressUpload: false,
      progressUploadStatus: "0",
      balanceName: "",
      showTables: false,
      tableData: [],
      tables: {},
      tipDialogVisible: false, //刚开始进入的提示框
      fullPage: true,
      isLoading: false,
      acknowledgeDialogVisible: false,
      uploadErrorDialogVisible: false, //控制无法识别的表的弹窗
      uploadErrors: [], //错误的表名及原因
      templateDownloadURL: "",
      // hasItemsInList: false,
      actualZq: 0,
      zqs: [],
      showSDSDialog: false,
      checkParts: false,
      code: 9996,
      index: -1,
      step2Msg: null,
      valueMsg: "",
      area: "",
      bizNo: "",
      radioIndex: "",
      passwordInput: "",
      clickQr: "",
      index1Data: "",
      indexJylsh: "",
      mobile: "",
      mobileCode: "",
      showTableDetail: true,
      batchNo: "",
      taxSiteUsername: "",
      // 山东
      czydm: "",
      // 山东
      //
      jsSelectRoleValueBoolean: false,
      //
      // 内蒙
      nmCookies: "",
      nmPhone: "",
      nmMobileCode: "",
      nmIdentityValue: "",
      nminfoData: "",
      nmotherData: "",
      // 新疆
      xjmobileCode: "",
      xjbdgxId: "",
      xjbsrType: "",
      xjcookies: "",
      xjtaxSiteUsername: "",
      xjvalue: "",
      buttonIndex: 1,
      // 重庆
      cqcookies: "",
      cqmobileCode: "",
      cqmobile: "",
      cqloginStatusOne: "",
      // 北京
      bjadditionalPassword: "",
      bjcookies: "",
      bjnameInfoList: "",
      bjvalue: "",
      //
      newZjBsry: "",
      // 辽宁
      lnmobile: "",
      lnVerifiTypeValue: "",
      lnmobileCode: "",
      lndtlSessionId: "",
      lndzswjTgc: "",
      // 广西
      gcmobileCode: "",
      gcVerifiTypeValue: "",
      gccookies: "",
      gcdjxh: "",
      gcczydm: "",
      gcsfmc: "",
      gcsfzjhm: "",
      // 河南
      hndqmobileCode: "",
      henanInfoListSelectValue: "",
      hndqCookies: "",
      hndqmobileCodeData: "",
      //重庆
      cqVerifiTypeNext: "",
      cqLoginType: "",
      cqmobileOne: "",
      cqVerifiTypeNextCode: false,
      // 湖南
      hunanCookies: "",
      hunanLoginStatus: "",
      hunanmobileCode: "",
      // 浙江新参数
      zjNewValue: "",
      newZjToken: "",
      // 上海参数
      shpasswordInput: "",
      shBsrs: "",
      shRadio: "",
      shCookies: "",
      shmobileCode: "",
      // 陕西参数
      shanxiCookies: "",
      shanxiLoginData: "",
      shanxidqmobileCode: "",
      shanxiInfoListSelectValue: "",
      // 河北参数
      hebeiNameValue: "",
      hebeidqmobileCode: "",
      hebeiCookies: "",
      hebeiGetInfoBoolean: false,
      // 江西参数
      jiangxicookies: "",
      jiangxiData: "",
      jiangxinsrbh: "",
      jiangxinsrmc: "",
      jiangxidqmobileCode: "",
      jiangxiInfoListSelectValue: "",
    };
  },
  components: {
    Loading,
    BreadCrumb,
    Uploader,
    TableList,
    SectionTitle,
    ModifyTable,
    AcknowledageTab,
    MainHeader,
    ProgressDiagram,
    saFooter: Footer,
    TestDataUploader,
    SDSFilter,
    reportMethod,
    licen,
    fangdichan,
  },
  props: {
    zq: [Number, String],
    productName: String,
    productID: [Number, String],
    zqLocation: [Number, String],
    bankId: String,
    uuid: String,
    dataPeriod: [Number, String],
    version: [Number, String],
    nsrsbhIndex: [Number, String],
    qykjzdIndex: [Number, String],
    selectvalueIndex: [Number, String],
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID", "SDSSelection"]),
    correctDialogVisible: {
      get: function () {
        return this.$store.state.UploadTable.correctDialogVisible;
      },
      set: function (value) {
        this.setCorrectDialogVisible(value);
      },
    },
    zqName: function () {
      return zq2name(this.actualZq);
    },
    progress: function () {
      let ret = 2;
      if (this.dataPeriod == 0) {
        ret = 2;
      } else if (this.dataPeriod == 1) {
        ret = 3;
      }
      return ret;
    },
    SDStables() {
      let ret = [];
      if (this.tables.所得税表) {
        ret = this.tables.所得税表.list;
      }
      return ret;
    },
  },
  async mounted() {
    await this.init();
    let nsrsbm = this.nsrsbhIndex;
    // 判断是不是正在取数中
    await findTaxAutoStatusByUuid(this.uuid, nsrsbm).then((res) => {
      if (res.msgCode == 1) {
        this.isTaxAutoProcessing = res.data;
        if (this.isTaxAutoProcessing && this.dataPeriod != 3) {
          this.getFindNeedExcel3();
        }
        if (this.$route.query.available == 0) {
          this.getFindNeedExcel3();
        }
      }
    });

    // 基础信息表
    if (!sessionStorage.getItem("isShowBase")) {
      this.showBaseInfo();
    }

    history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      history.pushState(null, null, document.URL);
    });
  },
  methods: {
    showBaseInfo() {
      if (this.version == this.baseVersion || this.version == this.baseVersion21) {
        this.fangdichanDialogVisible = true;
      }
    },
    closeBase() {
      sessionStorage.setItem("isShowBase", 100);
      this.fangdichanDialogVisible = false;
    },
    cancelBaseinfo() {
      sessionStorage.setItem("isShowBase", 100);
      this.fangdichanDialogVisible = false;
    },
    getFindNeedExcel3() {
      let params = {
        uuid: this.uuid,
        tag: this.version,
        zq: this.zq,
        nsrsbm: this.nsrsbhIndex,
        taxNo: JSON.parse(this.$route.query.info).consumerEntity.nsrsbh,
      };
      findNeedExcel3(params).then((res) => {
        this.warningText = res.data;
      });
    },
    checkOld() {
      let taxNo = "";
      if (this.$route.query.available) {
        taxNo = this.taxSiteUsername || sessionStorage.getItem("taxSiteUsername");
      } else {
        taxNo = "";
      }
      const loading = this.$loading({
        lock: true,
        text: "正在提交数据！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      genTestReportAi({
        token: localStorage.getItem("authorization"),
        nsrsbh: this.nsrsbhIndex,
        tag: this.version,
        uuid: this.uuid,
        zq: this.zq,
        invoiceNsrsbh: taxNo,
        isAutoData: 1,
        getDataWay: this.$route.query.available ? 2 : 1, //授权过就传2(自动)
        taxRateCalculation: this.$route.query.taxRateCalculation,
      }).then((res) => {
        if (res.msgCode == 1) {
          loading.close();
          this.$router.push({
            name: "test-InvoiceUpload",
            params: {
              nsrsbh: this.taxSiteUsername,
              uuid: this.uuid,
            },
          });
        } else if (res.msgCode == -1) {
          loading.close();
          //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
          this.$alert(res.msgInfo, "错误", {
            confirmButtonText: "确定",
            type: "error",
            callback: (action) => {
              this.$router.push({ name: "homePage" });
            },
          });
        } else {
          loading.close();
          this.$message({
            message: res.msgInfo,
            type: "error",
            duration: 5000,
            callback: (action) => {
              alert("dasdsa");
            },
          });
        }
      });
    },
    // 从手动切换回自动
    changeUserInfo1() {
      if (this.$route.query.available == 0) {
        this.$router.push({
          name: "choose-table",
          params: {
            productID: this.productID,
            bankId: this.bankId,
            productName: this.productName,
          },
        });
      } else {
        this.$router.push({
          name: "upload-table-fdc",
          params: {
            zq: this.zq,
            zqLocation: this.zqLocation,
            dataPeriod: 3,
            nsrsbhIndex: this.nsrsbhIndex,
            qykjzdIndex: this.qykjzdIndex,
          },
          query: {
            info: this.$route.query.info,
            available: this.$route.query.available,
            thirdDataSource: this.$route.query.thirdDataSource,
            taxRateCalculation: this.$route.query.taxRateCalculation,
            taxfirmTag: this.$route.query.taxfirmTag,
          },
        });
        let nsrsbm = this.nsrsbhIndex;
        // 判断是不是正在取数中
        findTaxAutoStatusByUuid(this.uuid, nsrsbm).then((res) => {
          if (res.msgCode == 1) {
            this.isTaxAutoProcessing = res.data;
          }
        });
      }
    },
    handleBtnNextAuto() {
      //
      if (this.version == this.baseVersion || this.version == this.baseVersion21) {
        // let params = {
        //   nsrsbm: this.nsrsbhIndex,
        // };
        let projectInfoId = JSON.parse(this.$route.query.info).projectInfoId;
        getFdcInfo(projectInfoId).then((res) => {
          this.checkBaseInfoData = res.data;
          if (!this.checkBaseInfoData || !this.checkBaseInfoData.projectName) {
            this.fangdichanDialogVisible = true;
          } else {
            this.checkBaseToNext();
          }
        });
      } else {
        this.checkBaseToNext();
      }
      //
    },
    // 下一步
    checkBaseToNext() {
      if (this.isTaxAutoProcessing) {
        this.$router.push({
          name: "upload-table-fdc",
          params: {
            zq: this.zq,
            zqLocation: this.zqLocation,
            dataPeriod: 0,
            nsrsbhIndex: this.nsrsbhIndex,
            qykjzdIndex: this.qykjzdIndex,
          },
          query: {
            info: this.$route.query.info,
            available: this.$route.query.available,
            thirdDataSource: this.$route.query.thirdDataSource,
            taxRateCalculation: this.$route.query.taxRateCalculation,
            taxfirmTag: this.$route.query.taxfirmTag,
          },
        });
      } else {
        this.useauthorize((res) => {
          this.getFindNeedExcel3();
          sessionStorage.setItem("taxSiteUsernameTaxNo", res.data.nsrsbh);
          this.$router.push({
            name: "upload-table-fdc",
            params: {
              zq: this.zq,
              zqLocation: this.zqLocation,
              dataPeriod: 0,
              nsrsbhIndex: this.nsrsbhIndex,
              qykjzdIndex: this.qykjzdIndex,
            },
            query: {
              info: this.$route.query.info,
              available: this.$route.query.available,
              thirdDataSource: this.$route.query.thirdDataSource,
              taxRateCalculation: this.$route.query.taxRateCalculation,
              taxfirmTag: this.$route.query.taxfirmTag,
            },
          });
        });
      }
    },
    ...mapMutations("UploadTable", ["setCorrectDialogVisible"]),
    showDetailBox() {
      // this.showTableDetail = true;
    },

    overUpload() {
      // this.openF5Refresh();
      this.progressUploadStatus = 100;
      this.processTexted = true;
      this.processTexting = false;
      setTimeout(() => {
        this.progressUpload = false;
        this.processTexted = false;
        this.processTexting = true;
      }, 1000);
    },

    changeLength(val) {
      // this.stopF5Refresh();
      this.progressUpload = true;
      this.progressUploadStatus = Number(((val.status / val.length) * 100).toFixed(2));
      if (this.progressUploadStatus >= 100) {
        this.progressUploadStatus = 100;
      }
    },

    showDetailBalance(val, zq) {
      this.balanceName = zq.slice(0, 4) + "年余额表";

      this.detailZq = zq;
      this.showTables = true;

      let token = localStorage.getItem("authorization");

      loadTableData(zq, "YEB", token, this.uuid).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
          return;
        }
        this.tableData = res.data.dataJson;
      });
    },
    deleteTable() {
      this.showTables = false;
    },
    showMoreUpload() {
      this.showTableDetail = true;
    },

    // 打开授权协议
    openlicense() {
      this.$refs.opendialog.openDialog();
    },
    changeName(val) {
      // console.log(val);
      sessionStorage.setItem("taxSiteUsername", val.name);
      this.taxSiteUsername = val.name;
      this.taxSitePwd = val.password;
      this.activeRead = val.active;
      this.index = val.index;
      this.step2Msg = val.step2Msg;
      this.valueMsg = val.value;
      this.area = val.province;
      this.bizNo = val.bizNo;
      this.radioIndex = val.radio;
      this.passwordInput = val.passwordInput;
      this.clickQr = val.clickQr;
      this.indexJylsh = val.indexJylsh;
      this.index1Data = val.index1Data;
      this.mobile = val.mobile;
      this.mobileCode = val.mobileCode;
      this.batchNo = val.batchNo;
      this.jsmobileCode = val.jsmobileCode;
      this.jscookies = val.jscookies;
      this.jsinfo = val.jsinfo;
      this.identityValue = val.identityValue;
      this.nameListArr = val.nameListArr;
      this.nameInfoList = val.nameInfoList;
      // 山东
      this.czydm = val.czydm;
      // 山东
      //
      this.jsSelectRoleValueBoolean = val.jsSelectRoleValueBoolean;
      //
      // 内蒙参数
      this.nmCookies = val.nmCookies;
      this.nmPhone = val.nmPhone;
      this.nmMobileCode = val.nmMobileCode;
      this.nmIdentityValue = val.nmIdentityValue;
      this.nminfoData = val.nminfoData;
      this.nmotherData = val.nmotherData;
      // 新疆
      this.xjmobileCode = val.xjmobileCode;
      this.xjbdgxId = val.xjbdgxId;
      this.xjbsrType = val.xjbsrType;
      this.xjcookies = val.xjcookies;
      this.xjtaxSiteUsername = val.xjtaxSiteUsername;
      this.xjvalue = val.xjvalue;
      // 重庆
      this.cqcookies = val.cqcookies;
      this.cqmobileCode = val.cqmobileCode;
      this.cqmobile = val.cqmobile;
      this.cqloginStatusOne = val.cqloginStatusOne;
      // 北京
      this.bjcookies = val.bjcookies;
      this.bjadditionalPassword = val.bjadditionalPassword;
      this.bjnameInfoList = val.bjnameInfoList;
      this.bjvalue = val.bjvalue;
      // 辽宁
      this.lnmobile = val.lnmobile;
      this.lnVerifiTypeValue = val.lnVerifiTypeValue;
      this.lnmobileCode = val.lnmobileCode;
      this.lndtlSessionId = val.lndtlSessionId;
      this.lndzswjTgc = val.lndzswjTgc;
      // 广西
      this.gcmobileCode = val.gcmobileCode;
      this.gcVerifiTypeValue = val.gcVerifiTypeValue;
      this.gccookies = val.gccookies;
      this.gcdjxh = val.gcdjxh;
      this.gcczydm = val.gcczydm;
      this.gcsfmc = val.gcsfmc;
      this.gcsfzjhm = val.gcsfzjhm;
      // 河南
      this.hndqmobileCode = val.hndqmobileCode;
      this.henanInfoListSelectValue = val.henanInfoListSelectValue;
      this.hndqCookies = val.hndqCookies;
      this.hndqmobileCodeData = val.hndqmobileCodeData;
      //重庆
      this.cqVerifiTypeNext = val.cqVerifiTypeNext;
      this.cqLoginType = val.cqLoginType;
      this.cqmobileOne = val.cqmobileOne;
      this.cqVerifiTypeNextCode = val.cqVerifiTypeNextCode;
      // 湖南
      this.hunanCookies = val.hunanCookies;
      this.hunanLoginStatus = val.hunanLoginStatus;
      this.hunanmobileCode = val.hunandqmobileCode;
      //
      this.zjNewValue = val.zjNewValue;
      this.newZjToken = val.newZjToken;
      this.newZjBsry = val.newZjBsry;
      // 上海
      this.shpasswordInput = val.shpasswordInput;
      this.shBsrs = val.shBsrs;
      this.shRadio = val.shRadio;
      this.shCookies = val.shCookies;
      this.shmobileCode = val.shmobileCode;
      // 陕西
      this.shanxiCookies = val.shanxiCookies;
      this.shanxiLoginData = val.shanxiLoginData;
      this.shanxidqmobileCode = val.shanxidqmobileCode;
      this.shanxiInfoListSelectValue = val.shanxiInfoListSelectValue;
      // 河北
      this.hebeiNameValue = val.hebeiNameValue;
      this.hebeidqmobileCode = val.hebeidqmobileCode;
      this.hebeiCookies = val.hebeiCookies;
      this.hebeiGetInfoBoolean = val.hebeiGetInfoBoolean;
      // 江西
      this.jiangxicookies = val.jiangxicookies;
      this.jiangxiData = val.jiangxiData;
      this.jiangxinsrbh = val.jiangxinsrbh;
      this.jiangxinsrmc = val.jiangxinsrmc;
      this.jiangxidqmobileCode = val.jiangxidqmobileCode;
      this.jiangxiInfoListSelectValue = val.jiangxiInfoListSelectValue;
    },

    useauthorize(callback) {
      var uPattern = /^[a-zA-Z0-9]{15,20}$/;
      if (!uPattern.test(this.taxSiteUsername)) {
        this.$message("请输入15-20位账号！");
        return;
      }
      if (!this.taxSitePwd) {
        this.$message("请输入密码！");
        return;
      }
      if (this.clickQr) {
        this.$alert("请点击验证按钮！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      // 浙江地区校验
      if (!this.valueMsg && this.index == 0) {
        this.$alert("请选择报税人员！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // 宁波地区校验
      if (!this.mobile && this.index == 1) {
        this.$alert("手机号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (!this.mobileCode && this.index == 1) {
        this.$alert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      //
      if (!this.identityValue && this.index == 2) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (!this.jsSelectRoleValueBoolean && this.index == 2) {
        this.$alert("请选择人员！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (!this.jsmobileCode && this.index == 2) {
        this.$alert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // 如果是选择密码方式，密码不能为空
      if (!this.passwordInput && this.index == 0 && this.radioIndex == 2) {
        this.$alert("密码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 3 && !this.czydm) {
        this.$alert("请输入委托人手机号/身份证号码/操作员代码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 4 && !this.nmPhone) {
        this.$alert("手机号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 4 && !this.nmMobileCode) {
        this.$alert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 5 && !this.xjvalue) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 5 && !this.xjmobileCode) {
        this.$alert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 7 && !this.cqmobile) {
        this.$alert("用户名不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 7 && this.cqVerifiTypeNext) {
        this.$alert("请输入用户名之后，点击下一步", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (
        this.index == 7 &&
        this.cqLoginType == "smsAuthentication" &&
        !this.cqmobileOne
      ) {
        this.$alert("手机号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (
        this.index == 7 &&
        this.cqLoginType == "smsAuthentication" &&
        !this.cqmobileCode
      ) {
        this.$alert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // cqVerifiTypeNextCode
      if (this.index == 7 && this.cqVerifiTypeNextCode) {
        this.$alert("请输入手机号和验证码之后，点击下一步", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 7 && this.cqloginStatusOne == "sfChoose" && !this.cqmobileCode) {
        this.$alert("验证码不能为空", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // cqloginStatusOne

      // cqmobileOne

      if (this.index == 8 && !this.bjvalue) {
        this.$alert("请选择办税人员！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 8 && !this.bjadditionalPassword) {
        this.$alert("密码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 9 && !this.lnVerifiTypeValue) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 9 && !this.lnmobile) {
        this.$alert("请输入手机号！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 9 && !this.lnmobileCode) {
        this.$alert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 10 && !this.gcVerifiTypeValue) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 10 && !this.gcmobileCode) {
        this.$alert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 11 && !this.henanInfoListSelectValue) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 11 && !this.hndqmobileCode) {
        this.$alert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (
        this.index == 12 &&
        this.hunanLoginStatus == "sfChoose" &&
        !this.hunanmobileCode
      ) {
        this.$alert("验证码不能为空", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 13 && !this.shBsrs) {
        this.$alert("请选择办税人员！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 13 && this.shRadio == 1 && !this.shpasswordInput) {
        this.$alert("请输入密码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 13 && this.shRadio == 2 && !this.shmobileCode) {
        this.$alert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // shanxiInfoListSelectValue
      if (this.index == 14 && !this.shanxiInfoListSelectValue) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // 河北
      if (this.index == 15 && !this.hebeiGetInfoBoolean) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 15 && !this.hebeidqmobileCode) {
        this.$alert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 16 && !this.jiangxiInfoListSelectValue) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 16 && !this.jiangxidqmobileCode) {
        this.$alert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // jiangxiInfoListSelectValue

      if (!this.activeRead) {
        this.$alert("请阅读并勾选授权协议", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: "正在提交数据,请耐心等待！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 通用授权
      if (this.index == -1) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          authorizeResult: true,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          taxpayerId: this.taxSiteUsername,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          // projectId: JSON.parse(localStorage.getItem("projectIds")),
          projectId: JSON.parse(this.$route.query.info).id,
          batchNo: this.batchNo || "",
        };
        useAuthorizeAi(params).then((res) => {
          loading.close(res);
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 浙江地区授权
      if (this.index == 0) {
        if (this.$route.query.thirdDataSource == "DB_WF") {
          let params = {
            // id: JSON.parse(localStorage.getItem("projectId")),
            id: JSON.parse(this.$route.query.info).consumerEntity.id,
            nsrsbm: this.nsrsbhIndex,
            uuid: this.uuid,
            tag: this.version,
            token: localStorage.getItem("authorization"),
            zq: this.zq,
            taxpayerId: this.taxSiteUsername,
            taxSiteUsername: this.taxSiteUsername,
            taxSitePwd: this.taxSitePwd,
            acwTc: this.step2Msg.acw_tc,
            aliyungfTc: this.step2Msg.aliyungf_tc,
            dtlSessionId: this.step2Msg.dtl_SESSION_ID,
            loginType: this.radioIndex == 1 ? "SMRZ" : "PWD",
            additionalUsername: this.valueMsg,
            additionalPassword: this.radioIndex == 1 ? this.bizNo : this.passwordInput,
            area: this.area,
            projectManager: JSON.parse(localStorage.getItem("user")).realName,
            projectManagerId: JSON.parse(localStorage.getItem("user")).id,
            // projectId: JSON.parse(localStorage.getItem("projectIds")),
            projectId: JSON.parse(this.$route.query.info).id,
            batchNo: this.batchNo,
            cookies: this.step2Msg,
            djxh: this.nameInfoList.djxh,
            nsrmc: this.nameInfoList.nsrmc,
            sfzjhm: this.nameInfoList.sfzjhm,
            sjhm: this.nameInfoList.sjhm,
            swjgDm: this.nameInfoList.swjgDm,
            type: this.nameInfoList.type,
            zjUuid: this.nameInfoList.uuid,
            xm: this.nameInfoList.xm,
          };
          breezeOuterAi(params).then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
              sessionStorage.setItem("qykjzd", res.data.kjzd);
              sessionStorage.setItem("companyName", res.data.companyName);
              callback(res);
            } else {
              this.$refs.showReport.getprovinceBath();
              this.$alert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        } else {
          let params = {
            czydm: this.zjNewValue,
            password: this.passwordInput,
            programId: this.uuid,
            token: this.newZjToken,
            zq: this.zq,
            bsry: this.newZjBsry,
          };
          zjBreezeLoginCreateTask(params).then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              callback(res);
            } else {
              this.$refs.showReport.getprovinceBath();
              this.$alert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        }
      }

      // 宁波授权
      if (this.index == 1) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          taxpayerId: this.taxSiteUsername.trim(),
          taxSiteUsername: this.taxSiteUsername.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          legalMobile: this.mobile.trim(),
          dzswjTgc: this.index1Data.dzswj_TGC,
          jylsh: this.indexJylsh,
          jsessionid: this.index1Data.jsessionid,
          area: this.area,
          captcha: this.mobileCode.trim(),
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          // projectId: JSON.parse(localStorage.getItem("projectIds")),
          projectId: JSON.parse(this.$route.query.info).id,
          batchNo: this.batchNo,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 江苏授权
      if (this.index == 2) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          taxpayerId: this.taxSiteUsername.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          smsCode: this.jsmobileCode.trim(),
          batchNo: this.batchNo,
          area: this.area,
          jsLoginType: "1",
          rybz: this.jsinfo.rybz,
          yxbz: this.jsinfo.yxbz,
          bz: this.jsinfo.bz,
          xm: this.jsinfo.xm,
          tel: this.jsinfo.tel,
          sjhm: this.jsinfo.sjhm,
          sfzh: this.jsinfo.sfzh,
          zjlx: this.jsinfo.zjlx,
          type: this.jsinfo.type,
          cookies: this.jscookies,
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          // projectId: JSON.parse(localStorage.getItem("projectIds")),
          projectId: JSON.parse(this.$route.query.info).id,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 山东授权
      if (this.index == 3) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          taxpayerId: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          batchNo: this.batchNo,
          area: this.area,
          czydm: this.czydm,
          taxSiteUsername: this.czydm,
          thirdDataSource: this.$route.query.thirdDataSource,

          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          // projectId: JSON.parse(localStorage.getItem("projectIds")),
          projectId: JSON.parse(this.$route.query.info).id,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 内蒙授权
      if (this.index == 4) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          // taxSiteUsername: this.taxSiteUsername.trim(),
          taxpayerId: this.taxSiteUsername.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          area: this.area,
          batchNo: this.batchNo,
          cookies: this.nmCookies,
          taxSiteUsername: this.nmPhone,
          infoData: this.nminfoData,
          otherData: this.nmotherData,
          smsCode: this.nmMobileCode,
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          // projectId: JSON.parse(localStorage.getItem("projectIds")),
          projectId: JSON.parse(this.$route.query.info).id,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 新疆授权
      if (this.index == 5) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          taxpayerId: this.taxSiteUsername.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.xjmobileCode,
          bdgxId: this.xjbdgxId,
          bsrType: this.xjbsrType,
          cookies: this.xjcookies,
          taxSiteUsername: this.xjtaxSiteUsername,
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          // projectId: JSON.parse(localStorage.getItem("projectIds")),
          projectId: JSON.parse(this.$route.query.info).id,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 重庆授权
      if (this.index == 7) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          batchNo: this.batchNo,
          area: this.area,
          cookies: this.cqcookies,
          loginStatus: this.cqloginStatusOne,
          taxSiteUsername: this.cqmobile.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          taxpayerId: this.taxSiteUsername.trim(),
          smsCode: this.cqmobileCode,
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          // projectId: JSON.parse(localStorage.getItem("projectIds")),
          projectId: JSON.parse(this.$route.query.info).id,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 北京授权
      if (this.index == 8) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          loginType: 1,
          additionalUsername: this.taxSiteUsername,
          additionalPassword: this.bjadditionalPassword,
          area: this.area,
          batchNo: this.batchNo,
          cookies: this.bjcookies,
          //
          xm: this.bjnameInfoList.xm,
          sjhm: this.bjnameInfoList.sjhm,
          sfzjhm: this.bjnameInfoList.zjhm,
          sfmc: this.bjnameInfoList.sfmc,
          key: this.bjnameInfoList.key,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 辽宁授权
      if (this.index == 9) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          loginType: this.lnVerifiTypeValue,
          phone: this.lnmobile,
          smsCode: this.lnmobileCode,
          dtlSessionId: this.lndtlSessionId,
          dzswjTgc: this.lndzswjTgc,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 广西授权
      if (this.index == 10) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          smsCode: this.gcmobileCode,
          cookies: this.gccookies,
          djxh: this.gcdjxh,
          czydm: this.gcczydm,
          sfmc: this.gcsfmc,
          sfzjhm: this.gcsfzjhm,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 河南授权
      if (this.index == 11) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          additionalUsername: this.taxSiteUsername,
          additionalPassword: this.taxSitePwd,
          smsCode: this.hndqmobileCode,
          djxh: this.henanInfoListSelectValue,
          wsbsfwtsessionId: this.hndqCookies,
          data: this.hndqmobileCodeData,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 湖南授权
      if (this.index == 12) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          smsCode: this.hunanmobileCode,
          cookies: this.hunanCookies,
          loginStatus: this.hunanLoginStatus,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 上海授权
      if (this.index == 13) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          //
          // 验证码
          smsCode: this.shmobileCode,
          ssoLoginTgc: this.shCookies.sso_LOGIN_TGC,
          jsessionid: this.shCookies.jsessionid,
          tgc: this.shCookies.tgc,
          legalMobile: "",
          //
          bsrType: this.shBsrs.bsrType,
          bdgxId: this.shBsrs.bdgxId,
          loginType: this.shRadio,
          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.shRadio == 1 ? this.shpasswordInput : this.taxSitePwd, // 二级密码
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 陕西授权
      if (this.index == 14) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          area: this.area,
          cookies: this.shanxiCookies,
          data: this.shanxiLoginData ? JSON.stringify(this.shanxiLoginData) : "",
          smsCode: this.shanxidqmobileCode,
          batchNo: this.batchNo,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 河北授权
      if (this.index == 15) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.hebeiNameValue,
          taxSitePwd: this.taxSitePwd,
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.hebeidqmobileCode,
          cookies: this.hebeiCookies,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 江西授权
      if (this.index == 16) {
        let params = {
          // id: JSON.parse(localStorage.getItem("projectId")),
          id: JSON.parse(this.$route.query.info).consumerEntity.id,
          nsrsbm: this.nsrsbhIndex,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          cookies: this.jiangxicookies,
          data: JSON.stringify(this.jiangxiData),
          swjgDm: this.jiangxinsrbh,
          nsrmc: this.jiangxinsrmc,
          smsCode: this.jiangxidqmobileCode,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
    },

    handleSDSDialogSubmit() {
      this.showSDSDialog = false;
    },

    checkIfAllFiltedTableFilled() {
      //把所有列表展平
      const tables = [];
      for (const key in this.tables) {
        if (this.tables.hasOwnProperty(key)) {
          const tablesInkey = this.tables[key].list;
          for (const table of tablesInkey) {
            tables.push(table);
          }
        }
      }
      return this.SDSSelection.every((aSDSSelection) => {
        const templateId = aSDSSelection.templateId;
        const found = tables.find((table) => {
          return templateId == table.templateId && table.status != "未上传";
        });
        return !!found;
      });
    },
    //本期的下一步按钮
    handleBtnNext() {
      this.$router.push({
        name: "upload-table-fdc",
        params: {
          zq: this.zq,
          zqLocation: this.zqLocation,
          dataPeriod: 1,
          nsrsbhIndex: this.nsrsbhIndex,
          qykjzdIndex: this.qykjzdIndex,
        },
        query: {
          info: this.$route.query.info,
          available: this.$route.query.available,
          thirdDataSource: this.$route.query.thirdDataSource,
          taxRateCalculation: this.$route.query.taxRateCalculation,
          taxfirmTag: this.$route.query.taxfirmTag,
        },
      });
    },
    //上期的下一步按钮点击
    handleBtnNext2() {
      if (this.version == this.baseVersion || this.version == this.baseVersion21) {
        // let params = {
        //   nsrsbm: this.nsrsbhIndex,
        // };
        let projectInfoId = JSON.parse(this.$route.query.info).projectInfoId;
        getFdcInfo(projectInfoId).then((res) => {
          this.checkBaseInfoData = res.data;
          if (!this.checkBaseInfoData || !this.checkBaseInfoData.projectName) {
            this.fangdichanDialogVisible = true;
          } else {
            this.uploadTableSumbit();
          }
        });
      } else {
        this.uploadTableSumbit();
      }
    },
    // 手动上传的提交
    uploadTableSumbit() {
      let params = {
        uuid: this.uuid,
        tag: this.version,
        zq: this.zq,
        nsrsbm: this.nsrsbhIndex,
        taxNo: JSON.parse(this.$route.query.info).consumerEntity.nsrsbh,
      };
      findNeedExcel2(params).then((res) => {
        if (res.msgCode == -1) {
          this.$confirm(res.msgInfo, "提示", {
            confirmButtonText: "跳过",
            cancelButtonText: "补充数据",
            type: "warning",
          })
            .then(() => {
              this.submitDataResult();
            })
            .catch(() => {});
        } else {
          this.submitDataResult();
        }
      });
    },
    submitDataResult() {
      let params = {
        nsrsbh: this.nsrsbhIndex,
        zq: this.zq,
        uuid: this.uuid,
      };
      addReportValue(params).then((res) => {
        if (res.code == 200) {
          this.checkOld();
        }
      });
    },

    async init() {
      // this.isLoading = true;

      if (this.dataPeriod == 1) {
        //上个征期
        this.actualZq = previousZq(this.zq);
        this.zqs.push(this.zq);
        this.zqs.push(this.actualZq);
      } else if (this.dataPeriod == 0) {
        //当前征期
        this.actualZq = this.zq;
      } else if (this.dataPeriod == 3) {
        //当前征期
        this.actualZq = this.zq;
      }

      this.$store.commit("UploadTable/setZq", { zq: this.actualZq });
      this.$store.commit("UploadTable/setZqList", { zq: this.zq });

      await this.$store.cache.dispatch("loadCompanyInfo");
      await this.loadList();
      this.$store.commit("UploadTable/setProduct", {
        name: this.productName,
        id: this.productID,
        bankID: this.bankId,
      });

      //下载模板的连接
      switch (sessionStorage.getItem("qykjzd")) {
        case "qykjzd":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%88%B6%E5%BA%A6.zip";
          break;
        case "qykjzz":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%87%86%E5%88%99.zip";
          break;
        case "xqykjzz":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E5%B0%8F%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%87%86%E5%88%99.zip";
          break;
      }

      // setTimeout(() => {
      //   this.isLoading = false;
      // }, 500);
    },
    clickbtn() {
      this.acknowledgeDialogVisible = false;
    },
    handleTipDialogBtnClick() {
      this.tipDialogVisible = false;
      localStorage.setItem("hideWenxinTip", true);
    },
    listChanged() {
      this.loadList();
    },
    //清空所有已经上传的报表
    handleBtnPurgeDataClicker() {
      this.$confirm(`所有文件将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await clearUploadedTableforTestAi(
          // this.companyInfo.nsrsbm,
          // sessionStorage.getItem("nsrsbh"),
          this.nsrsbhIndex,
          this.actualZq,
          localStorage.getItem("authorization"),
          this.uuid
        );

        if (result.code == 200) {
          this.$emit("listChanged");
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.loadList();
        } else {
          this.$message.error(result.message);
        }
      });
    },
    //修正表格的弹出框
    showCorrectDialog() {
      this.correctDialogVisible = true;
    },
    //错误原因弹出框
    showUploadErrorDialog() {
      this.uploadErrorDialogVisible = true;
    },
    submitData() {
      this.correctDialogVisible = true;
    },
    async loadList() {
      // 利用缓存
      const tables = await getUserTables(
        this.bankId,
        this.version,
        // "qykjzd",
        // sessionStorage.getItem("nsrsbh"),
        this.nsrsbhIndex,
        // "1594002474322",
        this.actualZq,
        this.uuid,
        false
      );

      // if (tables.data && tables.msgCode != -1) {
      //   for (let i in tables.data) {
      //     if (tables.data[i].uploadNum > 0) {
      //       this.showTableDetail = true;
      //     }
      //   }
      // }

      //过滤未上传的表格
      this.tables = {};
      let i = 0;
      for (const grpName in tables.data) {
        i++;
        const grpData = tables.data[grpName];
        const newGrp = [];

        for (const table of grpData.tableInfo) {
          i++;
          //if (table.uploadStatus != "未上传") {
          const {
            tableNameCn,
            tableNameEn: templateId,
            uploadStatus: status,
            tableNum,
            zq,
            children,
          } = table;

          //整理一下children的格式
          const thechildren = children.map((child) => {
            i++;
            const {
              tableNameCn,
              tableNameEn: templateId,
              uploadStatus: status,
              tableNum,
              zq,
              children,
            } = child;
            return {
              name: `${tableNameCn}`,
              status,
              zq,
              templateId,
              tableNum,
              children,
              id: i,
            };
          });
          i++;
          const item = {
            name: `${tableNameCn}`,
            status,
            zq,
            templateId,
            tableNum,
            id: i,
            children: thechildren,
          };

          newGrp.push(item);
          //}
        }

        if (newGrp.length > 0) {
          this.tables[grpName] = {
            list: newGrp,
            totalNum: grpData.totalNum,
            uploadNum: grpData.uploadNum,
          };
        }
      }
    },
    async onUpload(file, data) {
      //如果有错误的表格则弹出弹出框
      if (data.data.errorData.length > 0) {
        this.uploadErrors = data.data.errorData;
        this.showUploadErrorDialog();
      }

      const groups = data.data.succData;

      //根据上传的excel构建本地对应的数组

      //上传文件后需要纠错的表格信息
      const tables4correction = [];

      for (const groupName in groups) {
        const tables = groups[groupName]; //分组里包含的表

        for (const table of tables) {
          const status = table.uploadStatus;

          const { templateId, sysFileName, userFileName } = table;

          if (status === "待修改") {
            table.data = {};
            table.data.templateId = templateId;
            table.data.groupName = groupName;
            table.data.sysFileName = sysFileName;
            table.data.userFileName = userFileName;

            tables4correction.push(table.data);
          }
        }
      }

      if (tables4correction.length > 0) {
        this.$store.commit("UploadTable/setCorrectionTableData", {
          tables4correctionData: tables4correction,
        });
        this.showCorrectDialog();
      }
      this.loadList();
    },
    handleCorrectDialogClose() {
      this.loadList();
    },
  },
  watch: {
    $route: function (to, from) {
      window.scrollTo(0, 0);
      this.init();
      // 拿到目标参数 to.query.id 去再次请求数据接口
      //this.loadPageData(to.query.id);
      //this.dataPeriod;
      //debugger;
    },
    dataPeriod(newdataPeriod, olddataPeriod) {
      if (newdataPeriod == 3) {
        let nsrsbm = this.nsrsbhIndex;
        findTaxAutoStatusByUuid(this.uuid, nsrsbm).then((res) => {
          if (res.msgCode == 1) {
            this.isTaxAutoProcessing = res.data;
          }
        });
      }
    },
  },
  destroyed() {
    window.removeEventListener(
      "popstate",
      () => {
        history.pushState(null, null, document.URL);
      },
      false
    );
  },
};
</script>

<style lang="stylus" scoped>
.modify-table-wrapper {
  margin-top: 30px;
}

.correctDialog {
  padding: 0;

  >>> .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }
}

.tipDialog {
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;

    .img {
      width: 174px;
      flex-shrink: 0;
    }

    .right-wrapper {
      margin-left: 50px;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #062340;
      }

      .desc {
        margin-top: 16px;
        color: #6b7897;
        font-size: 16px;
        line-height: 1.5;
      }

      .btn-i-get {
        height: 40px;
        line-height: 40px;
        margin-top: 17px;
      }
    }
  }
}

.the-title {
  margin: 30px 30px 30px 0;
}

.content-body {
  margin: 30px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 0 0 8px 8px;
  padding: 20px;
  padding-top: 0;
  margin-top: 0;
}

.upload-footer {
  margin-top: 16px;

  .tip {
    float: left;
    color: #FAAD14;
    font-size: 14px;
  }

  .acknowlege, .btn-download {
    background: #fff;
    font-size: 14px;
    float: right;
    color: #2F54EB;
    vertical-align: middle;

    .icon {
      vertical-align: middle;
      font-size: 22px;
    }
  }

  .acknowlege {
    margin-right: 48px;
    cursor: pointer;
    float: right;
  }

  .acknowlege:hover, .btn-download:hover {
  }
}

.table-list {
  margin-top: 18px;
  padding-left: 24px;
}

.footer {
  // margin-top: 20px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 10px;
  }

  .btn-purge-data {
    color: #ff6464;
  }
}

// >>> .el-dialog__header {
// padding: 0;
// }

// >>> .section-title {
// margin-top: 10px;
// }
.page-wrapper {
  padding-bottom: 84px;
}

.progressdis {
  // position: absolute;
  width: 10%;
  // margin: 24px auto 45px;
  margin-left: 100px;
  margin-top: 2px;
}

.btn-right-none {
  margin-right: 16px !important;
  width: 180px;
  height: 46px;
  // line-height: 44px;
  box-sizing: border-box;
}

// .report-method {
// margin: 20px;
// }
.nomargin {
  margin: 0 30px;
  padding: 0;
}

.main-top {
  display: flex;
  justify-content: space-between;
}

.content-body-flex {
  display: flex;
  align-item: center;
  justify-content: space-between;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 24px;
  background: rgba(255, 255, 255, 1);
  // padding: 0 20px;
  // margin: 0 20px;
}

.content-body-flexr {
  display: flex;
  align-item: center;
  font-size: 14px;
  color: rgba(47, 84, 235, 1);
}

.content-top-1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.content-top-2 {
  margin-right: 24px;
}

.content-body-tab {
  display: flex;
  justify-content: center;
  background: #fff;
  border-bottom: 1px solid #D9D9D9;
  margin: 0 30px 0 30px;
}

.content-body-tabl, .content-body-tabr {
  width: 174px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  // box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
  // border-radius: 18px 18px 0px 0px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  border: 1px solid #fff;
  border-bottom: none;
}

.content-body-tabl a:hover {
  text-decoration: none;
}

a {
   text-decoration: none;
}

.content-body-tabl {
  margin-right: 24px;
}

.router-link-active {
  text-decoration: none;
}

.zqLocation {
  color: #2F54EB;
  font-weight: 600;
  border: 1px solid #D9D9D9;
  border-radius: 18px 18px 0px 0px;
  border-bottom: none;
}

.arrow-icon {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 28px;
}

.uploadFlex {
  display: flex;
  width: 100%;
}

.uploadFlexLeft {
  width: 25%;
  border-right: 1px solid rgba(217, 217, 217, 1);
  padding-right: 32px;
  box-sizing: border-box;
}

.uploadFlexRight {
  width: 75%;
}

.uploadFlexLefts {
  border-bottom: none;
  margin-bottom: 0;
}

.tables {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -550px;
  margin-top: -300px;
  width: 1158px;
  height: 700px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 2000;
  padding-bottom: 30px;
  overflow-y: auto;
}

.content {
  width: 1136px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  text-align: center;
  margin: 24px 0;
}

.closes {
  position: absolute;
  right: 25px;
  top: 17px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.containertables {
  // display: fixed;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // background: rgba(0, 0, 0, 0.04);
}

.process1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.process2 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.processLImg {
  position: relative;
  bottom: 1px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.processContainer {
  display: flex;
  align-item: center;
  position: relative;
  top: 6px;
}

.process-status {
  width: 120px;
  height: 2px;
  background: rgba(217, 217, 217, 1);
  position: relative;
  top: 8px;
  margin: 0 24px;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.progressUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2022;
}

.progressBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 638px;
  height: 406px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.processText {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2F54EB;
  line-height: 28px;
  margin-top: 25px;
}

.processTexted {
  color: #52C41A;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 28px;
  margin-top: 25px;
}

>>> .el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text {
  top: 80%;
}

.undraw_upload {
  position: absolute;
  width: 180px;
  height: 132px;
  top: 90px;
}

.processTextedNo {
  visibility: hidden;
}

.data-loading-wrapper {
  height: 722px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .text {
    margin-top: 47px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 25px;
  }
}

.changeUserInfo1 {
  color: #999;
  cursor: pointer;
}

.el-alert-warning {
  padding: 24px 78px;
  background: #fff;
}

.el-alert-warningText {
  height: 52px;
  line-height: 52px;
  background: #FBFBFB;
  padding: 0 16px;
  color: rgba(0,0,0,0.6000);
}

.new-next-btn {
  width: 180px;
  height: 46px;
  line-height: 44px;
  background: #2F54EB;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  border: none;
}

.new-next-btn:hover {
  background: #0f3bec;
  color: #fff;
}

.new-next-btn360 {
  width: 360px;
}

.ease {
  transition: all 0.3s;
}

.congtent-title-tab {
  margin: 0 30px;
  height: 54px;
  line-height: 54px;
  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px 0px #D9D9D9;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0,0,0,0.8000);
  padding-left: 24px;
  margin-top: 16px;
}

.baseVersion {
  height: 36px;
  line-height: 36px;
  padding: 0 6px;
  background: #2F54EB;
  margin: 4px 6px 0 0;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
</style>
