<template>
  <div>
    <bread-crumb>
      <el-breadcrumb-item :to="{ name: 'goods' }">产品中心</el-breadcrumb-item>
      <el-breadcrumb-item>服务订购</el-breadcrumb-item>
    </bread-crumb>
    <div class="submit-title">服务订购</div>
    <div class="product-btnC minWidth">
      <div>
        <!-- <div class="product-btn" :class="{btnCover:risk==0}" @click="risk=0">版本选择</div>
        <div class="product-btn" :class="{btnCover:risk==1}" @click="risk=1">风险库选择</div>-->
      </div>
      <div class="product-reduce ease" @click="allCheap">领取优惠券</div>
    </div>
    <div v-if="versionList.length">
      <div class="product-c minWidth" v-show="risk==1">
        <div
          class="product-item"
          v-for="(item, index) in goods"
          :key="item.id"
          @click="addSubmit(item.id,index)"
          :class="{productitemactive:item.active}"
        >
          <div class="product-i">
            <img class="product-icons" :src="item.faceImg" />
            <div>
              <div>{{item.name}}</div>
              <div class="item-bottom">{{item.price}}元/{{item.useNumber>10000?"无限":item.useNumber}}次</div>
            </div>
          </div>
          <div class="product-short" :title="item.remark">{{item.remark}}</div>
          <img v-if="item.active" class="product-have" src="./img/hook.png" alt />
        </div>
      </div>
      <div class="product-version-c" v-show="risk==0">
        <div class="product-version" v-for="(item, index) in versionList" :key="index">
          <div
            class="product-version-t"
            :class="{tryfession:index==0,professional:index==2,theprofessional:index==3}"
          >
            <div class="product-version-title">{{item.name}}</div>
            <div class="product-version-title2">{{item.remark}}</div>
          </div>
          <div class="version-flex">
            <div class="version-price">
              ￥
              <span class="version-pricespan">{{item.price}}</span>
              /年（{{item.useNumber>10000? "无限": item.useNumber}}次）
            </div>
            <div class="version-price2">
              原价
              <span class="version-cancel">￥{{item.priceOld}}</span>
            </div>
          </div>
          <div
            class="version-flex"
            v-for="(citem, index) in versionList[index].introduce.split(',')"
            :key="index"
          >
            <div class="version-flexdiv">- {{citem}}</div>
          </div>
          <div class="product-outtime">{{item.surplus}}</div>
          <div
            class="product-btn-bottom ease"
            :class="{addBan:act==index}"
            @click="opening(index)"
          >{{act==index?"已选择":"选择"}}</div>
        </div>
      </div>
      <div v-show="risk==1 " class="product-haveBuy">已开通风险库（{{tableData.length}}）</div>
      <div v-show="risk==1" class="product-c product-bottom minWidth">
        <div class="product-item product-itemHave" v-for="item in tableData" :key="item.id">
          <div class="product-i">
            <img class="product-icons" :src="item.face_img" />
            <div>
              <div>{{item.name}}</div>
            </div>
          </div>
          <div class="product-short">{{item.remake}}</div>
          <div class="product-remaining">剩余{{item.outNumber>1000?'无限':item.outNumber}}次</div>
          <div class="product-timeout">到期时间：{{formatDateTes(item.expireTime)}}</div>
        </div>
      </div>
    </div>
    <div class="version-show minWidth" v-if="versionList.length&&risk==0">
      <div class="version-show-title">
        <span
          v-for="(item, index) in versionList"
          @click="checkBorder(index)"
          class="version-show-title-span"
          :class="{versionborderact: borderAct==index}"
          :key="index"
        >{{item.name}}</span>
      </div>
      <div class="version-img" v-if="versionList.length">
        <span v-for="(item,index) in versionList" :key="index">
          <img :src="item.describeImage" v-show="borderAct==index" alt />
        </span>
      </div>
    </div>
    <div class="submit-price minWidth">
      <div class="submit-left">
        <!-- <div class="submit-check" @click="isActive = !isActive">
          <div :class="{ 'submit-circle': isActive }"></div>
        </div>-->
        <div class="submit-text"></div>
      </div>
      <div class="submit-right">
        <div></div>
        <div class="curson">
          <span class="product-gray">已选商品：</span>
          <span class="product-blue" @click="shopping = !shopping">{{shoppings.length}}</span>
          <span class="triangle" @click="shopping = !shopping"></span>
          <div class="product-Shopping" v-show="shopping">
            <div class="product-shopping-t">
              <div class="product-s-title">已选商品</div>
              <div class="product-shopping-cancel" @click="shopping = false">×</div>
            </div>
            <div v-if="shoppings.length">
              <div class="product-shopping-item" v-for="(item, index) in shoppings" :key="index">
                <span class="flex-center">
                  <img class="product-item-img" :src="item.faceImg" />
                  <span
                    class="product-name"
                    :class="{title1:item.name == versionName[0],title2:item.name == versionName[1],title3:item.name == versionName[2],title4:item.name == versionName[3]}"
                  >{{item.name}}</span>
                </span>
                <span class="product-priceitem">
                  <span class="addred">{{item.price}}/年</span>
                  （{{item.useNumber>10000? "无限": item.useNumber}}次）
                </span>
                <span class="cursion" @click="deletes(index)">删除</span>
              </div>
            </div>
            <div class="nodis" v-else>
              <img src="./img/nodis.png" alt />
              <span class="nodis-text">快去添加商品吧～</span>
            </div>
          </div>
        </div>
        <div class="product-Selected">
          <span class="product-gray">总计：</span>
          <span class="product-red">{{total?total.toFixed(2):0}}元</span>
        </div>
        <div class="submit-btns" v-if="!this.shoppings.length">下一步</div>
        <div class="submit-btn ease" @click="toNext" v-else>下一步</div>
      </div>
    </div>
    <div class="order-mask" v-if="mask">
      <div class="order-cheap">
        <div class="order-header">
          <div>领取优惠券</div>
          <div class="submit-close" @click="mask = false">×</div>
        </div>
        <div class="order-gets" v-if="lists.length>0">
          <div class="order-get" v-for="item in lists" :key="item.id">
            <div class="order-left">
              <div class="order-flex">
                <div class="order-prices" v-if="item.discount==1">￥{{item.price}}</div>
                <div class="order-prices" v-else>{{Number(item.discount)*10}}折</div>
                <div class="order-satisfy">满{{item.satisfy}}元</div>
                <div v-if="item.applyGoodType==1">全部商品</div>
                <div v-else>指定商品</div>
              </div>
              <div>有效时间：{{formatDateTes(item.discountStartTime)}} 至 {{formatDateTes(item.discountEndTime)}}</div>
              <div>优惠内容：{{item.txplain}}</div>
            </div>
            <div class="order-right" @click="getcheap(item.id)" v-if="!item.receive">
              <div>立即领取</div>
            </div>
            <div class="order-right order-rights" v-if="item.receive">
              <div>已领取</div>
            </div>
          </div>
        </div>
        <div class="nodisImg" v-else>
          <img src="./img/nodis.png" alt />
          <div>无可领取优惠券~</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import {
  alreadyBuyItem,
  allGoods,
  getVersion,
  allFull,
  buyItem,
  getFull
} from "../../api/api";

export default {
  components: {
    BreadCrumb
  },
  data() {
    return {
      shopping: false,
      isActive: true,
      act: 4,
      nameList: [],
      risk: 0,
      tableData: [],
      goods: [],
      shoppings: [],
      priceList: [],
      total: 0,
      mask: false,
      versionList: [],
      lists: [],
      versionNum: [5, 4, 3, 2],
      version: "",
      edition: null,
      listNum: 0,
      versionName: [],
      borderAct: 0,
      rDiscount: null
    };
  },
  created() {
    this.alreadybuy();
    this.allversion();
    this.buyitem();
  },
  methods: {
    checkBorder(index) {
      this.borderAct = index;
    },
    // 已购买最高版本
    buyitem() {
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token
      };
      buyItem(params).then(res => {
        let items = [
          res.data.list[2],
          res.data.list[3],
          res.data.list[4],
          res.data.list[5]
        ];
        let UpItems = items.reverse().findIndex(item => {
          return item == true;
        });
        this.version = this.versionNum[UpItems];
      });
    },

    // 所有优惠券
    allCheap() {
      this.mask = true;
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token,
        sPageNo: 0,
        sPageSize: 1000
      };
      allFull(params).then(res => {
        this.lists = res.data.content;
      });
    },

    // 所有商品
    allgood() {
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token
      };
      allGoods(params).then(res => {
        this.goods = res.data.content;
        this.goods.forEach((item, index) => {
          item.active = false;
        });
        // if (localStorage.getItem("nameList")) {
        //   this.nameList = JSON.parse(localStorage.getItem("nameList"));
        //   this.shoppings = JSON.parse(localStorage.getItem("shopList"));
        //   this.priceList = JSON.parse(localStorage.getItem("priceList"));
        //   this.total = this.sum(this.priceList);
        //   for (let i = 0; i < this.goods.length; i++) {
        //     for (let j = 0; j < this.nameList.length; j++) {
        //       if (this.goods[i].name.indexOf(this.nameList[j]) > -1) {
        //         this.goods[i].active = true;
        //       }
        //     }
        //   }
        //   this.shopping = true;
        //   for (let i = 0; i < this.versionName.length; i++) {
        //     if (this.nameList.indexOf(this.versionName[i]) > -1) {
        //       this.act = i;
        //     }
        //   }
        // }
        // if (!this.nameList.length) {
        //   this.shopping = false;
        // }
      });
    },

    // 已购买商品
    alreadybuy() {
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token
      };
      alreadyBuyItem(params).then(res => {
        let lists = res.data.filter(item => {
          item.status = this.lists[item.status - 3];
        });
        this.tableData = res.data;
      });
    },

    // 所有版本信息
    allversion() {
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token
      };
      getVersion(params).then(res => {
        this.versionList = res.content.filter(item => item.edition != 5);
        this.versionName = res.content.map(item => {
          return item.name;
        });
        this.allgood();
      });
    },

    // 添加风险库
    addSubmit(val, index) {
      this.listNum++;
      if (this.listNum == 1) {
        this.shopping = true;
      }
      if (this.nameList.indexOf(this.goods[index].name) == -1) {
        this.nameList.push(this.goods[index].name);
        this.shoppings.push(this.goods[index]);
        this.priceList.push(this.goods[index].price);
      } else {
        let idx = this.nameList.indexOf(this.goods[index].name);
        this.nameList.splice(idx, 1);
        this.shoppings.splice(idx, 1);
        this.priceList.splice(idx, 1);
      }
      this.goods[index].active = !this.goods[index].active;
      this.goods.reverse().reverse();
      this.total = this.sum(this.priceList);
      this.shoppings.forEach(item => {
        if (!item.edition) {
          item.edition = "";
        }
        let haveitem = this.versionName.find(items => {
          return items == item.name;
        });
        if (haveitem) {
          item.referenceType = 2;
        } else {
          item.referenceType = 1;
        }
      });
      this.setItemLocal();
    },

    // 删除购物车项目
    deletes(index) {
      for (let i = 0; i < this.goods.length; i++) {
        if (this.goods[i].name.indexOf(this.nameList[index]) > -1) {
          this.goods[i].active = false;
          this.goods.reverse().reverse();
        }
      }
      let found = this.nameList.find(element => {
        return (
          element == this.versionName[0] ||
          element == this.versionName[1] ||
          element == this.versionName[2] ||
          element == this.versionName[3]
        );
      });
      let foundIndex = this.nameList.findIndex(element => {
        return (
          element == this.versionName[0] ||
          element == this.versionName[1] ||
          element == this.versionName[2] ||
          element == this.versionName[3]
        );
      });
      if (this.nameList.indexOf(found) > -1 && index == foundIndex) {
        this.act = 4;
      }
      this.shoppings.splice(index, 1);
      this.nameList.splice(index, 1);
      this.priceList.splice(index, 1);
      this.total = this.sum(this.priceList);
      this.setItemLocal();
    },

    // 选择版本
    opening(index) {
      this.listNum++;
      if (this.listNum == 1) {
        this.shopping = true;
      }
      this.act = index;
      let found = this.nameList.find(element => {
        return (
          element == this.versionName[0] ||
          element == this.versionName[1] ||
          element == this.versionName[2] ||
          element == this.versionName[3]
        );
      });
      if (this.nameList.indexOf(found) > -1) {
        this.shoppings.splice(this.nameList.indexOf(found), 1);
        this.priceList.splice(this.nameList.indexOf(found), 1);
        this.nameList.splice(this.nameList.indexOf(found), 1);
      }
      this.shoppings.push(this.versionList[index]);
      this.nameList.push(this.versionList[index].name);
      this.priceList.push(this.versionList[index].price);
      this.shoppings.forEach(item => {
        if (!item.edition) {
          item.edition = "";
        }
        let haveitem = this.versionName.find(items => {
          return items == item.name;
        });
        if (haveitem) {
          item.referenceType = 2;
        } else {
          item.referenceType = 1;
        }
      });
      this.total = this.sum(this.priceList);
      // this.risk = 1;
      this.setItemLocal();
    },

    toNext() {
      // 未购买风险库提示
      // let havereFer = this.shoppings.findIndex(item => {
      //   return item.referenceType == 1;
      // });
      // if (havereFer < 0 && this.tableData.length == 0) {
      //   this.$confirm("系统检测到您未购买风险库，是否前往购买?", "温馨提示", {
      //     confirmButtonText: "前往选择",
      //     showCancelButton: false,
      //     type: "warning"
      //   })
      //     .then(() => {
      //       this.risk = 1;
      //     })
      //     .catch(() => {});
      //   return;
      // }

      // 未购买版本提示
      let haveEdition = this.shoppings.findIndex(item => {
        return item.edition > 0;
      });
      if (haveEdition > -1) {
        this.edition = this.shoppings[haveEdition].edition;
      } else {
        this.edition = this.version;
      }
      if (!this.edition) {
        this.$confirm("系统检测到您未购买版本，是否前往购买?", "温馨提示", {
          confirmButtonText: "前往选择",
          showCancelButton: false,
          type: "warning"
        })
          .then(() => {
            this.risk = 0;
          })
          .catch(() => {});
        return;
      }

      // 未点击阅读购买协议提示
      if (!this.isActive) {
        this.$message({
          message: "请阅读购买协议并选中！",
          type: "warning"
        });
        return;
      }

      let orderList = [];
      this.shoppings.forEach((item, index) => {
        orderList[index] = new Object();
        orderList[index].goodId = item.id;
        orderList[index].edition = this.edition;
        orderList[index].goodName = item.name;
        orderList[index].useDate = item.useDate;
        orderList[index].useNumber = item.useNumber;
        orderList[index].price = item.price;
        orderList[index].referenceType = item.referenceType;
        orderList[index].faceImg = item.faceImg
          ? item.faceImg
          : item.describeImage;
      });
      localStorage.setItem("info", JSON.stringify(orderList));
      this.$router.push({
        name: "submit"
      });
    },

    // 领取优惠券
    getcheap(val) {
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token,
        id: val
      };
      getFull(params).then(res => {
        if (res.msgCode == -1) {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: "<span style='z-index:9999999'>" + res.msgInfo + "</span>",
            type: "error"
          });
          return;
        } else {
          this.$message({
            type: "success",
            message: "领取成功!"
          });
        }
        this.allCheap();
      });
    },
    sum(arr) {
      return eval(arr.join("+"));
    },
    formatDateTes(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate();
      return Y + M + D;
    },
    // 存入缓存
    setItemLocal() {
      localStorage.setItem("shopList", JSON.stringify(this.shoppings));
      localStorage.setItem("nameList", JSON.stringify(this.nameList));
      localStorage.setItem("priceList", JSON.stringify(this.priceList));
    }
  }
};
</script>
<style lang="stylus" scoped>
.submit-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.8);
  padding: 8px 0 0 30px;
  font-weight: 500;
}

.product-c {
  display: flex;
  flex-wrap: wrap;
  margin-left: 30px;
  margin-top: 24px;
}

.product-item {
  position: relative;
  width: 254px;
  height: 138px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  margin: 0 16px 16px 0;
  cursor: pointer;
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 1);
}

.productitemactive {
  border: 1px solid rgba(47, 84, 235, 1);
  background: rgba(47, 84, 235, 0.04);
}

.product-itemHave {
  height: 174px;
  border: 1px solid rgba(255, 255, 255, 1);
}

.product-icons {
  width: 45px;
  height: 45px;
  margin-right: 16px;
}

.submit-check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border: 1px solid rgba(116, 96, 238, 1);
  border-radius: 50%;
  cursor: pointer;
  margin-left: 30px;
  margin-right: 8px;
}

.product-i {
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
}

.product-short {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 10px;
  line-height: 1.6;
  padding-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.item-bottom {
  font-size: 14px;
  color: rgba(245, 34, 45, 1);
  line-height: 1.3;
}

.product-have {
  position: absolute;
  top: -1px;
  right: -1px;
  color: #fff;
  width: 34px;
  height: 34px;
  overflow: hidden;
  z-index: 95;
  border-top-right-radius: 8px;
}

.product-btnC {
  display: flex;
  justify-content: space-between;
  margin: 0 0 0 30px;
}

.product-btn {
  display: inline-block;
  width: 128px;
  height: 38px;
  line-height: 38px;
  background: #fff;
  border-radius: 19px;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-size: 16px;
  margin-right: 12px;
  cursor: pointer;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}

.btnCover {
  background: rgba(47, 84, 235, 1);
  color: #fff;
}

.product-reduce {
  width: 116px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(245, 20, 45, 1);
  font-size: 14px;
  color: #fff;
  margin-right: 30px;
  border-radius: 4px;
  cursor: pointer;
}

.product-reduce:hover {
  background: rgba(245, 84, 45, 1);
}

.product-haveBuy {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  margin-left: 30px;
  margin-top: 8px;
}

.product-timeout {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.product-bottom {
  padding-bottom: 84px;
}

.product-remaining {
  position: absolute;
  top: 0;
  right: 0;
  width: 73px;
  height: 20px;
  line-height: 20px;
  background: rgba(47, 84, 235, 1);
  border-radius: 0px 8px 0px 8px;
  text-align: center;
  color: #fff;
  font-size: 14px;
}

.submit-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 180px;
  height: 68px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  font-size: 14px;
  z-index: 998;
}

.submit-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.submit-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.submit-check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border: 1px solid rgba(116, 96, 238, 1);
  border-radius: 50%;
  cursor: pointer;
  margin-left: 30px;
  margin-right: 8px;
}

.submit-circle {
  width: 8px;
  height: 8px;
  background: rgba(116, 96, 238, 1);
  border-radius: 50%;
}

.submit-btn {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 120, 235, 1);
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  margin-right: 30px;
}

.submit-btns {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  pointer-events: none;
  cursor: default;
  margin-right: 30px;
}

.ease {
  transition: all 0.3s;
}

.submit-btn:hover {
  background: rgba(47, 50, 255, 1);
}

div {
  user-select: none;
}

.product-Selected {
  margin-left: 48px;
}

.product-gray {
  color: rgba(0, 0, 0, 0.6);
}

.product-blue {
  display: inline-block;
  width: 12px;
  padding-right: 8px;
  color: rgba(47, 84, 235, 1);
  cursor: pointer;
  padding: 10px 8px 10px 0;
}

.curson {
  position: relative;
}

.triangle {
  cursor: pointer;
  display: inline-block;
  height: 12px;
  width: 0;
  height: 0;
  border-width: 0 4px 8px;
  border-style: solid;
  border-color: transparent transparent rgba(47, 84, 235, 1);
  position: relative;
  bottom: 1px;
}

.product-Shopping {
  position: absolute;
  left: -138px;
  bottom: 60px;
  width: 528px;
  padding-bottom: 10px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  overflow: hidden;
  z-index: 998;
}

.product-s-title {
  height: 57px;
  line-height: 57px;
  background: rgba(255, 255, 255, 1);
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 24px;
}

.product-shopping-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 50px;
  margin: 0 24px 0 16px;
}

.product-priceitem {
  margin-right: 50px;
  color: rgba(0, 0, 0, 0.6);
}

.product-red {
  color: red;
  width: 100px;
  display: inline-block;
}

.product-version-c {
  // min-width: 1200px;
  // max-width: 1360px;
  display: flex;
  margin-left: 30px;
  // justify-content: space-between;
  // margin: 0 auto;
  padding-left: 30px;
}

.product-version {
  position: relative;
  // display: inline-block;
  width: 288px;
  height: 590px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-right: 30px;
  // margin-top: 16px;
}

.product-version-t {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100px;
  background: rgba(24, 144, 255, 1);
  border-radius: 8px 8px 0px 0px;
}

.product-version-title {
  font-size: 24px;
  color: #ffffff;
  margin-bottom: 7px;
}

.product-version-title2 {
  color: #fff;
  font-size: 14px;
}

.product-btn-bottom {
  position: absolute;
  width: 85%;
  height: 36px;
  line-height: 32px;
  border-radius: 4px;
  border: 1px solid rgba(47, 84, 235, 1);
  text-align: center;
  color: rgba(47, 84, 235, 1);
  left: 7%;
  bottom: 20px;
  cursor: pointer;
  box-sizing: border-box;
}

.product-btn-bottom:hover {
  background: rgba(89, 126, 247, 1);
  color: #fff;
  border: 1px solid rgba(89, 126, 247, 1);
}

.version-price {
  margin-top: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.version-pricespan {
  font-size: 32px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.version-price2 {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin: 2px 0 24px 22px;
}

.version-flex {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-left: 40px;
}

.version-flexdiv {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.version-cancel {
  text-decoration: line-through;
}

.professional {
  background: rgba(47, 84, 235, 1);
}

.theprofessional {
  background: rgba(104, 0, 255, 1);
}

.nodis {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0;
}

.nodis-text {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  margin-top: 10px;
}

.nodis img {
  width: 240px;
  height: 138px;
}

.cursion {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.product-name {
  width: 145px;
}

.product-shopping-t {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #D9D9D9FF;
  padding-right: 20px;
}

.product-shopping-cancel {
  font-size: 30px;
  cursor: pointer;
  padding: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.addBan {
  border: 1px solid rgba(217, 217, 217, 1);
  color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
}

.addBan:hover {
  background: rgba(217, 217, 217, 1);
  border: 1px solid rgba(217, 217, 217, 1);
  cursor: default;
}

.order-gets {
  display: flex;
  flex-wrap: wrap;
  margin-left: 24px;
  font-size: 0;
  padding-top: 16px;
  overflow-x: hidden;
  max-height: 410px;
}

.order-get:nth-child(2n-1) {
  margin-right: 16px;
}

.order-left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  padding-left: 12px;
  line-height: 1.8;
}

.order-flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 260px;
}

.order-prices {
  color: #EE4B2B;
  font-size: 25px;
  line-height: 1.1;
  margin-bottom: 10px;
  margin-right: 8px;
  font-weight: 600;
}

.order-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 46px;
  height: 100%;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  cursor: pointer;
  // background: url('../order-discount/img/border.png');
  background: #F26161;
  font-size: 14px;
  border-radius: 4px;
}

.order-rights {
  background: rgba(242, 97, 97, 0.08);
}

.order-cheap {
  position: absolute;
  top: 160px;
  left: 50%;
  margin-left: -350px;
  width: 748px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}

.order-get {
  display: flex;
  justify-content: space-between;
  width: 336px;
  height: 123px;
  background: rgba(242, 97, 97, 0.08);
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 12px;
}

.order-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 748px;
  height: 56px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(217, 217, 217, 1);
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  padding: 0 24px;
  box-sizing: border-box;
}

.order-right div {
  word-wrap: break-word;
  letter-spacing: 20px;
  text-align: center;
  width: 14px;
}

.submit-close {
  cursor: pointer;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px;
}

.order-mask {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.48);
  z-index: 1002;
}

.product-item-img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.addred {
  color: red;
}

.version-show {
  margin: 16px 30px 0 30px;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-bottom: 120px;
}

.version-show-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
}

.version-show-title-span {
  display: inline-block;
  width: 120px;
  padding: 22px;
  text-align: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 2px solid #fff;
}

.version-show-title span:hover {
  color: rgba(47, 84, 235, 1);
}

.versionborderact {
  border-bottom: 2px solid rgba(47, 84, 235, 1);
  color: rgba(47, 84, 235, 1);
}

.version-img {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.version-img img {
  width: 1000px;
  overflow: hidden;
}

.tryfession {
  background: rgba(133, 165, 255, 1);
}

.minWidth {
  // min-width: 1150px;
}

.title1 {
  color: #85a5ff;
}

.title2 {
  color: rgba(24, 144, 255, 1);
}

.title3 {
  color: rgba(47, 84, 235, 1);
}

.title4 {
  color: rgba(128, 0, 255, 1);
}

.nodisImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
}

.nodisImg img {
  margin-bottom: 20px;
}

.order-satisfy {
  text-align: left;
}

.product-outtime {
  position: absolute;
  bottom: 65px;
  text-align: center;
  color: rgba(250, 173, 20, 1);
  font-size: 14px;
  width: 100%;
}
</style>