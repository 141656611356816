import axios, {
  get,
  post
} from "@/axios";

const base = process.env.VUE_APP_API_ROOT;
const baseTaxfirm = base + '/taxfirm';

// 房地产项目列表
export const bSuperiorProjectList = params => {
  return post(`${baseTaxfirm}/bSuperiorProject/getPage`, params)
    .then(res => res.data);
};

// 房地产项目列表详情
export const bSuperiorProjectDetail = id => {
  return get(`${baseTaxfirm}/bSuperiorProject/getInfo?id=${id}`)
    .then(res => res.data);
};

// 创建项目
export const bSuperiorProjectAdd = params => {
  return post(`${baseTaxfirm}/bSuperiorProject/add`, params)
    .then(res => res.data);
};

// 创建客户并创建项目
export const bSuperiorProjectAddBoth = params => {
  return post(`${baseTaxfirm}/bSuperiorProject/addSuperProjectAndConsumer`, params)
    .then(res => res.data);
};

// 修改项目信息
export const bSuperiorProjectEdit = params => {
  return post(`${baseTaxfirm}/bSuperiorProject/edit`, params)
    .then(res => res.data);
};

// 房企账号列表
export const realEstateUserList = params => {
  return post(`${baseTaxfirm}/realEstateUser/getPage`, params)
    .then(res => res.data);
};

// 创建房企账号
export const realEstateUserAdd = params => {
  return post(`${baseTaxfirm}/realEstateUser/add`, params)
    .then(res => res.data);
};

// 获取未创建过账号的项目列表
export const getAddAccountList = () => {
  return get(`${baseTaxfirm}/bSuperiorProject/getAddAccountList`)
    .then(res => res.data);
};

// 禁用启用
export const disableOrEnableUser = params => {
  return get(`${baseTaxfirm}/realEstateUser/disableOrEnableUser?id=${params.id}&status=${params.status}`)
    .then(res => res.data);
};

// 获取账户状态和详情
export const realEstateUserInfo = id => {
  return get(`${baseTaxfirm}/realEstateUser/getInfo?id=${id}`)
    .then(res => res.data);
};

// 修改详情信息
export const realEstateUserEdit = params => {
  return post(`${baseTaxfirm}/realEstateUser/edit`, params)
    .then(res => res.data);
};

// 房地产项目检测记录
export const bSuperiorProjectRuleList = params => {
  return post(`${baseTaxfirm}/bSuperiorProject/getRuleList`, params)
    .then(res => res.data);
};

// 获取可以检测的项目列表
export const bSuperiorProjectListTest = () => {
  return get(`${baseTaxfirm}/bSuperiorProject/getList`)
    .then(res => res.data);
};

// 更换ID
export const addSubsetProjectId = (id) => {
  return get(`${baseTaxfirm}/bSuperiorProject/addSubsetProjectAndGetSubsetProjectInfo?id=${id}`)
    .then(res => res.data);
};

// 获取基础信息表
export const getFdcInfo = (projectInfoId) => {
  return get(`${baseTaxfirm}/projectInfo/getInfo?id=${projectInfoId}`)
    .then(res => res.data);
};

export const addSubsetProjectFdc = params => {
  return post(`${baseTaxfirm}/bSuperiorProject/addSuperProjectAndConsumer`, params)
    .then(res => res.data);
};