<template>
  <div class="forgetPassword clearfix">
    <div class="right">
      <div class="form">
        <div class="li_input liwjmm perfect">完善信息</div>
        <div class="li_input">
          <i class="baseloginicon iconshouji iconfont"></i>

          <el-input v-model="mobile" placeholder="手机号" @blur="blurMobile"></el-input>
          <span class="blurSpan" v-if="showMiboleInfo">{{mobileInfo}}</span>
        </div>
        <!-- <div class="li_input" v-if="VueHuakuai == false">
          <VerifySlider
            :class="[sildesucc ? 'active' : '', 'drag']"
            @success="successHandler"
            tips="按住滑块，拖到最右边"
          ></VerifySlider>
        </div>-->
        <div class="li_input">
          <i class="baseloginicon iconmima iconfont"></i>
          <el-input
            v-model="code"
            @blur="checkBlur"
            placeholder="验证码"
            suffix-icon="el-icon-success"
            class="phoneyzm"
          ></el-input>
          <el-button
            :class="[canClick ? '' : 'active', 'btnsendcode']"
            @click="getCode()"
          >{{content}}</el-button>
          <span class="blurSpan" v-if="showCodeInfo">{{codeInfo}}</span>
        </div>
        <div class="li_input li_input1">
          <el-button type="primary" @click="jump">登录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VerifySlider } from "vue-verify-slider";
import {
  forgetpw,
  getRestpwdCode,
  forgetpwAi,
  phoneAndCode,
  phoneBind,
  getCodeAi
} from "@/api/api";

export default {
  components: { VerifySlider },
  data() {
    return {
      VueHuakuai: false,
      sildesucc: false,
      canClick: true,
      content: "获取验证码",
      totalTime: 60,
      newmsg: "",
      mobile: "",
      code: "",
      passwd: "",
      passwdN: "",
      timeout: 3,
      showMiboleInfo: false,
      showCodeInfo: false,
      showPasswordInfo: false,
      showPasswordInfoN: false,
      mobileInfo: "手机号输入错误，请检查",
      codeInfo: "验证码错误，请检查后重新输入",
      passwordInfo: "密码错误，请核对后重新输入",
      passwordInfoN: "密码两次输入不一致，请确认"
    };
  },
  methods: {
    blurMobile() {
      let myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.mobile)) {
        this.mobileInfo = "账号输入错误，请检查";
        this.showMiboleInfo = true;
      } else {
        this.showMiboleInfo = false;
      }
    },

    blurPassword() {
      let myreg = /^[0-9a-zA-Z_#]{6,16}$/;
      if (!myreg.test(this.passwd)) {
        this.passwordInfo = "请输入6~16位密码";
        this.showPasswordInfo = true;
      } else {
        this.showPasswordInfo = false;
      }
    },

    blurPasswordN() {
      let myreg = /^[0-9a-zA-Z_#]{6,16}$/;
      if (!myreg.test(this.passwdN)) {
        this.passwordInfoN = "请再次输入6~16位密码";
        this.showPasswordInfoN = true;
      } else {
        this.showPasswordInfoN = false;
      }
    },

    checkBlur() {
      if (!this.code) {
        this.codeInfo = "请输入短信验证码";
        this.showCodeInfo = true;
      } else {
        this.showCodeInfo = false;
      }
    },

    jump1() {
      this.$router.push({ name: "login" });
    },
    //滑动成功回调
    successHandler() {
      this.VueHuakuai = true;
      this.sildesucc = true;
    },
    //获取手机验证码
    isgetCode() {
      if (!this.VueHuakuai) {
        this.newmsg = "请先滑动滑块至最右侧，再获取验证码";
        return false;
      }
      return true;
    },
    //验证是否将表单填写完毕
    submit() {
      let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
      if (!this.mobile) {
        this.mobileInfo = "账号不能为空";
        this.showMiboleInfo = true;
        return false;
      }

      if (!this.checkTel(this.mobile)) {
        this.mobileInfo = "账号输入错误，请检查";
        this.showMiboleInfo = true;
        return false;
      }
      if (!this.code) {
        this.codeInfo = "请输入短信验证码";
        this.showCodeInfo = true;
        return false;
      }
      return true;
    },
    jump() {
      if (this.submit()) {
        let params = {
          code: this.code,
          mobile: this.mobile
        };
        phoneBind(params, localStorage.getItem("authorization")).then(data => {
          if (data.msgCode == 1) {
            localStorage.setItem("bindCode", 1030);
            const redirectURL = "/";
            this.$router.push({ path: redirectURL });
          } else {
            this.showCodeInfo = true;
            this.codeInfo = data.msgInfo;
          }
        });
      }
    },
    checkTel(tel) {
      var mobile = /^1[3|4|5|6|7|8|9]\d{9}$/,
        phone = /^0\d{2,3}-?\d{7,8}$/;
      return mobile.test(tel) || phone.test(tel);
    },
    getCode: function() {
      //如果验证码不能按直接跳过
      if (!this.canClick) return;

      //验证码发送的请求

      if (!this.checkTel(this.mobile)) {
        this.mobileInfo = "请输入账号";
        this.showMiboleInfo = true;
        return;
      }
      // if (!this.isgetCode()) return;

      let param = { mobile: this.mobile, type: "BINDINGMOBILE" };
      getCodeAi(param).then(data => {
        if (data.msgCode !== "1") {
          this.newmsg = data.msgInfo;
          this.$message.error(data.msgInfo);
          return;
        }
      });
      //获取验证码倒计时
      this.canClick = false;
      this.content = this.totalTime + "s后重发";
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重发验证码 ";
          this.totalTime = 60;
          this.canClick = true;
        }
      }, 1000);
    }
  }
};
</script>
<style lang="stylus" scoped>
.liwjmm {
  height: 33px !important;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 33px;
  text-align: center !important;
}

.phoneyzm {
  width: 264px !important;
}

.li_input1 {
  text-align: center !important;
}

>>>.el-input__prefix, >>>.el-input__suffix {
  color: #52C41A;
}

.forgetPassword {
  display: flex;
  justify-content: space-evenly;
  width: 400px;
  position: relative;
  box-sizing: border-box;
  height: 580px;
  // background: rgba(255, 255, 255, 1);
  // box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
  padding-top: 25px;

  .forget_left {
    width: 560px;

    img {
      width: 464px;
      margin-left: 48px;
      margin-top: 32px;
    }
  }

  .right {
    margin: 0 auto;
    width: 400px;

    .forget {
      font-size: 30px;
      color: #2F54EB;
      width: 75%;
      margin: 56px auto 50px auto;
    }

    .form {
      width: 96%;
      margin: 0 auto;

      .li_input {
        position: relative;
        height: 40px;
        margin-bottom: 23px;
        text-align: left;

        .baseloginicon {
          position: absolute;
          left: 16px;
          top: 11px;
          width: 16px;
          height: 16px;
          z-index: 2;
          color: #999;
        }

        >>> .el-input {
          .el-input__inner {
            height: 40px;
            background: rgba(255, 255, 255, 1);
            border-radius: 4px;
            border: 1px solid rgba(217, 217, 217, 1);
            text-indent: 10px;
            padding-left: 30px;
          }
        }

        >>> .drag {
          background: #fafafa;
          width: 100% !important;
          margin: 20px 0 10px 0;
          border-radius: 4px !important;

          &.active {
            .drag_text {
              color: #fff !important;
            }
          }

          .drag_bg {
            background: #2F54EB;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          .drag_text {
            font-size: 12px;
            color: #808080;
            border-color: #e6e6e6;
            border: none !important;
          }

          .handler_bg {
            background-image: url('../../../assets/login/loginhk.png');
          }

          .handler_ok_bg {
            background-image: url('../../../assets/login/loginright.png');
          }

          .handler {
            width: 60px;
            height: 40px;
          }

          .handler_ok_bg ~ .drag_text {
            color: #fff !important;
          }
        }

        .btnsendcode {
          position: absolute;
          right: 0px;
          background: #fff;
          border-radius: 4px;
          border: 1px solid #2F54EB;
          color: #2F54EB;
          padding: 12px 20px;

          &:hover, &:focus, &:active {
            background: #fff;
            border-color: #fff;
          }

          &.active {
            // background: #D1D1D1;
            // color: #E6E6E6;
            // border-color: #D1D1D1;
          }
        }
      }

      .li_input1 {
        top: 16px;

        .el-button {
          height: 38px;
          width: 100%;
          border-radius: 4px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          padding: 0;
          background: #2F54EB;
          border-color: #2F54EB;
        }
      }
    }
  }
}

.loginmeg {
  margin-top: 8px;
  text-align: center;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(245, 34, 45, 1);
  line-height: 17px;
}

.loginitemzc {
  margin-top: 23px;
  text-align: center;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;

  span {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(0, 0, 0, 0.6);
  }

  img {
    width: 24px;
    margin-left: 8px;
  }

  a {
    color: #2F54EB;
  }
}

.blurSpan {
  position: absolute;
  left: 0;
  top: 45px;
  font-size: 12px;
  color: rgba(245, 34, 45, 1);
}

.loginmain .forgetPassword .right .perfect {
  margin-bottom: 48px;
}
</style>