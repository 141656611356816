<template>
  <div>
    <el-tabs v-model="activeName" tab-position="left" class="tab-wrapper">
      <el-tab-pane
        :label="table.sysFileName"
        :name="key.toString()"
        v-for="(table, key) in tables4correction"
        :key="key"
      >
        <div class="tab-content-wrapper">
          <Correction
            v-if="activeName === key.toString()"
            :key="table.templateId"
            :table4correction="table"
            @save="handleCorrectionSave"
          ></Correction>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Correction from "./Correction";

export default {
  components: {
    Correction,
  },
  mounted() {
    this.activeName = "0";
  },
  data() {
    return {
      activeName: "0",
    };
  },
  computed: {
    tables4correction() {
      return this.$store.state.UploadTable.tables4correctionData;
    },
  },
  methods: {
    handleCorrectionSave() {
      this.activeName = "0";
    },
  },
};
</script>

<style lang="stylus" scoped>
.tab-wrapper {
  background: #eaedf7;
}

>>> {
  .el-tabs__item {
    height: 60px;
    font-size: 14px;
    line-height: 60px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .el-tabs__header.is-left {
    margin-right: 0;
    background: #eaedf7;
    font-size: 14px;
  }

  .el-tabs__item.is-left {
    text-align: left;
    padding: 0 20px;
    color: #7f9bc9;
  }

  .el-tabs__item.is-active {
    background: #fff;
    color: #007aff;
  }

  .el-tabs__active-bar {
    width: 0 !important;
  }

  .el-tabs__content {
  }

  .el-tabs__nav-wrap::after {
    content: none;
  }
}

.tab-content-wrapper {
  background: #fff;
  padding: 0 30px 0px 30px;
}
</style>