<template>
  <div class="container" id="projectBoxs" v-loading="addLoading">
    <div class="addTitle">{{ type == 1 ? "添加分类" : "修改分类" }}</div>
    <div class="project-close" @click="closeRole">×</div>
    <div class="project-name">
      <span class="project-named">*</span>名称:
      <span class="project-input-b" v-show="noName">名称不能为空</span>
    </div>
    <div class="project-input">
      <el-input
        placeholder="请输入"
        v-model.trim="menu.name"
        autofocus
      ></el-input>
    </div>
    <div class="project-btn ease btn-btn">
      <div v-if="type == 1" @click="addProjectForm">创建分类</div>
      <div v-else @click="saveClass">保存</div>
    </div>
  </div>
</template>

<script>
import {
  addMenuList,
  getMenu,
  addPfClass,
  findOnePfClass,
  updatePfClass,
} from "@/api/editor";
import { typeList, typeData, metaType, menuData } from "@/utils/constant";

export default {
  props: {
    addMenuType: [String, Number],
    type: [Number],
    classId: [Number],
  },
  data() {
    return {
      options: metaType,
      addLoading: false,
      menu: {
        name: "",
      },
      noName: false,
      noType: false,
      noGrade: false,
      cascaderKey: 1,
      realName: JSON.parse(localStorage.getItem("user")).realName,
      menuList: null,
      typeListDisabled: ["PF.YSJ", "PF.ZB", "PF.MX"],
      id: "",
    };
  },
  computed: {
    checkType() {
      if (this.addMenuType || this.addMenuType == 0) {
        this.menu.type = this.typeListDisabled[this.addMenuType];
        this.changeType();
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (this.type == 2) {
      this.findOne();
    }
  },
  methods: {
    findOne() {
      let params = {
        id: this.classId,
      };
      findOnePfClass(params).then((res) => {
        if (res.msgCode == 1) {
          this.menu.name = res.data.className;
        } else {
          this.$message({
            message: res.msgInfo,
            type: "error",
          });
        }
      });
    },
    saveClass() {
      let params = {
        className: this.menu.name,
        id: this.classId,
        userId: JSON.parse(localStorage.getItem("user")).id,
        userName: JSON.parse(localStorage.getItem("user")).realName,
      };
      updatePfClass(params).then((res) => {
        if (res.msgCode == 1) {
          this.$message({
            message: "修改成功！",
            type: "success",
          });
          this.$emit("addSuccess");
        } else {
          this.$message({
            message: res.msgInfo,
            type: "error",
          });
        }
      });
    },
    checkData() {
      this.noName = false;
      if (!this.menu.name) {
        this.noName = true;
        return false;
      }
      return true;
    },
    closeRole() {
      this.$emit("closeAddBox");
    },
    addProjectForm() {
      if (!this.checkData()) return;
      this.addLoading = true;
      let params = {
        className: this.menu.name,
        userId: JSON.parse(localStorage.getItem("user")).id,
        userName: JSON.parse(localStorage.getItem("user")).realName,
      };
      addPfClass(params).then((res) => {
        this.addLoading = false;
        if (res.code == 200) {
          this.$message({
            message: "新增成功！",
            type: "success",
          });
          this.$emit("addSuccess");
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.container {
  position: absolute;
  left: 50%;
  margin-left: -240px;
  top: 15vh;
  width: 480px;
  padding-bottom: 80px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
}

>>> #projectBoxs .el-input {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs >>>.el-input >>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #projectBoxs .el-input__icon {
  line-height: 36px;
}

>>> #projectBoxs .el-cascader {
  width: 400px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.addTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  padding: 24px 0 20px 0;
}

.project-close {
  position: absolute;
  cursor: pointer;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  top: 6px;
  right: 20px;
}

.project-name {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 0 0 4px 32px;
}

.project-named {
  position: relative;
  top: 2px;
  margin-right: 4px;
  color: #f5222d;
}

.project-input, .addForms {
  position: relative;
}

.project-input-b {
  font-size: 12px;
  color: #F5222D;
  float: right;
  margin-right: 34px;
}

.project-input .el-input {
  width: 400px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input .el-select {
  width: 400px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  line-height: 68px;
  background: rgba(47, 84, 235, 1);
  box-shadow: 0px -1px 0px 0px rgba(47, 84, 235, 1);
  z-index: 1004;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.btn-btn:hover {
  background: rgba(47, 34, 235, 1);
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-input__icon {
  line-height: 36px;
}

>>>.el-cascader {
  width: 400px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}
</style>