<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>房地产测算</el-breadcrumb-item>
          <el-breadcrumb-item>房地产项目</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">房地产项目</template>
      </main-header>
    </div>
    <div class="filter-body">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="form-item" id="form-items">
            <div class="label">房地产项目名称:</div>
            <el-input
              v-model.trim="projectName"
              placeholder="请输入"
              @keyup.enter.native="search"
              @input="changeManager"
              clearable
            ></el-input>
          </div>
          <div class="form-item" id="form-items">
            <div class="label">企业名称:</div>
            <el-input
              v-model.trim="consumerName"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeConsumer"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="btn-look-up ease" @click="search">查询</div>
    </div>
    <div class="addItem ease" @click="addProjectMethod">创建项目</div>
    <div class="content-body">
      <template>
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column width="10px"></el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            sortable
            :show-overflow-tooltip="true"
            min-width="12%"
          ></el-table-column>
          <el-table-column
            prop="projectName"
            label="房地产项目名称"
            :show-overflow-tooltip="true"
            min-width="18%"
          ></el-table-column>
          <el-table-column
            prop="projectArea"
            label="项目地址"
            :show-overflow-tooltip="true"
            min-width="18%"
          ></el-table-column>
          <el-table-column
            prop="consumerName"
            label="企业名称"
            :show-overflow-tooltip="true"
            min-width="20%"
          ></el-table-column>
          <el-table-column prop="viewReport" label="操作" width="120">
            <template slot-scope="scope">
              <div class="deleteProjects">
                <span @click="viewDetail(scope.row.id)" class="cursion" ref="clickBox">
                  编辑
                </span>
                <span
                  @click="viewDetailIndex(scope.row.id)"
                  class="cursion"
                  ref="clickBox"
                >
                  查看
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <!-- 新建项目弹窗 -->
    <transition name="fades">
      <div
        class="containerMask"
        v-if="addProjectdialogTab"
        @click="addProjectdialogTab = false"
      ></div>
    </transition>
    <transition name="fadee">
      <project-add
        v-if="addProjectdialogTab"
        :tableDataLists="tableDataLists"
        :tableDataId="tableDataId"
        @addSuccess="addSuccess"
        @closeBox="addProjectdialogTab = false"
        :dataList="dataList"
        :city="city"
        :hyList="hyList"
        :isShowDetailId="isShowDetailId"
      ></project-add>
    </transition>
    <el-pagination
      class="pagination-low"
      @current-change="handleCurrentChange"
      :current-page="pageno"
      layout="total, prev, pager, next, jumper"
      :total="totalNumber"
    ></el-pagination>
  </div>
</template>

<script>
import { getCustomListNew, getTradeList, getRegion } from "@/api/api";
import { bSuperiorProjectList, bSuperiorProjectDetail } from "@/api/fdc";
import { mapState } from "vuex";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import { area } from "@/pages/product/utils/limit.js";
import detailProject from "./components/detailProject.vue";
import projectAdd from "./components/projectAdd.vue";

import { formatDateTes } from "./help.js";
import { cloneDeep } from "lodash";

export default {
  components: {
    MainHeader,
    BreadCrumb,
    detailProject,
    projectAdd,
  },

  mounted() {
    this.getprojectList();
    this.getRegionData();
    this.getcustomList();
    this.gettradeList();
  },
  data() {
    return {
      consumerName: "",
      projectName: "",
      conversionAreas: "",
      dataList: null,
      hyList: null,
      totalNumber: 10, //总数
      showDetail: false,
      tableDataList: null,
      addProjectdialogTab: false,
      checkCustom: false,
      projectDetail: null,
      tableDataLists: null,
      tableDataId: null,
      personInfo: null,
      projectList: {},
      conversionArea: "",
      projectDetailTime: "",
      pageno: 1,
      areaTree: [],
      isShowDetailId: -1,
    };
  },

  methods: {
    viewDetailIndex(id) {
      this.$router.push({ name: "fdcIndex", query: { id: id } });
    },

    addProjectMethod() {
      this.isShowDetailId = -1;
      this.addProjectdialogTab = true;
    },

    addSuccess() {
      this.getprojectList();
      this.addProjectdialogTab = false;
    },

    // 翻页
    handleCurrentChange(val) {
      this.pageno = val;
      this.getprojectList();
    },

    // 搜索
    search() {
      this.pageno = 1;
      this.getprojectList();
    },

    // 获取客户列表
    getcustomList() {
      let params = {
        companyName: "",
        industry: "",
        contact: "",
        contactNumber: "",
        startTime: "",
        endTime: "",
        pageSize: 1000,
        pageNo: 0,
      };
      getCustomListNew(params, localStorage.getItem("authorization")).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            showClose: true,
            message: res.msgInfo,
            type: "error",
          });
        }
        this.tableDataLists = res.data.data.content;
        this.tableDataId = this.tableDataLists.map((item) => {
          return item.id;
        });
      });
    },

    changeManager() {
      if (!this.projectName) {
        this.pageno = 0;
        this.getprojectList();
      }
    },
    changeConsumer() {
      if (!this.consumerName) {
        this.pageno = 0;
        this.getprojectList();
      }
    },
    // 获取项目列表
    getprojectList() {
      let params = {
        consumerName: this.consumerName,
        projectName: this.projectName,
        pageSize: 10,
        pageNum: this.pageno,
      };
      bSuperiorProjectList(params).then((res) => {
        if (res.code == 200) {
        }
        this.tableDataList = res.list;
        this.totalNumber = res.pageTotal;
      });
    },

    customChange(e) {
      let index = this.tableDataId.findIndex(
        (value) => value == this.projectList.consumerId
      );
      this.personInfo = this.tableDataLists[index];
      this.conversionAreas = area(
        [
          this.personInfo.provinceCode,
          this.personInfo.cityCode,
          this.personInfo.districtCode,
        ],
        this.city
      );
      this.checkCustom = true;
    },
    viewDetail(id) {
      this.isShowDetailId = id;
      this.addProjectdialogTab = true;
    },
    getRegionData() {
      getRegion().then((res) => {
        this.areaTree = res.data;
      });
    },
    // 获取行业
    gettradeList() {
      getTradeList().then((res) => {
        if (res.msgCode == 1) {
          let data = JSON.parse(res.data);
          this.hyList = data;
          let dataList = {};
          for (let i = 0; i < this.hyList.length; i++) {
            this.hyList[i].hymc = this.hyList[i].hy_dm + this.hyList[i].hymc;
            dataList[this.hyList[i].hy_dm] = this.hyList[i].hymc;
          }
          this.dataList = dataList;
        } else {
          this.$message({
            showClose: true,
            message: res.msgInfo,
            type: "error",
          });
        }
      });
    },
  },

  computed: {
    city: function () {
      if (!this.areaTree) {
        return [];
      }
      const ret = cloneDeep(this.areaTree);
      function walk(data) {
        data.value = data.code;
        data.label = data.name;
        if (data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child);
          }
        }
        delete data.childNode;
      }
      for (const areaTree of ret) {
        walk(areaTree);
      }
      return [...ret];
    },
  },
};
</script>

<style lang="stylus" scoped>
@import './common.css';

.overflow-x {
  max-height: 375px;
  overflow-x: hidden;
}

.overflow-xx {
  margin-bottom: 90px;
}

>>> #form-items .project-input .el-input {
  height: 36px;
}

>>> #form-items .el-input__inner {
  height: 36px;
}

>>> #projectBoxs .project-input .el-input {
  width: 416px;
  height: 36px;
  margin: 0 0 16px 32px;
}

>>> #projectBoxs .project-input .el-input .el-input__inner {
  height: 36px;
}

>>> #projectBoxs .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.filter-body {
  margin: 20px;
  padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .inputs-wrapper {
    width: 92%;

    .inputs-wraper-row {
      display: flex;
      flex-wrap: wrap;

      .form-item {
        margin-right: 40px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        width: auto;

        .label {
          white-space: nowrap;
          width: auto;
          text-align: right;
          margin-right: 8px;
        }

        display: flex;
      }
    }
  }

  .btn-look-up {
    margin-right: 16px;
    width: 76px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #2F54EB;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.addItem {
  display: inline-block;
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 20px;
  cursor: pointer;
}

.addItem:hover {
  background: rgba(47, 34, 235, 1);
}

.containerMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1002;
}

>>> .form-item .el-input__icon {
  line-height: 28px;
}

.el-icon-edit-outline {
  color: #2f54eb;
  font-size: 18px;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

.pagination-low {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}

>>>.el-select {
  width: 240px;
  height: 36px;
}

>>>.el-input {
  width: 240px;
}

>>>.el-range-editor.el-input__inner {
  width: 240px;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-pagination__editor.el-input {
  width: 50px;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
  margin-top: 0;
}

@media (max-height: 800px) {
  .fixed-content {
    max-height: 220px;
    overflow-x: hidden;
  }

  .fixed-bottom {
    max-height: 200px;
    overflow-x: hidden;
  }
}

.cursion {
  margin-right: 8px;
  color: #2f22eb;
  cursor: pointer;
}
</style>
