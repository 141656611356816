var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wrapper"},[(_vm.data.contrastKey == 'IncomingSales')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph",staticStyle:{"min-height":"523px"}},[_c('el-tabs',{attrs:{"type":"card"}},[_c('el-tab-pane',{attrs:{"label":"折线图"}},[_c('div',[(_vm.pdfData)?_c('JxxChart',{attrs:{"data":_vm.pdfData}}):_vm._e()],1)]),_c('el-tab-pane',{attrs:{"label":"表格"}},[(_vm.JxxtableData)?_c('GenericTable',{attrs:{"data":_vm.JxxtableData}}):_vm._e()],1)],1)],1)]:(_vm.data.contrastKey == 'NonDeductionIncomingSales')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),(_vm.nonDeductedJxjeTableData)?_c('div',{staticClass:"graph"},[_c('GenericTable',{attrs:{"data":_vm.nonDeductedJxjeTableData}})],1):_vm._e()]:(_vm.data.contrastKey == 'IncomingSalesInNearJune')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),(_vm.InvoiceCountTaxTable)?_c('div',{staticClass:"graph"},[_c('GenericTable',{attrs:{"data":_vm.InvoiceCountTaxTable}})],1):_vm._e()]:(_vm.data.contrastKey == 'WithInputAndNoOutput')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),(_vm.invoiceYjxWxxTable)?_c('div',{staticClass:"graph"},[_c('GenericTable',{attrs:{"data":_vm.invoiceYjxWxxTable}})],1):_vm._e()]:(_vm.data.contrastKey == 'EntryTOP10')?[_c('div',[_vm._v(_vm._s(_vm.pdfData.tips))]),_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph",staticStyle:{"min-height":"523px"}},[_c('el-tabs',{attrs:{"type":"card"}},[_c('el-tab-pane',{attrs:{"label":"环形图"}},[(_vm.invoiceJxTop10Chart)?_c('div',[_c('PieNew',{attrs:{"data":_vm.invoiceJxTop10Chart}})],1):_vm._e()]),(_vm.invoiceJxTop10Table)?_c('el-tab-pane',{attrs:{"label":"表格"}},[_c('GenericTable',{attrs:{"data":_vm.invoiceJxTop10Table}})],1):_vm._e()],1)],1)]:(_vm.data.contrastKey == 'SalesTOP10')?[_c('div',[_vm._v(_vm._s(_vm.pdfData.tips))]),_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph",staticStyle:{"min-height":"523px"}},[_c('el-tabs',{attrs:{"type":"card"}},[_c('el-tab-pane',{attrs:{"label":"环形图"}},[_c('div',[(_vm.SalesTOP10Chart)?_c('PieNew',{attrs:{"data":_vm.SalesTOP10Chart}}):_vm._e()],1)]),_c('el-tab-pane',{attrs:{"label":"表格"}},[(_vm.SalesTOP10Table)?_c('GenericTable',{attrs:{"data":_vm.SalesTOP10Table}}):_vm._e()],1)],1)],1)]:(_vm.data.contrastKey == 'invoiceErrorNameTaxNo')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceErrorNameTaxNo)?_c('GenericTable',{attrs:{"data":_vm.invoiceErrorNameTaxNo}}):_vm._e()],1)]:(_vm.data.contrastKey == 'HighRisk')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceMgsp)?_c('GenericTable',{attrs:{"data":_vm.invoiceMgsp}}):_vm._e()],1)]:(_vm.data.contrastKey == 'EntryDiscount')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceSsyh)?_c('GenericTable',{attrs:{"data":_vm.invoiceSsyh}}):_vm._e()],1)]:(_vm.data.contrastKey == 'EntryLessThan6MonthGoods')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.EntryLessThan6MonthGoodsText)?_c('GenericTable',{attrs:{"data":_vm.EntryLessThan6MonthGoodsText}}):_vm._e()],1)]:(_vm.data.contrastKey == 'EntryGoods')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceCgzwsbl)?_c('GenericTable',{attrs:{"data":_vm.invoiceCgzwsbl}}):_vm._e()],1)]:(_vm.data.contrastKey == 'InvoiceNumber')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceLzwsfp)?_c('GenericTable',{attrs:{"data":_vm.invoiceLzwsfp}}):_vm._e()],1)]:(_vm.data.contrastKey == 'invoiceSpecialLife')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceSpecialLife)?_c('GenericTable',{attrs:{"data":_vm.invoiceSpecialLife,"showDetails":_vm.showDetails},on:{"update:showDetails":function($event){_vm.showDetails=$event},"update:show-details":function($event){_vm.showDetails=$event}}}):_vm._e(),(_vm.showDetails)?_c('div',{staticClass:"tableWrapper"},[_c('div',{staticClass:"table-title"},[_vm._v("详细信息（开票金额（含税）前十家）")]),(_vm.invoiceSpecialLifeDetails)?_c('GenericTable',{attrs:{"data":_vm.invoiceSpecialLifeDetails}}):_vm._e()],1):_vm._e()],1)]:(_vm.data.contrastKey == 'invoiceSpecialLabor')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceSpecialLabor)?_c('GenericTable',{attrs:{"data":_vm.invoiceSpecialLabor,"showDetails":_vm.showDetails},on:{"update:showDetails":function($event){_vm.showDetails=$event},"update:show-details":function($event){_vm.showDetails=$event}}}):_vm._e(),(_vm.showDetails)?_c('div',{staticClass:"tableWrapper"},[_c('div',{staticClass:"table-title"},[_vm._v("详细信息（开票金额（含税）前十家）")]),(_vm.invoiceSpecialLaborDetails)?_c('GenericTable',{attrs:{"data":_vm.invoiceSpecialLaborDetails}}):_vm._e()],1):_vm._e()],1)]:(_vm.data.contrastKey == 'invoiceSpecialService')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceSpecialService)?_c('GenericTable',{attrs:{"data":_vm.invoiceSpecialService,"showDetails":_vm.showDetails},on:{"update:showDetails":function($event){_vm.showDetails=$event},"update:show-details":function($event){_vm.showDetails=$event}}}):_vm._e(),(_vm.showDetails)?_c('div',{staticClass:"tableWrapper"},[_c('div',{staticClass:"table-title"},[_vm._v("详细信息（开票金额（含税）前十家）")]),(_vm.invoiceSpecialServiceDetails)?_c('GenericTable',{attrs:{"data":_vm.invoiceSpecialServiceDetails}}):_vm._e()],1):_vm._e()],1)]:(_vm.data.contrastKey == 'InvoiceGeneration')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.InvoiceGenerationText)?_c('GenericTable',{attrs:{"data":_vm.InvoiceGenerationText}}):_vm._e()],1)]:(_vm.data.contrastKey == 'OverheadEntryInvoice')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceEntryDekpqk)?_c('GenericTable',{attrs:{"data":_vm.invoiceEntryDekpqk}}):_vm._e()],1)]:(_vm.data.contrastKey == 'invoiceEntryException')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceEntryException)?_c('GenericTable',{attrs:{"data":_vm.invoiceEntryException}}):_vm._e()],1)]:(_vm.data.contrastKey == 'invoiceZerotax')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceZerotax)?_c('GenericTable',{attrs:{"data":_vm.invoiceZerotax}}):_vm._e()],1)]:(_vm.data.contrastKey == 'invoiceRed')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceRed)?_c('GenericTable',{attrs:{"data":_vm.invoiceRed}}):_vm._e()],1)]:(_vm.data.contrastKey == 'SalesGoodsInOtherProvinces')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceXsdwsbl)?_c('GenericTable',{attrs:{"data":_vm.invoiceXsdwsbl}}):_vm._e()],1)]:(_vm.data.contrastKey == 'InvoiceNumberInOtherProvinces')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceKwwsfpje)?_c('GenericTable',{attrs:{"data":_vm.invoiceKwwsfpje}}):_vm._e()],1)]:(_vm.data.contrastKey == 'OverheadInvoice')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceDekpqk)?_c('GenericTable',{attrs:{"data":_vm.invoiceDekpqk}}):_vm._e()],1)]:(_vm.data.contrastKey == 'CompanyNameRisk')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1)]:(_vm.data.contrastKey == 'invoiceNullify')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceNullify)?_c('GenericTable',{attrs:{"data":_vm.invoiceNullify}}):_vm._e()],1)]:(_vm.data.contrastKey == 'MutualInvoice')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceHxkpqk)?_c('GenericTable',{attrs:{"data":_vm.invoiceHxkpqk}}):_vm._e()],1)]:(_vm.data.contrastKey == 'EntryMoneyInNearDecember')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),(_vm.invoiceFzcGys)?_c('div',{staticClass:"graph"},[_c('GenericTable',{attrs:{"data":_vm.invoiceFzcGys.table}}),(_vm.invoiceFzcGys.drawer)?_c('div',{staticClass:"company-table-wrapper"},_vm._l((_vm.invoiceFzcGys.drawer),function(conpanyInfo,index){return _c('CompanyTable',{key:conpanyInfo.id,attrs:{"index":index,"isDisplay":!!conpanyInfo.isDisplay},on:{"toggleVisible":_vm.handleCompanyTableVisibilityToggle}},[_c('tr',[_c('td',[_vm._v("公司名称")]),_c('td',[_vm._v(_vm._s(conpanyInfo.companyName))])]),_c('tr',[_c('td',[_vm._v("纳税人识别号")]),_c('td',[_vm._v(_vm._s(conpanyInfo.nsrsbh))])]),_c('tr',[_c('td',[_vm._v("注册资本")]),_c('td',[_vm._v(_vm._s(conpanyInfo.zczb))])]),_c('tr',[_c('td',[_vm._v("工商注册时间")]),_c('td',[_vm._v(_vm._s(conpanyInfo.zcsj))])]),_c('tr',[_c('td',[_vm._v("主要经营范围")]),_c('td',[_vm._v(_vm._s(conpanyInfo.jyfw))])]),_c('tr',[_c('td',[_vm._v("与贵公司交易情况")]),_c('td',[_vm._v(_vm._s(conpanyInfo.jyje))])]),_c('tr',[_c('td',[_vm._v("已公布风险情况")]),_c('td',[_vm._v(_vm._s(conpanyInfo.fxqk))])])])}),1):_vm._e()],1):_vm._e()]:(_vm.data.contrastKey == 'invoiceSupplierLegalLitigationRisk')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceSupplierLegalLitigationRisk)?_c('TableWithDrawer',{attrs:{"drawerTriggerIndex":3,"data":_vm.invoiceSupplierLegalLitigationRisk},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(slotProps.drawerData.drawer)?_c('GenericTable',{attrs:{"data":_vm.caseOfinvoiceSupplierLegalLitigationRisk(slotProps.drawerData.drawer)}}):_vm._e()]}}],null,false,418926898)}):_vm._e()],1)]:(_vm.data.contrastKey == 'invoiceSupplierExecutorRisk')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceSupplierExecutorRisk)?_c('TableWithDrawer',{attrs:{"drawerTriggerIndex":2,"data":_vm.invoiceSupplierExecutorRisk},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(slotProps.drawerData.drawer)?_c('GenericTable',{attrs:{"data":_vm.caseOfinvoiceSupplierExecutorRisk(slotProps.drawerData.drawer)}}):_vm._e()]}}],null,false,3533416596)}):_vm._e()],1)]:(_vm.data.contrastKey == 'invoiceSupplierDishonestRisk')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceSupplierDishonestRisk)?_c('TableWithDrawer',{attrs:{"drawerTriggerIndex":2,"data":_vm.invoiceSupplierDishonestRisk},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(slotProps.drawerData.drawer)?_c('GenericTable',{attrs:{"data":_vm.caseOfinvoiceSupplierDishonestRisk(slotProps.drawerData.drawer)}}):_vm._e()]}}],null,false,2881254150)}):_vm._e()],1)]:(_vm.data.contrastKey == 'invoiceSupplierLimitingConsumptionRisk')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceSupplierLimitingConsumptionRisk)?_c('TableWithDrawer',{attrs:{"drawerTriggerIndex":2,"data":_vm.invoiceSupplierLimitingConsumptionRisk},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(slotProps.drawerData.drawer)?_c('GenericTable',{attrs:{"data":_vm.caseOfinvoiceSupplierLimitingConsumptionRisk(
                  slotProps.drawerData.drawer
                )}}):_vm._e()]}}],null,false,733007139)}):_vm._e()],1)]:(_vm.data.contrastKey == 'SalesMoneyInNearDecember')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceFzcKh)?_c('TableWithDrawer',{attrs:{"drawerTriggerIndex":1,"data":_vm.invoiceFzcKh},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(slotProps.drawerData.drawer)?_c('CompanyTable',[_c('tr',[_c('td',[_vm._v("公司名称")]),_c('td',[_vm._v(_vm._s(slotProps.drawerData.drawer.companyName))])]),_c('tr',[_c('td',[_vm._v("纳税人识别号")]),_c('td',[_vm._v(_vm._s(slotProps.drawerData.drawer.nsrsbh))])]),_c('tr',[_c('td',[_vm._v("注册资本")]),_c('td',[_vm._v(_vm._s(slotProps.drawerData.drawer.zczb))])]),_c('tr',[_c('td',[_vm._v("工商注册时间")]),_c('td',[_vm._v(_vm._s(slotProps.drawerData.drawer.zcsj))])]),_c('tr',[_c('td',[_vm._v("主要经营范围")]),_c('td',[_vm._v(_vm._s(slotProps.drawerData.drawer.jyfw))])]),_c('tr',[_c('td',[_vm._v("与贵公司交易情况")]),_c('td',[_vm._v(_vm._s(slotProps.drawerData.drawer.jyje))])]),_c('tr',[_c('td',[_vm._v("已公布风险情况")]),_c('td',[_vm._v(_vm._s(slotProps.drawerData.drawer.fxqk))])])]):_vm._e()]}}],null,false,1183840962)}):_vm._e()],1)]:(_vm.data.contrastKey == 'invoiceCustomerLegalLitigationRisk')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceCustomerLegalLitigationRisk)?_c('TableWithDrawer',{attrs:{"data":_vm.invoiceCustomerLegalLitigationRisk},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(slotProps.drawerData.drawer)?_c('GenericTable',{attrs:{"data":_vm.caseOfinvoiceCustomerLegalLitigationRisk(slotProps.drawerData.drawer)}}):_vm._e()]}}],null,false,230098978)}):_vm._e()],1)]:(_vm.data.contrastKey == 'invoiceCustomerExecutorRisk')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceCustomerExecutorRisk)?_c('TableWithDrawer',{attrs:{"data":_vm.invoiceCustomerExecutorRisk},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(slotProps.drawerData.drawer)?_c('GenericTable',{attrs:{"data":_vm.caseOfinvoiceCustomerExecutorRisk(slotProps.drawerData.drawer)}}):_vm._e()]}}],null,false,2166731140)}):_vm._e()],1)]:(_vm.data.contrastKey == 'invoiceCustomerDishonestRisk')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceCustomerDishonestRisk)?_c('TableWithDrawer',{attrs:{"data":_vm.invoiceCustomerDishonestRisk},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(slotProps.drawerData.drawer)?_c('GenericTable',{attrs:{"data":_vm.caseOfinvoiceCustomerDishonestRisk(slotProps.drawerData.drawer)}}):_vm._e()]}}],null,false,1810010646)}):_vm._e()],1)]:(_vm.data.contrastKey == 'invoiceCustomerLimitingConsumptionRisk')?[_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1),_c('div',{staticClass:"graph"},[(_vm.invoiceCustomerLimitingConsumptionRisk)?_c('TableWithDrawer',{attrs:{"data":_vm.invoiceCustomerLimitingConsumptionRisk},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(slotProps.drawerData.drawer)?_c('GenericTable',{attrs:{"data":_vm.caseOfinvoiceCustomerLimitingConsumptionRisk(
                  slotProps.drawerData.drawer
                )}}):_vm._e()]}}],null,false,2169398579)}):_vm._e()],1)]:[_c('div',{staticClass:"desc"},[_c('div',{staticClass:"title"},[_vm._v("分析结果")]),(_vm.roleIdList.indexOf(22) > -1 || _vm.authorizationFromHouTai)?_c('div',{staticClass:"textarea-wrapper"},[_c('el-tooltip',{attrs:{"popper-class":_vm.popperClass,"manual":true,"value":_vm.isInputModified || _vm.isInputModifiedByMyself,"content":"此内容已修改","placement":"top-end"}},[_c('el-input',{staticClass:"textarea",class:{
                isModified: _vm.isInputModified,
                isModifiedByMyself: _vm.isInputModifiedByMyself,
              },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.dataText),callback:function ($$v) {_vm.dataText=$$v},expression:"dataText"}})],1)],1):_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.dataText))])]),_c('div',{staticClass:"graph"},[[_c('div',_vm._l((_vm.tips),function(tip,index){return _c('el-alert',{key:index,staticClass:"tip",attrs:{"type":"warning","closable":false}},[_c('i',{staticClass:"el-icon-warning-outline"}),_vm._v(" "+_vm._s(tip.text)+" ")])}),1),(_vm.ruleOfInvoiceImageList)?[_c('el-tabs',{attrs:{"type":"card"}},[_vm._l((_vm.ruleOfInvoiceImageList),function(chart,index){return _c('el-tab-pane',{key:index,attrs:{"label":_vm.chartTypeName(chart.imageType)}},[(
                    chart.imageType == 'histogram' || chart.imageType == 'twoHistogram'
                  )?_c('ColumnChart',{attrs:{"data":chart,"width":"849px","height":"435px"}}):_vm._e(),(chart.imageType == 'brokenLine')?_c('LineChart',{attrs:{"data":chart,"width":"849px","height":"435px"}}):_vm._e(),(chart.imageType == 'annulus')?_c('Pie',{attrs:{"data":chart,"width":"849px","height":"435px"}}):_vm._e()],1)}),(_vm.generalTableData)?_c('el-tab-pane',{attrs:{"label":"表格"}},[_c('GenericTable',{attrs:{"data":_vm.generalTableData,"tips":_vm.tableTips,"subTables":_vm.subTables}})],1):_vm._e()],2)]:[(_vm.generalTableData)?_c('GenericTable',{attrs:{"data":_vm.generalTableData,"tips":_vm.tableTips,"subTables":_vm.subTables}}):_vm._e()]]],2),_c('div',{staticClass:"desc"},[_c('div',{staticClass:"title"},[_vm._v("核实思路")]),(_vm.roleIdList.indexOf(22) > -1 || _vm.authorizationFromHouTai)?_c('div',{staticClass:"textarea-wrapper"},[_c('el-input',{staticClass:"textarea",class:{
              isModified: _vm.isInputModified,
              isModifiedByMyself: _vm.isInputModifiedByMyself,
            },attrs:{"type":"textarea","disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? '请输入内容' : '不显示',"maxlength":"3000","show-word-limit":""},model:{value:(_vm.verificationIdeas),callback:function ($$v) {_vm.verificationIdeas=$$v},expression:"verificationIdeas"}})],1):_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.verificationIdeas))])])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }