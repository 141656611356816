<template>
  <div class="table-wrapper">
    <table class="table" v-if="isDisplay">
      <slot></slot>
    </table>
    <div class="btn-wrapper">
      <button
        class="btn"
        :class="{ 'btn-default': isDisplay }"
        @click="toggleVisible"
      >
        {{ label }}
      </button>
    </div>
  </div>
</template>

<script>
//专门用来展示公司信息的表格
export default {
  props: {
    index: Number,
    isDisplay: Boolean,
  },
  computed: {
    label() {
      return this.isDisplay ? "隐藏" : "显示";
    },
  },
  methods: {
    toggleVisible() {
      this.$emit("toggleVisible", this.index, !this.isDisplay);
      //this.nativeIsDisplay = !this.nativeIsDisplay;
      //this.$parent.$parent.setTreeNodeAttribute();
    },
  },
  data: function () {
    return {};
  },
};
</script>

<style lang="stylus" scoped>
.btn-wrapper {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

.table-wrapper {
  width: 818px;
  background: rgba(238, 238, 238, 1);
  padding: 18px;
  margin-top: 22px;
  box-sizing: border-box;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;

  td, th {
    text-align: left;
    font-size: 14px;
    padding: 9px 15px;
    border: 1px solid #D9D9D9;
    color: rgba(0, 0, 0, 0.6);
    background: #fff;

    .clickable {
      color: #2F54EB;
      cursor: pointer;
    }
  }

  th {
    font-weight: 600;
    background: rgba(240, 245, 255, 1);
  }

  td:first-child {
    background: #F0F5FF;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    width: 160px;
  }
}
</style>