<template>
  <div class="company_apply">
    <bread-crumb>
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>企业认证</el-breadcrumb-item>
    </bread-crumb>
    <div class="setuser">
      <div class="qymc">企业认证</div>
      <el-card class="usercard">
        <div class="box" v-if="userStatus!=0">
          <div class="qyname">当前角色：</div>
          <div class="name name-right">
            <img class="name-logo" v-if="userStatus==1" src="@/assets/test/usericon.png" alt />
            <img class="name-logo" v-if="userStatus==2" src="@/assets/test/financeicon.png" alt />
            <span class="font-600" v-if="userStatus==1">企业主</span>
            <span class="font-600" v-if="userStatus==2">财务人员</span>
          </div>
          <el-button type="text" class="code_btn" @click="checkPart">
            <i class="el-icon-refresh"></i>切换角色
          </el-button>
        </div>
        <div class="box box1">
          <div class="qyname field">企业名称：</div>
          <div class="name">
            <el-input placeholder="请输入" v-model="name"></el-input>
          </div>
        </div>
        <div class="box box1">
          <div class="qyname field">所属地区：</div>
          <div class="name">
            <el-cascader :options="city" v-model="diqu" @change="handleChange"></el-cascader>
          </div>
        </div>
        <template v-if="showMsg">
          <div class="box box1">
            <div class="qyname">所属1级行业：</div>
            <div class="name">
              <el-select v-model="hangye" placeholder="请选择" filterable @change="changeHy">
                <el-option
                  v-for="item in hyList"
                  :key="item.hyDm"
                  :label="item.hyMc"
                  :value="item.hyDm"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="box box1">
            <div class="qyname">所属2级行业：</div>
            <div class="name">
              <el-select v-model="morehy" placeholder="请选择" filterable>
                <el-option
                  v-for="item in hyList2"
                  :key="item.hyDm"
                  :label="item.dmMc"
                  :value="item.hyDm"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="box box1">
            <div class="qyname">会计制度：</div>
            <div class="name">
              <el-select v-model="zhidu" placeholder="请选择">
                <el-option
                  v-for="item in optionskjzd"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="box box1">
            <div class="qyname">资产总额平均值：</div>
            <div class="name">
              <el-input maxlength="9" placeholder="请输入资产总额平均值" v-model="all">
                <template slot="append">万元</template>
              </el-input>
            </div>
          </div>
          <div class="box box1">
            <div class="qyname">从业人数平均值：</div>
            <div class="name">
              <el-input placeholder="请输入从业人数平均值" v-model="average">
                <template slot="append">个</template>
              </el-input>
            </div>
          </div>
          <div class="box box1">
            <div class="qyname">企业类型：</div>
            <div class="name">
              <div>
                <span>高新技术企业：</span>
                <span>
                  <el-radio v-model="com1" label="1">是</el-radio>
                  <el-radio v-model="com1" label="0">否</el-radio>
                </span>
                <span class="nameleft nameleftP">存在外资投资股东：</span>
                <span class="nameleftP">
                  <el-radio v-model="com2" label="1">是</el-radio>
                  <el-radio v-model="com2" label="0">否</el-radio>
                </span>
              </div>
            </div>
          </div>
          <div class="box box1">
            <div class="qyname"></div>
            <div class="name">
              <div>
                <span>小型微利企业：</span>
                <span>
                  <el-radio v-model="com3" label="1">是</el-radio>
                  <el-radio v-model="com3" label="0">否</el-radio>
                </span>
                <span class="nameleft">从事国家限制或禁止行业：</span>
                <span>
                  <el-radio v-model="com4" label="1">是</el-radio>
                  <el-radio v-model="com4" label="0">否</el-radio>
                </span>
              </div>
            </div>
          </div>
        </template>
        <div class="box-more" v-if="!showMsg">
          <el-button type="text" class="code_btn" @click="showMsg=true">
            <span class="card-row">
              <i class="el-icon-d-arrow-right"></i>
            </span>
            选填选项
          </el-button>
        </div>
        <div class="box-more" v-if="showMsg">
          <el-button type="text" class="code_btn" @click="showMsg=false">
            <span class="card-row card-row-t">
              <i class="el-icon-d-arrow-right"></i>
            </span>
            点击收起
          </el-button>
        </div>
        <el-button type="primary" class="save" @click="rzconfirm">立即认证</el-button>
      </el-card>
    </div>
    <!-- 切换角色弹窗 -->
    <el-dialog
      :visible.sync="checkParts"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      id="partdialog"
      :show-close="false"
    >
      <div class="part-check font-600">
        <span>角色选择</span>
        <img class="part-close" src="@/assets/test/part-close.png" alt @click="checkClose" />
      </div>
      <div class="part-icon">
        <span class="part-user part-userR">
          <span class="part-circle" :class="{partactive:act==1}" @click="act=1">
            <img class="part-iconl" src="@/assets/test/part-u.png" alt />
          </span>
          <span class="part-checktext" :class="{partactivet:act==1}">企业主</span>
        </span>
        <span class="part-user">
          <span class="part-circle" :class="{partactive:act==2}" @click="act=2">
            <img src="@/assets/test/part-c.png" alt />
          </span>
          <span class="part-checktext" :class="{partactivet:act==2}">财务人员</span>
        </span>
      </div>
      <div>
        <div class="part-btn ease" @click="checkSubmit">确定</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import {
  getHy,
  dmHyMore,
  testaddcompany,
  renzhengApply,
  getCompanyrz,
  getCompanyId
} from "@/api/api";
import { regionData } from "@/dependencies/element-china-area-data.js";

export default {
  components: {
    BreadCrumb
  },
  props: ["addnewcompanystatus"],
  data() {
    return {
      checkParts: false,
      act: 1,
      checkList: [],
      city: regionData,
      value: "",
      name: "",
      zhidu: null,
      optionskjzd: [
        { value: "qykjzd", label: "企业会计制度" },
        { value: "qykjzz", label: "企业会计准则" },
        { value: "xqykjzz", label: "小企业会计准则" }
      ],
      hyList: [],
      hyList2: [],
      hangye: "",
      morehy: null, //具体行业
      diqu: null,
      // 新增
      all: "",
      average: "",
      com1: "",
      com2: "",
      com3: "",
      com4: "",
      userStatus: 0,
      showMsg: false
    };
  },
  mounted() {
    this.getHyList();
    this.checkParts = true;
  },
  methods: {
    checkSubmit() {
      this.userStatus = this.act;
      this.checkParts = false;
    },
    checkClose() {
      this.checkParts = false;
      this.$router.push({ path: "/" });
    },
    checkPart() {
      this.checkParts = true;
    },
    getHyList() {
      getHy().then(res => {
        this.hyList = res.data;
      });
    },
    changeHy(e) {
      let para = {
        hy: e,
        hycode: ""
      };
      dmHyMore(para).then(res => {
        this.hyList2 = res.data;
      });
    },
    //省市触发
    handleChange(value) {
      this.province1_code = value[0];
      for (let key in value) {
        for (let k in this.city) {
          if (this.city[k].value == value[key]) {
            this.province1 = this.city[k].value;
          }
          for (let k1 in this.city[k].children) {
            if (this.city[k].children[k1].value == value[key]) {
              this.city1 = this.city[k].children[k1].value;
            }
          }
        }
      }
    },
    issubmit() {
      if (!this.name) {
        this.$message.error({
          message: "请输入企业名称!",
          duration: 1500
        });
        return false;
      }
      if (!this.diqu) {
        this.$message.error({
          message: "请选择地区!",
          duration: 1500
        });
        return false;
      }
      // if (!this.morehy) {
      //   this.$message.error({
      //     message: "请选择所属2级行业!",
      //     duration: 1500
      //   });
      //   return false;
      // }
      // if (!this.zhidu) {
      //   this.$message.error({
      //     message: "请选择会计制度!",
      //     duration: 1500
      //   });
      //   return false;
      // }
      return true;
    },
    rzconfirm() {
      const _this = this;
      if (this.issubmit()) {
        let token = JSON.parse(localStorage.getItem("token")).token;
        let params = {
          type: this.userStatus,
          name: this.name,
          dq: this.diqu.toString(),
          hy: this.morehy,
          kjzd: this.zhidu,
          token: token,
          number: this.average,
          generalAssets: this.all,
          highNewTechnology: this.com1,
          miniature: this.com3,
          foreignInvestment: this.com2,
          restrictedIndustries: this.com4
        };
        // var aa = [this.province1, this.city1];
        // params.dq = aa.join();
        if (this.$route.query.addnewcompanystatus) {
          testaddcompany(params).then(res => {
            if (res.msgCode == 1) {
              // this.$message.success({
              //   message: "添加企业成功",
              //   duration: 1500
              // });
              this.$alert("添加企业成功", "提示", {
                type: "success"
              }).then(async () => {
                //修改localstorage里的user里的内容
                const user = JSON.parse(localStorage.getItem("user"));
                user.channalId = res.data.channalId;
                user.channalUserId = res.data.channalUserId;
                user.companyId = res.data.id;
                user.nsrsbm = res.data.nsrsbm;
                localStorage.setItem("user", JSON.stringify(user));

                this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
                this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
                await this.$store.cache.dispatch("loadCompanyRenzheng");
                await this.$store.cache.dispatch("loadCompanyInfo");

                //如果有跳转url 则跳转
                let redirectToURL = _this.$route.query.redirectToURL;
                console.log(redirectToURL, "redirectToURL");
                redirectToURL = decodeURIComponent(redirectToURL);
                if (redirectToURL && redirectToURL != "undefined") {
                  this.$router.push({ path: redirectToURL });
                } else {
                  this.$router.push({ path: "/" });
                }
              });
            } else {
              this.$message.error({
                message: res.msgInfo,
                duration: 1000
              });
            }
          });
        } else {
          //申请认证
          renzhengApply(params).then(async res => {
            if (res.msgCode == 1) {
              let params = {
                id: res.data.id,
                keyWord: res.data.nsrsbm,
                userName: this.name,
                mobile: JSON.parse(localStorage.getItem("user")).mobile
              };

              //修改localstorage里的user里的内容
              const user = JSON.parse(localStorage.getItem("user"));
              user.channalId = res.data.channalId;
              user.channalUserId = res.data.channalUserId;
              user.companyId = res.data.id;
              user.nsrsbm = res.data.nsrsbm;
              localStorage.setItem("user", JSON.stringify(user));

              //自动审核
              getCompanyrz(params).then(async data => {
                this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
                this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
                await this.$store.cache.dispatch("loadCompanyRenzheng");
                await this.$store.cache.dispatch("loadCompanyInfo");

                //如果有跳转url 则跳转
                const redirectURL = _this.$route.query.redirectTo
                  ? decodeURIComponent(_this.$route.query.redirectTo)
                  : null;
                if (redirectURL && redirectURL != undefined) {
                  this.$router.push({ path: redirectURL });
                } else {
                  //否则跳转
                  let redirectToURL = this.$route.query.redirectToURL;

                  redirectToURL = decodeURIComponent(redirectToURL);
                  if (redirectToURL && redirectToURL != "undefined") {
                    this.$router.push({ path: redirectToURL });
                  } else {
                    this.$router.push({ path: "/" });
                  }
                }
              });
            } else {
              this.$message.error({
                message: res.msgInfo,
                duration: 1500
              });
            }
          });
        }
      }
    }
  }
};
</script>
<style src="@/styles/index.scss" lang="scss" scoped></style>
<style lang="stylus" scoped>
.compant_mdf {
  min-height: 80px;

  .mdf_c1 {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.top_left {
  margin: 10px 0;
}

.setuser {
  width: 97%;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
  color: #062340;

  >>>.date_selet_dq .el-input .el-input__suffix {
    top: 5px;

    i:before {
      position: relative;
      top: -4px;
    }
  }

  .com_input {
    margin-left: 25px !important;
    width: 28% !important;
  }

  .date1 {
    margin-top: 15px;
    width: 100%;
    margin-left: 99px;

    .date_selet {
      width: 28%;
    }

    .company_dq {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(107, 120, 151, 1);
      margin-right: 5px;
    }

    .company_hy {
      margin-right: 10px;
    }
  }

  .savebtn {
    margin: 50px auto 50px auto;
  }
}

.nameleft {
  margin-left: 80px;
}

.nameleftP {
  position: relative;
  left: 42px;
}

.field:before {
  content: '*';
  color: red;
  position: relative;
  right: 4px;
}

.el-icon-refresh {
  margin-right: 6px;
}

.name-logo {
  position: relative;
  top: 5px;
  margin-right: 4px;
}

.name-right {
  margin-right: 9px;
}

>>> #partdialog .el-dialog {
  width: 400px;
  height: 304px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

>>> #partdialog .el-dialog__header {
  padding: 0;
}

>>> #partdialog .el-dialog__body {
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.part-check {
  width: 100%;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid #EEEEEE;
  padding-left: 24px;
  box-sizing: border-box;
  align-item: center;
}

.part-close {
  position: relative;
  top: 18px;
  float: right;
  padding-right: 24px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.part-icon {
  display: flex;
  justify-content: center;
  height: 180px;
  text-align: center;
  padding-top: 30px;
  border-bottom: 1px solid #EEEEEE;
  box-sizing: border-box;
}

.part-iconl {
  width: 74px;
  height: 89px;
}

.part-btn {
  position: relative;
  right: 24px;
  top: 16px;
  width: 76px;
  height: 36px;
  line-height: 36px;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  float: right;
  cursor: pointer;
}

.part-btn:hover {
  background: rgba(47, 60, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.part-circle {
  display: inline-block;
  width: 88px;
  height: 88px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(217, 217, 217, 1);
  border-radius: 50%;
  margin-bottom: 10px;
}

.part-circle:hover {
  transform: scale(1.1);
}

.part-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.part-userR {
  margin-right: 64px;
}

.part-check {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
}

.partactive {
  background: rgba(240, 245, 255, 1);
  border: 2px solid rgba(47, 84, 235, 1);
}

.partactivet {
  color: rgba(47, 84, 235, 1);
}

.box-more {
  margin-left: 140px;
  margin-top: 22px;
}

.card-row {
  display: inline-block;
  transform: rotate(90deg);
}

.card-row-t {
  display: inline-block;
  transform: rotate(270deg);
}
</style>