<template>
  <el-input type="text" v-bind="binds" v-on="btnListeners"
  ></el-input>
</template>

<script>
export default {
  name: "BigPowerInput",
  props: {
    type: {
      type: String,
      default: 'text'
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    value: [String, Number]
  },
  data() {
    return {

      lastSafeValue: ''
    }
  },
  mounted() {
    this.lastSafeValue = this.value
  },
  computed: {
    binds() {
      return {
        ...this.$attrs,
        value: this.value,
        type: this.type,
        max: {
          type: Number,
          default: null
        },
        min: {
          type: Number,
          default: null
        }
      }
    },
    btnListeners() {
      const vm = this;
      // `Object.assign` 将所有的对象合并为一个新对象
      let listeners = {
        ...this.$listeners,
      };
     // if (vm.$listeners.change) {
        listeners = {
          // 我们从父级添加所有的监听器
          ...this.$listeners,
          // 然后我们添加自定义监听器，
          // 或覆写一些监听器的行为
          // 这里确保组件配合 `v-model` 的工作
          change: async (value) => {
            if (value === '') {
              this.$emit('change', value)
            } else if (this.type == 'number') {
              if (!isNaN(value)) {
                if (this.max != null && value > this.max) {
                  value = this.max
                } else if (this.min != null && value < this.min) {
                  value = this.min
                } else {
                }
              } else {
                value = ''
              }

              this.$emit('input', value)
              this.$emit('change', value)
            }
          },
        };
     // }
      return listeners;
    },
  },
  methods: {

  },
  // watch: {
  //   value: {
  //     handler(newValue, oldValue) {
  //
  //       if (newValue == oldValue||newValue=='') {
  //         return
  //       }
  //       if (this.type == 'number') {
  //         if (typeof newValue !== 'number') {
  //           this.value = oldValue;
  //         }
  //       }
  //       console.log(newValue, oldValue)
  //     }
  //   }
  // }
}
</script>

<style scoped>

</style>
