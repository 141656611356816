<template>
  <div>
    <!-- 补全行业 -->
    <transition name="fades">
      <div class="containerMask" v-if="dialogTableVisibleHy"></div>
    </transition>
    <transition name="fadee">
      <div
        class="container"
        v-if="dialogTableVisibleHy"
        ref="projectBox"
        id="projectBoxs"
        v-loading="addLoading"
      >
        <div class="addTitle">以下信息未完善，请补全后继续</div>
        <div class="project-close" @click="closeDialog" v-if="buttonIndex == 2">×</div>
        <div class="project-name"><span class="project-named">*</span>客户行业</div>
        <div class="project-input">
          <el-select v-model="person.industry" placeholder="请选择" filterable>
            <el-option
              v-for="item in hyList"
              :key="item.hy_dm"
              :label="item.hymc"
              :value="item.hy_dm"
            ></el-option>
          </el-select>
          <span class="project-input-b" v-show="formHy">行业不能为空</span>
        </div>
        <div class="project-btn ease" @click="addProjectForm">
          <div>补全行业信息</div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { addCustom, editCustom, getTradeList } from "@/api/api";

export default {
  props: {
    uuid: [String, Number],
    buttonIndex: [String, Number],
  },

  data() {
    return {
      formHy: false,
      hyList: null,
      dataList: null,
      input: "",
      isEdit: false,
      addLoading: false,
      person: {},
      dialogTableVisibleHy: false,
      loading: true,
    };
  },
  methods: {
    gettradeList() {
      getTradeList().then((res) => {
        let data = JSON.parse(res.data);
        this.hyList = data;
        let dataList = {};
        for (let i = 0; i < this.hyList.length; i++) {
          this.hyList[i].hymc = this.hyList[i].hy_dm + this.hyList[i].hymc;
          dataList[this.hyList[i].hy_dm] = this.hyList[i].hymc;
        }
        this.dataList = dataList;
      });
    },
    openDetail() {
      this.dialogTableVisibleHy = true;
    },

    detailSave() {
      this.isEdit = false;
    },

    addProjectForm() {
      if (!this.person.industry) {
        this.formHy = true;
        return;
      }
      let info = JSON.parse(localStorage.getItem("projectCustomInfo"));
      let params = {
        id: info.consumerEntity.id,
        industry: this.person.industry,
      };
      editCustom(params).then((res) => {
        if (res.msgCode == 1) {
          this.$notify({
            title: "温馨提示",
            message: "补全信息成功！",
            type: "success",
          });
          this.$emit("supplementHy");
          this.dialogTableVisibleHy = false;
          this.person.industry = "";
        } else {
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
        }
      });
    },

    closeDialog() {
      this.dialogTableVisibleHy = false;
      this.$emit("closeDialogsHy");
    },
  },
  created() {
    this.gettradeList();
  },
};
</script>

<style lang="stylus" scoped>
.containerMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1002;
}

.addTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #FAAD14;
  line-height: 28px;
  padding: 24px 0 20px 0;
}

.container {
  position: absolute;
  left: 50%;
  margin-left: -240px;
  top: 15vh;
  width: 480px;
  height: 234px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
}

>>> #projectBoxs .el-input {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs >>>.el-input >>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #projectBoxs .el-input__icon {
  line-height: 36px;
}

>>> #projectBoxs .el-cascader {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input, .addForms {
  position: relative;
}

.project-input-b {
  position: absolute;
  right: 40px;
  top: 40px;
  font-size: 12px;
  color: red;
}

.project-input .el-input {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.project-name {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 0 0 4px 32px;
}

.project-named {
  position: relative;
  top: 2px;
  margin-right: 4px;
  color: #f5222d;
}

.project-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  line-height: 68px;
  background: rgba(47, 84, 235, 1);
  box-shadow: 0px -1px 0px 0px rgba(47, 84, 235, 1);
  z-index: 1004;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.project-close {
  position: absolute;
  cursor: pointer;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  top: 6px;
  right: 20px;
}

.fadee-enter-active {
  transition: all 0.3s ease;
}

.fades-enter-active {
  transition: all 0.3s ease;
}

.fadee-leave-active {
  transition: all 0.2s ease;
}

.fades-leave-active {
  transition: all 0.3s ease;
}

.fadee-enter, .fadee-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.fades-enter, .fades-leave-to {
  opacity: 0;
}

.project-btn:hover {
  background: rgba(47, 34, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(200px);
  opacity: 0;
}
</style>
