<template>
  <div>
    <bread-crumb>
      <el-breadcrumb-item :to="{ name: 'goods' }">产品中心</el-breadcrumb-item>
      <el-breadcrumb-item>我的订单</el-breadcrumb-item>
    </bread-crumb>
    <div class="submit-title">订单记录</div>
    <div class="record-select">
      <div class="record-state">订单状态：</div>
      <el-select v-model="value" placeholder="请选择" @change="optionSelect(value)">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <div class="record-time">创建时间：</div>
      <div class="block">
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="timeChange"
        ></el-date-picker>
      </div>
    </div>
    <div class="record-form">
      <el-table
        v-loading="loading"
        :data="tableData"
        element-loading-text="加载中..."
        style="width: 100%"
        :default-sort="{prop: 'date', order: 'descending'}"
      >
        <el-table-column width="24"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="orderNumber" label="订单编号" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="goodsName" label="版本"></el-table-column>
        <!-- <el-table-column prop="hyName" label="风险库" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span class="record-hyName" v-if="scope.row.hyName">{{(scope.row.hyName).substr(1)}}</span>
            <span v-else>无</span>
          </template>
        </el-table-column>-->
        <el-table-column label="费用(元)">
          <template slot-scope="scope">
            <span class="record-hyName">{{scope.row.price&&(scope.row.price).toFixed(2)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="订单状态">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 1||scope.row.status == 2">
              <div class="icons">
                <span class="icon icongary"></span>
                <span>待支付</span>
              </div>
            </div>
            <div class="icons" v-if="scope.row.status == 0">
              <span class="icon iconred"></span>
              <span>已取消</span>
            </div>
            <div
              class="icons"
              v-if="scope.row.status == 4||scope.row.status == 5||scope.row.status == 6"
            >
              <span class="icon"></span>
              <span>支付成功</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column class="blue" align="center" prop="payment_method" label="支付方式">
          <template slot-scope="scope">
            <span
              v-if="scope.row.payment_method=='支付宝扫码支付'||scope.row.payment_method=='支付宝电脑网站支付'"
            >支付宝</span>
            <span v-if="scope.row.payment_method=='微信 Native 支付'">微信</span>
          </template>
        </el-table-column>
        <el-table-column prop="discount" label="操作" align="right">
          <template slot-scope="scope">
            <span
              v-if="scope.row.status == 1||scope.row.status == 2"
              class="discount-btn discount-btn2"
              @click="pay(scope.row)"
            >支付</span>
            <span
              class="discount-btn discount-btn1"
              v-if="scope.row.status == 1||scope.row.status == 2"
              @click="cancelOrder(scope.row.orderId)"
            >取消</span>
            <span class="discount-btn discount-btn1" @click="viewDetail(scope.row)">查看</span>
          </template>
        </el-table-column>
        <el-table-column width="20"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import { orderRecord, allRecord, cancelOrder } from "../../api/api";
export default {
  components: {
    BreadCrumb
  },
  data() {
    return {
      options: [
        {
          value: "0",
          label: "全部"
        },
        {
          value: "1",
          label: "待支付"
        },
        {
          value: "2",
          label: "已取消"
        },
        {
          value: "3",
          label: "已支付"
        }
      ],
      value: "全部",
      value1: "",
      tableData: [],
      firstTime: "2018-11-13",
      lastTime: "2099-12-30",
      status: "8",
      loading: "true",
      pageNo: 0,
      pageSize: 100
    };
  },
  created() {
    this.allrecord(this.firstTime, this.lastTime, this.status);
  },
  methods: {
    // 查询所有订单记录
    allrecord(firstTime, lastTime, status) {
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token,
        status: status,
        startDate: firstTime,
        endDate: lastTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        companyId: JSON.parse(localStorage.getItem("user")).companyId
      };
      allRecord(params).then(res => {
        let data = res.data;
        if (data) {
          data.forEach(item => {
            item.createTime = this.formatDateTes(item.createTime);
            this.tableData = data.filter(item => {
              return item.status != 1;
            });
          });
        } else {
          this.tableData = data;
        }
        this.loading = false;
      });
    },
    // 取消订单
    cancelorder(id) {
      let params = {
        orderId: id
      };
      cancelOrder(params).then(res => {
        if (res.msgCode == 1) {
          this.allrecord(this.firstTime, this.lastTime, this.status);
          this.$message({
            type: "success",
            message: "取消订单成功!"
          });
        } else {
          this.$message({
            type: "info",
            message: "取消订单失败!"
          });
        }
      });
    },
    optionSelect(val) {
      if (val == 0) {
        this.status = 8;
      } else if (val == 2) {
        this.status = 0;
      } else {
        this.status = +val + 1;
      }
      this.allrecord(this.firstTime, this.lastTime, this.status);
    },
    timeChange(val) {
      if (!val) {
        this.firstTime = "2018-11-13";
        this.lastTime = "2099-12-30";
      } else {
        this.firstTime = this.formatDateTes(val[0]);
        this.lastTime = this.formatDateTes(val[1]);
      }
      this.allrecord(this.firstTime, this.lastTime, this.status);
    },
    // 取消订单
    cancelOrder(val) {
      this.$confirm("是否取消该订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "返回",
        type: "warning"
      })
        .then(() => {
          this.cancelorder(val);
        })
        .catch(() => {});
    },
    // 查看订单
    viewDetail(val) {
      this.$router.push({
        path: "/product/info",
        query: {
          id: val.orderId,
          status: val.status
        }
      });
    },
    // 支付
    pay(val) {
      localStorage.setItem("totalPrice", val.price);
      this.$router.push({
        path: "/product/pay",
        query: {
          id: val.orderId,
          num: val.orderNumber
        }
      });
    },
    formatDateTes(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + " " + h + m + s;
    }
  }
};
</script>
<style lang="stylus" scoped>
.submit-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.8);
  padding: 8px 0 0 30px;
  font-weight: 500;
}

.record-select {
  display: flex;
  align-items: center;
  // min-width: 1100px;
  height: 84px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 24px 30px 0 30px;
  color: rgba(0, 0, 0, 0.75);
}

>>>.el-select {
  width: 240px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-sizing: border-box;
}

>>>.el-input .el-input--suffix {
  width: 240px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-sizing: border-box;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-input__icon {
  line-height: 36px;
}

>>>.el-date-editor .el-range-separator {
  width: 8%;
}

>>>.el-input__icon {
  line-height: 28px;
}

>>>.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 260px;
}

.record-state {
  margin-left: 40px;
  margin-right: 8px;
}

.record-time {
  margin-left: 48px;
  margin-right: 8px;
}

.record-form {
  position: relative;
  bottom: 20px;
  // min-width: 1100px;
  margin: 36px 30px 0 30px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  overflow: hidden;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.el-date-editor .el-range__close-icon {
  line-height: 30px;
}

.icon {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: rgba(87, 197, 66, 1);
  border-radius: 50%;
  margin-right: 8px;
}

.icons {
  display: flex;
  align-items: center;
}

.iconred {
  background: rgba(246, 108, 111, 1);
}

.icongary {
  background: rgba(217, 217, 217, 1);
}

>>>.el-table__row {
  height: 52px !important;
}

>>>.el-table .cell {
  line-height: 1.1;
}

>>>.el-table tr td:last-child {
  margin-left: 50px;
}

.discount-btn {
  cursor: pointer;
  color: rgba(47, 84, 235, 1);
  font-size: 14px;
}

.discount-btn1 {
  margin-left: 12px;
}

.discount-btn2 {
  display: inline-block;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  color: #fff;
  padding: 6px 10px;
}

.record-hyName {
  padding-right: 30px;
}
</style>