<template>
  <div>
    <bread-crumb>
      <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'test-hub' }">检测中心</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{ name: 'choose-table', params: { productID,bankId,productName,zqLocation }}"
      >{{productName}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{zqName}}</el-breadcrumb-item>
      <el-breadcrumb-item>手工填报</el-breadcrumb-item>
    </bread-crumb>
    <div class="content-body">
      <table-list :tables="tables" @listChanged="listChanged" manner="manual"></table-list>
      <div class="footer">
        <button
          class="btn btn-purge-data"
          @click="handleBtnPurgeDataClicker"
          v-if="hasItemsInList"
        >清除数据</button>
        <router-link
          :to="{ name: 'upload-table', params: { productID,zq }}"
          class="btn btn-default"
        >上传表格</router-link>
        <router-link
          :to="{name:'choose-table',params:{zqLocation},query:{zq}}"
          class="btn btn-primary"
        >提交数据</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import TableList from "../UploadTable/components/TableList";
import { getUserTables, clearUploadedTableforTest } from "@/api/table";
import { mapActions, mapState } from "vuex";
import { zq2name } from "@/utils/helper";

export default {
  data() {
    return {
      tables: {},
      tipDialogVisible: true, //刚开始进入的提示框

      correctDialogVisible: false, //控制手动修正的表的弹窗

      hasItemsInList: false
    };
  },
  components: {
    BreadCrumb,
    TableList
  },
  props: {
    zq: [Number, String],
    productName: String,
    productID: [Number, String],
    bankId: String,
    zqLocation: [Number, String],
    uuid: String
  },
  computed: {
    //...mapState('UploadTable',["zq"]),
    ...mapState(["companyInfo", "authorization"]),
    zqName: function() {
      return zq2name(this.zq);
    }
  },
  async mounted() {
    //如果没有permission id就退出
    if (!this.$store.state.UploadTable.permissionID) {
      //this.$alert("权限校验已过期，请重新操作").then(()=>{
      this.$router.push({ name: "test-hub" });
      //})
    }

    this.$store.commit("UploadTable/setProduct", {
      name: this.productName,
      id: this.productID,
      bankID: this.bankId
    });
    this.$store.commit("UploadTable/setZq", { zq: this.zq });
    await this.$store.cache.dispatch("loadCompanyInfo");
    await this.loadList();
  },
  methods: {
    listChanged() {
      this.loadList();
    },
    //清空所有已经上传的报表
    handleBtnPurgeDataClicker() {
      this.$confirm(`所有文件将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const result = await clearUploadedTableforTest(
          this.companyInfo.nsrsbm,
          this.zq,
          this.authorization
        );

        if (result.msgCode == 1) {
          this.$emit("listChanged");
          this.$message({
            type: "success",
            message: "删除成功!"
          });
          this.loadList();
        } else {
          this.$message.error(result.msgInfo);
        }
      });
    },
    //修正表格的弹出框
    showCorrectDialog() {
      this.correctDialogVisible = true;
    },
    //错误原因弹出框
    showUploadErrorDialog() {
      this.uploadErrorDialogVisible = true;
    },
    submitData() {
      this.correctDialogVisible = true;
    },
    async loadList() {
      const tables = await getUserTables(
        this.bankId,
        this.$route.params.version,
        this.companyInfo.nsrsbm,
        this.zq,
        this.uuid
      );

      this.tables = {};

      this.hasItemsInList = false;
      for (const grpName in tables.data) {
        const grpData = tables.data[grpName];
        const newGrp = [];
        for (const table of grpData.tableInfo) {
          const {
            tableNameCn,
            tableNameEn: templateId,
            uploadStatus: status,
            tableNum
          } = table;
          const item = {
            name: tableNameCn,
            status,
            templateId,
            tableNum
          };

          if (status != "未上传") {
            this.hasItemsInList = true;
          }

          newGrp.push(item);
        }

        if (newGrp.length > 0) {
          this.tables[grpName] = newGrp;
        }
      }
    },
    async onUpload(file, data) {
      //如果有错误的表格则弹出弹出框
      if (data.data.errorData.length > 0) {
        this.uploadErrors = data.data.errorData;
        this.showUploadErrorDialog();
      }

      const groups = data.data.succData;

      //根据上传的excel构建本地对应的数组

      //上传文件后需要纠错的表格信息
      const tables4correction = [];

      for (const groupName in groups) {
        const tables = groups[groupName]; //分组里包含的表

        for (const table of tables) {
          const status = table.data.uploadStatus;
          const { templateId, sysFileName, userFileName } = table;

          if (status === "待修改") {
            table.data.templateId = templateId;
            table.data.groupName = groupName;
            table.data.sysFileName = sysFileName;
            table.data.userFileName = userFileName;
            tables4correction.push(table.data);
          }
        }
      }
      if (tables4correction.length > 0) {
        this.$store.commit("UploadTable/setCorrectionTableData", {
          tables4correctionData: tables4correction
        });
        this.showCorrectDialog();
      }
      this.loadList();
    }
  }
};
</script>

<style lang="stylus" scoped>
.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 20px;
}

.footer {
  margin-top: 20px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 10px;
  }

  .btn-purge-data {
    color: #ff6464;
  }
}
</style>
