<template>
  <div class="container">
    <div class="item">
      <div class="item1">客户数量</div>
      <div class="item2" v-if="statistics">{{ statistics.totalConsumer }}</div>
    </div>
    <div class="item">
      <div class="item1">项目数量</div>
      <div class="item2" v-if="statistics">{{ statistics.totalProject }}</div>
    </div>
    <div class="item">
      <div class="item1">已检测次数 / 剩余检测次数</div>
      <div class="item2" v-if="statistics">
        {{ statistics.totalRecord }}/{{ statistics.checkNumber }}
      </div>
    </div>
    <div class="itemIndex" v-if="haveExamine" @click="toReport">
      <div class="itemIndexTitle"></div>
      <div class="itemLogo">
        <img src="@/assets/brand/index-logo.png" alt />
      </div>
      <div>
        <div class="itemindexNum" v-if="indexToDoList">
          {{ indexToDoList.totalReviewRecord }}
        </div>
        <div>待审核报告</div>
      </div>
    </div>
    <div class="itemIndex" v-if="haveTask" @click="toTestLog">
      <div class="itemIndexTitle"></div>
      <div class="itemLogo">
        <img src="@/assets/brand/index-logo-2.png" alt />
      </div>
      <div>
        <div class="itemindexNum" v-if="indexToDoList">
          {{ indexToDoList.totalModifyRecord }}
        </div>
        <div>待办任务</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statistics: Object,
    indexToDoList: Object,
  },
  data() {
    return {
      haveExamine: true,
      haveTask: true,
    };
  },
  created() {
    // 查看是否有审核权限
    this.checkhaveExamine(22);
    // 查看是否有检测记录权限
    this.checkhaveTask(16);
  },
  methods: {
    // 权限方法
    checkAuthMethod(id) {
      let getUserAuth = JSON.parse(localStorage.getItem("user")).authorities;
      if (getUserAuth.length == 0) {
        return "none";
      }
      this.roleList = JSON.parse(localStorage.getItem("user")).authorities[0].roleList;
      let roleListId = this.roleList.map((item) => {
        return item.id;
      });
      if (roleListId.indexOf(id) == -1) {
        return false;
      } else {
        return true;
      }
    },
    // 判断是否有审核权限
    checkhaveExamine(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveExamine = false;
      }
    },
    // 判断是否有检测记录权限
    checkhaveTask(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveTask = false;
      }
    },
    toTestLog() {
      this.$router.push({ name: "test-log" });
    },
    toReport() {
      this.$router.push({ name: "examine" });
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  margin: 24px 14px 0 30px;
}

.item {
  flex: 1;
  height: 110px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-right: 16px;
}

.itemIndex {
  position: relative;
  flex: 1;
  height: 110px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  cursor: pointer;
}

.itemIndex:hover {
  transform: scale(1.05);
}

.itemLogo {
  margin-right: 16px;
}

.itemindexNum {
  font-size: 32px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
}

.item1 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 22px;
  margin: 24px 0 0 24px;
}

.item2 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 38px;
  margin: 4px 0 0 24px;
}

.item3 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 20px;
  margin: 16px 0 0 24px;
}

.itemIndexTitle {
  position: absolute;
  top: 0;
  width: 100%;
  height: 8px;
  background: rgba(250, 173, 20, 1);
}
</style>
