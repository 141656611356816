export const veriFiItem = (person, type, persons) => {
  if (!person.nsrsbh) {
    let limit = {
      code: 0,
      status: 0
    }
    return limit;
  }

  if (
    person.nsrsbh.trim().length > 20 ||
    person.nsrsbh.trim().length < 15
  ) {
    let limit = {
      code: 1,
      status: 0
    }
    return limit;
  }

  var uPattern = /^[a-zA-Z0-9_]{0,}$/;
  if (!uPattern.test(person.nsrsbh)) {
    let limit = {
      code: 1,
      status: 0
    }
    return limit;
  }

  if (!person.companyName) {
    let limit = {
      code: 2,
      status: 0
    }
    return limit;
  }

  if (type == 1 && !person.area) {
    let limit = {
      code: 3,
      status: 0
    }
    return limit;
  }

  if (type == 2 && !person.cityCode) {
    let limit = {
      code: 3,
      status: 0
    }
    return limit;
  }

  if (type == 3 && !persons.area) {
    let limit = {
      code: 3,
      status: 0
    }
    return limit;
  }
  
  if (!person.industry) {
    let limit = {
      code: 4,
      status: 0
    }
    return limit;
  }
  let limit = {
    code: 100,
    status: 1
  }
  return limit;
}

// 地区转换
export const area = (city, cityData) => {
  if (!city) {
    let info = "暂无地区信息";
    return info;
  }
  let info = {};
  for (var i = 0; i < cityData.length; i++) {
    if (city[0] == cityData[i].value) {
      info.pro = cityData[i].label
      for (var j = 0; j < cityData[i].children.length; j++) {
        if (city[1] == cityData[i].children[j].value) {
          info.city = cityData[i].children[j].label
          for (var k = 0; k < cityData[i].children[j].children.length; k++) {
            if (city[2] == cityData[i].children[j].children[k].value) {
              info.district = cityData[i].children[j].children[k].label
            }
          }
        }

      }
    }
  }
  return info.pro + "/" + info.city + "/" + info.district;
}

// 校验修改客户信息
export const veriFiItemAdds = (person, persons) => {
  if (!person.companyName) {
    let limit = {
      code: 0,
      status: 0
    }
    return limit;
  }
  if (!person.nsrsbh) {
    let limit = {
      code: 1,
      status: 0
    }
    return limit;
  }
  if (!person.scale) {
    let limit = {
      code: 2,
      status: 0
    }
    return limit;
  }

  if (!persons.area) {
    let limit = {
      code: 3,
      status: 0
    }
    return limit;
  }
  person.kjzd = "100"
  if (!person.kjzd) {
    let limit = {
      code: 4,
      status: 0
    }
    return limit;
  }
  if (!person.industry) {
    let limit = {
      code: 5,
      status: 0
    }
    return limit;
  }

  let limit = {
    code: 100,
    status: 1
  }
  return limit;
}
