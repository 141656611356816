<template>
    <div class="abnormaltax">
        <bread-crumb>
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>异常纳税企业</el-breadcrumb-item>
        </bread-crumb>
        <div id="setuser" >
             <div class="qymc">异常纳税企业</div>
            <el-card>
                <div class="mdf_content compant_mdf">
                    <div class="mdf_c1">
                        <span>查询信息：</span>
                        <el-input v-model="nsrxx" :maxlength='20'  placeholder="公司名称/社会统一信用代码/法人" class="mdf_input com_input"></el-input>
                         <el-button type="primary" class="nowbtn" @click="btn">马上查询</el-button>
                </div>
            </div>
            </el-card>
           
           <div v-if="list && list.length!=0" class="qiyemany">搜索到<span>{{total}}</span>家企业</div>
            <el-card v-if="list && list.length!=0" class="paddingno"  v-loading="loading">
                <div class="normal_content">
                    <!-- 查询异常 -->
                        <div >
                                <el-table
                                    :data="list"
                                    id="nsrtab"
                                    style="width: 100%">
                                        <el-table-column prop="nsrdzdah" label="公司名称">
                                            <template slot-scope="scope">
                                                <span>{{scope.row.ycnsr_hz.nsrmc}}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="nsrsbh" label="社会统一信用代码">
                                            <template slot-scope="scope">
                                                <span>{{scope.row.ycnsr_hz.nsrsbh}}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="nsrmc" label="法定代表人">
                                            <template slot-scope="scope">
                                                <span>{{scope.row.ycnsr_hz.fddbrmc}}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="fddbrmc" label="操作" width="180">
                                            <template slot-scope="scope">
                                            <el-button type="text" @click="lookdetail(scope.row)">查看</el-button>
                                            </template>
                                        </el-table-column>
                        </el-table>
                    </div>
                    <el-pagination
                            v-if="total"
                            background
                            class="page"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page.sync="currentPage"                       
                            layout="total, prev, pager, next, jumper"
                            :page-size="size"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
            <el-card  v-if="list.length == 0 " class="topjl">
               <div class="endS">
                    <div class="nosearch" v-if="number==1">
                        <img src="@/assets/util/wsearcg.png" alt="">    
                        <div>您还没有进行搜索哦～</div>
                    </div> 
                    <div class="nosearch"  v-if="number==2">
                        <img src="@/assets/util/search2.png" alt="">    
                        <div>未搜索到公司信息，请检查搜索内容或当前搜索公司无纳税异常</div>
                    </div> 
                     <div class="nosearch"  v-if="number==3">
                        <img src="@/assets/util/search1.png" alt="">    
                        <div>您的搜索词太广泛，建议更换搜索词</div>
                    </div> 
                </div>
           </el-card>
           </div>
        </div>
    </div>
</template>
<script>
import {getycmessage,nsrSearch} from '../../api/api'
import BreadCrumb from "@/components/BreadCrumb";
export default {
  components: {
    BreadCrumb
  },
   data(){
        return{
            list:[],
            nsrsbh:'',
            jbxx:[],
            nsycxx:[],
            sswfaj:[],
            sorryShow:false,
            nsrmc:'',
            nsrxx:'',
            istrue:false,
            number:1,
            pageNo:0,
            total:0,
            currentPage:1,
            size:20,
            loading:false
        }
    },
    mounted(){
        if(this.$route.query.name){
            this.list = ['999']
             this.loading = true;
            this.nsrxx = this.$route.query.name;
             const para = {
                nsrxx:this.$route.query.name,
                token:JSON.parse(localStorage.getItem('token')).token,
                pageNo:this.$route.query.page,
                pageSize:'20'
            }
            nsrSearch(para).then(res =>{
                if(res.msgCode == 1){
                     this.loading = false;
                    this.number = 0;
                    this.list = res.data;
                    this.total = res.totalCount;
                }else if(res.msgCode == -1){
                    this.list = [];
                    this.loading = false;
                    this.number = 2;
                    return
                }else if(res.msgCode == -3){
                    this.loading = false;
                    this.number = 3;
                    this.list = [];
                    return;
                }
            })
        }
    },
    methods:{
         //分页
            handleSizeChange(val) {
                  this.pageNo=val-1;
                  this.getList();
            },
            handleCurrentChange(val) {
                  this.pageNo=val-1;
                  this.getList();
            },
        btn(){
            this.jbxx = [];
            this.nsycxx = [];
            this.sswfaj = [];
            this.sorryShow = false;
            if(this.nsrxx){
              this.getList()
            }else{
                this.number = 1;
                this.list = [];
            }
        },
        lookdetail(data){
            this.$router.push({name:'abnormal_msg',query:{params:data,name:this.nsrxx,page:this.pageNo,number:0}})
        },
        getList(){
            this.loading = true;
             if (!isNaN(this.nsrxx) && this.nsrxx.length < 5 || this.nsrxx.length > 20) {
                 this.$message.error('请输入5位以上（包含5位）的社会统一信用代码~');
                 return
             }else if(isNaN(this.nsrxx) && this.nsrxx.length < 2){
                  this.$message.error('请输入2位以上（包含2位）企业名称或法人~');
                 return
             }
            const para = {
                nsrxx:this.nsrxx,
                token:JSON.parse(localStorage.getItem('token')).token,
                pageNo:this.pageNo,
                pageSize:'20'
            }
            nsrSearch(para).then(res =>{
                if(res.msgCode == 1){
                    this.loading = false;
                    this.number = 0;
                    this.list = res.data;
                    this.total = res.totalCount;

                }else if(res.msgCode == -1){
                    this.loading = false;
                    this.number = 2;
                    this.list = [];
                    // this.$message.error(res.msgInfo);
                    return
                }else if(res.msgCode == -3){
                     this.loading = false;
                    this.number = 3;
                    this.list = [];
                    return;
                }
                
            })
        }
    }
};
</script>
<style src="@/styles/index.scss" lang="scss" scoped></style>
<style lang="stylus" scoped>
.paddingno{
    >>>.el-card__body{
        padding:20px 0;
    }
}
.qiyemany{
    font-size:16px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(0,0,0,0.4);
    line-height:22px;
    margin:16px 0 8px 0;
    span{
        color:#007aff;
        margin:0 5px;
    }
}


.page{
    float:right;
    margin-top:30px;
}


.mdf_input {
    margin-left:10px !important;
}
.abnormaltax{
     .compant_mdf{
        height: 44px;
        min-height: 44px;
        margin-left :20px;
    }
    #setuser{
        padding:10px;
        margin:0px auto 30px auto;
        width:96%;
        // background:#fff;
    }
}
.normal_content{
    width: 100%;
    margin: 0 auto 80px auto;
    .navtitle{
        line-height: 50px;
        height: 50px;
        font-size: 16px;
        color: #888A88;
        text-align: left;
    }
    #nsrtab{
        text-indent:40px;
    }
   >>> #nsrtab .el-table__header-wrapper .el-table__header .has-gutter tr{
        color:rgba(0,0,0,0.8);
       .cell{
            text-indent:0px;
        } 
    }
    #nsrtab .el-table__body-wrapper .el-table__body tbody tr{
        color: #1a1a1a;
    }
    .nsrcsscenter .el-table__header-wrapper .el-table__header .has-gutter tr th{
        background: #fff;
        
    }
    .nsrcsscenter .el-table__header-wrapper .el-table__header .has-gutter tr .el-table_3_column_11    {
        text-align: center
    }
    .paddingt{
        margin-top:40px;
        margin-bottom: 15px !important;
    }
    .nsr_simg{
        width: 200px;
        height: 200px;
        padding: 50px 0 20px 0;
    }
    .nsr_stitle{
        color: #000000;
        font-size: 28px;
        font-weight: bold;
    }
    .nsr_wyc{
        text-align: center;
        height: 300px;
        width: 90%;
        margin: 0 auto 50px auto;
        text-align: left;
        div{
            float: left;
            img{
                width: 230px;
                padding: 50px 30px;
            }
        }
        span{
            float: left;
            font-size:22px;
            font-family:Microsoft YaHei;
            font-weight:bold;
            color:rgba(6,35,64,1);
            display: inline-block;
            line-height: 300px;
        }
    }
    .nsr_wyctitle{
        color: #000000;
        font-size: 28px;
        font-weight: bold;
        line-height: 300px;
        display: inline-block;
        width: 50%;
        text-align: left;
        float: right;
    }
    .resultcontain1{
        background: #ffffff;
        -webkit-box-shadow: 0 0 15px 0 #dce3f2;
        -moz-box-shadow: 0 0 15px 0 #dce3f2;
        box-shadow: 0 0 15px 0 #dce3f2;
        border-radius: 10px;
        margin-top: 40px;
    }
    .nsr_title{
        font-size: 28px;
        line-height: 90px;
        border-bottom: 1px solid #C6C6C6;
        text-indent: 40px;
        color: #000000;
    }
    .nsr_box{
        padding: 40px;
    }
    .nsr_box1{
        height: 25px;
    }
    .nsr_img{
        float: left;
    }
    .nsr_sbh{
        color: #2644F7;
        font-size: 15px;
        display: inline-block;
        float: left;
        margin-left: 5px;
        font-weight: Bold;
        line-height: 25px;
    }
    .nsr_input{
        width: 100%;
    }
    .nsr_input input{
        background-image: none;
        border-bottom: 1px solid #dcdcdc;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0 ;
        height: 60px;
        line-height: 60px;
        font-size: 20px;
        color: #C0C4CC;
        font-family: 'MicrosoftYaHei'
    }
    .nsr_input input:after{
        color: #1A1A1A;
    }
    .nsr_nowjs,.nsr_nowjs:focus,.nsr_nowjs:hover{
        width: 80%;
        height: 55px;
        vertical-align: middle;
        border-radius: 27.5px;
        background-color: #2644F7;
        color: #fff;
        font-size: 18px;
        line-height: 25px;
        margin-top: 25px;
    }
}
</style>