const  gz_2016 = [
    {"name":"北京市","gongzi":"122749"},
    {"name":"上海市","gongzi":"120503"},
    {"name":"拉萨市","gongzi":"111009"},
    {"name":"日喀则市","gongzi":"95598"},
    {"name":"南京市","gongzi":"90191"},
    {"name":"深圳市","gongzi":"89757"},
    {"name":"广州市","gongzi":"89096"},
    {"name":"天津市","gongzi":"87806"},
    {"name":"杭州市","gongzi":"87153"},
    {"name":"克拉玛依市","gongzi":"87001"},
    {"name":"衢州市","gongzi":"85527"},
    {"name":"丽水市","gongzi":"85058"},
    {"name":"无锡市","gongzi":"84931"},
    {"name":"宁波市","gongzi":"83656"},
    {"name":"常州市","gongzi":"81058"},
    {"name":"舟山市","gongzi":"80311"},
    {"name":"苏州市","gongzi":"79870"},
    {"name":"铜仁市","gongzi":"78809"},
    {"name":"长沙市","gongzi":"77782"},
    {"name":"济南市","gongzi":"77012"},
    {"name":"青岛市","gongzi":"76616"},
    {"name":"遵义市","gongzi":"75480"},
    {"name":"珠海市","gongzi":"74931"},
    {"name":"鄂尔多斯市","gongzi":"74496"},
    {"name":"成都市","gongzi":"74408"},
    {"name":"嘉兴市","gongzi":"73836"},
    {"name":"大连市","gongzi":"73764"},
    {"name":"乌鲁木齐市","gongzi":"73254"},
    {"name":"东营市","gongzi":"73129"},
    {"name":"金华市","gongzi":"72962"},
    {"name":"廊坊市","gongzi":"72744"},
    {"name":"大庆市","gongzi":"72459"},
    {"name":"武汉市","gongzi":"71963"},
    {"name":"南通市","gongzi":"71743"},
    {"name":"固原市","gongzi":"71168"},
    {"name":"合肥市","gongzi":"71054"},
    {"name":"银川市","gongzi":"70840"},
    {"name":"贵阳市","gongzi":"70535"},
    {"name":"温州市","gongzi":"70069"},
    {"name":"西安市","gongzi":"69611"},
    {"name":"厦门市","gongzi":"69218"},
    {"name":"镇江市","gongzi":"68874"},
    {"name":"南宁市","gongzi":"68560"},
    {"name":"长春市","gongzi":"68434"},
    {"name":"昆明市","gongzi":"68375"},
    {"name":"攀枝花市","gongzi":"68152"},
    {"name":"福州市","gongzi":"67630"},
    {"name":"扬州市","gongzi":"67611"},
    {"name":"沈阳市","gongzi":"67444"},
    {"name":"重庆市","gongzi":"67386"},
    {"name":"佛山市","gongzi":"67187"},
    {"name":"德阳市","gongzi":"67166"},
    {"name":"兰州市","gongzi":"67011"},
    {"name":"嘉峪关市","gongzi":"66925"},
    {"name":"安顺市","gongzi":"66742"},
    {"name":"清远市","gongzi":"66648"},
    {"name":"广安市","gongzi":"66034"},
    {"name":"普洱市","gongzi":"66033"},
    {"name":"南昌市","gongzi":"65812"},
    {"name":"张家界市","gongzi":"65363"},
    {"name":"乌兰察布市","gongzi":"65280"},
    {"name":"榆林市","gongzi":"65265"},
    {"name":"儋州市","gongzi":"65085"},
    {"name":"马鞍山市","gongzi":"65060"},
    {"name":"湖州市","gongzi":"65051"},
    {"name":"三亚市","gongzi":"65030"},
    {"name":"六盘水市","gongzi":"65015"},
    {"name":"太原市","gongzi":"64820"},
    {"name":"台州市","gongzi":"64816"},
    {"name":"中山市","gongzi":"64790"},
    {"name":"惠州市","gongzi":"64766"},
    {"name":"海东市","gongzi":"64761"},
    {"name":"三明市","gongzi":"64324"},
    {"name":"丽江市","gongzi":"64304"},
    {"name":"广元市","gongzi":"64237"},
    {"name":"烟台市","gongzi":"64219"},
    {"name":"包头市","gongzi":"63987"},
    {"name":"宣城市","gongzi":"63622"},
    {"name":"绵阳市","gongzi":"63618"},
    {"name":"延安市","gongzi":"63562"},
    {"name":"毕节市","gongzi":"63487"},
    {"name":"昭通市","gongzi":"63410"},
    {"name":"自贡市","gongzi":"62588"},
    {"name":"哈尔滨市","gongzi":"62583"},
    {"name":"贺州市","gongzi":"62110"},
    {"name":"漳州市","gongzi":"62093"},
    {"name":"吴忠市","gongzi":"62064"},
    {"name":"海口市","gongzi":"62030"},
    {"name":"秦皇岛市","gongzi":"61988"},
    {"name":"淄博市","gongzi":"61928"},
    {"name":"潍坊市","gongzi":"61815"},
    {"name":"中卫市","gongzi":"61689"},
    {"name":"庆阳市","gongzi":"61669"},
    {"name":"铜陵市","gongzi":"61501"},
    {"name":"韶关市","gongzi":"61465"},
    {"name":"滁州市","gongzi":"61436"},
    {"name":"宜宾市","gongzi":"61399"},
    {"name":"绍兴市","gongzi":"61394"},
    {"name":"芜湖市","gongzi":"61385"},
    {"name":"江门市","gongzi":"61366"},
    {"name":"宁德市","gongzi":"61309"},
    {"name":"连云港市","gongzi":"61262"},
    {"name":"河池市","gongzi":"61259"},
    {"name":"石家庄市","gongzi":"61189"},
    {"name":"郑州市","gongzi":"61149"},
    {"name":"泰州市","gongzi":"61069"},
    {"name":"西宁市","gongzi":"61069"},
    {"name":"玉溪市","gongzi":"60408"},
    {"name":"柳州市","gongzi":"60228"},
    {"name":"梅州市","gongzi":"60191"},
    {"name":"临沂市","gongzi":"60039"},
    {"name":"来宾市","gongzi":"59982"},
    {"name":"淮安市","gongzi":"59642"},
    {"name":"临沧市","gongzi":"59626"},
    {"name":"唐山市","gongzi":"59623"},
    {"name":"赤峰市","gongzi":"59602"},
    {"name":"鹰潭市","gongzi":"59595"},
    {"name":"肇庆市","gongzi":"59591"},
    {"name":"南平市","gongzi":"59537"},
    {"name":"株洲市","gongzi":"59423"},
    {"name":"龙岩市","gongzi":"59329"},
    {"name":"黄山市","gongzi":"59325"},
    {"name":"桂林市","gongzi":"59129"},
    {"name":"六安市","gongzi":"59107"},
    {"name":"日照市","gongzi":"59095"},
    {"name":"滨州市","gongzi":"58659"},
    {"name":"淮南市","gongzi":"58543"},
    {"name":"呼伦贝尔市","gongzi":"58386"},
    {"name":"乌海市","gongzi":"58301"},
    {"name":"盐城市","gongzi":"58205"},
    {"name":"沧州市","gongzi":"58122"},
    {"name":"威海市","gongzi":"57812"},
    {"name":"张掖市","gongzi":"57708"},
    {"name":"东莞市","gongzi":"57649"},
    {"name":"济宁市","gongzi":"57362"},
    {"name":"徐州市","gongzi":"57228"},
    {"name":"泉州市","gongzi":"57141"},
    {"name":"茂名市","gongzi":"57059"},
    {"name":"通辽市","gongzi":"57029"},
    {"name":"石嘴山市","gongzi":"56989"},
    {"name":"湘潭市","gongzi":"56619"},
    {"name":"莆田市","gongzi":"56548"},
    {"name":"河源市","gongzi":"56513"},
    {"name":"九江市","gongzi":"56331"},
    {"name":"呼和浩特市","gongzi":"56213"},
    {"name":"武威市","gongzi":"56148"},
    {"name":"酒泉市","gongzi":"56144"},
    {"name":"泸州市","gongzi":"56048"},
    {"name":"蚌埠市","gongzi":"56015"},
    {"name":"益阳市","gongzi":"55880"},
    {"name":"汕头市","gongzi":"55867"},
    {"name":"宿迁市","gongzi":"55844"},
    {"name":"汉中市","gongzi":"55739"},
    {"name":"平凉市","gongzi":"55693"},
    {"name":"湛江市","gongzi":"55565"},
    {"name":"郴州市","gongzi":"55560"},
    {"name":"北海市","gongzi":"55502"},
    {"name":"泰安市","gongzi":"55493"},
    {"name":"晋城市","gongzi":"55453"},
    {"name":"大同市","gongzi":"55450"},
    {"name":"防城港市","gongzi":"55409"},
    {"name":"百色市","gongzi":"55364"},
    {"name":"吕梁市","gongzi":"55357"},
    {"name":"常德市","gongzi":"55317"},
    {"name":"遂宁市","gongzi":"55279"},
    {"name":"赣州市","gongzi":"55253"},
    {"name":"聊城市","gongzi":"55223"},
    {"name":"南充市","gongzi":"55202"},
    {"name":"贵港市","gongzi":"55121"},
    {"name":"池州市","gongzi":"55068"},
    {"name":"牡丹江市","gongzi":"55054"},
    {"name":"资阳市","gongzi":"55033"},
    {"name":"莱芜市","gongzi":"55014"},
    {"name":"怀化市","gongzi":"54835"},
    {"name":"云浮市","gongzi":"54780"},
    {"name":"定西市","gongzi":"54735"},
    {"name":"抚州市","gongzi":"54607"},
    {"name":"新余市","gongzi":"54602"},
    {"name":"辽阳市","gongzi":"54507"},
    {"name":"巴彦淖尔市","gongzi":"54427"},
    {"name":"汕尾市","gongzi":"54425"},
    {"name":"保定市","gongzi":"54395"},
    {"name":"德州市","gongzi":"54390"},
    {"name":"雅安市","gongzi":"54361"},
    {"name":"上饶市","gongzi":"54322"},
    {"name":"阳江市","gongzi":"54245"},
    {"name":"乐山市","gongzi":"54222"},
    {"name":"金昌市","gongzi":"53996"},
    {"name":"崇左市","gongzi":"53954"},
    {"name":"潮州市","gongzi":"53940"},
    {"name":"白银市","gongzi":"53848"},
    {"name":"枣庄市","gongzi":"53793"},
    {"name":"铜川市","gongzi":"53652"},
    {"name":"承德市","gongzi":"53449"},
    {"name":"吉林市","gongzi":"53392"},
    {"name":"宝鸡市","gongzi":"53366"},
    {"name":"曲靖市","gongzi":"53270"},
    {"name":"晋中市","gongzi":"53196"},
    {"name":"保山市","gongzi":"53186"},
    {"name":"永州市","gongzi":"53085"},
    {"name":"达州市","gongzi":"53081"},
    {"name":"安庆市","gongzi":"52954"},
    {"name":"玉林市","gongzi":"52771"},
    {"name":"阜阳市","gongzi":"52718"},
    {"name":"抚顺市","gongzi":"52708"},
    {"name":"双鸭山市","gongzi":"52648"},
    {"name":"梧州市","gongzi":"52479"},
    {"name":"吉安市","gongzi":"52402"},
    {"name":"萍乡市","gongzi":"52389"},
    {"name":"邵阳市","gongzi":"52334"},
    {"name":"张家口市","gongzi":"52277"},
    {"name":"巴中市","gongzi":"52213"},
    {"name":"陇南市","gongzi":"52205"},
    {"name":"安康市","gongzi":"52195"},
    {"name":"钦州市","gongzi":"52127"},
    {"name":"黑河市","gongzi":"51422"},
    {"name":"朔州市","gongzi":"51377"},
    {"name":"洛阳市","gongzi":"51295"},
    {"name":"长治市","gongzi":"51070"},
    {"name":"渭南市","gongzi":"51057"},
    {"name":"菏泽市","gongzi":"50979"},
    {"name":"天水市","gongzi":"50861"},
    {"name":"邯郸市","gongzi":"50847"},
    {"name":"衡阳市","gongzi":"50768"},
    {"name":"葫芦岛市","gongzi":"50711"},
    {"name":"襄阳市","gongzi":"50677"},
    {"name":"四平市","gongzi":"50676"},
    {"name":"松原市","gongzi":"50592"},
    {"name":"齐齐哈尔市","gongzi":"50558"},
    {"name":"临汾市","gongzi":"50482"},
    {"name":"衡水市","gongzi":"50294"},
    {"name":"亳州市","gongzi":"50292"},
    {"name":"邢台市","gongzi":"50205"},
    {"name":"佳木斯市","gongzi":"50020"},
    {"name":"运城市","gongzi":"49912"},
    {"name":"景德镇市","gongzi":"49909"},
    {"name":"宜春市","gongzi":"49614"},
    {"name":"阜新市","gongzi":"49601"},
    {"name":"宿州市","gongzi":"49356"},
    {"name":"锦州市","gongzi":"49162"},
    {"name":"岳阳市","gongzi":"49009"},
    {"name":"开封市","gongzi":"48807"},
    {"name":"鹤岗市","gongzi":"48745"},
    {"name":"眉山市","gongzi":"48714"},
    {"name":"七台河市","gongzi":"48695"},
    {"name":"咸阳市","gongzi":"48542"},
    {"name":"娄底市","gongzi":"48530"},
    {"name":"淮北市","gongzi":"48266"},
    {"name":"许昌市","gongzi":"48235"},
    {"name":"阳泉市","gongzi":"48195"},
    {"name":"鞍山市","gongzi":"48164"},
    {"name":"揭阳市","gongzi":"48156"},
    {"name":"鸡西市","gongzi":"47866"},
    {"name":"朝阳市","gongzi":"47846"},
    {"name":"商丘市","gongzi":"47508"},
    {"name":"通化市","gongzi":"47506"},
    {"name":"商洛市","gongzi":"47481"},
    {"name":"南阳市","gongzi":"47468"},
    {"name":"白山市","gongzi":"47294"},
    {"name":"忻州市","gongzi":"47265"},
    {"name":"宜昌市","gongzi":"47193"},
    {"name":"营口市","gongzi":"46999"},
    {"name":"辽源市","gongzi":"46910"},
    {"name":"平顶山市","gongzi":"46759"},
    {"name":"本溪市","gongzi":"46662"},
    {"name":"三门峡市","gongzi":"46479"},
    {"name":"焦作市","gongzi":"46410"},
    {"name":"周口市","gongzi":"46355"},
    {"name":"濮阳市","gongzi":"46227"},
    {"name":"十堰市","gongzi":"46056"},
    {"name":"安阳市","gongzi":"45734"},
    {"name":"绥化市","gongzi":"45545"},
    {"name":"信阳市","gongzi":"45413"},
    {"name":"内江市","gongzi":"45234"},
    {"name":"驻马店市","gongzi":"44830"},
    {"name":"白城市","gongzi":"44619"},
    {"name":"黄石市","gongzi":"44549"},
    {"name":"新乡市","gongzi":"44237"},
    {"name":"随州市","gongzi":"44226"},
    {"name":"铁岭市","gongzi":"43596"},
    {"name":"荆门市","gongzi":"43245"},
    {"name":"荆州市","gongzi":"43111"},
    {"name":"盘锦市","gongzi":"42944"},
    {"name":"孝感市","gongzi":"42666"},
    {"name":"鹤壁市","gongzi":"42424"},
    {"name":"黄冈市","gongzi":"41497"},
    {"name":"漯河市","gongzi":"41389"},
    {"name":"鄂州市","gongzi":"41189"},
    {"name":"咸宁市","gongzi":"41019"},
    {"name":"丹东市","gongzi":"38763"},
    {"name":"伊春市","gongzi":"36793"},
    ]
    export default gz_2016;