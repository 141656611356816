<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>小工具</el-breadcrumb-item>
          <el-breadcrumb-item>审计小工具</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">审计小工具</template>
      </main-header>
    </div>
    <div class="filter-body">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="form-item">
            <div class="label">企业名称:</div>
            <el-input
              v-model="filter.companyName"
              placeholder="请输入"
              clearable
              @input="searchGetAudiList"
            ></el-input>
          </div>
          <div class="form-item form-itemCode">
            <div class="label labelCode">纳税人识别号:</div>
            <el-input
              v-model="filter.taxNo"
              placeholder="请输入"
              clearable
              @input="searchGetAudiList"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">项目经理:</div>
            <el-input
              v-model="filter.projectManager"
              placeholder="请输入"
              clearable
              @input="searchGetAudiList"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">状态:</div>
            <el-select
              v-model="filter.showStatus"
              clearable
              placeholder="请选择"
              filterable
              @change="searchGetAudiList"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="btn-look-up ease">搜索</div>
    </div>
    <div class="addItem ease" @click="addTest">发起检测</div>

    <div class="content-body">
      <template>
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" sortable></el-table-column>
          <el-table-column
            prop="checkTime"
            label="检测时间"
            sortable
            min-width="14%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="companyName"
            label="企业名称"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="taxNo"
            label="纳税人识别号"
            min-width="18%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="projectManager"
            label="项目经理"
            min-width="10%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="showStatus"
            label="状态"
            :show-overflow-tooltip="true"
            min-width="10%"
          >
            <template slot-scope="scope">
              <span>{{ statusListArr[scope.row.showStatus] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="260px">
            <template slot-scope="scope">
              <!-- <span class="export">
                <a :href="exportInvoice(scope.row)">发票</a>
              </span> -->
              <span class="export" @click="getTableData(scope.row)">报表</span>
              <span class="export" @click="analysis(scope.row.programId)">查看分析结果</span>
              <span class="export">
                <a :href="exportInvoiceResult(scope.row)">导出分析结果</a></span
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <el-pagination
      class="pagination-low"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :current-page="pageno"
      layout="prev, pager, next, total, jumper, sizes"
      :total="totalNumber"
      :page-sizes="[10, 20, 50, 100]"
      :haveTotal="haveTotal"
    ></el-pagination>
    <tax-no @closeBox="closeBox" v-if="taxBox" @addBox="addBox"></tax-no>
  </div>
</template>

<script>
import {
  getCustomListNew,
  auditList,
  getexportInvoiceURL,
  getexportAnalysisResults,
} from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import taxNo from "./components/addTaxno";

export default {
  components: {
    MainHeader,
    BreadCrumb,
    taxNo,
  },

  data() {
    return {
      statusListArr: ["已生成", "已生成", "待生成", "检测失败"],
      statusList: [
        {
          value: "1",
          label: "已生成",
        },
        {
          value: "2",
          label: "待生成",
        },
        {
          value: "3",
          label: "检测失败",
        },
      ],
      haveTotal: true,
      totalNumber: 10, //总数
      totalElements: 0,
      pageno: 1,
      filter: {
        companyName: "",
        projectManager: "",
        taxNo: "",
        showStatus: "",
      },
      tableDataList: null,
      taxBox: false,
      pageSize: 10,
    };
  },

  mounted() {
    this.getAuditList();
  },

  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getAuditList();
    },
    getTableData(row) {
      const currentDate = new Date();
      const date = new Date();
      const year = date.getFullYear();
      this.$router.push({
        name: "toolsTables",
        params: {
          bankId: "ade3fa2e-7cdc-4fb2-8f19-af5259b84ed3",
          version: "18",
          nsrsbm: row.programId,
          zq: year - 1 + "004",
          uuid: row.programId,
          dataPeriod: 0,
        },
      });
    },
    exportInvoice(row) {
      const url = getexportInvoiceURL({
        taxNo: row.taxNo,
      });
      return url;
    },

    exportInvoiceResult(row) {
      const url = getexportAnalysisResults({
        programId: row.programId,
      });
      return url;
    },
    addBox(code) {
      let taxNo = code.code;
      this.$router.push({ path: "/tools/authorization", query: { taxNo } });
    },
    closeBox() {
      this.taxBox = false;
    },
    analysis(programId) {
      this.$router.push({ path: "/tools/analysisResult", query: { programId } });
    },
    addTest() {
      this.taxBox = true;
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageno = val;
      this.getAuditList();
    },
    searchGetAudiList() {
      this.pageno = 1;
      this.getAuditList();
    },
    // 获取客户列表
    getAuditList() {
      let params = {
        companyName: this.filter.companyName,
        projectManager: this.filter.projectManager,
        showStatus: this.filter.showStatus,
        taxNo: this.filter.taxNo,
        pageNum: this.pageno,
        pageSize: this.pageSize,
      };
      auditList(params).then((res) => {
        this.tableDataList = res.list;
        this.totalNumber = Number(res.pageTotal);
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

.filter-body >>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.filter-body {
  margin: 20px;
  padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .inputs-wrapper {
    width: 92%;

    .inputs-wraper-row {
      display: flex;
      flex-wrap: wrap;

      .form-item {
        margin-right: 30px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .label {
          white-space: nowrap;
          width: 69px;
          text-align: right;
          margin-right: 8px;
        }

        .labelCode {
          width: 100px;
        }

        display: flex;
      }

      .form-itemCode {
        width: 336px;
      }
    }
  }

  .btn-look-up {
    margin-right: 16px;
    width: 76px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #2F54EB;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.addItem {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 20px;
  cursor: pointer;
  display: inline-block;
  margin-right: 16px;
}

.addItem:hover {
  background: rgba(47, 34, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.filter-body >>> .form-item .el-input__icon {
  line-height: 36px;
  height: 36px;
}

.el-icon-edit-outline {
  color: #2f54eb;
  font-size: 18px;
  margin-left: 4px;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.pagination-low {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}

.filter-body >>>.el-select {
  width: 240px;
}

.filter-body >>>.el-input {
  width: 240px;
}

.filter-body >>>.el-range-editor.el-input__inner {
  width: 240px;
}

.filter-body >>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

.filter-body >>>.el-pagination__editor.el-input {
  width: 50px;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.filter-body >>>.el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

.filter-body >>>.el-input__icon {
  width: 14px;
}

.filter-body >>>.el-range-editor.el-input__inner {
  padding: 3px 8px;
}

.filter-body >>>.el-date-editor .el-range-separator {
  line-height: 29px;
}

.filter-body >>>.form-item .el-input__icon {
  line-height: 38px;
}

.export {
  cursor: pointer;
  color: #2f22eb;
  margin-right: 8px;
}
</style>
