<template>
  <div style="width: 100%" :style="{height:height}" v-loading="true" element-loading-text="拼命加载中">

  </div>
</template>

<script>
export default {
  name: "BigPowerLoading",
  props:{
    height:{
      type:String,
      default:'533px'
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
