<template>
  <div class="container">
    <div class="header">
      <div class="title">房屋销售</div>
      <div class="select">
        <div class="detail" @click="toTotal">已售房源统计</div>
        <div class="detail" @click="toTotalDetail">购房者明细</div>
      </div>
    </div>
    <div class="chartContainer">
      <div class="select-array">
        <div class="select-option">
          <el-select
            @change="typeListValueChange"
            v-model="typeListValue"
            placeholder="请选择"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="primary-class">
            <el-button type="primary" round @click="chooseBig" v-if="typeListValue == 1"
              >选择大类</el-button
            >
            <el-button type="primary" round @click="chooseSmall" v-if="typeListValue == 2"
              >选择小类</el-button
            >
          </div>
        </div>
        <div class="select-option">
          <el-select
            v-model="dimensionType"
            placeholder="请选择"
            @change="dimensionTypeChange"
          >
            <el-option
              v-for="item in dimensionTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-option">
          <el-select @change="imgValueChange" v-model="imgValue" placeholder="请选择">
            <el-option
              v-for="item in imgValueList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div v-if="pieData && pieData.length > 0">
      <div class="echart-img" v-show="imgValue == 1">
        <!-- <pie v-for="item in pieData" :ref="pieRef" :item="item"></pie> -->
        <div class="chart-container">
          <div class="main chart" v-for="(item, index) in pieData" :key="index">
            <div class="bar"></div>
            <div class="typeName">
              {{ item.typeName }}
            </div>
          </div>
        </div>
      </div>

      <div class="echart-img" v-show="imgValue == 2">
        <!-- <columnar ref="columnarRef"></columnar> -->
        <div class="chart-container">
          <div class="main chart" v-for="(item, index) in pieData" :key="index">
            <div class="bar2"></div>
            <div class="typeName">
              {{ item.typeName }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-data" @click="toaddData">请导入房源及销售数据</div>
    <div class="casc">
      <el-cascader
        :options="options"
        :props="props"
        clearable
        v-model="cascaderModel"
        @change="cascaderModelChange"
      ></el-cascader>
    </div>
    <div class="index-table">
      <el-table
        :data="fdcArrayData"
        :span-method="objectSpanMethod"
        border
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column prop="superClassName" label="房屋类型"> </el-table-column>
        <el-table-column prop="houseTypeName" label="房屋类型" v-if="colData[0].istrue">
        </el-table-column>
        <el-table-column
          prop="totalBuiltArea"
          label="总计面积(M²)"
          v-if="colData[1].istrue"
        >
        </el-table-column>
        <el-table-column
          prop="saleBuiltArea"
          label="已售面积(M²)"
          v-if="colData[2].istrue"
        >
        </el-table-column>
        <el-table-column
          prop="unSaleBuiltArea"
          label="未售面积(M²)"
          v-if="colData[3].istrue"
        >
        </el-table-column>
        <el-table-column
          prop="saleBuiltAreaProportion"
          label="面积已售比例"
          v-if="colData[4].istrue"
        >
        </el-table-column>
        <el-table-column prop="totalNumber" label="总计数量" v-if="colData[5].istrue">
        </el-table-column>
        <el-table-column prop="saleNumber" label="已售数量" v-if="colData[6].istrue">
        </el-table-column>
        <el-table-column prop="unSaleNumber" label="未售数量" v-if="colData[7].istrue">
        </el-table-column>
        <el-table-column
          prop="saleNumberProportion"
          label="数量已售比例"
          v-if="colData[8].istrue"
        >
        </el-table-column>
        <el-table-column prop="totalAmount" label="总金额(元)" v-if="colData[9].istrue">
        </el-table-column>
        <el-table-column prop="saleAmount" label="已售金额(元)" v-if="colData[10].istrue">
        </el-table-column>
        <el-table-column
          prop="unSaleAmount"
          label="未售金额(元)"
          v-if="colData[11].istrue"
        >
        </el-table-column>
        <el-table-column
          prop="saleAmountProportion"
          label="金额已售比例"
          v-if="colData[12].istrue"
        >
        </el-table-column>
        <el-table-column
          prop="targetUnitPrice"
          label="目标单价(元/M²)"
          v-if="colData[13].istrue"
        >
        </el-table-column>
        <el-table-column
          prop="salesUnitPrice"
          label="销售单价(元/M²)"
          v-if="colData[14].istrue"
        >
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="提示" :visible.sync="BigdialogVisible" width="30%">
      <el-checkbox-group v-model="bigCheckList" @change="change1">
        <el-checkbox v-for="city in bigList" :label="city.value" :key="city.value">{{
          city.label
        }}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="BigdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="BigdialogVisibleMethod">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="SmalldialogVisible" width="30%">
      <el-checkbox-group v-model="smallCheckList" @change="change1">
        <el-checkbox v-for="city in smallList" :label="city.value" :key="city.value">{{
          city.label
        }}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="SmalldialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="SmalldialogVisibleMethod">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getInComeTable, getHomeInComeImage } from "@/api/fri";
import pie from "./pie";
import columnar from "./columnar";
import echarts from "echarts";

export default {
  components: {
    pie,
    columnar,
  },
  data() {
    return {
      bigList: [
        {
          value: 1,
          label: "普通住宅",
        },
        {
          value: 2,
          label: "非普通住宅",
        },
        {
          value: 3,
          label: "商业类",
        },
        {
          value: 4,
          label: "其他类",
        },
      ],
      bigCheckList: [1, 2, 3, 4],
      smallList: [
        {
          value: 1,
          label: "普通住宅",
        },
        {
          value: 2,
          label: "别墅",
        },
        {
          value: 3,
          label: "洋房",
        },
        {
          value: 4,
          label: "排屋",
        },
        {
          value: 5,
          label: "普通住宅",
        },
        {
          value: 6,
          label: "办公楼",
        },
        {
          value: 7,
          label: "会所",
        },
        {
          value: 8,
          label: "公寓",
        },
        {
          value: 9,
          label: "地下商铺",
        },
        {
          value: 10,
          label: "车库",
        },
        {
          value: 11,
          label: "地下车库",
        },
        {
          value: 12,
          label: "地下车位",
        },
        {
          value: 13,
          label: "地上车位",
        },
        {
          value: 14,
          label: "阁楼",
        },
        {
          value: 15,
          label: "储藏室",
        },
        {
          value: 16,
          label: "地下储藏室",
        },
      ],
      smallCheckList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      BigdialogVisible: false,
      SmalldialogVisible: false,
      title: ["测试柱图1", "测试柱图2", "测试柱图3", "测试柱图4", "测试柱图5"], //标题列表
      color: ["#5B8FF9", "#ca2df5", "#42CE92", "#f96950", "#047ff5"], //颜色列表
      value: [66, 90, 45, 12, 70], //数据（x轴的大小）
      pieData: null,
      typeList: [
        { value: 1, label: "房屋类型大类" },
        { value: 2, label: "房屋类型小类" },
      ],
      typeListValue: 1,
      dimensionTypeList: [
        { value: 1, label: "按面积" },
        { value: 2, label: "按数量" },
        { value: 3, label: "按金额" },
        // { value: 4, label: "按单价" },
      ],
      dimensionType: 1,
      imgValueList: [
        { value: 1, label: "饼状图" },
        { value: 2, label: "柱状图" },
      ],
      imgValue: 1,
      reload: 1,
      cascaderModel: [
        [1],
        [2],
        [3],
        [4],
        [5],
        [6],
        [7],
        [8],
        [9],
        [10],
        [11],
        [12],
        [13],
        [14],
        [15],
      ],
      props: { multiple: true },
      options: [
        {
          value: 1,
          label: "房屋类型",
        },
        {
          value: 2,
          label: "总计面积(M²)",
        },
        {
          value: 3,
          label: "已售面积(M²)",
        },
        {
          value: 4,
          label: "未售面积(M²)",
        },
        {
          value: 5,
          label: "面积已售比例",
        },
        {
          value: 6,
          label: "总计数量",
        },
        {
          value: 7,
          label: "已售数量",
        },
        {
          value: 8,
          label: "未售数量",
        },
        {
          value: 9,
          label: "数量已售比例",
        },
        {
          value: 10,
          label: "总金额(元)",
        },
        {
          value: 11,
          label: "已售金额(元)",
        },
        {
          value: 12,
          label: "未售金额(元)",
        },
        {
          value: 13,
          label: "金额已售比例",
        },
        {
          value: 14,
          label: "目标单价(元/M²)",
        },
        {
          value: 15,
          label: "销售单价(元/M²)",
        },
      ],
      colData: [
        { title: "房屋类型", istrue: true },
        { title: "总计面积(M²)", istrue: true },
        { title: "已售面积(M²)", istrue: true },
        { title: "未售面积(M²)", istrue: true },
        { title: "面积已售比例", istrue: true },
        { title: "总计数量", istrue: true },
        { title: "已售数量", istrue: true },
        { title: "未售数量", istrue: true },
        { title: "数量已售比例", istrue: true },
        { title: "总金额(元)", istrue: true },
        { title: "已售金额(元)", istrue: true },
        { title: "未售金额(元)", istrue: true },
        { title: "未售面积(M²)", istrue: true },
        { title: "金额已售比例", istrue: true },
        { title: "目标单价(元/M²)", istrue: true },
        { title: "销售单价(元/M²)", istrue: true },
      ],
      fdcArrayData: null,
      mergeObj: {},
      mergeArr: [
        "superClassName",
        "houseTypeName",
        "totalBuiltArea",
        "saleBuiltArea",
        "unSaleBuiltArea",
        "saleBuiltAreaProportion",
        "totalNumber",
        "saleNumber",
        "unSaleNumber",
        "saleNumberProportion",
        "totalAmount",
        "saleAmount",
        "unSaleAmount",
        "saleAmountProportion",
        "targetUnitPrice",
        "salesUnitPrice",
      ],
      items: [1, 2, 3],
    };
  },
  mounted() {
    this.getInComeTableData();
    this.getHomeInComeImageList();
  },
  methods: {
    BigdialogVisibleMethod() {
      this.typeListValueChange();
      this.BigdialogVisible = false;
    },
    SmalldialogVisibleMethod() {
      this.typeListValueChange();
      this.SmalldialogVisible = false;
    },
    change1() {
      // console.log(this.bigCheckList);
    },
    chooseBig() {
      this.BigdialogVisible = true;
    },
    chooseSmall() {
      this.SmalldialogVisible = true;
    },
    draw_Bar() {
      var myEchart = document.getElementsByClassName("bar"); //获取类名
      //此处for循环多次初始化 echarts 实例
      for (var i = 0; i < myEchart.length; i++) {
        var myChart = echarts.init(myEchart[i]); //初始化echarts实例要在for循环内
        let option = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [
            {
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "40",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              // data: ,
              data: [
                { value: this.pieData[i].soldAmount, name: "已售" },
                { value: this.pieData[i].unSoldAmount, name: "未售" },
              ],
            },
          ],
        };
        //使用刚指定的配置项和数据显示图表
        myChart.clear(); //消除当前实例
        myChart.setOption(option);
      }
    },

    draw_bar2() {
      var myEchart = document.getElementsByClassName("bar2"); //获取类名
      //此处for循环多次初始化 echarts 实例
      for (var i = 0; i < myEchart.length; i++) {
        var myChart = echarts.init(myEchart[i]); //初始化echarts实例要在for循环内
        let option = {
          tooltip: {
            trigger: "axis",
          },
          legend: { orient: "vertical", left: "left" },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: [0, 0.01],
          },
          yAxis: {
            type: "value",
            data: ["Brazil", "Indonesia", "USA", "India", "China", "World"],
          },
          series: [
            {
              name: "已售",
              type: "bar",
              data: [this.pieData[i].soldAmount],
            },
            {
              name: "未售",
              type: "bar",
              data: [this.pieData[i].unSoldAmount],
            },
          ],
        };
        //使用刚指定的配置项和数据显示图表
        myChart.clear(); //消除当前实例
        myChart.setOption(option);
      }
    },

    toTotal() {
      // this.$router.push("/product/fridata");
    },
    toTotalDetail() {
      // this.$router.push("/product/fridetail");
    },
    toaddData() {
      // this.$router.push("/product/custom");
    },
    imgValueChange() {
      if (this.imgValue == 1) {
        setTimeout(() => {
          var myEchart = document.getElementsByClassName("bar"); //获取类名

          //此处for循环多次初始化 echarts 实例
          for (var i = 0; i < myEchart.length; i++) {
            var myChart = echarts.init(myEchart[i]); //初始化echarts实例要在for循环内
            let option = {
              tooltip: {
                trigger: "item",
              },
              legend: {
                orient: "vertical",
                left: "left",
              },
              series: [
                {
                  type: "pie",
                  radius: ["40%", "70%"],
                  avoidLabelOverlap: false,
                  label: {
                    show: false,
                    position: "center",
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: "40",
                      fontWeight: "bold",
                    },
                  },
                  labelLine: {
                    show: false,
                  },
                  // data: ,
                  data: [
                    { value: this.pieData[i].soldAmount, name: "已售" },
                    { value: this.pieData[i].unSoldAmount, name: "未售" },
                  ],
                },
              ],
            };
            //使用刚指定的配置项和数据显示图表
            myChart.clear(); //消除当前实例
            myChart.setOption(option);
          }
        }, 300);
      } else if (this.imgValue == 2) {
        setTimeout(() => {
          var myEchart = document.getElementsByClassName("bar2"); //获取类名
          //此处for循环多次初始化 echarts 实例
          for (var i = 0; i < myEchart.length; i++) {
            var myChart = echarts.init(myEchart[i]); //初始化echarts实例要在for循环内
            let option = {
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "shadow",
                },
              },
              legend: { orient: "vertical", left: "left" },
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
              },
              xAxis: {
                type: "category",
                boundaryGap: [0, 0.01],
                data: [""]
              },
              yAxis: {
                type: "value",
                data: ["Brazil", "Indonesia", "USA", "India", "China", "World"],
              },
              series: [
                {
                  name: "已售",
                  type: "bar",
                  data: [this.pieData[i].soldAmount],
                },
                {
                  name: "未售",
                  type: "bar",
                  data: [this.pieData[i].unSoldAmount],
                },
              ],
            };
            //使用刚指定的配置项和数据显示图表
            myChart.clear(); //消除当前实例
            myChart.setOption(option);
          }
        }, 300);
      }
    },
    dimensionTypeChange() {
      if (this.imgValue == 1) {
        let params = {
          dimensionType: this.dimensionType,
          hierarchy: this.typeListValue,
          houseType: this.typeListValue == 1 ? this.bigCheckList : this.smallCheckList,
          projectId: this.$route.query.id,
        };
        getHomeInComeImage(params).then((res) => {
          this.pieData = res.data;
          setTimeout(() => {
            var myEchart = document.getElementsByClassName("bar"); //获取类名
            //此处for循环多次初始化 echarts 实例
            for (var i = 0; i < myEchart.length; i++) {
              var myChart = echarts.init(myEchart[i]); //初始化echarts实例要在for循环内
              let option = {
                tooltip: {
                  trigger: "item",
                },
                legend: {
                  orient: "vertical",
                  left: "left",
                },
                series: [
                  {
                    type: "pie",
                    radius: ["40%", "70%"],
                    avoidLabelOverlap: false,
                    label: {
                      show: false,
                      position: "center",
                    },
                    emphasis: {
                      label: {
                        show: true,
                        fontSize: "40",
                        fontWeight: "bold",
                      },
                    },
                    labelLine: {
                      show: false,
                    },
                    // data: ,
                    data: [
                      { value: this.pieData[i].soldAmount, name: "已售" },
                      { value: this.pieData[i].unSoldAmount, name: "未售" },
                    ],
                  },
                ],
              };
              //使用刚指定的配置项和数据显示图表
              myChart.clear(); //消除当前实例
              myChart.setOption(option);
            }
          }, 500);
        });
      } else if (this.imgValue == 2) {
        let params = {
          dimensionType: this.dimensionType,
          hierarchy: this.typeListValue,
          houseType: this.typeListValue == 1 ? this.bigCheckList : this.smallCheckList,
          projectId: this.$route.query.id
        };
        getHomeInComeImage(params).then((res) => {
          this.pieData = res.data;
          setTimeout(() => {
            var myEchart = document.getElementsByClassName("bar2"); //获取类名
            //此处for循环多次初始化 echarts 实例
            for (var i = 0; i < myEchart.length; i++) {
              var myChart = echarts.init(myEchart[i]); //初始化echarts实例要在for循环内
              let option = {
                tooltip: {
                  trigger: "axis",
                  axisPointer: {
                    type: "shadow",
                  },
                },
                legend: { orient: "vertical", left: "left" },
                grid: {
                  left: "3%",
                  right: "4%",
                  bottom: "3%",
                  containLabel: true,
                },
                xAxis: {
                  type: "category",
                  boundaryGap: [0, 0.01],
                },
                yAxis: {
                  type: "value",
                  data: ["Brazil", "Indonesia", "USA", "India", "China", "World"],
                },
                series: [
                  {
                    name: "已售",
                    type: "bar",
                    data: [this.pieData[i].soldAmount],
                  },
                  {
                    name: "未售",
                    type: "bar",
                    data: [this.pieData[i].unSoldAmount],
                  },
                ],
              };
              //使用刚指定的配置项和数据显示图表
              myChart.clear(); //消除当前实例
              myChart.setOption(option);
            }
          }, 500);
        });
      }
    },
    typeListValueChange() {
      this.dimensionTypeChange();
    },
    async getHomeInComeImageList() {
      let params = {
        dimensionType: this.dimensionType,
        hierarchy: this.typeListValue,
        houseType: this.typeListValue == 1 ? this.bigCheckList : this.smallCheckList,
        projectId: this.$route.query.id
      };
      getHomeInComeImage(params).then((res) => {
        // console.log(res);
        this.pieData = res.data;
        // console.log(this.pieData)

        setTimeout(() => {
          this.draw_Bar();
        }, 500);

        // console.log(this.$refs.pieRef);
        // for (let i = 0; i < this.$refs.pieRef.length; i++) {
        //   this.$refs.pieRef[i].pieChartData();
        // }

        // this.$refs.columnarRef.pieChartData();
      });
    },
    cascaderModelChange() {
      // console.log(this.cascaderModel);
    },
    getSpanArr(data) {
      this.mergeArr.forEach((key, index1) => {
        let count = 0; // 用来记录需要合并行的起始位置
        this.mergeObj[key] = []; // 记录每一列的合并信息
        data.forEach((item, index) => {
          // index == 0表示数据为第一行，直接 push 一个 1
          if (index === 0) {
            this.mergeObj[key].push(1);
          } else {
            // 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
            if (item[key] === data[index - 1][key]) {
              this.mergeObj[key][count] += 1;
              this.mergeObj[key].push(0);
            } else {
              // 如果当前行和上一行其值不相等
              count = index; // 记录当前位置
              this.mergeObj[key].push(1); // 重新push 一个 1
            }
          }
        });
      });
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 判断列的属性
      if (this.mergeArr.indexOf(column.property) !== -1) {
        // 判断其值是不是为0
        if (column.property == "superClassName") {
          if (this.mergeObj[column.property][rowIndex]) {
            return [this.mergeObj[column.property][rowIndex], 1];
          } else {
            // 如果为0则为需要合并的行
            return [0, 0];
          }
        }
      }
    },

    // 获取表格数据
    getInComeTableData() {
      let projectId = this.$route.query.id;
      getInComeTable(projectId).then((res) => {
        let dataArray = [];
        let data = res.data;
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].resultList.length; j++) {
            data[i].resultList[j].superClassName = data[i].superClassName;
            dataArray[i] = data[i].resultList;
          }
        }
        this.fdcArrayData = dataArray.flat();
        this.getSpanArr(this.fdcArrayData);
      });
    },
  },
  watch: {
    cascaderModel(val) {
      let data = this.colData;
      for (let i = 0; i < data.length; i++) {
        data[i].istrue = false;
      }
      for (let i = 0; i < val.length; i++) {
        data[val[i][0] - 1].istrue = true;
      }
      this.colData = data;
      this.reload = Math.random();
    },
  },
};
</script>

<style lang="stylus" scoped>
.chartContainer {
  width: 100%;
  margin-bottom: 10px;

  .select-array {
    display: flex;
    margin: 16px 0 0 30px;

    .select-option {
      margin-right: 30px;
    }
  }
}

.container {
  background: rgba(255, 255, 255, 1);
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.04);
  border-radius: 8px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 54px;
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid rgba(218, 213, 232, 0.6);

    .title {
      font-size: 18px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      margin-left: 24px;
    }

    .select {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);

      .detail {
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }

  .echart-img {
    padding-top: 50px;
    margin: 0 30px;
    overflow: auto;
  }

  .index-table {
    padding: 30px;
    padding-top: 0;
  }
}

.casc {
  margin-left: 30px;

  >>> .el-cascader {
    width: 700px;
    margin-top: 8px;
  }
}

.chart-container {
  white-space: nowrap;
  text-align: center;
}

.chart {
  position: relative;
  width: 300px;
  height: 200px;
  display: inline-block;
}

.typeName {
  position: absolute;
  left: 50%;
  margin-left: -40px;
  top: -30px;
  color: rgba(0,0,0,0.65);
}

.chart .bar {
  width: 100%;
  height: 100%;
}

.chart .bar2 {
  width: 100%;
  height: 100%;
}

.primary-class {
  margin: 6px 0 0 4px;
}

.no-data {
  height: 250px;
  line-height: 250px;
  text-align: center;
  cursor: pointer;
  color: rgba(0,0,0,0.65);
}
</style>
