import {cloneDeep} from "lodash";

/**
 * 得到一个treeData空节点
 * @param {number?} type
 * @param {isLeaf?} 是否是内容节点
 * @returns {{censorSnapshot: null, orderNumber: string, pageNumber: null, notes: null, userData: number, riskLevel: number, yebYesOrNoData: number, sign: number, type: number, editSnapshot: null, uuid: string, desensitizationStatus: number, oldPdfData: null, dataText: null, children: *[], isSimple: number, id: string, updateSort: number, floor: number, zbSq: string, pdfData: null, swzbNoData: null, oldDataText: null, warningLevelId: null, gradeMark: string, updateTime: null, sort: number, isAdd: null, contrastKey: string, parentId: number, isDisplay: number, zbBq: string, titleName: string, lastCensorSnapshot: null, createTime: null, saNsrsbh: string, lastEditSnapshot: null}}
 */
export const getEmptyNode = (nsrsbh,uuid,type = 0, isLeaf = false) => {
  return {
    "id": null,
    "orderNumber": "",
    "titleName": "",
    "gradeMark": "",
    "sort": 0,
    "parentId": 0,
    "contrastKey": "CLASS",
    uuid,
    "pageNumber": null,
    "saNsrsbh": nsrsbh,
    "oldDataText": null,
    "dataText": null,
    "oldPdfData": null,
    "pdfData": isLeaf ? JSON.stringify({dataText: ""}) : null,
    "createTime": null,
    "updateTime": null,
    "isDisplay": 1,
    "warningLevelId": null,
    "notes": null,
    "children": [],
    type,
    "swzbNoData": null,
    "yebYesOrNoData": 0,
    "zbSq": "",
    "zbBq": "",
    "userData": 1,
    "editSnapshot": null,
    "censorSnapshot": null,
    "updateSort": 0,
    "isAdd": null,
    "lastEditSnapshot": null,
    "lastCensorSnapshot": null,
    "sign": 2,
    "isSimple": 0,
    "riskLevel": 4,
    "desensitizationStatus": 0,
    "floor": 1,
    "tmpId":Math.random().toString(36).slice(-8),
  }

}
