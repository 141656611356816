export const qyStatusList = [
    { value: "小微企业", label: "小微企业" },
    { value: "中型企业", label: "中型企业" },
    { value: "大型企业", label: "大型企业" },
];

export const kjzd = [
    { value: "qykjzd", label: "企业会计制度" },
    { value: "qykjzz", label: "企业会计准则" },
    { value: "xqykjzz", label: "小企业会计准则" },
];

export const kjzdList = {
    qykjzd: "企业会计制度",
    qykjzz: "企业会计准则",
    xqykjzz: "小企业会计准则",
};

export const formatDateTes = function (timestamp) {
    var date = new Date(timestamp);
    var Y = date.getFullYear() + "-";
    var M =
        (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
    var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
    var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return Y + M + D + " " + h + m + s;
};