<template>
  <div class="changeRecord">
    <div class="left">
      <el-menu
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
        :default-openeds="openeds"
      >
        <el-submenu index="1">
          <template slot="title">
            <i class="el-icon-caret-bottom"></i>
            <span slot="title">1 企业背景</span>
          </template>
          <el-menu-item index="1-1">1.1基本信息</el-menu-item>
          <el-menu-item index="1-2">1.2股东及管理人员信息</el-menu-item>
          <el-menu-item index="1-3">1.3变更记录</el-menu-item>
          <el-menu-item index="1-4">1.4对外投资</el-menu-item>
          <el-menu-item index="1-5">1.5分支机构</el-menu-item>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-caret-bottom"></i>
            <span slot="title">2 上市公司信息</span>
          </template>
          <el-menu-item index="2-1"> 2.1上市公告</el-menu-item>
          <el-menu-item index="2-2"> 2.2十大股东</el-menu-item>
          <el-menu-item index="2-3">2.3高管信息</el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-caret-bottom"></i>
            <span slot="title">3 风险预警</span>
          </template>
          <el-menu-item index="3-1"> 3.1经营异常</el-menu-item>
          <el-menu-item index="3-2"> 3.2行政处罚</el-menu-item>
          <el-menu-item index="3-3"> 3.3严重违法</el-menu-item>
          <el-menu-item index="3-4"> 3.4税收违法</el-menu-item>
          <el-menu-item index="3-5"> 3.5欠税公告</el-menu-item>
          <el-menu-item index="3-6"> 3.6被执行人</el-menu-item>
          <el-menu-item index="3-7"> 3.7失信人</el-menu-item>
        </el-submenu>
        <el-submenu index="4">
          <template slot="title">
            <i class="el-icon-caret-bottom"></i>
            <span slot="title">4 公司发展</span>
          </template>
          <el-menu-item index="4-1"> 4.1企业业务</el-menu-item>
          <el-menu-item index="4-2"> 4.2融资历史</el-menu-item>
          <el-menu-item index="4-3">4.3投资事件</el-menu-item>
          <el-menu-item index="4-4">4.4行政许可</el-menu-item>
          <el-menu-item index="4-5">4.5进出口信用</el-menu-item>
        </el-submenu>

        <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-caret-bottom"></i>
            <span slot="title">5 公开正面信息</span>
          </template>
          <el-menu-item index="4-1"> 5.1企业资质</el-menu-item>
          <el-menu-item index="4-2"> 5.2知识产权</el-menu-item>
          <el-menu-item index="4-3"> 5.3招投标</el-menu-item>
        </el-submenu>
      </el-menu>
      <el-radio-group v-model="isCollapse" class="open">
        <el-radio-button :label="false" class="expandCatalog">展开目录</el-radio-button>
        <el-radio-button :label="true" class="collapseDirectory">收起目录</el-radio-button>
      </el-radio-group>
    </div>

    <div class="right">
      <h1>{{ contentList.address }}</h1>
      <div>报告日期：{{ contentList.estiblishTime }}</div>
    </div>
  </div>
</template>
<script>
import {
  getCreateDataFun,
  getBasicInfoFun,
  getBranchFun,
  getChangeRecordFun,
  getExecutiveInfoFun,
  getListingAnnouncementFun,
  getOutboundInvestmentFun,
  getPageFun,
} from "@/api/api";

export default {
  data() {
    return {
      programId: this.$route.params.programId,
      content: "",
      isCollapse: false,
      contentList: "",
      drawer: false,
      pageNum: 12,
      pageSize: 14,
      openeds: ["1", "2", "3", "4", "5"],
    };
  },
  mounted() {
    //this.CreateBusinessPanoramicReport();
  },
  created() {
    this.CreateBusinessPanoramicReport();
  },
  methods: {
    //创建工商全景画像报告
    CreateBusinessPanoramicReport() {
      getCreateDataFun(this.programId).then((res) => {
        //基本信息
        getBasicInfoFun(res.data).then((res2) => {
          this.contentList = res2.data;
          console.log(res2);
        });

        //变更记录
        getChangeRecordFun(res.data).then((res) => {
          //console.log(res);
        });
        //分支机构
        getBranchFun(res.data).then((res) => {
          //console.log(res);
        });
        //股东及管理人员信息
        getExecutiveInfoFun(res.data).then((res) => {
          //console.log(res);
        });
        //上市公告
        getListingAnnouncementFun(res.data).then((res) => {
          //console.log(res);
        });
        //对外投资
        getOutboundInvestmentFun(res.data).then((res) => {
          //console.log(res);
        });
      });
      //工商全景画像信息报告分页
      getPageFun(this.contentList.companyName).then((res) => {
        console.log(res);
      });
    },
    //侧边栏展开收起
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      //  console.log(key, keyPath);
    },
    handleClose(done) {
      done();
    },
  },
};
</script>
<style lang="stylus" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.changeRecord {
  display: flex;
  justify-content: space-between;
  .left{
    display: flex;
    justify-content: space-between
    .el-menu--collapse{
      width:1px;
      .el-submenu{
        display: none;
      }
    }
  }
  .right{
    flex: 1
    text-align: center;
  }
}
.drawer{
  /deep/ .el-drawer__container{
    position: relative;
    left: 182px;
    right: 0;
    top: 60px;
    bottom: 0;
    height: 100%;
    width: 36%;
  }
}
.open{
 border:none;
 padding 4px
 margin-top:370px
 //white-space:pre-wrap
 line-height 28px
 display: flex;
 flex-direction: column
 width:50px;
.expandCatalog{
}
.collapseDirectory{

}
}

.el-menu-vertical-demo .el-submenu .el-menu-item{
height 29px
}
.el-menu-vertical-demo >>>.el-submenu__title{
  height 35px
}
</style>
