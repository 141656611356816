<template>
    <div class="savedate">
        <el-card class="usercard">
            <div class="box">
                <div class="qyname">数据保存：</div>
                <div class="name">
                    <el-radio v-model="value" class="radio" label="1">保存数据</el-radio>
                    <el-radio v-model="value"  class="radio" label="2">不保存数据</el-radio>
                </div>
            </div>
             <div class="box">
                <div class="qyname"></div>
                <div class="name savedata">
                   系统自动保存您的数据，避免出现操作失误导致数据丢失，可实时查询历史上传记录，安全可靠便捷。                             
                </div>
            </div>
             <el-button type="primary" class="save">保存</el-button>
        </el-card>
    </div>
</template>
<script>
export default {
    data(){
        return{
            value:'',
            options:[{
                value:'yes',
                label:'是'
            },{
                value:'no',
                label:'否'
            }]
        }
    }
}
</script>
<style src="@/styles/index.scss" lang="scss" scoped></style>
<style lang="stylus">

 
</style>