<template>
  <el-dialog title="产品兑换" :visible.sync="dialogVisible" :close-on-click-modal="false" width="480px">
    <div class="row">
      <div class="label">兑换码</div>
      <div class="control">
        <el-input v-model="cdk" class="input" placeholder="不区分大小写，无需输入空格或连字符"></el-input>
        <div class="error-tip" v-if="error">
          <i class="el-icon-error"></i>
          {{error}}
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleBtnExchangeClick">兑换</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { exchange } from "@/api/api";
export default {
  props: {},
  data() {
    return {
      dialogVisible: false,
      cdk: "",
      error: ""
    };
  },
  methods: {
    async handleBtnExchangeClick() {
      if (!this.cdk) {
        this.error = "请输入兑换码";
      } else {
        let token = JSON.parse(localStorage.getItem("token")).token;
        const data = await exchange(token, this.cdk);
    
        if (data.msgCode != 1) {
          this.error = data.msgInfo;
        } else {
          this.$emit("onDialogClose", data);
          this.dialogVisible = false;
        }
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.error-tip {
  margin-top: 10px;
  color: #F5222D;
}

>>> .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

>>> .el-dialog__header {
  padding: 16px 24px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid #EEEEEE;
}

>>> .el-dialog__title {
  color: #333;
  font-size: 16px;
  font-weight: 600;
}

>>> .el-dialog__body {
  padding: 0;
}

>>> .el-dialog__footer {
  border-top: 1px solid #EEEEEE;
  padding: 12px 24px;

  .el-button {
    background: hsla(228, 82%, 55%, 1);
    width: 76px;
    height: 32px;
    background: rgba(47, 84, 235, 1);
    border-radius: 4px;
    border: 0 none;
    padding: 0;
    line-height: 32px;
    font-size: 14px;
  }

  .el-button:hover {
    background: hsla(228, 82%, 45%, 1);
  }
}

.row {
  width: 80%;
  padding: 48px 0;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  color: #666;

  .label {
    position: relative;
    top: 10px;
    vertical-align: top;
    margin-right: 8px;
  }

  .control {
    margin-left: 8px;
    vertical-align: top;
    width: 320px;
  }
}
</style>