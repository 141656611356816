<template>
  <el-dialog title="绑定微信" :visible.sync="dialogVisible" :close-on-click-modal="false" width="480px">
    <div class="dialog-content">
      <div class="tip">
        验证码将发送到绑定的手机号（{{mobileMasked}}）
      </div>
      <div class="sms-form">
        <el-input v-model="smsCode" placeholder="验证码" prefix-icon="iconfont iconmima" class="input"></el-input>
        <button class="btn btn-default btn-get-sms" @click="sendSMS" :disabled="!canClick">{{SMSBtnlabel}}</button>
      </div>
      <div class="error" v-if="error">
        <i class="iconfont iconshibai"></i>
        {{error}}
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleSMSDialogNext">下一步</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getBindSMSCode,verifyCodeForWx } from "@/api/auth";

export default {
  data: function() {
    return {
      mobile:null,
      dialogVisible: false,
      smsCode: "",
      error: null,
      canClick:true,
      SMSBtnlabel:"获取验证码",
      totalTime:60
    };
  },
  mounted(){
    const user = JSON.parse(localStorage.getItem("user"));
    this.mobile = user.mobile;
  },
  computed:{
    mobileMasked(){
      let mobile = this.mobile;
      if(mobile){
        mobile = mobile.slice(0,3)+'****'+mobile.slice(7)
      }
      return mobile;
    }
  },
  methods: {
    async handleSMSDialogNext() {
      if(!this.smsCode){
        this.error = "请输入您收到的短信验证码"
        return 
      }
      const params = {
        mobile:this.mobile,
        verifyCode:this.smsCode
      }
      const res = await verifyCodeForWx(params);
      if(res.msgCode=="1"){
        this.dialogVisible = false;
        this.$emit("dialogSendSMSNextCLick");
      }else{
        this.error = res.msgInfo;
      }
      
    },
    sendSMS() {
      const _this = this;

      getBindSMSCode(this.mobile).then(data => {
        if (data.msgCode !== "1") {
          _this.error = data.msgInfo
          _this.$alert(data.msgInfo, "提示", {
            confirmButtonText: "确定",
            type: "warning"
          });
        } else {
          
          //获取验证码倒计时
          this.canClick = false;
          this.SMSBtnlabel = this.totalTime + "s后重发";
          let clock = window.setInterval(() => {
            this.totalTime--;
            this.SMSBtnlabel = this.totalTime + "s后重发";
            if (this.totalTime < 0) {
              window.clearInterval(clock);
              this.SMSBtnlabel = "重发验证码";
              this.totalTime = 60;
              this.canClick = true;
            }
          }, 1000);
        }
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.dialog-content {
  .tip {
    margin-bottom: 8px;
    color: #666;
  }

  width: 320px;
  margin: 0 auto;

  .input {
    width: 200px;
  }

  .sms-form {
    display: flex;
    justify-content: space-around;
  }
}

.error {
  margin-top: 5px;
  color: #f00;
}
</style>