<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">风险检测</template>
      </main-header>
    </div>
    <div class="content">
      <div class="title">基本信息设置</div>
      <div class="form">
        <div class="item">
          <div class="name">选择企业：</div>
          <div class="inputs">
            <!-- <el-input v-model="companyName" placeholder="请输入"></el-input> -->
            <el-select
              ref="optionRef"
              v-model="companyName"
              filterable
              remote
              reserve-keyword
              placeholder="请输入企业名称，至少4个字"
              @change="optionRefChange"
              :remote-method="remoteMethod"
            >
              <el-option
                v-for="(item, index) in cityOptions"
                :key="item.creditCode"
                :label="item.name"
                :value="index"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="item">
          <div class="name">企业社会信用代码：</div>
          <div class="inputs">
            <el-input v-model="ruleForm.nsrsbh" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="item item-taxNo">
          <div class="name">检测期间：</div>
          <div class="inputs">
            <el-select v-model="zq" placeholder="请选择">
              <el-option
                v-for="item in zqOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="time">{{zq - 2}}年 - {{zq}}年</div>
        </div>
        <div class="next">
          <div @click="toNextAuto">下一步</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import { getQuerySectionList, getUUID } from "@/api/api";

export default {
  components: {
    BreadCrumb,
    MainHeader,
  },
  data() {
    return {
      uuid: "",
      companyName: "",
      zqOptions: [
        {
          value: "2022",
          label: "2022年",
        },
        {
          value: "2021",
          label: "2021年",
        },
        {
          value: "2020",
          label: "2020年",
        },
      ],
      zq: "2022",
      cityOptions: "",
      optionQuery: "",
      ruleForm: {
        nsrsbh: "",
        name: ""
      }
    };
  },
  mounted() {
    this.getUuid();
  },
  methods: {
    async getUuid() {
      let msg = await getUUID();
      this.uuid = msg.data.uuid;
    },
    optionRefChange() {
      this.ruleForm.nsrsbh = this.cityOptions[this.companyName].creditCode;
      this.ruleForm.name = this.cityOptions[this.companyName].name;
    },
    remoteMethod(query) {
      if (query.length < 4) return;
      getQuerySectionList(query).then((res) => {
        console.log(res)
        this.cityOptions = res.data.list;
      });
    },
    // 
    toNextAuto() {
      if(!this.ruleForm.nsrsbh || !this.ruleForm.name) {
        this.$message({
          message: '企业或税号不能为空！',
          type: 'warning'
        });
        return;
      }
      this.$router.push({
        name: "upload-table",
        query: {
          taxNo: this.ruleForm.nsrsbh,
          companyName: this.ruleForm.name,
          zq: this.zq + '004',
          uuid: this.uuid,
        },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.title-content {
  height: 96px;
  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px 0px #EEEEEE;
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.content {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.04);
  border-radius: 8px;
  margin: 16px 30px;
  padding-bottom: 40px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;

  .title {
    height: 54px;
    line-height: 54px;
    box-shadow: inset 0px -1px 0px 0px #D9D9D9;
    font-size: 16px;
    color: rgba(0,0,0,0.8);
    padding-left: 24px;
  }

  .form {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0,0,0,0.6);
    margin-top: 40px;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .name {
        width: 150px;
        text-align: right;
      }

      >>> .el-input, >>> .el-input__inner {
        height: 36px;
        line-height: 36px;
        width: 400px;
      }

      >>> .el-input__icon {
        line-height: 36px;
      }

      .search {
        width: 76px;
        height: 36px;
        line-height: 34px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #2F54EB;
        box-sizing: border-box;
        color: #2F54EB;
        margin-left: 44px;
        cursor: pointer;
      }
      .search:hover {
        color: #fff;
        background: #2F54EB;
        transition: all 0.3s;
      }

      .time {
        margin-left: 18px;
        color: rgba(0,0,0,0.8);
      }
    }

    .item-taxNo {
      >>> .el-input, >>> .el-input__inner {
        width: 280px;
      }
    }

    .next {
      width: 400px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      color: #fff;
      background: #2F54EB;
      border-radius: 4px;
      margin-left: 150px;
      margin-top: 40px;
      cursor: pointer;
    }
  }
}
</style>
