<template>
  <div class="mdfpassword safephone">
    <el-card class="usercard">
      <div class="box box1">
        <div class="qyname">手机号：</div>
        <div class="name">
          <el-input placeholder="请输入手机号" v-model="newphone"></el-input>
        </div>
      </div>
      <div class="box box1">
        <div class="qyname">验证码：</div>
        <div class="name">
          <el-input placeholder="请输入验证码" v-model="msgCode"></el-input>
          <el-button
            type="text"
            class="code_btn"
            @click="getCode"
            :class="{disabled: !this.canClick}"
          >{{content}}</el-button>
        </div>
      </div>
      <el-button type="primary" class="save" @click="changephone2">保存</el-button>
    </el-card>
  </div>
</template>
<script>
import { getRegisterCode, newphone } from "@/api/api";
export default {
  data() {
    return {
      content: "获取验证码",
      newphone: "",
      msgCode: "",
      totalTime: 60,
      canClick: true
    };
  },
  methods: {
    submitimg() {
      if (!this.checkTel(this.newphone)) {
        this.$message.error("请输入正确的手机格式");
        return false;
      }
      return true;
    },
    submit() {
      if (!this.newphone) {
        this.$message.error("请输入新的安全手机!");
        return false;
      }
      if (!this.msgCode) {
        this.$message.error("请输入短信验证码!");
        return false;
      }
      if (!this.checkTel(this.newphone)) {
        this.$message.error("请输入正确的手机格式");
        return false;
      }
      return true;
    },
    checkTel: function(tel) {
      var mobile = /^1[3|4|5|6|7|8|9]\d{9}$/,
        phone = /^0\d{2,3}-?\d{7,8}$/;
      return mobile.test(tel) || phone.test(tel);
    },
    getCode: function() {
      if (!this.canClick) return;
      if (this.submitimg()) {
        let mobile = this.newphone;
        getRegisterCode(mobile).then(data => {
          if (data.msgCode == "1") {
            this.$message.success("请注意查收短信验证码");
          } else {
            this.$message.error(data.msgInfo);
          }
        });
        this.canClick = false;
        this.content = this.totalTime + "s后重新发送";
        let clock = window.setInterval(() => {
          this.totalTime--;
          this.content = this.totalTime + "s后重新发送";
          if (this.totalTime < 0) {
            window.clearInterval(clock);
            this.content = "重新发送验证码";
            this.totalTime = 60;
            this.canClick = true;
          }
        }, 1000);
      }
    },
    changephone2() {
      if (this.submit()) {
        let token = JSON.parse(localStorage.getItem("token")).token;
        let params = {
          token: token,
          code: this.msgCode,
          mobile: this.newphone
        };
        newphone(params).then(data => {
          if (data.msgCode != "-1") {
            this.$alert("更换手机成功,请重新登录", "提示", {
              type: "success"
            }).finally(() => {
              this.$store.cache.clear();
              this.$store.commit("reset");

              this.$store.commit("clearToken");
              localStorage.clear();
              sessionStorage.clear();
              this.$router.push({ name: "login" });
            });

            //this.$router.push('/')
          } else {
            this.$message.error(data.msgInfo);
          }
        });
      }
    }
  }
};
</script>
<style src="@/styles/index.scss" lang="scss" scoped></style>
<style lang="stylus" scoped>
.safephone {
  .phone_input {
    width: 67%;
  }
}
</style>