<template>
  <div>
    <div>
      <el-table :data="balanceData" style="width: 100%">
        <el-table-column prop="index" label="序号"></el-table-column>
        <el-table-column prop="name" label="表单名称"></el-table-column>
        <el-table-column prop="status" label="状态"></el-table-column>
        <el-table-column prop="status" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="showTable(scope.row)"
              v-if="scope.row.status == '已上传'"
              >查看</el-button
            >
            <el-button type="text" size="small" v-if="scope.row.status == '未上传'"
              >暂未上传</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="deletes(scope.row)"
              v-if="scope.row.status == '已上传'"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="containertables" v-if="showTables">
      <div class="tables">
        <div class="title">{{ this.templateName }}</div>
        <div class="closes" @click="deleteTable">×</div>
        <div class="content">
          <el-table
            :data="tableData"
            style="width: 100%"
            height="620"
            row-key="subBsDm"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column
              prop="subBsDm"
              label="科目编码"
              width="160"
            ></el-table-column>
            <el-table-column
              prop="subBsMc"
              label="科目名称"
              width="150"
            ></el-table-column>
            <el-table-column label="年初余额" align="center">
              <el-table-column
                prop="ncBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="ncLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="期初余额" align="center">
              <el-table-column
                prop="beforeBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="beforeLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="本期发生额" align="center">
              <el-table-column
                prop="currentBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="currentLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="本年累计" align="center">
              <el-table-column
                prop="bnljBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="bnljLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="期末余额" align="center">
              <el-table-column
                prop="afterBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="afterLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showBalanceDetail } from "@/api/api";
import { loadTableDataAi } from "@/api/table";

export default {
  props: {
    balanceData: [Array],
    uuid: [String],
  },
  data() {
    return {
      showTables: false,
      deleteList: [
        localStorage.getItem("zqY") + "004",
        +localStorage.getItem("zqY") - 1 + "004",
      ],
      tableData: [],
    };
  },

  methods: {
    deletes(val) {
      this.$emit("deletes", { index: val.index, name: val.name, zq: val.zq });
      this.act = val.index;
    },
    // showTable(val) {
    //   this.templateName = val.name;
    //   this.showTables = true;

    //   let params = {
    //     zq: this.deleteList[val.index],
    //     uuid:this.uuid
    //   };
    //   showBalanceDetail(params).then((res) => {
    //     this.tableData = res.data;
    //     // let tableData = this.tableData.filter(item=> {
    //     //   return item.balanceSheetDto
    //     // })
    //     // console.log(tableData);
    //     let newTableData = [];
    //     for (let i = 0; i < this.tableData.length; i++) {
    //       newTableData.push(this.tableData[i].balanceSheetDto);
    //       if (!this.tableData[i].balanceSheetList) continue;
    //       for (let j = 0; j < this.tableData[i].balanceSheetList.length; j++) {
    //         newTableData.push(this.tableData[i].balanceSheetList[j]);
    //       }
    //     }

    //     this.tableData = newTableData;
    //   });
    // },
    showTable(val) {
      this.templateName = val.name;
      let token = localStorage.getItem("authorization");
      let zq = this.templateName.substring(0, 4) + "004";
      loadTableDataAi(zq, "YEB", token, this.uuid).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
          return;
        }
        this.tableData = res.data.dataJson;
      });

      this.showTables = true;
    },
    deleteTable() {
      this.showTables = false;
    },
  },
};
</script>

<style scoped>
.tables {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -550px;
  margin-top: -300px;
  width: 1158px;
  height: 700px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 2000;
  padding-bottom: 30px;
  overflow-y: auto;
}

.content {
  width: 1136px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  text-align: center;
  margin: 24px 0;
}

.closes {
  position: absolute;
  right: 25px;
  top: 17px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.containertables {
  display: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.04);
}
</style>
