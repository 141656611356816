<template>
  <div :class="{hide: isCensorDone&&node.isDisplay==0}">
    <!--标题-->
    <div class="head-wrapper">
      <!--标题的编辑弹出框-->
      <el-popover
        placement="top-start"
        width="400"
        trigger="manual"
        v-model="toolTipVisible"
        @show="handleTooltipShow"
      >
        <div class="form-control-wrapper">
          <div class="input-wrapper">
            <el-input v-model="titleInput" placeholder="请输入内容"></el-input>
          </div>

          <div class="btn-wrapper">
            <button class="btn btn-default" @click="handleBtnSaveClick">确定</button>
            <button class="btn btn-default" @click="handleBtnCancelClick">取消</button>
          </div>
        </div>
        <template slot="reference">
          <div class="wrapper">
            <div class="head-level-0" :class="{editable:isEditable}"  @click.stop="handleHeadClick">
              {{chineseNumber(indexes[0]+1)}}、{{node.titleName}}
              <i
                class="icon iconfont iconbianji"
                v-if="roleIdList.indexOf(22) > -1 || authorizationFromHouTai"
              ></i>
            </div>
            <div class="btn-grp" v-if="roleIdList.indexOf(22) > -1 || authorizationFromHouTai">


              <el-dropdown @command="handleBtnAddClick">
                <el-button type="primary" class="btn btn-default btn-add">
                  添加<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="sibling">添加同级目录</el-dropdown-item>
                  <el-dropdown-item command="child">添加子目录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

              <button
                class="btn btn-default  btns-wrapper-showable"
                v-if="isDisplay"
                @click="handleBtnDisplayToggleClick"
              >
                {{ toggleBtnLabel }}
              </button>

              <button
                class="btn btn-default  btn-toggle-show"
                v-else
                @click="handleBtnDisplayToggleClick"
              >
                <i class="iconfont iconxianshi"></i> {{ toggleBtnLabel }}
              </button>

<!--              <button class="btn btn-default" @click="handleBtnDisplayToggleClick">{{toggleBtnLabel}}</button>-->
            </div>
          </div>

        </template>
      </el-popover>
    </div>

    <!--/标题的编辑弹出框-->

    <!--标题-->
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import mixin from "@/pages/ResultEditor/mixin.js";
import {cloneDeep} from "lodash";
import {getEmptyNode} from "../../util/treeDataTemplate";

const nzhcn = require("nzh/cn");
export default {
  mixins:[mixin],
  props: {
    indexes: Array,
    target: {
      default: "treeData",
      type: String,
    },
  },
  computed: {
    ...mapState("ResultEditor", ["treeData"]),
    ...mapGetters("ResultEditor", [
      "findTheNodeByIndexes"
      ,"isCensorDone"
    ]),
    node() {
      return this.findTheNodeByIndexes(this.indexes);
    },
    pos() {
      return this.indexes.join(".");
    },
    //是否隐藏
    isDisplay() {
      let ret = false;
      ret = !!this.node.isDisplay;
      return ret;
    },
    //按钮上的文字
    toggleBtnLabel() {
      return this.isDisplay ? "隐藏" : "显示";
    },
  },
  mounted() {
    this.$eventBus.$on("ResultEditorBgClick",()=>{
      this.toolTipVisible=false
    })
    this.getAuth();
  },
  methods: {
    // 报告权限获取
    getAuth() {
      if (!localStorage.getItem("user")) return;
      var roList = JSON.parse(localStorage.getItem("user")).roleList;
      this.roleIdList = roList.map((item) => {
        if(item) {
          return item.id;
        }
      });
      // 21报告下载  22报告编辑
    },
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes","refineOrder","setTreeDataByIndexes","setTreeData"]),
    ...mapActions("ResultEditor", ["refresh", "submit", "reload"]),
    handleBtnAddClick(command){
     if(command==='sibling'){
        this.handleBtnAddSibling()
     }else if(command==='child'){
        this.handleBtnAddChild()
     }
    },
    handleBtnAddSibling(){
      const treeData = cloneDeep(this.treeData);

      const level0Node = getEmptyNode(this.$route.params.nsrsbh||this.$route.query.nsrsbh,this.$route.params.uuid);
      const level1Node = getEmptyNode(this.$route.params.nsrsbh||this.$route.query.nsrsbh,this.$route.params.uuid);
      level0Node.children.push(level1Node);
      const level2Node = getEmptyNode(this.$route.params.nsrsbh||this.$route.query.nsrsbh,this.$route.params.uuid,5,true);
      level1Node.children.push(level2Node);

      treeData.unshift(level0Node);
      this.setTreeData({data:treeData})
      this.refineOrder({ target: this.target })
    },
    handleBtnAddChild(){
      const level0Node = cloneDeep(this.node);
      const level1Node = getEmptyNode(this.$route.params.nsrsbh||this.$route.query.nsrsbh,this.$route.params.uuid);
      level0Node.children.unshift(level1Node);
      const level2Node = getEmptyNode(this.$route.params.nsrsbh||this.$route.query.nsrsbh,this.$route.params.uuid,5,true);
      level1Node.children.unshift(level2Node);
      this.setTreeDataByIndexes({
        data:level0Node,
        indexes:this.indexes
      })
      this.refineOrder({ target: this.target })
    },
    //显示隐藏
    handleBtnDisplayToggleClick() {
      //如果当前节点是综述节点
      if (this.isOverallNode) {
        const node = cloneDeep(this.node);
        node.isDisplay = this.node.isDisplay == "1" ? 0 : 1;
        this.$store.commit("ResultEditor/setOverall", { data: node });
        // this.$message({
        //   message:
        //     `${this.title}将在PDF中` + (node.isDisplay ? "隐藏" : "显示"),
        //   type: "info"
        // });
      } else {
        const nodeData = cloneDeep(this.node);
        //如果当前节点是树状目录中的节点
        nodeData.isDisplay = this.node.isDisplay == "1" ? 0 : 1;

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
          target: this.target,
        });

        this.refineOrder({ target: this.target });
        // this.$message({
        //   message:
        //     `${this.title}将在PDF中` + (this.node.isDisplay ? "隐藏" : "显示"),
        //   type: "info"
        // });
      }
      this.submit();
    },
    //阿拉伯数字转中文
    chineseNumber(number) {
      return nzhcn.encodeS(number);
    },
    handleHeadClick() {
      if (!this.isEditable) {
        return;
      }
      this.$eventBus.$emit("ResultEditorBgClick")
      this.toolTipVisible=true
    },
    handleBtnCancelClick() {
      this.toolTipVisible = false;
    },
    handleBtnSaveClick() {
      this.toolTipVisible = false;

      //如果是综述则修改综述里的titleName
      if (this.pos == "overall") {
        const node = cloneDeep(this.node);
        node.titleName = this.titleInput;
        this.$store.commit("ResultEditor/setOverall", { data: node });
      } else {
        const nodeData = cloneDeep(this.node);
        nodeData.titleName = this.titleInput; //给节点设置新的titleName
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes
        });
      }
    },
    handleTooltipShow() {
      this.titleInput = this.node.titleName;
    }
  },
  data: function() {
    return {
      titleInput: "",
      toolTipVisible: false,
      authorizationFromHouTai: this.$route.query.fromHouTai || "",
      roleIdList: [],
    };
  }
};
</script>

<style lang="stylus" scoped>
.hide{
  display none !important
}
.head-wrapper {
  display: flex;
  justify-content: center;
  position relative;
  margin-bottom 5px

  .head-level-0 {

    background: rgba(47, 84, 235, 1);
    border-radius: 30px;
    min-width: 356px;
    display: inline-block;
    padding: 0 30px;
    box-sizing: border-box;
    height: 52px;
    line-height: 52px;
    text-align: center;
    color: #fff;
    font-size: 20px;
    margin: 0 auto;
  }
  .head-level-0.editable{
    cursor pointer
  }

  .btn-grp{
    position absolute;
    right 32px
    top 10px
    .btn-default{
      background-color: #fff;
      border: 1px solid #2f54eb ;
    }
    .btn-add:active,.btn-add:focus{
      color #2f54eb;
    }
  }
}



.form-control-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .input-wrapper {
    flex-grow: 1;
  }

  .btn-wrapper {
    margin-left: 10px;
    display: flex;

    .btn {
      margin: 0 5px;
    }
  }


}


</style>
