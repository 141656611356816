<template>
  <div
    class="page-wrapper"
    v-loading="isLoading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="wrapper">
      <div class="title-wrapper">
        <span>{{ this.$route.query.title }} </span>
      </div>
      <template v-if="!isLoading">
        <InvoiceIndex
          v-if="type == 1"
          :indexes="[...indexes.split(',')]"
          :isShowDetail="true"
          :isReadOnly="true"
        ></InvoiceIndex>
         <RiskProblem
          :indexes="[...indexes.split(',')]"
          v-if="type == 2"
          :isForceShowDetail="true"
          :isReadOnly="true"
        ></RiskProblem>
        <TaxIndex
          v-if="type == 4"
          :indexes="[...indexes.split(',')]"
          :isForceShowDetail="true"
          :isReadOnly="true"
        ></TaxIndex>
        <!-- <Riskfenlei
                      :indexes="[...indexes.split(',')]"
                      v-if="type == 3"
                    ></Riskfenlei> -->
       
      </template>
    </div>
  </div>
</template>

<script>
import InvoiceIndex from "../InvoiceIndex/";
import TaxIndex from "../TaxIndex/";
import RiskProblem from "../RiskProblem";
export default {
  props: {
    type: [Number, String],
    contrastKey: String,
    indexes: [String],
    nsrsbh: String,
    uuid: String,
    action: String,
    title: String,
  },
  components: {
    InvoiceIndex,
    TaxIndex,
    RiskProblem,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      await this.$store.dispatch("ResultEditor/loadPDFEditorData", {
        nsrsbh: this.nsrsbh,
        uuid: this.uuid,
        action: this.action,
        isSimple: 1,
      });
      this.isLoading = false;
    },
  },
  computed: {},
};
</script>

<style lang="stylus" scoped>
.page-wrapper {
  background: #f8fafb;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;

  .wrapper {
    background: #f8fafb;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    border-radius 16px;
    
    margin-top: 30px;
    background: #fff;
    padding-top: 0;

    .title-wrapper {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #d9d9d9;
      height: 56px;
      padding: 0 10px;
      .read-only-text {
        margin-left: 10px;
      }
    }
  }
}
</style>