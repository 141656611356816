<template>
  <div class="masker">
    <button class="view-more" @click="handleViewMoreClick">查看更多</button>
  </div>
</template>

<script>
import router from "@/router";

export default {
  router,
  methods: {
    handleViewMoreClick() {
      this.onClick();
    }
  }
};
</script>

<style lang="stylus" scoped>
.masker {
  overflow hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right,rgba(238, 238, 238, 0.1), rgba(238, 238, 238, 1) 20%,rgba(238, 238, 238, 1), rgba(238, 238, 238, 1));
  display: flex;
  justify-content: center;
  align-items: center;

  .view-more {
    cursor: pointer;
    background: none;
    border-radius: 4px;
    border: 1px solid #2f54eb;
    color: #2f54eb;
    padding: 8px 24px;
    outline: none !important;
  }
}
</style>