<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>账户管理</el-breadcrumb-item>
          <el-breadcrumb-item>账户列表</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">账户列表</template>
      </main-header>
    </div>
    <div class="filter-body" id="elinput">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="form-item">
            <div class="label">真实姓名：</div>
            <el-input
              clearable
              v-model="filter.realName"
              placeholder="请输入"
              @input="changeName"
              @keyup.enter.native="search"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">所属角色：</div>
            <el-input
              clearable
              v-model="filter.roleName"
              placeholder="请输入"
              @input="changeroleName"
              @keyup.enter.native="search"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">创建时间：</div>
            <el-date-picker
              v-model="filter.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="search"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="btn-look-up ease" @click="search">查询</div>
      <!-- <button class="btn btn-primary btn-look-up" @click="reset">重置</button> -->
    </div>
    <div class="addItem ease additemInline" @click="dialogTableVisible = true">创建账户</div>
    <div class="addItem ease additemInline" @click="dialogTableVisibles = true">批量创建</div>
    <div class="content-body">
      <template>
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column width="10px"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
          <el-table-column prop="userName" label="真实姓名"></el-table-column>
          <el-table-column prop="roleName" label="所属角色"></el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <div v-if="scope.row.status == 1"><span class="circle"></span>启用</div>
              <div v-else><span class="circle circle1"></span>停用</div>
            </template>
          </el-table-column>
          <el-table-column prop="viewReport" label="操作" width="100">
            <template slot-scope="scope" v-if="scope.row.roleremark != 1">
              <span
                class="cursion selectAdd"
                v-if="scope.row.status == 1"
                @click="disableorEnableUser(0, scope.row.id)"
                >禁用</span
              >
              <span class="cursion selectAdd" v-else @click="disableorEnableUser(1, scope.row.id)"
                >启用</span
              >

              <span @click="viewDetail(scope.row.id)" class="cursion" ref="clickBox">
                <i class="el-icon-edit-outline"></i>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <!-- 客户详情 -->
    <transition name="fades">
      <div class="containerMask" v-if="showDetail" @click="showDetailFalse"></div>
    </transition>
    <transition name="slide-fade">
      <div class="fixed-item" v-loading="loading" v-if="showDetail" ref="box">
        <div class="fixed-item-top">
          <img
            @click="showHiddenDetail"
            class="fixed-item-icon cursion"
            src="@/assets/test/arrow_right.png"
            alt
          />
          <div>账户详情</div>
          <div class="switch">
            <el-switch
              @change="elSwitch(customItem)"
              v-model="customItem.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
            ></el-switch>
          </div>
          <div class="switchTetx switchTetxb" v-if="customItem.status">已启用</div>
          <div class="switchTetx" v-else>已停用</div>
        </div>
        <div class="fixed-content" :class="{ fixedItemEdit: isEdit }">
          <div class="content-title" :class="{ editActive: isEdit }">真实姓名：</div>
          <div class="content-text" v-if="!isEdit">
            {{ customItem.userName }}
          </div>
          <div
            class="content-text content-isEdit addForms"
            :class="{ isEditPadding: isEdit }"
            id="content-isEdit"
            v-if="isEdit"
          >
            <el-input v-model.trim="currentEdit.userName" placeholder="请输入"></el-input>
            <span class="project-input-b" v-show="addForm[5]">真实姓名不能为空</span>
          </div>
          <div class="content-title">登录密码：</div>
          <div class="content-text">************</div>
          <div class="content-title" :class="{ editActive: isEdit, editPadding: isEdit }">
            选择角色：
          </div>
          <div class="content-text" v-if="!isEdit">
            {{ customItem.roleName }}
          </div>
          <div
            class="content-text content-isEdit addForms"
            :class="{ isEditPadding: isEdit }"
            id="content-isEdit"
            v-if="isEdit"
          >
            <el-select v-model="currentEdit.roleName" placeholder="请选择">
              <el-option
                v-for="item in roleOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <span class="project-input-b" v-show="addForm[4]">会计制度不能为空</span>
          </div>
        </div>
        <div
          class="content-btn ease"
          @click="detailEdit"
          v-show="!isEdit"
          v-if="customItem.status == 0"
        >
          编辑
        </div>
        <div v-if="customItem.status == 0">
          <div class="content-btn ease detailContent" v-show="isEdit">
            <div class="detailcancel" @click="removeEdit">取消</div>
            <div class="detailSaves" @click="hold(customItem.id)">保存</div>
          </div>
        </div>
      </div>
    </transition>

    <!-- 创建客户 -->
    <transition name="fades">
      <div
        class="containerMask"
        v-if="dialogTableVisible"
        @click="dialogTableVisible = false"
      ></div>
    </transition>
    <transition name="fadee">
      <div
        class="container"
        v-if="dialogTableVisible"
        ref="projectBox"
        id="projectBoxs"
        v-loading="addLoading"
      >
        <div class="addTitle">创建账户</div>
        <div class="project-close" @click="dialogTableVisible = false">×</div>
        <div class="project-name"><span class="project-named">*</span>真实姓名</div>
        <div class="project-input">
          <el-input placeholder="请输入" v-model.trim="person.name"></el-input>
          <span class="project-input-b" v-show="form4">真实姓名不能为空</span>
        </div>
        <div class="project-name"><span class="project-named">*</span>登录密码</div>
        <div class="project-input">
          <el-input
            placeholder="请输入"
            v-model.trim="person.password"
            :show-password="true"
          ></el-input>
          <span class="project-input-b" v-show="form5">登录密码不能为空</span>
        </div>
        <div class="project-name"><span class="project-named">*</span>确认密码</div>
        <div class="project-input">
          <el-input
            placeholder="请输入"
            v-model.trim="person.passwordN"
            :show-password="true"
          ></el-input>
          <span class="project-input-b" v-show="form6">登录密码不能为空</span>
        </div>
        <div class="project-name"><span class="project-named">*</span>选择角色</div>
        <div class="project-input">
          <el-select v-model="person.role" placeholder="请选择">
            <el-option
              v-for="item in roleOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span class="project-input-b" v-show="form7">角色选择不能为空</span>
        </div>
        <div class="project-btn ease" @click="addProjectForm">
          <div>创建账户</div>
        </div>
      </div>
    </transition>

    <!-- 批量创建 -->
    <transition name="fades">
      <div
        class="containerMask"
        v-if="dialogTableVisibles"
        @click="dialogTableVisibles = false"
      ></div>
    </transition>
    <transition name="fadee">
      <div
        class="container"
        v-if="dialogTableVisibles"
        ref="projectBox"
        id="projectBoxs"
        v-loading="addLoading"
      >
        <div class="addTitle">批量创建</div>
        <div class="project-close" @click="dialogTableVisibles = false">×</div>
        <div class="project-name">
          <span class="project-named">*</span>真实姓名
          <span class="adds-text1">" ; " 间隔</span>
          <span class="adds-text2">例：张三；李四</span>
        </div>
        <div class="project-input project-inputs project-inputsAdd">
          <el-input placeholder="请输入" v-model.trim="persons.name"></el-input>
          <span class="addItems ease" @click="addItemName">添加</span>
          <span class="project-input-b" v-show="form1"
            >真实姓名不能为空，输入真实姓名后，点击添加按钮</span
          >
        </div>
        <div class="addItemClasss">
          <span class="addItemClass" v-for="(item, index) in addItem" :key="index">
            {{ item }}
            <span class="closeItem" @click="deleteItem(index)">x</span>
          </span>
        </div>
        <div class="project-name"><span class="project-named">*</span>默认密码</div>
        <div class="project-input">
          <el-input
            placeholder="请输入"
            v-model.trim="persons.password"
            :show-password="true"
          ></el-input>
          <span class="project-input-b" v-show="form2">密码不能为空</span>
        </div>

        <div class="project-name"><span class="project-named">*</span>选择角色</div>
        <div class="project-input">
          <el-select v-model="persons.role" placeholder="请选择">
            <el-option
              v-for="item in roleOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <span class="project-input-b" v-show="form3">角色选择不能为空</span>
        </div>
        <div class="project-btn ease" @click="addProjectForms">
          <div>创建账户</div>
        </div>
      </div>
    </transition>
    <el-pagination
      class="pagination-low"
      @current-change="handleCurrentChange"
      :current-page="pageno"
      layout="total, prev, pager, next, jumper"
      :total="totalNumber"
    ></el-pagination>
  </div>
</template>

<script>
import {
  accountTotalList,
  accountTotalAdd,
  accountTotalAdds,
  accountTotalEdit,
  getAccountListDetail,
  roleTotalList,
  accountStatus,
  getPublicKeyApi,
} from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import { JSEncrypt } from "jsencrypt";

export default {
  components: {
    MainHeader,
    BreadCrumb,
  },

  mounted() {
    this.accounttotalList();
    this.roletotalList();
  },

  data() {
    return {
      //
      roleListName: {},
      //
      existNameList: "",
      form1: false,
      form2: false,
      form3: false,
      form4: false,
      form5: false,
      form6: false,
      form7: false,
      roleOptions: null,
      addItem: [],
      totalNumber: 10, //总数
      current: {},
      currentEdit: {},
      input: "",
      isEdit: false,
      addLoading: false,
      form: [0, 0, 0, 0, 0, 0, 0],
      addForm: [0, 0, 0, 0, 0, 0, 0],
      person: {
        name: "",
        password: "",
        passwordN: "",
        role: "",
      },
      persons: {
        password: "",
      },
      dialogTableVisible: false,
      dialogTableVisibles: false,
      pageno: 0,
      filter: {
        realName: "",
        status: "",
        time: [],
        source: "",
        roleName: "",
      },
      tableData: [],
      showDetail: false,
      loading: true,
      tableDataList: null,
      customItem: {},
    };
  },
  methods: {
    // 关闭账户详情
    showDetailFalse() {
      this.showDetail = false;
      this.isEdit = false;
    },

    elSwitch(item, tag) {
      this.disableorEnableUser(this.customItem.status == false ? 0 : 1, item.id, 10);
    },

    handleCurrentChange(val) {
      this.pageno = val;
      this.accounttotalList();
    },
    disableorEnableUser(status, id, tag = "") {
      let params = {
        status: status,
        userId: id,
      };
      accountStatus(params).then((res) => {
        if (!res && tag) {
          this.viewDetail(id);
          return;
        }
        if (res.msgCode == 1) {
          this.notify("切换账户状态成功！");
          this.accounttotalList();
        } else {
          this.errorTip(res.msgInfo);
        }
      });
    },
    // 角色列表
    roletotalList() {
      let params = {
        startTime: "",
        endTime: "",
        pageSize: 1000,
        pageNo: 0,
      };
      roleTotalList(params).then((res) => {
        if (res.msgCode == 1) {
          this.roleOptions = res.data.filter((item) => {
            this.roleListName[item.name] = item.id;
            return item.remark != 1;
          });
        } else {
          this.errorTip(res.msgInfo);
        }
        console.log(this.roleListName);
      });
    },

    deleteItem(index) {
      this.addItem.splice(index, 1);
      this.addItem.reverse().reverse();
    },

    addItemName() {
      if (!this.persons.name || !this.persons.name.trim()) {
        return;
      }
      if (this.persons.name.indexOf(";") > -1) {
        this.form1 = false;
        var dataList = this.persons.name.trim().split(";");
        for (let i = 0; i < dataList.length; i++) {
          if (!dataList[i].trim()) continue;
          this.addItem.push(dataList[i]);
        }
      } else if (this.persons.name.indexOf("；") > -1) {
        this.form1 = false;
        var dataList = this.persons.name.trim().split("；");
        for (let i = 0; i < dataList.length; i++) {
          if (!dataList[i].trim()) continue;
          this.addItem.push(dataList[i]);
        }
      } else {
        this.form1 = false;
        var dataList = this.persons.name.trim();
        this.addItem.push(dataList);
      }
      this.persons.name = "";
      this.addItem.reverse().reverse();
    },
    unique(arr) {
      return [...new Set(arr)];
    },

    isRepeat(arr) {
      var hash = {};
      for (var i in arr) {
        if (hash[arr[i]]) return true;
        hash[arr[i]] = true;
      }
      return false;
    },

    // 批量新增账户
    async accounttotalAdds() {
      if (this.addItem.length < 1) {
        this.form1 = true;
        return;
      }
      if (!this.persons.password.trim()) {
        this.form2 = true;
        return;
      }
      if (!this.persons.role) {
        this.form3 = true;
        return;
      }

      let isRepeat = this.isRepeat(this.addItem);
      if (isRepeat) {
        this.errorTip("此次提交存在相同的真实姓名，请检查后重试！");
        return;
      }
      let key = await getPublicKeyApi();
      let params = {
        password: this.encryptedData(key.data.data, this.persons.password),
        userName: this.addItem.join(";"),
        roleId: this.persons.role,
      };
      accountTotalAdds(params, localStorage.getItem("authorization")).then((res) => {
        if (res.msgCode == 1) {
          this.notify("添加账户成功！");
          this.persons = {};
          this.accounttotalList();
          this.dialogTableVisibles = false;
          this.addItem = [];
          this.form1 = false;
          this.form2 = false;
          this.form3 = false;
        } else {
          if (res.data) {
            this.existNameList = res.data.existNameList.toString();
            this.errorTip(
              res.msgInfo + this.existNameList + "已存在，请给姓名添加后缀或者更换姓名"
            );
            this.addItem = [];
          } else {
            this.errorTip(res.msgInfo);
          }
        }
      });
    },

    /* 加密 */
    encryptedData(publicKey, data) {
      let encryptor = new JSEncrypt({
        default_key_size: 2048,
      });
      // 设置公钥
      encryptor.setPublicKey(publicKey);
      // 加密数据
      return encryptor.encrypt(data);
    },

    // 单个新增账户
    async accounttotalAdd() {
      if (!this.person.name.trim()) {
        this.form4 = true;
        return;
      }
      if (!this.person.password.trim()) {
        this.form5 = true;
        return;
      }
      if (!this.person.passwordN.trim()) {
        this.form6 = true;
        return;
      }
      if (!this.person.role) {
        this.form7 = true;
        return;
      }
      if (this.person.password.trim() != this.person.passwordN.trim()) {
        this.errorTip("两次密码不一致！");
        return;
      }
      let key = await getPublicKeyApi();
      let params = {
        userName: this.person.name.trim(),
        password: this.encryptedData(key.data.data, this.person.password),
        confirmPassword: this.encryptedData(key.data.data, this.person.passwordN),
        roleId: this.person.role,
      };
      accountTotalAdd(params).then((res) => {
        if (res.msgCode == 1) {
          this.notify("添加账户成功！");
          this.person = {};
          this.accounttotalList();
          this.dialogTableVisible = false;
          this.form4 = false;
          this.form5 = false;
          this.form6 = false;
          this.form7 = false;
        } else {
          this.errorTip(res.msgInfo + "，" + "请给姓名添加后缀或者更换姓名");
        }
      });
    },

    accounttotalList() {
      let params = {
        userName: this.filter.realName.trim(),
        startCreateTime: this.filter.time ? this.filter.time[0] : "",
        endCreateTime: this.filter.time ? this.filter.time[1] : "",
        pageNum: this.pageno,
        pageSize: 10,
        roleName: this.filter.roleName.trim(),
      };
      accountTotalList(params).then((res) => {
        this.tableDataList = res.data.list;
        this.totalNumber = res.data.pageTotal;
      });
    },
    changeName() {
      if (!this.filter.realName) {
        this.search();
      }
    },
    changeroleName() {
      if (!this.filter.roleName) {
        this.search();
      }
    },
    search() {
      this.page = 0;
      this.accounttotalList();
    },

    removeEdit() {
      this.isEdit = false;
      this.currentEdit = {};
    },

    hold(id) {
      if (!this.currentEdit.roleName) {
        this.errorTip("请选择角色！");
        return;
      }
      let params = {
        id: id,
        userName: this.currentEdit.userName,
        roleId: this.currentEdit.roleName,
      };
      accountTotalEdit(params, localStorage.getItem("authorization")).then((res) => {
        if (res.msgCode == 1) {
          this.notify("修改账户成功！");
          this.isEdit = false;
          this.accounttotalList();
          this.viewDetail(this.actId);
        } else {
          this.errorTip(res.msgInfo);
        }
      });
      return;
    },

    detailEdit() {
      this.addForm = [0, 0, 0, 0, 0, 0, 0];
      this.currentEdit = JSON.parse(JSON.stringify(this.customItem));
      if (this.currentEdit.roleName) {
        this.currentEdit.roleName = this.roleListName[this.currentEdit.roleName];
      }
      console.log(this.currentEdit.roleName);
      this.isEdit = true;
    },

    addProjectForm() {
      this.accounttotalAdd();
    },

    addProjectForms() {
      this.accounttotalAdds();
    },

    viewDetail(id) {
      this.actId = id;
      this.showDetail = true;
      let params = {
        userId: id,
      };
      getAccountListDetail(params).then((res) => {
        this.customItem = res.data;
        if (this.customItem.status == 1) {
          this.customItem.status = true;
        } else {
          this.customItem.status = false;
        }
        this.loading = false;
      });
    },

    showHiddenDetail() {
      this.showDetail = false;
      this.isEdit = false;
    },

    // 成功提示
    notify(text) {
      this.$notify({
        title: "温馨提示",
        message: text,
        type: "success",
      });
    },

    // 错误提示
    errorTip(text) {
      this.$message({
        message: text,
        type: "warning",
        duration: 4000,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import './common.css';

.addTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  padding: 24px 0 20px 0;
}

.container {
  position: absolute;
  left: 50%;
  margin-left: -240px;
  top: 15vh;
  width: 480px;
  height: 480px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
}

>>> #projectBoxs .el-input {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs >>>.el-input >>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #projectBoxs .el-input__icon {
  line-height: 36px;
}

>>> #projectBoxs .el-cascader {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input, .addForms {
  position: relative;
}

.project-input-b {
  position: absolute;
  right: 40px;
  top: 40px;
  font-size: 12px;
  color: red;
}

.project-input .el-input {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-inputsAdd .el-input {
  width: 330px;
}

.project-inputs .el-input {
  margin-bottom: 16px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.project-name {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 0 0 4px 32px;
}

.project-named {
  position: relative;
  top: 2px;
  margin-right: 4px;
  color: #f5222d;
}

.project-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  line-height: 68px;
  background: rgba(47, 84, 235, 1);
  box-shadow: 0px -1px 0px 0px rgba(47, 84, 235, 1);
  z-index: 1004;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.project-close {
  position: absolute;
  cursor: pointer;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  top: 6px;
  right: 20px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.btn-view {
  line-height: 28px;
  height: 28px;
  color: #2f54eb;
  margin-right: 10px;
  display: inline-block;
}

.btn-view:hover {
  color: rgb(8, 47, 202);
}

.filter-body {
  margin: 20px;
  padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  // padding: 20px;
  // padding-top: 20px;
  // padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-top: 16px;

  .inputs-wrapper {
    width: 92%;

    .inputs-wraper-row {
      display: flex;
      flex-wrap: wrap;

      .form-item {
        margin-right: 40px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .label {
          white-space: nowrap;
          width: 69px;
          text-align: right;
          margin-right: 8px;
        }

        display: flex;
      }
    }
  }

  .btn-look-up {
    margin-right: 16px;
    width: 76px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #2F54EB;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.btn-blue {
  color: rgba(47, 84, 235, 1);
  cursor: pointer;
}

.done, .pedding, .delete, .perfect {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 7px;
  margin-bottom: 2px;
}

.done {
  background: rgba(82, 196, 26, 1);
}

.pedding {
  background: rgba(217, 217, 217, 1);
}

.delete {
  background: rgba(245, 34, 45, 1);
}

.perfect {
  background: rgba(250, 173, 20, 1);
}

.fixed-item {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  width: 496px;
  height: 100%;
  z-index: 1003;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(251, 251, 251, 1);
  box-shadow: -4px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.fixed-content {
  width: 464px;
  height: 228px;
  background: rgba(240, 245, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(214, 228, 255, 1);
  margin: 16px 0 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.fixed-item-top {
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid rgba(218, 213, 232, 0.6);
}

.fixed-item-icon {
  width: 30px;
  height: 30px;
  padding: 20px 8px 16px 20px;
}

.content-title {
  margin: 19px 0 0 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
}

.content-text {
  margin: 8px 0 24px 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.content-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  line-height: 60px;
  background: rgba(47, 84, 235, 1);
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  cursor: pointer;
}

.content-btn:hover {
  background: rgba(47, 34, 235, 1);
}

.addItem {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 20px;
  cursor: pointer;
}

.addItem:hover {
  background: rgba(47, 34, 235, 1);
}

.project-btn:hover {
  background: rgba(47, 34, 235, 1);
}

.editActive:before {
  content: '*';
  color: red;
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.content-isEdit {
  margin-bottom: 16px;
}

>>> #content-isEdit .el-input {
  width: 416px;
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #content-isEdit .el-cascader {
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input__icon {
  line-height: 36px;
}

.fixedItemEdit {
  height: 260px;
}

.detailContent {
  display: flex;
  align-item: center;
}

.detailcancel {
  width: 50%;
}

.detailcancel:after {
  content: '|';
  position: relative;
  left: 108px;
  top: 1px;
}

.detailSaves {
  width: 50%;
}

>>> .form-item .el-input__icon {
  line-height: 36px;
  height: 36px;
}

.paginationNew {
  float: right;
  margin-right: 30px;
  margin-bottom: 24px;
}

.isEditPadding {
  margin-top: 4px;
}

.editPadding {
  margin-top: 16px;
}

.additemInline {
  display: inline-block;
}

.addItems {
  display: inline-block;
  width: 76px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(47, 84, 235, 1);
  font-size: 14px;
  color: rgba(47, 84, 235, 1);
  margin-left: 8px;
}

.addItems:hover {
  color: #fff;
  background: rgba(47, 84, 235, 1);
  cursor: pointer;
}

.ease {
  transition: all 0.3s;
}

.addItemClasss {
  margin-left: 32px;
  margin-bottom: 8px;
  margin-top: -8px;
}

.addItemClass {
  display: inline-block;
  padding: 0 14px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(82, 196, 26, 0.08);
  border-radius: 4px;
  border: 1px solid rgba(82, 196, 26, 0.48);
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 8px;
  margin-bottom: 8px;
}

.closeItem {
  position: relative;
  left: 2px;
  font-size: 16px;
  color: #52C41A;
  cursor: pointer;
  padding: 4px;
}

.circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: rgba(82, 196, 26, 1);
  border-radius: 50%;
  margin-right: 6px;
}

.circle1 {
  background: #F5222D;
}

.selectAdd {
  margin-right: 18px;
  color: #2F54EB;
}

.el-icon-edit-outline {
  color: #2F54EB;
  padding: 0 6px;
  cursor: pointer;
}

.switch {
  margin-left: 25px;
}

.switchTetx {
  position: relative;
  top: 3px;
  margin-left: 2px;
  font-size: 14px;
  color: rgba(245, 34, 45, 1);
  line-height: 80px;
}

.switchTetxb {
  color: rgba(82, 196, 26, 1);
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: inset 0px -1px 0px 0px #EEEEEE;
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.adds-text1 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 14px;
}

.adds-text2 {
  font-size: 14px;
  color: rgba(250, 173, 20, 1);
  margin-left: 8px;
}

.pagination-low {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}

.el-icon-edit-outline {
  color: #2f54eb;
  font-size: 18px;
  position: relative;
  top: 2px;
}

>>>.el-range-editor.el-input__inner {
  width: 240px;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.el-select {
  width: 240px;
}

#elinput >>>.el-input {
  width: 240px;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

>>>.el-pagination__editor.el-input {
  width: 50px;
}

>>>.el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

>>>.el-input__icon {
  width: 14px;
}

>>>.el-range-editor.el-input__inner {
  padding: 3px 8px;
}
</style>
