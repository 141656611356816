<template>
  <div>
    <transition name="fades">
      <div class="containerMask" @click="closeDialog" v-if="dialogTableVisible"></div>
    </transition>
    <transition name="fadee">
      <div class="container" v-if="dialogTableVisible" v-loading="addLoading">
        <div class="boxTitle">
          <div>创建新项目</div>
          <div class="project-close" @click="closeDialog">×</div>
        </div>
        <div class="selectBox">
          <span class="selectName">选择客户：</span>
          <el-radio
            v-model="selectCustom"
            label="1"
            v-if="this.customIdList && this.customIdList.length > 0"
            >选择已有客户</el-radio
          >
          <el-radio v-model="selectCustom" label="2">创建新客户</el-radio>
        </div>
        <div
          v-show="selectCustom == 1"
          class="selectCustom prjectSet flex-custom selectCustom1"
        >
          <span class="selectName selectName-n"
            ><span class="project-named">*</span>企业名称：</span
          >
          <el-select
            v-model="value"
            @change="selectChange"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in customDataList"
              :key="item.consumerId"
              :label="item.companyName"
              :value="item.consumerId"
            ></el-option>
          </el-select>
          <!-- 补全行业 -->
          <div
            class="selectNameTop flex-custom"
            v-if="customPageList && !customPageList.industry"
          >
            <span class="selectName selectName-n filed">行业：</span>
            <el-select v-model="hyListValue" filterable placeholder="请选择">
              <el-option
                v-for="item in hyList"
                :key="item.hy_dm"
                :label="item.hymc"
                :value="item.hy_dm"
              ></el-option>
            </el-select>
          </div>
          <!-- 补全行业 -->
          <!-- 补全地区 -->
          <div
            class="selectNameTop flex-custom"
            v-if="customPageList && !customPageList.cityCode"
          >
            <span class="selectName selectName-n filed">地区：</span>
            <el-cascader :options="city" v-model="fixArea"></el-cascader>
          </div>
          <!-- 补全地区 -->
          <div v-if="value" class="customDetail">
            <div class="item">
              <div class="leftItem">
                <div>企业名称</div>
                <div class="detail">{{ customPageList.companyName }}</div>
              </div>
            </div>
            <div class="item">
              <div class="leftItem">
                <div>纳税人识别号</div>
                <div class="detail">{{ customPageList.nsrsbh }}</div>
              </div>
              <div class="rightItem">
                <div>企业规模</div>
                <div class="detail">{{ customPageList.scale }}</div>
              </div>
            </div>
            <div class="item">
              <div class="leftItem">
                <div>企业地区</div>
                <div v-if="conversionArea" class="detail">
                  {{ conversionArea }}
                </div>
              </div>
              <div class="rightItem">
                <div>联系电话</div>
                <div class="detail">{{ customPageList.contactNumber }}</div>
              </div>
            </div>
            <div class="item">
              <div class="leftItem">
                <div>企业行业</div>
                <div class="detail">
                  {{ dataList[customPageList.industry] }}
                </div>
              </div>
              <div class="rightItem">
                <div>业务员</div>
                <div class="detail">{{ customPageList.salesman }}</div>
              </div>
            </div>
            <div class="item">
              <div class="leftItem">
                <div>联系人</div>
                <div class="detail">{{ customPageList.contact }}</div>
              </div>
            </div>
          </div>
          <div class="division division1" v-show="!value">
            <el-divider></el-divider>
          </div>
          <div class="selectNameTop selectNameTop-projectName flex-custom">
            <span class="selectName selectName-n selectName-6">项目名称：</span>
            <el-input
              placeholder="请输入"
              v-model="projectNameType1"
              maxlength="30"
              show-word-limit
            ></el-input>
          </div>
          <div class="selectNameTop selectNameTop-projectName flex-custom">
            <span class="selectName selectName-n">
              <span class="project-named">*</span>
              项目经理：</span
            >
            <el-input placeholder="请输入" disabled v-model="userRealName"></el-input>
          </div>
        </div>
        <div v-show="selectCustom == 2" class="selectCustomB custom-overflow">
          <div class="project-name"><span class="project-named">*</span>纳税人识别号</div>
          <div class="project-input">
            <el-input
              placeholder="请输入"
              v-model.trim="person.nsrsbh"
              maxlength="20"
              show-word-limit
              @blur="msgBlur"
            ></el-input>
            <span class="project-input-b" v-show="form[0]">纳税人识别号不能为空</span>
            <span class="project-input-b" v-show="form[1]"
              >最低15位最多20位数字+字母组合</span
            >
          </div>
          <div class="project-name"><span class="project-named">*</span>企业名称</div>
          <div class="project-input">
            <el-input
              placeholder="请输入"
              v-model="person.companyName"
              maxlength="30"
              show-word-limit
              :disabled="checkTaxNo"
            ></el-input>
            <span class="project-input-b" v-show="form[2]">企业名称不能为空</span>
          </div>
          <div class="project-name"><span class="project-named">*</span>所属地区</div>
          <div class="project-input">
            <el-cascader :options="city" v-model="persons.area"></el-cascader>
            <span class="project-input-b" v-show="form[3]">地区不能为空</span>
          </div>
          <div class="project-name"><span class="project-named">*</span>所属行业</div>
          <div class="project-input">
            <el-select v-model="person.industry" placeholder="请选择" filterable>
              <el-option
                v-for="item in hyList"
                :key="item.hy_dm"
                :label="item.hymc"
                :value="item.hy_dm"
              ></el-option>
            </el-select>
            <span class="project-input-b" v-show="form[4]">行业不能为空</span>
          </div>
          <div class="project-name">
            <span class="selectName-12">企业规模</span>
          </div>
          <div class="project-input">
            <el-select v-model="person.scale" placeholder="请选择">
              <el-option
                v-for="item in qyStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <!-- <div class="project-name"><span class="project-named"></span>项目名称</div>
          <div class="project-input">
            <el-input
              placeholder="请输入"
              v-model="person.projectName"
              maxlength="30"
              show-word-limit
            ></el-input>
          </div> -->
          <div class="project-name"><span class="selectName-12">业务员</span></div>
          <div class="project-input">
            <el-input
              placeholder="请输入"
              v-model="person.salesman"
              maxlength="20"
              show-word-limit
            ></el-input>
          </div>
          <div class="project-name"><span class="selectName-12">联系人</span></div>
          <div class="project-input">
            <el-input
              placeholder="请输入"
              v-model="person.contact"
              maxlength="20"
              show-word-limit
            ></el-input>
          </div>
          <div class="project-name"><span class="selectName-12">联系电话</span></div>
          <div class="project-input">
            <el-input
              placeholder="请输入"
              v-model="person.contactNumber"
              show-word-limit
            ></el-input>
          </div>
          <div
            class="project-btn ease"
            v-show="selectCustom == 2"
            @click="addProjectForm"
          >
            <div>创建项目</div>
          </div>
        </div>
        <div v-show="selectCustom == 2">
          <div class="division">
            <el-divider></el-divider>
          </div>
          <div class="project-name"><span class="selectName-12">项目名称</span></div>
          <div class="project-input">
            <el-input
              placeholder="请输入"
              v-model="person.projectName"
              maxlength="30"
              show-word-limit
            ></el-input>
          </div>
          <div class="project-name"><span class="project-named">*</span>项目经理</div>
          <div class="project-input">
            <el-input placeholder="请输入" disabled v-model="userRealName"></el-input>
          </div>
        </div>
        <div class="nexts ease" @click="nextTapOpen" v-show="selectCustom == 1">
          下一步
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  getCustomListNew,
  getTradeList,
  editCustom,
  getRegion,
  addCustomBoth,
  getCompanyMsgByTaxNo,
} from "@/api/api";

import { cloneDeep } from "lodash";

import { qyStatusList } from "@/pages/project/help.js";
import { veriFiItem, area, veriFiItemAdds } from "@/pages/product/utils/limit.js";

export default {
  props: {
    uuid: [String, Number],
  },
  data() {
    return {
      projectNameType1: "",
      hyList: null,
      // 补全
      hyListValue: "",
      fixKjzd: "",
      fixArea: "",
      // 补全
      dialogTableVisible: false,
      selectCustom: "2",
      value: "",
      customDataList: null,
      customIdList: null,
      dataList: null,
      conversionArea: "",
      customPageList: null,
      person: {
        companyName: "",
      },
      form: [0, 0, 0, 0, 0, 0, 0],
      form11: false,
      qyStatusList: [
        { value: "小微企业", label: "小微企业" },
        { value: "中型企业", label: "中型企业" },
        { value: "大型企业", label: "大型企业" },
      ],
      persons: {},
      addLoading: false,
      saveinDustry: false,
      saveinArea: false,
      areaTree: [],
      companyList: {},
      checkTaxNo: false,
      available: false,
      userRealName: JSON.parse(localStorage.getItem("user")).realName,
    };
  },
  created() {
    this.getRegionData();
    this.getCustomList();
    this.gettradeList();
  },
  computed: {
    city: function () {
      if (!this.areaTree) {
        return [];
      }
      const ret = cloneDeep(this.areaTree);
      function walk(data) {
        data.value = data.code;
        data.label = data.name;
        if (data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child);
          }
        }
        delete data.childNode;
      }
      for (const areaTree of ret) {
        walk(areaTree);
      }
      return [...ret];
    },
  },
  methods: {
    msgBlur() {
      if (!this.person.nsrsbh) return;
      this.getMsgByTaxNo();
    },
    getMsgByTaxNo() {
      getCompanyMsgByTaxNo(this.person.nsrsbh).then((res) => {
        if (res.msgCode == 1) {
          this.companyList = res.data;
          this.person.companyName = this.companyList.name;
          this.checkTaxNo = true;
        } else {
          this.checkTaxNo = false;
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
        }
      });
    },
    getRegionData() {
      getRegion().then((res) => {
        this.areaTree = res.data;
      });
    },
    nextTap() {
      this.$emit("nextTap");
      this.dialogTableVisible = false;
    },
    nextTapOpen() {
      if (!this.value) {
        this.$message({
          message: "请选择企业！",
          type: "warning",
        });
        return;
      }
      if (!this.hyListValue && this.saveinDustry) {
        this.$message({
          message: "请补全行业后继续！",
          type: "error",
        });
        return;
      }
      if (!this.fixArea && this.saveinArea) {
        this.$message({
          message: "请补全地区后继续！",
          type: "error",
        });
        return;
      }
      // 补全行业,会计制度,地区
      if (this.saveinDustry || this.saveinArea) {
        this.addLoading = true;
        let data = {
          id: this.customPageList.id,
          industry: this.customPageList.industry || this.hyListValue,
        };
        if (
          this.customPageList.provinceCode &&
          this.customPageList.cityCode &&
          this.customPageList.districtCode
        ) {
          data.provinceCode = this.customPageList.provinceCode;
          data.cityCode = this.customPageList.cityCode;
          data.districtCode = this.customPageList.districtCode;
        } else {
          data.provinceCode = this.fixArea[0];
          data.cityCode = this.fixArea[1];
          data.districtCode = this.fixArea[2];
        }
        editCustom(data).then((res) => {
          if (res.msgCode == 1) {
            this.saveCustom();
          } else {
            this.addLoading = false;
            this.$message({
              message: res.msgInfo,
              type: "error",
            });
          }
        });
      } else {
        this.addLoading = true;
        this.saveCustom();
      }
    },

    saveCustom() {
      getCompanyMsgByTaxNo(this.customPageList.nsrsbh).then((res) => {
        if (res.msgCode == 1) {
          let params = {
            id: this.customPageList.id,
            uuid: this.uuid,
            projectManager: JSON.parse(localStorage.getItem("user")).realName,
            projectManagerId: JSON.parse(localStorage.getItem("user")).id,
            projectName: this.projectNameType1,
          };
          addCustomBoth(params).then((res) => {
            this.addLoading = false;
            if (res.msgCode == 1) {
              let newInfo = res.data;
              localStorage.setItem("projectCustomInfo", JSON.stringify(newInfo));
              // 存税号
              sessionStorage.setItem("manualNsrsbh", this.customPageList.nsrsbh);
              this.$notify({
                title: "温馨提示",
                message: "创建项目成功！",
                type: "success",
              });
              this.$emit("supplement", {
                nsrsbh: res.data.nsrsbh,
                kjzd: res.data.consumerEntity.kjzd,
                newInfo: newInfo,
              });
              this.dialogTableVisible = false;
              this.person = {};
              this.persons = {};
            }
          });
        } else {
          this.addLoading = false;
          this.$confirm("客户纳税人识别号有误，请重新创建客户?", "提示", {
            confirmButtonText: "创建客户",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push({ name: "custom", query: { create: 1 } });
            })
            .catch(() => {});
        }
      });
      return;
    },

    openDialog() {
      this.dialogTableVisible = true;
    },
    selectChange() {
      this.saveinDustry = false;
      this.saveinArea = false;
      this.fixKjzd = "";
      this.fixArea = "";
      this.hyListValue = "";
      let selectIndex = this.customIdList.findIndex((item) => {
        return this.value == item;
      });
      this.customPageList = this.customDataList[selectIndex];
      if (!this.customPageList.industry) {
        this.saveinDustry = true;
      }
      if (!this.customPageList.cityCode) {
        this.saveinArea = true;
      }
      this.conversionArea = area(
        [
          this.customPageList.provinceCode,
          this.customPageList.cityCode,
          this.customPageList.districtCode,
        ],
        this.city
      );
    },
    closeDialog() {
      this.dialogTableVisible = false;
      this.$emit("changeHaveProject");
    },

    resetFormCheck() {
      this.form = [0, 0, 0, 0];
      let verification = veriFiItem(this.person, 3, this.persons);
      this.form[verification.code] = 1;
      this.form.reverse().reverse();
      if (!verification.status) {
        return false;
      } else {
        return true;
      }
    },

    addProjectForm() {
      if (!this.resetFormCheck()) return;
      // if (!this.checkTaxNo) {
      //   this.$message({
      //     message: "请输入正确税号！",
      //     type: "warning",
      //   });
      //   return;
      // }
      this.addLoading = true;
      this.person.provinceCode = this.persons.area[0];
      this.person.cityCode = this.persons.area[1];
      this.person.districtCode = this.persons.area[2];
      this.person.uuid = this.uuid;
      this.person.projectManager = JSON.parse(localStorage.getItem("user")).realName;
      this.person.projectManagerId = JSON.parse(localStorage.getItem("user")).id;
      getCompanyMsgByTaxNo(this.person.nsrsbh).then((res) => {
        if (res.msgCode == 1) {
          addCustomBoth(this.person).then((res) => {
            this.addLoading = false;
            if (res.msgCode == 1) {
              let newInfo = res.data;
              localStorage.setItem("projectCustomInfo", JSON.stringify(newInfo));
              sessionStorage.setItem("nsrsbh", res.data.nsrsbh);
              // 存税号
              sessionStorage.setItem("manualNsrsbh", this.person.nsrsbh);
              this.dialogTableVisible = false;
              this.$notify({
                title: "温馨提示",
                message: "创建项目成功！",
                type: "success",
              });
              this.$emit("supplement", {
                nsrsbh: res.data.nsrsbh,
                kjzd: res.data.consumerEntity.kjzd,
                newInfo: newInfo,
              });
              this.person = {};
              this.persons = {};
            }
          });
        } else {
          this.addLoading = false;
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
        }
      });
    },

    getCustomList() {
      let params = {
        pageNo: 0,
        pageSize: 1000,
      };
      getCustomListNew(params).then((res) => {
        if (res.data.msgCode == 1) {
          this.customDataList = res.data.data.content;
          this.customIdList = res.data.data.content.map((item) => {
            return item.consumerId;
          });
          this.$emit("customIdLength", this.customIdList.length);
        } else {
          this.$message.error(res.data.msgInfo);
          this.$emit("customIdLength", 0);
        }
      });
    },
    gettradeList() {
      getTradeList().then((res) => {
        let data = JSON.parse(res.data);
        this.hyList = data;
        let dataList = {};
        for (let i = 0; i < this.hyList.length; i++) {
          this.hyList[i].hymc = this.hyList[i].hy_dm + this.hyList[i].hymc;
          dataList[this.hyList[i].hy_dm] = this.hyList[i].hymc;
        }
        this.dataList = dataList;
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.containerMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1002;
}

.container {
  position: absolute;
  left: 50%;
  margin-left: -240px;
  top: 5vh;
  width: 500px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
  padding-bottom: 58px;
}

.fadee-enter-active {
  transition: all 0.3s ease;
}

.fades-enter-active {
  transition: all 0.3s ease;
}

.fadee-leave-active {
  transition: all 0.2s ease;
}

.fades-leave-active {
  transition: all 0.3s ease;
}

.fadee-enter, .fadee-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.fades-enter, .fades-leave-to {
  opacity: 0;
}

.ease {
  transition: all 0.3s;
}

.boxTitle {
  height: 54px;
  background: #FFFFFF;
  box-shadow: 0px 1px 0px 0px #EEEEEE;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 54px;
  padding-left: 24px;
  color: #FAAD14;
  text-align: center;
}

.project-close {
  position: absolute;
  cursor: pointer;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  top: -14px;
  right: 8px;
}

.selectName {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
}

.selectBox {
  margin: 24px 0 0 24px;
}

.selectCustom {
  margin: 16px 0 12px 32px;
}

.nexts {
  height: 52px;
  background: #2F54EB;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 52px;
  text-align: center;
  cursor: pointer;
}

.customDetail {
  margin: 16px 34px 16px 0;
  background: #F0F5FF;
  border-radius: 4px;
  border: 1px solid #D6E4FF;
  padding: 18px;
}

.item {
  display: flex;
  margin-bottom: 24px;
}

.item:last-child {
  margin-bottom: 0;
}

.leftItem, .rightItem {
  width: 50%;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
}

.detail {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.nexts:hover {
  background: rgba(47, 34, 235, 1);
}

.container >>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

.container >>>.el-input__icon {
  line-height: 36px;
}

.selectNameTop {
  margin-top: 14px;
}

.project-name {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 0 0 4px 32px;
}

.project-named {
  position: relative;
  top: 2px;
  margin-right: 4px;
  color: #f5222d;
}

.project-input, .addForms {
  position: relative;
}

.project-input-b {
  position: absolute;
  right: 40px;
  top: 40px;
  font-size: 12px;
  color: red;
}

.project-input .el-input {
  width: 435px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input .el-select {
  width: 435px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 52px;
  line-height: 52px;
  background: rgba(47, 84, 235, 1);
  box-shadow: 0px -1px 0px 0px rgba(47, 84, 235, 1);
  z-index: 1004;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.project-btn:hover {
  background: rgba(47, 34, 235, 1);
}

.selectCustomB .el-cascader {
  width: 435px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.selectCustomB {
  margin-top: 16px;
  max-height: 307px;
  overflow-x: hidden;
}

.filed:before {
  content: '*';
  color: red;
  position: relative;
  right: 2px;
}

.prjectSet >>> .el-input__inner {
  height: 36px;
  line-height: 36px;
  width: 435px;
}

.selectNameTop-projectName {
  display: flex;
  // align-items: center;

  >>> .el-input {
    width: 435px;
  }

  >>> .el-input__inner {
    width: 435px;
  }
}

.division {
  padding: 0 35px 0 32px;

  .el-divider--horizontal {
    margin: 10px 0 22px 0;
  }
}

.division1 {
  padding: 16px 35px 0 2px;

  .el-divider--horizontal {
    margin-bottom: 6px;
  }
}

.flex-custom {
  display: flex;
  flex-direction: column;
}

.selectCustom1 {
  >>> .el-input  {
    width: 435px;
  }

  >>> .el-select {
    width: 435px;
  }
}

.selectName-n {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0,0,0,0.8);
  line-height: 20px;
  margin: 0 0 4px 0;
}

.selectName-6 {
  margin-left: 12px;
}

.selectName-12 {
  margin-left: 10px;
}
</style>
