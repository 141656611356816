<template>
  <div class="container" v-loading="loadingItem" id="projectBoxs">
    <div class="addTitle">
      {{ isShowDetailId == -1 ? "创建房地产项目" : "修改房地产项目" }}
    </div>
    <div class="project-close" @click="closeBox">×</div>
    <div class="overflow-xx overflow-xy">
      <!-- 选择已有客户 -->
      <div v-show="createMethod == 1">
        <div class="project-name project-Title-Flex">
          <div><span class="project-named">*</span>企业名称</div>
          <div
            class="toCreateCustoms"
            @click="toCreateCustom"
            v-if="isShowDetailId == -1"
          >
            <span class="tocreateFont">没有企业？</span>前往创建
          </div>
        </div>
        <div class="project-input">
          <el-select
            v-model="projectList.consumerId"
            :disabled="isShowDetailId != -1"
            placeholder="请选择"
            @change="customChange"
            filterable
          >
            <el-option
              v-for="item in tableDataLists"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <transition name="customFade">
          <div class="fixed-bottom project-info" v-if="checkCustom">
            <div class="item-flex1">
              <div class="content-title">纳税人识别号：</div>
              <div class="content-text content-text-bottom">
                {{ personInfo.nsrsbh }}
              </div>
              <div class="content-title">所属地区：</div>
              <template v-if="city && city.length > 0">
                <div class="content-text content-text-bottom" v-if="personInfo.cityCode">
                  {{ conversionAreas }}
                </div>
                <div class="content-text content-text-bottom" v-else>暂无地区信息</div>
              </template>
              <div class="content-title">所属行业：</div>
              <div class="content-text content-text-bottom">
                <span v-if="personInfo.industry">{{
                  dataList[personInfo.industry]
                }}</span>
                <span v-else>暂无行业信息</span>
              </div>
              <div class="content-title">联系人：</div>
              <div class="content-text content-text-bottom">
                {{ personInfo.contact }}
              </div>
            </div>
            <div class="item-flex1">
              <div class="content-title">企业规模：</div>
              <div class="content-text content-text-bottom">
                {{ personInfo.scale }}
              </div>
              <div class="content-title">业务员：</div>
              <div class="content-text content-text-bottom">
                {{ personInfo.salesman }}
              </div>
              <div class="content-title">联系电话：</div>
              <div class="content-text content-text-bottom">
                {{ personInfo.contactNumber }}
              </div>
            </div>
          </div>
        </transition>
        <!-- 补全地区 -->
        <div class="project-name" v-if="cityCodeBoolean">
          <span> <span class="project-named">*</span>地区 </span>
        </div>
        <div class="project-input" v-if="cityCodeBoolean">
          <el-cascader :options="city" v-model="fixArea"></el-cascader>
        </div>
        <!-- 补全地区 -->
        <!-- 补全行业 -->
        <div class="project-name" v-if="industryBoolean">
          <span> <span class="project-named">*</span>行业 </span>
        </div>
        <div class="project-input" v-if="industryBoolean">
          <el-select v-model="fixHyListValue" filterable placeholder="请选择">
            <el-option
              v-for="item in hyList"
              :key="item.hy_dm"
              :label="item.hymc"
              :value="item.hy_dm"
            ></el-option>
          </el-select>
        </div>
        <!-- 补全行业 -->
      </div>
      <!-- 创建客户并创建项目 -->
      <div v-show="createMethod == 2">
        <div class="project-name project-Title-Flex">
          <span> <span class="project-named">*</span>纳税人识别号 </span>
          <div class="toCreateCustoms" @click="createMethod = 1">
            <span class="tocreateFont tocreateFontCompany toCreateCustoms"
              >选择已有企业</span
            >
          </div>
        </div>
        <div class="project-input">
          <el-input
            placeholder="请输入"
            v-model.trim="consumerVo.nsrsbh"
            maxlength="20"
            show-word-limit
          ></el-input>
        </div>
        <div class="project-name">
          <span> <span class="project-named">*</span>企业名称 </span>
        </div>
        <div class="project-input">
          <el-input
            placeholder="请输入"
            v-model.trim="consumerVo.companyName"
            maxlength="20"
            show-word-limit
          ></el-input>
        </div>
        <div class="project-name"><span class="project-named">*</span>所属地区</div>
        <div class="project-input">
          <el-cascader filterable :options="city" v-model="consumerVo.area"></el-cascader>
        </div>
        <div class="project-name"><span class="project-named">*</span>所属行业</div>
        <div class="project-input">
          <el-select v-model="consumerVo.industry" placeholder="请选择" filterable>
            <el-option
              v-for="item in hyList"
              :key="item.hy_dm"
              :label="item.hymc"
              :value="item.hy_dm"
            ></el-option>
          </el-select>
        </div>
        <div class="project-name"><span class="project-named"></span>企业规模</div>
        <div class="project-input">
          <el-select v-model="consumerVo.scale" placeholder="请选择">
            <el-option
              v-for="item in qyStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div>
          <div class="project-name"><span class="project-named"></span>业务员</div>
          <div class="project-input">
            <el-input
              placeholder="请输入"
              v-model="consumerVo.salesman"
              maxlength="20"
              show-word-limit
            ></el-input>
          </div>
          <div class="project-name"><span class="project-named"></span>联系人</div>
          <div class="project-input">
            <el-input
              placeholder="请输入"
              v-model="consumerVo.contact"
              maxlength="20"
              show-word-limit
            ></el-input>
          </div>
          <div class="project-name"><span class="project-named"></span>联系电话</div>
          <div class="project-input">
            <el-input placeholder="请输入" v-model="consumerVo.contactNumber"></el-input>
          </div>
        </div>
      </div>
      <div class="project-name">
        <span> <span class="project-named">*</span>房地产项目名称 </span>
      </div>
      <div class="project-input">
        <el-input
          placeholder="请输入"
          v-model.trim="projectName"
          maxlength="20"
          show-word-limit
        ></el-input>
      </div>
      <div class="project-name">
        <span> <span class="project-named">*</span>房地产项目地址 </span>
      </div>
      <div class="project-input">
        <el-input
          placeholder="请输入"
          v-model.trim="projectAreaFdc"
          maxlength="50"
          show-word-limit
        ></el-input>
      </div>
      <div class="project-divider">
        <el-divider></el-divider>
      </div>
      <div class="project-fdc">
        <div class="project-fdc-tip">以下内容财税风险检测时需要使用，请按需填写</div>
        <div class="project-fdc-is">
          <div>是否甲供材：</div>
          <div>
            <el-radio-group v-model="baseInfoObject.armorForMaterials">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="project-fdc-priceTxt">备案单价(区间)：</div>
        <div class="project-fdc-price">
          <el-input
            v-model="baseInfoObject.projectMinMoney"
            placeholder="请输入"
          ></el-input>
          <span class="project-fdc-priceTdiv">-</span>
          <el-input
            v-model="baseInfoObject.projectMaxMoney"
            placeholder="请输入"
          ></el-input>
        </div>
        <div class="project-fdc-is">
          <div>计税方式：</div>
          <div>
            <el-radio-group v-model="baseInfoObject.taxCode">
              <el-radio :label="1">一般计税</el-radio>
              <el-radio :label="2">简易计税</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="project-fdc-area">
          <div>项目所属区域：</div>
          <div>
            <el-radio-group v-model="baseInfoObject.projectArea">
              <el-radio :label="1"
                >开发项目位于省/自治区/直辖市和计划单列市人民政府所在地城市城区和郊区</el-radio
              >
              <el-radio :label="2">开发项目位于地级市城区及郊区</el-radio>
              <el-radio :label="3">开发项目位于其他地区</el-radio>
              <el-radio :label="4">属于经记适用房/限价房和危改房</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="project-name"><span class="project-named">*</span>项目经理</div>
      <div class="project-input">
        <el-input
          v-model="projectManagerIdName"
          placeholder="请选择"
          :disabled="true"
        ></el-input>
      </div>
    </div>
    <div v-if="isShowDetailId == -1">
      <div class="project-btn ease" @click="createProject" v-if="createMethod == 1">
        <div>创建项目</div>
      </div>
      <div class="project-btn ease" @click="createProjectBoth" v-if="createMethod == 2">
        <div>创建项目</div>
      </div>
    </div>
    <div v-else>
      <div class="project-btn ease" @click="editProject">
        <div>修改项目</div>
      </div>
    </div>
  </div>
</template>

<script>
import { area } from "@/pages/product/utils/limit.js";
import { editCustom } from "@/api/api";
import {
  bSuperiorProjectAdd,
  bSuperiorProjectAddBoth,
  bSuperiorProjectDetail,
  bSuperiorProjectEdit,
} from "@/api/fdc";
import { qyStatusList } from "../help.js";

export default {
  props: {
    tableDataLists: [Object, Array],
    tableDataId: [Object, Array],
    dataList: [Object, Array],
    city: [Object, Array],
    hyList: [Object, Array],
    isShowDetailId: [Number, String],
  },
  data() {
    return {
      loadingItem: false,
      checkCustom: false,
      projectList: {
        consumerId: "",
      },
      disabled: false,
      projectManagerIdName: JSON.parse(localStorage.getItem("user")).realName,
      personOptionId: [0, 1],
      fixArea: "", //补全地区
      fixHyListValue: "", //补全行业
      cityCodeBoolean: false, //是否显示地区框框
      industryBoolean: false, //行业补全
      customID: Number(this.$route.query.id),
      //
      projectName: "",
      projectArea: 1,
      armorForMaterials: 0,
      taxCode: 1,
      projectAreaFdc: "",
      projectMaxMoney: "",
      projectMinMoney: "",
      createMethod: 1,
      industry: "",
      area: "",
      qyStatusList: qyStatusList,
      scale: "",
      salesman: "",
      contact: "",
      contactNumber: "",
      custom: {
        area: "",
        industry: "",
        scale: "",
        salesman: "",
        contact: "",
        contactNumber: "",
      },
      baseInfoObject: {
        armorForMaterials: 5,
        projectMinMoney: "",
        projectMaxMoney: "",
        taxCode: 5,
        projectArea: 5,
      },
      consumerVo: {
        companyName: "",
        nsrsbh: "",
        area: [],
        industry: "",
        scale: "",
        salesman: "",
        contact: "",
        contactNumber: "",
        provinceCode: "",
        cityCode: "",
        districtCode: "",
      },
      projectId: "",
      projectInfoId: "",
    };
  },
  mounted() {
    if (this.isShowDetailId != -1) {
      this.getProjectInfo();
    }
  },
  methods: {
    // 修改项目
    editProject() {
      this.loadingItem = true;
      let consumerIndex = this.tableDataId.findIndex(
        (value) => value == this.projectList.consumerId
      );
      let projectManagerIndex = this.personOptionId.findIndex(
        (value) => value == this.projectList.projectManagerId
      );
      this.projectList.consumer = this.tableDataLists[consumerIndex].companyName;
      let params = {
        consumerId: this.tableDataLists[consumerIndex].consumerId,
        projectArea: this.projectAreaFdc,
        projectName: this.projectName,
        projectManager: JSON.parse(localStorage.getItem("user")).realName,
        projectManagerId: JSON.parse(localStorage.getItem("user")).id,
        // 基础信息表
        projectInfoVO: this.baseInfoObject,
        id: this.projectId
      };

      params.projectInfoVO.id = this.projectInfoId;

      bSuperiorProjectEdit(params).then((res) => {
        this.loadingItem = false;
        if (res.code == 200) {
          this.projectList = {};
          this.checkCustom = false;
          this.$notify({
            title: "温馨提示",
            message: res.message,
            type: "success",
          });
          this.$emit("addSuccess");
        }
      });
    },
    // 获取详情
    getProjectInfo() {
      bSuperiorProjectDetail(this.isShowDetailId).then((res) => {
        this.projectList.consumerId = res.data.consumerEntity.id;
        this.projectName = res.data.projectName;
        this.projectAreaFdc = res.data.projectArea;
        this.baseInfoObject.armorForMaterials = Number(
          res.data.projectInfoResult.armorForMaterials
        );
        this.baseInfoObject.projectMinMoney = res.data.projectInfoResult.projectMinMoney;
        this.baseInfoObject.projectMaxMoney = res.data.projectInfoResult.projectMaxMoney;
        this.baseInfoObject.taxCode = Number(res.data.projectInfoResult.taxCode);
        this.baseInfoObject.projectArea = Number(res.data.projectInfoResult.projectArea);
        this.projectId = res.data.id;
        this.projectInfoId = res.data.projectInfoResult.id;
        this.customChange();
      });
    },
    // 关闭新增弹窗
    closeBox() {
      this.$emit("closeBox");
    },
    // 去创建客户
    toCreateCustom() {
      this.createMethod = 2;
    },

    // 切换选择客户
    customChange() {
      this.cityCodeBoolean = false;
      let index = this.tableDataId.findIndex(
        (value) => value == this.projectList.consumerId
      );
      this.personInfo = this.tableDataLists[index];
      if (!this.personInfo.cityCode) {
        this.cityCodeBoolean = true;
      }
      if (!this.personInfo.industry) {
        this.industryBoolean = true;
      }
      this.conversionAreas = area(
        [
          this.personInfo.provinceCode,
          this.personInfo.cityCode,
          this.personInfo.districtCode,
        ],
        this.city
      );
      this.checkCustom = true;
    },

    // 创建项目
    createProject() {
      if (this.cityCodeBoolean && !this.fixArea) {
        this.$message({
          message: "请补全地区信息后继续！",
          type: "warning",
        });
        return;
      }
      if (this.industryBoolean && !this.fixHyListValue) {
        this.$message({
          message: "请补全行业信息后继续！",
          type: "warning",
        });
        return;
      }
      // 补全地区或者行业
      if (this.cityCodeBoolean || this.industryBoolean) {
        let data = {
          id: this.personInfo.id,
          provinceCode: this.personInfo.provinceCode || this.fixArea[0],
          cityCode: this.personInfo.cityCode || this.fixArea[1],
          districtCode: this.personInfo.districtCode || this.fixArea[2],
          industry: this.personInfo.industry || this.fixHyListValue,
        };
        editCustom(data).then((res) => {
          if (res.msgCode == 1) {
            this.addprojects();
          } else {
            this.loadingItem = false;
            this.$message({
              message: res.msgInfo,
              type: "error",
            });
          }
        });
      } else {
        this.addprojects();
      }
    },

    // 创建项目
    addprojects() {
      if (!this.projectList.consumerId) {
        this.$message({
          message: "请选择客户！",
          type: "warning",
        });
        return;
      }
      this.loadingItem = true;
      let consumerIndex = this.tableDataId.findIndex(
        (value) => value == this.projectList.consumerId
      );
      let projectManagerIndex = this.personOptionId.findIndex(
        (value) => value == this.projectList.projectManagerId
      );
      this.projectList.consumer = this.tableDataLists[consumerIndex].companyName;
      let params = {
        consumerId: this.tableDataLists[consumerIndex].consumerId,
        projectArea: this.projectAreaFdc,
        projectName: this.projectName,
        projectManager: JSON.parse(localStorage.getItem("user")).realName,
        projectManagerId: JSON.parse(localStorage.getItem("user")).id,
        // 基础信息表
        projectInfoVO: this.baseInfoObject,
      };

      bSuperiorProjectAdd(params).then((res) => {
        this.loadingItem = false;
        if (res.code == 200) {
          this.projectList = {};
          this.checkCustom = false;
          this.$notify({
            title: "温馨提示",
            message: res.message,
            type: "success",
          });
          this.$emit("addSuccess");
        }
      });
    },

    // 创建客户并创建项目
    createProjectBoth() {
      this.consumerVo.provinceCode = this.consumerVo.area[0];
      this.consumerVo.cityCode = this.consumerVo.area[1];
      this.consumerVo.districtCode = this.consumerVo.area[2];
      let params = {
        consumerVo: this.consumerVo,
        projectArea: this.projectAreaFdc,
        projectInfoVO: this.baseInfoObject,
        projectName: this.projectName,
        projectManager: JSON.parse(localStorage.getItem("user")).realName,
        projectManagerId: JSON.parse(localStorage.getItem("user")).id,
      };
      bSuperiorProjectAddBoth(params).then((res) => {
        if (res.code == 200) {
          this.$notify({
            title: "温馨提示",
            message: res.message,
            type: "success",
          });
          this.$emit("addSuccess");
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.container {
  position: fixed;
  left: 50%;
  margin-left: -240px;
  top: 2vh;
  width: 480px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
}

>>> #projectBoxs .project-input >>> .el-input {
  width: 416px;
  height: 36px;
  margin: 0 0 16px 32px;
}

>>> #projectBoxs .project-input .el-input .el-input__inner {
  height: 36px;
}

>>> #projectBoxs .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #projectBoxs .el-range-editor.el-input__inner {
  height: 36px;
  margin: 0 0 16px 32px;
  width: 416px;
}

>>> #projectBoxs .el-input__icon {
  line-height: 30px;
}

.addTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  padding: 24px 0 20px 0;
}

.project-close {
  position: absolute;
  cursor: pointer;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  top: 6px;
  right: 20px;
}

.overflow-xx {
  margin-bottom: 90px;
  max-height: 650px;
  overflow: auto;
}

.overflow-x {
  max-height: 490px;
  overflow-x: hidden;
}

.overflow-xx {
  margin-bottom: 90px;
}

@media (max-height: 800px) {
  .fixed-content {
    max-height: 220px;
    overflow-x: hidden;
  }

  .fixed-bottom {
    max-height: 200px;
    overflow-x: hidden;
  }
}

.fixed-bottom {
  display: flex;
  width: 464px;
  height: 340px;
  background: rgba(240, 245, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(214, 228, 255, 1);
  margin: 8px 0 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.project-name {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 0 0 4px 32px;
}

.project-named {
  position: relative;
  top: 2px;
  margin-right: 4px;
  color: #F5222D;
}

.project-Title-Flex {
  display: flex;
  justify-content: space-between;
  padding-right: 32px;
  cursor: pointer;
}

.toCreateCustoms {
  color: #2F54EB;
}

.tocreateFont {
  color: rgba(0, 0, 0, 0.4);
}

>>> .project-input .el-input__icon {
  line-height: 36px;
}

>>> #form-items .project-input .el-input {
  height: 36px;
}

>>> #form-items .el-input__inner {
  height: 36px;
}

>>> #projectBoxs .project-input .el-input {
  width: 416px;
  height: 36px;
  margin: 0 0 16px 32px;
}

>>> #projectBoxs .project-input .el-input .el-input__inner {
  height: 36px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  margin: 0 0 16px 0;
}

.project-info {
  width: 416px;
  height: 272px;
  padding: 0;
  margin-top: -16px;
  margin-left: 32px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.item-flex1 {
  flex: 1;
}

.content-title {
  margin: 18px 0 0 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.content-text {
  margin: 8px 0 30px 16px;
  color: rgba(0, 0, 0, 0.6);
}

.content-text-bottom {
  margin-bottom: 25px;
}

.project-btn:hover {
  background: rgba(47, 34, 235, 1);
}

.project-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  line-height: 68px;
  background: rgba(47, 84, 235, 1);
  box-shadow: 0px -1px 0px 0px rgba(47, 84, 235, 1);
  z-index: 1004;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.project-divider {
  padding: 0 20px 0 30px;
}

.project-fdc-tip {
  color: rgba(0,0,0,0.4);
  font-size: 14px;
  margin-left: 30px;
}

.project-fdc-is {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0,0,0,0.6);
  margin: 10px 0;
  margin-left: 30px;
}

.project-fdc-price {
  display: flex;
  align-items: center;
  margin-left: 30px;

  >>> .el-input {
    width: 200px;
  }
}

.project-fdc-priceTxt {
  margin-left: 30px;
  font-size: 14px;
  color: rgba(0,0,0,0.6);
  margin-bottom: 8px;
}

.project-fdc-priceTdiv {
  margin: 0 8px;
}

.project-fdc-area {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  font-size: 14px;
  color: rgba(0,0,0,0.6);

  >>> .el-radio-group {
    display: flex;
    flex-direction: column;
  }

  >>> .el-radio {
    margin: 6px 0;
    white-space: pre-wrap;
  }
}

.tocreateFontCompany {
  display: inline;
  font-size: 14px;
  color: #2f54eb;
}
</style>
