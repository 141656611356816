<template>
  <div class="content">
    <div class="tip">
      请使用微信扫码，绑定该账号
      <span class="iconfont iconmima"></span>
    </div>
    <div class="imgbox" id="login_wx"></div>
  </div>
</template>

<script>
import WxLogin from "@/utils/wxLogin";
import { random } from "lodash";

export default {
  mounted() {
    const redirect_uri_base = process.env.VUE_APP_WECHAT_CALLBACK_ROOT;
    const appid = process.env.VUE_APP_WECHAT_APPID;
    let redirect_uri = encodeURIComponent(
      `${redirect_uri_base}/thirdUser/callBackAddOrUpdateBindWx`
    );
    //let rootURL =  window.location.protocol+'//' + window.location.host;

    const user = JSON.parse(localStorage.getItem("user"));

    const mobile = user.mobile;

    const rd = random(100000, 999999);
    const state = `${rd}${mobile}`;
    const params = {
      id: "login_wx",
      appid,
      scope: "snsapi_login",
      agentid: "",
      redirect_uri,
      state,
      href:
        "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/aitax/wx.css?spm=5176.8466032.0.dopenurl.29ad1450HvgKCU&file=wx.css",
      self_redirect: false
    };


    let obj = new WxLogin(params);
  }
};
</script>

<style lang="stylus" scoped>
.content {
  text-align: center;
  width: 400px;
  height: 400px;

  .tip {
    margin-bottom: 8px;
    color: #666;
  }

  width: 320px;
  margin: 0 auto;

  .input {
    width: 200px;
  }

  .sms-form {
    display: flex;
    justify-content: space-around;
  }
}
</style>