<template>
  <div v-loading="getCityIng">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>项目管理</el-breadcrumb-item>
          <el-breadcrumb-item>项目清单</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">项目清单</template>
      </main-header>
    </div>
    <div class="filter-body">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="form-item" id="form-items">
            <div class="label">项目名称:</div>
            <el-input
              v-model="filter.projectName"
              placeholder="请输入"
              @keyup.enter.native="search"
              @input="changeName"
              clearable
            ></el-input>
          </div>
          <div class="form-item" id="form-items">
            <div class="label">企业名称:</div>
            <el-input
              v-model="filter.consumer"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeConsumer"
            ></el-input>
          </div>
          <div class="form-item" id="form-items">
            <div class="label">项目经理:</div>
            <el-input
              v-model="filter.projectManager"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeManager"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">状态:</div>
            <el-select
              v-model="filter.status"
              clearable
              placeholder="请选择"
              filterable
              @change="search"
            >
              <el-option
                v-for="item in statusList"
                :key="item.status"
                :label="item.name"
                :value="item.status"
              ></el-option>
            </el-select>
          </div>
          <div class="form-item" id="form-items">
            <div class="label">创建时间:</div>
            <el-date-picker
              class="input"
              v-model="filter.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="search"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="btn-look-up ease" @click="search">搜索</div>
      <!-- <button class="btn btn-primary btn-look-up" @click="reset">重置</button> -->
    </div>
    <div class="addItem ease" @click="dialogTableVisible = true">创建项目</div>
    <div class="content-body">
      <template>
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column width="10px"></el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            sortable
            :show-overflow-tooltip="true"
            min-width="8%"
          ></el-table-column>
          <el-table-column
            prop="projectName"
            label="项目名称"
            :show-overflow-tooltip="true"
            min-width="18%"
          ></el-table-column>
          <el-table-column
            prop="consumerEntity.companyName"
            label="企业名称"
            :show-overflow-tooltip="true"
            min-width="20%"
          ></el-table-column>
          <el-table-column
            prop="projectManager"
            label="项目经理"
            min-width="7%"
          ></el-table-column>
          <el-table-column label="状态" min-width="7%">
            <template slot-scope="scope">
              <div v-if="scope.row.status == 0"><span class="circle0"></span>待检测</div>
              <div v-if="scope.row.status == 1"><span class="circle1"></span>已结束</div>
              <div v-if="scope.row.status == 2"><span class="circle2"></span>进行中</div>
              <div v-if="scope.row.status == 3"><span class="circle3"></span>已废除</div>
            </template>
          </el-table-column>
          <el-table-column prop="viewReport" label="操作" width="80" align="right">
            <template slot-scope="scope">
              <div class="deleteProjects">
                <span v-if="scope.row.status == 0" @click="deleteProject(scope)">
                  <i class="el-icon-delete"></i>
                </span>
                <span @click="viewDetail(scope.row.id)" class="cursion" ref="clickBox">
                  <i class="el-icon-edit-outline"></i>
                </span>
                <!--  -->
                <delete-project
                  class="deleteProject-item"
                  v-if="scopeDelete == true && scope.row.id == deleteId"
                  @cancels="cancels"
                  @comfirms="comfirms"
                  :id="deleteId"
                ></delete-project>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <!-- 项目详情 -->
    <transition name="fades">
      <div class="containerMask" v-if="showDetail" @click="showDetailFalse"></div>
    </transition>
    <transition name="slide-fade">
      <detail-project
        v-if="showDetail"
        :projectDetail="projectDetail"
        :projectDetailTime="projectDetailTime"
        :conversionArea="conversionArea"
        :dataList="dataList"
        @editProjectSuccess="editProjectSuccess"
        @hiddenDetail="hiddenDetail"
      ></detail-project>
    </transition>

    <!-- 新建项目弹窗 -->
    <transition name="fades">
      <div
        class="containerMask"
        v-if="dialogTableVisible"
        @click="dialogTableVisible = false"
      ></div>
    </transition>
    <transition name="fadee">
      <project-add
        v-if="dialogTableVisible"
        :tableDataLists="tableDataLists"
        :tableDataId="tableDataId"
        @addSuccess="addSuccess"
        @closeBox="closeBox"
        :dataList="dataList"
        :city="city"
        :hyList="hyList"
      ></project-add>
    </transition>
    <el-pagination
      class="pagination-low"
      @current-change="handleCurrentChange"
      :current-page="pageno"
      layout="total, prev, pager, next, jumper"
      :total="totalNumber"
    ></el-pagination>
    <!--  -->
  </div>
</template>

<script>
import {
  getProjectList,
  getProjectListDetail,
  addProjects,
  getCustomListNew,
  getTradeList,
  getRegion,
} from "@/api/api";
import { mapState } from "vuex";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import { regionData } from "@/dependencies/element-china-area-data.js";
import { veriFiItem, veriFiCustom, area } from "@/pages/product/utils/limit.js";
import pagination from "@/pages/product/components/pagination.vue";
import deleteProject from "./components/deleteProject.vue";
import detailProject from "./components/detailProject.vue";
import projectAdd from "./components/projectAdd.vue";

import { qyStatusList, kjzd, kjzdList, formatDateTes } from "./help.js";
import { cloneDeep } from "lodash";

export default {
  components: {
    MainHeader,
    BreadCrumb,
    deleteProject,
    detailProject,
    projectAdd,
  },
  created() {
    this.getRegionData();
  },

  mounted() {
    this.getprojectList();
    this.gettradeList();
    this.getcustomList();
  },
  data() {
    return {
      scopeDelete: false,
      getCityIng: false,
      deleteId: 0,
      createInfo: this.$route.query.bothCreate,
      customID: Number(this.$route.query.id),
      conversionAreas: "",
      dataList: null,
      hyList: null,
      projectManagerIdName: JSON.parse(localStorage.getItem("user")).realName,
      totalNumber: 10, //总数
      // city: regionData,
      filter: {
        consumer: "",
        projectName: "",
        time: [],
        name: "",
        projectManager: "",
        status: "",
      },
      statusList: [
        {
          status: 0,
          name: "待检测",
        },
        {
          status: 1,
          name: "已结束",
        },
        {
          status: 2,
          name: "进行中",
        },
        {
          status: 3,
          name: "已废除",
        },
      ],
      personOptionId: [0, 1],
      showDetail: false,
      loading: true,
      tableDataList: null,
      dialogTableVisible: false,
      checkCustom: false,
      projectDetail: null,
      tableDataLists: null,
      tableDataId: null,
      personInfo: null,
      kjzdList: {
        qykjzd: "企业会计制度",
        qykjzz: "企业会计准则",
        xqykjzz: "小企业会计准则",
      },
      projectList: {},
      loadingItem: false,
      isEdit: false,
      conversionArea: "",
      projectDetailTime: "",
      pageno: 1,
      areaTree: [],
    };
  },

  computed: {
    city: function () {
      if (!this.areaTree) {
        return [];
      }
      const ret = cloneDeep(this.areaTree);
      function walk(data) {
        data.value = data.code;
        data.label = data.name;
        if (data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child);
          }
        }
        delete data.childNode;
      }
      for (const areaTree of ret) {
        walk(areaTree);
      }
      return [...ret];
    },
  },

  methods: {
    getRegionData() {
      getRegion().then((res) => {
        this.areaTree = res.data;
      });
    },
    closeBox() {
      this.dialogTableVisible = false;
    },
    addSuccess() {
      this.getprojectList();
      this.dialogTableVisible = false;
    },
    hiddenDetail() {
      this.showDetailFalse();
    },
    // 修改成功后
    editProjectSuccess(val) {
      this.projectDetail = val;
      this.getprojectList();
    },
    // 点击关闭详情
    showDetailFalse() {
      this.showDetail = false;
      this.isEdit = false;
    },

    // 翻页
    handleCurrentChange(val) {
      this.pageno = val;
      this.getprojectList();
    },

    // 去创建客户
    toCreateCustom() {
      this.$router.push({ name: "custom", query: { create: 1 } });
    },

    // 获取行业
    gettradeList() {
      getTradeList().then((res) => {
        if (res.msgCode == 1) {
          let data = JSON.parse(res.data);
          this.hyList = data;
          let dataList = {};
          for (let i = 0; i < this.hyList.length; i++) {
            this.hyList[i].hymc = this.hyList[i].hy_dm + this.hyList[i].hymc;
            dataList[this.hyList[i].hy_dm] = this.hyList[i].hymc;
          }
          this.dataList = dataList;
        } else {
          this.$message({
            showClose: true,
            message: res.msgInfo,
            type: "error",
          });
        }
      });
    },
    // 搜索
    search() {
      this.pageno = 1;
      this.getprojectList();
    },
    // 获取客户列表
    getcustomList() {
      let params = {
        companyName: "",
        industry: "",
        contact: "",
        contactNumber: "",
        startTime: "",
        endTime: "",
        pageSize: 1000,
        pageNo: 0,
      };
      getCustomListNew(params, localStorage.getItem("authorization")).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            showClose: true,
            message: res.msgInfo,
            type: "error",
          });
        }
        this.tableDataLists = res.data.data.content;
        this.tableDataId = this.tableDataLists.map((item) => {
          return item.id;
        });
        this.tableDataIdCus = this.tableDataLists.map((item) => {
          return item.consumerId;
        });
        let both = sessionStorage.getItem("both");
        if (this.customID && both) {
          this.getCityIng = true;
          setTimeout(() => {
            this.dialogTableVisible = true;
            this.projectList.consumerId = this.customID;
            this.customChange();
            this.getCityIng = false;
          }, 500);
        }
      });
    },
    // 重置
    changeName() {
      if (!this.filter.projectName) {
        this.pageno = 0;
        this.getprojectList();
      }
    },
    changeConsumer() {
      if (!this.filter.consumer) {
        this.pageno = 0;
        this.getprojectList();
      }
    },
    changeManager() {
      if (!this.filter.projectManager) {
        this.pageno = 0;
        this.getprojectList();
      }
    },
    // 获取项目列表
    getprojectList() {
      let params = {
        consumer: this.filter.consumer.trim(),
        projectName: this.filter.projectName.trim(),
        startTime: this.filter.time ? this.filter.time[0] : "",
        endTime: this.filter.time ? this.filter.time[1] : "",
        pageSize: 10,
        pageNo: this.pageno,
        projectManager: this.filter.projectManager,
        status: this.filter.status,
      };
      getProjectList(params, localStorage.getItem("authorization")).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            showClose: true,
            message: res.msgInfo,
            type: "error",
          });
        }
        this.tableDataList = res.data.content;
        this.totalNumber = res.data.totalSize;
        for (let i = 0; i < this.tableDataList.length; i++) {
          this.tableDataList[i].delete = false;
          this.tableDataList[i].createTime = formatDateTes(
            this.tableDataList[i].createTime,
            0
          );
        }
      });
    },

    customChange(e) {
      let index = this.tableDataId.findIndex(
        (value) => value == this.projectList.consumerId
      );
      this.personInfo = this.tableDataLists[index];
      this.conversionAreas = area(
        [
          this.personInfo.provinceCode,
          this.personInfo.cityCode,
          this.personInfo.districtCode,
        ],
        this.city
      );
      this.checkCustom = true;
    },
    viewDetail(id) {
      let params = {
        id: id,
      };
      getProjectListDetail(params, localStorage.getItem("authorization")).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.loading = false;
          this.projectDetail = res.data;
          this.projectDetail.time = [];
          this.projectDetail.time[0] = formatDateTes(this.projectDetail.createTime, 0);
          this.projectDetailTime = this.projectDetail.time[0];
          this.projectDetail.time[1] = formatDateTes(this.projectDetail.endTime, 0);
          if (!this.projectDetail.endTime) {
            this.projectDetail.time[1] = null;
          }
          this.conversionArea = area(
            [
              this.projectDetail.consumerEntity.provinceCode,
              this.projectDetail.consumerEntity.cityCode,
              this.projectDetail.consumerEntity.districtCode,
            ],
            this.city
          );
          this.showDetail = true;
        } else {
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
        }
      });
    },

    deleteProject(info) {
      this.deleteId = info.row.id;
      this.scopeDelete = true;
    },

    cancels(index) {
      this.scopeDelete = false;
    },

    comfirms(index) {
      this.getprojectList();
      this.scopeDelete = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import './common.css';

.overflow-x {
  max-height: 375px;
  overflow-x: hidden;
}

.overflow-xx {
  margin-bottom: 90px;
}

>>> #form-items .project-input .el-input {
  height: 36px;
}

>>> #form-items .el-input__inner {
  height: 36px;
}

>>> #projectBoxs .project-input .el-input {
  width: 416px;
  height: 36px;
  margin: 0 0 16px 32px;
}

>>> #projectBoxs .project-input .el-input .el-input__inner {
  height: 36px;
}

>>> #projectBoxs .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #projectBoxs .el-range-editor.el-input__inner {
  height: 36px;
  margin: 0 0 16px 32px;
  width: 416px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  margin: 0 0 16px 0;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.filter-body {
  margin: 20px;
  padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  // padding: 20px;
  // padding-top: 20px;
  // padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-top: 16px;

  .inputs-wrapper {
    width: 92%;

    .inputs-wraper-row {
      display: flex;
      flex-wrap: wrap;

      .form-item {
        margin-right: 40px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .label {
          white-space: nowrap;
          width: 69px;
          text-align: right;
          margin-right: 8px;
        }

        display: flex;
      }
    }
  }

  .btn-look-up {
    margin-right: 16px;
    width: 76px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #2F54EB;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.addItem {
  display: inline-block;
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 20px;
  cursor: pointer;
}

.addItem:hover {
  background: rgba(47, 34, 235, 1);
}

.containerMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1002;
}

>>> #projectBoxs .el-input__icon {
  line-height: 30px;
}

>>> .project-input .el-input__icon {
  line-height: 36px;
}

>>> .form-item .el-input__icon {
  line-height: 28px;
}

>>> .el-date-editor .el-range-separator {
  line-height: 28px;
}

.circle0 {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: rgba(82, 196, 26, 1);
  border-radius: 50%;
  margin-right: 8px;
}

.circle1 {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #F5222D;
  border-radius: 50%;
  margin-right: 8px;
}

.circle2 {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #FAAD14;
  border-radius: 50%;
  margin-right: 8px;
}

.circle3 {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
  margin-right: 8px;
}

.el-icon-edit-outline {
  color: #2f54eb;
  font-size: 18px;
}

.el-icon-delete {
  color: rgba(217, 217, 217, 1);
  font-size: 18px;
  margin-right: 16px;
  cursor: pointer;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.pagination-low {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}

>>>.el-select {
  width: 240px;
  height: 36px;
}

>>>.el-input {
  width: 240px;
}

>>>.el-range-editor.el-input__inner {
  width: 240px;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-pagination__editor.el-input {
  width: 50px;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

>>>.el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

>>>.el-input__icon {
  width: 14px;
}

>>>.el-range-editor.el-input__inner {
  padding: 3px 8px;
}

@media (max-height: 800px) {
  .fixed-content {
    max-height: 220px;
    overflow-x: hidden;
  }

  .fixed-bottom {
    max-height: 200px;
    overflow-x: hidden;
  }
}

.deleteProjects {
  position: relative;
}

.deleteProject-item {
  position: absolute;
  right: 60px;
  top: -63px;
  z-index: 3005;
}

>>>.is-right .cell {
  overflow: visible;
}

>>>.el-table__footer-wrapper, .el-table__header-wrapper {
  overflow: visible;
}

>>>.el-table {
  overflow: visible;
}

>>>.el-table__body-wrapper {
  overflow: visible;
}
</style>
