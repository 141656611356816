<template>
  <div class="diagram-flex">
    <!-- <div class="diagram-text diagram-text-t diagram-txtw" :class="{ diagramBlue: getBase}">
      <i class="iconfont iconjianceshezhi"></i>
      <span class="diagram-txt">检测设置</span>
    </div>
    <div class="diagram-process" :class="{ diagramProcess: getCurrent}"></div>-->
    <!-- <div class="diagram-box" :class="{diagramBoxblue: getCurrent}" v-if="userInfo==100">
      <div class :class="{ diagramBluen: getCurrent}">
        <i class="iconfont iconshangchuan"></i>
        <span class="diagram-txtn diagram-txtnr">本期上传</span>
      </div>
      <div class="diagram-process" :class="{ diagramProcess: getLast}"></div>
      <div class :class="{ diagramBluen: getLast}">
        <i class="iconfont iconshangchuan diagram-txtnl"></i>
        <span class="diagram-txtn">上期上传</span>
      </div>
      <template> -->
        <!-- <div>111</div>
        <div class="activeColor">
          <span>自动</span>
          <span>手动</span>
        </div>-->
        <!-- <el-button
          v-if="this.progress == 2|| this.progress == 3"
          type="text"
          class="code_btn activeColor posi-absolute"
          @click="userCheck"
        >
          <i class="el-icon-refresh"></i>切换上传方式
        </el-button>-->
      <!-- </template>
    </div> -->
    <!-- <div class="diagram-text diagram-text-t" :class="{ diagramBlue: getCurrent}"> -->
      <!-- <i class="iconfont iconfapiaotiqu"></i>
      <span class="diagram-txt">申报数据读取</span> -->
      <template>
        <div
          class="activeColor"
          v-if="this.progress == 2|| this.progress == 3"
        >
          <span
            class="activeleft"
            :class="{activeAct: userInfo==1}"
            data-index="1"
            @click="userCheck"
          >自动</span>
          <span
            class="activeright"
            :class="{activeActr: userInfo==2}"
            data-index="2"
            @click="userCheck"
          >手动</span>
        </div>
        <!-- <el-button
          v-if="this.progress == 2|| this.progress == 3"
          type="text"
          class="code_btn activeColor"
          @click="userCheck"
        >
          <i class="el-icon-refresh"></i>切换上传方式
        </el-button>-->
      </template>
    </div>
    <!-- <div class="diagram-process" :class="{ diagramProcess: getBill}"></div>
    <div
      class="diagram-text diagram-text-t"
      v-if="version==4||version==5"
      :class="{ diagramBlue: getBill}"
    >
      <i class="iconfont iconfapiaotiqu"></i>
      <span class="diagram-txt">发票数据采集</span>
    </div> -->
    <!-- <div
      class="diagram-process"
      v-if="version==4||version==5"
      :class="{ diagramProcess: getFinish}"
    ></div>-->
    <!-- <div class="diagram-text diagram-text-t" :class="{ diagramBlue: getFinish}">
      <i class="iconfont iconshengchengbaogao"></i>
      <span class="diagram-txt">检测报告生成</span>
    </div>-->
  </div>
</template>

<script>
export default {
  props: {
    progress: Number,
    version: [Number, String],
    userInfo: [Number, String],
    nochange: Boolean
  },
  computed: {
    getBase() {
      return this.progress == 1 || 2 || 3 || 4 || 5;
    },
    getCurrent() {
      return (
        this.progress == 2 ||
        this.progress == 3 ||
        this.progress == 4 ||
        this.progress == 5
      );
    },
    getLast() {
      return this.progress == 3 || this.progress == 4 || this.progress == 5;
    },
    getBill() {
      return this.progress == 4 || this.progress == 5;
    },
    getFinish() {
      return this.progress == 5;
    }
  },
  methods: {
    userCheck(e) {
      this.userInfoIndex = e.target.dataset.index;
      this.$emit("editUser", this.userInfoIndex);
    }
  }
};
</script>

<style lang="stylus" scoped>
.diagram-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
}

.diagram-text {
  width: 124px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  // background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  // border: 1px solid #D9D9D9;
}

.diagram-txtw {
  width: 124px;
}

.diagramBlue {
  color: rgba(47, 84, 235, 1);
  // border: 1px solid rgba(47, 84, 235, 1);
}

.diagram-process {
  flex: 1;
  height: 3px;
  background: #EEEEEE;
}

.diagramProcess {
  background: #2F54EB;
}

.diagram-text-t {
  width: 156px;
}

.diagram-txt {
  margin-left: 12px;
}

.diagram-txtn {
  margin-left: 4px;
}

.diagram-box {
  // position: relative;
  display: flex;
  align-items: center;
  width: 300px;
  border: 1px solid #D9D9D9;
  height: 48px;
  padding: 0 16px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
}

.diagram-txtnr {
  padding-right: 16px;
}

.diagram-txtnl {
  padding-left: 16px;
}

.diagram-txtw {
  width: 124px;
}

.diagramBluen {
  color: rgba(47, 84, 235, 1);
}

.diagramBoxblue {
  border: 1px solid rgba(47, 84, 235, 1);
}

.activeColor {
  color: #2F54EB;
  border: 1px solid rgba(47, 84, 235, 1);
  position: absolute;
  left: 320px;
  top: 112px;
  width: 152px;
  height: 28px;
  line-height: 28px;
  border-radius: 7px;
  font-size: 14px;
  color: rgba(47, 84, 235, 1);
  overflow: hidden;
}

// .posi-absolute {
// position: absolute;
// top: 50px;
// left: 80px;
// }
.el-icon-refresh {
  margin-right: 6px;
}

.activeleft, .activeright {
  display: inline-block;
  width: 50%;
  text-align: center;
  cursor: pointer;
}

.activeAct {
  background: rgba(47, 84, 235, 1);
  color: #fff;
  border-radius: 0 7px 7px 0;
}

.activeActr {
  background: rgba(47, 84, 235, 1);
  color: #fff;
  border-radius: 7px 0 0 7px;
}
</style>