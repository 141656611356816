<template>
  <div class="wrapper">
    <div class="title">
      {{ table4correction.sysFileName }}
      <div class="subtitle">{{ table4correction.userFileName }}</div>
    </div>
    <EditTable
      :zq="zq"
      ref="theTable"
      :table="table"
      v-if="table"
      @getInstance="getInstance"
    ></EditTable>

    <div class="footer">
      <p class="tip">*请您仔细检查所有需要调整的选项</p>
      <button
        class="btn btn-primary btn-confirm"
        @click="handleBtnSaveClick(table4correction.templateId)"
      >
        确认保存
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { cloneDeep } from "lodash";

import {
  uploadTableData,
  loadTableData,
  loadTableStructure,
  uploadWebTableExcel,
} from "@/api/table";
import EditTable from "@/components/HandsonTable";

const array2keys = (arr, mapping) => {
  let ret = {};
  for (const [rowNum, row] of arr.entries()) {
    for (const [colNum, v] of row.entries()) {
      if (mapping[rowNum][colNum]) {
        ret[mapping[rowNum][colNum]] = v;
      }
    }
  }
  return ret;
};

const keys2array = (keys, mapping, data, colHeaders) => {
  let ret = [...data];

  for (const [rowNum, row] of mapping.entries()) {
    //ret[rowNum] = {};
    for (const [colNum, v] of row.entries()) {
      if (keys[v]) {
        const name = colHeaders[colNum];
        ret[rowNum][name] = keys[v];
      }
    }
  }
  return ret;
};

export default {
  components: {
    EditTable,
  },
  async mounted() {
    await this.loadUserFilledData();

    await this.loadTable();
  },
  props: {
    table4correction: Object,
    test: Number,
  },
  computed: {
    ...mapState("UploadTable", ["zq"]),
    ...mapState(["companyInfo"]),
  },
  methods: {
    ...mapMutations("UploadTable", ["setCorrectDialogVisible"]),
    async loadUserFilledData() {
      const uuid = this.$route.params.uuid;
      //获取一下表格数据
      let resTableData = await loadTableData(
        this.zq,
        this.table4correction.templateId,
        this.$store.state.authorization,
        uuid
      );

      if (resTableData.data) {
        this.uploadedTableData = resTableData.data;
        // this.tableName = resTableData.data.excelTableNameCn;
      } else {
        this.uploadedTableData = null;
      }
    },
    async loadTable() {

      let realTemplateId = this.table4correction.templateId;


      //会计制度
      if (this.uploadedTableData.cwSign) {
        realTemplateId = `${realTemplateId}_${this.uploadedTableData.cwSign}`;
      }

      //准则
      if (this.uploadedTableData && this.uploadedTableData.executeSign === 0) {
        realTemplateId = realTemplateId + "_OLD";
      }

      const ret = await loadTableStructure({
        templateId: realTemplateId,
        zq: this.zq,
      });

      if (ret.code == 200) {
        this.excelName = this.table4correction.sysFileName;
        this.templateId = this.table4correction.templateId;
        this.table = JSON.parse(ret.data.excelData);

        this.mapping = JSON.parse(ret.data.excelMap);
        this.loadTableData();
      }
    },
    getInstance(instance) {
      this.instance = instance;

      //首次进入验证一下
      const dataTable = this.$refs.theTable.dataTable;
      const invalidCells = dataTable.invalidCells;
      // dataTable.invalidCells = [];

      //修复验证莫名不标红的bug
      this.timer = setTimeout(() => {
        try {
          this.instance.validateCells();
        } catch (error) {}
      }, 500);
      //修复验证莫名不标红的bug
      this.timer = setTimeout(() => {
        try {
          this.instance.validateCells();
          this.instance.render();
        } catch (error) {}
      }, 1000);
    },
    //把填写的值显示到表格上
    displayFromFilledResult() {
      const dataTable = this.$refs.theTable.dataTable;
      const data = this.table.data;
      const columns = dataTable.columns;

      const filledResultArr = this.filledResult.map((row, index) => {
        const data = {};
        row.forEach((col, index2) => {
          const columnName = Object.values(columns)[index2].data;

          data[columnName] = col;
        });

        return data;
      });

      const table = cloneDeep(this.table);
      table.data = filledResultArr;
      this.table = table;

      //this.loadTableData();
    },
    async handleBtnSaveClick(templateId) {
      const dataTable = this.$refs.theTable.dataTable;

      const invalidCells = dataTable.invalidCells;
      dataTable.invalidCells = [];

      //系统检测到您有部分数据未修改，若不修改系统将默认取0值
      this.instance.validateCells(async (valid) => {
        this.filledResult = this.instance.getData();
        //公式校验正确
        if (this.instance.CustomErrorCells.length === 0) {
          //不正确的数字格式
          if (!valid) {
            let isChangeTo0 = false;
            try {
              isChangeTo0 = await this.$confirm(
                "系统检测到您有部分数据未修改，若不修改系统将默认取0值",
                "提示",
                {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }
              );
            } catch (error) {
              return;
            }

            //把错误的单元格改成0
            if (isChangeTo0) {
              invalidCells.forEach((v) => {
                this.filledResult[v[0]][v[1]] = "0";
              });
            }
          }

          //提交数据
          const data = array2keys(this.filledResult, this.mapping);
          const html = this.instance.toHTML();
          data.source = this.source;

          await Promise.all([
            uploadTableData(
              // this.companyInfo.nsrsbm,
              sessionStorage.getItem("nsrsbh"),
              this.zq,
              this.templateId,
              this.$route.params.uuid,
              data,
            ),
            uploadWebTableExcel({
              tableLabel: html,
              nsrsbh: sessionStorage.getItem("nsrsbh"),
              templeteId: this.templateId,
              zq: this.zq,
              uuid: this.$route.params.uuid,
            }),
          ]);

          this.$alert("表格保存成功", "提示", {
            type: "success",
          }).then(() => {
            const tables4correctionData = cloneDeep(
              this.$store.state.UploadTable.tables4correctionData
            );

            const index = tables4correctionData.findIndex(
              (tables4correction) => {
                return tables4correction.templateId == templateId;
              }
            );

            tables4correctionData.splice(index, 1);

            this.$store.commit("UploadTable/setCorrectionTableData", {
              tables4correctionData: tables4correctionData,
            });
            this.$emit("save");
            if (tables4correctionData.length == 0) {
              this.setCorrectDialogVisible(false);
            }

            //this.displayFromFilledResult();
          });
        } else {
          this.instance.render();
          this.$alert("表格填写错误，请检查", "错误", {
            type: "error",
          });
        }
      });
    },
    loadTableData() {
      const data = this.uploadedTableData.dataJson;

      const theData = keys2array(
        data,
        this.mapping,
        this.table.data,
        this.table.colHeaders
      );

      this.$set(this.table, "data", theData);

      //this.updateTableSettings();
    },
  },
  data() {
    return {
      timerHandler: null,
      instance: null,
      excelName: "",
      templateId: "",
      filledResult: {},
      table: {},
      mapping: [],
      uploadedTableData: null, //之前上传到服务器的表格数据
    };
  },
  destroyed() {
   // clearTimeout(this.timerHandler);
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {
  background: #fff;

  .title {
    font-size: 18px;
    text-align: center;
    padding: 20px 0;

    .subtitle {
      font-size: 12px;
      color: #999;
      margin-top: 10px;
    }
  }
}

.footer {
  text-align: center;
  padding: 20px 0 50px;

  .tip {
    color: #F13A4C;
  }

  .btn-confirm {
    margin-top: 10px;
    width: 200px;
  }
}
</style>
