<template>
  <div>
    <bread-crumb>
      <el-breadcrumb-item :to="{ name: 'goods' }">产品中心</el-breadcrumb-item>
      <el-breadcrumb-item>优惠券</el-breadcrumb-item>
    </bread-crumb>
    <div class="submit-title">优惠券</div>
    <div class="discount" @click="checkAct">
      <div
        class="discount-btn"
        :class="{'discountact': act==0}"
        data-index="0"
      >未使用（{{this.lists[0]?this.lists[0].length: 0 }}）</div>
      <div
        class="discount-btn"
        :class="{'discountact': act==1}"
        data-index="1"
      >已使用（{{this.lists[1]?this.lists[1].length: 0 }}）</div>
      <div
        class="discount-btn"
        :class="{'discountact': act==2}"
        data-index="2"
      >已失效（{{this.lists[2]?this.lists[2].length: 0 }}）</div>
    </div>
    <div class="discount-items" v-if="lists[this.act]&&lists[this.act].length>0">
      <div class="discount-item" v-for="item in lists[this.act]" :key="item.id">
        <div class="flex">
          <div
            class="item1"
            :class="{'discount-timeout':act!=0}"
            v-if="item.info.price"
          >￥{{item.info.price}}</div>
          <div class="item1" :class="{'discount-timeout':act!=0}" v-else>{{item.info.discount*10}}折</div>
          <div class="item2" :class="{'discount-timeout':act!=0}">满{{item.info.satisfy}}元可用</div>
          <div class="item8" :class="{'discount-timeout':act!=0}">
            <span v-if="item.info.applyGoodType==1">全部商品</span>
            <span v-else>指定商品</span>
          </div>
        </div>
        <div
          class="item4"
          :class="{'discount-timeout':act!=0}"
        >有效时间：{{formatDateTes(item.info.discountStartTime)}} 至 {{formatDateTes(item.info.discountEndTime)}}</div>
        <div class="item5" :class="{'discount-timeout':act!=0}">优惠内容：{{item.info.txplain}}</div>
      </div>
    </div>
    <div class="discount-no" v-else>
      <img src="./img/nodis.png" />
      <div class="discount-txt">空空如也~</div>
    </div>
  </div>
</template>

<script>
import { searchFull } from "../../api/api";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  components: {
    BreadCrumb
  },
  data() {
    return {
      act: 0,
      lists: []
    };
  },
  created() {
    this.searchfull();
  },
  methods: {
    checkAct(e) {
      if (!e.target.dataset.index) {
        return;
      }
      this.act = e.target.dataset.index;
    },
    searchfull() {
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token
      };
      searchFull(params).then(res => {
        this.lists = res.data;
        let list1 = this.lists.filter(item => item.status == 2);
        let list2 = this.lists.filter(item => item.status == 1);
        let list3 = this.lists.filter(item => item.status == 3);
        this.lists = [list1, list2, list3];
      });
    },
    formatDateTes(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate();
      return Y + M + D;
    }
  }
};
</script>

<style lang="stylus" scoped>
.submit-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.8);
  padding: 8px 0 0 30px;
  font-weight: 500;
}

.discount {
  display: inline-flex;
  margin: 24px 0 0 30px;

}

.discount-btn {
  width: 145px;
  height: 38px;
  line-height: 38px;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(255, 255, 255, 1);
  border-radius: 19px;
  text-align: center;
  margin-right: 24px;
  cursor: pointer;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}

.discountact {
  background: rgba(98, 97, 242, 1);
  color: #fff;
}

.discount-items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-left: 30px;
}

.flex {
  display: flex;
  align-items: baseline;
}

.discount-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 380px;
  height: 123px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  padding-left: 24px;
  box-sizing: border-box;
}

.item1 {
  color: rgba(247, 81, 127, 1);
  font-size: 32px;
  margin-bottom: 8px;
  margin-top: -12px;
  margin-right: 10px;
}

.item2 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}

.item4, .item5 {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}

.item5 {
  margin-top: 6px;
}

.discount-no {
  position: relative;
  bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 717px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 40px 24px 24px 24px;
}

.discount-txt {
  margin-top: 16px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.item8 {
  flex: 1;
  text-align: right;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  margin-right: 24px;
}

.discount-timeout {
  color: rgba(0, 0, 0, 0.2);
}
</style>