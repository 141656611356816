<template>
  <div class="container">
    <div class="header">
      <div class="title">开发成本及其他</div>
      <div class="select">
        <div class="detail" @click="toTotalDetail">成本金额对比</div>
      </div>
    </div>
    <div class="chartContainer">
      <div class="select-array">
        <div class="select-option">
          <!-- 切换明细和汇总 明细和汇总的接口不一样  明细的数据是多个 汇总的数据是单个-->
          <el-select
            v-model="typeListValue"
            placeholder="请选择"
            @change="typeListValueChange"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-option">
          <!-- 成本和单位成本传递的参数不一样 -->
          <el-select
            v-model="dimensionType"
            placeholder="请选择"
            @change="dimensionTypeChange"
          >
            <el-option
              v-for="item in dimensionTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-option">
          <!-- 饼图和柱状图数据不同-->
          <el-select @change="imgValueChange" v-model="imgValue" placeholder="请选择">
            <el-option
              v-for="item in imgValueList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <!-- 饼图 -->
    <!-- typeListValue 1:明细 2:汇总 -->
    <!-- dimensionType 1:成本 2:单位成本 -->
    <!--      imgValue 1:饼状图 2:柱状图 -->
    <div v-if="pieData && pieData.length > 0">
      <div class="echart-img" v-show="imgValue == 1">
        <div class="chart-container">
          <div class="chart-container-pie" v-show="typeListValue == 1">
            <div class="main chart" v-for="(item, index) in dataPie" :key="index">
              <div class="main_pie_cost_detail"></div>
              <div class="typeName">
                {{ yData[dimensionType - 1][index] }}
              </div>
            </div>
          </div>
          <div v-show="typeListValue == 2">
            <div class="pie-total">
              <div class="pie-total-chart" id="pie-total-chart"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 柱状图 -->
      <div class="echart-img" v-show="imgValue == 2">
        <!-- 汇总 -->
        <div class="chart-container" v-show="typeListValue == 2">
          <div class="barp" id="main1"></div>
        </div>
        <!-- 明细 -->
        <div class="chart-container-pie" v-show="typeListValue == 1">
          <div id="main2"></div>
        </div>
      </div>
    </div>
    <div v-else class="no-data" @click="toaddData">请导入开发成本及其他数据</div>
    <div class="casc">
      <el-cascader
        :options="options"
        :props="props"
        clearable
        v-model="cascaderModel"
        @change="cascaderModelChange"
      ></el-cascader>
    </div>
    <div class="index-table">
      <el-table
        :data="fdcArrayData"
        :span-method="objectSpanMethod"
        border
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column prop="costSubject" label="科目"> </el-table-column>
        <el-table-column prop="targetCost" label="目标成本(元)" v-if="colData[0].istrue">
        </el-table-column>
        <el-table-column prop="actualCost" label="实际成本(元)" v-if="colData[1].istrue">
        </el-table-column>
        <el-table-column
          prop="settlementAmount"
          label="结算金额(元)"
          v-if="colData[2].istrue"
        >
        </el-table-column>
        <el-table-column
          prop="contractAmount"
          label="合同金额(元)"
          v-if="colData[3].istrue"
        >
        </el-table-column>
        <el-table-column
          prop="invoiceTaxAmount"
          label="发票价税合计(元)"
          v-if="colData[4].istrue"
        >
        </el-table-column>
        <el-table-column
          prop="contractUnitAmount"
          label="目标单位成本(元/M²)"
          v-if="colData[5].istrue"
        >
        </el-table-column>
        <el-table-column
          prop="actualUnitCost"
          label="实际单位成本(元/M²)"
          v-if="colData[6].istrue"
        >
        </el-table-column>
        <el-table-column
          prop="settlementUnitAmount"
          label="结算单位金额(元/M²)"
          v-if="colData[7].istrue"
        >
        </el-table-column>
        <el-table-column
          prop="contractUnitAmount"
          label="合同单位金额(元/M²)"
          v-if="colData[8].istrue"
        >
        </el-table-column>
        <el-table-column
          prop="invoiceTaxUnitAmount"
          label="发票单位金额(元/M²)"
          v-if="colData[9].istrue"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  getInComeTable,
  getHomeInComeImage,
  getInComeTableCost,
  getHomeCostDetailedImage,
  getHomeCostSummaryImage,
} from "@/api/fri";
import pie from "./pie";
import columnar from "./columnar";
import echarts from "echarts";

export default {
  components: {
    pie,
    columnar,
  },
  data() {
    return {
      pieDataSummary: null,
      pieData: null,
      dataPie: null,
      typeList: [
        { value: 1, label: "明细" },
        { value: 2, label: "汇总" },
      ],
      typeListValue: 1,
      dimensionTypeList: [
        { value: 1, label: "成本" },
        { value: 2, label: "单位成本" },
      ],
      dimensionType: 1,
      imgValueList: [
        { value: 1, label: "饼状图" },
        { value: 2, label: "柱状图" },
      ],
      imgValue: 1,
      reload: 1,
      cascaderModel: [[1], [2], [3], [4], [5], [6], [7], [8], [9], [10]],
      props: { multiple: true },
      options: [
        {
          value: 1,
          label: "目标成本(元)",
        },
        {
          value: 2,
          label: "实际成本(元)",
        },
        {
          value: 3,
          label: "结算金额(元)",
        },
        {
          value: 4,
          label: "合同金额(元)",
        },
        {
          value: 5,
          label: "发票价税合计(元)",
        },
        {
          value: 6,
          label: "目标单位成本(元/M²)",
        },
        {
          value: 7,
          label: "实际单位成本(元/M²)",
        },
        {
          value: 8,
          label: "结算单位金额(元/M²)",
        },
        {
          value: 9,
          label: "合同单位金额(元/M²)",
        },
        {
          value: 10,
          label: "发票单位金额(元/M²)",
        },
      ],
      colData: [
        { title: "目标成本(元)", istrue: true },
        { title: "实际成本(元)", istrue: true },
        { title: "结算金额(元)", istrue: true },
        { title: "合同金额(元)", istrue: true },
        { title: "发票价税合计(元)", istrue: true },
        { title: "目标单位成本(元/M²)", istrue: true },
        { title: "实际单位成本(元/M²)", istrue: true },
        { title: "结算单位金额(元/M²)", istrue: true },
        { title: "合同单位金额(元/M²)", istrue: true },
        { title: "发票单位金额(元/M²)", istrue: true },
      ],
      fdcArrayData: null,
      mergeObj: {},
      mergeArr: [
        "costSubject",
        "targetCost",
        "actualCost",
        "settlementAmount",
        "contractAmount",
        "invoiceTaxAmount",
        "contractUnitAmount",
        "actualUnitCost",
        "settlementUnitAmount",
        "contractUnitAmount",
        "invoiceTaxUnitAmount",
      ],
      items: [1, 2, 3],
      yData: [
        [
          "目标成本(元)",
          "实际成本(元)",
          "合同金额(元)",
          // "记账金额(元)",
          "结算金额(元)",
          "发票价税合计(元)",
        ],
        [
          "目标单位成本(元/M²)",
          "实际单位成本(元/M²)",
          "合同单位金额(元/M²)",
          // "记账单位金额(元/M²)",
          "结算单位金额(元/M²)",
          "发票单位金额(元/M²)",
        ],
      ],
      pieDataTotal: null,
    };
  },
  mounted() {
    this.getInComeTableData();
    this.init();
    // 初始化的时候
    //
    // 总共几种情况
    // 明细-成本-饼状图
    // 明细-成本-柱状图
    // 明细-单位成本-饼状图
    // 明细-单位成本-柱状图
    // 汇总-成本-饼状图
    // 汇总-成本-柱状图
    // 汇总-单位成本-饼状图
    // 汇总-单位成本-柱状图
    //
    // 初始化展示明细-成本-饼状图
  },
  methods: {
    toaddData() {
      // this.$router.push("/product/frienter");
    },
    init() {
      // 初始化展示明细-成本-饼状图
      this.detailedCostPie();
    },
    // 切换明细或汇总
    typeListValueChange() {
      // 明细
      if (this.typeListValue == 1) {
        // 成本
        if (this.dimensionType == 1) {
          // 饼状图
          if (this.imgValue == 1) {
            this.detailedCostPie();
          }
          // 柱状图
          if (this.imgValue == 2) {
            this.detailedCostBar();
          }
        }
        // 单位成本
        if (this.dimensionType == 2) {
          // 饼状图
          if (this.imgValue == 1) {
            this.detailedUnitCostPie();
          }
          // 柱状图
          if (this.imgValue == 2) {
            this.detailedUnitCostBar();
          }
        }
      }

      // 汇总
      if (this.typeListValue == 2) {
        // 成本
        if (this.dimensionType == 1) {
          // 饼状图
          if (this.imgValue == 1) {
            this.totalCostPie();
          }
          // 柱状图
          if (this.imgValue == 2) {
            this.totalCostBar();
          }
        }
        // 单位成本
        if (this.dimensionType == 2) {
          // 饼状图
          if (this.imgValue == 1) {
            this.totalUnitCostPie();
          }
          // 柱状图
          if (this.imgValue == 2) {
            this.totalUnitCostBar();
          }
        }
      }
    },
    // 切换成本或单位成本
    dimensionTypeChange() {
      // 成本
      if (this.dimensionType == 1) {
        // 明细
        if (this.typeListValue == 1) {
          // 饼状图
          if (this.imgValue == 1) {
            this.detailedCostPie();
          }
          // 柱状图
          if (this.imgValue == 2) {
            this.detailedCostBar();
          }
        }
        // 汇总
        if (this.typeListValue == 2) {
          // 饼状图
          if (this.imgValue == 1) {
            this.totalCostPie();
          }
          // 柱状图
          if (this.imgValue == 2) {
            this.totalCostBar();
          }
        }
      }

      // 单位成本
      if (this.dimensionType == 2) {
        // 明细
        if (this.typeListValue == 1) {
          // 饼状图
          if (this.imgValue == 1) {
            this.detailedCostPie();
          }
          // 柱状图
          if (this.imgValue == 2) {
            this.detailedUnitCostBar();
          }
        }
        // 汇总
        if (this.typeListValue == 2) {
          // 饼状图
          if (this.imgValue == 1) {
            this.totalUnitCostPie();
          }
          // 柱状图
          if (this.imgValue == 2) {
            this.totalUnitCostBar();
          }
        }
      }
    },
    // 切换饼图和柱状图
    imgValueChange() {
      // 明细
      if (this.typeListValue == 1) {
        // 成本
        if (this.dimensionType == 1) {
          // 饼状图
          if (this.imgValue == 1) {
            this.detailedCostPie();
          }
          // 柱状图
          if (this.imgValue == 2) {
            this.detailedCostBar();
          }
        }
        // 单位成本
        if (this.dimensionType == 2) {
          // 饼状图
          if (this.imgValue == 1) {
            this.detailedUnitCostPie();
          }
          // 柱状图
          if (this.imgValue == 2) {
            this.detailedUnitCostBar();
          }
        }
      }

      // 汇总
      if (this.typeListValue == 2) {
        // 成本
        if (this.dimensionType == 1) {
          // 饼状图
          if (this.imgValue == 1) {
            this.totalCostPie();
          }
          // 柱状图
          if (this.imgValue == 2) {
            this.totalCostBar();
          }
        }
        // 单位成本
        if (this.dimensionType == 2) {
          // 饼状图
          if (this.imgValue == 1) {
            this.totalUnitCostPie();
          }
          // 柱状图
          if (this.imgValue == 2) {
            this.totalUnitCostBar();
          }
        }
      }
    },

    cascaderModelChange() {
      // console.log(this.cascaderModel);
    },
    getSpanArr(data) {
      this.mergeArr.forEach((key, index1) => {
        let count = 0; // 用来记录需要合并行的起始位置
        this.mergeObj[key] = []; // 记录每一列的合并信息
        data.forEach((item, index) => {
          // index == 0表示数据为第一行，直接 push 一个 1
          if (index === 0) {
            this.mergeObj[key].push(1);
          } else {
            // 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
            if (item[key] === data[index - 1][key]) {
              this.mergeObj[key][count] += 1;
              this.mergeObj[key].push(0);
            } else {
              // 如果当前行和上一行其值不相等
              count = index; // 记录当前位置
              this.mergeObj[key].push(1); // 重新push 一个 1
            }
          }
        });
      });
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 判断列的属性
      if (this.mergeArr.indexOf(column.property) !== -1) {
        // 判断其值是不是为0
        if (column.property == "superClassName") {
          if (this.mergeObj[column.property][rowIndex]) {
            return [this.mergeObj[column.property][rowIndex], 1];
          } else {
            // 如果为0则为需要合并的行
            return [0, 0];
          }
        }
      }
    },

    // 获取表格数据
    getInComeTableData() {
      let projectId = this.$route.query.id;
      getInComeTableCost(projectId).then((res) => {
        let data = res.data;
        this.fdcArrayData = res.data;
      });
    },
    toTotalDetail() {
      // this.$router.push("/product/fricost");
    },
    // 总共几种情况
    // 初始化展示明细-成本-饼状图
    //
    // 明细-成本-饼状图
    detailedCostPie() {
      let projectId = this.$route.query.id;
      getHomeCostDetailedImage(this.dimensionType, projectId).then((res) => {
        this.pieData = res.data;
        let dataPie = [[], [], [], [], []];
        for (let i = 0; i < this.pieData.length; i++) {
          dataPie[0][i] = {
            name: this.pieData[i].costSubject,
            value: this.pieData[i].targetCost,
          };
          dataPie[1][i] = {
            name: this.pieData[i].costSubject,
            value: this.pieData[i].actualCost,
          };
          dataPie[2][i] = {
            name: this.pieData[i].costSubject,
            value: this.pieData[i].contractAmount,
          };
          dataPie[3][i] = {
            name: this.pieData[i].costSubject,
            value: this.pieData[i].settlementAmount,
          };
          dataPie[4][i] = {
            name: this.pieData[i].costSubject,
            value: this.pieData[i].invoiceTaxAmount,
          };
          // dataPie[3][i] = {
          //   name: this.pieData[i].costSubject,
          //   value: this.pieData[i].bookkeepingAmount,
          // };
          // dataPie[4][i] = {
          //   name: this.pieData[i].costSubject,
          //   value: this.pieData[i].settlementAmount,
          // };
          // dataPie[5][i] = {
          //   name: this.pieData[i].costSubject,
          //   value: this.pieData[i].invoiceTaxAmount,
          // };
        }
        this.dataPie = dataPie;
        console.log(this.dataPie)
        setTimeout(() => {
          this.draw_Pie_cost_detailed();
        }, 500);
      });
    },
    // 明细-成本-柱状图
    detailedCostBar() {
      let projectId = this.$route.query.id;
      getHomeCostDetailedImage(this.dimensionType, projectId).then((res) => {
        this.pieData = res.data;
        let dataPie = [[], [], [], [], []];
        for (let i = 0; i < this.pieData.length; i++) {
          dataPie[0][i] = {
            name: this.pieData[i].costSubject,
            value: this.pieData[i].targetCost,
          };
          dataPie[1][i] = {
            name: this.pieData[i].costSubject,
            value: this.pieData[i].actualCost,
          };
          dataPie[2][i] = {
            name: this.pieData[i].costSubject,
            value: this.pieData[i].contractAmount,
          };
          dataPie[3][i] = {
            name: this.pieData[i].costSubject,
            value: this.pieData[i].settlementAmount,
          };
          dataPie[4][i] = {
            name: this.pieData[i].costSubject,
            value: this.pieData[i].invoiceTaxAmount,
          };
          // dataPie[3][i] = {
          //   name: this.pieData[i].costSubject,
          //   value: this.pieData[i].bookkeepingAmount,
          // };
          // dataPie[4][i] = {
          //   name: this.pieData[i].costSubject,
          //   value: this.pieData[i].settlementAmount,
          // };
          // dataPie[5][i] = {
          //   name: this.pieData[i].costSubject,
          //   value: this.pieData[i].invoiceTaxAmount,
          // };
        }
        this.dataPie = dataPie;

        setTimeout(() => {
          this.draw_Bar();
        }, 500);
      });
    },
    // 明细-单位成本-饼状图
    detailedUnitCostPie() {
      this.detailedCostPie();
    },
    // 明细-单位成本-柱状图
    detailedUnitCostBar() {
      this.detailedCostBar();
    },
    // 汇总-成本-饼状图
    totalCostPie() {
      let projectId = this.$route.query.id;
      getHomeCostSummaryImage(this.dimensionType, projectId).then((res) => {
        this.pieDataSummary = res.data;
        let data = res.data;
        this.pieDataTotal = [];
        this.pieDataTotal = [
          { value: data.targetCost, name: "目标成本(元)" },
          { value: data.actualCost, name: "实际成本(元)" },
          { value: data.contractAmount, name: "合同金额(元)" },
          // { value: data.bookkeepingAmount, name: "记账金额(元)" },
          { value: data.settlementAmount, name: "结算金额(元)" },
          { value: data.invoiceTaxAmount, name: "发票价税合计(元)" },
        ];
        setTimeout(() => {
          this.draw_pie_Total();
        }, 500);
      });
    },
    // 汇总-成本-柱状图
    totalCostBar() {
      let projectId = this.$route.query.id;
      getHomeCostSummaryImage(this.dimensionType, projectId).then((res) => {
        this.pieDataSummary = res.data;
        setTimeout(() => {
          this.draw_BarTotal_company();
        }, 500);
      });
    },
    // 汇总-单位成本-饼状图
    totalUnitCostPie() {
      // this.totalCostPie();
      let projectId = this.$route.query.id;
      getHomeCostSummaryImage(this.dimensionType, projectId).then((res) => {
        this.pieDataSummary = res.data;
        let data = res.data;
        this.pieDataTotal = [];
        this.pieDataTotal = [
          { value: data.targetCost, name: "目标单位成本(元/M²)" },
          { value: data.actualCost, name: "实际单位成本(元/M²)" },
          { value: data.contractAmount, name: "合同单位金额(元/M²)" },
          // { value: data.bookkeepingAmount, name: "记账单位金额(元/M²)" },
          { value: data.settlementAmount, name: "结算单位金额(元/M²)" },
          { value: data.invoiceTaxAmount, name: "发票单位金额(元/M²)" },
        ];
        setTimeout(() => {
          this.draw_pie_Total();
        }, 500);
      });
    },
    // 汇总-单位成本-柱状图
    totalUnitCostBar() {
      this.totalCostBar();
    },
    //
    // 明细-成本-饼状图 明细-单位成本-饼状图
    draw_Pie_cost_detailed() {
      // 通过饼状图数据 循环多个饼状图 明细的图都是需要多个图
      let dataPie = [[], [], [], [], []];
      for (let i = 0; i < this.pieData.length; i++) {
        dataPie[0][i] = {
          name: this.pieData[i].costSubject,
          value: this.pieData[i].targetCost,
        };
        dataPie[1][i] = {
          name: this.pieData[i].costSubject,
          value: this.pieData[i].actualCost,
        };
        dataPie[2][i] = {
          name: this.pieData[i].costSubject,
          value: this.pieData[i].contractAmount,
        };
        dataPie[3][i] = {
          name: this.pieData[i].costSubject,
          value: this.pieData[i].settlementAmount,
        };
        dataPie[4][i] = {
          name: this.pieData[i].costSubject,
          value: this.pieData[i].invoiceTaxAmount,
        };
        // dataPie[3][i] = {
        //   name: this.pieData[i].costSubject,
        //   value: this.pieData[i].bookkeepingAmount,
        // };
        // dataPie[4][i] = {
        //   name: this.pieData[i].costSubject,
        //   value: this.pieData[i].settlementAmount,
        // };
        // dataPie[5][i] = {
        //   name: this.pieData[i].costSubject,
        //   value: this.pieData[i].invoiceTaxAmount,
        // };
      }
      this.dataPie = dataPie;
      var myEchart = document.getElementsByClassName("main_pie_cost_detail"); //获取类名
      //此处for循环多次初始化 echarts 实例
      for (var i = 0; i < myEchart.length; i++) {
        var myChart = echarts.init(myEchart[i]); //初始化echarts实例要在for循环内
        let option = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [
            {
              type: "pie",
              radius: ["20%", "50%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "40",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              // data: ,
              data: this.dataPie[i],
            },
          ],
        };
        //使用刚指定的配置项和数据显示图表
        myChart.clear(); //消除当前实例
        myChart.setOption(option);
      }
    },
    // 汇总-成本-饼状图 汇总-单位成本-饼状图
    draw_pie_Total() {
      var chartDom = document.getElementById("pie-total-chart");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            type: "pie",
            radius: ["20%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.pieDataTotal,
          },
        ],
      };
      myChart.clear(); //消除当前实例
      option && myChart.setOption(option);
    },
    // 汇总-成本-柱状图 汇总-单位成本-柱状图
    draw_BarTotal_company() {
      var myEchart = document.getElementById("main1"); //获取类名
      //此处for循环多次初始化 echarts 实例
      var myChart = echarts.init(myEchart); //初始化echarts实例要在for循环内
      let option = {
        xAxis: {
          type: "category",
          data: this.yData[this.dimensionType - 1],
          axisLabel: {
            //X轴字体倾斜设置
            interval: 0,
            rotate: -20, //倾斜的程度
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: "30%",
          left: "10%",
          right: "8%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            data: [
              this.pieDataSummary.targetCost,
              this.pieDataSummary.actualCost,
              this.pieDataSummary.contractAmount,
              // this.pieDataSummary.bookkeepingAmount,
              this.pieDataSummary.settlementAmount,
              this.pieDataSummary.invoiceTaxAmount,
            ],
            type: "bar",
          },
        ],
      };
      //使用刚指定的配置项和数据显示图表
      myChart.clear(); //消除当前实例
      myChart.setOption(option);
    },
    // 明细-成本-柱状图 明细-单位成本-柱状图
    draw_Bar() {
      var myEchart = document.getElementById("main2"); //获取类名
      //此处for循环多次初始化 echarts 实例
      var data = [];
      var myChart = echarts.init(myEchart); //初始化echarts实例要在for循环内
      for (var i = 0; i < this.pieData.length; i++) {
        data.push({
          name: this.pieData[i].costSubject,
          type: "bar",
          data: [
            this.pieData[i].targetCost,
            this.pieData[i].actualCost,
            this.pieData[i].contractAmount,
            // this.pieData[i].bookkeepingAmount,
            this.pieData[i].settlementAmount,
            this.pieData[i].invoiceTaxAmount,
          ],
        });
      }
      let option = {
        tooltip: {
          trigger: "axis",
        },
        toolbox: {},
        legend: {
          data: ["Evaporation", "Precipitation", "Temperature"],
        },
        grid: {
          top: "30%",
          left: "10%",
          right: "8%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.yData[this.dimensionType - 1],
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              //X轴字体倾斜设置
              interval: 0,
              rotate: -20, //倾斜的程度
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: data,
      };
      // 使用刚指定的配置项和数据显示图表;
      myChart.clear(); //消除当前实例
      myChart.setOption(option);
    },
  },
  watch: {
    cascaderModel(val) {
      let data = this.colData;
      for (let i = 0; i < data.length; i++) {
        data[i].istrue = false;
      }
      for (let i = 0; i < val.length; i++) {
        data[val[i][0] - 1].istrue = true;
      }
      this.colData = data;
      this.reload = Math.random();
    },
  },
};
</script>

<style lang="stylus" scoped>
.chartContainer {
  width: 100%;
  margin-bottom: 10px;

  .select-array {
    display: flex;
    margin: 16px 0 0 30px;

    .select-option {
      margin-right: 30px;
    }
  }
}

.container {
  background: rgba(255, 255, 255, 1);
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.04);
  border-radius: 8px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 54px;
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid rgba(218, 213, 232, 0.6);

    .title {
      font-size: 18px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      margin-left: 24px;
    }

    .select {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);

      .detail {
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }

  .echart-img {
    padding-top: 50px;
    margin: 0 30px;
    overflow: auto;
  }

  .index-table {
    padding: 30px;
    padding-top: 0;
  }
}

.casc {
  margin-left: 30px;

  >>> .el-cascader {
    width: 700px;
    margin-top: 8px;
  }
}

.pie-total {
  width: 700px;
  height: 500px;
}

.pie-total .pie-total-chart {
  width: 100%;
  height: 100%;
}

.pie-total2 {
  width: 700px;
  height: 500px;
}

.pie-total2 .pie-total-chart2 {
  width: 100%;
  height: 100%;
}

.chart-container {
  // display: flex;
  // justify-content: center;
  white-space: nowrap;
  text-align: center;
}

.chart {
  position: relative;
  width: 800px;
  height: 300px;
}

.chart .main2 {
  width: 100%;
  height: 100%;
}

#main1 {
  position: relative;
  width: 900px;
  height: 300px;
}

#main2 {
  position: relative;
  width: 900px;
  height: 300px;
}

.typeName {
  position: absolute;
  left: 50%;
  margin-left: -40px;
  top: -30px;
  color: rgba(0,0,0,0.65);
}

.chart .barp {
  width: 100%;
  height: 100%;
}

.chart .barl {
  width: 100%;
  height: 100%;
}

.casc {
  margin-left: 30px;

  >>> .el-cascader {
    width: 700px;
    margin-top: 8px;
  }
}

.chart-container-pie {
  white-space: nowrap;
  text-align: center;
}

//
.chart-container-pie .chart {
  width: 450px;
  height: 240px;
  display: inline-block;
}

.chart .main_pie_cost_detail {
  width: 100%;
  height: 100%;
}

.no-data {
  height: 250px;
  line-height: 250px;
  text-align: center;
  cursor: pointer;
  color: rgba(0,0,0,0.65);
}
</style>
