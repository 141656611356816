<template>
  <div>
    <el-card class="usercard">
      <div class="bind-item">
        <div class="bind-provider">微信:</div>
        <template v-if="bindInfo">
          <img
            :src="bindInfo.headImgUrl"
            class="avatar"
          />
          <div class="nickname">{{bindInfo.nickName}}</div>
          <button class="link" @click="handleBindLinkCLick">更换</button>
        </template>
        <template v-else>
          <button class="link" @click="handleBindLinkCLick">立即绑定</button>
        </template>
      </div>
    </el-card>

    <DialogSendSMS ref="dialogSendSMS" @dialogSendSMSNextCLick="handleDialogSendSMSNextCLick"></DialogSendSMS>
    <el-dialog title="绑定微信" :visible.sync="dialogQRVisible" :close-on-click-modal="false" width="480px">
      <DialogQR ref="dialogQR"></DialogQR>
    </el-dialog>
  </div>
</template>

<script>
import { checkIsBindWx } from "@/api/auth";
import DialogSendSMS from "./components/DialogSendSMS";
import DialogQR from "./components/DialogQR";
export default {
  components: {
    DialogSendSMS,
    DialogQR
  },
  async mounted() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.mobile = user.mobile;

    //加载微信绑定信息
    this.loadBindInfo();
  },
  data: function() {
    return {
      bindInfo: null,
      dialogQRVisible: false,
      mobile: null
    };
  },
  methods: {
    async loadBindInfo() {
      let bindRes;
      try {
        bindRes = await checkIsBindWx(this.mobile);
      } catch (error) {
        throw error;
      }

      if (bindRes.msgCode == "1") {
        this.bindInfo = bindRes.data;
      }
    },
    handleBindLinkCLick() {
      this.$refs["dialogSendSMS"].dialogVisible = true;
    },
    handleDialogSendSMSNextCLick() {
      
      this.dialogQRVisible = true;
    }
  }
};
</script>
<style src="@/styles/index.scss" lang="scss" scoped></style>

<style lang="stylus" scoped>
>>>.el-dialog__header {
  .el-dialog__title {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    font-size: 16px;
  }

  border-bottom: 1px solid #eee;
}

>>>.el-dialog__footer {
  border-top: 1px solid #eee;
}

>>> .el-input__inner:focus {
  border-color: #2f54eb;
}

.bind-item {
  font-size: 14px;
  display: flex;
  align-items: center;
  vertical-align: top;
  padding: 30px 100px;

  .bind-provider {
    color: #999;
    margin-right: 20px;
  }

  .nickname {
    color: #333;
    margin: 0px 20px 0 10px;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  button.link {
    background: none;
    border: 0 none;
    color: #2F54EB;
    cursor: pointer;
  }

  button.link:focus {
    outline: 0;
  }
}
</style>