<template>
  <div class="page-wrapper">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>小工具</el-breadcrumb-item>
          <el-breadcrumb-item>发票查询</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">发票查询</template>
      </main-header>
    </div>

    <div class="invoice-content">
      <img class="invoice-img" src="@/assets/result/no-invoice.png" alt />
      <div class="invoice-text">查询时间因发票数量而定，最迟T+1完成，请耐心等待。</div>
      <div class="invoice-upload">查询结果默认保留14天</div>
      <div class="invoice-btn ease" @click="toTestLog">返回发票查询列表页</div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";

export default {
  components: {
    BreadCrumb,
    MainHeader,
  },
  data: function () {
    return {};
  },

  methods: {
    toTestLog() {
      this.$router.push({ name: "billList" });
    },
  },
};
</script>

<style lang="stylus" scoped>
.page-wrapper {
  padding-bottom: 100px;
}

.form-wrapper {
  display: flex;
  margin-top: 24px;

  .left-wrapper {
    width: 50%;
  }

  .item {
    color: #666;
    font-size: 14px;
    margin-top: 16px;

    .required-icon {
      color: #F5222D;
      margin-right: 4px;
    }

    .label {
      width: 150px;
      display: inline-block;
      text-align: right;
      margin-right: 8px;
      vertical-align: top;
      line-height: 36px;
    }

    .input {
      border: 1px solid #D9D9D9;
      width: 400px;
      height: 36px;
      line-height: 36px;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 3px;
      outline: none;
    }

    .input-wrapper {
      display: inline-block;
    }

    .big {
      font-size: 24px;
      margin: 16px auto;
      color: #333;
    }
  }

  .info {
    margin-left: 50px;
    margin-top: 25px;
  }
}

.progressdis {
  width: 90%;
  margin: 24px auto;
}

.invoice-content {
  text-align: center;
  height: 606px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 30px;
  margin-top: 16px;
}

.invoice-img {
  margin-top: 80px;
}

.invoice-text {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.invoice-upload {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.invoice-btn {
  width: 170px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  margin-top: 24px;
  cursor: pointer;
}

.invoice-btn:hover {
  background: rgba(47, 64, 235, 1);
}

.ease {
  transition: all 0.3s;
}
</style>
