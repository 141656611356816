<template>
  <div class="section" style="padding-bottom: 0" v-if="loaded">
    <div class="edit-company" v-if="roleIdList.indexOf(22) > -1 || authorizationFromHouTai">
      <el-button v-if="editType" @click="cancelCompany">取消</el-button>
      <el-button v-if="editType" type="primary" @click="submitCompany">确定</el-button>
      <i v-if="!editType" @click="editCompany" class="editCompany el-icon-edit"></i>
    </div>
    <div class="section-title">
      <div class="title">财税风险</div>
      <!-- <BigPowerSwitcher v-model="showType"></BigPowerSwitcher> -->
    </div>
    <div v-if="showType === 'table'">
      <div class="table-wrapper">
        <!-- <div class="time">检测区间：{{ data.period }}</div> -->
        <div class="time" v-if="!editType">
          {{ dataText }}
        </div>
        <div class="time" v-if="editType">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="dataEdit">
          </el-input>
        </div>
        <BigPowerTable :data="dataTable" v-if="dataTable" class="table"></BigPowerTable>
      </div>
    </div>
    <div v-if="showType === 'chart'">
      <div class="stats-wrapper">
        <div class="time">检测区间：{{ data.period }}</div>
        <div class="stats">
          <div class="stats-item">
            <div class="number">{{ data.total }}</div>
            <div class="text">检测总项</div>
          </div>
          <div class="stats-item">
            <div class="number">{{ data.highRisk }}</div>
            <div class="text">高风险</div>
          </div>
          <div class="stats-item">
            <div class="number">{{ data.middleRisk }}</div>
            <div class="text">中风险</div>
          </div>
          <div class="stats-item">
            <div class="number">{{ data.lowRisk }}</div>
            <div class="text">低风险</div>
          </div>
          <div class="stats-item">
            <div class="number">{{ data.noRisk }}</div>
            <div class="text">无风险</div>
          </div>
        </div>
      </div>
      <div class="ciyun-wrapper">
        <div class="toolbar">
          <BigPowerLegends
            name="风险词云"
            :legends="[
              { color: '#EA6C5B', text: '高风险' },
              { color: '#F7C122', text: '中风险' },
              { color: '#63DAAB', text: '低风险' },
            ]"
          ></BigPowerLegends>
        </div>
        <div class="section-body">
          <!--            <Danmu class="danmu" ref="danmu"></Danmu>-->
          <Ciyun
            legendsName="风险词云"
            class="danmu"
            ref="danmu"
            :data="data.riskWordCloudList"
            v-if="data.riskWordCloudList"
            @click="handleCiyunClick"
          ></Ciyun>
          <div class="danmu" v-else></div>
        </div>
      </div>
    </div>
  </div>
  <div class="main section" style="padding-bottom: 0" v-else>
    <BigPowerLoading height="790px"></BigPowerLoading>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl";
import BigPowerSwitcher from "@/pages/Result/ThreeYearResult/components/BigPowerSwitcher";
import BigPowerLegends from "@/pages/Result/ThreeYearResult/components/BigPowerLegends";
import { getAutoRoutePushTo } from "@/utils/helper";
import Ciyun from "@/pages/Result/ThreeYearResult/financialAnalysis/Ciyun";
import BigPowerTable from "big-power-table";
import { getFinancialRisk } from "@/api/threeYears";

export default {
  name: "FinancialRisk",
  components: {
    BigPowerTable,
    BigPowerSwitcher,
    BigPowerLegends,
    Ciyun,
  },
  async mounted() {
    this.loaded = false;
    await this.loadFinancialRisk();
    this.loaded = true;
        // 
    this.getAuth();
  },
  data() {
    return {
      authorizationFromHouTai: this.$route.query.fromHouTai || "",
      roleIdList: [],
      showType: "table",
      data: {},
      loaded: false,
      dataText: "",
      dataEdit: "",
      editType: false
    };
  },
  methods: {
    // 报告权限获取
    getAuth() {
      if (!localStorage.getItem("user")) return;
      var roList = JSON.parse(localStorage.getItem("user")).roleList;
      this.roleIdList = roList.map((item) => {
        if(item) {
          return item.id;
        }
      });
      // 21报告下载  22报告编辑
    },
    submitCompany() {
      this.dataText = this.dataEdit;
      this.$emit("dataText", this.dataEdit);
      this.editType = false;
    },
    editCompany() {
      this.dataEdit = JSON.parse(JSON.stringify(this.dataText));
      this.editType = true;
    },
    cancelCompany() {
      this.$emit("dataText", this.dataText);
      this.editType = false;
    },
    handleCiyunClick(data) {
      this.$eventBus.$emit("switchToDetailTab", { anchor: data.id });
      //this.$router.push(getReportRoute(this.uuid,  {anchor: data.id}));
      //const fullURL = new URL(props.href, window.location.href).href;
      //location.href=fullURL;
    },
    async loadFinancialRisk() {
      const res = await getFinancialRisk({
        programId: this.$route.params.uuid,
      });
      this.data = res.data.data;
      this.dataText = res.data.text;
      this.$emit("dataText", this.dataText);
    },
  },
  computed: {
    dataTable() {
      if (this.data.dataTable) {
        this.data.dataTable.body.forEach((row, index) => {
          if (row[2]) {
            if (row[2].content === "高") {
              row[2].color = "#F53F3F";
            } else if (row[2].content === "中") {
              row[2].color = "#FF7D00";
            } else if (row[2].content === "低") {
              row[2].color = "#00B42A";
            }
          }
        });
      }
      return this.data.dataTable;
    },
  },
};
</script>

<style lang="stylus" scoped>
.section-title
  height: 22px;
  color: #1D2129;
  line-height: 22px;
  display flex
  justify-content space-between
  border-bottom 1px solid #F2F3F5;
  padding-bottom 10px

  .title
    font-size: 16px;
    font-weight: 600;

.stats-wrapper
  background: #FFFFFF;
  border: 1px solid #F2F3F5;
  margin 20px 0
  padding 20px

  .time
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
    line-height: 20px;

  .stats
    display flex
    margin-top 24px
    margin-bottom 7px

    .stats-item
      width 25%;
      display flex;
      align-items center
      justify-content center
      flex-direction column
      border-left 1px solid #F2F3F5;

      .number
        font-size: 30px;
        font-weight: 600;
        color: #1D2129;
        line-height: 42px;

      .text
        font-size: 14px;
        font-weight: 400;
        color: #4E5969;
        line-height: 20px;

    .stats-item:first-child
      border-left 0 none

.ciyun-wrapper
  .toolbar
    display flex
    justify-content flex-end

.table-wrapper
  background: #FFFFFF;
  margin 20px 0
  margin-bottom 0
  padding-bottom 24px

  .time {
    margin 20px 16px
    font-size: 14px;
    font-weight: 400;
    color: #86909C;
  }

  .table {
    >>> .bigPowerTable {
      max-height 720px
      overflow auto
    }

  }

.section {
  position: relative;
  margin: 0 20px;
  margin-bottom: 10px;
}

.edit-company {
  position: absolute;
  top: 10px;
  right: 25px;
}

.editCompany {
  color: rgb(47, 84, 235);
}
</style>
