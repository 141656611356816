<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>客户管理</el-breadcrumb-item>
          <el-breadcrumb-item>客户分配记录</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">客户分配记录</template>
      </main-header>
    </div>
    <div class="filter-body">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="form-item">
            <div class="label">客户名称:</div>
            <el-input
              v-model="filter.consumerName"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeName"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">客户行业:</div>
            <el-select
              v-model="filter.industry"
              clearable
              placeholder="请选择"
              filterable
              @change="search"
            >
              <el-option
                v-for="item in hyList"
                :key="item.hy_dm"
                :label="item.hymc"
                :value="item.hy_dm"
              ></el-option>
            </el-select>
          </div>
          <div class="form-item">
            <div class="label">原关联账号:</div>
            <el-select
              v-model="filter.oldUserName"
              clearable
              placeholder="请选择"
              filterable
              @change="search"
            >
              <el-option
                v-for="(item, index) in oldRoleOptions"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="form-item">
            <div class="label">现项目经理:</div>
            <el-select
              v-model="filter.newUserName"
              clearable
              placeholder="请选择"
              filterable
              @change="search"
            >
              <el-option
                v-for="(item, index) in roleOptions"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="form-item form-create-time">
            <div class="label">分配时间:</div>
            <el-date-picker
              v-model="filter.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="search"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="btn-look-up ease" @click="search">搜索</div>
    </div>
    <!-- <div class="addItem ease" @click="addProject">创建客户</div>
    <el-upload
      class="upload-demo addItem ease addAllItem"
      ref="upload"
      action
      :on-change="uploadChange"
      :limit="1"
      :auto-upload="false"
      :show-file-list="false"
      v-if="haveBoth"
    >
      <div class="addItem ease addAllItem">批量导入</div>
    </el-upload>
    <div class="addItem ease addAllItem" @click="submitUpload()" v-if="showUploadBtn">
      上传到服务器
    </div>
    <div class="download">
      <a
        href="https://shuian-file.oss-cn-hangzhou.aliyuncs.com/taxfirm/%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E5%AE%A2%E6%88%B7.xlsx"
        >下载模板</a
      >
    </div> -->

    <div class="content-body">
      <template>
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column width="10px"></el-table-column>
          <el-table-column
            prop="allocateTime"
            label="分配时间"
            sortable
            min-width="13%"
          ></el-table-column>
          <el-table-column
            prop="companyName"
            label="客户名称"
            :show-overflow-tooltip="true"
            min-width="20%"
          ></el-table-column>
          <el-table-column label="客户行业" :show-overflow-tooltip="true" min-width="15%">
            <template slot-scope="scope">
              <span v-if="dataList && scope.row.industry">{{
                dataList[scope.row.industry]
              }}</span>
              <span v-else>暂无行业信息</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="oldUserName"
            label="原关联账号"
            min-width="10%"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <span class="bill-set">原关联账号</span>
              <el-tooltip popper-class="tooltip" placement="top" :open-delay="300">
                <i class="el-icon-info"></i>
                <div slot="content" class="tooltip-content">
                  <div>客户最近一次被分配前所关联的账号</div>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="newUserName"
            label="现项目经理"
            min-width="10%"
            :show-overflow-tooltip="true"
          >
            <template slot="header">
              <span class="bill-set">现项目经理</span>
              <el-tooltip popper-class="tooltip" placement="top" :open-delay="300">
                <i class="el-icon-info"></i>
                <div slot="content" class="tooltip-content">
                  <div>客户最近一次被指定分配后归属的项目经理</div>
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <el-pagination
      class="pagination-low"
      @current-change="handleCurrentChange"
      :current-page="pageno"
      layout="total, prev, pager, next, jumper"
      :total="totalNumber"
      :haveTotal="haveTotal"
    ></el-pagination>
  </div>
</template>

<script>
import {
  getCustomListNew,
  getCustomListDetail,
  getTradeList,
  importLoseOrder,
  getRegion,
  getAllocationConsumerPage,
  getProjectManagerAndOther,
  getAllUserByFirmId,
} from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import { area } from "@/pages/product/utils/limit.js";

import { cloneDeep } from "lodash";

export default {
  components: {
    MainHeader,
    BreadCrumb,
  },

  data() {
    return {
      oldRoleOptions: [],
      roleOptions: [],
      haveTotal: true,
      haveCusDetail: true,
      haveCusAdd: true,
      haveCusEdit: true,
      haveBoth: true,
      detailAddForm: false,
      showUploadBtn: false,
      showMore: false,
      createInfo: this.$route.query.create,
      dataList: null,
      hyList: null,
      totalNumber: 10, //总数
      current: {},
      currentEdit: {},
      input: "",
      isEdit: false,
      dialogTableVisible: false,
      totalElements: 0,
      pageno: 1,
      filter: {
        consumerName: "",
        time: [],
        industry: "",
        oldUserName: "",
        newUserName: "",
      },
      showDetail: false,
      loading: true,
      tableDataList: null,
      customItem: {},
      conversionArea: "",
      areaTree: [],
    };
  },
  computed: {
    city: function () {
      if (!this.areaTree) {
        return [];
      }
      const ret = cloneDeep(this.areaTree);
      function walk(data) {
        data.value = data.code;
        data.label = data.name;
        if (data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child);
          }
        }
        delete data.childNode;
      }
      for (const areaTree of ret) {
        walk(areaTree);
      }
      return [...ret];
    },
  },

  mounted() {
    this.getcustomList();
    this.gettradeList();
    // 移除同时上传标志
    sessionStorage.removeItem("both");
    // 判断是否是从项目清单进入的，如果从项目清单进度，则自动弹窗
    if (this.createInfo) {
      this.dialogTableVisible = true;
    }
    // 判断是否有项目列表权限
    this.checkCustomList(9);
    // 判断是否有查看客户详情权限
    this.checkCustomDetail(7);
    // 查看是否有创建客户权限
    this.checkCustomAdd(6);
    // 查看是否有编辑客户权限
    this.checkCustomEdit(8);
    // 查看是否有批量导入功能
    this.checkCustomBoth(47);
    this.getRegionData();
    //
    this.getProjectManager();
    this.getAllUserByFirm();
  },

  methods: {
    // 原账户
    getAllUserByFirm() {
      getAllUserByFirmId().then((res) => {
        this.oldRoleOptions = res.data;
      });
    },
    // 获取可使用项目经理
    getProjectManager() {
      getProjectManagerAndOther().then((res) => {
        this.roleOptions = res.data;
      });
    },
    getRegionData() {
      getRegion().then((res) => {
        this.areaTree = res.data;
      });
    },
    // 修改客户成功后
    editCustomSuccess(val) {
      this.getcustomList();
      this.customItem = val;
      this.conversionArea = area(
        [
          this.customItem.provinceCode,
          this.customItem.cityCode,
          this.customItem.districtCode,
        ],
        this.city
      );
    },
    // 添加客户成功之后的事件
    addCustomSuccess() {
      this.dialogTableVisible = false;
      this.getcustomList();
    },
    // 关闭添加客户弹窗
    closeCreatedCustom() {
      this.dialogTableVisible = false;
    },
    // 点击关闭详情
    showDetailFalse() {
      this.showDetail = false;
      this.isEdit = false;
    },
    // 权限方法
    checkAuthMethod(id) {
      let getUserAuth = JSON.parse(localStorage.getItem("user")).authorities;
      if (!getUserAuth) {
        return "none";
      }
      this.roleList = JSON.parse(localStorage.getItem("user")).authorities[0].roleList;
      let roleListId = this.roleList.map((item) => {
        return item.id;
      });
      if (roleListId.indexOf(id) == -1) {
        return false;
      } else {
        return true;
      }
    },
    // 查看是否有批量导入权限
    checkCustomBoth(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveBoth = false;
      }
    },
    // 查看是否有编辑客户权限
    checkCustomEdit(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveCusEdit = false;
      }
    },
    // 查看是否有项目列表权限
    checkCustomList(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveTotal = false;
      }
    },
    // 查看是否有客户查看权限
    checkCustomDetail(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveCusDetail = false;
      }
    },
    // 查看是否有创建客户权限
    checkCustomAdd(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveCusAdd = false;
      }
    },
    // 批量导入客户
    uploadChange(file, fileList) {
      this.fileList = fileList;
      this.loading = this.$loading({
        lock: true,
        text: "正在批量导入客户...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.submitUpload();
    },
    // 批量导入
    submitUpload() {
      let formFile = new FormData();
      let file = this.fileList[0].raw;
      formFile.append("file", file);
      this.getToExcel(formFile);
    },
    // 批量导入
    getToExcel(formFile) {
      importLoseOrder(formFile)
        .then((res) => {
          this.loading.close();
          this.$refs.upload.clearFiles();
          this.getcustomList();
          if (res.msgCode == 1) {
            if (res.data && res.data.insertSuccess) {
              this.$alert("成功导入" + res.data.insertSuccess + "个客户", "温馨提示", {
                confirmButtonText: "确定",
                type: "success",
                callback: (action) => {},
              });
            }
          } else {
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
              type: "warning",
              callback: (action) => {},
            });
          }
        })
        .catch((err) => {
          this.$message.error("服务器错误");
        });
    },
    // 地区变化
    citychange() {
      this.currentEdit.provinceCode = this.currentEdit.cityCodeList[0];
      this.currentEdit.cityCode = this.currentEdit.cityCodeList[1];
      this.currentEdit.districtCode = this.currentEdit.cityCodeList[2];
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageno = val;
      this.getcustomList();
    },
    // 获取行业
    gettradeList() {
      getTradeList().then((res) => {
        if (res.msgCode == 1) {
          let data = JSON.parse(res.data);
          this.hyList = data;
          let dataList = {};
          for (let i = 0; i < this.hyList.length; i++) {
            this.hyList[i].hymc = this.hyList[i].hy_dm + this.hyList[i].hymc;
            dataList[this.hyList[i].hy_dm] = this.hyList[i].hymc;
          }
          this.dataList = dataList;
        } else {
          this.$message({
            showClose: true,
            message: "获取行业列表失败！",
            type: "error",
          });
        }
      });
    },
    // 搜索
    search() {
      this.pageno = 1;
      this.getcustomList();
    },
    changeName() {
      if (!this.filter.name) {
        this.pageno = 1;
        this.getcustomList();
      }
    },
    changeContact() {
      if (!this.filter.contact) {
        this.pageno = 1;
        this.getcustomList();
      }
    },
    changeNumber() {
      if (!this.filter.contactNumber) {
        this.pageno = 1;
        this.getcustomList();
      }
    },
    changeSalesman() {
      if (!this.filter.salesman) {
        this.pageno = 1;
        this.getcustomList();
      }
    },
    // 获取客户列表
    getcustomList() {
      let params = {
        consumerName: this.filter.consumerName,
        endDate: this.filter.time ? this.filter.time[1] : null,
        industry: this.filter.industry,
        newUserName: this.filter.newUserName,
        oldUserName: this.filter.oldUserName,
        pageNum: this.pageno,
        pageSize: 10,
        startDate: this.filter.time ? this.filter.time[0] : null,
      };

      getAllocationConsumerPage(params).then((res) => {
        if (res.code == 200) {
          this.tableDataList = res.list;
          this.totalNumber = res.pageTotal;
        } else {
          this.$message({
            showClose: true,
            message: res.msgInfo,
            type: "error",
          });
        }
      });
    },

    // 创建客户
    addProject() {
      if (!this.haveCusAdd) {
        this.$message({
          message: "抱歉，您没有创建客户权限！",
          type: "warning",
        });
        return;
      }
      this.dialogTableVisible = true;
      this.showMore = false;
    },

    // 查看客户详情
    viewDetail(id) {
      if (!this.haveCusDetail) {
        this.$message({
          message: "抱歉，您没有查看客户详情权限！",
          type: "warning",
        });
        return;
      }
      this.showDetail = true;
      this.detailAddForm = false;
      let params = {
        id: id,
      };
      getCustomListDetail(params, localStorage.getItem("authorization")).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            showClose: true,
            message: res.msgInfo,
            type: "error",
          });
        }
        this.customItem = res.data;
        this.loading = false;
        this.conversionArea = area(
          [
            this.customItem.provinceCode,
            this.customItem.cityCode,
            this.customItem.districtCode,
          ],
          this.city
        );
      });
    },

    // 关闭客户详情
    hiddenDetail() {
      this.showDetail = false;
      this.isEdit = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.containerMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1002;
}

.container {
  position: absolute;
  left: 50%;
  margin-left: -240px;
  top: 10vh;
  width: 480px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
}

>>> #projectBoxs .el-cascader {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.filter-body {
  margin: 20px;
  padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  // padding: 20px;
  // padding-top: 20px;
  // padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-top: 16px;

  .inputs-wrapper {
    width: 92%;

    .inputs-wraper-row {
      display: flex;
      flex-wrap: wrap;

      .form-item {
        margin-right: 40px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .label {
          white-space: nowrap;
          width: 78px;
          text-align: right;
          margin-right: 8px;
        }

        display: flex;
      }
    }
  }

  .btn-look-up {
    margin-right: 16px;
    width: 76px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #2F54EB;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.addItem {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 20px;
  cursor: pointer;
  display: inline-block;
  margin-right: 16px;
}

.addItem:hover {
  background: rgba(47, 34, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.download {
  display: inline-block;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
  cursor: pointer;
}

>>> .form-item .el-input__icon {
  line-height: 36px;
  height: 36px;
}

.el-icon-edit-outline {
  color: #2f54eb;
  font-size: 18px;
  margin-left: 4px;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.pagination-low {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}

>>>.el-select {
  width: 240px;
}

>>>.el-input {
  width: 240px;
}

>>>.el-range-editor.el-input__inner {
  width: 240px;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

.container >>>.el-select {
  width: 416px;
}

.container >>>.el-input {
  width: 416px;
}

>>>.el-pagination__editor.el-input {
  width: 50px;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

>>>.el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

>>>.el-input__icon {
  width: 14px;
}

>>>.el-range-editor.el-input__inner {
  padding: 3px 8px;
}

>>>.el-date-editor .el-range-separator {
  line-height: 29px;
}

>>>.form-item .el-input__icon {
  line-height: 36px;
}

.addAllItem {
  margin-left: 0;
}
.form-create-time {
  >>> .el-date-editor {
    width: 240px;
  }

  >>> .el-date-editor el-range-editor el-input__inner el-date-editor--daterange {
    width: 240px;
  }
}

.filter-body .inputs-wrapper .inputs-wraper-row .form-item {
  width: auto;
}
</style>
