<template>
  <div class="container" id="projectBoxs" v-loading="addLoading">
    <div class="addTitle">创建角色</div>
    <div class="project-close" @click="closeRole">×</div>
    <div class="project-name">
      <span class="project-named">*</span>角色名称:
    </div>
    <div class="project-input">
      <el-input placeholder="请输入" v-model.trim="person.companyName" autofocus></el-input>
      <span class="project-input-b" v-show="companyNameNo">角色名称不能为空</span>
    </div>
    <div class="project-btn ease btn-btn" @click="addProjectForm">
      <div>创建角色</div>
    </div>
  </div>
</template>

<script>
import { roleTotalListadd } from "@/api/api";
export default {
  data() {
    return {
      addLoading: false,
      person: {},
      companyNameNo: false,
    };
  },
  methods: {
    closeRole() {
      this.companyNameNo = false;
      this.$emit("closeAddBox");
    },
    addProjectForm() {
      if (!this.person.companyName || !this.person.companyName.trim()) {
        this.companyNameNo = true;
        return;
      }
      this.roletotalListadd();
    },
    roletotalListadd() {
      this.addLoading = true;
      let params = {
        name: this.person.companyName.trim(),
      };
      roleTotalListadd(params, localStorage.getItem("authorization")).then(
        (res) => {
          this.addLoading = false;
          if (res.msgCode == 1) {
            this.notify("添加角色成功！");
            // this.addRoleBoolean = true;
            // this.roleTotalList();
            // this.dialogTableVisible = false;
            this.$emit("addRoleSuccess");
            this.person.companyName = "";
            this.companyNameNo = false;
          } else {
            this.errorTip(res.msgInfo);
          }
        }
      );
    },
    // 成功提示
    notify(text) {
      this.$notify({
        title: "温馨提示",
        message: text,
        type: "success",
      });
    },

    // 错误提示
    errorTip(text) {
      this.$message({
        message: text,
        type: "warning",
        duration: 4000,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.container {
  position: absolute;
  left: 50%;
  margin-left: -240px;
  top: 15vh;
  width: 480px;
  height: 228px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
}

>>> #projectBoxs .el-input {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs >>>.el-input >>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #projectBoxs .el-input__icon {
  line-height: 36px;
}

>>> #projectBoxs .el-cascader {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.addTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  padding: 24px 0 20px 0;
}

.project-close {
  position: absolute;
  cursor: pointer;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  top: 6px;
  right: 20px;
}

.project-name {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 0 0 4px 32px;
}

.project-named {
  position: relative;
  top: 2px;
  margin-right: 4px;
  color: #f5222d;
}

.project-input, .addForms {
  position: relative;
}

.project-input-b {
  position: absolute;
  right: 40px;
  top: 40px;
  font-size: 12px;
  color: red;
}

.project-input .el-input {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  line-height: 68px;
  background: rgba(47, 84, 235, 1);
  box-shadow: 0px -1px 0px 0px rgba(47, 84, 235, 1);
  z-index: 1004;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.btn-btn:hover {
  background: rgba(47, 34, 235, 1);
}
</style>