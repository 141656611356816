<template>
  <div>
    <div class="risk-problem-wrapper">
      <div class="desc">
        <div class="item">
          <div class="title">风险说明</div>
          <div class="textarea-wrapper">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="ruleRiskStatement"
              maxlength="3000"
              show-word-limit
              class="textarea"
              :disabled="!isEditable"
              :autosize="{ minRows: 3, maxRows: 10}"
            ></el-input>
          </div>
        </div>
        <div class="item">
          <div class="title blue">解决方案</div>
          <div class="textarea-wrapper">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="ruleAdvise"
              maxlength="3000"
              show-word-limit
              class="textarea"
              :disabled="!isEditable"
              :autosize="{ minRows: 3, maxRows: 10}"
            ></el-input>
          </div>
        </div>
        <div class="item">
          <div class="title blue">法规</div>
          <div class="textarea-wrapper">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="policy"
              maxlength="3000"
              show-word-limit
              class="textarea"
              :disabled="!isEditable"
              :autosize="{ minRows: 3, maxRows: 10}"
            ></el-input>
          </div>
        </div>
        <div class="item">
          <div class="title blue">案例</div>
          <div class="textarea-wrapper">
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="caseText"
              maxlength="3000"
              show-word-limit
              class="textarea"
              :disabled="!isEditable"
              :autosize="{ minRows: 3, maxRows: 10}"
            ></el-input>
          </div>
        </div>

      </div>
      <div class="chart-wrapper">
        <RiskProblemEchart
          :list="pdfData.ruleParamsTemplete"
          ref="child"
          v-if="pdfData.ruleParamsTemplete"
          :bq="node.zbBq"
          :sq="node.zbSq"
        ></RiskProblemEchart>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import RiskProblemEchart from "./RiskProblemEchart";
import mixin from "@/pages/ResultEditor/mixin.js";

export default {
  mixins: [mixin],
  components: {
    RiskProblemEchart
  },
  props: {
    indexes: Array
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    node() {
      return this.findTheNodeByIndexes(this.indexes);
    },

    pdfData() {
      return JSON.parse(this.node.pdfData);
    },
    ruleRiskStatement: {
      get() {
        return this.pdfData.ruleRiskStatement;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.ruleRiskStatement = value;
        nodeData.pdfData = JSON.stringify(pdfData);

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes
        });
      }
    },
    ruleAdvise: {
      get() {
        return this.pdfData.ruleAdvise;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.ruleAdvise = value;
        nodeData.pdfData = JSON.stringify(pdfData);

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes
        });

        // const pdfData = cloneDeep(this.pdfData);
        // pdfData.ruleAdvise = value;

        // //直接调用父组件里的方法设置属性
        // this.$parent.setTreeNodeAttribute({
        //   pdfData: JSON.stringify(pdfData)
        // });
      }
    },
    caseText: {
      get() {
        return this.pdfData.caseText;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.caseText = value;
        nodeData.pdfData = JSON.stringify(pdfData);

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes
        });

        // const pdfData = cloneDeep(this.pdfData);
        // pdfData.ruleAdvise = value;

        // //直接调用父组件里的方法设置属性
        // this.$parent.setTreeNodeAttribute({
        //   pdfData: JSON.stringify(pdfData)
        // });
      }
    },

    policy: {
      get() {
        return this.pdfData.policy;
      },
      set(value) {
        const nodeData = cloneDeep(this.node);
        const pdfData = cloneDeep(this.pdfData);
        pdfData.policy = value;
        nodeData.pdfData = JSON.stringify(pdfData);

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes
        });

        // const pdfData = cloneDeep(this.pdfData);
        // pdfData.ruleAdvise = value;

        // //直接调用父组件里的方法设置属性
        // this.$parent.setTreeNodeAttribute({
        //   pdfData: JSON.stringify(pdfData)
        // });
      }
    }
  },
  methods: {
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes"])
  }
};
</script>

<style lang="stylus" scoped>
.risk-problem-wrapper {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 0px 0px;
  background: #fff;
  padding: 24px;
  background: rgba(251, 251, 251, 1);

  .chart-wrapper {
    display: flex;
  }

  .desc {
    background: rgba(240, 245, 255, 1);
    padding: 16px;

    .title {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
    }

    .textarea-wrapper {
      margin: 16px;
    }
  }
}
</style>
