<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>小工具</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ name: 'businessCirclesQuery' }">工商全景</el-breadcrumb-item>
          <el-breadcrumb-item>企业信息查询</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">企业信息查询</template>
      </main-header>
    </div>
    <div class="company-body">
      <div class="search-wrapper">
        <el-input
          placeholder="请输入"
          class="search-input"
          prefix-icon="el-icon-search"
          v-model="word"
          clearable
        />
        <button class="btn-primary btn-search" @click="getQuerySectionTwo">查一下</button>
      </div>
      <div class="company-list" v-loading="loading" element-loading-text="拼命加载中">
        <div
          class="content"
          v-for="item in dataList"
          v-bind:key="item.keyWord"
          @click="goChangeRecord(item.keyWord)"
        >
          <div class="contentLeft">
            <div class="left">
              <div>{{ item.name.slice(0, 4) }}</div>
            </div>
            <div class="right">
              <div class="title">{{ item.name }}</div>
              <div class="name">法人：{{ item.legalPersonName }}</div>
              <div class="date">成立日期：{{ item.estiblishTime }}</div>
            </div>
          </div>
          <div class="end" v-if="item.regStatus">
            <div>{{ item.regStatus }}</div>
          </div>
        </div>
      </div>

      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 100, 200, 300, 400]"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
          <!--   layout="total, sizes, prev, pager, next, jumper" -->
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getQuerySectionList, getCreateDataFun } from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
export default {
  components: {
    MainHeader,
    BreadCrumb,
  },
  data() {
    return {
      dialogVisible: false,
      dataList: null,
      word: "",
      pageNum: 1,
      pageSize: 10,
      programId: "",
      total: 1,
      loading: true,
    };
  },
  async mounted() {
    this.word = this.$route.params.dataPeriod;
    this.getList();
    //this.getQuerySectionTwo();
  },
  methods: {
    //工商全景画像信息模糊搜索
    getList() {
      this.loading = true;
      this.dataList = [];
      getQuerySectionList(this.word, this.pageNum, this.pageSize).then((res) => {
        this.dataList = res.list;
        this.total = res.pageTotal;
        this.loading = false;
      });
    },
    //二次模糊搜索
    getQuerySectionTwo() {
      this.getList();
    },

    handleSizeChange(size) {
      this.pageSize = size;
      this.pageNum = 1;
      this.getList();
    },
    async goChangeRecord(keyword) {
      this.$confirm(`确定生成工商全景报告吗?`, "温馨提示", {
        confirmButtonText: "确定",
        showCancelButton: true,
      })
        .then(async () => {
          //创建工商全景画像报告
          const res = await getCreateDataFun(keyword);
          const programId = res.data;
          this.$router.push({ path: "/jinzhiReport/" + programId });
        })
        .catch(() => {});
    },
    //页码改变
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
  },
};
</script>
<style lang="stylus" scoped>
.company-body {
  margin:0 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: column
  //justify-content: space-between;
  margin-top: 16px;
}

.search-wrapper {
  display flex
  width: 98%;
  justify-content: space-between
  align-items center

  .search-input {
    width 100%

    display: block;
    //text-align: center;
    line-height 50px;
    margin-left 20px
    font-size: 18px


    :deep(.el-input__inner) {
      border-radius: 4px 0px 0px 4px;
      width 100%
      //height 36px
      border: 1px solid #2F54EB;
    }
  }

  .btn-search {
    padding 0
    display: block;
    font-size: 14px;
    font-weight: 400
    line-height 37px;
    text-align: center
    border-radius: 5px
    width: 240px;
    height: 40px;
    background: #2F54EB;
    border-radius: 0px 4px 4px 0px;
  }
}

.company-list {
  min-height 300px
  overflow-y auto
}

.content {
  display: flex;
  justify-content: space-between
  box-shadow: 0px 1px 1px #f5f5f5
  //margin 16px
  align-items center
  height:134px;
}

.content:hover {
  background rgba(78, 154, 239, 0.1);
  cursor pointer
}

.contentLeft {
  display: flex;
  justify-content: space-between
  //border-bottom:1px solid #f5f5f5


  .left {
    background: #365AEB;
    color: #fff
    border-radius: 8px;
    font-size: 20px;
    font-weight: 400
    width: 80px;
    height 80px;
    padding 17px;
    line-height 28px;
    display: flex;
    justify-content: space-between
    box-sizing: border-box;
    padding-top: 10px;
    margin-top:27px;
    margin-left:24px;
  }

  .right {
    display: flex;
    justify-content: flex-start
    flex-direction: column
    margin-left 16px

    .title {
      margin-top:24px
      //width: 140px;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0,0,0,0.8);
      line-height: 28px;
    }

    .name {
      margin 5px;
      margin-bottom 10px
    }

    .date {
      margin-left:5px
    }
  }
}

.company-body >>> .company-body.el-input__inner {
  width 400px
}

.end {
  margin-right 40px
  width: 76px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,0.2);
  font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0,0,0,0.65);
line-height: 35px;

  div {

    display: flex;
    justify-content: center;
  }
}

.business {
  color: greenyellow;
  border: 1px solid greenyellow;
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

.dialog .dialogText {
  font-size: 17px;
  margin: 5px;
}

.block {
  margin 16px
  text-align: right ;
}
>>>.el-input__icon{
color: #2F54EB;
}
</style>
