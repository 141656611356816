import moment from "moment";
export const isNumeric = str => {
  if (typeof str === "number") {
    return true;
  } else if (typeof str === "string") {
    if (str.indexOf(",") >= 0) {
      //包含千分号
      const thousandSeperatorPreg = /^[-+]?[\d]{0,3}(?=(,[\d]{3})*(\.[\d]{0,2})?$)/;
      return thousandSeperatorPreg.test("1,234.00");
    } else {
      //没有千分号
      return /^[-+]?[\d]+(\.[\d]{0,2})?$/.test(str);
    }
  }
};

export const parseNumber = str => {
  if (typeof str === "number") {
    return str;
  }
  if (isNumeric(str)) {
    return parseFloat(str.replace(/,/g, ""));
  } else {
    return str;
  }
};

export function round(num, point = 2) {
  return parseFloat(num.toFixed(point));
}

export function dateRange(fromDate, toDate) {
  return fromDate == toDate ? fromDate : `${fromDate} - ${toDate}`;
}

export function toFixed(num,point=2) {
  return Number.parseFloat(num).toFixed(point)
}

export function percentage(num,point= 2) {
  return (Math.round(num * 100 * 10000) / 10000).toFixed(point) + "%";
}
