<template>
  <div class="handsonTable-wrapper">
    <div
      ref="example1"
      class="hot handsontable htRowHeaders htColumnHeaders"
      style="height: 320px; overflow: hidden; width: 100%;"
      data-originalstyle="height: 320px; overflow: hidden; width: 100%;"
    ></div>
  </div>
</template>

<script>
import { HotTable } from "@handsontable/vue";
import Handsontable from "handsontable";
export default {
  mounted() {
    var example = this.$refs.example1;
    var hot1 = new Handsontable(example, {
      data: Handsontable.helper.createSpreadsheetData(101, 202),
      colWidths: 100,
      licenseKey: "non-commercial-and-evaluation",
      width: "100%",
      height: 320,
      rowHeights: 23,
      rowHeaders: true,
      colHeaders: true
    });
  },
  data: function() {
    return {};
    // return {
    //   hotSettings: {
    //     // stretchH: "all",
    //     data: Handsontable.helper.createSpreadsheetData(1000, 1000),
    //     colWidths: 100,
    //     width: "100%",
    //     height: 800,
    //     rowHeights: 23,
    //     rowHeaders: true,
    //     colHeaders: true
    //   }
    // };
  },
  components: {
    HotTable
  }
};
</script>

<style lang="stylus">
@import '~handsontable/dist/handsontable.full.css';
</style>