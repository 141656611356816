<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
          <el-breadcrumb-item>检测记录</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">检测记录</template>
      </main-header>
    </div>
    <el-form class="filter-body" @submit.native.prevent="search">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="form-item">
            <div class="label">客户名称：</div>
            <el-input
              class="standard-input input"
              v-model="filter.consumer"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeConsumer"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">项目名称：</div>
            <el-input
              class="input"
              v-model="filter.projectName"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeProject"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">审核状态：</div>
            <div class="form-control">
              <el-select
                class="input"
                v-model="filter.reviewStatus"
                clearable
                placeholder="请选择"
                @change="search"
              >
                <el-option
                  v-for="item in sencorStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="form-item">
            <div class="label">生成状态：</div>
            <div class="form-control">
              <el-select
                class="input"
                v-model="filter.showStatus"
                clearable
                placeholder="请选择"
                @change="search"
              >
                <el-option
                  v-for="item in generateStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="form-item large">
            <div class="label">检测时间：</div>
            <el-date-picker
              class="input"
              v-model="filter.submitTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="search"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <AwaitButton class="btn btn-primary btn-look-up" @click="search">
          查询
        </AwaitButton>
      </div>
    </el-form>
    <div class="content-body-test" @click="testMethod">发起检测</div>
    <div class="content-body">
      <template>
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column width="70px" prop="id" label="#"></el-table-column>
          <el-table-column width="10"></el-table-column>
          <el-table-column
            prop="exe_time"
            label="检测时间"
            min-width="12%"
            :formatter="dateFormatter"
            :show-overflow-tooltip="true"
            sortable
          ></el-table-column>

          <el-table-column
            prop="project_name"
            label="项目名称"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="company_name"
            label="客户名称"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column prop="zqlx" label="检测类型" min-width="10%"></el-table-column>
          <el-table-column
            prop="zq_mc"
            label="报表征期"
            min-width="10%"
          ></el-table-column>
          <!-- <el-table-column prop="tag" label="检测版本"></el-table-column> -->
          <el-table-column prop="reviewer" label="生成状态" min-width="10%">
            <template slot-scope="scope">
              <div v-if="scope.row.show_status == 1" class="text-blue">
                <span class="circle blue"></span> 已生成
              </div>
              <div v-if="scope.row.show_status == 2" class="text-gray-darker">
                <span class="circle gray-darker"></span> 待生成
              </div>
              <div v-if="scope.row.show_status == 3" class="text-gray">
                <span class="circle gray"></span> 已删除
              </div>
              <div v-if="scope.row.show_status == 4" class="text-yellow">
                <span class="circle yellow"></span> 待完善
              </div>
              <div v-if="scope.row.show_status == 5" class="text-red">
                <span class="circle red"></span> 生成出错
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="review_status" label="审核状态" sortable min-width="13%">
            <template slot-scope="scope">
              <div v-if="scope.row.review_status == 1019"></div>
              <div v-if="scope.row.review_status == 1020">
                <span class="circle yellow"></span> 编辑中
              </div>
              <div v-if="scope.row.review_status == 1021">
                <span class="circle purple"></span> 待审核
              </div>
              <div v-if="scope.row.review_status == 1025">
                <span class="circle yellow"></span> 审核中
              </div>
              <div v-if="scope.row.review_status == 1022">
                <span class="circle cyan"></span> 待处理
              </div>
              <div v-if="scope.row.review_status == 1023">
                <span class="circle red"></span> 待审核
              </div>
              <div v-if="scope.row.review_status == 1024">
                <span class="circle green"></span> 审核通过
              </div>
              <div v-if="scope.row.review_status == 1026">
                <span class="circle green"></span> 审核通过
              </div>
              <div v-if="scope.row.review_status == 1027">
                <span class="circle yellow"></span> 编辑中
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="viewReport" label="操作" width="240">
            <template slot-scope="scope" v-if="haveShowAuth">
              <template v-if="scope.row.review_status == 1019">
                <router-link
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1020">
                <router-link
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1021">
                <router-link
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1025">
                <router-link
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1022">
                <router-link
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1023">
                <router-link
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1024">
                <router-link
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1026">
                <router-link
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  v-if="scope.row.show_status == 1"
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template v-if="scope.row.review_status == 1027">
                <router-link
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                      action: 'edit',
                    },
                  }"
                  class="manipulator-btn"
                  >报告</router-link
                >
                <button
                  @click="
                    handleCensorBtnLogClick({
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    })
                  "
                  class="manipulator-btn"
                >
                  记录
                </button>
              </template>
              <template> </template>
              <template>
                <a
                  class="showProce"
                  target="_blank"
                  v-if="scope.row.show_status == 1 && isShowDownloadInvoice"
                  :href="getInvoiceUrl(scope.row.taxNo)"
                >
                  发票
                </a>
              </template>
              <template>
                <div
                  class="showProce"
                  v-if="scope.row.show_status == 4"
                  @click="showFixFlow(scope.row)"
                >
                  去完善
                </div>
              </template>
              <template>
                <div
                  class="showProce"
                  v-if="scope.row.show_status == 5"
                  @click="retryAdd(scope.row)"
                >
                  重试
                </div>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="pagination-wrapper">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[2, 10, 20, 100]"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="totalSize"
      ></el-pagination>
    </div>
    <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false">
      <CensorHistory :uuid="uuid" :nsrsbh="nsrsbh"></CensorHistory>
    </el-drawer>
    <!--  -->
    <!-- 新建项目弹窗 -->
    <transition name="fades">
      <div
        class="containerMask"
        v-if="addProjectdialogTab"
        @click="addProjectdialogTab = false"
      ></div>
    </transition>
    <transition name="fadee">
      <project-add
        v-if="addProjectdialogTab"
        :tableDataLists="tableDataLists"
        :tableDataId="tableDataId"
        @addSuccess="addSuccess"
        @closeBox="addProjectdialogTab = false"
        :dataList="dataList"
        :city="city"
        :hyList="hyList"
        :isShowDetailId="isShowDetailId"
      ></project-add>
    </transition>
  </div>
</template>

<script>
import AwaitButton from "await-button";
import filterCacheMixin from "@/mixins/filterCacheMixin";
import {
  getPdfDataList,
  progressBar,
  retryFlowAdd,
  getCustomListNew,
  getTradeList,
  getRegion,
  getUUIDAi,
} from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import CensorHistory from "../../components/CensorHistory/CensorHistory";
import { veriFiItem, veriFiCustom, area } from "@/pages/product/utils/limit.js";
import { getInvoiceUrl } from "../../../api/api";

import { bSuperiorProjectRuleList } from "@/api/fdc";

import projectAdd from "@/pages/fdc/project-fdc/components/projectAdd.vue";

const moment = require("moment");

import { cloneDeep } from "lodash";

export default {
  mixins: [filterCacheMixin],
  components: {
    AwaitButton,
    MainHeader,
    BreadCrumb,
    CensorHistory,
    projectAdd,
  },

  mounted() {
    this.getPdfDataList();
    // 查看是否有查看报告权限
    this.checkShowIndex(17);
    //
    if (this.canChangeBillList.includes(this.canChangeBill)) {
      this.canChangeBillData = true;
    }

    sessionStorage.removeItem("threeActiveIndex");
    sessionStorage.removeItem("dataPeriod");
    //
    this.getRegionData();
    this.getcustomList();
    this.gettradeList();
  },

  data() {
    return {
      //
      canChangeBillList: [22, 29, 49],
      canChangeBill: JSON.parse(localStorage.getItem("user")).firmId,
      canChangeBillData: false,
      //
      showStatusPro: false,
      checkParts: false,
      dataVal: null,
      uploadStatus: null,
      classObject: "",
      processInfoStatus: false,
      processFalse: false,
      locks: false,
      drawer: false,
      totalSize: 10, //总数
      pageSize: 10,
      pageNo: sessionStorage.getItem("testPageNo")
        ? Number(sessionStorage.getItem("testPageNo"))
        : 0,
      uuid: "",
      nsrsbh: "",
      sencorStatusOptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1020,1027",
          label: "编辑中",
        },
        {
          value: "1021,1023",
          label: "待审核",
        },
        {
          value: 1022,
          label: "待处理",
        },
        {
          value: "1024,1026",
          label: "审核通过",
        },
      ],
      generateStatusOptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 1,
          label: "已生成",
        },
        {
          value: 2,
          label: "待生成",
        },
        {
          value: 4,
          label: "待完善",
        },
        {
          value: 5,
          label: "生成出错",
        },
      ],
      filter: {
        projectName: "",
        consumer: "",
        submitTime: [],
        reviewStatus: "",
        showStatus: "",
      },
      tableDataList: null,
      fixData: null,
      // 是否需要补全
      formData: {
        areaBoolean: false,
        kjzdBoolean: false,
        hyBoolean: false,
      },
      closeProcessTag: true,
      coverLoading: false,
      haveShowAuth: true,
      //
      addProjectdialogTab: false,
      tableDataLists: null,
      tableDataId: null,
      dataList: null,
      areaTree: [],
      hyList: null,
      isShowDetailId: -1,
    };
  },
  methods: {
    getInvoiceUrl(taxNo) {
      return getInvoiceUrl({ taxNo });
    },
    // 权限方法
    checkAuthMethod(id) {
      let getUserAuth = JSON.parse(localStorage.getItem("user")).authorities;
      if (getUserAuth.length == 0) {
        return "none";
      }
      this.roleList = JSON.parse(localStorage.getItem("user")).authorities[0].roleList;
      let roleListId = this.roleList.map((item) => {
        return item.id;
      });
      if (roleListId.indexOf(id) == -1) {
        return false;
      } else {
        return true;
      }
    },
    // 判断是否有查看报告权限
    checkShowIndex(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveShowAuth = false;
      }
    },
    retryAdd(data) {
      //
      this.$confirm("是否重试?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            nsrsbh: data.nsrsbh,
            uuid: data.uuid,
            invoiceAccessStatus: data.invoice_access_status,
            taxAccessStatus: data.tax_access_status,
            taxNo: data.taxNo,
          };
          retryFlowAdd(params).then((res) => {
            if (res.msgCode == 1) {
              this.$message({
                message: "重试成功，请耐心等待！",
                type: "success",
              });
              this.getPdfDataList();
            } else {
              this.$message({
                message: res.msgInfo,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {});
      //
    },
    showFixFlow(data) {
      if (!data.city_code && !data.province_code && !data.district_code) {
        this.formData.areaBoolean = true;
      }
      if (!data.kjzd) {
        this.formData.kjzdBoolean = true;
      }
      if (!data.industry) {
        this.formData.hyBoolean = true;
      }
      this.fixData = data;
      this.checkParts = true;
    },

    handleCensorBtnLogClick({ uuid, nsrsbh }) {
      this.uuid = uuid;
      this.nsrsbh = nsrsbh;
      this.drawer = true;
    },
    search() {
      this.pageNo = 0;
      this.saveFilterToCache(this.filter);
      this.getPdfDataList();
    },
    changeConsumer() {
      if (!this.filter.consumer) {
        this.search();
      }
    },
    changeProject() {
      if (!this.filter.projectName) {
        this.search();
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getPdfDataList();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      sessionStorage.setItem("testPageNo", this.pageNo);

      this.getPdfDataList();
    },
    reset() {
      this.filter = {
        projectName: "",
        consumer: "",
        submitTime: [],
      };
      this.getPdfDataList();
    },
    dateFormatter(row, column) {
      return moment(row.exe_time).format("YYYY-MM-DD HH:mm:ss");
    },
    async getPdfDataList() {
      let params = {
        ...this.filter,
        startTime:
          this.filter.submitTime && this.filter.submitTime[0]
            ? moment(this.filter.submitTime[0]).format("YYYY-MM-DD HH:mm:ss")
            : "",
        endTime:
          this.filter.submitTime && this.filter.submitTime[1]
            ? moment(this.filter.submitTime[1]).format("YYYY-MM-DD HH:mm:ss")
            : "",
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };

      const res = await bSuperiorProjectRuleList(params);
      const resData = res.data.content;

      this.tableDataList = resData;
      this.totalSize = res.data.totalSize;
    },
    // 获取客户列表
    getcustomList() {
      let params = {
        companyName: "",
        industry: "",
        contact: "",
        contactNumber: "",
        startTime: "",
        endTime: "",
        pageSize: 1000,
        pageNo: 0,
      };
      getCustomListNew(params, localStorage.getItem("authorization")).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            showClose: true,
            message: res.msgInfo,
            type: "error",
          });
        }
        this.tableDataLists = res.data.data.content;
        this.tableDataId = this.tableDataLists.map((item) => {
          return item.id;
        });
      });
    },
    addSuccess() {
      this.getprojectList();
      this.addProjectdialogTab = false;
    },
    // 获取行业
    gettradeList() {
      getTradeList().then((res) => {
        if (res.msgCode == 1) {
          let data = JSON.parse(res.data);
          this.hyList = data;
          let dataList = {};
          for (let i = 0; i < this.hyList.length; i++) {
            this.hyList[i].hymc = this.hyList[i].hy_dm + this.hyList[i].hymc;
            dataList[this.hyList[i].hy_dm] = this.hyList[i].hymc;
          }
          this.dataList = dataList;
        } else {
          this.$message({
            showClose: true,
            message: res.msgInfo,
            type: "error",
          });
        }
      });
    },
    getRegionData() {
      getRegion().then((res) => {
        this.areaTree = res.data;
      });
    },
    addProjectMethod() {
      this.isShowDetailId = -1;
      this.addProjectdialogTab = true;
    },
    async testMethod() {
      sessionStorage.setItem("method", 1);
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const uuidData = await getUUIDAi();
      if (uuidData.msgCode == 1) {
        this.loading.close();
        this.uuid = uuidData.data.uuid;
        this.$router.push({
          name: "choose-table-fdc",
          params: {
            productID: this.$store.state.goods.id,
            bankId: this.$store.state.goods.bankId,
            productName: "风险检测",
            uuid: this.uuid,
          },
        });
      } else {
        alert(uuidData.msgInfo);
        this.loading.close();
      }
    },
  },
  computed: {
    //某些账号ID显示下载按钮
    isShowDownloadInvoice() {
      return false;
      const userInfo = JSON.parse(localStorage.getItem("user"));
      if (process.env.NODE_ENV === "production") {
        return userInfo && (userInfo.id === 249 || userInfo.id === 215);
      } else {
        return userInfo && userInfo.id === 208;
      }
    },
    city: function () {
      if (!this.areaTree) {
        return [];
      }
      const ret = cloneDeep(this.areaTree);
      function walk(data) {
        data.value = data.code;
        data.label = data.name;
        if (data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child);
          }
        }
        delete data.childNode;
      }
      for (const areaTree of ret) {
        walk(areaTree);
      }
      return [...ret];
    },
  },
};
</script>

<style lang="stylus" scoped>
.pagination-wrapper {
  text-align: right;
  align-items: center;
  height: 36px;
  line-height: 36px;
  margin: 20px 30px 20px 0;
}

.project-input .el-input {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.content-body {
  margin: 16px 0 16px 30px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.ease {
  transition: all 0.3s;
}

>>> .form-item .el-input__icon {
  line-height: 36px;
  height: 36px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(238, 238, 238, 1);
  display: inline-block;
}

.circle.gray {
  background: rgba(0, 0, 0, 0.2);
}

.circle.gray-darker {
  background: rgba(0, 0, 0, 0.4);
}

.circle.blue {
  background: #2F54EB;
}

.circle.cyan {
  background: rgba(24, 144, 255, 1);
}

.circle.green {
  background: rgba(82, 196, 26, 1);
}

.circle.red {
  background: rgba(245, 34, 45, 1);
}

.circle.purple {
  background: rgba(153, 47, 235, 1);
}

.circle.yellow {
  background: rgba(250, 173, 20, 1);
}

.text-gray {
  color: rgba(0, 0, 0, 0.2);
}

.text-gray-darker {
  color: rgba(0, 0, 0, 0.4);
}

.text-blue {
  color: rgba(98, 97, 242, 1);
}

.text-cyan {
  color: rgba(24, 144, 255, 1);
}

.text-green {
  color: rgba(82, 196, 26, 1);
}

.text-red {
  color: rgba(245, 34, 45, 1);
}

.text-purple {
  color: rgba(153, 47, 235, 1);
}

.text-yellow {
  color: rgba(250, 173, 20, 1);
}

.manipulator-btn {
  color: rgba(47, 84, 235, 1);
  background: none;
  border: 0 none;
  cursor: pointer;
  outline-style: none;
  box-shadow: none;
  display: inline-block;
  padding: 0 12px;
  padding-left: 0;
}

.manipulator-btn:hover {
  text-decoration: underline;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

>>>.el-range-editor.el-input__inner {
  width: 240px;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.el-input__icon {
  line-height: 28px;
}

>>>.el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

>>>.el-input__icon {
  width: 14px;
}

>>>.el-range-editor.el-input__inner {
  padding: 3px 8px;
}

.showProce {
  color: #2f54eb;
  cursor: pointer;
}

.content-body-test {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: #2f54eb;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin: 16px 0 0 30px;
  cursor: pointer;
}

.containerMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1002;
}
</style>
