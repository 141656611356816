import axios, {
  get,
  post
} from "@/axios";
import store from "@/store";

const base = process.env.VUE_APP_API_ROOT;
const base_content = process.env.VUE_APP_API_ROOT_CONTENT;
//const base = process.env.VUE_APP_API_ROOT_FK;
const baseTaxfirm = base + '/tbdc';
const basePlat = `${base}/plat`;

export const businessDataFilter = (word) => {
  return axios
    .get(`${basePlat}/businessData/search?word=${word}&pageNum=1&pageSize=10`)
    .then(res => res.data);
};

// 陕西宽古获取验证码
export const newShanxiGetCode = (params, prefix) => {
  return axios
    .post(`${baseTaxfirm}/breeze/sendValidateCode`, params)
    .then(res => res.data);
};

// 陕西宽古授权
export const newShanxiBreeze = (params, prefix) => {
  return axios
    .post(`${baseTaxfirm}/breeze/authorizationAndCreateTaskByYzm`, params)
    .then(res => res.data);
};

//
export const allocationConsumer = (params) => {
  return axios
    .post(`${baseTaxfirm}/consumer/allocationConsumer`, params)
    .then(res => res.data);
};

export const getProjectManagerAndOther = () => {
  return get(`${baseTaxfirm}/user/getProjectManagerAndOther`)
    .then(res => res.data);
};

export const getAllocationConsumerPage = (params) => {
  return axios
    .post(`${baseTaxfirm}/consumer/getAllocationConsumerPage`, params)
    .then(res => res.data);
};

export const getUserAllocationNumberText = () => {
  return get(`${baseTaxfirm}/consumer/getUserAllocationNumberText`)
    .then(res => res.data);
};

export const getAllUserByFirmId = () => {
  return get(`${baseTaxfirm}/user/getAllUserByFirmId`)
    .then(res => res.data);
};
//

// 选择版本20221012新增
export const getTaxFirmEditionList = (id) => {
  return get(`${baseTaxfirm}/model/getTaxFirmEditionList`)
    .then(res => res.data);
};

export const getTaxFirmFindInvoice = (id) => {
  return get(`${baseTaxfirm}/model/getTaxFirmFindInvoice`)
    .then(res => res.data);
};
// 选择版本


// 供应商风险时间区间
export const getInvoiceSection = (id) => {
  return get(`${baseTaxfirm}/pdfData/getInvoiceSection?programId=${id}`)
    .then(res => res.data);
};

// 检测记录发票查询
export const getPageOfInvoice = params => {
  return post(`${baseTaxfirm}/invoiceTotal/getPageOfInvoice`, params)
    .then(res => res.data);
};

// 新版授权
// 浙江登录
export const zjBreezeLogin = params => {
  return post(`${baseTaxfirm}/breeze/login`, params)
    .then(res => res.data);
};
// 浙江授权
export const zjBreezeLoginCreateTask = params => {
  return post(`${baseTaxfirm}/breeze/authorizationAndCreateTask `, params)
    .then(res => res.data);
};

export const zjBreezeLoginV2 = params => {
  return post(`${baseTaxfirm}/breeze/login/V2`, params)
    .then(res => res.data);
};


// 重庆取数 获取取数方式
export const getLoginMethod = params => {
  return post(`${baseTaxfirm}/breeze/getLoginMethod`, params)
    .then(res => res.data);
};

// 第一次获取验证码
export const sendAuthSmsOne = params => {
  return post(`${baseTaxfirm}/breeze/sendAuthSms`, params)
    .then(res => res.data);
};

// 报表是否上传
export const findNeedExcel2 = (params) => {
  return get(
      `${baseTaxfirm}/common/findNeedExcel2?uuid=${params.uuid}&tag=${params.tag}&zq=${params.zq}&nsrsbm=${params.nsrsbm}&taxNo=${params.taxNo}`
    )
    .then(res => res.data);
};

export const findNeedExcel3 = (params) => {
  return get(
      `${baseTaxfirm}/common/findNeedExcel3?uuid=${params.uuid}&tag=${params.tag}&zq=${params.zq}&nsrsbm=${params.nsrsbm}&taxNo=${params.taxNo}`
    )
    .then(res => res.data);
};

// 获取企业信息
export const getCompanyMsgByTaxNo = (taxNo) => {
  return get(`${baseTaxfirm}/breeze/getCompanyMsgByTaxNo?taxNo=${taxNo}`)
    .then(res => res.data);
};

// 判断有没有对接过税局
export const getAreaMsg = (taxNo) => {
  return get(`${baseTaxfirm}/breeze/getAreaMsg?taxNo=${taxNo}`)
    .then(res => res.data);
};

// 发票查询的check
export const ptInvoiceBasicInfoCheck = (consumerId, programId) => {
  return get(
      `${baseTaxfirm}/ptInvoiceBasicInfo/add?consumerId=${consumerId}&programId=${programId}`)
    .then(res => res.data);
};

// 发票列表
export const ptInvoiceBasicInfoList = params => {
  return post(`${baseTaxfirm}/ptInvoiceBasicInfo/getPage`, params)
    .then(res => res.data);
};

// 删除发票
export const ptInvoiceBasicInfoDelete = id => {
  return post(`${baseTaxfirm}/ptInvoiceBasicInfo/del/${id}`)
    .then(res => res.data);
};

// 发票详情页面
// 查询进项公司
export const getEntryCompanyList = params => {
  return post(`${baseTaxfirm}/invoiceTotal/entry/getCompanyList`, params)
    .then(res => res.data);
};
// 查询销项公司
export const getSalesCompanyList = params => {
  return post(`${baseTaxfirm}/invoiceTotal/sales/getCompanyList`, params)
    .then(res => res.data);
};
// 查询进项商品
export const getEntryInvoiceGoodsList = params => {
  return post(`${baseTaxfirm}/invoiceTotal/entry/getInvoiceGoodsList`, params)
    .then(res => res.data);
};
// 查询销项商品
export const getSalesInvoiceGoodsList = params => {
  return post(`${baseTaxfirm}/invoiceTotal/sales/getInvoiceGoodsList`, params)
    .then(res => res.data);
};
// 查询进项品类
export const getEntryInvoiceCategoryList = params => {
  return post(`${baseTaxfirm}/invoiceTotal/entry/getInvoiceCategoryList`, params)
    .then(res => res.data);
};
// 查询销项品类
export const getSalesInvoiceCategoryList = params => {
  return post(`${baseTaxfirm}/invoiceTotal/sales/getInvoiceCategoryList`, params)
    .then(res => res.data);
};
// 查询进项发票
export const getEntryInvoiceList = params => {
  return post(`${baseTaxfirm}/invoiceTotal/entry/getInvoiceList`, params)
    .then(res => res.data);
};
// 查询销项发票
export const getSalesInvoiceList = params => {
  return post(`${baseTaxfirm}/invoiceTotal/sales/getInvoiceList`, params)
    .then(res => res.data);
};
// 查询进项每月进项
export const getEntryInvoiceMonthList = params => {
  return post(`${baseTaxfirm}/invoiceTotal/entry/getInvoiceMonthList`, params)
    .then(res => res.data);
};
// 查询销项每月进项
export const getSalesInvoiceMonthList = params => {
  return post(`${baseTaxfirm}/invoiceTotal/sales/getInvoiceMonthList`, params)
    .then(res => res.data);
};

// 20230216新增
// 进项分类查询
export const getEntryClass = taxNo => {
  return get(`${baseTaxfirm}/invoiceTotal/entry/getInvoiceTaxCodeList?taxNo=${taxNo}`)
    .then(res => res.data);
};
// 销项分类查询
export const getSalesClass = taxNo => {
  return get(`${baseTaxfirm}/invoiceTotal/sales/getInvoiceTaxCodeList?taxNo=${taxNo}`)
    .then(res => res.data);
};
// 20230216新增

// 查询是否有权限
export const ptInvoiceBasicInfoFindInvoice = () => {
  return get(`${baseTaxfirm}/ptInvoiceBasicInfo/findInvoice`)
    .then(res => res.data);
};
// 判断是否失效
export const ptInvoiceBasicInfoExpire = (id) => {
  return get(`${baseTaxfirm}/ptInvoiceBasicInfo/expire?id=${id}`)
    .then(res => res.data);
};
//
//
export const findTaxAutoStatusByUuid = (uuid, nsrsbm) => {
  return get(`${baseTaxfirm}/common/findTaxAutoStatusByUuid?uuid=${uuid}&nsrsbm=${nsrsbm}`)
    .then(res => res.data);
};

export const getRegion = () => {
  return get(`${baseTaxfirm}/dmDq/loadAreaTree`)
    .then(res => res.data);
};
export const areaList = getRegion;

// 审计小工具
export const auditRuleListAdd = taxNo => {
  return get(`${baseTaxfirm}/auditRuleList/add?taxNo=${taxNo}`)
    .then(res => res.data);
};

export const byTokenGetUser = () => {
  return get(`${baseTaxfirm}/auth/getUserInfo`)
    .then(res => res.data);
};

export const resultSuccess = params => {
  return post(`${baseTaxfirm}/auditRuleList/resultSuccess`, params)
    .then(res => res.data);
};

// 检测记录
export const auditList = params => {
  return post(`${baseTaxfirm}/auditRuleList/getPage`, params)
    .then(res => res.data);
};

// 查看分析结果
export const getAnalysisResults = params => {
  return post(`${baseTaxfirm}/auditRuleList/getAnalysisResults`, params)
    .then(res => res.data);
};

// 发票导出
export function getexportInvoiceURL({
  taxNo
}) {
  return `${baseTaxfirm}/downloadAuditRuleResult/exportInvoice?taxNo=${taxNo}`
}

// 下载当前分析报告
export function getexportAnalysisResults({
  programId
}) {
  return `${baseTaxfirm}/downloadAuditRuleResult/analysisResults?programId=${programId}`
}

// 上传表格后重新生成
export const uploadRetry = (nsrsbh, uuid, zq) => {
  return get(`${baseTaxfirm}/model/retry?nsrsbh=${nsrsbh}&uuid=${uuid}&zq=${zq}`)
    .then(res => res.data);
};

// http://test.ai-tax.cn/taxfirm/common/getListV2?zq=2020004&programId=3294852248e547f8ab15cdaace73bffb&tag=4
export const getTaxList = (zq, programId) => {
  return get(`${base}/tbdc/common/getAuditTable?zq=${zq}&programId=${programId}`)
    .then(res => res.data);
};


//首页接口
export const homemsg = params => {
  return axios.get(`${base_content}/index/findAllImg`, params);
};

//首页广告展示接口
export const adInHomePage = async () => {
  const url = `${base_content}/ad/show`;
  const res = await axios.get(url);
  return res.data;
};
//登录接口
export const requestLogin = params => {
  return axios.post(`${base}/bCompanyUser/login`, params).then(res => res.data);
};
//短信登录接口
export const newrequestLogin = params => {
  return axios.post(`${base}/bCompanyUser/login/code`, params).then(res => res.data);
};
//获取注册验证码
export const getRegisterCode = params => {
  return axios.get(`${base}/bCompanyUser/code/REGISTER/` + params).then(res => res.data);
};
//获取注册验证码new
export const newgetRegisterCode = params => {
  return axios.get(`${base}/bCompanyUser/code/LOGINNOTICE/` + params).then(res => res.data);
};
//注册接口
export const register = params => {
  return post(`${base}/bCompanyUser/register?code=` + params.code, params)
    .then(res => res.data);
};
//全国纳税人异常信息查询
export const getycmessage = params => {
  return get(`${base}/xxk/ycnsr/hz/${params.token}?nsrxx=${params.nsrxx}`)
    .then(res => res.data);
};
// 修改密码
export const changepw = params => {
  return post(`${base}/bCompanyUser/info/pwd?token=` + params.token, params)
    .then(res => res.data);
};
// 设置密码
export const changepwnew = params => {
  return axios.post(`${base}/bCompanyUser/changePwd`, params).then(res => res.data);
};

// 绑定新的手机
export const newphone = params => {
  return axios.get(`${base}/bCompanyUser/changemobile`, {
    params: params
  }).then(res => res.data);
};
//获取企业基本信息
export const getbasicinfo = params => {
  return axios.get(`${base}/bCompany/load/` + params).then(res => res.data);
};
//保存企业信息
export const saveCompanyInfo = params => {
  return axios.post(`${base}/bCompany/saveCompanyInfo`, params).then(res => res.data);
};
//获取企业的companyId(更改原来储存在缓存的companyId为接口获取，为了方便把getCompanyId接口放置在ceping.js的api接口JS中)
export const getCompanyId = async token => {
  const url = `${base}/bCompany/apply/query?token=${token}`;
  const res = await axios.get(url);
  return res.data;
};

//获取行业的接口
export const getHy = params => {
  return axios.get(`${base}/dmHy/load`, params).then(res => res.data);
};
//查找具体行业信息
export const findhydm = params => {
  return axios.get(`${base}/dmHy/loadByHyDm/?hyDm=${params.hyDm}`, params).then(res => res.data);
};
// 获取2级行业信息
export const dmHyMore = params => {
  return axios.get(`${base}/dmHy/load/${params.hy}?hyDm=${params.hycode}`).then(res => res.data);
};
//切换企业下拉列表
export const changecompanyselect = params => {
  return get(`${base}/bCompany/switchCompanyPage?id=${params.id}`, params)
    .then(res => res.data);
};
//切换企业
export const changecompany = async params => {
  await store.cache.delete("loadCompanyInfo"); //清空缓存中的企业信息

  return get(
      `${base}/bCompany/switchCompanyButton?companyId=${params.companyId}&userId=${params.userId}`,
      params
    )
    .then(res => res.data);
};
//切换企业制度
export const changekjzd = async params => {
  await store.cache.delete("loadCompanyInfo"); //清空缓存中的企业信息

  return axios.post(`${base}/bCompany/changeKjzd`, params).then(res => res.data);
};
//更新行业
export const changehyaix = async params => {
  await store.cache.delete("loadCompanyInfo"); //清空缓存中的企业信息
  return axios.post(`${base}/dmHy/save/${params.token}?hyDm=${params.hyDm}`).then(res => res
    .data);
};
//测试用户新增企业//本地调试
export const testaddcompany = params => {
  return post(`${base}/bCompany/applytest/v1?token=` + params.token, params)
    .then(res => res.data);
};
//企业认证申请
export const renzhengApply = async params => {
  return post(`${base}/bCompany/apply/v1?token=` + params.token, params)
    .then(res => res.data);
};
//企业信息审核
export const getCompanyrz = params => {
  return post(`${base}/aip/getCompanyInfo?keyWord=${params.keyWord}`, params)
    .then(res => res.data);
};

//个税接口
export const getTaxInfo = params => {
  return axios.post(`${base}/personTax/getTaxInfo`, params).then(res => res.data);
};
//五险一金
export const getShebaoInfo = params => {
  return axios.post(`${base}/personTax/getShebaoInfo`, params).then(res => res.data);
};

/****
 * 账户信息api
 *
 */

//查询企业信息
export const fetchCompanyInfo = async params => {
  const url = `${base}/bCompany/load/${params.companyID}`;
  const res = await axios.get(url);
  return res.data;
};
// 消息中心
export const messageList = params => {
  return axios.post(`${base_content}/category/loadByPage`, params).then(res => res.data);
};
//忘记密码接口
export const forgetpw = params => {
  return axios.post(`${base}/bCompanyUser/info/pwd/reset`, params).then(res => res.data);
};
//忘记密码验证码
export const getRestpwdCode = params => {
  return axios.get(`${base}/bCompanyUser/code/RESTPWD/` + params).then(res => res.data);
};
// 获取验证手机号验证码
export const getRestmobileCode = params => {
  return axios.get(`${base}/bCompanyUser/code/RESETMOBILE/` + params.mobile).then(res => res
    .data);
};
//确认验证码
export const resetmobile = params => {
  return get(`${base}/bCompanyUser/resetmobile?token=` + params.token + "&code=" + params.code)
    .then(res => res.data);
};
/****
 * 检测报表
 */
//显示需要上传的报表
export const showModules = params => {
  return post(`${baseTaxfirm}/model/showModules?bankId=` + params.bankId + "&qykjzd=" + params
      .qykjzd,
      params)
    .then(res => res.data);
};
//马上咨询  http://120.55.107.135:9014/user_m/addUser_m
export const nowzixun = params => {
  return axios.post(`${base_content}/user_m/addUser_m`, params).then(res => res.data);
};
// 用户提交专家解答短信通知
export const sendMsg = params => {
  return post(`${base}/bCompany/sendNotice/COMMITQUESTIONNOTICE`, params)
    .then(res => res.data);
};
// pdf下载
export const downPDFurl = params => {
  return post(`${base}/model/creatTestReportPdf/${params.token}`, params)
    .then(res => res.data);
};

//获取所有产品及规格
export const requestTableInfo = async params => {
  const url = `${base}/boGoods/loadByPage`;
  const res = await axios.post(url, {
    ...params
  });
  return res.data;
};

//此接口为用户选择征期后点击下一步时需要调用的校验接口，主要校验内容为：用户是否已认证，是否已购买商品等，只有通过校验后才可以进行测评
export const checkProductPermission = async (token, goodsId, type = null) => {
  const url = `${base}/model/checkAuth/${token}`;
  const res = await axios.post(url, {
    ...{
      goodsId,
      type
    }
  });
  return res.data;
};

//确认用户上传报表信息
export const confirmUploadedTable = async params => {
  if (params) {
    params.goodsId = params.goodsId.toString();
  }
  const url = `${base}/model/checkUserData`;
  const res = await axios.post(url, {
    ...params
  });
  return res.data;
};


//生成测评结果页
export const genTestReport = async params => {
  const url = `${base}/model/check`;
  const {
    bankId,
    goodsId,
    kjzd,
    token,
    nsrsbh,
    tag,
    id,
    uuid,
    zq,
    invoice_nsrsbh,
    invoice_email,
    invoice_company_name,
    invoice_phone,
    isAutoData
  } = params;
  const res = await axios.post(url, {
    bankId,
    goodsId,
    kjzd,
    token,
    nsrsbh,
    tag,
    id,
    uuid,
    zq,
    invoice_nsrsbh,
    invoice_company_name,
    invoice_email,
    invoice_phone,
    isAutoData
  });
  return res.data;
};

export const genTestReportAi = (params) => {
  return post(`${baseTaxfirm}/model/check`, params)
    .then(res => res.data);
};

//生成测评结果页
export const genTestReportAiFix = async params => {
  const url = `${baseTaxfirm}/model/check`;
  const {
    bankId,
    goodsId,
    kjzd,
    token,
    nsrsbh,
    tag,
    id,
    uuid,
    zq,
    invoiceNsrsbh,
    invoice_email,
    invoice_company_name,
    invoice_phone,
    isAutoData,
    isUpdateResultList,
    getDataWay
  } = params;
  const res = await axios.post(url, {
    bankId,
    goodsId,
    kjzd,
    token,
    nsrsbh,
    tag,
    id,
    uuid,
    zq,
    invoiceNsrsbh,
    invoice_company_name,
    invoice_email,
    invoice_phone,
    isAutoData,
    isUpdateResultList,
    getDataWay
  });
  return res.data;
};

//发票文本与数据接口接口

export const getInvoiceBillData = async params => {
  const url = `${base}/model/getInvoiceBillData`;
  const {
    nsrsbh,
    uuid,
    zq,
    bankId
  } = params;
  const res = await axios.get(url, {
    params: {
      nsrsbh,
      uuid,
      zq,
      bankId
    }
  });
  return res.data;
};

//获取uuid
export const getReportResult = async (uuid, token, bankId, nsrsbh, zq) => {
  const url = `${base}/model/getResult/${token}`;
  const res = await axios.post(url, {
    uuid,
    token,
    bankId,
    nsrsbh,
    zq
  });
  return res.data;
};

//获取检测记录
export const getHistoryReport = async nsrsbh => {
  const url = `${base}/model/getRuleList`;
  const res = await axios.post(url, {
    nsrsbh
  });
  return res.data;
};

//获取检测记录
export const getHistoryReports = async (
  nsrsbh,
  companyName,
  startDate,
  endDate,
  pageNo,
  pageSize,
  tag,
  status,
  goodsId
) => {
  const url = `${baseTaxfirm}/model/getRuleListTable`;
  const res = await axios.post(url, {
    nsrsbh,
    companyName,
    startDate,
    endDate,
    pageNo,
    pageSize,
    tag,
    status,
    goodsId
  });
  return res.data;
};

//api base
export const getAPIBase = () => {
  return base;
};

//提交图片给后端
export const sendPic2OSS = async params => {
  const {
    gxzbbdl,
    sszb,
    fxdj,
    gxzbcyl,
    cwzb,
    nsrsbh,
    uuid,
    gauge
  } = params;
  const url = `${base}/common/uploadBASEToOSS`;
  const res = await axios.post(url, {
    gxzbbdl,
    sszb,
    fxdj,
    gxzbcyl,
    cwzb,
    nsrsbh,
    uuid,
    gauge
  });
  return res.data;
};

// 异常纳税人搜索
export const nsrSearch = params => {
  return get(
      `${base}/xxk/ycnsr/hz/` +
      params.token +
      "?nsrxx=" +
      params.nsrxx +
      "&pageNo=" +
      params.pageNo +
      "&pageSize=" +
      params.pageSize
    )
    .then(res => res.data);
};

// 产品中心

// 创建产品订单
export const createOrder = params => {
  return axios.post(`${base}/bBuyOrder/create?token=${params.token}`, params).then(res => res
    .data);
};
// 掉起支付接口
export const openOrder = params => {
  return axios.post(`${base}/bBuyOrder/createCharge`, params).then(res => res.data);
};
// 查找所有优惠券
export const allFull = params => {
  return get(
      `${base}/bBuyOrder/selectAllCouponInfoBefore?token=${params.token}&sPageNo=${params.sPageNo}&sPageSize=${params.sPageSize}`
    )
    .then(res => res.data);
};
// 查询用户领取优惠券
export const searchFull = params => {
  return get(`${base}/bBuyOrder/selectUserReceiveCoupon?token=${params.token}`)
    .then(res => res.data);
};
// 用户领取优惠券
export const getFull = params => {
  return get(`${base}/bBuyOrder/userReceiveCoupon?token=${params.token}&couponid=${params.id}`)
    .then(res => res.data);
};
// 用户使用优惠券
export const useFull = params => {
  return get(
      `${base}/bBuyOrder/userapplyCoupon?token=${params.token}&couponid=${params.couponid}&specid=${params.specid}`
    )
    .then(res => res.data);
};
// 已购买
export const alreadyBuy = params => {
  return get(`${base}/bBuyOrder/alreadyPurchased?token=${params.token}`)
    .then(res => res.data);
};
// 获取所有商品
export const allGoods = params => {
  return axios.post(`${base}/boGoods/loadByPage`, params).then(res => res.data);
};
// 查询订单记录
export const orderRecord = params => {
  return post(`${base}/bBuyOrder/myOrderRecord?token=${params.token}`, params)
    .then(res => res.data);
};
// 满减券
export const fullSelect = params => {
  return get(
      `${base}/bBuyOrder/selectUserMaUseCoupon?token=${params.token}&specId=${params.specId}`)
    .then(res => res.data);
};

// 查询用户多个可使用优惠券
export const someFull = params => {
  return axios.post(`${base}/bBuyOrder/selectAllUserMaUseCoupon`, params).then(res => res.data);
};

//查找已购买版本
export const getPurchasedVersions = async params => {
  const {
    token
  } = params;
  const url = `${base}/bBuyOrder/selectPurchasedVersion?token=${token}`;
  const res = await axios.get(url);
  return res.data;
};

// 已购买风险库
export const alreadyBuyItem = params => {
  return get(`${base}/bBuyOrder/alreadyPurchasedRiskLibrary?token=${params.token}`)
    .then(res => res.data);
};
// 查看所有版本
export const getVersion = params => {
  return get(`${base}/boGoodsEdition/loadByPage?token=${params.token}`)
    .then(res => res.data.data);
};

// 订单记录模块
// 查询所有订单记录
export const allRecord = params => {
  return post(`${base}/bBuyOrder/myOrderRecord?token=${params.token}`, params)
    .then(res => res.data);
};
// 取消订单
export const cancelOrder = params => {
  return axios.get(`${base}/bBuyOrder/closeOrder?orderId=${params.orderId}`).then(res => res
    .data);
};
// 查看单个详情订单
export const viewOrder = params => {
  return axios.get(`${base}/bBuyOrder/loadOrder?orderId=${params.orderId}`).then(res => res.data);
};
// 创建支付订单
// export const createOrder = params => { return axios.post(`${base}/bBuyOrder/create?token=${params.token}`, params).then(res => res.data); }
// 查看已购买版本
export const buyItem = params => {
  return get(`${base}/bBuyOrder/selectPurchasedVersion?token=${params.token}`, params)
    .then(res => res.data);
};

// 查看支付状态
export const orderStatus = params => {
  return get(
      `${base}/bBuyOrder/findOrderPayState?token=${params.token}&ordernumber=${params.ordernumber}`
    )
    .then(res => res.data);
};

// 兑换记录
export const agentRecord = params => {
  return axios.post(`${base}/cdKeyPlat/getCdKeyList`, params).then(res => res.data);
};

// 检测测试是否可以解锁
// export const removeMaskDetection = async (uuid, token, bankId, nsrsbh, zq) => {
//   const url = `${base}/model/removeMaskDetection/${token}`;
//   const res = await axios.post(url, { uuid, token, bankId, nsrsbh, zq });
//   return res.data;
// };

// 解锁检测过的测试
export const unlockHistoryTest = async (uuid, token, bankId, nsrsbh, zq, tag) => {
  const url = `${base}/model/reductionTimes/${token}`;
  const res = await axios.post(url, {
    uuid,
    token,
    bankId,
    nsrsbh,
    zq,
    tag
  });
  return res.data;
};

// 兑换券
export const exchange = async (token, cdk) => {
  const url = `${base}/cd/exchange/act`;
  const res = await axios.post(url, {
    token,
    cdk
  });
  return res.data;
};

// 用户使用切换优惠券
export const useReduce = params => {
  return axios.post(`${base}/bBuyOrder/userapplyCoupon`, params).then(res => res.data);
};

// 代理商配置
export const getAgent = params => {
  return axios.get(`${base}/logo/getLogoTable/${params.token}`).then(res => res.data.data);
};

// 代理商配置
export const getAgentAi = params => {
  return axios.get(`${baseTaxfirm}/logo/getLogoTable/${params.token}`).then(res => res.data.data);
};

// 自动匹配风险库
export const mateGoods = params => {
  return get(`${base}/boGoods/getnewGoodsInfo?token=${params.token}`, params)
    .then(res => res.data);
};

// 删除报告
export const deleteRule = params => {
  return axios.post(`${base}/model/deleteRuleListOne`, params).then(res => res.data);
};
// 授权
export const useAuthorize = params => {
  return axios.post(`${base}/breeze/breezeAuthorization`, params).then(res => res.data);
};

// 授权
export const useAuthorizeAi = params => {
  return axios.post(`${baseTaxfirm}/breeze/breezeAuthorization`, params).then(res => res.data);
};

export const getProvinceBath = params => {
  return axios.post(`${baseTaxfirm}/breeze/getBatchNo`, params).then(res => res.data);
};

// 企业信息校验  /bCompany/checkCompanyInfo
export const checkCompany = params => {
  return axios.post(`${base}/bCompany/checkCompanyInfo`, params).then(res => res.data);
};

// 发票自动取数新街口
export const mateBill = params => {
  return get(
      `${base}/breeze/invoice/isAutoInvoiceBillData?invoice_nsrsbh=${params.invoice_nsrsbh}&isAutoData=${params.isAutoData}`,
      params
    )
    .then(res => res.data);
};

// 自动匹配行业新接口
export const checkNew = params => {
  return axios.post(`${base}/model/check3`, params).then(res => res.data);
};

// 补救接口
export const retryMethods = params => {
  return axios.post(`${base}/model/checkRetry`, params).then(res => res.data);
};

// 授权之前获取省份
export const getProvince = params => {
  return axios.post(`${base}/breeze/getProvince`, params).then(res => res.data);
};

// 获取授权人员
export const getIdentityInfo = params => {
  return axios.post(`${base}/breeze/getIdentityInfo`, params).then(res => res.data);
};

// 获取二维码
export const getQrCode = params => {
  return axios.post(`${base}/breeze/getQrCode`, params).then(res => res.data);
};

// 其他省份授权
export const breezeOuter = params => {
  return axios.post(`${base}/breeze/breezeAuthorizationByCode`, params).then(res => res.data);
};

// 获取验证码
export const breezeCode = params => {
  return axios.post(`${base}/breeze/sendVerificationCode`, params).then(res => res.data);
};

//代理商查找配置
export const findOneLogoSettings = agentUserId => {
  return get(`http://47.110.195.108:9009/logo/findOneLogo/${agentUserId}`)
    .then(res => res.data);
};
//代理商添加配置接口
export const addLogoSettings = params => {
  return axios.post(`http://47.110.195.108:9009/logo/addLogo`, params).then(res => res.data);
};

//更新添加配置接口
export const updateLogoSettings = params => {
  return axios.post(`http://47.110.195.108:9009/logo/updateLogo`, params).then(res => res.data);
};

//推广链接
export const getRegCode = params => {
  return post(`http://47.110.195.108:9009/chUser/getRegCode/` + params.chUserId)
    .then(res => res.data);
};

// AI事务所登录
// export const requestLoginAi = params => {
//   return axios.post(`${baseTaxfirm}/auth/login`, params).then(res => res.data);
// };

//AI事务所忘记密码接口
export const forgetpwAi = (params, token) => {
  return axios.post(`${baseTaxfirm}/auth/pwd/reset`, params).then(res => res.data);
};

// http://192.168.10.134:8008

//AI事务所首页
export const getIndex = token => {
  return post(
      `${baseTaxfirm}/model/data/dataCount2`, {}, {
        headers: {
          Authorization: token
        }
      }
    )
    .then(res => res.data);
};

// AI事务所折线图数据
export const getIndexData = (params, token) => {
  return post(`${baseTaxfirm}/index/data/incrementByMonthOrYear`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

// AI事务所数据统计
export const getStatistics = (params, token) => {
  return post(`${baseTaxfirm}/statistics/loadByPage`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

//AI事务所数据统计详情
export const getStatisticsDetail = (params, token) => {
  return post(`${baseTaxfirm}/statistics/getDetail`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

// AI事务所项目管理列表
export const getProjectList = (params, token) => {
  return post(`${baseTaxfirm}/project/loadByPage`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

// AI事务所新项目管理列表
export const getProjectListNew = (params, token) => {
  return post(`${baseTaxfirm}/model/loadTCheckProject`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

// AI事务所项目管理列表详情
export const getProjectListDetail = (params, token) => {
  return post(`${baseTaxfirm}/project/getProject`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res && res.data);
};

// AI事务所新增项目
export const addProjects = (params, token) => {
  return post(`${baseTaxfirm}/project/addProject`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res && res.data);
};

// AI事务所修改项目
export const editProjects = (params, token) => {
  return post(`${baseTaxfirm}/project/updateProject`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

// AI事务所客户管理列表/审查列表
export const getCustomList = params => {
  return axios.post(`${baseTaxfirm}/review/loadByPage`, params);
};

// AI事务所客户管理列表2
export const getCustomListNew = params => {
  return axios.post(`${baseTaxfirm}/consumer/loadByPage`, params);
};

// 风险检测检测记录查询列表
export const getPdfDataList = params => {
  return axios.post(`${baseTaxfirm}/model/getPage`, params);
};

// AI事务所客户管理详情
export const getCustomListDetail = (params, token) => {
  return post(`${baseTaxfirm}/consumer/getConsumer`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};
// 删除AI事务所客户
export const delCustomListDetail = (id) => {
  // return axios.post(`${baseTaxfirm}/consumer/del/${id}`, null, {
  //   headers: {
  //     Authorization: token
  //   }
  // })
  //   .then(res => res.data);
  return axios.post(`${baseTaxfirm}/consumer/del/${id}`)
};

// AI事务所客户管理新增客户
export const addCustom = params => {
  return post(`${baseTaxfirm}/consumer/addConsumer`, params, {
      headers: {
        Authorization: params.token
      }
    })
    .then(res => res && res.data);
};

// AI事务所客户管理新增客户
export const addCustomBoth = (params, token) => {
  return post(`${baseTaxfirm}/project/createConsumerAndProject`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

// AI事务所客户管理修改客户
export const editCustom = (params, token) => {
  return post(`${baseTaxfirm}/consumer/updateConsumer`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

// AI事务所查看余额表上传情况
export const getBalanceInfo = params => {
  return axios.post(`${baseTaxfirm}/balance/checkUpload`, params).then(res => res.data);
};

// AI事务所账号模块
// 新增用户
export const accountAddUser = params => {
  return axios.post(`${baseTaxfirm}/user/addUser`, params).then(res => res.data);
};
// 新增角色
export const accountAddRole = (params, token) => {
  return post(`${baseTaxfirm}/role/addRole`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

// AI事务所获取uuid
//获取uuid
export const getUUIDAi = async () => {
  const url = `${baseTaxfirm}/common/getUuid`;
  const res = await axios.get(url);
  return res.data;
};

// Ai事务所上传表格
export const uploadForTestUrlAiAll = (nsrsbh, zq, uuid, qykjzd, balance) => {
  return `${baseTaxfirm}/common/uploadExcelBatch?nsrsbh=${nsrsbh}&zq=${zq}&uuid=${uuid}&qykjzd=${qykjzd}&balance=${balance}`;
};


// Ai事务所账户管理账户列表
export const accountTotalList = (params, token) => {
  return post(`${baseTaxfirm}/user/loadByPage`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

// Ai事务所账户管理新增账户
export const accountTotalAdd = (params, token) => {
  return post(`${baseTaxfirm}/user/addUser/single`, params)
    .then(res => res.data);
};

// Ai事务所账户管理批量新增账户
export const accountTotalAdds = (params, token) => {
  return post(`${baseTaxfirm}/user/addUser/batch`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

// Ai事务所账户修改账户接口
export const accountTotalEdit = (params, token) => {
  return post(`${baseTaxfirm}/user/updateUser`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

// Ai事务所账户详情
export const getAccountListDetail = (params) => {
  return get(`${baseTaxfirm}/user/getUserInfo`, params)
    .then(res => res.data);
};

// Ai事务所账户切换状态
export const accountStatus = (params) => {
  return get(`${baseTaxfirm}/user/disableOrEnableUser`, params)
    .then(res => res && res.data);
};


// Ai事务所账户管理角色列表
export const roleTotalList = (params) => {
  return get(`${baseTaxfirm}/role/findList`, params)
    .then(res => res.data);
};

// Ai事务所账户管理新增角色
export const roleTotalListadd = (params, token) => {
  return post(`${baseTaxfirm}/role/addRole`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

// Ai事务所账户管理新增角色
export const roleTotalListDetail = (params) => {
  return get(`${baseTaxfirm}/role/getRoleLevelList`, params)
    .then(res => res.data);
};

// Ai事务所账户管理删除角色
export const roleTotalListDelete = (params) => {
  return get(`${baseTaxfirm}/role/deleteRole`, params)
    .then(res => res.data);
};

// Ai事务所账户管理修改角色
export const roleTotalListChange = (params, token) => {
  return post(`${baseTaxfirm}/role/updateRole`, params, {
      headers: {
        'Authorization': token
      },
    })
    .then(res => res.data);
};

// // AI事务所其他省份授权
// export const breezeOuterAi = params => {
//   return axios
//     .post(`${baseTaxfirm}/breeze/breezeAuthorizationByCode`, params)
//     .then(res => res.data);
// };

// 获取品牌配置
export const getLogo = () => {
  return get(`${baseTaxfirm}/brandConfig/getBrandConfig`)
    .then(res => res.data);
};

// // 修改品牌配置
// export const editLogo = token => {
//   return axios
//     .get(`${baseTaxfirm}/brandConfig/modifyBrand`, {
//       params: {},
//       headers: {
//         Authorization: token
//       }
//     })
//     .then(res => res.data);
// };

export const editLogo = (params, token) => {
  return post(`${baseTaxfirm}/brandConfig/modifyBrand`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

export const clearUploadedTableforTestAi = async (nsrsbh, zq, token, uuid) => {
  const url = `${baseTaxfirm}/model/clean/${token}`;
  const res = await axios.get(url, {
    params: {
      nsrsbh,
      zq,
      uuid
    },
    headers: {
      Authorization: token
    }
  });
  return res.data;
};

// 授权之前获取省份
export const getProvinceAi = params => {
  return post(`${baseTaxfirm}/breeze/getProvince`, params)
    .then(res => res.data);
};

// 获取授权人员
export const getIdentityInfoAi = params => {
  return post(`${baseTaxfirm}/breeze/getIdentityInfo`, params)
    .then(res => res.data);
};

// 获取二维码
export const getQrCodeAi = params => {
  return post(`${baseTaxfirm}/breeze/getQrCode`, params)
    .then(res => res.data);
};

// 其他省份授权
export const breezeOuterAi = params => {
  return post(`${baseTaxfirm}/breeze/breezeAuthorizationByCode`, params)
    .then(res => res.data);
};

// 获取验证码
export const breezeCodeAi = params => {
  return post(`${baseTaxfirm}/breeze/sendVerificationCode`, params)
    .then(res => res.data);
};

// 发票自动取数新街口Ai
export const mateBillAi = params => {
  return get(
      `${baseTaxfirm}/breeze/invoice/isAutoInvoiceBillData?invoice_nsrsbh=${params.invoice_nsrsbh}&isAutoData=${params.isAutoData}`,
      params
    )
    .then(res => res.data);
};

// 自动匹配行业新接口Ai
export const checkNewAi = params => {
  return post(`${baseTaxfirm}/model/check3`, params)
    .then(res => res.data);
};

// 事务所查看余额表明细
export const showBalanceDetail = params => {
  return post(`${baseTaxfirm}/balance/sheet`, params)
    .then(res => res.data);
};

//删除单个文件
export const deleteTableAi = async (nsrsbh, zq, templateId, token, uuid) => {
  console.log("delete")
  const url = `${baseTaxfirm}/model/cancel/upload/${token}`;

  const res = await axios.post(url, {
    nsrsbh,
    zq,
    template: templateId,
    uuid
  });
  return res.data;
};

// 事务所查看日志列表
export const showLog = (params, token) => {
  return post(`${baseTaxfirm}/log/loadByPage`, params, {
      headers: {
        'Authorization': token
      },
    })
    .then(res => res.data);
};

// ai获取行业编码
export const getTradeList = () => {
  return get(`${baseTaxfirm}/codeUtil/codeHy`)
    .then(res => res.data);
};


// http://localhost:8008/common/upload/image/{type}

// 事务所查看日志列表
export const uploadToOss = (params, token) => {
  return post(`${baseTaxfirm}/common/upload/image/brand`, params, {
      headers: {
        'Authorization': token
      },
    })
    .then(res => res.data);
};

// 首页代办报告
export const indexToDo = (token) => {
  return post(`${baseTaxfirm}/index/taskCount`, {}, {
      headers: {
        'Authorization': token
      },
    })
    .then(res => res.data);
};

// 自动匹配风险库
export const mateGoodsAi = params => {
  return get(`${baseTaxfirm}/boGoods/getNewGoodsInfo?nsrsbm=${params.nsrsbm}`, params)
    .then(res => res.data);
};

// 查询取数进度/breeze/progressBar
export const progressBar = (params, token) => {
  return post(`${baseTaxfirm}/breeze/progressBar`, params, {
      headers: {
        'Authorization': token
      },
    })
    .then(res => res.data);
};

// 手机验证码登录
export const phoneAndCode = (params) => {
  return post(`${baseTaxfirm}/auth/login/code`, params)
    .then(res => res.data);
};

// 手机号绑定
export const phoneBind = (params, token) => {
  return post(`${baseTaxfirm}/auth/login/bindingMobile`, params, {
      headers: {
        'Authorization': token
      },
    })
    .then(res => res.data);
};

// 获取短信验证码
export const getCodeAi = (params) => {
  return get(`${baseTaxfirm}/auth/code/${params.type}/${params.mobile}`, params)
    .then(res => res.data);
};

// /auth/pwd/updatePwd
// 修改密码
export const changePwdAi = (params, token) => {
  return post(`${baseTaxfirm}/auth/pwd/updatePwd`, params, {
      headers: {
        'Authorization': token
      },
    })
    .then(res => res.data);
};

// 修改手机号
export const changePhoneAi = (params, token) => {
  return post(`${baseTaxfirm}/auth/pwd/updateMoblie`, params, {
      headers: {
        'Authorization': token
      },
    })
    .then(res => res.data);
};

//AI事务所数据统计导出
export function getStatisticsDerive({
  consumer,
  projectName,
  reviewer,
  projectManager,
  status,
  startTime,
  endTime,
  firmId
}) {
  return `${baseTaxfirm}/BackgroundPdfData/export?consumer=${consumer}&projectName=${projectName}&reviewer=${reviewer}&projectManager=${projectManager}&status=${status}&startTime=${startTime}&endTime=${endTime}&firmId=${firmId}`
};

//AI事务所项目废除
export const closeProject = (params, token) => {
  return post(`${baseTaxfirm}/project/closeProject`, params, {
      headers: {
        Authorization: token
      }
    })
    .then(res => res.data);
};

// 获取事务所名称、logo
export const getIndexLogo = () => {
  return get(`${baseTaxfirm}/index/loadFirmMsg`)
    .then(res => res.data);
};

export const importLoseOrder = (formData, token) => {
  return post(`${baseTaxfirm}/consumer/addConsumer/batch`, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data"
      }
    })
    .then(res => res.data);
};

// model/checkRetry补全并且重试
export const checkRetryFlow = (params) => {
  return post(`${baseTaxfirm}/model/checkRetry`, params)
    .then(res => res.data);
};

// 重试接口
export const retryFlowAdd = (params) => {
  return post(`${baseTaxfirm}/checkProcessLog/retry`, params)
    .then(res => res.data);
};

export const addReportValue = (params) => {
  return get(
      `${baseTaxfirm}/model/addReportValue?nsrsbh=${params.nsrsbh}&zq=${params.zq}&uuid=${params.uuid}`
    )
    .then(res => res.data);
};

/**
 * 得到报告进度条
 * @param {programId} programId
 * @returns {Promise<*>}
 */
export const getProgressBar = async (programId) => {
  return await get(
      `${baseTaxfirm}/breeze/progressBar`, {
        programId
      })
    .then(res => res.data);
};

/**
 * 获取公司风险详细信息
 * @returns {Promise<*>}
 */


export const getCompanyRiskDetails = async ({
  companyArea,
  companyName,
  pageNum,
  pageSize,
  programId,
  riskList,
  companyType,
  type,
  startMoney,
  endMoney,
  startDate,
  endDate
}) => {
  const url = `${baseTaxfirm}/pdfData/getCompanyRiskDetails`;
  const res = await post(url, {
    companyArea,
    companyName,
    pageNum,
    pageSize,
    programId,
    riskList,
    companyType,
    type,
    startMoney,
    endMoney,
    startDate,
    endDate
  });
  return res.data;
};

// 获取可视化报告地区
export const getVisualArea = async (programId, companyType) => {
  const url = `${baseTaxfirm}/pdfData/getVisualArea`;
  const res = await get(url, {
    programId,
    companyType
  });
  return res.data;
};

// 获取可视化报告风险下拉选项
export const getVisualRiskOptions = async (programId, companyType) => {
  const url = `${baseTaxfirm}/pdfData/getVisualRiskModel`;
  const res = await get(url, {
    programId,
    companyType
  });
  return res.data;
};

// 获取可视化报告风险
export const getVisualRisk = async () => {
  const url = `${baseTaxfirm}/pdfData/getVisualRisk`;
  const res = await get(url);
  return res.data;
};

//获取uuid
export const getVisualReport = async (programId) => {
  const url = `${baseTaxfirm}/pdfData/getVisualReport`;
  const res = await get(url, {
    programId
  });
  return res.data;
};

// 下载发票
export function getInvoiceUrl({
  taxNo
}) {
  return `${baseTaxfirm}/downloadAuditRuleResult/exportSimpleInvoice?taxNo=${taxNo}`
}

/**
 * 获取用户的脱敏设置
 * @param {String} token
 * @returns {Promise<*>}
 */
export const getDesensitizationInfo = async ({
  token
}) => {
  const url = `${baseTaxfirm}/user/getInfo`;
  const res = await get(url, {
    token
  });
  return res.data;
};

/**
 * 获取用户的脱敏设置
 * @param {String} token
 * @param {Number} desensitizationStatus
 * @returns {Promise<*>}
 */
export const updateDesensitizationInfo = async ({
  token,
  desensitizationStatus
}) => {
  const url = `${baseTaxfirm}/user/updateInfo`;
  const res = await get(url, {
    token,
    desensitizationStatus
  });
  return res.data;
};

/**
 * 检测脱敏报告是否可用
 * @param uuid
 * @returns {Promise<*>}
 */
export const getDesensitizationStatus = async ({
  uuid
}) => {
  const url = `${baseTaxfirm}/pdfData/getDesensitizationStatus`;
  const res = await get(url, {
    programId: uuid
  });
  return res.data;
};

// 重新生成报告
export const longPeriodFiscalRetry = (programId) => {
  return axios
    .get(`${baseTaxfirm}/longPeriodFiscal/retry?programId=${programId}`)
    .then(res => res.data);
};

// 报表查询表格对比
export const reportCompare = (uuid, type, zq) => {
  return axios
    .get(
      `${base}/admin/reportCompare/getList?programId=${uuid}&tableName=${type}&zq=${zq}`
    )
    .then(res => res.data);
};

export const uploadForTestUrlTag = (nsrsbh, zq, uuid, qykjzd, balance, tag) => {
  return `${base}/admin/excelInfo/uploadExcelEx2?nsrsbh=${uuid}&zq=${zq}`;
};

export const reportCompareCw = (uuid, zq) => {
  return axios
    .get(
      `${base}/admin/reportCompare/getTaxReportAnalysis?programId=${uuid}&zq=${zq}`
    )
    .then(res => res.data);
};

// 删除发票
export const deleteBill = (programId) => {
  return axios
    .get(`${baseTaxfirm}/invoiceTotal/deleteInvoiceSection?programId=${programId}`)
    .then(res => res.data);
};

// 报告样式
export const getPageStyle = ({
  programId
}) => {
  return axios
    .get(`${baseTaxfirm}/model/getPageStyle?programId=${programId}`)
    .then(res => res.data);
};

// 查看基础信息表详情
export const getInfoByCompanyNsrsbm = programId => {
  return get(`${baseTaxfirm}/projectInfo/getProjectInfoFDC?programId=${programId}`)
    .then(res => res.data);
};

// 新增基础信息表
export const addInfoBy = params => {
  return post(`${baseTaxfirm}/projectInfo/edit`, params)
    .then(res => res.data);
};

// 修改基础信息表
export const editInfoBy = params => {
  return post(`${baseTaxfirm}/projectInfo/edit`, params)
    .then(res => res.data);
};

// 北京授权
export const zjBreezeLoginCreateTaskBj = (params) => {
  return axios
    .post(`${baseTaxfirm}/breeze/authorizationAndCreateTask/beijing `, params)
    .then(res => res.data);
};


//导出pdf表格
export const exportTableApi = (programId, zq) => {
  return axios
    .get(
      `${base}/admin/reportCompare/exportReportExcel?programId=${programId}&zq=${zq}`
    )
    .then(res => res.data);
};

// 202212需求  数据错误弹窗提示
export const getInvoiceAccess = programId => {
  return get(`${baseTaxfirm}/invoiceTotal/getInvoiceAccess?uuid=${programId}`)
    .then(res => res.data);
};

export const getDueDiligenceModel002 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel002`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}





export const getDueDiligenceModel001 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel001`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}



export const getDueDiligenceModel003 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel003`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel004 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel004`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel005 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel005`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}

export const getDueDiligenceModel006 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel006`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}

export const getDueDiligenceModel007 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel007`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}

export const getDueDiligenceModel008 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel008`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel009 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel009`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel010 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel010`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel011 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel011`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel012 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel012`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}
export const getDueDiligenceModel013 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel013`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}

/**
 * 创建可视化报告分享功能
 * @param uuid
 * @param nsrsbm
 * @param expireHours
 * @returns {Promise<*>}
 */
export const createShareCode = async (uuid, nsrsbm, expireHours) => {
  const url = `${basePlat}/share/createShareCode`;
  const res = await get(url, {
    uuid,
    nsrsbm,
    hours: expireHours
  });
  return res.data;
};

export const getDueDiligenceModel014 = async ({
  uuid,
  desensitizationStatus
}) => {
  const url = `${basePlat}/dueDiligence/getResultData?fixedKey=dueDiligenceModel014`;
  const res = await get(url, {
    programId: uuid,
    desensitizationStatus
  });
  return res.data;
}


//判断是否购买查询发票
export const getWhetherPurchase = () => {
  return get(`${baseTaxfirm}/ptInvoiceBasicInfo/findInvoice`)
    .then(res => res.data);
};

//工商全景画像信息模糊搜索
export const getQuerySectionList = (
  word,
  pageNum = 1,
  pageSize = 10,
) => {
  return get(`${baseTaxfirm}/businessData/search`, {
      word,
      pageNum,
      pageSize,
    })
    .then(res => res.data);
};

//获取uuid
export const getUUID = () => {
  return get(`${baseTaxfirm}/common/getUuid`)
    .then(res => res.data);
};

//创建工商全景画像报告
export const getCreateDataFun = (Word) => {
  return get(`${baseTaxfirm}/businessData/createData/`, {
      keyWord: Word
    })
    .then(res => res.data);
};

//基本信息
export const getBasicInfoFun = (data) => {
  return get(`${baseTaxfirm}/businessData/basicInfo/`, {
      programId: data
    })
    .then(res => res.data);
};

//分支机构
export const getBranchFun = (programId) => {
  return get(`${baseTaxfirm}/businessData/branch`, {
      programId: programId
    })
    .then(res => res
      .data);
};
//变更记录
export const getChangeRecordFun = (programId) => {
  return get(`${baseTaxfirm}/businessData/changeRecord/`, {
      programId: programId
    })
    .then(res => res
      .data);
};
//股东及管理人员信息
export const getExecutiveInfoFun = programId => {
  return get(`${baseTaxfirm}/businessData/executiveInfo/`, {
      programId: programId
    })
    .then(res => res
      .data);
};
//上市公告
export const getListingAnnouncementFun = programId => {
  return get(`${baseTaxfirm}/businessData/listingAnnouncement/`, {
      programId: programId
    })
    .then(res => res
      .data);
};
//对外投资
export const getOutboundInvestmentFun = programId => {
  return get(`${baseTaxfirm}/businessData/outboundInvestment/`, {
      programId: programId
    })
    .then(res => res
      .data);
};
//工商全景画像信息报告分页
export const getPageFun = (companyName, startDate, endDate, pageNum, pageSize) => {
  return get(`${baseTaxfirm}/businessData/getPage/`, {
      companyName,
      startDate,
      endDate,
      pageNum,
      pageSize
    })
    .then(res => res
      .data);
};
//删除工商全景画像报告信息
export const postDelFun = id => {
  return post(`${baseTaxfirm}/businessData/del/${id}`)
    .then(res => res
      .data);
};
//工商全景报告基本信息查询(报告生成时间)
export const getInfo = (programId) => {
  return get(`${baseTaxfirm}/businessData/getInfo/`, {
      programId
    })
    .then(res => res
      .data);
};

//
export const getInvoiceDetailPage = params => {
  return post(`${baseTaxfirm}/invoice/detail/getInvoiceDetailPage`, params)
    .then(res => res.data);
};

export const getInvoiceDetailCompanyList = params => {
  return post(`${baseTaxfirm}/invoice/detail/getInvoiceDetailCompanyList`, params)
    .then(res => res.data);
};

export const getInvoiceYearBox = (programId, modelId) => {
  return get(
      `${baseTaxfirm}/invoice/detail/getInvoiceYearBox?programId=${programId}&modelId=${modelId}`)
    .then(res => res.data);
};

export const getInvoiceType = (programId, modelId) => {
  return get(
      `${baseTaxfirm}/invoice/detail/getInvoiceType?programId=${programId}&modelId=${modelId}`)
    .then(res => res.data);
};
//获取最新通知

export const getLatestNotice = () => {
  return get(
      `${baseTaxfirm}/bizNotice/getLatestNotice`)
    .then(res => res.data);
};

//不再提醒通知
export const noPrompt = (noticeId) => {
  return post(
      `${baseTaxfirm}/bizNotice/noPrompt?noticeId=${noticeId}`)
    .then(res => res.data);
};

//获取通知分页
export const getNoticePage = (params) => {
  return post(
      `${baseTaxfirm}/bizNotice/getPage`, params)
    .then(res => res.data);
};
//通知已读状态修改
export const markAsRead = (params) => {
  return post(
      `${baseTaxfirm}/bizNotice/markAsRead`, params)
    .then(res => res.data);
};
//获得未读通知条数
export const getUnreadNum = () => {
  return get(
      `${baseTaxfirm}/bizNotice/getUnreadNum`)
    .then(res => res.data);
};

// 
// 后台过来的token交换
export const loginChangeToken = (token) => {
  return get(`${baseTaxfirm}/auth/login/${token}`)
    .then(res => res.data);
};

// 
// 查看税号取数状态
export const getAuthStatus = (taxNo, programId) => {
  return get(`${base}/daqc/thirdAuth/getAuthStatus?taxNo=${taxNo}&programId=${programId}`)
    .then(res => res.data);
};

// 删除报告
export const getDelRuleIdApi = (ruleId) => {
  return post(`${baseTaxfirm}/model/del/${ruleId}`)
    .then(res => res.data);
};

// 查看税号取数状态33
export const getAuthStatus2 = (programId) => {
  return get(`${base}/daqc/thirdAuth/getAuthorizeStatus?programId=${programId}`)
    .then(res => res.data);
};


// 税局查看日志列表
export const postloadByPageApi = (params) => {
  return post(`${base}/tbdc/log/loadByPage`, params)
    .then(res => res.data);
};

// 税局登录
export const requestLoginApi = params => {
  return axios.post(`${base}/tbdc/user/login`, params).then(res => res);
};
//税局秘钥
export const getPublicKeyApi = () => {
  return axios.get(`${base}/tbdc/user/getPublicKey`).then(res => res);
};


// 税局首页折线图数据
export const getAnalysisDistributeApi = (params) => {
  return post(`${base}/tbdc/index/getAnalysisDistribute`, params)
    .then(res => res.data);
};

// 税局提交检测记录
export const postCheckApi = (params) => {
  return post(`${base}/tbdc/model/check`, params)
    .then(res => res.data);
};


// 税局检测记录
export const getTaxFirmEditionListApi = () => {
  return get(`${base}/tbdc/model/getTaxFirmEditionList`)
    .then(res => res.data);
};

//创建帮助信息详情
export const postAddApi = (params) => {
  return post(`${base}/tbdc/helpNewInfo/add`, params)
    .then(res => res.data);
};
//删除帮助信息详情信息
export const postDelApi = (id) => {
  return post(`${base}/tbdc/helpNewInfo/del/${id}`)
    .then(res => res.data);
};
//修改帮助信息详情
// export const postEditApi = (params) => {
//   return post(`${base}/tbdc/helpNewInfo/edit`, params)
//     .then(res => res.data);
// };

//获取帮助信息详情信息详情
export const getInfoApi = (id) => {
  return get(`${base}/tbdc/helpNewInfo/getInfo?id=${id}`, )
    .then(res => res.data);
};

//获取帮助信息详情信息列表
export const getListApi = (problem) => {
  return get(`${base}/tbdc/helpNewInfo/getList?problem=${problem}`, )
    .then(res => res.data);
};
//获取帮助信息详情分页列表
export const getPageApi = (pageNum,pageSize) => {
  return get(`${base}/tbdc/helpNewInfo/getPage?pageNum=${pageNum}&pageSize=${pageSize}`)
    .then(res => res.data);
};

//发税局版布功能
export const postPublishApi = (programId) => { 
  return post(`${base}/tbdc/model/publish/${programId}`)
  .then(res => res.data);
};

// 税局版重新生成报告
export const getRetryByProgramIdApi = (programId) => {
  return axios
    .get(`${baseTaxfirm}/pdfData/retryByProgramId?programId=${programId}`)
    .then(res => res.data);
};