<template>
  <div class="bill-container">
    <div class="title-content" v-if="billTypes != 2">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>小工具</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/tools/billList' }"
            >发票查询</el-breadcrumb-item
          >
          <el-breadcrumb-item>发票详情</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">发票查询</template>
      </main-header>
    </div>
    <div class="backTest" @click="backTest" v-if="billTypes == 2">返回上一页</div>
    <div class="bill-detail-title" v-if="billTypes != 2">
      <div>{{ companyNameTitle }}</div>
      <div class="time">{{ createTimeTitle }}</div>
    </div>
    <div class="bill-detail-content">
      <div class="bill-detail-menu">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">进项发票</el-menu-item>
          <el-menu-item index="2">销项发票</el-menu-item>
        </el-menu>
      </div>
      <div class="bill-time-filter">
        <div>查询日期：</div>
        <div>
          <el-date-picker
            v-model="billTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="timeChange"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="search">搜索</div>
        <el-divider direction="vertical"></el-divider>
        <div
          class="search-month"
          :class="{ 'month-active': isActive == 0 }"
          @click="nowMonth"
        >
          本月
        </div>
        <div
          class="search-month"
          :class="{ 'month-active': isActive == 1 }"
          @click="lastMonth"
        >
          上月
        </div>
        <div
          class="search-month"
          :class="{ 'month-active': isActive == 2 }"
          @click="nowYear"
        >
          本年
        </div>
        <div
          class="search-month"
          :class="{ 'month-active': isActive == 3 }"
          @click="lastYear"
        >
          上年
        </div>
        <div
          class="search-month"
          :class="{ 'month-active': isActive == 4 }"
          @click="allResult"
        >
          全部
        </div>
      </div>
      <div class="bill-time-table">
        <div class="left">
          <div class="title">
            <div class="title-company">公司名称：</div>
            <div class="title-input">
              <el-input
                clearable
                v-model="nameFilter"
                placeholder="请输入内容"
                @change="namefilterMeth"
                @input="namefilterclear"
              ></el-input>
            </div>
          </div>
          <div class="content-c" v-if="billData && billData.length > 0">
            <div class="content" v-for="item in billData">
              <div
                class="content-item"
                :class="{ contentAct: contentIndex == item.taxNo }"
                @click="item2Act(item)"
              >
                <div class="company price">{{ item.companyName }}</div>
                <div class="info">
                  <!-- <div class="price" v-if="activeIndex == 1">
                    含税总进项金额：{{ item.totalAmount }}元
                  </div>
                  <div class="price" v-else>含税总销项金额：{{ item.totalAmount }}元</div> -->
                  <div class="price">价税合计：{{ item.totalAmount }}元</div>
                  <div class="price">税额：{{ item.totalTax }}元</div>
                </div>
                <i
                  @click="contentRight = !contentRight"
                  v-if="!contentRight"
                  class="el-icon-caret-right content-right"
                ></i>
                <i
                  @click="contentRight = !contentRight"
                  v-if="contentRight"
                  class="el-icon-caret-bottom content-right"
                ></i>
              </div>
              <template>
                <div
                  v-show="contentRight"
                  v-for="item2 in item.child"
                  class="content-item2"
                  @click="item2Act(item2)"
                  :class="{ contentAct: contentIndex == item2.taxNo }"
                >
                  <div class="company price">{{ item2.companyName }}</div>
                  <div class="info">
                    <div class="price">价税合计：{{ item2.totalAmount }}元</div>
                    <div class="price">税额：{{ item2.totalTax }}元</div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div v-else class="content-flex">
            <div>暂无数据</div>
          </div>
        </div>
        <div class="right">
          <div class="right-item">
            <div class="item" @click="changeItem">
              <div class="taxname" :class="{ 'bill-name': item == 0 }" data-index="0">
                商品明细
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="taxname" :class="{ 'bill-name': item == 1 }" data-index="1">
                品类统计
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="taxname" :class="{ 'bill-name': item == 2 }" data-index="2">
                发票明细
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="taxname" :class="{ 'bill-name': item == 3 }" data-index="3">
                {{ activeIndex == 1 ? "每月进项" : "每月销项" }}
              </div>
            </div>
            <div class="item select" v-if="item == 1">
              <div
                class="taxname taxname-select"
                :class="{ 'bill-names': itemSelect == 0 }"
                @click="itemSelect = 0"
              >
                表格
              </div>
              <el-divider direction="vertical"></el-divider>
              <div
                class="taxname taxname-select"
                :class="{ 'bill-names': itemSelect == 1 }"
                @click="itemSelect = 1"
              >
                图形
              </div>
            </div>

            <div class="item select bill-select" v-if="item == 2">
              <div>发票类型：</div>
              <div>
                <el-select
                  v-model="billtype"
                  placeholder="请选择"
                  clearable
                  @change="billchangeType"
                >
                  <el-option
                    v-for="item in billtypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="billStatus">发票状态：</div>
              <div>
                <el-select
                  v-model="billStatus"
                  placeholder="请选择"
                  clearable
                  @change="billchangeType"
                >
                  <el-option
                    v-for="item in billStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="item-table" v-show="item == 0">
            <el-table
              :data="billTableDataItem0"
              :max-height="totalNumber0 > 200 ? tableHeight - 40 : tableHeight"
              style="width: 100%"
              v-loading="getTable"
            >
              <el-table-column width="20" fixed> </el-table-column>
              <el-table-column
                width="120"
                prop="billingDate"
                label="开票日期"
                sortable
                fixed
              >
              </el-table-column>
              <el-table-column width="140" prop="invoiceCode" label="发票代码">
              </el-table-column>
              <el-table-column width="140" prop="invoiceNumber" label="发票号码">
              </el-table-column>
              <el-table-column width="270" prop="productName" label="货品和劳务名称">
              </el-table-column>
              <!-- <el-table-column width="150" prop="productModel" label="规格型号">
              </el-table-column>
              <el-table-column width="80" prop="productUnit" label="单位">
              </el-table-column>
              <el-table-column
                width="120"
                prop="productNumber"
                label="数量"
                sortable
                :sort-method="sortproductNumber"
              >
              </el-table-column>
              <el-table-column
                width="160"
                prop="unitPriceExcludingTax"
                label="不含税单价（元）"
                sortable
                :sort-method="sortunitPriceExcludingTax"
              >
              </el-table-column> -->
              <el-table-column
                width="160"
                prop="amountExcludingTax"
                label="金额（元）"
                sortable
                :sort-method="sortamountExcludingTax"
              >
              </el-table-column>
              <el-table-column
                width="90"
                prop="taxRateDetailed"
                label="税率"
                sortable
                :sort-method="sorttaxRateDetailed"
              >
              </el-table-column>
              <el-table-column
                width="120"
                prop="taxDetailed"
                label="税额（元）"
                sortable
                :sort-method="sorttaxDetailed"
              >
              </el-table-column>
              <el-table-column
                width="150"
                prop="taxAmount"
                label="价税合计（元）"
                sortable
                :sort-method="sorttaxtaxAmount"
              >
              </el-table-column>
            </el-table>
            <div class="item-pagination" v-show="totalNumber0 > 200">
              <el-pagination
                @size-change="handleSizeChange0"
                @current-change="handleCurrentChange0"
                :current-page="pageNum0"
                :page-size="pageSize0"
                layout="prev, pager, next, total, jumper, sizes"
                :page-sizes="[100, 200, 300, 400, 500]"
                :total="totalNumber0"
              >
              </el-pagination>
            </div>
          </div>
          <div class="item-table" v-show="item == 1">
            <div v-show="itemSelect == 0">
              <el-table
                :data="billTableDataItem1"
                style="width: 100%"
                :max-height="tableHeight"
                v-loading="getTable"
              >
                <el-table-column width="20" fixed> </el-table-column>
                <el-table-column
                  prop="productName"
                  label="货品和劳务名称"
                  width="280"
                  fixed
                >
                </el-table-column>
                <!-- <el-table-column prop="productUnit" label="单位" width="110">
                </el-table-column>
                <el-table-column
                  prop="productNumber"
                  label="数量"
                  width="150"
                  sortable
                  :sort-method="sortproductNumber1"
                >
                </el-table-column> -->
                <el-table-column
                  prop="amountExcludingTax"
                  label="合计金额(万元)"
                  width="150"
                  sortable
                  :sort-method="sortamountExcludingTax1"
                >
                </el-table-column>
                <el-table-column
                  prop="taxDetailed"
                  label="合计税额(万元)"
                  width="150"
                  sortable
                  :sort-method="sorttaxDetailed1"
                >
                </el-table-column>
                <el-table-column
                  prop="taxAmount"
                  label="价税合计(万元)"
                  width="150"
                  sortable
                  :sort-method="sorttaxAmount1"
                >
                </el-table-column>
                <!-- <el-table-column
                  prop="averagePrice"
                  label="均价（元）"
                  width="180"
                  sortable
                  :sort-method="sortaveragePrice1"
                >
                </el-table-column> -->
                <el-table-column
                  prop="percent"
                  label="占总金额比例"
                  width="160"
                  sortable
                  :sort-method="sortpercent1"
                >
                  <template slot-scope="scope">
                    <span>{{ (scope.row.percent * 100).toFixed(2) }}%</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="circle-flex" v-show="itemSelect == 1">
              <div class="circle" id="circle"></div>
              <div class="circle-item">
                <div v-for="item in billCircleData" class="bill-Data">
                  <div>
                    {{ item.name }}
                  </div>
                  <div>{{ (item.value * 100).toFixed(2) }}%</div>
                </div>
              </div>
            </div>
          </div>
          <div class="item-table" v-show="item == 2">
            <el-table
              :data="billTableDataItem2"
              :max-height="totalNumber1 > 200 ? tableHeight - 40 : tableHeight"
              style="width: 100%"
              v-loading="getTable"
            >
              <el-table-column width="20" fixed> </el-table-column>
              <el-table-column
                prop="billingDate"
                label="开票日期"
                width="110"
                sortable
                fixed
              >
              </el-table-column>
              <el-table-column prop="invoiceCode" label="发票代码" width="130">
              </el-table-column>
              <el-table-column prop="invoiceNumber" label="发票号码" width="130">
              </el-table-column>
              <el-table-column prop="invoiceType" label="发票种类" width="230">
                <template slot-scope="scope">
                  <span>{{ billTypeArray[scope.row.invoiceType] }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="invoiceStatus" label="发票状态" width="130">
                <template slot-scope="scope">
                  <span v-if="activeIndex == 1">{{
                    billStatusListArray[scope.row.invoiceStatus]
                  }}</span>
                  <span v-if="activeIndex == 2">{{
                    billStatusListArraySale[scope.row.invoiceStatus]
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="totalAmount"
                label="金额（元）"
                width="150"
                sortable
                :sort-method="sorttotalAmount"
              >
              </el-table-column>
              <el-table-column
                prop="totalTax"
                label="税额（元）"
                width="150"
                sortable
                :sort-method="sorttotalTax"
              >
              </el-table-column>
              <el-table-column
                prop="amountTax"
                label="价税合计（元）"
                width="150"
                sortable
                :sort-method="sortamountTax"
              >
              </el-table-column>
              <el-table-column prop="consolidateRate" label="税率" width="130">
              </el-table-column>
              <template v-if="relationTaxNo != contentIndex">
                <el-table-column
                  prop="companyName"
                  label="销售方名称"
                  width="360"
                  v-if="activeIndex == 1"
                >
                </el-table-column>
                <el-table-column prop="companyName" label="采购方名称" width="230" v-else>
                </el-table-column>
              </template>
            </el-table>
            <div class="item-pagination" v-show="totalNumber1 > 200">
              <el-pagination
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="pageNum1"
                :page-size="pageSize1"
                layout="prev, pager, next, total, jumper, sizes"
                :page-sizes="[100, 200, 300, 400, 500]"
                :total="totalNumber1"
              >
              </el-pagination>
            </div>
          </div>
          <div class="item-table" v-show="item == 3">
            <el-table
              :data="billTableDataItem3"
              style="width: 100%"
              :max-height="tableHeight"
              v-loading="getTable"
            >
              <el-table-column width="20"> </el-table-column>
              <el-table-column prop="yearMonth" label="年度月份" sortable>
              </el-table-column>
              <el-table-column
                prop="totalAmount"
                label="合计金额（元）"
                sortable
                :sort-method="sorttotalAmount3"
              >
              </el-table-column>
              <el-table-column
                prop="totalTax"
                label="合计税额（元）"
                sortable
                :sort-method="sorttotalTax3"
              >
              </el-table-column>
              <el-table-column
                prop="amountTax"
                label="价税合计（元）"
                sortable
                :sort-method="sortamountTax3"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCustomListNew,
  getEntryCompanyList,
  getSalesCompanyList,
  getEntryInvoiceGoodsList,
  getSalesInvoiceGoodsList,
  getEntryInvoiceCategoryList,
  getSalesInvoiceCategoryList,
  getEntryInvoiceList,
  getSalesInvoiceList,
  getEntryInvoiceMonthList,
  getSalesInvoiceMonthList,
} from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import circleBill from "./circleBill";

import echarts from "echarts";

const moment = require("moment");

import autoTableHeight from "./utils/help.js";

export default {
  components: {
    MainHeader,
    BreadCrumb,
    circleBill,
  },

  computed: {
    tableHeight() {
      return autoTableHeight(); // 2. 调用函数，返回高度
    },
  },

  data() {
    return {
      MaxHeightTable: "100vh",
      getTable: false,
      billTypeArray: {
        "01": "增值税专用发票  专用发票",
        "02": "货物运输业增值税专用发票  专用发票",
        "03": "机动车销售统一发票  其他发票",
        "04": "增值税普通发票  普通发票",
        "08": "电子专用发票  专用发票",
        10: "电子普通发票  普通发票",
        11: "卷式普通发票  普通发票",
        14: "电子普通[通行费]发票  普通发票",
        15: "二手车统一发票  其他发票",
        "004": "增值税专用发票  专用发票",
        "007": "增值税普通发票  普通发票",
        "009": "货物运输业增值税专用发票  专用发票",
        "005": "机动车销售统一发票  其他发票",
        "006": "二手车统一发票  其他发票",
        "026": "电子普通发票  普通发票",
        99: "通用机打发票  普通发票",
        c: "增值税普通发票  普通发票",
        s: "增值税专用发票  专用发票",
        j: "机动车销售统一发票  其他发票",
        p: "电子普通发票  普通发票",
      },
      relationTaxNo: "", //点击左侧公司才有这个税号
      companyNameTitle: this.$route.query.companyName,
      createTimeTitle: this.$route.query.createTime,
      billTypes: this.$route.query.type,
      contentRight: true,
      billtype: "",
      billStatus: "",
      itemSelect: 0,
      contentIndex: 0,
      item: 0,
      activeIndex: "1",
      billTime: "",
      nameFilter: "",
      billtypeList: [
        {
          value: "1",
          label: "专用发票",
        },
        {
          value: "2",
          label: "普通发票",
        },
        {
          value: "3",
          label: "其他",
        },
      ],
      billData: [],
      billStatusListArray: ["正常", "作废", "红冲", "失控", "异常"],
      billStatusListArraySale: ["正常", "红冲", "空白发票", "作废", "异常", "失控"],
      billStatusList: [
        {
          value: "0",
          label: "正常",
        },
        {
          value: "1",
          label: "作废",
        },
        {
          value: "2",
          label: "红冲",
        },
        {
          value: "3",
          label: "失控",
        },
        {
          value: "4",
          label: "异常",
        },
      ],
      billTableDataItem0: [],
      billTableDataItem1: [],
      billTableDataItem2: [],
      billTableDataItem3: [],
      pageNum0: 1,
      pageSize0: 200,
      totalNumber0: 10,
      pageNum1: 1,
      pageSize1: 200,
      totalNumber1: 10,
      billCircleData: [],
      isActive: 4,
    };
  },

  mounted() {
    this.contentIndex = this.$route.query.taxNo;
    this.getEntryCompany();
    this.searchItem();
  },

  methods: {
    backTest() {
      this.$router.go(-1);
    },
    handleSizeChange0(val) {
      this.pageSize0 = val;
      this.searchItem();
    },
    handleCurrentChange0(val) {
      this.pageNum0 = val;
      this.searchItem();
    },
    handleSizeChange1(val) {
      this.pageSize1 = val;
      this.searchItem();
    },
    handleCurrentChange1(val) {
      this.pageNum1 = val;
      this.searchItem();
    },
    sorttaxtaxAmount(obj1, obj2) {
      let val1 = obj1.taxAmount;
      let val2 = obj2.taxAmount;
      return val1 - val2;
    },
    sortproductNumber1(obj1, obj2) {
      let val1 = obj1.productNumber;
      let val2 = obj2.productNumber;
      return val1 - val2;
    },
    sortamountExcludingTax1(obj1, obj2) {
      let val1 = obj1.amountExcludingTax;
      let val2 = obj2.amountExcludingTax;
      return val1 - val2;
    },
    sorttaxAmount1(obj1, obj2) {
      let val1 = obj1.taxAmount;
      let val2 = obj2.taxAmount;
      return val1 - val2;
    },
    sorttaxDetailed1(obj1, obj2) {
      let val1 = obj1.taxDetailed;
      let val2 = obj2.taxDetailed;
      return val1 - val2;
    },
    sortaveragePrice1(obj1, obj2) {
      let val1 = obj1.averagePrice;
      let val2 = obj2.averagePrice;
      return val1 - val2;
    },
    sortpercent1(obj1, obj2) {
      let val1 = obj1.percent;
      let val2 = obj2.percent;
      return val1 - val2;
    },
    sortunitPriceExcludingTax(obj1, obj2) {
      let val1 = obj1.unitPriceExcludingTax;
      let val2 = obj2.unitPriceExcludingTax;
      return val1 - val2;
    },
    sortproductNumber(obj1, obj2) {
      let val1 = obj1.productNumber;
      let val2 = obj2.productNumber;
      return val1 - val2;
    },
    sortamountExcludingTax(obj1, obj2) {
      let val1 = obj1.amountExcludingTax;
      let val2 = obj2.amountExcludingTax;
      return val1 - val2;
    },
    sorttaxRateDetailed(obj1, obj2) {
      let val1 = parseInt(obj1.taxRateDetailed);
      let val2 = parseInt(obj2.taxRateDetailed);
      return val1 - val2;
    },
    sorttaxDetailed(obj1, obj2) {
      let val1 = obj1.taxDetailed;
      let val2 = obj2.taxDetailed;
      return val1 - val2;
    },
    sorttotalAmount(obj1, obj2) {
      let val1 = obj1.totalAmount;
      let val2 = obj2.totalAmount;
      return val1 - val2;
    },
    sorttotalTax(obj1, obj2) {
      let val1 = obj1.totalTax;
      let val2 = obj2.totalTax;
      return val1 - val2;
    },
    sortamountTax(obj1, obj2) {
      let val1 = obj1.amountTax;
      let val2 = obj2.amountTax;
      return val1 - val2;
    },
    sorttotalAmount3(obj1, obj2) {
      let val1 = obj1.totalAmount;
      let val2 = obj2.totalAmount;
      return val1 - val2;
    },
    sorttotalTax3(obj1, obj2) {
      let val1 = obj1.totalTax;
      let val2 = obj2.totalTax;
      return val1 - val2;
    },
    sortamountTax3(obj1, obj2) {
      let val1 = obj1.amountTax;
      let val2 = obj2.amountTax;
      return val1 - val2;
    },
    // 时间筛选框
    timeChange() {
      console.log(this.billTime);
      this.searchSetIndex();
      this.searchCompany();
      this.searchItem();
    },
    // 公司名称过滤器
    namefilterMeth() {
      this.searchCompany();
    },
    // 公司名称清空
    namefilterclear() {
      if (!this.nameFilter) {
        this.searchCompany();
      }
    },
    item2Act(item) {
      this.contentIndex = item.taxNo;
      // 如果点击了母公司，关联企业参数为空
      if (this.contentIndex == this.$route.query.taxNo) {
        this.relationTaxNo = "";
      } else {
        this.relationTaxNo = this.contentIndex;
      }
      this.searchSetIndex();
      this.searchItem();
    },
    getEntryCompany() {
      let params = {
        endDate: this.billTime ? this.billTime[1] : "",
        startDate: this.billTime ? this.billTime[0] : "",
        taxNo: this.$route.query.taxNo,
        companyName: this.nameFilter,
        programId: this.$route.query.uuid,
      };
      getEntryCompanyList(params).then((res) => {
        this.billData = [];
        if (res.data.taxNo) {
          this.billData.push(res.data);
        } else {
          this.billData = [];
        }
      });
    },
    getSalesCompany() {
      let params = {
        endDate: this.billTime ? this.billTime[1] : "",
        startDate: this.billTime ? this.billTime[0] : "",
        taxNo: this.$route.query.taxNo,
        companyName: this.nameFilter,
        programId: this.$route.query.uuid,
      };
      getSalesCompanyList(params).then((res) => {
        this.billData = [];
        if (res.data.taxNo) {
          this.billData.push(res.data);
        } else {
          this.billData = [];
        }
      });
    },
    billchangeType() {
      this.pageNum1 = 1;
      this.getInvoiceList();
    },
    changeItem(e) {
      let index = e.target.dataset.index;
      if (!index) return;
      this.item = index;
      this.searchItem();
    },
    searchItem() {
      this.getTable = true;
      if (this.item == 0) {
        this.getInvoiceGoodsList();
      }
      if (this.item == 1) {
        this.getInvoiceCategoryList();
      }
      if (this.item == 2) {
        this.getInvoiceList();
      }
      if (this.item == 3) {
        this.getInvoiceMonthList();
      }
    },
    searchSetIndex() {
      this.pageNum0 = 1;
      this.pageNum1 = 1;
    },
    allResult() {
      this.billTime = "";
      this.isActive = 4;
      this.searchSetIndex();
      this.searchItem();
      this.searchCompany();
    },
    lastYear() {
      const end = new Date();
      const start = new Date().getFullYear();
      const startYear = new Date(start - 1, 0, 1);
      const endYear = start - 1 + "-" + "12" + "-" + "31";
      this.billTime = [moment(startYear).format("YYYY-MM-DD"), endYear];
      this.isActive = 3;
      this.searchSetIndex();
      this.searchItem();
      this.searchCompany();
    },
    nowYear() {
      const end = new Date();
      const start = new Date().getFullYear();
      const startYear = new Date(start, 0, 1);
      this.billTime = [
        moment(startYear).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD"),
      ];
      this.isActive = 2;
      this.searchSetIndex();
      this.searchItem();
      this.searchCompany();
    },
    nowMonth() {
      const end = new Date();
      const start = new Date(end.getFullYear(), end.getMonth(), 1);
      this.billTime = [
        moment(start).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD"),
      ];
      this.isActive = 0;
      this.searchSetIndex();
      this.searchItem();
      this.searchCompany();
    },
    lastMonth() {
      const oDate = new Date();
      var year = oDate.getFullYear();
      var month = oDate.getMonth();
      var start, end;
      if (month == 0) {
        year--;
        start = new Date(year, 11, 1);
        end = new Date(year, 11, 31);
      } else {
        start = new Date(year, month - 1, 1);
        end = new Date(year, month, 0);
      }
      this.billTime = [
        moment(start).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD"),
      ];
      this.isActive = 1;
      this.searchSetIndex();
      this.searchItem();
      this.searchCompany();
    },
    handleSelect(key) {
      this.activeIndex = key;
      this.contentIndex = 0;
      this.relationTaxNo = "";
      this.searchSetIndex();
      this.searchCompany();
      this.searchItem();
    },
    searchCompany() {
      if (this.activeIndex == 1) {
        this.getEntryCompany();
      } else {
        this.getSalesCompany();
      }
    },
    // 进项商品明细
    getEntryInvoiceGoods() {
      let params = {
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: this.pageNum0,
        pageSize: this.pageSize0,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
      };
      getEntryInvoiceGoodsList(params).then((res) => {
        this.totalNumber0 = Number(res.pageTotal);
        this.billTableDataItem0 = res.list;
        this.getTable = false;
      });
    },
    // 销项商品明细
    getSalesInvoiceGoods() {
      let params = {
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: this.pageNum0,
        pageSize: this.pageSize0,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
      };
      getSalesInvoiceGoodsList(params).then((res) => {
        this.totalNumber0 = Number(res.pageTotal);
        this.billTableDataItem0 = res.list;
        this.getTable = false;
      });
    },
    getInvoiceGoodsList() {
      if (this.activeIndex == 1) {
        this.getEntryInvoiceGoods();
      } else {
        this.getSalesInvoiceGoods();
      }
    },
    // 进项品类统计
    getEntryInvoiceCategory() {
      let params = {
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: 1,
        pageSize: 1000,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
      };
      getEntryInvoiceCategoryList(params).then((res) => {
        this.billTableDataItem1 = res.data.tableList;
        let billCircleData = res.data.imageList;
        this.billCircleData = [];
        for (let i in billCircleData) {
          this.billCircleData[i] = {};
          this.billCircleData[i].value = billCircleData[i].percent;
          this.billCircleData[i].name = billCircleData[i].productName;
        }
        this.showMap();
        this.getTable = false;
      });
    },
    // 销项品类统计
    getSalesInvoiceCategory() {
      let params = {
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: 1,
        pageSize: 1000,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
      };
      getSalesInvoiceCategoryList(params).then((res) => {
        this.billTableDataItem1 = res.data.tableList;
        let billCircleData = res.data.imageList;
        this.billCircleData = [];
        for (let i in billCircleData) {
          this.billCircleData[i] = {};
          this.billCircleData[i].value = billCircleData[i].percent;
          this.billCircleData[i].name = billCircleData[i].productName;
        }
        this.showMap();
        this.getTable = false;
      });
    },
    getInvoiceCategoryList() {
      if (this.activeIndex == 1) {
        this.getEntryInvoiceCategory();
      } else {
        this.getSalesInvoiceCategory();
      }
    },
    // 进项发票明细
    getEntryInvoice() {
      let params = {
        invoiceStatus: this.billStatus,
        invoiceType: this.billtype,
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: this.pageNum1,
        pageSize: this.pageSize1,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
      };
      getEntryInvoiceList(params).then((res) => {
        this.totalNumber1 = Number(res.pageTotal);
        this.billTableDataItem2 = res.list;
        this.getTable = false;
      });
    },
    // 销项发票明细
    getSalesInvoice() {
      let params = {
        invoiceStatus: this.billStatus,
        invoiceType: this.billtype,
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: this.pageNum1,
        pageSize: this.pageSize1,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
      };
      getSalesInvoiceList(params).then((res) => {
        this.totalNumber1 = Number(res.pageTotal);
        this.billTableDataItem2 = res.list;
        this.getTable = false;
      });
    },
    getInvoiceList() {
      if (this.activeIndex == 1) {
        this.getEntryInvoice();
      } else {
        this.getSalesInvoice();
      }
    },
    // 进项每月进项
    getEntryInvoiceMonth() {
      let params = {
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: 1,
        pageSize: 1000,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
      };
      getEntryInvoiceMonthList(params).then((res) => {
        this.billTableDataItem3 = res.data;
        this.getTable = false;
      });
    },
    // 销项每月进项
    getSalesInvoiceMonth() {
      let params = {
        startDate: this.billTime ? this.billTime[0] : "",
        endDate: this.billTime ? this.billTime[1] : "",
        pageNum: 1,
        pageSize: 1000,
        relationTaxNo: this.relationTaxNo,
        taxNo: this.$route.query.taxNo,
        programId: this.$route.query.uuid,
      };
      getSalesInvoiceMonthList(params).then((res) => {
        this.billTableDataItem3 = res.data;
        this.getTable = false;
      });
    },
    getInvoiceMonthList() {
      if (this.activeIndex == 1) {
        this.getEntryInvoiceMonth();
      } else {
        this.getSalesInvoiceMonth();
      }
    },
    showMap() {
      this.chartColumn = echarts.init(document.getElementById("circle"));
      this.chartColumn.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b} : {d}%",
        },
        toolbox: {
          show: true,
          feature: {
            magicType: {
              show: true,
              type: ["pie", "funnel"],
              option: {
                funnel: {
                  x: "25%",
                  width: "50%",
                  funnelAlign: "left",
                  max: 1548,
                },
              },
            },
          },
        },
        calculable: true,
        series: [
          {
            name: "品类统计",
            type: "pie",
            radius: "80%",
            radius: ["40%", "80%"],
            color: [
              "#EC5757",
              "#BCA88D",
              "#2588ED",
              "#F7A659",
              "#95A5CD",
              "#FF409A",
              "#B8D4F9",
              "#ABDE8A",
              "#EED9BE",
              "#6293F8",
              "#878787",
            ],
            label: {
              normal: {
                position: "inner",
                show: false,
              },
            },
            data: this.billCircleData,
          },
        ],
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.bill-detail-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 20px 30px 16px 30px;
  padding: 0 24px 0 16px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.75);

  .time {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
  }
}

.bill-detail-content {
  margin: 0 30px;
  background: #fff;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  padding-bottom: 6px;

  .bill-detail-menu {
    padding: 0 16px;
    background: #fff;

    >>> .el-menu--horizontal > .el-menu-item {
      height: 44px;
      line-height: 44px;
    }

    >>> .el-menu--horizontal > .el-menu-item.is-active {
      border-bottom: 2px solid #2f54eb;
      color: #2f54eb;
    }
  }

  .bill-time-filter {
    display: flex;
    align-items: center;
    // padding: 16px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.75);
    padding: 12px 16px;

    >>> .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
      width: 294px;
      height: 36px;
      line-height: 36px;
    }

    >>> .el-date-editor .el-range-separator {
      line-height: 28px;
    }

    >>> .el-date-editor .el-range__icon {
      line-height: 28px;
    }

    >>> .el-date-editor .el-range__close-icon {
      line-height: 28px;
    }

    .search {
      width: 76px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      color: #fff;
      background: #2F54EB;
      border-radius: 4px;
      margin: 0 0 0 16px;
      font-size: 14px;
      cursor: pointer;
    }

    .search:hover {
      background: rgba(47, 34, 235, 1);
    }

    >>> .el-divider--vertical {
      margin: 0 24px;
      height: 40px;
    }

    .search-month {
      border: 1px solid rgba(47, 34, 235, 1);
      background: #fff;
      color: rgba(47, 34, 235, 1);
      box-sizing: border-box;
      width: 76px;
      height: 36px;
      line-height: 32px;
      text-align: center;
      margin-right: 16px;
      border-radius: 4px;
      cursor: pointer;
    }

    .month-active {
      background: rgba(47, 34, 235, 1);
      color: #fff;
    }
  }

  .bill-time-table {
    display: flex;
    padding: 0 16px;

    >>> .content-c::-webkit-scrollbar {
      /* 滚动条整体样式 */
      width: 6px;
      /* 高宽分别对应横竖滚动条的尺寸 */
      height: 1px;
      position: relative;
      right: 4px;
    }

    >>> .content-c::-webkit-scrollbar-thumb {
      /* 滚动条里面小方块 */
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: #C4CFFF;
    }

    >>> .content-c::-webkit-scrollbar-track {
      /* 滚动条里面轨道 */
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background: #F2F4FF;
    }

    .left {
      width: 26%;
      border: 1px solid #D9D9D9;
      position: relative;

      .content-c {
        max-height: calc(100vh - 136px);
        overflow-x: auto;
      }

      .content-flex {
        color: rgba(0, 0, 0, 0.45);
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -32px;
      }

      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.75);
        padding: 15px 16px;
        box-sizing: border-box;

        .title-input {
          flex: 1;
          padding-right: 20px;
        }

        .title-company {
          white-space: nowrap;
        }

        >>> .el-input {
          // max-width: 240px;
          height: 36px;
          line-height: 36px;
        }

        >>> .el-input__inner {
          height: 36px;
          line-height: 36px;
        }

        >>> .el-input__icon {
          height: 36px;
          line-height: 36px;
        }
      }

      .contentAct {
        background: #F0F5FF;
      }

      .content {
        position: relative;
        border-bottom: 1px solid #EEEEEE;
        cursor: pointer;

        .content-item {
          padding: 16px 34px;
        }

        .content-item2 {
          padding: 6px 34px 6px 45px;
        }

        .content-right {
          position: absolute;
          top: 8px;
          color: #D8D8D8;
          left: -4px;
          font-size: 22px;
          padding: 10px;
        }

        .company {
          font-size: 16px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.8);
          line-height: 22px;
        }

        .price {
          margin-bottom: 8px;
          margin-right: 16px;
        }

        .info {
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .content:last-child {
        // border: none;
      }
    }

    .right {
      width: 74%;
      overflow: hidden;

      .right-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .select {
          margin-right: 42px;

          >>> .el-select {
            width: 90px;
            height: 36px;
          }

          >>> .el-input__inner {
            height: 36px;
            line-height: 36px;
          }

          >>> .el-input__icon {
            line-height: 36px;
          }

          .billStatus {
            margin-left: 24px;
          }
        }

        .bill-select {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.75);
          font-weight: 400;
          margin-right: 0;
        }
      }

      .item {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.6);
        margin-left: 28px;
        cursor: pointer;

        >>> .el-divider--vertical {
          margin: 0;
          height: 36px;
        }

        .bill-name {
          color: #2f22eb;
        }

        .taxname-select {
          padding: 10px 8px;
          color: rgba(0, 0, 0, 0.4);
        }

        .bill-names {
          color: rgba(0, 0, 0, 0.6);
        }

        .taxname {
          padding: 10px 14px;
        }

        .taxname:first-child {
          padding-left: 0;
        }
      }

      .select {
      }

      .item-table {
        position: relative;

        .item-pagination {
          // position: absolute;
          // right: 0;
          // bottom: 10px;
          float: right;
          margin-top: 4px;
        }

        >>> .el-table td {
          border: none;
        }

        >>> .el-table {
          // min-height: 330px;
        }

        >>> .el-table__body-wrapper::-webkit-scrollbar {
          /* 滚动条整体样式 */
          width: 6px;
          /* 高宽分别对应横竖滚动条的尺寸 */
          height: 10px;
          position: relative;
          right: 4px;
        }

        >>> .el-table__body-wrapper::-webkit-scrollbar-thumb {
          /* 滚动条里面小方块 */
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
          background: #C4CFFF;
        }

        >>> .el-table__body-wrapper::-webkit-scrollbar-track {
          /* 滚动条里面轨道 */
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          background: #F2F4FF;
        }

        >>> .el-table th .cell {
          line-height: 34px;
        }

        >>> .el-table td {
          padding: 11px 0;
        }
      }
    }
  }
}

.circle-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 500px;

  .circle {
    position: relative;
    left: 20px;
    width: 250px;
    height: 250px;
    background: #fff;
  }

  .circle-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: space-between;
    margin-left: 60px;

    .bill-Data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 460px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
      line-height: 22px;
      margin-bottom: 8px;
    }
  }
}

.backTest {
  position: absolute;
  top: 7px;
  right: 40px;
  display: inline-block;
  font-size: 14px;
  color: #86909c;
  margin: 12px 0 12px 30px;
  cursor: pointer;
  text-decoration: underline;
  z-index: 1001;
}

.bill-container {
  position: relative;
}
</style>
