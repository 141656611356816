<template>
  <div class="page-wrapper">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>风险检测</el-breadcrumb-item>
          <el-breadcrumb-item>电子税局账号密码填写</el-breadcrumb-item>
          <el-breadcrumb-item>报告生成中</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">风险检测</template>
      </main-header>
    </div>
    <div class="invoice-content">
      <div class="progress-wrapper">
        <img class="invoice-img" src="@/assets/result/no-invoice.png" alt />
        <div class="invoice-text">报告生成中，请耐心等待！</div>
        <div class="invoice-btn ease" @click="toTestLog">返回检测列表页</div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";

export default {
  components: {
    BreadCrumb,
    MainHeader,
  },
  props: {
    uuid: String,
  },
  methods: {
    toTestLog() {
      this.$router.push({ name: "test-log" });
    },
  },
};
</script>

<style lang="stylus" scoped>
.page-wrapper {
  height calc(100vh - 60px)
}

.invoice-content {
  text-align: center;
  height: calc(100% - 32px - 96px);
  box-sizing border-box
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 16px 30px;
  display flex
  align-items center
  justify-content center

  .progress-wrapper {
    display flex
    align-items center
    justify-content center
    flex-direction column
  }

  .text {
    font-size: 18px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 25px;
    margin-top 50px
  }
}

.invoice-text {
  margin-top: 52px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.invoice-btn {
  width: 170px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  margin-top: 24px;
  cursor: pointer;
}

.invoice-btn:hover {
  background: rgba(47, 64, 235, 1);
}
</style>
