<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/tools/toolsAudit' }">小工具</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/tools/toolsAudit' }">审计小工具</el-breadcrumb-item>
          <el-breadcrumb-item>查看报表</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">查看报表</template>
      </main-header>
    </div>
    <div>
      <div class="audit-container">
        <div class="audit-tab">标题1</div>
        <div class="audit-tab">标题2</div>
        <div class="audit-tab audit-tabRight">标题3</div>
      </div>
      <div class="audit-content">标题1</div>
      <div class="audit-select">
        年度筛选：
        <el-select v-model="yearValue" placeholder="请选择">
          <el-option
            v-for="item in yearOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <div class="content-body">
      <template>
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" sortable></el-table-column>
          <el-table-column prop="createTime" label="检测时间" sortable></el-table-column>
          <el-table-column
            prop="companyName"
            label="企业名称"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="salesman"
            label="纳税人识别号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="contact"
            label="项目经理"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="contactNumber"
            label="状态"
            :show-overflow-tooltip="true"
          ></el-table-column>
        </el-table>
      </template>
    </div>

    <el-pagination
      class="pagination-low"
      @current-change="handleCurrentChange"
      :current-page="pageno + 1"
      layout="total, prev, pager, next, jumper"
      :total="totalNumber"
      :haveTotal="haveTotal"
    ></el-pagination>
  </div>
</template>

<script>
import { getCustomListNew } from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";

export default {
  components: {
    MainHeader,
    BreadCrumb,
  },

  data() {
    return {
      yearOptions: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      haveTotal: true,
      totalNumber: 10, //总数
      pageno: 0,
      tableDataList: null,
      yearValue: "",
    };
  },

  mounted() {
    this.getcustomList();
  },

  methods: {
    addTest() {
      this.$router.push({ name: "authorization" });
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageno = val - 1;
      this.getcustomList();
    },
    // 获取客户列表
    getcustomList() {
      let params = {
        pageSize: 10,
        pageNo: this.pageno,
      };
      getCustomListNew(params, localStorage.getItem("authorization")).then((res) => {
        if (res.data.msgCode == 1) {
          this.tableDataList = res.data.data.content;
          this.totalNumber = res.data.data.totalElements;
        } else {
          this.$message({
            showClose: true,
            message: res.msgInfo,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

.audit-select >>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.el-icon-edit-outline {
  color: #2f54eb;
  font-size: 18px;
  margin-left: 4px;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.pagination-low {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}

.audit-select >>>.el-select {
  width: 240px;
}

.audit-select >>>.el-input {
  width: 240px;
}

.audit-select >>>.el-range-editor.el-input__inner {
  width: 240px;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

>>>.el-input__icon {
  line-height: 28px;
}

.audit-content {
  margin: 0 20px;
  padding: 14px 20px;
  background: #fff;
  border-radius: 12px;
}

.audit-select {
  margin: 10px 0 0 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.audit-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;

  .audit-tab {
    width: 174px;
    height: 49px;
    line-height: 49px;
    text-align: center;
    background: #fff;
    -webkit-box-shadow: 0px -4px 8px 0px rgba(0,0,0,0.08);
    box-shadow: 0px -4px 8px 0px rgba(0,0,0,0.08);
    border-radius: 18px 18px 0px 0px;
    cursor: pointer;
    color: rgba(0,0,0,0.4);
    margin-right: 20px;
  }

  .audit-tabRight {
    margin-right: 0;
  }
}
</style>
