const clearAllChildren = (items, childrenName = 'children') => {
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    // 当前对象存在children
    if (item && item[childrenName]) {
      // children为空数组时删除
      if (item[childrenName].length === 0) {
        delete item[childrenName]
      } else {
        // 递归当前children数组
        clearAllChildren(item[childrenName], childrenName)
      }
    }
  }
  return items
}

export default clearAllChildren
