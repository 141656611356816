<template>
  <div class="login-wrapper">
    <!-- <div class="login_left">
      <img src="@/assets/login/3rdpartleft.png" alt />
    </div> -->
    <div class="tab_box">
      <div class="aitax_title">完善信息</div>
      <el-form
        :model="ruleForm2"
        ref="ruleForm2"
        label-position="left"
        label-width="0px"
        class="containernew"
      >
        <ValidationObserver ref="validationObserver">
          <ValidationProvider name="手机号：" rules="required|mobile">
            <div slot-scope="{ classes,errors }">
              <el-form-item prop="phone">
                <div>
                  <el-input
                    class="usernameinput"
                    type="text"
                    v-model.lazy="ruleForm2.phone"
                    auto-complete="off"
                    placeholder="请输入手机号"
                    :class="classes"
                    name="mobile"
                  ></el-input>
                  <i class="baseloginicon iconshouji iconfont"></i>
                </div>
                <p class="validate-error">{{ errors[0] }}</p>
              </el-form-item>
            </div>
          </ValidationProvider>

          <ValidationProvider name="短信验证码：" rules="required">
            <div slot-scope="{ classes,errors }">
              <el-form-item prop="verifyCode">
                <div>
                  <el-input
                    class="passwordinput usernameinput phoneyzm"
                    type="text"
                    v-model.lazy="ruleForm2.verifyCode"
                    auto-complete="off"
                    placeholder="短信验证码"
                    :class="classes"
                  ></el-input>
                  <i class="baseloginicon iconmima iconfont"></i>

                  <el-button
                    :class="[canClick ? '' : 'active', 'btnsendcode']"
                    class="btn-send-sms"
                    @click="getSMScode()"
                  >{{content}}</el-button>
                </div>
                <p class="validate-error">{{ errors[0] }}</p>
              </el-form-item>
            </div>
          </ValidationProvider>

          <div class="userId1">
            <span class="userCheck">
              <el-form-item
                prop="UserChecked"
                class="useryuedu"
                style="margin-bottom: 0px !important;"
              >
                <el-checkbox label="我已阅读并接受" class="rememberpw" checked v-model="agreement"></el-checkbox>
                <span class="userxy" @click="dialogVisible = true">《用户协议》</span>
              </el-form-item>
            </span>
          </div>
          <el-form-item class="loginitem">
            <el-button
              class="loginbtn1"
              @click.native.prevent="handleSubmit"
              :loading="logining"
              :disabled="submitDisabled"
            >确定</el-button>
          </el-form-item>
        </ValidationObserver>
      </el-form>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="80%" center :show-close="true">
      <agruments></agruments>
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { localize, validate } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_CN", zh_CN);

import agruments from "@/pages/login/components/agreement.vue";


import { regirster3rdPartyAuth,getSMSCode } from "@/api/auth";
export default {
  components: {
    agruments
  },
  data: function() {
    return {
      dialogVisible: false,
      agreement: true,
      submitDisabled: false,
      content: "获取验证码",
      canClick: true,
      totalTime: 60,
      logining: false,
      labelchecked: false,
      ruleForm2: {
        phone: "",
        verifyCode: ""
      }
    };
  },
  mounted() {
    //this.$route.query;
    
  },
  methods: {
    ...mapMutations(["changeLogin"]),
    handleSubmit() {
      const _this = this;
      const openId = this.$route.query.openid;
      const unionId  = this.$route.query.unionid;
      const nickName  = this.$route.query.nickName;
      const headImgUrl  = this.$route.query.headImgUrl;

      _this.submitDisabled = true;
      this.$refs["validationObserver"]
        .validate()
        .then(async success => {
          if (!success) {
            return;
          } else {
            if (!this.agreement) {
              this.$alert("同意《用户协议》才能提交", "错误", {
                confirmButtonText: "确定",
                type: "error"
              });
            } else {
              const param = {
                mobile: _this.ruleForm2.phone,
                verifyCode: _this.ruleForm2.verifyCode,
                openId,
                unionId,
                nickName,
                headImgUrl
              };
              const resData = await regirster3rdPartyAuth(param);
              //
              if (resData.msgCode == "1") {
                this.login(resData.data);
              } else {
                _this.$alert(resData.msgInfo, "提示", {
                  confirmButtonText: "确定",
                  type: "warning"
                });
              }
            }
          }
        })
        .finally(() => {
          _this.submitDisabled = false;
        });
    },
    getSMScode() {
      let _this = this;
      if (!this.canClick) return;

      validate(this.ruleForm2.phone, "required|mobile", {
        name: "mobile"
      }).then(result => {
        if (result.valid) {
          //验证码发送的请求
          let param = { phone: this.ruleForm2.phone };

          getSMSCode(param.phone).then(data => {
            if (data.msgCode !== "1") {
              _this.$alert(data.msgInfo, "提示", {
                confirmButtonText: "确定",
                type: "warning"
              });
            } else {
              //获取验证码倒计时
              this.canClick = false;
              this.content = this.totalTime + "s后重发";
              let clock = window.setInterval(() => {
                this.totalTime--;
                this.content = this.totalTime + "s后重发";
                if (this.totalTime < 0) {
                  window.clearInterval(clock);
                  this.content = "重发验证码 ";
                  this.totalTime = 60;
                  this.canClick = true;
                }
              }, 1000);
            }
          });
        } else {
          _this.$alert("请先输入手机号码再点击发送验证码", "提示", {
            confirmButtonText: "确定",
            type: "warning"
          });
        }
      });
    },
    login(data) {
      let user = data.user;
      let token = data.token;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", JSON.stringify(token));
      // 将用户token保存到vuex中
      this.changeLogin({ authorization: token.token });
      this.$router.push({ path: "/" });
    }
  }
};
</script>
<style lang="stylus" scoped>
.login_left {
  width: 560px;

  img {
    width: 464px;
    margin-left: 48px;
    margin-top: 32px;
  }
}

.tab_box {
  width: 400px;

  .aitax_title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 24px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 33px;
  }

  .aitax {
    text-align: center;
    height: 36px;
    font-size: 32px;
    font-family: ArialMT;
    color: rgba(0, 0, 0, 0.8);
    line-height: 37px;
  }
}

#login_tab {
  width: 400px;
}

.wjmm {
  margin-bottom: 16px;
}

.zcuser1 {
  margin-top: 23px !important;
}

.zcuser {
  margin-top: 23px;
  text-align: center;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;

  span {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: rgba(0, 0, 0, 0.6);
  }

  img {
    width: 24px;
    margin-left: 8px;
  }
}

.phoneyzm {
  width: 210px !important;
}

.wxlogin {
  width: 124px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  display: inline-block;
  position: relative;

  img {
    width: 24px;
    cursor: pointer;
    height: 24px;
    position: absolute;
    top: -3px;
    right: 0;
  }
}

.passcss {
  width: 18px !important;
  height: 18px !important;
}

.btnsendcode {
  position: absolute;
  right: 0;
  top: -16px;
  background: #fff;
  border-radius: 4px;
  color: #2F54EB;
  width: 102px;
  padding: 12px 0px;
  border: 1px solid #2F54EB;
}

>>> .usernameinput {
  .el-input__inner {
    height: 40px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    border: 1px solid rgba(217, 217, 217, 1);
    text-indent: 10px;
  }
}

>>>.rememberpw {
  .el-checkbox__inner {
    border-color: #D9D9D9;

    &::after {
    }
  }

  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #2F54EB;
    border-color: #2F54EB;
  }

  .el-checkbox__label {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }
}

.forget1 {
  a {
    font-size: 14px !important;
  }
}

.forget {
  text-align: left;
  margin-right: 10px;

  a {
    font-size: 16px;
    color: #2F54EB;
  }
}

.btn-send-sms {
  margin-top: 16px;
}

>>>.el-input__inner {
  padding-left: 30px;
}

>>>.el-tabs__nav-wrap::after {
  content: none;
}

.loginmeg {
  margin-top: 8px;
  text-align: center;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(245, 34, 45, 1);
  line-height: 17px;
}

.loginitem {
  text-align: right;
  margin-bottom: 8px !important;

  .loginitemzc {
    float: left;
    text-align: left;
    color: #2F54EB;

    a {
      color: #2F54EB;
    }
  }
}

.loginbtn1 {
  background: #2F54EB;
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
}

.containernew {
  width: 80%;
  margin: 0 auto;

  >>>.el-form-item {
    margin-bottom: 24px;
  }

  >>>.el-form-item:nth-child(2) {
    margin-bottom: 32px;
  }

  .baseloginicon {
    position: absolute;
    left: 16px;
    // top: 11px;
    color: #999999;
    width: 16px;
    height: 16px;
  }
}

.login-wrapper {
  display: flex;
  justify-content: space-evenly;
  height 440px;
  box-sizing border-box;
  padding:80px 0 60px;
  width: 480px;
  position: relative;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
  padding-top: 80px;
}

.logintitle {
  margin-bottom: 80px;

  .titletop {
    font-size: 36px;
    color: #1a1a1a;
    font-weight: bold;
    text-align: center;

    span {
      font-size: 36px;
      color: #000000;
      font-weight: normal;
    }
  }
}

.userxy {
  color: #2F54EB;
  cursor: pointer;
}

.validate-error {
  margin: 0;
  line-height: 1.5;
  position: absolute;
}

>>> .validation-is-invalid .el-input__inner {
  border: 1px solid #f56c6c !important;
}
</style>