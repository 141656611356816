<template>
  <div class="page-wrapper" :key="actualZq" v-if="actualZq">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
        <!-- <el-breadcrumb-item :to="{ name: 'test-hub' }">检测中心</el-breadcrumb-item> -->
        <el-breadcrumb-item>风险检测流程</el-breadcrumb-item>
        <el-breadcrumb-item>{{zqName}}</el-breadcrumb-item>
      </bread-crumb>
      <div class="main-top">
        <main-header>
          <template slot="title">数据读取</template>
        </main-header>
        <div class="processContainer">
          <span>
            <img class="processLImg" src="./images/1.png" alt />
          </span>
          <span class="process1">申报数据读取</span>
          <span class="process-status"></span>
          <span>
            <img class="processLImg" src="./images/2.png" alt />
          </span>
          <span class="process2">发票数据采集</span>
        </div>

        <ProgressDiagram
          class="progressdis"
          @editUser="editUser"
          :userInfo="userInfo"
          :progress="progress"
          :version="version"
        ></ProgressDiagram>
      </div>
    </div>
    <div class="content-body-tab" v-if="userInfo==2">
      <div v-if="dataPeriod==1">
        <router-link
          :to="{ name: 'UploadTableFix', params: { zq,zqLocation,dataPeriod:0,nsrsbhIndex: this.nsrsbhIndex,
              qykjzdIndex: this.qykjzdIndex}}"
        >
          <div
            class="content-body-tabl"
            :class="{zqLocation: dataPeriod==0}"
          >{{zq.substring(0,4)}}年报表</div>
        </router-link>
      </div>
      <div
        v-if="dataPeriod==0"
        class="content-body-tabl"
        :class="{zqLocation: dataPeriod==0}"
      >{{zq.substring(0,4)}}年报表</div>
      <div
        class="content-body-tabr"
        :class="{zqLocation: dataPeriod==1}"
        @click="handleBtnNext"
        v-if="dataPeriod==0"
      >{{zq.substring(0,4)-1}}年报表</div>
      <div
        class="content-body-tabr"
        :class="{zqLocation: dataPeriod==1}"
        v-if="dataPeriod==1"
      >{{zq.substring(0,4)-1}}年报表</div>
    </div>

    <div class="content-body" :class="{nomargin:userInfo==1}">
      <div v-if="userInfo==2" :class="{uploadFlex:showTableDetail}">
        <div :class="{uploadFlexLeft:showTableDetail}">
          <div
            class="content-body-flex"
            :class="{uploadFlexLefts:showTableDetail}"
            v-if="userInfo==2"
          >
            <div class="content-top-1" v-if="!showTableDetail">
              <span v-if="dataPeriod==0">{{zq.substring(0,4)}}年报表上传</span>
              <span v-if="dataPeriod==1">{{zq.substring(0,4)-1}}年报表上传</span>
            </div>
            <div class="content-body-flexr">
              <div class="acknowlege content-top-2" @click="acknowledgeDialogVisible = true">查看上传须知</div>
              <a :href="templateDownloadURL" class="btn-download content-top-2" download>下载模板</a>
            </div>
          </div>
          <div>
            <uploader
              @onUpload="onUpload"
              :uuid="uuid"
              :showTableDetail="showTableDetail"
              v-if="actualZq"
              @showDetailBox="showDetailBox"
            ></uploader>
          </div>

          <!-- <div class="clearfix upload-footer">
          <div class="tip">
            <i class="el-icon-warning"></i>
            温馨提示：为保证检测准确性，用户至少需要上传：资产负债表、利润表、增值税纳税申报表、所得税申报表
          </div>
          </div>-->
          <div class="arrow-icon" v-if="!showTableDetail" @click="showMoreUpload">
            手动填写报表
            <i class="el-icon-d-arrow-left"></i>
          </div>
        </div>

        <table-list
          v-show="showTableDetail"
          :tables="tables"
          @listChanged="listChanged"
          manner="upload"
          class="table-list"
          :class="{uploadFlexRight:showTableDetail}"
          @showDetailBalance="showDetailBalance"
        ></table-list>
      </div>
      <report-method @openlicense="openlicense" @setName="changeName" v-if="userInfo==1"></report-method>
      <sa-footer>
        <div class="footer">
          <button
            class="btn btn-purge-data"
            @click="handleBtnPurgeDataClicker"
            v-if="hasItemsInList&&userInfo==2"
          >清除数据</button>
          <button
            class="btn btn-default btn-next btn-right-none"
            @click="handleShowAllSDSTablesBtnClick"
            v-if="userInfo==2"
          >全部所得税申报表</button>
          <template v-if="dataPeriod==0">
            <router-link
              :to="{name:'choose-table', params: { zqLocation,nsrsbhIndex: this.nsrsbhIndex,
              qykjzdIndex: this.qykjzdIndex }}"
              class="btn btn-default btn-next btn-right-none"
            >上一步</router-link>
            <button
              class="btn btn-primary btn-next"
              @click="handleBtnNext"
            >{{userInfo==1?"完成申报补救":"下一步"}}</button>
          </template>
          <template v-else-if="dataPeriod==1">
            <router-link
              :to="{ name: 'UploadTableFix', params: { zq,zqLocation,dataPeriod:0,nsrsbhIndex: this.nsrsbhIndex,
              qykjzdIndex: this.qykjzdIndex}}"
              class="btn btn-default btn-next btn-right-none"
            >上一步</router-link>
            <button @click="handleBtnNext2" class="btn btn-primary btn-next">完成申报补救</button>
          </template>

          <!-- <router-link
          :to="{ name: 'upload-table', params: { id:tableCards[0].id,zq:tableCards[0].zq,zqLocation:zqs[0]}}"
          class="btn btn-primary btn-next"
          >下一步</router-link>-->
        </div>
      </sa-footer>
    </div>
    <!--温馨提示弹出框-->
    <el-dialog
      :visible.sync="tipDialogVisible"
      width="576px"
      :show-close="false"
      class="tipDialog"
      top="32vh"
    >
      <div class="inner">
        <img src="@/assets/test/tip.png" class="img" />
        <div class="right-wrapper">
          <div class="title">温馨提示</div>
          <div class="desc">为保证数据准确提取，需尽量保证文件命名标准，若一个Excel内包含多个sheet则sheet页命名需规范</div>
          <button class="btn btn-primary btn-i-get" @click="handleTipDialogBtnClick">知道了</button>
        </div>
      </div>
    </el-dialog>
    <!--温馨提示弹出框-->
    <!--上传错误提示弹出框-->
    <el-dialog
      :visible.sync="correctDialogVisible"
      width="80%"
      :show-close="true"
      class="correctDialog"
      top="1vh"
      @close="handleCorrectDialogClose"
    >
      <SectionTitle>报表匹配</SectionTitle>
      <div class="modify-table-wrapper">
        <modify-table></modify-table>
      </div>
    </el-dialog>
    <!--/上传错误提示弹出框-->
    <!--纠错弹出框-->
    <el-dialog
      :visible.sync="uploadErrorDialogVisible"
      width="800px"
      :show-close="true"
      top="25vh"
      title="抱歉,以下表无法解析"
    >
      <el-table :data="uploadErrors" style="width: 100%">
        <el-table-column prop="userFileName" label="表名"></el-table-column>
        <el-table-column prop="reason" label="异常原因"></el-table-column>
      </el-table>
    </el-dialog>
    <!--/纠错弹出框-->
    <!--上传须知提示弹出框-->
    <el-dialog
      :visible.sync="acknowledgeDialogVisible"
      width="62.5%"
      :show-close="true"
      class="correctDialog"
    >
      <SectionTitle>上传须知</SectionTitle>
      <div class="modify-table-wrapper">
        <acknowledage-tab v-on:acknowledgeDialogVisible="clickbtn" :bankId="bankId"></acknowledage-tab>
      </div>
    </el-dialog>
    <!--/上传须知提示弹出框-->

    <!--数据上传组件-->
    <TestDataUploader
      ref="testDataUploader"
      :bankId="bankId"
      :productID="productID"
      :productName="productName"
      :uuid="uuid"
      :zq="zq"
      :version="version"
    ></TestDataUploader>
    <!--/数据上传组件-->

    <loading
      :active.sync="isLoading"
      backgroundColor="#000"
      :can-cancel="true"
      :is-full-page="fullPage"
      loader="Dots"
      color="#2f54eb"
    ></loading>

    <!--所得税填报选择-->
    <el-dialog
      :visible.sync="showSDSDialog"
      :close-on-click-modal="false"
      width="800px"
      :show-close="false"
      class="correctDialog"
    >
      <SectionTitle>所得税填报选择</SectionTitle>
      <SDSFilter :tables="SDStables" @onDialogSubmit="handleSDSDialogSubmit"></SDSFilter>
    </el-dialog>
    <!--/所得税填报选择-->
    <!-- 补全信息弹窗 -->
    <supple-ment :code="code" :checkParts="checkParts" @submit="submit" @cancel="cancel"></supple-ment>
    <!-- 补全信息弹窗 -->
    <licen ref="opendialog"></licen>
    <add-custom ref="addCustoms" @closeDialogs="closeDialogs" @supplement="supplement" :uuid="uuid"></add-custom>
    <add-hy ref="addHys" @closeDialogsHy="closeDialogsHy" @supplementHy="supplementHy" :uuid="uuid"></add-hy>
    <div v-if="showTables" class="containertables">
      <div class="tables">
        <div class="title">{{balanceName}}</div>
        <div class="closes" @click="deleteTable">×</div>
        <div class="content">
          <el-table :data="tableData" style="width: 100%" height="620">
            <el-table-column prop="subBsDm" label="科目编码"></el-table-column>
            <el-table-column prop="subBsMc" label="科目名称"></el-table-column>
            <el-table-column label="期初余额" align="center">
              <el-table-column prop="beforeBorrow" label="借方" align="right"></el-table-column>
              <el-table-column prop="beforeLoan" label="贷方" align="right"></el-table-column>
            </el-table-column>
            <el-table-column label="本期发生额" align="center">
              <el-table-column prop="currentBorrow" label="借方" align="right"></el-table-column>
              <el-table-column prop="currentLoan" label="贷方" align="right"></el-table-column>
            </el-table-column>
            <el-table-column label="期末余额" align="center">
              <el-table-column prop="afterBorrow" label="借方" align="right"></el-table-column>
              <el-table-column prop="afterLoan" label="贷方" align="right"></el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="process-statusC" v-if="locks">
      <process-status
        :uuid="uuid"
        :nsrshb="$route.params.nsrsbh"
        class="process-statuss"
        :uploadStatus="uploadStatus"
        @closeProcess="closeProcess"
        :classObject="classObject"
        :processInfoStatus="processInfoStatus"
        :processFalse="processFalse"
      ></process-status>
    </div>
  </div>
</template>

<script>
import {
  useAuthorize,
  checkCompany,
  checkNew,
  breezeOuter,
  breezeOuterAi,
} from "@/api/api";
import { cloneDeep } from "lodash";
import BreadCrumb from "@/components/BreadCrumb";
import Uploader from "./components/Uploader";
import TableList from "./components/TableList";
import ModifyTable from "./components/ModifyTable";
import SectionTitle from "@/components/SectionTitle";
import AcknowledageTab from "./components/AcknowledageTab";
import suppleMent from "./components/supplement";
import SDSFilter from "./components/SDSFilter";
import MainHeader from "@/pages/components/MainHeader";
import ProgressDiagram from "@/pages/components/ProgressDiagram";
import Footer from "../ChooseTable/components/Footer";
import TestDataUploader from "../Components/TestDataUploader";
import { getUserTables, clearUploadedTableforTest } from "@/api/table";
// import { clearUploadedTableforTestAi } from "@/api/api";
import {
  clearUploadedTableforTestAi,
  showBalanceDetail,
  useAuthorizeAi,
  progressBar,
  genTestReportAiFix,
} from "@/api/api";
import { mapState } from "vuex";
import { zq2name, previousZq } from "@/utils/helper";
import reportMethod from "../../Test/ChooseTable/components/report-method";
import licen from "./components/licen-dialog";
import addCustom from "@/pages/custom/components/addCustom";
import addHy from "@/pages/custom/components/addHy";
import processStatus from "@/pages/fixProcess/components/processStatus";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      ban: "余额表",
      zzs: "增值税表",
      sds: "所得税表",
      cw: "财务表",
      balanceName: "",
      showTables: false,
      tableData: null,
      tables: {},
      tipDialogVisible: false, //刚开始进入的提示框
      fullPage: true,
      isLoading: false,
      correctDialogVisible: false, //控制手动修正的表的弹窗
      acknowledgeDialogVisible: false,
      uploadErrorDialogVisible: false, //控制无法识别的表的弹窗
      uploadErrors: [], //错误的表名及原因
      templateDownloadURL: "",
      hasItemsInList: false,
      actualZq: 0,
      zqs: [],
      showSDSDialog: false,
      userInfo: sessionStorage.getItem("method") || 1,
      checkParts: false,
      code: 9996,
      index: -1,
      step2Msg: null,
      valueMsg: "",
      area: "",
      bizNo: "",
      radioIndex: "",
      passwordInput: "",
      clickQr: "",
      index1Data: "",
      indexJylsh: "",
      mobile: "",
      mobileCode: "",
      showTableDetail: false,
      locks: false,
      uploadStatus: null,
      classObject: null,
      processInfoStatus: false,
      processFalse: false,
      fixIndex: this.$route.query.index,
      taxName: this.$route.query.taxName,
    };
  },
  components: {
    Loading,
    BreadCrumb,
    Uploader,
    TableList,
    SectionTitle,
    ModifyTable,
    AcknowledageTab,
    MainHeader,
    ProgressDiagram,
    saFooter: Footer,
    TestDataUploader,
    SDSFilter,
    reportMethod,
    suppleMent,
    licen,
    addCustom,
    addHy,
    processStatus,
  },
  props: {
    zq: [Number, String],
    productName: String,
    productID: [Number, String],
    zqLocation: [Number, String],
    bankId: String,
    uuid: String,
    dataPeriod: [Number, String],
    version: [Number, String],
    nsrsbhIndex: [Number, String],
    qykjzdIndex: [Number, String],
    selectvalueIndex: [Number, String],
    taxName: [Number, String],
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID", "SDSSelection"]),
    zqName: function () {
      return zq2name(this.actualZq);
    },
    progress: function () {
      let ret = 2;
      if (this.dataPeriod == 0) {
        ret = 2;
      } else if (this.dataPeriod == 1) {
        ret = 3;
      }
      return ret;
    },
    SDStables() {
      let ret = [];
      if (this.tables.所得税表) {
        ret = this.tables.所得税表.list;
      }
      return ret;
    },
  },
  async mounted() {
    // 如果是报税版本，默认拉表方式
    // if (this.version == 3) {
    //   this.userInfo = 2;
    // }

    await this.init();
    // 如果是报税版本，初始化判断是否应该补全信息
    // if (this.userInfo == 2 || this.version == 3) {
    //   this.checkcompany();
    //   return;
    // }
    let toProcess = sessionStorage.getItem("toProcess");
    if (toProcess) {
      this.progressbar();
      this.locks = true;
    }
  },
  methods: {
    closeProcess() {
      this.$confirm("是否关闭进度条，关闭之后可以前往检测记录查看", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.locks = false;
          clearTimeout(this.n);
          sessionStorage.removeItem("timestamp");
          sessionStorage.removeItem("toProcess");
          sessionStorage.removeItem("toProcess2");
          sessionStorage.removeItem("taxpayerIdProcess");
          this.$router.push({
            name: "test-InvoiceUpload",
            params:{
              uuid:this.uuid,
              nsrsbh:sessionStorage.getItem("nsrsbh")
            }
          });
        })
        .catch(() => {});
    },
    progressbar() {
      var that = this;
      that.locks = true;
      this.n = setTimeout(function cb() {
        let params = {
          taxpayerId:
            sessionStorage.getItem("isTaxpayerId") || that.taxSiteUsername,
          nsrsbm: sessionStorage.getItem("nsrsbh"),
          zq: that.zq,
          type: that.userInfo == 2 ? 1 : 2,
        };
        progressBar(params, localStorage.getItem("authorization")).then(
          (res) => {
            if (res.msgCode == -1) {
              that.$message({
                type: "error",
                message: res.msgInfo + "查询进度出错",
                duration: 10000,
              });
              // that.$router.push({
              //   path: "/",
              // });
              return;
            }
            that.uploadStatus = res.data;
            if (that.isSuccessUpload) {
              that.uploadStatus.sb = 3;
            }
            let timestamp = Date.parse(new Date());

            let add = Number(timestamp) - Number(that.timestamp);
            let data2 = ((add / 2400000) * 100).toFixed(2);
            that.classObject = data2;
            if (that.classObject > 90) {
              that.classObject = 90;
            }
            if (
              add >= 2400000 &&
              that.uploadStatus.fp != 2 &&
              that.uploadStatus.sb != 2 &&
              that.uploadStatus.sb != 3 &&
              that.uploadStatus.sb != 4
            ) {
              that.uploadStatus = {
                fp: "0",
                sb: "0",
                yeb: res.data.yeb,
              };
              return;
            }
            if (add >= 2400000 && that.uploadStatus.fp != 2) {
              that.uploadStatus = {
                fp: "0",
                sb: res.data.sb,
                yeb: res.data.yeb,
              };
              return;
            }
            if (add >= 2400000 && that.uploadStatus.sb != 2) {
              that.uploadStatus = {
                fp: res.data.fp,
                sb: "0",
                yeb: res.data.yeb,
              };
              return;
            }
            if (that.uploadStatus.fp == 2 && that.uploadStatus.sb == 2) {
              that.processInfoStatus = true;
            } else if (that.uploadStatus.fp == 0 && that.uploadStatus.sb == 0) {
              // 申报发票都报错
            } else if (that.uploadStatus.fp == 0 && that.uploadStatus.sb == 3) {
              // 发票报错，申报已上传
            } else if (that.uploadStatus.fp == 0 && that.uploadStatus.sb == 4) {
              // 发票报错，申报未上传
            } else {
              if (sessionStorage.getItem("toProcess")) {
                this.n = setTimeout(cb, 10000);
              }
            }
          }
        );
      }, 0);
    },

    closeDialogsHy() {
      this.userInfo = 1;
    },
    showDetailBox() {
      this.showTableDetail = true;
    },

    showDetailBalance(val) {
      // console.log(val);
      this.balanceName = val.slice(0, 4) + "年余额表";
      // console.log(this.balanceName);
      this.detailZq = val;
      this.showTables = true;
      let params = {
        zq: val,
        uuid:this.uuid
      };
      showBalanceDetail(params).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
          return;
        }
        this.tableData = res.data;
        // let tableData = this.tableData.filter(item=> {
        //   return item.balanceSheetDto
        // })
        // console.log(tableData);
        let newTableData = [];
        for (let i = 0; i < this.tableData.length; i++) {
          newTableData.push(this.tableData[i].balanceSheetDto);
          if (!this.tableData[i].balanceSheetList) continue;
          for (let j = 0; j < this.tableData[i].balanceSheetList.length; j++) {
            newTableData.push(this.tableData[i].balanceSheetList[j]);
          }
        }
        // console.log(newTableData);
        this.tableData = newTableData;
      });
    },
    deleteTable() {
      this.showTables = false;
    },
    showMoreUpload() {
      this.showTableDetail = true;
    },

    // 打开授权协议
    openlicense() {
      this.$refs.opendialog.openDialog();
    },
    //根据会计制度获取不同的报表弹窗
    toast() {
      if (this.dataPeriod == 0 && this.userInfo == 2) {
        this.showSDSFilterDialog();
      }
    },
    // 补全信息确定
    submit() {
      this.checkParts = false;
      // 补全信息后更新企业信息
      this.deleteCache();
    },
    async deleteCache() {
      this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
      this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
      await this.$store.cache.dispatch("loadCompanyRenzheng");
      await this.$store.cache.dispatch("loadCompanyInfo");
      await this.loadList();
      await this.toast();
    },
    // 关闭补全信息
    cancel() {
      this.checkParts = false;
      this.editUser();
      // 如果是报税版，关闭补全信息，则跳转到基础设置页面
      // if (this.version == 3) {
      //   this.$router.go(-1);
      // }
      // 如果是综合体检，关闭补全信息，则跳转到输入税号密码格式
      // if (this.version == 4) {
      //   this.editUser();
      // }
    },
    changeName(val) {
      // console.log(val);
      this.taxSiteUsername = val.name;
      this.taxSitePwd = val.password;
      this.activeRead = val.active;
      this.index = val.index;
      this.step2Msg = val.step2Msg;
      this.valueMsg = val.value;
      this.area = val.province;
      this.bizNo = val.bizNo;
      this.radioIndex = val.radio;
      this.passwordInput = val.passwordInput;
      this.clickQr = val.clickQr;
      this.indexJylsh = val.indexJylsh;
      this.index1Data = val.index1Data;
      this.mobile = val.mobile;
      this.mobileCode = val.mobileCode;
    },
    useauthorize(callback) {
      var uPattern = /^[a-zA-Z0-9]{15,20}$/;
      if (!uPattern.test(this.taxSiteUsername)) {
        this.$message("请输入15-20位账号！");
        return;
      }
      if (!this.taxSitePwd) {
        this.$message("密码不能为空！");
        return;
      }
      if (this.clickQr) {
        this.$alert("请点击验证按钮！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      // 浙江地区校验
      if (!this.valueMsg && this.index == 0) {
        this.$alert("请选择报税人员！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // 宁波地区校验
      if (!this.mobile && this.index == 1) {
        this.$alert("手机号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (!this.mobileCode && this.index == 1) {
        this.$alert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (!this.activeRead) {
        this.$alert("请阅读并勾选授权协议", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // 如果是选择密码方式，密码不能为空
      if (!this.passwordInput && this.index == 0 && this.radioIndex == 2) {
        this.$alert("密码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.clickQr) {
        this.$alert("请先点击验证按钮", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: "正在提交数据,请耐心等待！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 通用授权
      if (this.index == -1) {
        let params = {
          // id: JSON.parse(localStorage.getItem("user")).companyId,
          id: JSON.parse(localStorage.getItem("projectId")),
          authorizeResult: true,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          taxpayerId: this.taxSiteUsername,
          nsrsbm: JSON.parse(localStorage.getItem("user")).nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          projectId: JSON.parse(localStorage.getItem("projectIds")),
        };
        if (this.selectvalueIndex == 1) {
          (params.projectManager = ""), (params.projectManagerId = "");
        } else {
          params.projectId = "";
          params.id = "";
        }
        useAuthorizeAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback();
          } else {
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 浙江地区授权
      if (this.index == 0) {
        let params = {
          // id: JSON.parse(localStorage.getItem("user")).companyId,
          id: JSON.parse(localStorage.getItem("projectId")),
          nsrsbm: JSON.parse(localStorage.getItem("user")).nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          acwTc: this.step2Msg.acw_tc,
          aliyungfTc: this.step2Msg.aliyungf_tc,
          dtlSessionId: this.step2Msg.dtl_SESSION_ID,
          loginType: this.radioIndex == 1 ? "SMRZ" : "PWD",
          additionalUsername: this.valueMsg,
          additionalPassword:
            this.radioIndex == 1 ? this.bizNo : this.passwordInput,
          area: this.area,
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          projectId: JSON.parse(localStorage.getItem("projectIds")),
        };
        if (this.selectvalueIndex == 1) {
          (params.projectManager = ""), (params.projectManagerId = "");
        } else {
          params.projectId = "";
          params.id = "";
        }
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback();
          } else {
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 宁波授权
      if (this.index == 1) {
        let params = {
          // id: JSON.parse(localStorage.getItem("user")).companyId,
          id: JSON.parse(localStorage.getItem("projectId")),
          nsrsbm: JSON.parse(localStorage.getItem("user")).nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          taxpayerId: this.taxSiteUsername.trim(),
          taxSiteUsername: this.taxSiteUsername.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          legalMobile: this.mobile.trim(),
          dzswjTgc: this.index1Data.dzswj_TGC,
          jylsh: this.indexJylsh,
          jsessionid: this.index1Data.jsessionid,
          area: this.area,
          captcha: this.mobileCode.trim(),
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          projectId: JSON.parse(localStorage.getItem("projectIds")),
        };
        if (this.selectvalueIndex == 1) {
          (params.projectManager = ""), (params.projectManagerId = "");
        } else {
          params.projectId = "";
          params.id = "";
        }
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback();
          } else {
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 四川授权
      if (this.index == 2) {
      }
    },
    // 判断是否需要补全信息，如果是返回1，走checkAmount
    checkcompany() {
      let res = {
        msgCode: 1,
      };
      this.checkAmount(res);
    },
    // 如果不需要补全信息，则请求表格接口，然后显示表格信息
    async checkAmount(res) {
      if (res.msgCode == 1) {
        if (this.dataPeriod == 0 && this.userInfo == 2) {
          await this.loadList();
          await this.showSDSFilterDialog();
        }
      }
    },
    // 切换上传方式
    async editUser(val) {
      // this.userInfo = val;
      // if(this.userInfo == 1) this.userInfo = 1;
      // if(this.userInfo == 2) this.userInfo = 2;
      // this.userInfo == 1 ? (this.userInfo = 2) : (this.userInfo = 1);
      // sessionStorage.setItem("method", this.userInfo);
      // 综合体检方式才有切换上传按钮，点击切换上传按钮之后，去判断是否需要补全信息
      // if (this.userInfo == 2) {
      // if (this.dataPeriod == 0 && this.userInfo == 2) {
      // await this.checkcompany();
      // console.log(this.selectvalueIndex);
      let info = JSON.parse(localStorage.getItem("projectCustomInfo"));
      if (
        this.selectvalueIndex == 2 &&
        sessionStorage.getItem("nsrsbh") == "1111"
      ) {
        this.$refs.addCustoms.openDetail();
      } else if (this.selectvalueIndex == 1 && !info.consumerEntity) {
        this.$refs.addHys.openDetail();
      } else if (this.selectvalueIndex == 1 && !info.consumerEntity.industry) {
        this.$refs.addHys.openDetail();
      } else {
        this.userInfo = val;
        sessionStorage.setItem("method", this.userInfo);
        await this.checkcompany();
      }
      // }
      // }
      //首次进入显示温馨提示
      // const hideWenxinTip = localStorage.getItem("hideWenxinTip");
      // if (!hideWenxinTip) {
      //   this.tipDialogVisible = true;
      // }
    },

    supplement(val) {
      sessionStorage.setItem("qykjzd", val.kjzd);
      sessionStorage.setItem("nsrsbh", val.nsrsbh);
      this.nsrsbhIndext = val.nsrsbh;
      this.qykjzdIndext = val.kjzd;
      this.userInfo = 2;
      sessionStorage.setItem("method", this.userInfo);
      this.checkcompany();
    },

    supplementHy() {
      this.userInfo = 2;
      sessionStorage.setItem("method", this.userInfo);
      this.checkcompany();
    },

    closeDialogs() {
      this.userInfo = 1;
    },

    handleShowAllSDSTablesBtnClick() {
      this.$store.commit("UploadTable/setSDSSelection", {
        SDSlist: this.tables.所得税表.list,
      });
    },
    handleSDSDialogSubmit() {
      this.showSDSDialog = false;
    },
    //第一阶段显示所得税填报选择
    showSDSFilterDialog() {
      if (this.SDSSelection === null) {
        this.showSDSDialog = true;
      }
    },
    checkIfAllFiltedTableFilled() {
      //把所有列表展平
      const tables = [];
      for (const key in this.tables) {
        if (this.tables.hasOwnProperty(key)) {
          const tablesInkey = this.tables[key].list;
          for (const table of tablesInkey) {
            tables.push(table);
          }
        }
      }
      return this.SDSSelection.every((aSDSSelection) => {
        const templateId = aSDSSelection.templateId;
        const found = tables.find((table) => {
          return templateId == table.templateId && table.status != "未上传";
        });
        return !!found;
      });
    },
    //本期的下一步按钮
    handleBtnNext() {
      if (this.userInfo == 1 && this.version == 4) {
        this.useauthorize(() => {
          localStorage.setItem("taxSiteUsername", this.taxSiteUsername);
          this.timestamp = Date.parse(new Date());
          sessionStorage.setItem("toProcess", 100);
          // 启动进度条
          this.progressbar();
        });

        return;
      }

      //如果选择所得税数据表数量不等于所有所得税数据表的数量 并且 不是每张选择的所得税都被填了 则提示
      if (
        this.SDSSelection &&
        this.tables.所得税表.list.length != this.SDSSelection.length &&
        !this.checkIfAllFiltedTableFilled()
      ) {
        this.$confirm(
          "检测到您勾选所得税填报，未全部填写完成是否继续填写",
          "温馨提示",
          {
            confirmButtonText: "下一步",
            cancelButtonText: "继续填写",
            type: "warning",
          }
        ).then(() => {
          this.$router.push({
            name: "UploadTableFix",
            params: {
              zq: this.zq,
              zqLocation: this.zqLocation,
              dataPeriod: 1,
              nsrsbhIndex: this.nsrsbhIndex,
              qykjzdIndex: this.qykjzdIndex,
            },
          });
        });
      } else {
        this.$router.push({
          name: "UploadTableFix",
          params: {
            zq: this.zq,
            zqLocation: this.zqLocation,
            dataPeriod: 1,
            nsrsbhIndex: this.nsrsbhIndex,
            qykjzdIndex: this.qykjzdIndex,
          },
        });
      }
    },
    //上期的下一步按钮点击
    handleBtnNext2() {
      this.timestamp = Date.parse(new Date());
      sessionStorage.setItem("toProcess", 100);
      this.checkOld();
      return;
      // return;
      // 模拟跳转余额标
      // if (this.userInfo == 1) {
      //   this.useauthorize(() => {
      //     localStorage.setItem("taxSiteUsername", this.taxSiteUsername);
      //     this.$router.push({
      //       // name: "test-invoice",
      //       // params: { userName: this.taxSiteUsername }
      //       // 将账号带过去
      //       // Ai事务所先跳余额表
      //       name: "test-Balance",
      //       params: { userName: this.taxSiteUsername },
      //     });
      //   });
      //   return;
      // }

      // 模拟跳转余额标

      //如果选择所得税数据表数量不等于所有所得税数据表的数量 并且 不是每张选择的所得税都被填了 则提示

      if (
        this.SDSSelection &&
        this.tables.所得税表.list.length != this.SDSSelection.length &&
        !this.checkIfAllFiltedTableFilled()
      ) {
        this.$confirm(
          "检测到您勾选所得税填报，未全部填写完成是否继续填写",
          "温馨提示",
          {
            confirmButtonText: "下一步",
            cancelButtonText: "继续填写",
            type: "warning",
          }
        ).then(() => {
          //如果是发票版或者定制版则调招到发票检测页面
          if (this.version > 3) {
            this.$router.push({
              name: "test-invoice",
            });
          } else {
            //否则直接开始上传流程
            this.$refs["testDataUploader"].handleBtnNext2Click();
          }
        });
      } else {
        //如果是发票版或者定制版则调招到发票检测页面
        if (this.version > 3) {
          this.$router.push({
            name: "test-invoice",
            // Ai事务所跳转余额表
            // name: "test-Balance",
            params: { userName: this.taxSiteUsername },
          });
        } else {
          //否则直接开始上传流程
          this.$refs["testDataUploader"].handleBtnNext2Click();
        }
      }
    },

    checkOld() {
      let data = this.$store.state;
      const loading = this.$loading({
        lock: true,
        text: "正在提交数据！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      genTestReportAiFix({
        bankId: this.bankId,
        goodsId: this.productID,
        kjzd: sessionStorage.getItem("qykjzd"),
        token: localStorage.getItem("authorization"),
        nsrsbh: sessionStorage.getItem("nsrsbh"),
        tag: this.version,
        id: "123",
        uuid: this.uuid,
        type: "",
        zq: this.zq,
        invoice_nsrsbh: this.taxName,
        invoice_company_name: "",
        invoice_email: "",
        invoice_phone: "",
        isAutoData: 1,
        isUpdateResultList: 1,
      }).then((res) => {
        if (res.msgCode == 1) {
          this.timestamp =
            sessionStorage.getItem("timestamp") || Date.parse(new Date());
          sessionStorage.setItem("timestamp", this.timestamp);
          sessionStorage.setItem("toProcess", 100);
          this.progressbar();

          sessionStorage.setItem("taxpayerIdProcess", this.taxPayerID);

          localStorage.removeItem("taxSiteUsername");
          loading.close();
          //设置permissionID
          this.$store.commit("UploadTable/setPermissionID", {
            permissionID: "",
          });
          // this.$router.push({
          //   name: "test-InvoiceUpload"
          // });
        } else if (res.msgCode == -1) {
          loading.close();
          //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
          this.$alert(res.msgInfo, "错误", {
            confirmButtonText: "确定",
            type: "error",
            callback: (action) => {
              this.$router.push({ name: "homePage" });
            },
          });
        } else {
          loading.close();
          this.$message({
            message: res.msgInfo,
            type: "error",
            duration: 5000,
            callback: (action) => {
              alert("dasdsa");
            },
          });
        }
      });
    },

    async init() {
      //如果没有permission id就退出
      // if (!this.$store.state.UploadTable.permissionID) {
      //   this.$router.push({ name: "homePage" });
      // }

      this.isLoading = true;

      if (this.dataPeriod == 1) {
        //上个征期
        this.actualZq = previousZq(this.zq);
        this.zqs.push(this.zq);
        this.zqs.push(this.actualZq);
      } else if (this.dataPeriod == 0) {
        //当前征期
        this.actualZq = this.zq;
      }

      this.$store.commit("UploadTable/setZq", { zq: this.actualZq });
      this.$store.commit("UploadTable/setZqList", { zq: this.zq });

      await this.$store.cache.dispatch("loadCompanyInfo");
      await this.loadList();
      this.$store.commit("UploadTable/setProduct", {
        name: this.productName,
        id: this.productID,
        bankID: this.bankId,
      });

      //下载模板的连接
      switch (sessionStorage.getItem("qykjzd")) {
        case "qykjzd":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%88%B6%E5%BA%A6.zip";
          break;
        case "qykjzz":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%87%86%E5%88%99.zip";
          break;
        case "xqykjzz":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E5%B0%8F%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%87%86%E5%88%99.zip";
          break;
      }

      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    clickbtn() {
      this.acknowledgeDialogVisible = false;
    },
    handleTipDialogBtnClick() {
      this.tipDialogVisible = false;
      localStorage.setItem("hideWenxinTip", true);
    },
    listChanged() {
      this.loadList();
    },
    //清空所有已经上传的报表
    handleBtnPurgeDataClicker() {
      this.$confirm(`所有文件将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await clearUploadedTableforTestAi(
          // this.companyInfo.nsrsbm,
          sessionStorage.getItem("nsrsbh"),
          this.actualZq,
          localStorage.getItem("authorization"),
          this.uuid
        );

        if (result.msgCode == 1) {
          this.$emit("listChanged");
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.loadList();
        } else {
          this.$message.error(result.msgInfo);
        }
      });
    },
    //修正表格的弹出框
    showCorrectDialog() {
      this.correctDialogVisible = true;
    },
    //错误原因弹出框
    showUploadErrorDialog() {
      this.uploadErrorDialogVisible = true;
    },
    submitData() {
      this.correctDialogVisible = true;
    },
    async loadList() {
      //bankId, qykjzd, nsrsbh, zq
      // const tables = await getUserTables(
      //   this.bankId,
      //   this.companyInfo.kjzd,
      //   this.companyInfo.nsrsbm,
      //   this.actualZq,
      //   false
      // );
      // AI事务所

      // const tables = await getUserTables(
      //   this.bankId,
      //   this.qykjzdIndex,
      //   this.nsrsbhIndex,
      //   this.actualZq,
      //   false
      // );
      // this.showTableDetail = true;
      // 利用缓存
      const tables = await getUserTables(
        this.bankId,
        this.version,
        // "qykjzd",
        sessionStorage.getItem("nsrsbh"),
        // "1594002474322",
        this.actualZq,
        this.uuid,
        false
      );
      let tableNumUploadTotal =
        tables.data[this.ban].uploadNum +
        tables.data[this.zzs].uploadNum +
        tables.data[this.sds].uploadNum +
        tables.data[this.cw].uploadNum;
      if (tableNumUploadTotal > 0) {
        this.showTableDetail = true;
      }
      //过滤未上传的表格
      this.tables = {};

      for (const grpName in tables.data) {
        const grpData = tables.data[grpName];
        const newGrp = [];
        for (const table of grpData.tableInfo) {
          //if (table.uploadStatus != "未上传") {
          const {
            tableNameCn,
            tableNameEn: templateId,
            uploadStatus: status,
            tableNum,
            zq
          } = table;

          const item = {
            name: `${tableNameCn}`,
            status,
            zq,
            templateId,
            tableNum,
          };

          newGrp.push(item);
          //}
        }

        if (newGrp.length > 0) {
          this.tables[grpName] = {
            list: newGrp,
            totalNum: grpData.totalNum,
            uploadNum: grpData.uploadNum,
          };
        }
      }
      if (Object.keys(this.tables).length > 0) {
        this.hasItemsInList = true;
      } else {
        this.hasItemsInList = false;
      }
    },
    async onUpload(file, data) {
      //如果有错误的表格则弹出弹出框
      if (data.data.errorData.length > 0) {
        this.uploadErrors = data.data.errorData;
        this.showUploadErrorDialog();
      }

      const groups = data.data.succData;

      //根据上传的excel构建本地对应的数组

      //上传文件后需要纠错的表格信息
      const tables4correction = [];

      for (const groupName in groups) {
        const tables = groups[groupName]; //分组里包含的表

        for (const table of tables) {
          const status = table.data.uploadStatus;
          const { templateId, sysFileName, userFileName } = table;

          if (status === "待修改") {
            table.data.templateId = templateId;
            table.data.groupName = groupName;
            table.data.sysFileName = sysFileName;
            table.data.userFileName = userFileName;

            tables4correction.push(table.data);
          }
        }
      }
      if (tables4correction.length > 0) {
        this.$store.commit("UploadTable/setCorrectionTableData", {
          tables4correctionData: tables4correction,
        });
        this.showCorrectDialog();
      }
      this.loadList();
    },
    handleCorrectDialogClose() {
      this.loadList();
    },
  },
  watch: {
    $route: function (to, from) {
      window.scrollTo(0, 0);
      this.init();
      // 拿到目标参数 to.query.id 去再次请求数据接口
      //this.loadPageData(to.query.id);
      //this.dataPeriod;
      //debugger;
    },
  },
  destroyed() {
    this.locks = false;
    sessionStorage.removeItem("timestamp");
    sessionStorage.removeItem("toProcess");
    sessionStorage.removeItem("taxpayerIdProcess");
    clearTimeout(this.n);
  },
};
</script>

<style lang="stylus" scoped>
.modify-table-wrapper {
  margin-top: 30px;
}

.correctDialog {
  padding: 0;

  >>> .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }
}

.tipDialog {
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;

    .img {
      width: 174px;
      flex-shrink: 0;
    }

    .right-wrapper {
      margin-left: 50px;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #062340;
      }

      .desc {
        margin-top: 16px;
        color: #6b7897;
        font-size: 16px;
        line-height: 1.5;
      }

      .btn-i-get {
        height: 40px;
        line-height: 40px;
        margin-top: 17px;
      }
    }
  }
}

.the-title {
  margin: 30px 30px 30px 0;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 20px;
  padding-top: 0;
  margin-top: 0;
}

.upload-footer {
  margin-top: 16px;

  .tip {
    float: left;
    color: #FAAD14;
    font-size: 14px;
  }

  .acknowlege, .btn-download {
    background: #fff;
    font-size: 14px;
    float: right;
    color: #2F54EB;
    vertical-align: middle;

    .icon {
      vertical-align: middle;
      font-size: 22px;
    }
  }

  .acknowlege {
    margin-right: 48px;
    cursor: pointer;
    float: right;
  }

  .acknowlege:hover, .btn-download:hover {
  }
}

.table-list {
  margin-top: 18px;
  padding-left: 24px;
}

.footer {
  margin-top: 20px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 10px;
  }

  .btn-purge-data {
    color: #ff6464;
  }
}

// >>> .el-dialog__header {
// padding: 0;
// }

// >>> .section-title {
// margin-top: 10px;
// }
.page-wrapper {
  padding-bottom: 84px;
}

.progressdis {
  // position: absolute;
  width: 10%;
  // margin: 24px auto 45px;
  margin-left: 100px;
  margin-top: 2px;
}

.btn-right-none {
  margin-right: 6px !important;
}

// .report-method {
// margin: 20px;
// }
.nomargin {
  margin: 0 30px;
  padding: 0;
}

.main-top {
  display: flex;
  justify-content: space-between;
}

.content-body-flex {
  display: flex;
  align-item: center;
  justify-content: space-between;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 24px;
  background: rgba(255, 255, 255, 1);
  // padding: 0 20px;
  // margin: 0 20px;
}

.content-body-flexr {
  display: flex;
  align-item: center;
  font-size: 14px;
  color: rgba(47, 84, 235, 1);
}

.content-top-1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.content-top-2 {
  margin-right: 24px;
}

.content-body-tab {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 22px;
}

.content-body-tabl, .content-body-tabr {
  width: 174px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 18px 18px 0px 0px;
  cursor: pointer;
}

.content-body-tabl {
  margin-right: 24px;
}

.zqLocation {
  color: #2F54EB;
}

.arrow-icon {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 28px;
}

.uploadFlex {
  display: flex;
  width: 100%;
}

.uploadFlexLeft {
  width: 25%;
  border-right: 1px solid rgba(217, 217, 217, 1);
  padding-right: 32px;
  box-sizing: border-box;
}

.uploadFlexRight {
  width: 75%;
}

.uploadFlexLefts {
  border-bottom: none;
  margin-bottom: 0;
}

.tables {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -550px;
  margin-top: -300px;
  width: 1158px;
  height: 700px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 2000;
  padding-bottom: 30px;
  overflow-y: auto;
}

.content {
  width: 1136px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  text-align: center;
  margin: 24px 0;
}

.closes {
  position: absolute;
  right: 25px;
  top: 17px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.containertables {
  // display: fixed;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // background: rgba(0, 0, 0, 0.04);
}

.process1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.process2 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.processLImg {
  position: relative;
  bottom: 1px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.processContainer {
  display: flex;
  align-item: center;
  position: relative;
  top: 6px;
}

.process-status {
  width: 120px;
  height: 2px;
  background: rgba(217, 217, 217, 1);
  position: relative;
  top: 8px;
  margin: 0 24px;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.process-statuss {
  position: fixed;
  top: 20vh;
  left: 50%;
  margin-left: -400px;
}

.process-statusC {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2001;
}
</style>
