export const itemList = [
    {
        name: "+",
        id: 0,
        key: 0
    },
    {
        name: "-",
        id: 1,
        key: 1
    },
    {
        name: "*",
        id: 2,
        key: 2
    },
    {
        name: "/",
        id: 3,
        key: 3
    },
    // {
    //     name: "<",
    //     id: 4,
    //     key: 4
    // },
    // {
    //     name: ">",
    //     id: 5,
    //     key: 5
    // },
    // {
    //     name: "=",
    //     id: 6,
    //     key: 6
    // },
    {
        name: "(",
        id: 7,
        key: 7
    },
    {
        name: ")",
        id: 8,
        key: 8
    },
    // {
    //     name: "and",
    //     id: 9,
    //     key: 9
    // },
    // {
    //     name: "or",
    //     id: 10,
    //     key: 10
    // },
    // {
    //     name: "not",
    //     id: 11,
    //     key: 11
    // },
    {
        name: "0",
        id: 12,
        key: 12
    },
    {
        name: "1",
        id: 13,
        key: 13
    },
    {
        name: "2",
        id: 14,
        key: 14
    },
    {
        name: "3",
        id: 15,
        key: 15
    },
    {
        name: "4",
        id: 16,
        key: 16
    },
    {
        name: "5",
        id: 17,
        key: 17
    },
    {
        name: "6",
        id: 18,
        key: 18
    },
    {
        name: "7",
        id: 19,
        key: 19
    },
    {
        name: "8",
        id: 20,
        key: 20
    },
    {
        name: "9",
        id: 21,
        key: 21
    },
    {
        name: ".",
        id: 22,
        key: 22
    },
    {
        name: "清空",
        id: 23,
        key: 23
    },
]

export const itemListBan = [
    {
        name: "+",
        id: 0,
        key: 0
    },
    {
        name: "-",
        id: 1,
        key: 1
    },
    {
        name: "*",
        id: 2,
        key: 2
    },
    {
        name: "/",
        id: 3,
        key: 3
    },
    {
        name: "<",
        id: 4,
        key: 4
    },
    {
        name: ">",
        id: 5,
        key: 5
    },
    {
        name: "(",
        id: 7,
        key: 7
    },
    {
        name: ")",
        id: 8,
        key: 8
    },
    {
        name: " && ",
        id: 9,
        key: 9
    },
    {
        name: " || ",
        id: 10,
        key: 10
    },
    {
        name: "==",
        id: 6,
        key: 6
    },
    {
        name: "!=",
        id: 11,
        key: 11
    },
    {
        name: "<=",
        id: 31,
        key: 31
    },
    {
        name: ">=",
        id: 32,
        key: 32
    },
    {
        name: "1",
        id: 13,
        key: 13
    },
    {
        name: "2",
        id: 14,
        key: 14
    },
    {
        name: "3",
        id: 15,
        key: 15
    },
    {
        name: "4",
        id: 16,
        key: 16
    },
    {
        name: "5",
        id: 17,
        key: 17
    },
    {
        name: "6",
        id: 18,
        key: 18
    },
    {
        name: "7",
        id: 19,
        key: 19
    },
    {
        name: "8",
        id: 20,
        key: 20
    },
    {
        name: "9",
        id: 21,
        key: 21
    },
    {
        name: "0",
        id: 12,
        key: 12
    },
    {
        name: ".",
        id: 22,
        key: 22
    },
    {
        name: "null",
        id: 33,
        key: 33
    },
    {
        name: "清空",
        id: 23,
        key: 23
    },
]