<template>
  <div class="page-wrapper">
    <div class="invoice-content">
      <img class="invoice-img" src="@/assets/result/no-invoice.png" alt />
      <div class="invoice-text" v-if="statusRepord == '00'">检测报告生成需T+1个工作日完成，请耐心等待</div>
      <div class="invoice-upload" v-if="statusRepord == '00'">报告生成后可在“检测记录”内查看下载111</div>
      <div class="invoice-text" v-if="statusRepord == '99'">授权失败</div>
      <div class="invoice-btn ease" @click="toTestLog">前往检测记录</div>
    </div>
  </div>
</template>

<script>
import { resultSuccess } from "@/api/api";
// resultSuccess
import Loading from "vue-loading-overlay";
import { mapState } from "vuex";
import { zq2name } from "@/utils/helper";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import ProgressDiagram from "@/pages/components/ProgressDiagram";

import { localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_CN", zh_CN);

//在加载的样式
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
    BreadCrumb,
    MainHeader,
    ProgressDiagram,
  },
  props: {
    bankId: String,
    productID: [String, Number],
    productName: String,
    zqLocation: [String, Number],
    uuid: String,
    zq: String,
    version: [Number, String],
  },
  data: function () {
    return {
      isLoading: false,
      fullPage: true,
      companyName: "",
      email: "",
      taxPayerID: "",
      mobile: "",
      userInfo: sessionStorage.getItem("method") || 1,
      haveRecord: true,
      statusRepord: '00' //99失败
    };
  },
  mounted() {
    // "http://localhost:8080/tools/analysisSuccess?message=QVN2SFQy…Z1FtV0hmMXhGN0dDN09tK3A5Y3NuTHNZdGhVUkdEOVQ0N0RlNStmdWpINHRm"
    // console.log(window.location.href);
    let url = window.location.href;
    // console.log(url.indexOf("message="));
    let newUrl = url.substr(url.indexOf("message=") + 8);
    // console.log(newUrl);
    let params = {
      encryptionString: newUrl,
    };
    resultSuccess(params).then((res) => {
      // console.log(res);
      this.statusRepord = res.data;
    });
    // this.closeMenu();
    console.log(window.location.href);
  },

  methods: {
    toTestLog() {
      // console.log(this);
      // this.$router.push({ name: "toolsAudit" });
      this.closeMenu();
    },
    closeMenu() {
      window.parent.postMessage({
        cmd: "jump",
        params: {
          val: 1,
        },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.page-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
//   padding-bottom: 100px;
}

.form-wrapper {
  display: flex;
  margin-top: 24px;

  .left-wrapper {
    width: 50%;
  }

  .item {
    color: #666;
    font-size: 14px;
    margin-top: 16px;

    .required-icon {
      color: #F5222D;
      margin-right: 4px;
    }

    .label {
      width: 150px;
      display: inline-block;
      text-align: right;
      margin-right: 8px;
      vertical-align: top;
      line-height: 36px;
    }

    .input {
      border: 1px solid #D9D9D9;
      width: 400px;
      height: 36px;
      line-height: 36px;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 3px;
      outline: none;
    }

    .input-wrapper {
      display: inline-block;
    }

    .big {
      font-size: 24px;
      margin: 16px auto;
      color: #333;
    }
  }

  .info {
    margin-left: 50px;
    margin-top: 25px;
  }
}

.progressdis {
  width: 90%;
  margin: 24px auto;
}

.invoice-content {
  text-align: center;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
//   margin: 0 30px;
//   margin-top: 16px;
}

.invoice-img {
  margin-top: 80px;
}

.invoice-text {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.invoice-upload {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.invoice-btn {
  width: 170px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  margin-top: 24px;
  cursor: pointer;
}

.invoice-btn:hover {
  background: rgba(47, 64, 235, 1);
}

.ease {
  transition: all 0.3s;
}
</style>
