import { render, staticRenderFns } from "./Risk.vue?vue&type=template&id=2e2814c3&scoped=true&"
var script = {}
import style0 from "./Risk.vue?vue&type=style&index=0&id=2e2814c3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e2814c3",
  null
  
)

export default component.exports