<template>
  <div class="bills">
    <div class="bill-content">
      <div class="bill-detail-content">
        <div class="bill-time-filter">
          <div>发票类型：</div>
          <div class="good-type good-type-bill">
            <el-select
              v-model="iType"
              placeholder="请选择"
              @change="searchItem"
              :disabled="getTable"
            >
              <el-option
                v-for="item in billtypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>选择日期：</div>
          <div class="good-type-data">
            <el-date-picker
              v-model="billTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :disabled="getTable"
              @change="timefilter"
            >
            </el-date-picker>
          </div>
          <div
            class="search-month search-month-first"
            :class="{ 'month-active': isActive == item.code }"
            @click="nowYear(item.code)"
            v-for="(item, index) in yearBox"
          >
            {{ item.name }}
          </div>
          <div
            class="search-month"
            :class="{ 'month-active': isActive == 999 }"
            @click="allResult"
            v-if="yearBox && yearBox.length > 0"
          >
            全部
          </div>
          <div class="item-goods">
            <div class="select-group select-group-price">
              <div>金额(元)：</div>
              <div>
                <el-input
                  clearable
                  v-model="amountFrom"
                  :disabled="getTable"
                  placeholder="请输入内容点击查询按钮"
                ></el-input>
                <span class="item-goods-right">至</span>
                <el-input
                  clearable
                  v-model="amountTo"
                  :disabled="getTable"
                  placeholder="请输入内容点击查询按钮"
                ></el-input>
              </div>
            </div>
          </div>
          <div class="item-goods">
            <div class="select-group">
              <div>货品和劳务名称：</div>
              <div>
                <el-input
                  clearable
                  v-model="goodsFilter"
                  placeholder="请输入内容点击查询按钮"
                  :disabled="getTable"
                ></el-input>
              </div>
              <div class="more-class" @click="moreClass">编号</div>
            </div>
          </div>
          <div class="good-type-rate">税率：</div>
          <div class="good-type good-type-calc">
            <el-cascader
              v-model="goodtype"
              :options="billtypeListRate"
              :props="props"
              clearable
              :disabled="getTable"
            ></el-cascader>
          </div>
          <div class="item-goods-button">
            <el-button type="primary" @click="newSearchItem">查询</el-button>
          </div>
        </div>
        <div class="bill-time-table">
          <div class="left">
            <div class="title">
              <div class="title-company">公司名称：</div>
              <div class="title-input">
                <el-input
                  clearable
                  v-model="companyName"
                  placeholder="请输入内容"
                  @input="namefilterclear"
                ></el-input>
              </div>
            </div>
            <div v-loading="getTableCompany">
              <div class="content-c" v-if="billData && billData.length > 0"
                   :id="'contents' + modelId">
                <i class="el-icon-top el-icon-tops" @click="toTop"></i>
                <div class="contents" v-for="(item, index) in billData">
                  <div
                    class="content-item"
                    :class="{
                      contentAct: contentIndex == index,
                      'content-item-index': index == 0,
                    }"
                    @click="item2Act(item, index)"
                  >
                    <div class="company price">
                      {{ item.name }}
                    </div>
                    <div class="info">
                      <div class="price">
                        金额：{{ item.totalAmount }}元
                        <span class="copy-price" @click.stop="copyText(item.totalAmount)"
                        >复制</span
                        >
                      </div>
                      <div class="price">
                        税额：{{ item.taxAmount }}元<span
                        class="copy-price"
                        @click.stop="copyText(item.taxAmount)"
                      >复制</span
                      >
                      </div>
                      <div class="price">
                        价税合计：{{ item.amount }}元<span
                        class="copy-price"
                        @click.stop="copyText(item.amount)"
                      >复制</span
                      >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div v-show="getMore" class="get-moredata" @click="getMoreData">
                加载更多
              </div> -->
              </div>
              <div v-else class="content-flex">
                <div>暂无数据</div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="item-table">
              <el-table
                :data="billTableDataItem0"
                max-height="600px"
                style="width: 100%"
                v-loading="getTable"
                :header-cell-style="{ background: '#F7F8FA', 'text-align': 'center' }"
                border
              >
                <el-table-column
                  width="120"
                  prop="billingDate"
                  label="开票日期"
                  sortable
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  width="140"
                  prop="invoiceCode"
                  label="发票代码"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  width="140"
                  prop="invoiceNumber"
                  label="发票号码"
                  align="center"
                >
                </el-table-column>
                <el-table-column prop="invoiceType" label="发票种类" width="230">
                  <template slot-scope="scope">
                    <span>{{
                        scope.row.invoiceType == "-"
                          ? "-"
                          : billTypeArray[scope.row.invoiceType]
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="invoiceStatus"
                  label="发票状态"
                  width="130"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                        scope.row.invoiceStatus == "-"
                          ? "-"
                          : billStatusListArray[scope.row.invoiceStatus]
                      }}</span>
                  </template>
                </el-table-column>
                <el-table-column width="320" prop="productName" label="货品和劳务名称">
                </el-table-column>
                <el-table-column
                  width="160"
                  prop="productTotalAmount"
                  label="金额（元）"
                  sortable
                  :sort-method="sortproductTotalAmount"
                  align="right"
                >
                </el-table-column>
                <el-table-column
                  width="120"
                  prop="productTaxAmount"
                  label="税额（元）"
                  sortable
                  :sort-method="sortproductTaxAmount"
                  align="right"
                >
                </el-table-column>
                <el-table-column
                  width="150"
                  prop="productAmount"
                  label="价税合计（元）"
                  sortable
                  :sort-method="sortproductAmount"
                  align="right"
                >
                </el-table-column>
                <el-table-column
                  width="90"
                  prop="productTaxRate"
                  label="税率"
                  sortable
                  :sort-method="sortproductTaxRate"
                  align="right"
                >
                </el-table-column>
                <el-table-column
                  width="220"
                  prop="purchaserName"
                  label="购买方名称"
                  align="right"
                >
                </el-table-column>
                <el-table-column
                  width="220"
                  prop="salesName"
                  label="销售方名称"
                  align="right"
                >
                </el-table-column>
                <el-table-column width="320" prop="remark" label="备注" align="center">
                </el-table-column>
              </el-table>
              <div class="item-pagination">
                <el-pagination
                  @size-change="handleSizeChange0"
                  @current-change="handleCurrentChange0"
                  :current-page="pageNum0"
                  :page-size="pageSize0"
                  layout="prev, pager, next, total, jumper, sizes"
                  :page-sizes="[10, 20, 50, 100, 200, 300]"
                  :total="totalNumber0"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <classification
        :ClassData0="ClassSearchList0"
        :ClassData1="ClassSearchList1"
        ref="block"
        @classChange="classChange"
        :classValueIndex="iType"
      ></classification>
    </div>
  </div>
</template>

<script>
import {
  getEntryClass,
  getInvoiceDetailCompanyList,
  getInvoiceDetailPage, getInvoiceType,
  getInvoiceYearBox,
  getSalesClass,
} from "@/api/api";
import classification from "./classification.vue";
import autoTableHeight from "@/pages/Result/ThreeYearResult/bill/utils/help.js";

import clearAllChildren from "@/pages/Result/ThreeYearResult/bill//clearAllChildren.js";

const moment = require("moment");

export default {
  components: {
    classification,
  },

  props: {
    uuid: String,
    taxNo: String,
    modelId: String,
  },

  computed: {
    tableHeight() {
      return autoTableHeight(); // 2. 调用函数，返回高度
    },
  },

  data() {
    return {
      amountFrom: "",
      amountTo: "",
      getMore: true,
      billPage: 1,
      ClassSearchList: [],
      abnormalFilter1: [],
      abnormalFilter2: [],
      taxRateFilter: [],
      moreGoodsIndex: false,
      goodsFilter: "",
      radioTypeCopy: 1,
      getTableCompany: false,
      radioType: 1,
      optionsValueList: [],
      optionsValue: [
        // [1],
        ["司法风险", "法律诉讼"],
        ["司法风险", "被执行人"],
        ["司法风险", "失信人"],
        ["司法风险", "严重违法"],
        ["司法风险", "行政处罚"],
        ["司法风险", "限制消费令"],
        ["税务风险", "税收违法"],
        ["税务风险", "欠税"],
        ["税务风险", "纳税信用等级低"],
        ["经营风险", "经营状态异常"],
        ["经营风险", "经营异常"],
        ["经营风险", "社保人数异常"],
        ["经营风险", "成立不满6个月"],
        ["经营风险", "个人独资企业"],
        ["经营风险", "个体工商户"],
      ],
      props: {multiple: true, expandTrigger: "hover"},
      options: [
        {
          value: "司法风险",
          label: "司法风险",
          children: [
            {value: "法律诉讼", label: "法律诉讼"},
            {value: "被执行人", label: "被执行人"},
            {value: "失信人", label: "失信人"},
            {value: "严重违法", label: "严重违法"},
            {value: "行政处罚", label: "行政处罚"},
            {value: "限制消费令", label: "限制消费令"},
          ],
        },
        {
          value: "税务风险",
          label: "税务风险",
          children: [
            {value: "税收违法", label: "税收违法"},
            {value: "欠税", label: "欠税"},
            {value: "纳税信用等级低", label: "纳税信用等级低"},
          ],
        },
        {
          value: "经营风险",
          label: "经营风险",
          children: [
            {value: "经营状态异常", label: "经营状态异常"},
            {value: "经营异常", label: "经营异常"},
            {value: "社保人数异常", label: "社保人数异常"},
            {value: "成立不满6个月", label: "成立不满6个月"},
            {value: "个人独资企业", label: "个人独资企业"},
            {value: "个体工商户", label: "个体工商户"},
          ],
        },
        // ],
        // },
      ],
      riskTypeVisible: false,
      activeIndexList: ["采购方名称", "销售方名称"],
      MaxHeightTable: "100vh",
      getTable: false,
      billTypeArray: {
        "01": "增值税专用发票  专用发票",
        "02": "货物运输业增值税专用发票  专用发票",
        "03": "机动车销售统一发票  其他发票",
        "04": "增值税普通发票  普通发票",
        "08": "电子专用发票  专用发票",
        10: "电子普通发票  普通发票",
        11: "卷式普通发票  普通发票",
        14: "电子普通[通行费]发票  普通发票",
        15: "二手车统一发票  其他发票",
        "004": "增值税专用发票  专用发票",
        "007": "增值税普通发票  普通发票",
        "009": "货物运输业增值税专用发票  专用发票",
        "005": "机动车销售统一发票  其他发票",
        "006": "二手车统一发票  其他发票",
        "026": "电子普通发票  普通发票",
        99: "通用机打发票  普通发票",
        c: "增值税普通发票  普通发票",
        s: "增值税专用发票  专用发票",
        j: "机动车销售统一发票  其他发票",
        p: "电子普通发票  普通发票",
      },
      relationTaxNo: "", //点击左侧公司才有这个税号
      companyNameTitle: "",
      createTimeTitle: "",
      contentRight: true,
      billtype: "",
      billStatus: "",
      goodtype: [],
      goodStatus: "",
      itemSelect: 0,
      contentIndex: 0,
      item: 0,
      activeIndex: "1",
      billTime: "",
      nameFilter: "",
      billtypeList: [
        {
          value: "0",
          label: "进项发票",
        },
        {
          value: "1",
          label: "销项发票",
        },
      ],
      billtypeListRate: [
        {
          value: "0",
          label: "0",
        },
        {
          value: "0.01",
          label: "1%",
        },
        {
          value: "0.03",
          label: "3%",
        },
        {
          value: "0.05",
          label: "5%",
        },
        {
          value: "0.06",
          label: "6%",
        },
        {
          value: "0.09",
          label: "9%",
        },
        {
          value: "0.1",
          label: "10%",
        },
        {
          value: "0.11",
          label: "11%",
        },
        {
          value: "0.13",
          label: "13%",
        },
        {
          value: "0.16",
          label: "16%",
        },
        {
          value: "0.17",
          label: "17%",
        },
      ],
      billData: [],
      billStatusListArray: ["正常", "作废", "红冲", "失控", "异常"],
      billStatusListArraySale: ["正常", "红冲", "空白发票", "作废", "异常", "失控"],
      billStatusList: [
        {
          value: "0",
          label: "正常",
        },
        {
          value: "1",
          label: "作废",
        },
        {
          value: "2",
          label: "红冲",
        },
        {
          value: "3",
          label: "失控",
        },
        {
          value: "4",
          label: "异常",
        },
      ],
      billTableDataItem0: [],
      pageNum0: 1,
      pageSize0: 10,
      totalNumber0: 10,
      pageNum1: 1,
      pageSize1: 200,
      totalNumber1: 10,
      billCircleData: [],
      isActive: 999,
      rangeTimes: {
        start: "",
        end: "",
      },
      productNoList: [[], []],
      //
      programId: this.uuid,
      companyName: "",
      iType: "0",
      yearBox: [],
      itemCompanyName: "",
      itemTaxNo:"",
      ClassSearchList0: [],
      ClassSearchList1: [],
    };
  },

  async mounted() {
    await this.loadInvoiceType()

    this.getInvoiceYear();
    this.getInvoiceDetailCompany();
    this.getInvoiceDetailPageList();
    //
    this.entryClassSearch();
    this.salesClassSearch();
  },

  methods: {
    entryClassSearch() {
      getEntryClass(this.taxNo).then((res) => {
        let data = res.data;
        this.ClassSearchList0 = clearAllChildren(data);
      });
    },
    salesClassSearch() {
      getSalesClass(this.taxNo).then((res) => {
        let data = res.data;
        this.ClassSearchList1 = clearAllChildren(data);
      });
    },
    amountFromChange() {
      if (!this.amountFrom) {
        this.amountTo = "";
      }
    },
    namefilterclear() {
      this.getInvoiceDetailCompany();
      this.pageNum0 = 1;
      this.itemCompanyName = "";
      this.itemTaxNo="";
      this.getInvoiceDetailPageList();
    },
    timefilter() {
      this.itemCompanyName = "";
      this.itemTaxNo="";
      this.contentIndex = 0;
      this.namefilterclear();
    },
    getInvoiceDetailPageList() {
      let params = {
        itype: this.iType,
        programId: this.programId,
        modelId: this.modelId,
        pageNum: this.pageNum0,
        pageSize: this.pageSize0,
        taxNo: this.itemTaxNo,
        lowerAmount: this.amountFrom,
        upperAmount: this.amountTo,
        startTime: this.billTime ? this.billTime[0] : "",
        endTime: this.billTime ? this.billTime[1] : "",
        productName: this.goodsFilter,
        rates: this.goodtype.length > 0 ? this.goodtype.flat() : [],
        year: this.isActive == "999" ? "" : this.isActive,
        productNo: this.productNoList[this.iType],
        desensitizationStatus:this.$route.query.desensitization,
      };
      this.getTable = true;
      getInvoiceDetailPage(params).then((res) => {
        this.totalNumber0 = Number(res.data.pageTotal);
        this.billTableDataItem0 = res.data.list;
        this.getTable = false;
      });
    },
    getInvoiceDetailCompany() {
      let params = {
        programId: this.programId,
        modelId: this.modelId,
        companyName: this.companyName,
        itype: this.iType,
        pageNum: 0,
        pageSize: 1000000,
        startTime: this.billTime ? this.billTime[0] : "",
        endTime: this.billTime ? this.billTime[1] : "",
        year: this.isActive == "999" ? "" : this.isActive,
        desensitizationStatus:this.$route.query.desensitization,
      };
      this.getTable = true;
      this.getTableCompany = true;
      getInvoiceDetailCompanyList(params).then((res) => {
        // this.totalNumber0 = Number(res.pageTotal);
        this.billData = res.data.list;
        this.getTable = false;
        this.getTableCompany = false;
      });
    },
    async loadInvoiceType() {
      const res = await getInvoiceType(this.programId, this.modelId)
      if (res.data != null) {
        this.iType = res.data.toString();
      }
    },
    getInvoiceYear() {
      getInvoiceYearBox(this.programId, this.modelId).then((res) => {
        this.yearBox = res.data.entries;
      });
    },
    toTop() {
      document.getElementById("contents" + this.modelId).scrollTo(0, 0);
    },
    getMoreData() {
      this.billPage++;
      this.searchCompany();
    },
    classChange(val) {
      // console.log(val)
      this.productNoList[this.iType] = val;
      console.log(this.productNoList)
      this.newSearchItem();
    },
    moreClass() {
      this.$refs.block.dialogVisible = true;
    },
    moreGoods() {
      this.moreGoodsIndex = !this.moreGoodsIndex;
    },
    optionsChange() {
      let optionsValue = [];
      for (let i = 0; i < this.optionsValue.length; i++) {
        if (this.optionsValue[i].length == 2) {
          optionsValue.push(this.optionsValue[i][1]);
        }
      }
      this.optionsValueList = optionsValue;
    },
    riskTypeVisibleFalse() {
      this.riskTypeVisible = false;
    },
    riskTypeVisibleComfirm() {
      this.billPage = 1;
      this.radioTypeCopy = this.radioType;
      this.searchCompany();
      this.riskTypeVisible = false;
    },
    copyText(val) {
      var copyTest = val;
      var inputTest = document.createElement("input");
      inputTest.value = copyTest;
      document.body.appendChild(inputTest);
      inputTest.select();
      document.execCommand("Copy");
      inputTest.className = "oInput";
      inputTest.style.display = "none";
      this.$message({
        message: "复制成功！",
        type: "success",
      });
    },
    handleSizeChange0(val) {
      this.pageNum0 = 1;
      this.pageSize0 = val;
      this.getInvoiceDetailPageList();
    },
    handleCurrentChange0(val) {
      this.pageNum0 = val;
      this.getInvoiceDetailPageList();
    },
    sortproductTotalAmount(obj1, obj2) {
      let val1 = obj1.productTotalAmount;
      let val2 = obj2.productTotalAmount;
      return val1 - val2;
    },
    sortproductTaxAmount(obj1, obj2) {
      let val1 = obj1.productTaxAmount;
      let val2 = obj2.productTaxAmount;
      return val1 - val2;
    },
    sortproductAmount(obj1, obj2) {
      let val1 = obj1.productAmount;
      let val2 = obj2.productAmount;
      return val1 - val2;
    },
    sortproductTaxRate(obj1, obj2) {
      let val1 = obj1.productTaxRate;
      let val2 = obj2.productTaxRate;
      return val1 - val2;
    },

    // 公司名称过滤器
    namefilterMeth() {
      this.billPage = 1;
      this.searchItem();
    },
    item2Act(item, index) {
      this.contentIndex = index;
      if (item.name == "合计") {
        this.itemCompanyName = "";
        this.itemTaxNo="";
      } else {
        this.itemCompanyName = item.name;
        this.itemTaxNo = item.taxNo;
      }
      this.pageNum0 = 1;
      this.getInvoiceDetailPageList();
    },
    searchItem(val) {
      this.getInvoiceDetailCompany();
      this.contentIndex = 0;
      this.itemCompanyName = "";
      this.itemTaxNo="";
      this.pageNum0 = 1;
      this.getInvoiceDetailPageList();
    },
    newSearchItem() {
      this.pageNum0 = 1;
      this.getInvoiceDetailCompany();
      this.getInvoiceDetailPageList();
    },
    allResult() {
      this.billPage = 1;
      if (this.rangeTimes.start) {
        this.billTime = [];
        [this.billTime[0], this.billTime[1]] = [
          moment(this.rangeTimes.start).format("YYYY-MM-DD"),
          moment(this.rangeTimes.end).format("YYYY-MM-DD"),
        ];
      } else {
        this.billTime = "";
      }
      this.isActive = 999;
      this.itemCompanyName = "";
      this.itemTaxNo="";
      this.contentIndex = 0;
      this.newSearchItem();
    },
    nowYear(val) {
      this.billPage = 1;
      const start = val;
      const startYear = new Date(start, 0, 1);
      const endYear = start + "-" + "12" + "-" + "31";
      this.billTime = [moment(startYear).format("YYYY-MM-DD"), endYear];
      this.isActive = val;
      this.itemCompanyName = "";
      this.itemTaxNo="";
      this.contentIndex = 0;
      this.newSearchItem();
    },
  },
};
</script>

<style lang="stylus" scoped>
.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.bill-detail-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.0400);
  border-radius: 8px;
  margin: 20px 0 16px 0;
  padding: 0 24px 0 16px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7500);

  .time {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4000);
  }
}

.bill-detail-content {
  // margin-top: 24px;
  //   margin: 0 30px;
  background: #fff;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  padding-bottom: 6px;

  .bill-detail-menu {
    position: relative;
    padding: 0 20px;
    background: #fff;
    padding-top: 6px;

    .bill-detail-time {
      position: absolute;
      right: 20px;
      top: 18px;
      font-size: 14px;
      color: #86909C;
    }

    >>> .el-menu--horizontal > .el-menu-item {
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      font-weight: 600;
      border-bottom: 2px solid transparent !important;
      color: #86909C;
    }

    >>> .el-menu--horizontal > .el-menu-item.is-active {
      border-bottom: 2px solid #2f54eb !important;
      font-size: 16px;
      font-weight: 600;
      color: #1D2129;
    }

    >>> .el-menu-item {
      padding: 0 0;
    }

    >>> .el-menu-item:first-child {
      margin-right: 40px;
    }
  }

  .bill-time-filter {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 12px 20px;
    min-height: 88px;
    font-size: 14px;
    font-weight: 600;
    color: #86909c;

    .good-type-bill {
      margin-right: 30px;
    }

    >>> .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
      width: 280px;
      height: 32px;
      line-height: 32px;
    }

    >>> .el-date-editor .el-range-separator {
      line-height: 24px;
    }

    >>> .el-date-editor .el-range__icon {
      line-height: 24px;
    }

    >>> .el-date-editor .el-range__close-icon {
      line-height: 24px;
    }

    .search {
      width: 60px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      color: #fff;
      background: #165DFF;
      border-radius: 2px;
      margin: 0 0 0 16px;
      font-size: 14px;
      cursor: pointer;
    }

    .search:hover {
      background: rgba(47, 34, 235, 1);
    }

    >>> .el-divider--vertical {
      margin: 0 4px;
      height: 32px;
    }

    .search-month {
      background: #fff;
      color: #86909C;
      box-sizing: border-box;
      width: 82px;
      height: 32px;
      line-height: 30px;
      text-align: center;
      margin-right: 10px;
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid #C0C4CC;
    }

    .search-month-first {
      margin-left: 10px;
    }

    .month-active {
      background: rgba(22, 93, 255, 0.1);
      font-weight: 600;
      color: #165DFF;
      border: 1px solid #165DFF;
    }
  }

  .bill-time-table {
    display: flex;
    padding: 0 16px;

    scrollbar-color: #E5E6EB #F7F8FA; /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
    scrollbar-width: thin; /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
    // -ms-overflow-style:none;  /* 隐藏滚动条（在IE和Edge两个浏览器中很难更改样式，固采取隐藏方式） */

    >>> .content-c::-webkit-scrollbar {
      /* 滚动条整体样式 */
      width: 8px;
      /* 高宽分别对应横竖滚动条的尺寸 */
      height: 1px;
      position: relative;
      right: 4px;
    }

    >>> .content-c::-webkit-scrollbar-thumb {
      /* 滚动条里面小方块 */
      // border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      background: #E5E6EB;
    }

    >>> .content-c::-webkit-scrollbar-track {
      /* 滚动条里面轨道 */
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
      // border-radius: 5px;
      background: #F7F8FA;
    }

    .left {
      width: 30%;
      border: 1px solid #D9D9D9;
      position: relative;

      .content-c {
        max-height: 600px;
        overflow-x: auto;
        // padding-bottom: 30px;

        .el-icon-tops {
          position: absolute;
          bottom: -10px;
          right: 0;
          color: #4e5969;
          font-size: 24px;
          padding: 30px;
          z-index: 3001;
          cursor: pointer;
        }
      }

      .content-flex {
        color: rgba(0, 0, 0, 0.4500);
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -32px;
      }

      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.7500);
        padding: 12px 8px;
        padding-right: 8px;
        box-sizing: border-box;

        .title-input {
          flex: 1;
          padding-right: 8px;
        }

        .title-company {
          white-space: nowrap;
          font-size: 14px;
          color: #86909C;
        }

        >>> .el-input {
          // max-width: 240px;
          height: 32px;
          line-height: 32px;
        }

        >>> .el-input__inner {
          height: 32px;
          line-height: 32px;
        }

        >>> .el-input__icon {
          height: 32px;
          line-height: 32px;
        }
      }

      .contentAct {
        background: #F0F5FF;

        .company {
          color: #1D2129 !important;
        }

        .price {
          color: #1D2129;
        }

        .info {
          color: #1D2129;
        }
      }

      .contents {
        position: relative;

        // border-bottom: 1px solid #EEEEEE;
        cursor: pointer;

        .content-item {
          padding: 16px 24px;
        }

        .content-item-index {
          padding: 16px 12px;
        }

        .content-item2 {
          padding: 6px 34px 6px 45px;
        }

        .content-right {
          position: absolute;
          top: 8px;
          color: #D8D8D8;
          left: -4px;
          font-size: 22px;
          padding: 10px;
        }

        .company {
          font-size: 16px;
          font-weight: 600;
          color: #4E5969;
          line-height: 22px;
        }

        .price {
          margin-bottom: 8px;
          margin-right: 16px;
        }

        .info {
          font-size: 14px;
          color: #4E5969;
        }
      }

      .contents:last-child {
        // border: none;
      }
    }

    .right {
      width: 70%;
      overflow: hidden;

      .right-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .select {
          // margin-right: 42px;

          >>> .el-select {
            width: 106px;
            height: 32px;
          }

          >>> .el-input__inner {
            height: 32px;
            line-height: 32px;
          }

          >>> .el-input__icon {
            line-height: 32px;
          }

          .billStatus {
            margin-left: 24px;
          }
        }

        .bill-select {
          font-size: 14px;
          color: #86909C;
          margin-right: 0;
        }
      }

      .item {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #86909C;
        margin-left: 18px;
        cursor: pointer;
        line-height: 32px;

        >>> .el-divider--vertical {
          margin: 0 16px;
          height: 20px;
        }

        .bill-name {
          font-size: 14px;
          color: #165DFF;
        }

        .taxname-select {
          padding: 10px 8px;
          color: rgba(0, 0, 0, 0.4000);
        }

        .bill-names {
          color: rgba(0, 0, 0, 0.6000);
        }

        .taxname {
          // padding: 10px 14px;
        }

        .taxname:first-child {
          padding-left: 0;
        }
      }

      .select {

      }

      .item-table {
        position: relative;
        margin-left: 10px;

        .el-table--border {
          border: none;
        }

        >>> .el-table__fixed {
          // height: auto !important;
          bottom: 10px !important;
        }

        .item-pagination {
          // position: absolute;
          // right: 0;
          // bottom: 10px;
          float: right;
          margin-top: 4px;
        }

        >>> .el-table td {
          // border: none;
        }

        >>> .el-table {
          // min-height: 330px;
        }

        >>> .el-table__body-wrapper {
          // z-index: 1001;
        }

        >>> .el-table__body-wrapper::-webkit-scrollbar {
          /* 滚动条整体样式 */
          width: 8px;
          /* 高宽分别对应横竖滚动条的尺寸 */
          height: 10px;
          position: relative;
          right: 4px;
        }

        >>> .el-table__body-wrapper::-webkit-scrollbar-thumb {
          /* 滚动条里面小方块 */
          // border-radius: 5px;
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
          background: #E5E6EB;
        }

        >>> .el-table__body-wrapper::-webkit-scrollbar-track {
          /* 滚动条里面轨道 */
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
          // border-radius: 5px;
          background: #F7F8FA;
        }

        >>> .el-table th .cell {
          line-height: 34px;
        }

        >>> .el-table td {
          padding: 11px 0;
        }

        >>> .el-table thead th {
          padding: 2px 0;
        }
      }
    }
  }
}

.circle-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 500px;

  .circle {
    position: relative;
    left: 20px;
    width: 250px;
    height: 250px;
    background: #fff;
  }

  .circle-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: space-between;
    margin-left: 60px;

    .circle-item-color {
      width: 8px;
      height: 16px;
      margin-right: 8px;
    }

    .bill-Data {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      width: 460px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6000);
      line-height: 22px;
      margin-bottom: 8px;

      .bill-Data-content {
        margin-right: 4px;
      }
    }
  }
}

.bill-content {

  width: 100%;
}

.chart-img {
  margin-right: 4px;

  img {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
}

.chart-img-right {
  margin-right: 0;
}

.bill-detail-info {
  font-size: 14px;
  color: #165dff;
  cursor: pointer;
}


.good-type {
  >>> .el-select {
    width: 217px;
    line-height: 34px;
    height: 34px;
  }

  >>> .el-input {
    line-height: 34px;
    height: 34px;
  }

  >>> .el-input__inner {
    line-height: 34px;
    height: 34px;
  }

  >>> .el-input__icon {
    line-height: 34px;
  }

  >>> .el-cascader {
    width: 280px;
  }

  >>> .el-cascader__tags {
    // top: 64%;
  }
}

.good-type-calc {
  >>> .el-input {
    height: auto;
    line-height: auto;
  }
}

.bill-select-type {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #86909c;
  margin-left: 40px;
}

.billStatus {
  margin-left: 10px;
}

.copy-price {
  margin-left: 10px;
  color: #165dff;
  cursor: pointer;
  display: none;
}

.price:hover .copy-price {
  display: inline;
}

.risk-type {
  font-size: 14px;
  color: #165dff;
  cursor: pointer;
}

.block {
  >>> .el-cascader {
    width: 330px;
  }
}

.risk-type-active {
  color: #86909c;
}

.item3-company {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 1px 2px;
  border-radius: 4px;
  margin-left: 4px;
}

.el-cascader-c {
  margin: 10px 0 0 90px;
}

.item-goods {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  color: #86909c;

  .item-goods-right {
    padding: 0 6px;
  }

  >>> .el-input {
    height: 32px;
    line-height: 32px;
    width: 202px;
  }

  >>> .el-input__inner {
    height: 32px;
    line-height: 32px;
  }

  >>> .el-input__icon {
    height: 32px;
    line-height: 32px;
  }

  .more-filter {
    color: #165dff;
    text-decoration: underline;
    margin-left: 8px;
    cursor: pointer;
  }

  .more-class {
    margin-left: 8px;
    cursor: pointer;
    margin-right: 40px;
  }
}

.right-item-filter {
  font-size: 14px;
  font-weight: 600;
  color: #86909c;
  margin-left: -2px;

  .radio {
    display: flex;
    position: relative;
    // align-items: center;

    .rate {
      padding-right: 12px;
      width: 76px;
      text-align: right;
    }

    >>> .el-checkbox {
      margin-right: 9px;
    }

    >>> .el-checkbox:last-child {
      margin-right: 0;
    }

    >>> .el-checkbox__label {
      padding-left: 2px;
    }
  }

  .radio-top {
    margin-bottom: 4px;
  }
}

.item-goods-button {
  position: absolute;
  right: 16px;
  bottom: 20px;

  >>> .el-button {
    padding: 9px 12px;
    margin-left: 2px;
    background: #165dff;
    border-radius: 0;
  }

  >>> .el-input {
    width: 200px;
  }
}

.select-group {
  display: flex;
  align-items: center;
}

.clear-filter {
  margin-right: 6px;
  cursor: pointer;
  padding: 0 1px;
}

.get-moredata {
  text-align: center;
  color: #86909c;
  cursor: pointer;
}

.el-icon-relative {
  position: relative;
}

.el-icon {
  position: absolute;
  top: -6px;
  right: -6px;
}

.good-type-data {
  margin-right: 30px;
}

.select-group-price {
  margin-right: 30px;

  >>> .el-input {
    width: 128px;
  }
}
</style>
