<template>
  <div>
    <el-dialog
      :visible.sync="checkParts"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      id="supple"
      :show-close="false"
    >
      <div class="part-check font-600">
        <span>温馨提示</span>
        <span class="title-font">未匹配到以下相关信息，请完善后继续</span>
        <img class="part-close" src="@/assets/test/part-close.png" alt @click="cancel" />
      </div>
      <div class="part-icon">
        <div class="box box1 box-bottom" v-if="formData.areaBoolean">
          <div class="qyname">地区：</div>
          <div class="name">
            <el-cascader :options="cityList" v-model="area"></el-cascader>
          </div>
        </div>
        <div class="box box1 box-bottom" v-if="formData.kjzdBoolean">
          <div class="qyname">会计制度：</div>
          <div class="name">
            <el-select v-model="zhidu" placeholder="请选择">
              <el-option
                v-for="item in kjzd"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="box box1 box-bottom" v-if="formData.hyBoolean">
          <div class="qyname">所属行业：</div>
          <div class="name">
            <el-select v-model="hangye" placeholder="请选择" filterable>
              <el-option
                v-for="item in hyListDataShow"
                :key="item.hy_dm"
                :label="item.hymc"
                :value="item.hy_dm"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="part-btnC">
        <div class="part-btn ease" @click="sumbit">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getTradeList, checkRetryFlow } from "@/api/api";
import { regionData } from "@/dependencies/element-china-area-data.js";
export default {
  data() {
    return {
      area: "",
      hangye: "",
      zhidu: "",
      hyList: [],
      kjzd: [
        { value: "qykjzd", label: "企业会计制度" },
        { value: "qykjzz", label: "企业会计准则" },
        { value: "xqykjzz", label: "小企业会计准则" },
      ],
      hyListDataShow: null,
      zhiduBoolean: false,
      hangyeBoolean: false,
      areaBoolean: false,
      cityList: regionData,
      areaBoolean: false,
      kjzdBoolean: false,
      hyBoolean: false,
    };
  },
  props: {
    checkParts: [Boolean],
    formData: [Object, Array],
    fixData: [Object, Array],
  },
  created() {
    this.gettradeList();
  },
  methods: {
    // 获取行业
    gettradeList() {
      getTradeList().then((res) => {
        if (res.msgCode == 1) {
          if (!res.data) {
            this.$message.error(res.msgInfo);
          }
          let hyList = JSON.parse(res.data);
          let dataList = {};
          for (let i = 0; i < hyList.length; i++) {
            hyList[i].hymc = hyList[i].hy_dm + hyList[i].hymc;
            dataList[hyList[i].hy_dm] = hyList[i].hymc;
          }
          this.hyListDataShow = hyList;
        } else {
          this.$message.error(res.msgInfo);
        }
      });
    },
    sumbit() {
      if (!this.area && this.formData.areaBoolean) {
        this.$message.error("地区不能为空！");
        return;
      }
      if (!this.zhidu && this.formData.kjzdBoolean) {
        this.$message.error("会计制度不能为空！");
        return;
      }
      if (!this.hangye && this.formData.hyBoolean) {
        this.$message.error("行业不能为空！");
        return;
      }

      if (
        this.fixData.province_code &&
        this.fixData.city_code &&
        this.fixData.district_code
      ) {
        var params = {
          nsrsbh: this.fixData.nsrsbh,
          uuid: this.fixData.uuid,
          hy: this.fixData.industry || this.hangye,
          kjzd: this.fixData.kjzd || this.zhidu,
          provinceCode: this.fixData.province_code,
          cityCode: this.fixData.city_code,
          districtCode: this.fixData.district_code,
        };
      } else {
        var params = {
          nsrsbh: this.fixData.nsrsbh,
          uuid: this.fixData.uuid,
          hy: this.fixData.industry || this.hangye,
          kjzd: this.fixData.kjzd || this.zhidu,
          provinceCode: this.area[0],
          cityCode: this.area[1],
          districtCode: this.area[2],
        };
      }
      checkRetryFlow(params).then((res) => {
        console.log(res);
        if (res.msgCode == 1) {
          this.$message({
            message: "补全信息成功,等待报告生成!",
            type: "success",
          });
          this.$emit("fixSuccess")
        } else {
          this.$message.error(res.msgInfo);
        }
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="stylus" scoped>
>>> #supple .el-dialog {
  width: 480px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

>>> #supple .el-dialog__header {
  padding: 0;
}

>>> #supple .el-dialog__body {
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.part-check {
  width: 100%;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid #eeeeee;
  padding-left: 24px;
  box-sizing: border-box;
  align-item: center;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}

.part-close {
  position: relative;
  top: 18px;
  float: right;
  padding-right: 24px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.part-icon {
  text-align: center;
  border-bottom: 1px solid #eeeeee;
  box-sizing: border-box;
  padding-left: 50px;
  padding-top: 32px;
  padding-bottom: 16px;
}

.box {
  display: flex;
}

.part-btn {
  position: relative;
  right: 24px;
  top: 16px;
  width: 76px;
  height: 36px;
  line-height: 36px;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  float: right;
  cursor: pointer;
}

.part-btn:hover {
  background: rgba(47, 60, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.title-font {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-left: 8px;
}

.qyname {
  width: 100px;
  line-height: 40px;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
}

.el-select {
  width: 284px;
}

.part-btnC {
  height: 68px;
}

.box-bottom {
  margin-bottom: 16px;
}

.part-icon >>>.el-cascader {
  width: 284px;
}
</style>