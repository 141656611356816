<template>
  <div>
    <template>
      <div
        :class="[
          `section-wrapper-${level}`,
          isEditable && showEditForm ? 'highlighted' : '',
        ]"
        :id="'node' + node.id"
      >
        <!--标题的编辑弹出框-->
        <template v-if="level == 1">
          <el-popover
            placement="top-start"
            width="400"
            trigger="manual"
            v-model="toolTipVisible"
            @show="handleTooltipShow"
          >
            <div class="form-control-wrapper">
              <div class="input-wrapper">
                <el-input
                  v-model="titleInput"
                  placeholder="请输入内容"
                  :maxlength="level > 1 ? 20 : 15"
                ></el-input>
              </div>

              <div class="btn-wrapper">
                <button class="btn btn-default" @click="handleBtnSaveClick">
                  确定
                </button>
                <button class="btn btn-default" @click="handleBtnCancelClick">
                  取消
                </button>
              </div>
            </div>
            <template slot="reference">
              <!--标题-->
              <div
                class="title-wrapper"
                @click="
                  toolTipVisible = true && isEditable && !isSystemDiscountPolicy
                "
              >
                <el-tooltip
                  :popper-class="popperClass"
                  :manual="true"
                  :value="(isModifiedDiscountPolicyByMyself || isInputModifiedByMyself)&&false"
                  content="此标题已修改"
                  placement="top-end"
                >
                  <div>
                    <span
                      :class="{
                        bold: node.children && node.children.length > 0,
                        removed: !node.isDisplay,
                      }"
                      >{{ node.orderNumber }} {{ title }}
                    </span>
                    <i
                      class="icon iconfont iconbianji"
                      v-if="isEditable && !isSystemDiscountPolicy"
                    ></i>
                  </div>
                </el-tooltip>

                <div class="badge yellow" v-if="isModifiedDiscountPolicy">
                  已修改
                </div>
                <div class="badge yellow" v-else-if="isInputModified">
                  已修改
                </div>

                <div class="badge red" v-if="isModifiedDiscountPolicyByMyself">
                  已修改
                </div>
                <div class="badge red" v-else-if="isInputModifiedByMyself">
                  已修改
                </div>

                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="node.notes"
                  placement="top"
                  v-if="node.notes"
                >
                  <el-button class="tooltip">
                    <i class="icon iconfont iconbangzhu2"></i>
                  </el-button>
                </el-tooltip>

                <WarningBadge :id="node.riskLevel"></WarningBadge>

                <span class="label-box" v-if="node.label">{{
                  node.label
                }}</span>
              </div>
              <!--标题-->
            </template>
          </el-popover>
        </template>
        <template v-else>
          <div
            :class="[
              showEditForm || (node.children && node.children.length > 0)
                ? ''
                : 'head-level-editable-style',
              'head-level-' + level,
            ]"
          >
            <!--标题-->
            <!--查看模式-->

            <template v-if="!showEditForm">
              <div
                class="title-wrapper"
                @click="
                  toolTipVisible = true && isEditable && !isSystemDiscountPolicy
                "
              >
                <span
                  :class="{
                    bold: node.children && node.children.length > 0,
                    removed: !node.isDisplay,
                  }"
                  >{{ node.orderNumber }} {{ title }}
                </span>

                <div class="badge yellow" v-if="isModifiedDiscountPolicy">
                  已修改
                </div>
                <div class="badge yellow" v-else-if="isInputModified">
                  已修改
                </div>

                <div class="badge red" v-if="isModifiedDiscountPolicyByMyself">
                  已修改
                </div>
                <div class="badge red" v-else-if="isInputModifiedByMyself">
                  已修改
                </div>

                <WarningBadge :id="node.riskLevel"></WarningBadge>

                <span class="label-box" v-if="node.label">{{
                  node.label
                }}</span>
              </div>
            </template>

            <!--/查看模式-->
            <template v-else>
              <div class="editable-title">
                <div class="label">标题：</div>
                <input type="text" class="input" v-model="titleInput" />
                <div class="warningLevelSelector-wrapper" v-if="!isOverallNode">
                  <div class="label">风险程度:</div>
                  <WarningLevelSelector
                    @change="handleWarningLevelSelectorChanged"
                    :selectedValue="node.riskLevel"
                  ></WarningLevelSelector>
                </div>
              </div>
            </template>
            <!--/标题-->
            <!--按钮-->
            <template v-if="!(node.children && node.children.length > 0)">
              <template v-if="isEditable">
                <template v-if="showEditForm">
                  <div v-if="isEditable">
                    <button
                      class="btn btn-primary btn-rounded"
                      @click="handleBtnSaveClick"
                    >
                      保存
                    </button>
                  </div>
                </template>
                <template v-else>
                  <div v-if="isEditable">
                    <button
                      class="btn btn-primary btn-rounded btns-wrapper-showable"
                      @click="handleMaskEditClick"
                      v-if="isDisplay"
                    >
                      编辑
                    </button>
                    <router-link
                      :to="{
                        name: 'resultDetail',
                        params: {
                          type: node.type,
                          contrastKey: node.contrastKey,
                          indexes: indexes.join(','),
                          nsrsbh: this.$route.params.nsrsbh||this.$route.query.nsrsbh,
                          uuid: this.$route.params.uuid,
                          action: this.$route.params.action,
                        },
                        query: {
                          title: `${node.orderNumber} ${title}`,
                        },
                      }"
                      class="btn btn-primary btn-rounded btns-wrapper-showable"
                      target="_blank"
                      v-if="isDisplay && !isOverallNode && !node.userData"
                      >查看详情
                    </router-link>

                    <button
                      class="btn btn-default btn-rounded btns-wrapper-showable"
                      @click="handleBtnResetClick"
                      v-if="
                        isDisplay &&
                        !isDiscountPolicy &&
                        (!node.children ||
                          node.children.length == 0 ||
                          this.isOverallNode)
                      "
                    >
                      重置
                    </button>
                    <button
                      class="btn btn-default btn-rounded btns-wrapper-showable"
                      @click="handleBtnDeleteClick"
                      v-if="isDisplay && isUserAddedData"
                    >
                      删除
                    </button>
                    <button
                      class="btn btn-default btn-rounded btns-wrapper-showable"
                      v-if="isDisplay"
                      @click="handleBtnDisplayToggleClick"
                    >
                      {{ toggleBtnLabel }}
                    </button>

                    <button
                      class="btn btn-default btn-rounded btn-toggle-show"
                      v-else
                      @click="handleBtnDisplayToggleClick"
                    >
                      <i class="iconfont iconxianshi"></i> {{ toggleBtnLabel }}
                    </button>
                  </div>
                </template>
              </template>
              <template v-else>
                <!--如果没有编辑权限-->
                <router-link
                  :to="{
                    name: 'resultDetail',
                    params: {
                      type: node.type,
                      contrastKey: node.contrastKey,
                      indexes: indexes.join(','),
                      nsrsbh: this.$route.params.nsrsbh||this.$route.query.nsrsbh,
                      uuid: this.$route.params.uuid,
                      action: this.$route.params.action,
                    },
                    query: {
                      title: `${node.orderNumber} ${title}`,
                    },
                  }"
                  class="btn btn-primary btn-rounded"
                  target="_blank"
                  v-if="isDisplay && !isOverallNode && !node.userData"
                  >查看详情
                </router-link>
                <!--/如果没有编辑权限-->
              </template>
            </template>
            <!--/按钮-->
          </div>
        </template>
        <!--/标题的编辑弹出框-->

        <transition name="slide">
          <div class="section-body" v-if="isDisplay">
            <slot v-bind:isEditMode="isEditable && showEditForm"></slot>
          </div>
        </transition>
      </div>

      <!--/如果可以编辑-->
    </template>
    <div class="masker" v-if="isEditable && showEditForm"></div>
  </div>
</template>

<script>
import WarningLevelSelector from "./WarningLevelSelector";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import WarningBadge from "@/pages/SimpleResultEditor/components/WarningBadge.vue";
import { getRevokePdfCatalogData, delectPdfCatalogData } from "@/api/shiwusuo";
import mixin from "@/pages/SimpleResultEditor/mixin.js";
export default {
  mixins: [mixin],
  props: {
    title: String,
    level: Number, //当前节点在treeData中第几层
    indexes: {
      //当前组件所在treeData中的索引位置,如果是综述节点值就是overall
      type: Array,
      required: false,
    },
    target: {
      default: "treeData",
      type: String,
    },
  },
  mounted() {
    this.selectedWarningLevelId = this.node.riskLevel;
  },
  computed: {
    popperClass() {
      let ret = "";
      if (this.isInputModifiedByMyself||this.isModifiedDiscountPolicyByMyself) {
        ret = "modified-tooltip-red";
      } else if (this.isInputModified) {
        ret = "modified-tooltip-yellow";
      }
      return ret;
    },
    //是否是的优惠政策
    isDiscountPolicy() {
      return this.node.type == 5;
    },
    //是否是用户定义的节点
    isUserAddedData() {
      return this.node.userData == 1;
    },
    //是否是系统自带的优惠政策
    isSystemDiscountPolicy() {
      return this.node.type == 5 && this.node.userData != 1;
    },
    //显示已经修改
    isModifiedDiscountPolicy() {
      let ret = false;
      const action = this.$route.params.action;
      if (action == "edit") {
        ret = this.node.lastCensorSnapshot
          ? this.node.lastCensorSnapshot.length > 0
          : false;
      } else if (action == "censor") {
        ret = this.node.lastEditSnapshot
          ? this.node.lastEditSnapshot.length > 0
          : false;
      }
      return ret;
    },
    isModifiedDiscountPolicyByMyself() {
      let ret = false;
      const action = this.$route.params.action;
      if (action == "edit") {
        ret = this.node.editSnapshot
          ? this.node.editSnapshot.length > 0
          : false;
      } else if (action == "censor") {
        ret = this.node.censorSnapshot
          ? this.node.censorSnapshot.length > 0
          : false;
      }
      return ret;
    },
    isInputModified() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.node.lastCensorSnapshot
          ? this.node.lastCensorSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.node.lastEditSnapshot
          ? this.node.lastEditSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    isInputModifiedByMyself() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.node.editSnapshot
          ? this.node.editSnapshot.includes("TextChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.node.censorSnapshot
          ? this.node.censorSnapshot.includes("TextChange")
          : false;
      }
      return ret;
    },
    //是否是综述节点
    isOverallNode() {
      return this.indexes == null;
    },
    ...mapState("ResultEditor", ["treeData", "overall", "discountPolicyTree"]),
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    node() {
      //如果没有提供indexes则是overall
      if (this.isOverallNode) {
        return this.overall;
      } else {
        //如果有则是可以排序的节点
        return this.findTheNodeByIndexes(this.indexes, this.target);
      }
    },
    //是否隐藏
    isDisplay() {
      let ret = false;
      ret = !!this.node.isDisplay;
      return ret;
    },
    //按钮上的文字
    toggleBtnLabel() {
      return this.isDisplay ? "隐藏" : "显示";
    },
  },
  data: function () {
    return {
      isShowMasker: true,
      showEditForm: false, //是否展示编辑用的表单
      titleInput: "",
      toolTipVisible: false,
      selectedWarningLevelId: "", //当前选中的风险
    };
  },
  components: {
    WarningBadge,
    WarningLevelSelector,
  },

  methods: {
    ...mapMutations("ResultEditor", [
      "setTreeDataByIndexes",
      "refineOrder",
      "setDiscountPolicyTree",
    ]),
    ...mapActions("ResultEditor", ["refresh", "submit", "reload"]),
    handleMaskEditClick() {
      this.showEditForm = true;
    },
    //当风险等级选择器选择时
    handleWarningLevelSelectorChanged(id) {
      this.selectedWarningLevelId = id;
    },
    //重置
    async handleBtnResetClick() {
      const pdfId = this.node.id;
      const resData = await getRevokePdfCatalogData({ pdfId });
      const nodeRemoteData = resData.data;

      if (resData.msgCode == "1") {
        if (this.isOverallNode) {
          //如果当前节点是综述节点
          const nativeTreeData = cloneDeep(this.node);
          // nativeTreeData.titleName = nodeRemoteData.titleName;
          nativeTreeData.pdfData = nodeRemoteData.pdfData;
          this.$store.commit("ResultEditor/setOverall", {
            data: nativeTreeData,
          });
        } else {
          //如果当前节点是树状目录中的节点
          const nodeData = cloneDeep(this.node);
          //nodeData.titleName = this.titleInput; //给节点设置新的titleName
          nodeData.pdfData = nodeRemoteData.pdfData;
          nodeData.dataText = nodeRemoteData.dataText;

          this.setTreeDataByIndexes({
            data: nodeData,
            indexes: this.indexes,
            target: this.target,
          });
        }
        this.$message({
          message: `${this.title}的数据已经被重置`,
          type: "info",
        });
      } else {
        this.$message.error(resData.msgInfo);
      }
      this.submit();
    },
    //显示隐藏
    handleBtnDisplayToggleClick() {
      //如果当前节点是综述节点
      if (this.isOverallNode) {
        const node = cloneDeep(this.node);
        node.isDisplay = this.node.isDisplay == "1" ? 0 : 1;
        this.$store.commit("ResultEditor/setOverall", { data: node });
        // this.$message({
        //   message:
        //     `${this.title}将在PDF中` + (node.isDisplay ? "隐藏" : "显示"),
        //   type: "info"
        // });
      } else {
        const nodeData = cloneDeep(this.node);
        //如果当前节点是树状目录中的节点
        nodeData.isDisplay = this.node.isDisplay == "1" ? 0 : 1;

        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
          target: this.target,
        });

        this.refineOrder({ target: this.target });

        // this.$message({
        //   message:
        //     `${this.title}将在PDF中` + (this.node.isDisplay ? "隐藏" : "显示"),
        //   type: "info"
        // });
      }
      this.submit();
    },
    async handleBtnDeleteClick() {
      const nodeId = this.node.id;
      const discountPolicyTree = cloneDeep(this.discountPolicyTree);

      this.$confirm("此操作将删除该节点, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        // discountPolicyTree[0].children = discountPolicyTree[0].children.filter(
        //   (v) => {
        //     return v.id !== nodeId;
        //   }
        // );
        await delectPdfCatalogData({
          pdfCatalogDataId: this.node.id,
          nsrsbh: this.$route.params.nsrsbh||this.$route.query.nsrsbh,
          uuid: this.$route.params.uuid,
          isSimple: 1,
        });
        this.reload();
        //this.setDiscountPolicyTree({ data: discountPolicyTree });
      });
    },
    handleBtnCancelClick() {
      this.toolTipVisible = false;
    },
    handleBtnSaveClick() {
      this.showEditForm = false;

      //如果是综述则修改综述里的titleName
      if (this.isOverallNode) {
        const node = cloneDeep(this.node);
        node.titleName = this.titleInput;
        this.$store.commit("ResultEditor/setOverall", { data: node });
      } else {
        //设置vuex中的titleName
        const nodeData = cloneDeep(this.node);
        //如果当前节点是树状目录中的节点
        nodeData.titleName = this.titleInput;
        nodeData.riskLevel = this.selectedWarningLevelId;
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
          target: this.target,
        });
      }
      this.toolTipVisible = false;
      this.submit();
    },
    handleTooltipShow() {
      this.titleInput = this.node.titleName;
    },
  },
  watch: {
    title: {
      handler() {
        this.titleInput = this.title;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.highlighted {
  position: relative;
  z-index: 10;
}

.masker {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 9;
}

.head-level-editable-style {
  border-bottom: 1px solid #D9D9D9;
}

.label-box {
  margin-left: 10px;
  font-weight: normal;
  display: inline-block;
  padding: 1px 3px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  border: 1px solid rgba(47, 84, 235, 1);
  font-size: 12px;
  color: rgba(47, 84, 235, 1);
}

.form-control-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .input-wrapper {
    flex-grow: 1;
  }

  .btn-wrapper {
    margin-left: 10px;
    display: flex;

    .btn {
      margin: 0 5px;
    }
  }
}

.title-wrapper {
  display: flex;
  align-items: center;

  .read-only-text {
    margin-left: 10px;
  }
}

.icon {
  color: rgba(47, 84, 235, 1);
  margin-left: 8px;
  font-weight: normal;
  cursor: pointer;
}

.slide-enter-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.bold {
  font-weight: bold;
}

.removed {
  text-decoration: line-through;
  color: #999;
}

.head-level-1 {
  font-weight: bold;
  padding: 0 0px 0;
  font-weight: bold;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head-level-2, .head-level-3 {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
}

.head-level-2 {
  box-sizing: border-box;
  padding: 0px;
  font-weight: normal;
}

.section-wrapper-2, .section-wrapper-3 {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
  overflow: hidden;
}

.section-wrapper-1 {
  box-sizing: border-box;
  margin: 32px 31px;
  overflow: hidden;
}

.tooltip {
  padding: 0;
  border: 0 none;
  margin: 0 0 0 8px;

  .icon {
    color: #2F54EB;
    font-size: 18px;
  }
}

.tooltip:hover {
  background: none;
}

.section-body {
}

.badge {
  font-weight: normal;
  padding: 3px 8px;
  color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  margin-left: 16px;
}

.badge.red {
  background: rgba(245, 34, 45, 1);
  color: #fff;
  border: 1px solid rgba(245, 34, 45, 1);
}

.badge.yellow {
  color: rgba(250, 173, 20, 1);
  border: 1px solid rgba(250, 173, 20, 1);
  background: rgba(250, 173, 20, 0.08);
}

.btn-toggle-show {
  border: none;
  background: none !important;
  font-weight: bold;
}

.btn-toggle-show:hover {
  background: none !important;
  color: #2F54EB !important;
}

.editable-title {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);

  .label {
    margin-right: 5px;
    font-weight: bold;
    font-size: 16px;
  }

  .input {
    background: #FBFBFB;
    border-radius: 16px;
    border: 1px solid #D9D9D9;
    width: 490px;
    height: 32px;
    line-height: 32px;
    padding: 0 17px;
    font-size: 16px;
    box-sizing: border-box;
  }

  .warningLevelSelector-wrapper {
    margin-left: 32px;
    display: flex;
    align-items: center;

    .label {
      margin-right: 8px;
    }
  }
}

.btns-wrapper-showable {
  display: none;
}

.section-wrapper-2:hover, section-wrapper-3:hover {
  .btns-wrapper-showable {
    display: inline-block;
  }
}
</style>
