<template>
  <div class="footer-wrapper">
    <div class="inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped>
.footer-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
  width: 100%;

  .inner {
    height:84px;
    margin-left: 180px;
    box-sizing: border-box;
    background: #fff;
    box-shadow:0px 0px 8px 0px rgba(0,0,0,0.08),0px 0px 4px 0px rgba(0,0,0,0.02);
    display flex;
    justify-content center;
    align-items center;

    .btn-next{
        border-radius:4px;
        cursor pointer;
        font-size:14px;
        margin-right 30px;
    }
  }
}
</style>
