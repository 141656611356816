<template>
  <div>
    <GenericTable :drawerTriggerIndex="drawerTriggerIndex" @cellClick="handleCellClick" :data="tableData"></GenericTable>
    <el-drawer :visible.sync="drawer" direction="rtl" size="50%">
      <div class="drawer-inner">
        <slot :drawerData="drawerData"></slot>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import GenericTable from "./GenericTable";
export default {
  props: {
    data: Object,
    drawerTriggerIndex: Number
  },
  components: {
    GenericTable
  },
  computed: {
    tableData() {
      const data = this.data.data.map(v => {
        return v.table;
      });

      return { data, labels: this.data.labels };
    }
  },
  data() {
    return {
      drawer: false,
      drawerData: {}
    };
  },
  methods: {
    handleCellClick(row) {
      this.drawer = true;
      this.drawerData = this.data.data[row];
    }
  }
};
</script>

<style lang="stylus" scoped>
.drawer-inner {
  overflow-y auto;
  padding: 25px;
  height calc(100vh - 70px);
  padding-top 0;
}
</style>