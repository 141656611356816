<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>数据统计</el-breadcrumb-item>
          <el-breadcrumb-item>数据统计</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <div class="submit-title">数据统计</div>
    </div>
    <div class="filter-body">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="form-item">
            <div class="label">项目名称:</div>
            <el-input
              v-model="filter.projectName"
              placeholder="请输入"
              clearable
              @input="changeName"
              @keyup.enter.native="search"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">客户名称:</div>
            <el-input
              clearable
              v-model="filter.consumer"
              placeholder="请输入"
              @input="changeConsumer"
              @keyup.enter.native="search"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">检测时间:</div>
            <el-date-picker
              class="input"
              v-model="filter.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="changeTime"
            ></el-date-picker>
          </div>
          <div class="form-item">
            <div class="label">项目经理:</div>
            <el-input
              v-model="filter.projectManager"
              placeholder="请输入"
              clearable
              @input="changeManager"
              @keyup.enter.native="search"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">审核人:</div>
            <el-input
              clearable
              v-model="filter.reviewer"
              placeholder="请输入"
              @input="changeReviewer"
              @keyup.enter.native="search"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">业务员:</div>
            <el-input
              clearable
              v-model="filter.salesman"
              placeholder="请输入"
              @input="changeSalesman"
              @keyup.enter.native="search"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">状态:</div>
            <el-select
              v-model="filter.status"
              clearable
              placeholder="请选择"
              filterable
              @change="search"
            >
              <el-option
                v-for="item in statusList"
                :key="item.status"
                :label="item.name"
                :value="item.status"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div @click="search" class="search-btna ease">搜索</div>
      <!-- <div @click="reset" class="search-btna">重置</div> -->
    </div>
    <a class="addItem ease addItemR" :href="derive()">导出</a>
    <!-- <div class="addItem ease addItemR" @click="derive">导出</div> -->
    <div class="record-form">
      <el-table
        :data="staticList"
        element-loading-text="加载中..."
        style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column width="10"></el-table-column>
        <el-table-column
          prop="exe_time"
          label="检测时间"
          sortable
          :show-overflow-tooltip="true"
          min-width="13%"
        >
          <template slot-scope="scoped">
            <span v-if="scoped.row.exe_time">{{ scoped.row.exe_time }}</span>
            <span v-else>暂无检测时间</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="zqlx"
          label="检测类型"
          :show-overflow-tooltip="true"
          min-width="8%"
        >
          <template>
            <div>年度</div>
          </template>
        </el-table-column>
        <el-table-column prop="zq_mc" label="征期" min-width="8%"></el-table-column>
        <el-table-column
          prop="project_name"
          label="项目名称"
          :show-overflow-tooltip="true"
          min-width="20%"
        ></el-table-column>
        <el-table-column
          prop="company_name"
          label="客户名称"
          :show-overflow-tooltip="true"
          min-width="20%"
        ></el-table-column>
        <el-table-column
          prop="project_manager"
          label="项目经理"
          min-width="8%"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="salesman"
          label="业务员"
          min-width="8%"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="reviewer"
          label="审核人"
          min-width="8%"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column prop="status" label="状态" min-width="10%">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 0"><span class="circle0"></span>待检测</div>
            <div v-if="scope.row.status == 1"><span class="circle1"></span>已结束</div>
            <div v-if="scope.row.status == 2"><span class="circle2"></span>进行中</div>
            <div v-if="scope.row.status == 3"><span class="circle3"></span>已废除</div>
          </template>
        </el-table-column>
        <el-table-column prop="discount" label="操作" width="60">
          <template slot-scope="scope">
            <span class="discount-btn" @click="viewDetails(scope.row.id)">查看</span>
          </template>
        </el-table-column>
        <el-table-column width="10"></el-table-column>
      </el-table>
    </div>
    <transition name="fades">
      <div class="containerMask" v-if="showDetail" @click="showDetail = false"></div>
    </transition>
    <transition name="slide-fade">
      <div v-if="showDetail" class="dataDetail" ref="box">
        <data-detail
          :dataList="dataList"
          @hiddenDetail="hiddenDetail"
          class="dataDetail"
          :dataInfo="dataInfo"
        ></data-detail>
      </div>
    </transition>
    <el-pagination
      class="pagination-low"
      @current-change="handleCurrentChange"
      :current-page="pageno"
      layout="total, prev, pager, next, jumper"
      :total="totalNumber"
    ></el-pagination>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import { regionData } from "@/dependencies/element-china-area-data.js";
import { veriFiItem, veriFiCustom, area } from "@/pages/product/utils/limit.js";
import {
  orderRecord,
  allRecord,
  cancelOrder,
  getStatistics,
  getStatisticsDetail,
  getTradeList,
  getStatisticsDerive,
  getRegion,
} from "../../api/api";
import pagination from "@/pages/product/components/pagination.vue";
import dataDetail from "./components/dataDetail.vue";

import { statusList, formatDateTes } from "./help.js";

import { cloneDeep } from "lodash";

export default {
  components: {
    BreadCrumb,
    pagination,
    dataDetail,
  },

  data() {
    return {
      areaTree: [],
      haveDataDetail: true,
      haveDerive: true,
      statusList: statusList,
      dataList: null,
      hyList: null,
      name: "",
      // city: regionData,
      person: "",
      time: "",
      dataInfo: null,
      totalNumber: 10, //总数
      value: "",
      value1: "",
      tableData: [],
      firstTime: "2018-11-13",
      lastTime: "2099-12-30",
      status: "8",
      loading: "true",
      pageno: 1,
      pageSize: 10,
      showDetail: false,
      filter: {
        projectName: "",
        consumer: "",
        time: "",
        projectManager: "",
        reviewer: "",
        salesman: "",
        status: "",
      },
      staticList: null,
    };
  },
  computed: {
    city: function () {
      if (!this.areaTree) {
        return [];
      }
      const ret = cloneDeep(this.areaTree);
      function walk(data) {
        data.value = data.code;
        data.label = data.name;
        if (data.childNode && data.childNode.length > 0) {
          data.children = data.childNode;
          for (const child of data.children) {
            walk(child);
          }
        }
        delete data.childNode;
      }
      for (const areaTree of ret) {
        walk(areaTree);
      }
      return [...ret];
    },
  },
  created() {
    this.getstatistics();
    this.gettradeList();
    // 查看是否有查看详情权限
    this.checkDataDetail(28);
    // 查看是否有导出权限
    this.checkDerive(29);
    this.getRegionData();
  },
  methods: {
    getRegionData() {
      getRegion().then((res) => {
        this.areaTree = res.data;
      });
    },
    // 权限方法
    checkAuthMethod(id) {
      let getUserAuth = JSON.parse(localStorage.getItem("user")).authorities;
      if (!getUserAuth) {
        return "none";
      }
      this.roleList = JSON.parse(localStorage.getItem("user")).authorities[0].roleList;
      let roleListId = this.roleList.map((item) => {
        return item.id;
      });
      if (roleListId.indexOf(id) == -1) {
        return false;
      } else {
        return true;
      }
    },
    // 查看是否有查看详情权限
    checkDataDetail(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveDataDetail = false;
      }
    },
    // 查看是否有导出功能
    checkDerive(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveDerive = false;
      }
    },
    derive() {
      const url = getStatisticsDerive({
        consumer: this.filter.consumer.trim(),
        projectName: this.filter.projectName.trim(),
        reviewer: this.filter.reviewer.trim(),
        projectManager: this.filter.projectManager.trim(),
        startTime: this.filter.time ? this.filter.time[0] : "",
        endTime: this.filter.time ? this.filter.time[1] : "",
        status: this.filter.status,
        firmId: JSON.parse(localStorage.getItem("user")).firmId,
      });
      return url;
      // if (!this.haveDerive) {
      //   this.$message({
      //     message: "抱歉，您没有导出权限！",
      //     type: "warning",
      //   });
      //   return;
      // }
      // let params = {
      //   consumer: this.filter.consumer.trim(),
      //   projectName: this.filter.projectName.trim(),
      //   reviewer: this.filter.reviewer.trim(),
      //   projectManager: this.filter.projectManager.trim(),
      //   status: "",
      //   startTime: this.filter.time ? this.filter.time[0] : "",
      //   endTime: this.filter.time ? this.filter.time[1] : "",
      //   pageSize: 10,
      //   pageNo: this.pageno,
      //   status: this.filter.status,
      //   fileName: "数据统计导出",
      // };
      // getStatisticsDerive(params).then((response) => {
      //   if (response) {
      //     // console.log(response);
      //     // this.stateUrl = response;
      //     return response;
      //     // const aLink = document.createElement("a");
      //     // const newfileName = "数据统计导出";
      //     // let blob = new Blob([response], {
      //     //   type: "application/vnd.ms-excel",
      //     // });
      //     // aLink.href = URL.createObjectURL(blob);
      //     // aLink.setAttribute("download", `${newfileName}`);
      //     // aLink.click();
      //     // window.URL.revokeObjectURL(blob);
      //   } else {
      //     this.$message({
      //       showClose: true,
      //       message: response.msgInfo || "查询失败",
      //       type: "warning",
      //     });
      //   }
      // });
    },
    handleCurrentChange(val) {
      this.pageno = val;
      this.getstatistics();
    },
    changeTime() {
      this.search();
    },
    changeName() {
      if (!this.filter.projectName) {
        this.search();
      }
    },
    changeConsumer() {
      if (!this.filter.consumer) {
        this.search();
      }
    },
    changeManager() {
      if (!this.filter.projectManager) {
        this.search();
      }
    },
    changeReviewer() {
      if (!this.filter.reviewer) {
        this.search();
      }
    },
    changeSalesman() {
      if (!this.filter.salesman) {
        this.search();
      }
    },
    search() {
      this.pageno = 1;
      this.getstatistics();
    },
    reset() {
      this.pageno = 1;
      this.filter = {
        projectName: "",
        consumer: "",
        projectManager: "",
        reviewer: "",
        salesman: "",
        startTime: "",
        endTime: "",
        status: "",
      };
      this.getstatistics();
    },
    gettradeList() {
      getTradeList().then((res) => {
        if (res.msgCode == 1) {
          let data = JSON.parse(res.data);
          this.hyList = data;
          let dataList = {};
          for (let i = 0; i < this.hyList.length; i++) {
            this.hyList[i].hymc = this.hyList[i].hy_dm + this.hyList[i].hymc;
            dataList[this.hyList[i].hy_dm] = this.hyList[i].hymc;
          }
          this.dataList = dataList;
        } else {
          this.$message.error(res.msgInfo);
        }
      });
    },
    optionSelect() {
      this.showDetail = false;
    },

    getStatisticsDetail(id) {
      let params = {
        id: id,
      };
      getStatisticsDetail(params, localStorage.getItem("authorization")).then((res) => {
        if (res.msgCode == 1) {
          this.dataInfo = res.data;
          this.dataInfo.conversionAreas = area(
            [
              this.dataInfo.consumerEntity.provinceCode,
              this.dataInfo.consumerEntity.cityCode,
              this.dataInfo.consumerEntity.districtCode,
            ],
            this.city
          );
          this.dataInfo.time1 = formatDateTes(this.dataInfo.createTime);
          if (this.dataInfo.endTime) {
            this.dataInfo.time2 = formatDateTes(this.dataInfo.endTime);
          } else {
            this.dataInfo.time2 = "";
          }
        } else {
          this.$message.error(res.msgInfo);
        }
      });
    },

    getstatistics() {
      let params = {
        consumer: this.filter.consumer.trim(),
        projectName: this.filter.projectName.trim(),
        reviewer: this.filter.reviewer.trim(),
        projectManager: this.filter.projectManager.trim(),
        status: "",
        startTime: this.filter.time ? this.filter.time[0] : "",
        endTime: this.filter.time ? this.filter.time[1] : "",
        pageSize: 10,
        pageNo: this.pageno,
        status: this.filter.status,
      };
      getStatistics(params, localStorage.getItem("authorization")).then((res) => {
        if (res.msgCode == 1) {
          this.staticList = res.data.content;
          this.totalNumber = res.data.totalSize;
          for (let i = 0; i < this.staticList.length; i++) {
            if (!this.staticList[i].exe_time) continue;
            this.staticList[i].exe_time = formatDateTes(this.staticList[i].exe_time);
          }
        } else {
          this.$message.error(res.msgInfo);
        }
      });
    },

    viewDetails(id) {
      if (!this.haveDataDetail) {
        this.$message({
          message: "抱歉，您没有查看详情权限！",
          type: "warning",
        });
        return;
      }
      this.showDetail = true;
      this.getStatisticsDetail(id);
    },

    hiddenDetail() {
      this.showDetail = false;
    },
  },
};
</script>
<style lang="stylus" scoped>
.submit-title {
  padding: 13px 0 0 30px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

>>>.el-select {
  width: 240px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-sizing: border-box;
}

>>>.el-input .el-input--suffix {
  width: 240px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-sizing: border-box;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-input__icon {
  line-height: 36px;
}

>>>.el-date-editor .el-range-separator {
  width: 8%;
}

>>>.el-input__icon {
  line-height: 28px;
}

>>>.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 260px;
}

.record-form {
  position: relative;
  bottom: 28px;
  // min-width: 1200px;
  margin: 36px 30px 0 30px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  overflow: hidden;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.el-date-editor .el-range__close-icon {
  line-height: 30px;
}

.icon {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: rgba(87, 197, 66, 1);
  border-radius: 50%;
  margin-right: 8px;
}

.icons {
  display: flex;
  align-items: center;
}

.iconred {
  background: rgba(246, 108, 111, 1);
}

.icongary {
  background: rgba(217, 217, 217, 1);
}

>>>.el-table__row {
  height: 52px !important;
}

>>>.el-table .cell {
  line-height: 1.1;
}

>>>.el-table tr td:last-child {
  margin-left: 50px;
}

.discount-btn {
  cursor: pointer;
  color: rgba(47, 84, 235, 1);
  font-size: 14px;
}

.discount-btn1 {
  margin-left: 12px;
}

.discount-btn2 {
  display: inline-block;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  color: #fff;
  padding: 6px 10px;
}

.dataDetail {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 496px;
  background: rgba(251, 251, 251, 1);
  box-shadow: -4px 0px 16px 0px rgba(0, 0, 0, 0.08);
  z-index: 1007;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

.circle0 {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: rgba(82, 196, 26, 1);
  border-radius: 50%;
  margin-right: 8px;
}

.circle1 {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #F5222D;
  border-radius: 50%;
  margin-right: 8px;
}

.form-item {
  margin-right: 40px;
  // margin-bottom: 16px;
  display: flex;
  align-items: center;

  .label {
    white-space: nowrap;
    width: 80px;
    text-align: right;
  }
}

.btn-look-up {
  margin-left: 20px;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

.filter-body {
  margin: 20px;
  padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  // padding: 20px;
  // padding-top: 20px;
  // padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-top: 16px;

  .inputs-wrapper {
    width: 92%;

    .inputs-wraper-row {
      display: flex;
      flex-wrap: wrap;

      .form-item {
        margin-right: 40px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .label {
          white-space: nowrap;
          width: 69px;
          text-align: right;
          margin-right: 8px;
        }

        display: flex;
      }
    }
  }

  .btn-look-up {
    margin-right: 16px;
    width: 76px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #2F54EB;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.circle2 {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #FAAD14;
  border-radius: 50%;
  margin-right: 8px;
}

.circle3 {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  margin-right: 8px;
}

.pagination-low {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}

>>>.el-select {
  width: 240px;
}

>>>.el-input {
  width: 240px;
}

>>>.el-pagination__editor.el-input {
  width: 50px;
}

.addItem {
  display: inline-block;
  width: 76px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 30px;
  cursor: pointer;
}

.addItem:hover {
  background: rgba(47, 34, 235, 1);
}

>>>.el-range-editor.el-input__inner {
  width: 240px;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.submit-title {
  padding: 0;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  padding-left: 30px;
}

>>>.el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

>>>.el-input__icon {
  width: 14px;
}

>>>.el-range-editor.el-input__inner {
  padding: 3px 8px;
}

.search-btna {
  width: 76px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  margin-left: 10px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  margin-right: 16px;
}

.search-btna:hover {
  background: rgba(47, 54, 235, 1);
}

>>>.el-date-editor .el-range-separator {
  padding: 0 3px;
}

.fades-enter-active {
  transition: all 0.3s ease;
}

.fades-leave-active {
  transition: all 0.3s ease;
}

.fades-enter, .fades-leave-to {
  opacity: 0;
}

.containerMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1002;
}
</style>
