<template>
  <div>
    <!--上传了哪些表-->
    <CheckTable
      :bankId="this.bankId"
      :productID="this.productID"
      :zqs="zqs"
      ref="checkTable"
      @BtnGenClicked="handleGenBtnClicked"
    ></CheckTable>
    <!--/上传了哪些表-->
    <!--检测动效-->
    <loadingTest @complete="handleLoadingTestComplete" ref="loadingTest"></loadingTest>
    <!--/检测动效-->
  </div>
</template>

<script>
import CheckTable from "../UploadTable/components/CheckTable";
import LoadingTest from "../UploadTable/components/LoadingTest";
import { previousZq } from "@/utils/helper";
import { mapState } from "vuex";
import { genTestReport, mateGoods } from "@/api/api";

export default {
  components: {
    CheckTable,
    LoadingTest
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID"])
  },
  props: {
    bankId: String,
    productID: [String, Number],
    productName: String,
    uuid: String,
    zq: String,
    version: [Number, String],
    inVoiceMobile: [String, Number],
    inVoiceTaxPayerID: [String, Number],
    inVoiceEmail: String,
    inVoiceCompanyName: String
  },
  data: function() {
    return {
      zqs: []
    };
  },
  mounted() {
    this.actualZq = previousZq(this.zq);
    this.zqs.push(this.zq);
    this.zqs.push(this.actualZq);
  },
  methods: {
    //确认检测对话框的开始检测按钮按下
    handleGenBtnClicked() {
      this.$refs["checkTable"].showCheckports = false;
      this.$refs["loadingTest"].open();
    },
    //检测动画结束
    handleLoadingTestComplete() {
      this.handleBtnStartClick();
    },
    handleBtnNext2Click() {
      this.$refs["checkTable"].open();
    },
    async handleBtnStartClick() {
      // this.tableCards.reduce(uploaded=>{
      //   return uploaded+uploaded;
      // },0)
      this.loading1 = true;
      if (!this.$store.state.UploadTable.permissionID) {
        //this.$alert("权限校验已过期，请重新操作").then(() => {
        this.$router.push({ name: "test-hub" });
        //});
      } else {
        let params = {
          token: this.$store.state.authorization
        };
        await mateGoods(params).then(res => {
          if (res.msgCode == 1) {
            this.$store.commit("setGoods", res.data);
            this.productid = res.data.id;
            this.bankid = res.data.bankId;
            this.produName = res.data.name;
          } else {
            this.$message({
              message: res.msgInfo,
              type: "error"
            });
          }
        });
        // const uuidRes = await getUUID();
        // const uuid = uuidRes.data.uuid;
        // console.log(this.inVoiceMobile);
        // console.log(this.inVoiceTaxPayerID);
        // console.log(this.inVoiceEmail);
        // console.log(this.inVoiceCompanyName);
        const zq = this.zq;
        const gentestReportRes = await genTestReport({
          bankId: this.bankid,
          goodsId: this.productid,
          kjzd: this.companyInfo.kjzd,
          token: this.authorization,
          nsrsbh: this.companyInfo.nsrsbm,
          tag: this.version,
          id: this.$store.state.UploadTable.permissionID,
          uuid: this.uuid,
          zq,
          invoice_nsrsbh: this.inVoiceTaxPayerID,
          invoice_email: this.inVoiceEmail,
          invoice_company_name: this.inVoiceCompanyName,
          invoice_phone: this.inVoiceMobile
        });

        if (gentestReportRes.msgCode == 1) {
          this.loading1 = false;
          //设置permissionID
          this.$store.commit("UploadTable/setPermissionID", {
            permissionID: ""
          });

          this.$store.commit("Result/setIsFromUpload", true);

          this.$router.push({
            name: "result",
            params: { uuid: this.uuid },
            query: {
              bankId: this.bankid,
              zq,
              uuid: this.uuid,
              nsrsbh: this.companyInfo.nsrsbm,
              name: this.produName
            }
          });
          this.showCheckports = false;
        } else {
          this.$alert(gentestReportRes.msgInfo);
          this.loading1 = false;
        }
      }
    }
  }
};
</script>

<style lang="stylus" scoped></style>