<template>
  <el-dialog title="客户分配" :visible.sync="showCustomDis" width="640px">
    <div class="custon-distri">
      <div class="custon-name custon-right">
        待分配客户（{{ nameOptions.length }}个）名称：
      </div>
      <div class="custon-name">指定项目经理：</div>
    </div>
    <div class="custon-distri custon-distri-posi">
      <i class="el-icon-arrow-right el-icon-arrow-icon"></i>
      <div class="custon-right custon-select">
        <div class="custon-select-item" v-for="item in nameOptions">
          {{ item.companyName }}
        </div>
      </div>
      <div>
        <el-select v-model="role" placeholder="请选择">
          <el-option
            v-for="(item, index) in roleOptions"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="addCustomDis"
        :loading="addLoading"
        :disabled="!role"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { allocationConsumer } from "@/api/api";

export default {
  props: {
    nameOptions: [Array, Object],
    roleOptions: [Array, Object],
  },
  data() {
    return {
      showCustomDis: false,
      name: "",
      role: "",
      addLoading: false,
    };
  },
  methods: {
    addCustomDis() {
      let params = {
        consumerList:
          this.nameOptions &&
          this.nameOptions.map((item) => {
            return {
              consumerId: item.consumerId,
              projectManagerId: item.projectManagerId,
            };
          }),
        userName: this.role,
      };
      this.addLoading = true;
      allocationConsumer(params).then((res) => {
        this.addLoading = false;
        if (res.code == 200) {
          this.$emit("addCustomDistribution");
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.custon-distri {
  display: flex;
  align-items: center;


  .custon-name {
    width: 240px;
    text-align: left;
    margin-bottom: 12px;
  }

  .custon-right {
    margin-right: 50px;
    width: 280px;
  }

  .el-icon-arrow-icon {
    position: absolute;
    // top: 36px;
    left: 296px;
    font-size: 24px;
    color: #C0C4CC;
  }

  .custon-select {
    width: 280px;
    max-height: 150px;
    border: 1px solid #C0C4CC;
    border-radius: 8px;
    overflow-x: auto;
    padding: 6px 10px;
    box-sizing: border-box;
  }

  .custon-select-item {
    margin-bottom: 12px;
  }

  .custon-select-item:last-child {
    margin-bottom: 0;
  }
}

.custon-distri-posi {
  position: relative;
}
</style>
