import { render, staticRenderFns } from "./registerBase.vue?vue&type=template&id=2f8ff5d0&scoped=true&"
import script from "./registerBase.vue?vue&type=script&lang=js&"
export * from "./registerBase.vue?vue&type=script&lang=js&"
import style0 from "./registerBase.vue?vue&type=style&index=0&id=2f8ff5d0&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f8ff5d0",
  null
  
)

export default component.exports