<template>
  <div class="detail-item" v-loading="detailLoading">
    <div class="detail-item-top">
      <img
        @click="hiddenDetail"
        class="fixed-item-icon cursion"
        src="@/assets/test/arrow_right.png"
        alt
      />
      <div>客户详情</div>
    </div>
    <div class="detail-content" :class="{ fixedItemEdit: isEditCustom }">
      <div class="content-title" :class="{ editActive: isEditCustom }">
        纳税人识别号：
      </div>
      <div class="content-text" v-if="!isEditCustom">
        {{ customItems.nsrsbh }}
      </div>
      <div
        class="content-text content-isEdit addForms"
        id="content-isEdit"
        v-if="isEditCustom"
      >
        <el-input
          v-model.trim="currentEdits.nsrsbh"
          placeholder="请输入"
          maxlength="20"
          show-word-limit
        ></el-input>
        <span class="project-input-b" v-show="addForm[0]">纳税人识别号不能为空</span>
        <span class="project-input-b" v-show="addForm[1]"
          >最低15位最多20位数字+字母组合</span
        >
      </div>
      <div class="content-title" :class="{ editActive: isEditCustom }">企业名称：</div>
      <div class="content-text" v-if="!isEditCustom">
        {{ customItems.companyName }}
      </div>
      <div
        class="content-text content-isEdit addForms"
        id="content-isEdit"
        v-if="isEditCustom"
      >
        <el-input
          v-model="currentEdits.companyName"
          placeholder="请输入"
          maxlength="30"
          show-word-limit
          disabled
        ></el-input>
        <span class="project-input-b" v-show="addForm[2]">客户名称不能为空</span>
      </div>
      <div class="content-title" :class="{ editActive: isEditCustom }">所属地区：</div>
      <div class="content-text" v-if="!isEditCustom">
        <span v-if="customItems.cityCode">{{ conversionArea }}</span>
        <span v-else>暂无地区信息</span>
      </div>
      <div
        class="content-text content-isEdit addForms"
        id="content-isEdit"
        v-if="isEditCustom"
      >
        <el-cascader
          :options="city"
          v-model="currentEdits.cityCodeList"
          @change="citychange"
        ></el-cascader>
        <span class="project-input-b" v-show="addForm[3]">客户地区不能为空</span>
      </div>
      <div class="content-title" :class="{ editActive: isEditCustom }">所属行业：</div>
      <div class="content-text" v-if="!isEditCustom">
        <span v-if="dataList">{{ dataList[customItems.industry] }}</span>
      </div>
      <div
        class="content-text content-isEdit addForms"
        id="content-isEdit"
        v-if="isEditCustom"
      >
        <el-select v-model="currentEdits.industry" placeholder="请选择" filterable>
          <el-option
            v-for="item in hyData"
            :key="item.hy_dm"
            :label="item.hymc"
            :value="item.hy_dm"
          ></el-option>
        </el-select>
        <span class="project-input-b" v-show="addForm[4]">行业不能为空</span>
      </div>
      <div class="content-title">企业规模：</div>
      <div class="content-text" v-if="!isEditCustom">
        {{ customItems.scale }}
      </div>
      <div
        class="content-text content-isEdit addForms"
        id="content-isEdit"
        v-if="isEditCustom"
      >
        <el-select v-model="currentEdits.scale" placeholder="请选择">
          <el-option
            v-for="item in qyStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="content-title">联系人：</div>
      <div class="content-text" v-if="!isEditCustom">
        {{ customItems.contact }}
      </div>
      <div
        class="content-text content-isEdit addForms"
        id="content-isEdit"
        v-if="isEditCustom"
      >
        <el-input v-model="currentEdits.contact" placeholder="请输入"></el-input>
      </div>
      <div class="content-title">联系电话：</div>
      <div class="content-text" v-if="!isEditCustom">
        {{ customItems.contactNumber }}
      </div>
      <div
        class="content-text content-isEdit addForms"
        id="content-isEdit"
        v-if="isEditCustom"
      >
        <el-input v-model="currentEdits.contactNumber" placeholder="请输入"></el-input>
      </div>
      <div class="content-title">业务员：</div>
      <div class="content-text" v-if="!isEditCustom">
        {{ customItems.salesman }}
      </div>
      <div
        class="content-text content-isEdit addForms"
        id="content-isEdit"
        v-if="isEditCustom"
      >
        <el-input v-model="currentEdits.salesman" placeholder="请输入"></el-input>
      </div>
    </div>
    <div class="content-btn ease" @click="detailEdit" v-show="!isEditCustom">编辑</div>
    <div class="content-btn ease detailContent" v-show="isEditCustom">
      <div class="detailcancel" @click="removeEdits">取消</div>
      <div class="detailSaves" @click="holds">保存</div>
    </div>
  </div>
</template>

<script>
import { qyStatusList } from "../help.js";
import { regionData } from "@/dependencies/element-china-area-data.js";
import { veriFiItem, area } from "@/pages/product/utils/limit.js";
import { editCustom, getRegion, getCompanyMsgByTaxNo } from "@/api/api";
import { cloneDeep } from "lodash";

export default {
  props: {
    customItems: [Object, Array],
    hyData: [Object, Array],
    dataList: [Object, Array],
    conversionArea: [String],
    haveCusEdit: [Boolean],
    city: [Object, Array],
  },
  data() {
    return {
      isEditCustom: false,
      detailLoading: false,
      currentEdits: null,
      addForm: [0, 0, 0, 0, 0],
      qyStatusList: qyStatusList,
      detailAddForm: false,
      checkTaxNo: false,
      companyList: {},
    };
  },
  methods: {
    // 地区变化
    citychange() {
      this.currentEdits.provinceCode = this.currentEdits.cityCodeList[0];
      this.currentEdits.cityCode = this.currentEdits.cityCodeList[1];
      this.currentEdits.districtCode = this.currentEdits.cityCodeList[2];
    },
    hiddenDetail() {
      this.$emit("hiddenDetail");
    },
    // 保存编辑
    holds() {
      if (!this.haveCusEdit) {
        this.$message({
          message: "抱歉，您没有编辑客户权限！",
          type: "warning",
        });
        return;
      }
      this.addForm = [0, 0, 0, 0, 0];
      let verification = veriFiItem(this.currentEdits, 2);
      this.addForm[verification.code] = 1;
      this.addForm.reverse().reverse();
      if (!verification.status) return;
      var uPattern = /^[a-zA-Z0-9_]{0,}$/;
      if (
        this.currentEdits.nsrsbh.trim().length > 20 ||
        this.currentEdits.nsrsbh.trim().length < 15
      ) {
        this.detailAddForm = true;
        return;
      }
      if (!uPattern.test(this.currentEdits.nsrsbh)) {
        this.detailAddForm = true;
        return;
      }
      this.currentEdits.provinceCode = this.currentEdits.cityCodeList[0];
      this.currentEdits.cityCode = this.currentEdits.cityCodeList[1];
      this.currentEdits.districtCode = this.currentEdits.cityCodeList[2];

      this.detailLoading = true;
      getCompanyMsgByTaxNo(this.currentEdits.nsrsbh).then((res) => {
        if (res.msgCode == 1) {
          this.companyList = res.data;
          this.currentEdits.companyName = this.companyList.name;

          editCustom(this.currentEdits).then((res) => {
            this.detailAddForm = false;
            this.detailLoading = false;
            if (res.msgCode == 1) {
              this.$notify({
                title: "温馨提示",
                message: "修改客户成功",
                type: "success",
              });
              this.$emit("editCustomSuccess", this.currentEdits);
              this.isEditCustom = false;
            }
          });
          return;
        } else {
          this.detailLoading = false;
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
        }
      });
    },
    detailEdit() {
      this.addForm = [0, 0, 0, 0, 0, 0, 0];
      this.currentEdits = JSON.parse(JSON.stringify(this.customItems));
      this.currentEdits.cityCodeList = [
        this.customItems.provinceCode && this.customItems.provinceCode.toString(),
        this.customItems.cityCode && this.customItems.cityCode.toString(),
        this.customItems.districtCode && this.customItems.districtCode.toString(),
      ];

      this.isEditCustom = true;
    },
    // 取消编辑
    removeEdits() {
      this.detailAddForm = false;
      this.isEditCustom = false;
      this.currentEdits = {};
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../common.css';

.detail-item {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  width: 496px;
  height: 100%;
  z-index: 1003;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(251, 251, 251, 1);
  box-shadow: -4px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.detail-item-top {
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid rgba(218, 213, 232, 0.6);
}

.detail-content {
  width: 464px;
  // height: 636px;
  background: rgba(240, 245, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(214, 228, 255, 1);
  margin: 16px 0 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

// 屏幕适配
@media screen and (max-height: 800px) {
  .detail-content {
    max-height: 455px;
    overflow-x: hidden;
  }
}

.content-title {
  margin: 16px 0 0 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
}

.fixedItemEdit {
  height: 716px;
}

.editActive:before {
  content: '*';
  color: red;
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.content-text {
  margin: 4px 0 24px 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.content-isEdit {
  margin-bottom: 16px;
}

>>> #content-isEdit .el-input {
  width: 416px;
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #content-isEdit .el-cascader {
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input__icon {
  line-height: 36px;
}

.project-input, .addForms {
  position: relative;
}

.project-input-b {
  position: absolute;
  right: 40px;
  top: 40px;
  font-size: 12px;
  color: red;
}

.content-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  line-height: 60px;
  background: rgba(47, 84, 235, 1);
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  cursor: pointer;
}

.content-btn:hover {
  background: rgba(47, 34, 235, 1);
}

.detailContent {
  display: flex;
  align-item: center;
}

.detailcancel {
  width: 50%;
}

.detailcancel:after {
  content: '|';
  position: relative;
  left: 108px;
  top: 1px;
}

.detailSaves {
  width: 50%;
}

.fixed-item-icon {
  width: 30px;
  height: 30px;
  padding: 20px 8px 16px 20px;
}
</style>
