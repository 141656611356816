<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/tools/toolsAudit' }"
          >小工具
          </el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/tools/toolsAudit' }"
          >审计小工具
          </el-breadcrumb-item
          >
          <el-breadcrumb-item>查看分析结果</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">查看分析结果</template>
      </main-header>
    </div>
    <div>
      <div class="audit-container" @click="selectAct">
        <div class="audit-tab" data-act="0" :class="{ 'audit-act': titleAct == 0 }">
          购入技术服务情况
        </div>
        <div class="audit-tab" data-act="1" :class="{ 'audit-act': titleAct == 1 }">
          提供技术服务的单位无经营资质
        </div>
        <div
          class="audit-tab audit-tabRight"
          data-act="2"
          :class="{ 'audit-act': titleAct == 2 }"
        >
          货物购销发票与申报表对比
        </div>
      </div>
      <div class="audit-content">{{ titleList[titleAct]['title'] }}
        <div class="tip">{{ titleList[titleAct]['tip'] }}</div>
      </div>
      <div class="audit-select" v-if="titleAct != 2">
        年度筛选：
        <el-radio-group v-model="yearValue[titleAct]" @change="selectGetcustom">
          <el-radio-button label="全部"></el-radio-button>
          <el-radio-button label="2021"></el-radio-button>
          <el-radio-button label="2020"></el-radio-button>
          <el-radio-button label="2019"></el-radio-button>
        </el-radio-group>
        <!-- <el-select
          v-model="yearValue[titleAct]"
          clearable
          placeholder="请选择"
          @change="selectGetcustom"
        >
          <el-option
            v-for="item in yearOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
      </div>
    </div>

    <div class="content-body">
      <template v-if="titleAct == 0">
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" sortable></el-table-column>

          <el-table-column prop="zqYear" label="年度" min-width="6%"></el-table-column>
          <el-table-column
            prop="companyName"
            label="供应商名称"
            :show-overflow-tooltip="true"
            min-width="20%"
          ></el-table-column>
          <el-table-column
            prop="techologySum"
            label="技术服务发票不含税金额(万元)"
            :show-overflow-tooltip="true"
            min-width="18%"
          ></el-table-column>
          <el-table-column
            prop="sum"
            label="合计采购不含税金额(万元)"
            :show-overflow-tooltip="true"
            min-width="16%"
          ></el-table-column>
          <el-table-column
            prop="percentage"
            label="技术服务费占比(%)"
            :show-overflow-tooltip="true"
            min-width="14%"
          ></el-table-column>
        </el-table>
      </template>
      <template v-if="titleAct == 1">
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" sortable></el-table-column>

          <el-table-column prop="zqYear" label="年度" min-width="6%"></el-table-column>
          <el-table-column
            prop="companyName"
            label="供应商名称"
            :show-overflow-tooltip="true"
            min-width="20%"
          ></el-table-column>
          <el-table-column
            prop="businessScope"
            label="经营范围"
            min-width="24%"
          ></el-table-column>
          <el-table-column
            prop="techologySum"
            label="技术服务发票不含税金额(万元)"
            :show-overflow-tooltip="true"
            min-width="18%"
          ></el-table-column>
          <el-table-column
            prop="sum"
            label="合计采购不含税金额(万元)"
            :show-overflow-tooltip="true"
            min-width="16%"
          ></el-table-column>
          <el-table-column
            prop="percentage"
            label="技术服务费占比(%)"
            :show-overflow-tooltip="true"
            min-width="14%"
          ></el-table-column>
        </el-table>
      </template>
      <template v-if="titleAct == 2">
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column type="index" label="序号" sortable></el-table-column>

          <el-table-column prop="zqYear" label="年度" min-width="6%"></el-table-column>
          <el-table-column
            prop="uninvoicedSales"
            label="未开具发票销售额(万元)"
            :show-overflow-tooltip="true"
            min-width="13%"
            align="center"
          >
            <template slot="header">
              <div>未开具发票销售额(万元)</div>
              <div>【1】</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="entryGoodsMoney"
            label="采购13%税率商品不含税金额(万元)"
            :show-overflow-tooltip="true"
            min-width="18%"
            align="center"
          >
            <template slot="header">
              <div>采购13%税率商品不含税金额(万元)</div>
              <div>【2】</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="salesGoodsMoney"
            label="销售13%税率商品不含税金额(万元)"
            :show-overflow-tooltip="true"
            min-width="16%"
            align="center"
          >
            <template slot="header">
              <div>销售13%税率商品不含税金额(万元)</div>
              <div>【3】</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="cye"
            label="差异额(万元)"
            :show-overflow-tooltip="true"
            min-width="14%"
            align="center"
          >
            <template slot="header">
              <div>差异额(万元)</div>
              <div>【4=2-3-1】</div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <el-pagination
      class="pagination-low"
      @current-change="handleCurrentChange"
      :current-page="pageno"
      layout="total, prev, pager, next, jumper"
      :total="totalNumber"
      :haveTotal="haveTotal"
    ></el-pagination>
  </div>
</template>

<script>
import {getCustomListNew, getAnalysisResults} from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";

const moment = require("moment");

export default {
  components: {
    MainHeader,
    BreadCrumb,
  },

  data() {
    return {
      titleAct: 0,
      titleList: [
        {
          title: '购入技术服务情况',
          tip: '如无记录表示进项发票中查无“技术服务”发票，请知悉。'
        },
        {
          title: '提供技术服务的单位无经营资质',
          tip: '经营范围不包含技术服务的企业将被判定为无经营资质'
        },
        {
          title: '货物购销发票与申报表对比',
          tip: '1.采购13%税率商品不包含：“劳务”“服务”“硒鼓”“纸制品”“计算器”“计算机”“家具”“文具”“设备”。 2.发票取数范围近36个月，因此第一年数据可能不完整。'
        }
      ],
      yearOptions: [
        {
          value: "2021",
          label: "2021",
        },
        {
          value: "2020",
          label: "2020",
        },
        {
          value: "2019",
          label: "2019",
        },
      ],
      haveTotal: true,
      totalNumber: 10, //总数
      pageno: 1,
      tableDataList: null,
      yearValue: ["全部", "全部"],
    };
  },

  mounted() {
    this.getcustomList();
  },

  methods: {
    selectGetcustom() {
      this.pageno = 1;
      this.getcustomList();
    },
    selectAct(data) {
      let act = data.target.dataset.act;
      if (!act) return;
      this.titleAct = act;
      this.getcustomList();
    },
    dateFormatter(row, column) {
      return moment(row.exe_time).format("YYYY-MM-DD HH:mm:ss");
    },
    addTest() {
      this.$router.push({name: "authorization"});
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageno = val;
      this.getcustomList();
    },
    // 获取客户列表
    getcustomList() {
      var year = this.yearValue[this.titleAct];
      if (year == "全部") {
        year = "";
      }
      let params = {
        pageSize: 10,
        pageNum: this.pageno,
        status: Number(this.titleAct) + 1,
        zqYear: year,
        programId: this.$route.query.programId,
      };
      getAnalysisResults(params).then((res) => {
        // console.log(res);
        this.tableDataList = res.list;
        this.totalNumber = Number(res.pageTotal);
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

.audit-select >>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.el-icon-edit-outline {
  color: #2f54eb;
  font-size: 18px;
  margin-left: 4px;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.pagination-low {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}

.audit-select >>> .el-select {
  width: 240px;
}

.audit-select >>> .el-input {
  width: 240px;
}

.audit-select >>> .el-range-editor.el-input__inner {
  width: 240px;
}

>>> .bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

>>> .el-input__icon {
  line-height: 28px;
}

.audit-content {
  margin: 0 20px;
  padding: 14px 20px;
  background: #fff;
  border-radius: 12px;
  color: rgba(0, 0, 0, 0.6);
  display flex;

  .tip {
    margin-left 10px
    color #ff0000
    font-size 14px
  }
}

.audit-select {
  margin: 10px 0 0 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.audit-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;

  .audit-tab {
    width: 260px;
    height: 49px;
    line-height: 49px;
    text-align: center;
    background: #fff;
    -webkit-box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 18px 18px 0px 0px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.4);
    margin-right: 20px;
  }

  .audit-tabRight {
    margin-right: 0;
  }
}

.audit-container .audit-act {
  color: #2f54eb;
}
</style>
