var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"content"},_vm._l((_vm.testList),function(item){return _c('div',[(item.show_status == 1)?_c('div',{staticClass:"item"},[_c('router-link',{staticClass:"cursion-item",attrs:{"tag":"a","target":"_blank","to":{
            name: 'result-detect-always',
            params: {
              uuid: item.uuid,
              nsrsbh: item.nsrsbh,
              action: 'edit',
            },
          }}},[_c('span',{staticClass:"exe-report"},[_vm._v("风险检查报告")]),_c('span',{staticClass:"exe-time"},[_vm._v(_vm._s(item.exe_time))])])],1):_c('div',{staticClass:"item"},[_c('span',{staticClass:"exe-report"},[_vm._v("风险检查报告")]),_c('span',{staticClass:"exe-time"},[_vm._v("待生成")])])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v("项目检测")])])
}]

export { render, staticRenderFns }