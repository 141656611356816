<template>
  <div class="table-wrapper" :class="{default:!width}">
    <div class="btn-toolbar" :class="{'left-align':data.floor==2}">
      <button class="btn btn-primary btn-show-dialog" v-if="data.anchorPointType=='invoice-model'"
              @click="handleShowInvoiceModelDialog">发票
      </button>
    </div>

    <table class="table" :style="{width}">
      <thead>
      <tr>
        <th v-for="(label, index) in labels" :key="index" :style="getThStyle(label)">
          {{ label }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, index) in tableData" :key="index">
        <td v-for="(col, index2) in row" :key="index2"
            :style="{'text-align':getAlign(labels[index2])}">
          <el-tooltip class="item" effect="light" :content="tipText(index,index2)"
                      placement="top-start"
                      :disabled="!tipText(index,index2)">
            <span v-if="col == 'button{detail}'"
                  class="clickable"
                  @click="handleCellClick(index)"
                  :class="{'text-has-tip':tipText(index,index2)}"
            ><a
              href="#"
              @click.prevent="handleToggleDetailCLick"
              class="link"
            >{{ toggleLabel }}</a
            ></span
            >
            <span v-else v-html="col"
                  :class="{'text-has-tip':tipText(index,index2),'text-has-table':!!subTable(index,index2)}"
                  @click="handleSubTableLinkClick(subTable(index,index2),index,index2)"></span>
          </el-tooltip>
        </td>
      </tr>
      </tbody>
    </table>

    <el-drawer
      :title="subTableInDrawer?subTableInDrawer.name:''"
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose">
      <div class="drawer-inner">
        <GenericTable
          v-if="generalTableData"
          :data="generalTableData"
        ></GenericTable>
      </div>

    </el-drawer>


    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="1240px"
      :z-index="2010"
      :before-close="handleDialogClose">
      <ReportBill :tax-no="$route.query.taxNo" :uuid="$route.params.uuid"
                  :model-id="data.modelId"></ReportBill>

    </el-dialog>
  </div>
</template>

<script>

import ReportBill from "@/pages/tools/bill/report-bill.vue";

const alignRightKeywords = [
  '本期期末余额借方',
  '本期期末余额贷方',
  '本期行业平均上期',
  '发票数量',
  '价税合计',
  '占比税额',
  '金额',
  '占比',
  '税额',
  '开票数量',
  '被执行标的金额合计',
  '涉案案件次数',
  '采购价税合计',
  '销售价税合计',
  '均值',
  '2022年',
  '2021年',
  '2020年',
  '本期',
  '上期',
  '数量',
  '计税合计金额'
]

export default {
  name: "GenericTable",
  components: {
    ReportBill
  },
  props: {
    width: {
      type: [String, Number],
      default: '1200px'
    },
    data: Object,
    drawerTriggerIndex: Number,
    showDetails: Boolean,
    tips: Array,
    subTables: Array,
  },
  computed: {

    tableData() {
      return this.data.data;
    },
    labels() {
      return this.data.labels;
    },
    toggleLabel() {
      return this.showDetails ? "收起" : "展开";
    },
    //通用表格
    generalTableData() {
      if (!this.subTableInDrawer) {
        return null;
      }
      const ret = [];
      for (let i = 0; i < this.subTableInDrawer.detailedTable.body.length; i++) {
        ret[i] = [];

        this.subTableInDrawer.detailedTable.header.forEach(key => {

          ret[i].push(this.subTableInDrawer.detailedTable.body[i][key])
        })

      }


      return {
        data: ret,
        labels: this.subTableInDrawer.detailedTable.header,
        anchorPointType: this.subTableInDrawer.detailedTable.anchorPointType,
        modelId: this.data.modelId,
        floor: 2
      };
    },
  },
  data() {
    return {
      drawer: false,
      dialogVisible: false,
      subTableInDrawer: null
    };
  },
  methods: {
    getThStyle(label) {
      const tableWidth = parseInt(this.width.replace("px", ""));
      const ret = {
        'text-align': this.getAlign(label),
      }
      if(this.labels.length*100 <= tableWidth){
        ret["min-width"] = '100px'
      }else {
        ret["width"] = Math.min(tableWidth / this.labels.length) + 'px'
      }
      return ret;
    },
    handleDialogClose() {
      this.dialogVisible = false
    },
    handleShowInvoiceModelDialog() {
      this.dialogVisible = true
    },
    guessThStyle(label, index) {
      let ret = {
        'max-width': '300px',
        'min-width': '100px'
      }
      if (label.includes('序号') || label.includes('行次')) {
        ret = {width: '30px', 'min-width': '0px', 'text-align': 'center'}
      } else if (label.includes('日期')) {
        ret = {width: '100px'}
      } else if (label.includes('时间')) {
        ret = {width: '100px'}
      } else if (label.includes('地区')) {
        ret = {width: '100px'}
      } else if (label.includes('信息类型')) {
        ret = {width: '100px'}
      } else if (label.includes('变更类型')) {
        ret = {width: '250px'}
      } else if (label.includes('资产（单位：万元）') || label.includes('负债（单位：万元）')) {
        ret = {width: '450px'}
      } else if (label.includes('最近2年连续供应年限')) {
        ret = {width: '100px', 'white-space': 'wrap'}
      }
      return ret
    },
    getAlign(label, index) {
      if (label == null) {
        return 'justify'
      }
      if (alignRightKeywords.find(item => label.trim().startsWith(item))) {
        return 'right'
      } else {
        return 'justify'
      }


    },
    guessTdStyle(value, index, label) {
      if (value == null) {
        return {};
      }
      if (typeof value == 'string') {
        value = value.replace(/%$/, '')
      }

      let ret = {}
      value = value.toString().replace(/%$/, '')
      // value = value.replace(/万/,'')
      // value = value.replace(/人民币/,'')
      // value = value.replace(/元/,'')
      // value = value.replace(/升/,'')
      // value = value.replace(/月/,'')
      // value = value.replace(/年/,'')
      if (index === 0) {
        ret = {
          'text-align': 'center'
        }
      }
      if (label == '状态') {
        ret = {'text-align': 'justify'}
      } else if (label.includes('日期') || label.includes('月份')) {
        ret = {'text-align': 'justify'}
      } else if (label.includes('时间')) {
        ret = {'text-align': 'justify'}
      } else if (value == '-') {
        ret = {
          'text-align': 'center'
        }
      } else if (!isNaN(value)) {
        ret = {
          'text-align': 'right'
        }
      } else {
        ret = {
          'text-align': 'justify'
        }
      }
      return ret
    },
    handleSubTableLinkClick(tableData, index, index2) {
      if (!tableData) {
        return
      }
      this.subTableInDrawer = tableData
      this.drawer = true
    },
    handleClose() {
      this.drawer = false
    },
    //点击打开表格
    subTable(row, col) {
      let ret = null;
      if (!this.subTables) {
        return null
      }
      const table = this.subTables.find(v => {
          let isShowTipInRow = false
          if (v.pos[0] == -1 || v.pos[0] == row) {
            isShowTipInRow = true
          }


          let isShowTipInCol = false
          if (v.pos[1] == -1 || v.pos[1] == col) {
            isShowTipInCol = true
          }
          if (isShowTipInRow && isShowTipInCol) {

            // debugger
          }
          return isShowTipInRow && isShowTipInCol
        }
      )
      if (table) {
        ret = {
          detailedTable: table.detailedTable,
          name: table.name
        }
      }

      return ret
    },
    //需要提示的文案
    tipText(row, col) {
      let ret = null;
      if (!this.tips) {
        return null
      }
      const tip = this.tips.find(v => {
          let isShowTipInRow = false
          if (v.pos[0] == -1 || v.pos[0] == row) {
            isShowTipInRow = true
          }


          let isShowTipInCol = false
          if (v.pos[1] == -1 || v.pos[1] == col) {
            isShowTipInCol = true
          }

          return isShowTipInRow && isShowTipInCol
        }
      )
      if (tip) {
        ret = tip.text
      }
      return ret
    },
    handleCellClick(row) {
      this.$emit("cellClick", row);
    }
    ,
    handleToggleDetailCLick() {
      this.$emit("update:showDetails", !this.showDetails);
    }
    ,

  },
}
;
</script>

<style lang="stylus" scoped>
.table-wrapper {

  margin-top: 22px;

  .btn-toolbar {
    display flex
    justify-content flex-end

    .btn-show-dialog {
      margin-left 0px
      margin-bottom 5px
    }
  }

  .btn-toolbar.left-align {
    justify-content flex-start
  }
}

.drawer-inner {
  overflow-y auto;
  padding: 25px;
  height calc(100vh - 70px);
  padding-top 0;
  box-sizing: border-box
}


.text-has-tip {
  text-decoration underline;
  cursor pointer
}

.text-has-table {
  color blue
  cursor pointer
  text-decoration underline;
}

.default {
  min-width: 819px;
  max-width: 1200px;
}

.table {
  width 100%
  border-collapse: collapse;
  border-spacing: 0;

  td, th {
    text-align: justify;
    font-size: 14px;
    padding: 8px 6px;
    border: 1px solid rgba(238, 238, 238, 1);
    color: rgba(0, 0, 0, 0.6);
    vertical-align: top;
    min-width: auto;

    .clickable {
      color: #2F54EB;
      cursor: pointer;
    }
  }

  th {
    font-weight: 600;
    background: rgba(240, 245, 255, 1);
  }

  tr:nth-child(even) {
    td {
      background: rgba(245, 245, 245, 1);
    }
  }

  .link {
    color: rgba(47, 84, 235, 1);
  }
}

>>> :focus {
  outline: none;
}
</style>
