<template>
  <div>
    <div class="content">
      <div class="title">
        <img
          v-if="option.icon == 1"
          class="icon"
          src="@/assets/test/shape-copy.png"
          alt=""
        />
        <img
          v-if="option.icon == 2"
          class="icon"
          src="@/assets/test/shape-copy2.png"
          alt=""
        />
        <div class="text">{{ option.title }}</div>
        <div class="close" v-if="option.close">
          <img
            @click="cancel"
            class="close-img"
            src="@/assets/test/part-close.png"
            alt=""
          />
        </div>
      </div>
      <div class="main" v-html="option.content">

      </div>
      <div class="press">
        <div @click="cancel" v-if="option.cancel" class="currency cancel">
          取消
        </div>
        <div class="currency submit" @click="submit">{{ option.submit }}</div>
      </div>
    </div>
    <div class="reminder" @click="cancel"></div>
  </div>
</template>

<script>
export default {
  props: {
    option: Object,
  },
  methods: {
    submit() {
      if (this.option.type == 1) {
        this.download();
        this.$emit("closeToast");
      } else {
        this.$emit("closeToast");
      }
    },
    cancel() {
      this.$emit("closeToast");
    },
    // 下载
    download() {
      var a = document.createElement("a");
      a.setAttribute(
        "href",
        "//shuian-file.oss-cn-hangzhou.aliyuncs.com/goods/AI%E7%A8%8E%E5%8A%A1%E5%B8%88.zip"
      );
      a.setAttribute("id", "startTelMedicine");
      // 防止反复添加
      if (document.getElementById("startTelMedicine")) {
        document.body.removeChild(document.getElementById("startTelMedicine"));
      }
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style lang="stylus" scoped>
.content {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -240px;
  margin-top: -240px;
  width: 480px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-sizing: border-box;
  z-index: 2003;

  .title {
    display: flex;
    align-items: center;
    padding: 32px 28px 0 32px;

    .text {
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
    }

    .icon {
      width: 22px;
      height: 22px;
      margin-right: 16px;
    }

    .close {
      flex: 1;
      cursor: pointer;

      .close-img {
        float: right;
        padding: 4px;
      }
    }
  }

  .main {
    padding: 8px 32px 0 70px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
  }

  .press {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
    font-size: 14px;
    padding-bottom: 24px;

    .currency {
      width: 76px;
      height: 36px;
      line-height: 36px;
      background: #2F54EB;
      text-align: center;
      color: #fff;
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid #2F54EB;
    }

    .submit {
      margin: 0 32px 0 16px;
    }

    .cancel {
      background: #FFFFFF;
      border: 1px solid #2F54EB;
      color: #2F54EB;
    }
  }
}

.reminder {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1002;
}
</style>