<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>小工具</el-breadcrumb-item>
          <el-breadcrumb-item>工商查询</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">工商查询</template>
      </main-header>
    </div>
    <div class="filter-body">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="label" style="margin-left: 15px; margin-top: 5px">企业信息查询:</div>
          <el-input
            placeholder="请输入企业名称、老板姓名、品牌名称等"
            clearable
            v-model="QueryInformation"
          ></el-input>
          <div class="btn-look-up search" @click="FuzzyQuery">马上查询</div>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="search-wrapper">
        <div class="search-input-wrapper">
          <div class="gutter-row">
            <div class="label">企业名称:</div>
            <el-input
              placeholder="请输入"
              clearable
              v-model.trim="filter.enterpriseName"
              @keyup.enter.native="search"
              @input="changeEnterpriseName"
            ></el-input>
          </div>
          <div class="gutter-row" :span="6">
            <div class="label">报告生成时间:</div>
            <el-date-picker
              style="width: 240px"
              v-model="filter.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="search"
            ></el-date-picker>
          </div>
          <div class="gutter-row">
            <div class="btn-look-up ease" @click="search">搜索</div>
          </div>
        </div>
      </div>
      <div class="table">
        <el-table :data="tableData" border style="width: 99%">
          <el-table-column fixed prop="date" type="index" label="序号" width="80" align="center">
          </el-table-column>
          <el-table-column prop="companyName" label="企业名称"> </el-table-column>
          <el-table-column prop="createTime" label="报告生成时间" sortable> </el-table-column>
          <el-table-column fixed="right" label="操作" width="180">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="text" size="small"
                >查看详情
              </el-button>
              <el-button
                type="text"
                size="small"
                style="color: gray"
                @click="handleDelClick(scope.row)"
              >
                删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          layout="prev, pager, next, jumper"
          :total="totalPages"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getWhetherPurchase, getQuerySectionList, getPageFun, postDelFun } from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import { tSIndexedAccessType } from "@babel/types";
export default {
  components: {
    MainHeader,
    BreadCrumb,
  },
  data() {
    return {
      haveTotal: true,
      QueryInformation: "",
      filter: {
        enterpriseName: "",
        time: [],
      },
      pageno: 1,
      companyName: "",
      startDate: "",
      endDate: "",
      pageNum: 1,
      pageSize: 10,
      tableData: [{}],
      totalPages: 100,
    };
  },

  mounted() {
    this.businessData();
  },
  methods: {
    //模糊搜索
    FuzzyQuery() {
      if (this.QueryInformation) {
        this.$router.push({ path: "/tools/fuzzyQuery/" + this.QueryInformation });
      } else {
        this.$message.error("搜索框不能为空");
      }
    },

    //工商全景画像信息报告分页
    businessData() {
      // let params = {
      //   companyName: this.filter.enterpriseName,
      //   startDate: this.filter.time ? this.filter.time[0] : "",
      //   endDate: this.filter.time ? this.filter.time[1] : "",
      //   pageNum: 1,
      //   pageSize: 10,
      // };

      getPageFun(
        this.filter.enterpriseName,
        this.filter.time && this.filter.time[0],
        this.filter.time && this.filter.time[1],
        this.pageNum,
        this.pageSize
      ).then((res) => {
        // getPageFun(params).then((res) => {
        this.tableData = res.list;
        this.totalPages = res.pageTotal;
      });
    },

    //搜索
    search() {
      this.pageNum = 1;
      this.businessData();
    },

    //企业名称
    changeEnterpriseName() {
      if (!this.filter.enterpriseName) {
        this.pageNum = 1;
        this.businessData();
      }
    },

    //查看全景报告
    handleClick(row) {
      this.$router.push({ path: "/jinzhiReport/" + row.programId });
    },
    //删除全景报告
    async handleDelClick(row) {
      this.$confirm(`确定删除工商全景报告吗?`, "温馨提示", {
        confirmButtonText: "确定",
        showCancelButton: true,
      })
        .then(async () => {
          this.id = row.id;
          postDelFun(this.id).then((res) => {
            if (res.code == 200) {
              this.businessData();
            }
          });
          this.$message({
            message: "删除成功",
            type: "success",
          });
        })
        .catch(() => {});
    },
    //删除全景报告
    // handleDelClick(row) {
    //   this.id = row.id;
    //   postDelFun(this.id).then((res) => {
    //     if (res.code == 200) {
    //       this.businessData();
    //     }
    //   });
    // },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.businessData();
    },
  },
};
</script>
<style lang="stylus" scoped>
.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}
.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.filter-body {
  margin: 20px;
  //padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  .inputs-wrapper {
    width: 100%;
    display: flex;
    //margin-left: -2px;
    justify-content: space-between
    .inputs-wraper-row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      >>>.el-input__inner {
        //width: 310px;
        height: 40px;
      }
      .form-item {
        margin-right: 30px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        >>>el_input{
          //width: 300px
        }

        .labelCode {
          width: 100px;
        }

        display: flex;
      }

      .form-itemCode {
        width: 336px;
      }
    }
  }

}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.addItem {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 20px;
  cursor: pointer;
  display: inline-block;
  margin-right: 16px;
}

.addItem:hover {
  background: rgba(47, 34, 235, 1);
}

.el-icon-edit-outline {
  color: #2f54eb;
  font-size: 18px;
  margin-left: 4px;
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

.pagination-low {
  float: right;
  margin-right: 30px;
  margin-bottom: 20px;
}

.export {
  cursor: pointer;
  color: #2f22eb;
  margin-right: 8px;
}
.queryRecord{
  margin-left 20px
  display: block
  padding 16px
}

.search-wrapper{
  display flex
  justify-content flex-start
  position relative
  width:100%
  .search-input-wrapper{

    display flex
    flex-wrap wrap
    justify-content flex-start
    margin :24px auto
    width: 100%
    .gutter-row{
      display: flex;
      justify-content: space-between;
      align-items: center;
      //width:400px;
      width:369px;
      //flex-basis 350px
      //flex-shrink 0;
      //flex-grow 0;
      margin-right 17px
      .input{
        width: 200px;
      }
    }
  }
}
.body{
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  margin: 20px;
  padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  margin-top: 16px;
}
.label {
  white-space: nowrap;
  width: 100px
  text-align: right;
  margin-right: 8px;


}

.btn-look-up {
  // margin-right: 16px;
   width: 76px;
   height: 36px;
   line-height: 36px;
   text-align: center;
   background: #2F54EB;
   border-radius: 4px;
   font-size: 14px;
   color: #FFFFFF;
 }
 .ease {
  transition: all 0.3s;
}
.block{
  margin 16px
  text-align: right
}
.table >>>.el-table .el-table__cell{
padding:3px 0px;
}
>>>.filter-body .el-input__inner{
  width:308px;
}
>>>.filter-body .el-input{
  width:300px;
}
.search{
  margin-left: 24px;
  margin-top: 2px;

}
</style>
