<template>
  <div>
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>审核管理</el-breadcrumb-item>
          <el-breadcrumb-item>审核列表</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">审核列表</template>
      </main-header>
    </div>
    <el-form class="filter-body" @submit.native.prevent="search">
      <div class="inputs-wrapper">
        <div class="inputs-wraper-row">
          <div class="form-item">
            <div class="label">项目名称:</div>
            <el-input
              class="input"
              v-model="filter.projectName"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeName"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">客户名称:</div>
            <el-input
              class="input"
              v-model="filter.consumer"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeConsumer"
            ></el-input>
          </div>
          <div class="form-item large">
            <div class="label">提交时间:</div>
            <el-date-picker
            class="input"
              v-model="filter.submitTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              @change="changeTime"
            ></el-date-picker>
          </div>
          <div class="form-item">
            <div class="label">状态:</div>
            <div class="form-control">
              <el-select
                class="input"
                clearable
                v-model="filter.reviewStatus"
                placeholder="请选择"
                @change="search"
              >
                <el-option
                  v-for="item in sencorStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="form-item">
            <div class="label">项目经理:</div>
            <el-input
              class="input"
              v-model="filter.projectManager"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeManager"
            ></el-input>
          </div>
          <div class="form-item">
            <div class="label">审核人:</div>
            <el-input
              class="input"
              v-model="filter.reviewer"
              placeholder="请输入"
              clearable
              @keyup.enter.native="search"
              @input="changeReviewer"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <div class="btn-look-up ease" data-index="100" @click="search">
          查询
        </div>
        <!-- <button class="btn btn-primary btn-look-up" @click="reset">重置</button> -->
      </div>
    </el-form>
    <div class="content-body">
      <template>
        <el-table
          :data="tableDataList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          style="width: 100%"
        >
          <el-table-column width="10"></el-table-column>
          <el-table-column width="100px" prop="id" label="#"></el-table-column>
          <el-table-column
            prop="exe_time"
            label="提交时间"
            min-width="13%"
            :formatter="dateFormatter"
            :show-overflow-tooltip="true"
            sortable
          ></el-table-column>

          <el-table-column
            prop="project_name"
            label="项目名称"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="company_name"
            label="客户名称"
            min-width="20%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="zqlx"
            label="检测类型"
            min-width="10%"
          ></el-table-column>
          <el-table-column
            prop="zq_mc"
            label="征期"
            min-width="10%"
          ></el-table-column>
          <el-table-column
            prop="project_manager"
            label="项目经理"
            min-width="10%"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="reviewer"
            label="审核人"
            min-width="10%"
          ></el-table-column>
          <el-table-column
            prop="review_status"
            label="状态"
            sortable
            min-width="10%"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.review_status == 1019"></div>
              <div v-if="scope.row.review_status == 1020">
                <span class="circle yellow"></span> 编辑中
              </div>
              <div v-if="scope.row.review_status == 1021">
                <span class="circle purple"></span> 待审核
              </div>
              <div v-if="scope.row.review_status == 1025">
                <span class="circle yellow"></span> 审核中
              </div>
              <div v-if="scope.row.review_status == 1022">
                <span class="circle cyan"></span> 待处理
              </div>
              <div v-if="scope.row.review_status == 1023">
                <span class="circle red"></span> 待审核
              </div>
              <div v-if="scope.row.review_status == 1024">
                <span class="circle green"></span> 审核通过
              </div>
              <div v-if="scope.row.review_status == 1026">
                <span class="circle green"></span> 审核通过
              </div>
              <div v-if="scope.row.review_status == 1027">
                <span class="circle yellow"></span> 编辑中
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="viewReport" label="操作" width="120px">
            <template slot-scope="scope">
              <template v-if="showExamine">
                <router-link
                  tag="a"
                  target="_blank"
                  :to="{
                    name: 'result-detect-always',
                    params: {
                      action: 'censor',
                      uuid: scope.row.uuid,
                      nsrsbh: scope.row.nsrsbh,
                    },query:{
                      desensitization:scope.row.desensitizationStatus
                    }
                  }"
                  class="manipulator-btn"
                >
                  <template v-if="scope.row.review_status == 1024"
                    >查看</template
                  >
                  <template v-else>审核</template>
                </router-link>
              </template>
              <button
                @click="
                  handleCensorBtnLogClick({
                    uuid: scope.row.uuid,
                    nsrsbh: scope.row.nsrsbh,
                  })
                "
                class="manipulator-btn"
              >
                审核记录
              </button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="pagination-wrapper">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[2, 10, 20, 100]"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="totalSize"
      ></el-pagination>
    </div>

    <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false">
      <CensorHistory :uuid="uuid" :nsrsbh="nsrsbh"></CensorHistory>
    </el-drawer>
  </div>
</template>

<script>
import AwaitButton from "await-button";
import filterCacheMixin from "@/mixins/filterCacheMixin";
import { getCustomList } from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import CensorHistory from "../components/CensorHistory/CensorHistory";
import { veriFiItem, veriFiCustom, area } from "@/pages/product/utils/limit.js";

const moment = require("moment");

export default {
  mixins: [filterCacheMixin],
  components: {
    MainHeader,
    BreadCrumb,
    CensorHistory,
  },

  mounted() {
    this.getcustomList();
    // 判断是否有审核权限
    this.checkshowExamine(24);
    // 判断是否有审核记录权限
    this.checkshowExamineList(25);
  },

  data() {
    return {
      showExamine: true,
      showExamineList: true,
      drawer: false,
      totalSize: 10, //总数
      pageSize: 10,
      pageNo: 0,
      uuid: "",
      nsrsbh: "",
      sencorStatusOptions: [
        {
          value: "",
          label: "全部",
        },
        // {
        //   value: 1019,
        //   label: ""
        // },
        {
          value: "1021,1023",
          label: "待审核",
        },
        {
          value: 1025,
          label: "审核中",
        },
        {
          value: 1022,
          label: "待处理",
        },
        {
          value: "1024,1026",
          label: "审核通过",
        },
      ],
      filter: {
        projectName: "",
        consumer: "",
        submitTime: [],
        projectManager: "",
        reviewer: "",
        reviewStatus: "",
      },
      tableDataList: null,
    };
  },
  methods: {
    // 权限方法
    checkAuthMethod(id) {
      let getUserAuth = JSON.parse(localStorage.getItem("user")).authorities;
      if (getUserAuth.length == 0) {
        return "none";
      }
      this.roleList = JSON.parse(
        localStorage.getItem("user")
      ).authorities[0].roleList;
      let roleListId = this.roleList.map((item) => {
        return item.id;
      });
      if (roleListId.indexOf(id) == -1) {
        return false;
      } else {
        return true;
      }
    },
    // 判断是否有审核权限
    checkshowExamine(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.showExamine = false;
      }
    },
    // 判断是否有审核记录权限
    checkshowExamineList(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.showExamineList = false;
      }
    },

    handleCensorBtnLogClick({ uuid, nsrsbh }) {
      if (!this.showExamineList) {
        this.$message({
          message: "抱歉，您没有查看审核记录权限！",
          type: "warning",
        });
        return;
      }
      this.uuid = uuid;
      this.nsrsbh = nsrsbh;
      this.drawer = true;
    },
    changeName() {
      if (!this.filter.projectName) {
        this.search();
      }
    },
    changeConsumer() {
      if (!this.filter.consumer) {
        this.search();
      }
    },
    changeTime() {
      this.search();
    },
    changeManager() {
      if (!this.filter.projectManager) {
        this.search();
      }
    },
    changeReviewer() {
      if (!this.filter.reviewer) {
        this.search();
      }
    },
    search() {
      this.pageNo = 0;
      this.saveFilterToCache(this.filter);
      this.getcustomList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getcustomList();
    },
    handleCurrentChange(val) {
      //debugger
      this.pageNo = val;
      this.getcustomList();
    },
    reset() {
      this.filter = {
        projectName: "",
        consumer: "",
        submitTime: [],
        projectManager: "",
        reviewer: "",
      };
      this.getcustomList();
    },
    dateFormatter(row, column) {
      return moment(row.exe_time).format("YYYY-MM-DD HH:mm:ss");
    },
    async getcustomList() {
      let params = {
        ...this.filter,
        startTime:
          this.filter.submitTime && this.filter.submitTime[0]
            ? moment(this.filter.submitTime[0]).format("YYYY-MM-DD HH:mm:ss")
            : "",
        endTime:
          this.filter.submitTime && this.filter.submitTime[0]
            ? moment(this.filter.submitTime[1]).format("YYYY-MM-DD HH:mm:ss")
            : "",
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };

      const res = await getCustomList(params);
      const resData = res.data;

      this.tableDataList = resData.data.content;
      this.totalSize = resData.data.totalSize;
      //this.pageNo = resData.data.pageNo;
    },
  },
};
</script>

<style lang="stylus" scoped>
.pagination-wrapper {
  text-align: right;
  margin: 20px 30px 20px 0;
  align-items: center;
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs .el-input {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs >>>.el-input >>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #projectBoxs .el-input__icon {
  line-height: 36px;
}

>>> #projectBoxs .el-cascader {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input, .addForms {
  position: relative;
}

.project-input-b {
  position: absolute;
  right: 40px;
  top: 40px;
  font-size: 12px;
  color: red;
}

.project-input .el-input {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 0 10px;
}

.filter-body {
  margin: 20px;
  padding: 24px 0 8px 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  // padding: 20px;
  // padding-top: 20px;
  // padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-top: 16px;

  .inputs-wrapper {
    width: 92%;

    .inputs-wraper-row {
      display: flex;
      flex-wrap: wrap;

      .form-item {
        margin-right: 40px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .label {
          white-space: nowrap;
          width: 69px;
          text-align: right;
          margin-right: 8px;
        }

        .form-control {
          width: 240px;
        }

        .label {
          text-align: right;
          flex-grow: 0;
          flex-shrink: 0;
          width: 69px;
          // padding-right: 10px;
        }

        .input {
          width: 240px;
          flex: 0 0 auto;
          flex-shrink: 0;
        }

        display: flex;
      }
    }
  }

  .btn-look-up {
    margin-right: 16px;
    width: 76px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background: #2F54EB;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.btn-look-up:hover {
  cursor: pointer;
  background: rgba(47, 34, 235, 1);
}

.done, .pedding, .delete, .perfect {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 7px;
  margin-bottom: 2px;
}

.done {
  background: rgba(82, 196, 26, 1);
}

.pedding {
  background: rgba(217, 217, 217, 1);
}

.delete {
  background: rgba(245, 34, 45, 1);
}

.perfect {
  background: rgba(250, 173, 20, 1);
}

.paginations {
  float: right;
  margin-right: 20px;
  margin-bottom: 30px;
}

.fixed-item {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 496px;
  height: 100%;
  z-index: 1000;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(251, 251, 251, 1);
  box-shadow: -4px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.ease {
  transition: all 0.3s;
}

.cursion {
  cursor: pointer;
}

.content-isEdit {
  margin-bottom: 16px;
}

.paginationNew {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 40px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(238, 238, 238, 1);
  display: inline-block;
}

.circle.gray {
  background: rgba(238, 238, 238, 1);
}

.circle.blue {
  background: rgba(98, 97, 242, 1);
}

.circle.cyan {
  background: rgba(24, 144, 255, 1);
}

.circle.green {
  background: rgba(82, 196, 26, 1);
}

.circle.red {
  background: rgba(245, 34, 45, 1);
}

.circle.purple {
  background: rgba(153, 47, 235, 1);
}

.circle.yellow {
  background: rgba(250, 173, 20, 1);
}

.text-gray {
  color: rgba(238, 238, 238, 1);
}

.text-blue {
  color: rgba(98, 97, 242, 1);
}

.text-cyan {
  color: rgba(24, 144, 255, 1);
}

.text-green {
  color: rgba(82, 196, 26, 1);
}

.text-red {
  color: rgba(245, 34, 45, 1);
}

.text-purple {
  color: rgba(153, 47, 235, 1);
}

.text-yellow {
  color: rgba(250, 173, 20, 1);
}

.manipulator-btn {
  color: rgba(47, 84, 235, 1);
  background: none;
  border: 0 none;
  cursor: pointer;
  outline-style: none;
  box-shadow: none;
}

.manipulator-btn:hover {
  text-decoration: underline;
}

>>>.bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>>.bread-crumb-wrapper {
  margin-top: 0;
}

>>>.el-range-editor.el-input__inner {
  width: 240px;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.el-input__icon {
  line-height: 28px;
}

>>>.el-date-editor .el-range__icon {
  margin-left: 0;
  margin-right: 6px;
}

>>>.el-input__icon {
  width: 14px;
}

>>>.el-range-editor.el-input__inner {
  padding: 3px 8px;
}
</style>
