<template>
  <div @click="handleBgCLick">
    <div
      class="wrapper"
      v-loading="!dataLoaded"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :class="{ 'reportTypeClass-wrapper': reportType == 3 }"
    >
      <ToolBar
        @btnSaveClick="handleBtnSaveClick"
        @switchTab="handleSwitchTab"
        v-if="dataLoaded"
        :reportType="reportType"
        :companyInfo="companyInfo"
        :dataText="dataText"
      ></ToolBar>
      <template v-if="tabId == 0 && dataLoaded">
        <div class="nestedTree-wrapper">
          <div class="nestedTree" v-if="dataLoaded">
            <div class="treeTitle-wrapper">
              <div class="treeTitle">风控模型详情及核实思路</div>
              <MenuTree :data="treeData" target="treeData" :key="0"></MenuTree>
            </div>
          </div>
        </div>

        <ResultContent
          @companyInfo2="companyInfo2"
          @dataText2="dataText2"
          class="resultContent"
        ></ResultContent>
      </template>
      <!-- <template v-if="tabId == 1 && dataLoaded">
        <div class="nestedTree-wrapper">
            <div class="nestedTree" v-if="dataLoaded">
              <div class="treeTitle-wrapper">
                <div class="treeTitle">优惠政策提醒</div>
                <MenuTree
                  :data="discountPolicyTree"
                  target="discountPolicyTree"
                  :key="1"
                ></MenuTree>
              </div>
            </div>
        </div>

        <DiscountPolicyTab class="resultContent"></DiscountPolicyTab>
      </template> -->
      <!-- <HistoryLog v-if="dataLoaded"></HistoryLog> -->
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { mapState } from "vuex";
import MenuTree from "./components/MenuTree";
import ToolBar from "./components/ToolBar";
import ResultContent from "./components/ResultContent";

import HistoryLog from "./components/HistoryLog/HistoryLog";
import DiscountPolicyTab from "./components/DiscountPolicyTab";

export default {
  props: {
    reportType: [Number, String],
    anchor: [String, Number],
  },
  data: function () {
    return {
      data: [],
      tabId: 0,
      companyInfo: {},
      dataText: "",
    };
  },
  computed: {
    ...mapState("ResultEditor", ["treeData", "discountPolicyTree"]),

    dataLoaded() {
      //加载完成
      return Object.keys(this.treeData).length > 0;
    },
  },
  components: {
    MenuTree,
    ToolBar,
    ResultContent,
    HistoryLog,
    DiscountPolicyTab,
  },
  async mounted() {
    await this.loadData();
    this.$emit("load");
    setTimeout(() => {
      const anchor = this.$route.query.anchor || this.anchor;
      this.$scrollTo(document.querySelector("#node" + anchor), 1000);
    }, 1000);
  },
  activated() {
    const anchor = this.$route.query.anchor || this.anchor;
    this.$scrollTo(document.querySelector("#node" + anchor), 1000);
  },
  methods: {
    handleBgCLick() {
      this.$eventBus.$emit("ResultEditorBgClick");
    },
    async loadData() {
      await this.$store.dispatch("ResultEditor/loadPDFEditorData", {
        nsrsbh: this.$route.params.nsrsbh || this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
        action: this.$route.params.action,
        isFromAdmin: !!this.$route.query.fromAdmin,
        shuijuType: this.$route.query.action,
      });
    },
    handleBtnSaveClick() {},
    handleSwitchTab(tabId) {
      this.tabId = tabId;
    },
    // sortedData(data) {
    //   const ret = [];

    //   const sort = data => {
    //     data.forEach((aData, index) => {
    //       if (aData.children) {
    //         data[index].children = sort(aData.children);
    //       }
    //     });

    //     return data.sort((a, b) => a.sort - b.sort);
    //   };

    //   return sort(data);
    // }
    companyInfo2(val) {
      this.companyInfo = val;
    },
    dataText2(val) {
      this.dataText = val;
    },
  },
  destroyed() {
    this.$store.commit("reset");
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding-top: 61px;
  display: flex;
  min-height: 100vh;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.02);
  .nestedTree-wrapper {
    position: fixed;
    width: 320px;
    z-index: 10;
    height: calc(100vh - 61px);
    overflow: auto;
    .ps {
      height: calc(100vh - 61px);
    }
    .nestedTree {
      z-index: 2;

      flex: 0 0 auto;
      .treeTitle-wrapper {
        padding: 24px 0;
        background: rgba(240, 245, 255, 1);
        box-sizing: border-box;
        min-height: calc(100vh - 61px);

        padding-right: 5px;
        padding-bottom: 30px;
        .treeTitle {
          padding-left: 48px;
          font-weight: 600;

          color: rgba(0, 0, 0, 0.8);
          line-height: 22px;
          font-size: 16px;
          padding-bottom: 16px;
        }
      }
    }
  }

  .resultContent {
    flex: 1 1 auto;
    margin-left: 320px;
  }
}
.reportTypeClass-wrapper {
  padding-top: 0px;
}
</style>
