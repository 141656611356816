<template>
  <section class="app-main">
    <!-- <transition name="fade" mode="out-in"> -->
      <!-- <router-view :key="$route.fullPath"></router-view> -->
      <!-- <router-view /> -->
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"> </router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- </transition> -->
  </section>
</template>

<script>
export default {
  name: "AppMain",
};
</script>
<style lang="scss" scoped>
.app-main {
  height: 100%;
  padding-top: 60px;
}
</style>