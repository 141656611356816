<template>
  <div>
    <SectionNode
      v-for="(node0,index0) in discountPolicyTree"
      :key="node0.id"
      :title="`${node0.titleName}`"
      :indexes="[index0]"
      :level="1"
      target="discountPolicyTree"
    >
      
        <SectionNode
          v-for="(node1,index1) in node0.children"
          :key="node1.id"
          :indexes="[index0,index1]"
          :title="`${node1.titleName}`"
          :level="2"
          target="discountPolicyTree"
        >
          <!--优惠政策-->
          <DiscountPolicy v-if="node1.type==5" :indexes="[index0,index1]"></DiscountPolicy>
          <!--/优惠政策-->
        </SectionNode>
     
    </SectionNode>
    <DiscountPolicyAppend v-if="isEditable"></DiscountPolicyAppend>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import SectionNode from "./SectionNode";
import InvoiceIndex from "./InvoiceIndex/";
import TaxIndex from "./TaxIndex/";
import Riskfenlei from "./Riskfenlei";
import DiscountPolicy from "./DiscountPolicy/DiscountPolicy";
import DiscountPolicyAppend from "./DiscountPolicy/DiscountPolicyAppend";
import RiskProblem from "./RiskProblem/";
import HeadLevel0 from "./HeadLevel0";
import mixin from "@/pages/ResultEditor/mixin.js";

import { mapState } from "vuex";

export default {
  mixins:[mixin],
  filters: {
    textarea: function(value) {
      return value.replace(/\n/g, "<br/>");
    }
  },
  components: {
    DiscountPolicy,
    SectionNode,
    DiscountPolicyAppend
  },
  computed: {
    textarea: function() {
      return value.replace(/\n/g, "<br/>");
    },
    ...mapState("ResultEditor", ["discountPolicyTree"])
  },
  data: function() {
    return {};
  }
};
</script>

<style lang="stylus" scoped>
.discountPolicy {
}

.textarea-wrapper {
  display: flex;
}

.section-wrapper-0 {
}

.textarea {
  >>> textarea {
    padding: 16px;
  }

  display: block;
  margin: 16px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  flex-grow: 1;
}
</style>
