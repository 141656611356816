import { cloneDeep } from "lodash";

function initialState() {
  return {
    graphicEditorScale: 1,
    isSaved: true,
    status: -1, //0.草稿   2.已发布  9.已删除
    modelForm: {
      modelName: "",
      area: [],
      catalog: [],
      industry: [],
      riskLevel: "",
      type: "",
      createUserId: "",
      createUserName: "",
      classify:"",
      modelBase: "",
      modelId: "",
      modelJson: ""
    },
    graphData: {
      // 节点
      nodes: [
        // {
        //   id: "n2",
        //   entityType: "PF.ZBNODE", //元数据、指标节点
        //   nodeEntityCode: "ZB00001",
        //   x: 300,
        //   y: 300,
        //   name: "节点2",
        //   type: ""
        // },
        // {
        //   id: "n3",
        //   entityType: "PF.ZBNODE1", //元数据、指标节点
        //   nodeEntityCode: "ZB00002",
        //   x: 300,
        //   y: 300,
        //   name: "节点3",
        //   type: ""
        // },
        {
          id: "n0",
          entityType: "ENDNODE", //元数据、指标节点
          nodeEntityCode: "ENDNODE",
          x: 300,
          y: 300,
          result: {},
          diagram: [],
          name: "终点",
          type: "end"
        }
      ],
      // 链接线
      vectors: {
        // n2: {
        //   // n2 节点连接到n0
        //   n0: {
        //     condition: {
        //       showText: "指标1 > 0 且 指标2 指标1 > 0 且 指标2 指标1 > 0 且 指标2",
        //       expression: "指标1 > 0 且 指标2 指标1 > 0 且 指标2 指标1 > 0 且 指标2",
        //        data:null
        //     },
        //     id: "n2_n0",
        //      preNodeId:"",
        //     result: {
        // riskDesc: "",
        // suggestion: "",
        // case: "",
        // policy: "",
        // comments: ""
        //     },
        //     relations:[],
        //   }
        // },
        // n3: {
        //   // n3 节点连接到n0
        //   n0: {
        //     condition: {
        //       showText: "",
        //       expression: ""
        //     },
        //     id: "n3_n0",
        //     result: {
        //       riskDesc: "我是一个测试",
        //       suggestion: "",
        //       case: "",
        //       policy: "",
        //       comments: ""
        //     },
        //     relations:[],
        //   }
        // }
      }
    }
  };
}

export const state = {
  ...initialState()
};

// 根据nodeId查找node
function findNodeByIdFn(state, id) {
  return state.graphData.nodes.find(node => {
    return node.id === id;
  });
}
// 根据nodeId查找node
function findVectorByIdFn(state, id) {
  for (const nodeFromId in state.graphData.vectors) {
    {
      const vectorGrp = state.graphData.vectors[nodeFromId];
      for (const nodeToId in vectorGrp) {
        const vector = vectorGrp[nodeToId];
        if (vector.id == id) {
          return vector;
        }
      }
    }
  }
  return null;
}

const getters = {
  relations: state => {
    return state.graphData.nodes.map(v => {
      return {
        entityType: v.entityType,
        entityId: v.nodeEntityCode
      };
    });
  },
  findNodeById: state => id => {
    return findNodeByIdFn(state, id);
  },
  findVectorById: state => id => {
    return findVectorByIdFn(state, id);
  },

};
const mutations = {
  reset(state) {
    // acquire initial state
    const s = cloneDeep(initialState());
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  setgraphicEditorScale(state, rate) {
    state.graphicEditorScale = rate;
  },
  setIsSaved(state, isSaved) {
    state.isSaved = isSaved;
  },
  setStatus(state, status) {
    state.status = status;
  },
  setGraphData(state, { graphData }) {
    state.graphData = graphData;
  },
  updateGraphDataNodeById(state, { nodeId, nodeData }) {
    const node = findNodeByIdFn(state, nodeId);

    Object.assign(node, nodeData);
  },

  updateGraphDataVectorById(state, { vectorId, vectorData }) {
    const vector = findVectorByIdFn(state, vectorId);
    Object.assign(vector, vectorData);
  },

  setModelFormByAttr(state, { key, val }) {
    state.modelForm[key] = val;
  }
};
const actions = {};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
