var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"wrapper"},[_c('draggable',{attrs:{"disabled":!_vm.isEditable},on:{"update":_vm.handleDragMoved},model:{value:(_vm.currentNode),callback:function ($$v) {_vm.currentNode=$$v},expression:"currentNode"}},[_c('transition-group',_vm._l((_vm.data),function(item,index){return (item.floor <= 2)?_c('div',{key:item.id,staticClass:"item"},[_c('div',{staticClass:"title-wrapper"},[(
                item.children && item.children.length > 0 && item.isDisplay
              )?_c('div',{on:{"click":function($event){_vm.handleBtnArrowCLick($event, _vm.orderNumber(index))}}},[(item.floor <= 1)?[(!item.hidden)?_c('div',{staticClass:"el-icon-caret-bottom icon"}):_c('div',{staticClass:"el-icon-caret-right icon"})]:_vm._e()],2):_c('div',{staticClass:"blank-icon"}),_c('div',{staticClass:"title",class:{
                title1: item.children && item.children.length > 0,
                removed: !item.isDisplay,
                isModified: _vm.isMenuItemChanged(index),
                dragable: _vm.isEditable,
              },on:{"click":function($event){return _vm.handleScrollTo(item.id)}}},[_vm._v(" "+_vm._s(item.orderNumber)+" "+_vm._s(item.titleName)+" ")])]),(item.children && !item.hidden && item.isDisplay)?_c('MenuTree',{attrs:{"target":_vm.target,"data":item.children,"previousOrderNumber":_vm.orderNumber(index),"level":_vm.level + 1}}):_vm._e()],1):_vm._e()}),0)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }