<template>
  <div>
    <el-menu class="navbar" :class="{ navbarColor: navbarChange }" mode="horizontal">
      <div class="pageLogo">
        <img v-if="!navbarChange" :src="pageLogo" alt />
        <!-- <img v-else :src="pageLogo" alt /> -->
      </div>
      <div class="left-nav-box" v-if="isDemoMode">
        <router-link :to="{ name: 'homePage' }" class="btn btn-primary btn-back"
          >返回真实账号</router-link
        >
      </div>
      <div class="user-profile-container" trigger="click">
        <div class="user-profile-content">
          <div class="menu-icons">
            <!--            <div class="btn-downloadC btn-download" @click="dPlug">-->
            <!--              <i class="el-icon-download"></i>下载发票采集插件-->
            <!--              &lt;!&ndash; <a-->
            <!--                href="//shuian-file.oss-cn-hangzhou.aliyuncs.com/goods/AI%E7%A8%8E%E5%8A%A1%E5%B8%88.zip"-->
            <!--                class="btn-download"-->
            <!--              >-->
            <!--                <i class="el-icon-download"></i>下载发票采集插件-->
            <!--              </a> &ndash;&gt;-->
            <!--            </div>-->
            <!-- <span class="menu-icon red" @click="showActiviateDialog">
              <i class="icon iconfont">&#xe65f;</i>
              <span class="icon-text">兑换码激活</span>
            </span>

            <span class="menu-icon" @click="toHelp">
              <svg class="icon-symbol" aria-hidden="true">
                <use xlink:href="#iconbangzhu" />
              </svg>
              <span class="icon-text">帮助中心</span>
            </span>-->

            <!-- <span class="menu-icon">
              <a
                href="https://cschat-ccs.aliyun.com/index.htm?tntInstId=_1EiTA0W&scene=SCE00004242"
                target="_blank"
              >
                <i class="icon iconfont" style="color:#1890FF;">&#xe663;</i>

                <span class="icon-text">客服中心</span>
              </a>
            </span>-->

            <!-- <span class="menu-icon" @click="toMessage">
              <el-badge :value="messageNumber" class="item" v-if="messageNumber!=0">
                <i class="icon iconfont" style="color:#FF7718;">&#xe65e;</i>
                <span class="icon-text">消息中心</span>
              </el-badge>
              <el-badge class="item" v-else>
                <i class="icon iconfont" style="color:#FF7718;">&#xe65e;</i>
                <span class="icon-text">消息中心</span>
              </el-badge>
            </span>-->
          </div>
          <!-- <span
            style="fontsize: 14px; lineheight: 21px; display: inline-block; margin-top: 4px"
            @click="opendia"
            >通知<span style="color: red">{{ length }}</span></span
          > -->

          <div class="user-profile-body-wrapper" v-if="dataLoaded">
            <el-dropdown>
              <div class="user-profile-body">
                <div class="username-wrapper">
                  <span class="user-name">
                    <span class="showName" :class="{ navbarColorText: navbarChange }">{{
                      showName
                    }}</span>
                    <!-- <template v-if="companyInfo.companyName">{{companyInfo.companyName}}</template>
                    <template v-else>未认证企业</template>-->
                  </span>
                  <i class="icon el-icon-caret-bottom" v-if="!navbarChange"></i>
                </div>

                <!-- <img
                class="user-avatar"
                src="https://img.alicdn.com/tfs/TB1ONhloamWBuNjy1XaXXXCbXXa-200-200.png"
                />-->
              </div>
              <el-dropdown-menu slot="dropdown" class="user-dropdown" v-if="!navbarChange">
                <router-link
                  :to="{ name: 'companyApply' }"
                  v-if="companyrenzheng.auditStatus == -1"
                >
                  <!-- <el-dropdown-item>去认证</el-dropdown-item> -->
                </router-link>
                <router-link to="/setUser" v-else>
                  <el-dropdown-item>企业信息</el-dropdown-item>
                </router-link>
                <el-dropdown-item>
                  <span style="display: block" @click="changePwd">修改密码</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span style="display: block" @click="changePhone">更换手机</span>
                </el-dropdown-item>
                <el-dropdown-item>
                  <span style="display: block" @click="logout">退出账号</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </el-menu>
    <DialogNotice
      ref="theDialogNotice"
      :length="length"
      @lengthchange="lengthchange($event)"
    ></DialogNotice>
    <DialogActiviate
      ref="theDialogActiviate"
      @onDialogClose="handleActiviateDialogClose"
    ></DialogActiviate>
    <dialog-success ref="theDialogSuccess" :activationData="activationData"></dialog-success>
    <dialog-password ref="changePwdDialog"></dialog-password>
    <dialog-phone ref="changePhoneDialog"></dialog-phone>
    <reminder
      @closeToast="closeToast"
      v-if="reminderToast"
      class="reminder-toast"
      :option="option"
    ></reminder>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { messageList, mateGoods, getIndexLogo } from "@/api/api";
import DialogActiviate from "./components/DialogActivite";
import DialogNotice from "./components/DialogNotice";
import DialogSuccess from "./components/DialogSuccess";
import DialogPassword from "./components/DialogChangePwd";
import DialogPhone from "./components/DialogChangePhone";
import reminder from "./components/reminder";

import "@/assets/fonts/iconfont.css";
export default {
  name: "NavBar",
  components: {
    DialogActiviate: DialogActiviate,
    DialogSuccess: DialogSuccess,
    DialogPassword,
    DialogPhone,
    reminder,
    DialogNotice,
  },
  data() {
    return {
      length: 0,
      dataLoaded: false,
      res: [],
      errorstatus: 0,
      noread: 0,
      activationData: null,
      name: "",
      pageLogo: "",
      reminderToast: false,
      option: {
        cancel: true,
        close: true,
        submit: "下载",
        type: 1,
        content: "发票取值插件仅支持安装在windows系统内的电脑，是否继续下载？",
        icon: 1,
        title: "温馨提示",
      },
      navbarChange: localStorage.getItem("changeColor"),
    };
  },
  computed: {
    showName() {
      let user = JSON.parse(localStorage.getItem("user"));
      if(!user) return
      return user.userName;
    },
    ...mapState(["companyrenzheng", "companyInfo", "authorization", "messageNumber"]),
    isDemoMode() {
      return this.$route.fullPath.includes("/demo/");
    },
  },
  async mounted() {
    //消息中心
    // try {
    //   await this.$store.dispatch("loadMessageNumber");
    // } catch (error) {
    //   // eslint-disable-next-line no-console
    //   console.error(error);
    // }
    //企业信息
    try {
      await this.$store.cache.dispatch("loadCompanyInfo");
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    // try {
    //   await this.$store.cache.dispatch("loadCompanyRenzheng");
    // } catch (error) {
    //   // eslint-disable-next-line no-console
    //   console.error(error);
    // }

    // await this.mategoods();
    // 获取uuid
    // 自动匹配行业bankID,goodsID,goodsName

    // this.getMessageList();
    this.dataLoaded = true;
    // this.getindexLogo();
  },
  methods: {
    lengthchange(e) {
      console.log(e);
      this.length = e;
    },
    async opendia() {
      this.$refs.theDialogNotice.showdia();
    },

    // 判断当前系统
    validataOS() {
      if (navigator.userAgent.indexOf("Window") > 0) {
        return 1;
      } else {
        return 2;
      }
    },
    closeToast() {
      this.reminderToast = false;
    },
    // 下载
    download() {
      var a = document.createElement("a");
      a.setAttribute(
        "href",
        "//shuian-file.oss-cn-hangzhou.aliyuncs.com/goods/AI%E7%A8%8E%E5%8A%A1%E5%B8%88.zip"
      );
      a.setAttribute("id", "startTelMedicine");
      // 防止反复添加
      if (document.getElementById("startTelMedicine")) {
        document.body.removeChild(document.getElementById("startTelMedicine"));
      }
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    dPlug() {
      // 先判断系统
      // 如果是window 则直接下载
      // 如果是mac 则弹窗
      let system = this.validataOS();
      if (system == 1) {
        this.download();
      } else {
        this.reminderToast = true;
      }
    },
    getindexLogo() {
      getIndexLogo().then((res) => {
        if (res.msgCode != 1) {
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
        } else {
          if (res.data.is_brand_configurable == 0) {
            this.pageLogo = "";
          } else {
            this.pageLogo = res.data.logo;
          }
        }
      });
    },

    changePhone() {
      this.$refs.changePhoneDialog.showDialog();
    },
    changePwd() {
      this.$refs.changePwdDialog.showDialog();
    },
    handleActiviateDialogClose(data) {
      if (data) {
        this.$refs.theDialogSuccess.dialogVisible = true;
        this.activationData = data;
      }
    },

    //打开激活弹出框
    showActiviateDialog() {
      this.$refs.theDialogActiviate.dialogVisible = true;
    },
    //获取消息未读数量
    getMessageList() {
      const para = {
        type: "2",
      };
      messageList(para).then((res) => {
        var message1 = res.data.content;
        for (var i in message1) {
          if (message1[i].childList) {
            for (var j in message1[i].childList) {
              if (message1[i].childList[j].readStatus == 1) {
                this.noread += 1;
              }
            }
          }
        }
      });
    },
    toMessage() {
      this.$router.push("/message/center");
    },
    toHelp() {
      this.$router.push("/helpCenter");
    },
    logout() {
      this.$store.commit("reset");
      this.$store.cache.clear();
      this.$store.commit("clearToken");
      this.$router.push({ name: "login" });
      localStorage.clear();
      sessionStorage.clear();
    },
    mategoods() {
      let params = {
        token: this.$store.state.authorization,
      };
      mateGoods(params).then((res) => {
        this.$store.commit("setGoods", res.data);
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.el-message-box {
  border-radius: 10px;
}

.el-message-box__btns {
  button {
    width: 100px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    padding: 0;
    margin-top: 50px;

    span {
      display: inline-block;
      height: 30px;
      line-height: 30px;
    }
  }

  .el-button--default {
    color: #007aff;
    border-color: #007aff;
  }

  .el-button--primary {
    color: #fff;
    background: #007aff;
  }
}

.el-message-box__message {
  p {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(107, 120, 151, 1);
    line-height: 22px;
  }
}

.el-message-box__header {
  .el-message-box__title {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: rgba(6, 35, 64, 1);
    line-height: 18px;
  }
}

.navbar {
  height: 60px;
  border-bottom: 0 none;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.08), 0px 0px 4px 0px rgba(0,0,0,0.02);
  position: fixed;
  z-index: 1002;
  width: 100%;
  left: 0;
  top: 0;

  .user-profile-container {
    position: absolute;
    right: 20px;
    cursor: pointer;
    color: #666;
    height: 60px;
    display: flex;
    align-items: center;

    .user-profile-content {
      display: flex;
    }

    .menu-icons {
      display: flex;
      align-items: center;

      .menu-icon {
        font-size: 14px;
        padding: 0 20px;
        color: #666;

        .icon {
          display: inline-block;
          font-size: 14px;
          text-align: center;
          position: relative;
          top: 0px;
        }

        .icon-text {
          margin-left: 5px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
        }

        >>> .el-badge__content {
          background-color: #FF7718;
        }
      }

      .menu-icon.red {
        color: #F5222D;
      }
    }

    .user-profile-body-wrapper {
      display: flex;
      align-items: center;
      padding: 0 5px 0 40px;

      .user-profile-body {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-right: 14px;
      }

      .user-avatar {
        width: 32px;
        height: 32px;
        background: rgba(255, 255, 255, 1);
        border-radius: 50%;
      }

      .username-wrapper {
        margin-right: 15px;

        .user-name {
          font-family: PingFangSC-Regular, PingFang SC;
          color: rgba(0, 0, 0, 0.5);
          font-size: 14px;
          font-weight: 400;
          padding: 0 0 0 6px;
          max-width: 172px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          top: 2px;
          margin-right: 3px;
        }

        .icon {
          color: #333;
        }
      }

      .user-department {
        font-size: 12px;
        color: rgba(102, 102, 102, 0.65);
      }
    }

    .btn-verified {
      text-align: center;
      line-height: 22px;
      width: 60px;
      height: 22px;
      background: rgba(83, 220, 145, 1);
      border-radius: 5px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
    }
  }
}

.left-nav-box {
  color: #333;
  position: absolute;
  left: 176px;
  z-index: 11;
}

.btn-back {
  background: rgba(245, 34, 45, 1);
  border-radius: 4px;
  padding: 8px 24px;
  height: auto;
  line-height: 1.5;
  width: auto;
  margin-top: 12px;
  margin-left: 30px;
}

.btn-back:hover {
  background: rgba(245, 84, 45, 1);
}

.showName {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  margin-right: 2px;
}

.btn-downloadC {
  width: 168px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: #D6E4FF;
  border-radius: 4px;
}

.btn-download {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.el-icon-download {
  margin-right: 10px;
}

.pageLogo {
  position: absolute;
  height: 44px;
  top: 6px;
  left: 24px;
}

.pageLogo img {
  height: 100%;
}

.navbarColor {
  background: #2f54eb;
  background:url("http://shuian-file.oss-cn-hangzhou.aliyuncs.com/user/disanfangbeijingtu.jpg") no-repeat;
}

.navbarColorText {
  color: #fff;
}
</style>
