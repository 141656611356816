import { cloneDeep } from "lodash";

var filterCacheMixin = {
  datafunction() {
    return {
      initFilter: {},
      filter: {},
    };
  },
  methods: {
    saveFilterToCache(filters) {
      sessionStorage.setItem(this.$route.path, JSON.stringify(filters));
    },
    getFilterFromCache() {
      const data = sessionStorage.getItem(this.$route.path);
      return data ? JSON.parse(data) : null;
    },
    getList() {},
    //重置
    resetFilter() {
      this.filter = cloneDeep(this.initFilter);
      this.getList();
    },
  },
  mounted() {
    this.initFilter = cloneDeep(this.filter);
    this.filter = this.getFilterFromCache(this.filter) || this.filter;
  },
};

export default filterCacheMixin;
