import axios, {
  get,
  post
} from "@/axios";

const base = process.env.VUE_APP_API_ROOT;

// 房地产项目列表详情
export const bSuperiorProjectDetail = id => {
  return get(`${base}/bSuperiorProject/getInfo?id=${id}`)
    .then(res => res.data);
};

// 登录
export const fdcLogin = params => {
  return post(`${base}/real-rstate/realEstateUser/login`, params)
    .then(res => res.data);
};

// 修改密码
export const fdcUpdatePassword = params => {
  return post(`${base}/real-rstate/realEstateUser/updatePassword`, params)
    .then(res => res.data);
};

// 获取房地产收入房源信息表分页列表 
export const realEstateIncomeList = params => {
  return post(`${base}/real-rstate/realEstateIncome/getPage`, params)
    .then(res => res.data);
};

// 删除全部房地产收入房源信息表信息
export const realEstateIncomeDelAll = params => {
  return post(`${base}/real-rstate/realEstateIncome/delAll`, params)
    .then(res => res.data);
};

export const realEstateIncomeEdit = params => {
  return post(`${base}/real-rstate/realEstateIncome/edit`, params)
    .then(res => res.data);
};

// 下载当前检测报告相应期间发票数据
export function realEstateIncomeExportIncome() {
  // return `${base}/real-rstate/realEstateIncome/exportIncome`
  return get(`${base}/real-rstate/realEstateIncome/exportIncome`)
}

// 导入
export const realEstateIncomeUpload = (formData) => {
  return post(`${base}/real-rstate/realEstateIncome/uploadContExcel`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(res => res.data);
};

// 获取已售房源统计列表
export const realEstateGetCostPage = params => {
  return post(`${base}/real-rstate/realEstateIncome/getCostPage`, params)
    .then(res => res.data);
};

// 获取父类列表 
export const developmentCostClass = params => {
  return get(`${base}/real-rstate/developmentCostClass/getParentList`, params)
    .then(res => res.data);
};

// 根据父类获取子类
export const developmentCostClassByParent = parentClassNo => {
  return get(
      `${base}/real-rstate/developmentCostClass/getListByParent?parentClassNo=${parentClassNo}`)
    .then(res => res.data);
};

// 获取房地产开发成本表分页列表 
export const realEstateDevelopmentCostList = params => {
  return post(`${base}/real-rstate/realEstateDevelopmentCost/getPage`, params)
    .then(res => res.data);
};

// 修改房地产开发成本
export const realEstateDevelopmentCostEdit = params => {
  return post(`${base}/real-rstate/realEstateDevelopmentCost/edit`, params)
    .then(res => res.data);
};

// 导入成本表
export const uploadContExcelEnter = (formData) => {
  return post(`${base}/real-rstate/realEstateDevelopmentCost/uploadContExcel`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(res => res.data);
};

// 删除成本表
export const realEstateDevelopmentCostDelAll = () => {
  return get(`${base}/real-rstate/realEstateDevelopmentCost/delAll`)
    .then(res => res.data);
};

// 导出成本表
export const realEstateDeveExportCost = () => {
  return get(`${base}/real-rstate/realEstateDevelopmentCost/exportCost`)
    .then(res => res.data);
};

// 其他
// 利息支出
export const realEstateInterestExpenseList = params => {
  return post(`${base}/real-rstate/realEstateInterestExpense/getPage`, params)
    .then(res => res.data);
};

// 删除所有房地产利息支出表
export const realEstateInterestExpenseDel = params => {
  return post(`${base}/real-rstate/realEstateInterestExpense/delAll`, params)
    .then(res => res.data);
};

// 导入房地产利息支出表
export const realEstateUploadExcel = (formData) => {
  return post(`${base}/real-rstate/realEstateInterestExpense/uploadExcel`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(res => res.data);
};

// 修改利息支出
export const realEstateInterestExpenseEdit = params => {
  return post(`${base}/real-rstate/realEstateInterestExpense/edit`, params)
    .then(res => res.data);
};

// 缴纳税费列表数据
export const realEstatePayTaxesList = params => {
  return post(`${base}/real-rstate/realEstatePayTaxes/getPage`, params)
    .then(res => res.data);
};

// 缴纳的税费 上传excel
export const realEstatePayTaxesExcel = (formData) => {
  return post(`${base}/real-rstate/realEstatePayTaxes/uploadExcel`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(res => res.data);
};

// 缴纳的税费 删除
export const realEstatePayTaxesDelAll = () => {
  return get(`${base}/real-rstate/realEstatePayTaxes/delAll`)
    .then(res => res.data);
};

// 缴纳税费 修改
export const realEstatePayTaxesEdit = params => {
  return post(`${base}/real-rstate/realEstatePayTaxes/edit`, params)
    .then(res => res.data);
};

// 余额表
export const realEstateBalanceTableUpload = (formFile, period) => {
  return post(`${base}/real-rstate/realEstateBalanceTable/uploadExcel?period=${period}`,
      formFile, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
    .then(res => res.data);
};

// 余额表列表
export const realEstateBalanceTableList = params => {
  return get(`${base}/real-rstate/realEstateBalanceTable/getPage`, params)
    .then(res => res.data);
};

// 删除余额表
export const realEstateBalanceTableDel = id => {
  return get(`${base}/real-rstate/realEstateBalanceTable/del?id=${id}`)
    .then(res => res.data);
};

// 查看余额表
export const realEstateBalanceTableDetail = programId => {
  return get(`${base}/real-rstate/realEstateBalanceTable/getExcleInfo?programId=${programId}`)
    .then(res => res.data);
};

// 项目概况
export const homePageGetInfo = (projectId) => {
  return get(`${base}/real-rstate/homePage/getInfo?projectId=${projectId}`)
    .then(res => res.data);
};

// 首页房屋销售表格数据
export const getInComeTable = (projectId) => {
  return get(`${base}/real-rstate/homePage/getInComeTable?projectId=${projectId}`)
    .then(res => res.data);
};

export const getHomeInComeImage = params => {
  return post(`${base}/real-rstate/homePage/getHomeInComeImage`, params)
    .then(res => res.data);
};

// 首页房屋销售成本表格数据
export const getInComeTableCost = (projectId) => {
  return get(`${base}/real-rstate/homePage/getCostTable?projectId=${projectId}`)
    .then(res => res.data);
};

// 获取首页项目检测
export const bSuperiorProjectRuleList = params => {
  return post(`${base}/real-rstate/bSuperiorProject/getRuleList`, params)
    .then(res => res.data);
};

export const bSuperiorProjectRuleListIndex = params => {
  return post(`${base}/real-rstate/homePage/getRuleList`, params)
    .then(res => res.data);
};

// 上传附件
export const realEstateDevelopmentCostUpload = (formData) => {
  return post(`${base}/real-rstate/realEstateDevelopmentCost/uploadFile`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(res => res.data);
};

// 上传附件之后调用保存
export const realEstateAnnexAdd = params => {
  return post(`${base}/real-rstate/realEstateAnnex/add`, params)
    .then(res => res.data);
};

export const realEstateAnnexList = () => {
  return get(`${base}/real-rstate/realEstateAnnex/getList`)
    .then(res => res.data);
};

// 删除房地产附件
export const realEstateAnnexDel = (id) => {
  return get(`${base}/real-rstate/realEstateAnnex/del?id=${id}`)
    .then(res => res.data);
};

// 房地产账号列表
export const realEstateUserAccountList = params => {
  return post(`${base}/real-rstate/realEstateUser/getPage`, params)
    .then(res => res.data);
};

// 创建房地产账号
export const realEstateUserAdd = params => {
  return post(`${base}/real-rstate/realEstateUser/add`, params)
    .then(res => res.data);
};

// 房地产详情
export const realEstateAnnexDetail = (id) => {
  return get(`${base}/real-rstate/realEstateUser/getInfo?id=${id}`)
    .then(res => res.data);
};

// 房地产账号禁用启用
export const disableOrEnableUser = params => {
  return get(
      `${base}/real-rstate/realEstateUser/disableOrEnableUser?id=${params.id}&status=${params.status}`
    )
    .then(res => res.data);
};

// 修改房地产信息
export const realEstateUserEdit = params => {
  return post(`${base}/real-rstate/realEstateUser/edit`, params)
    .then(res => res.data);
};

// 获取首页详细图片
export const getHomeCostDetailedImage = (unitType, projectId) => {
  return get(`${base}/real-rstate/homePage/getHomeCostDetailedImage?unitType=${unitType}&projectId=${projectId}`)
    .then(res => res.data);
};

// 获取首页汇总图片
export const getHomeCostSummaryImage = (unitType, projectId) => {
  return get(`${base}/real-rstate/homePage/getHomeCostSummaryImage?unitType=${unitType}&projectId=${projectId}`)
    .then(res => res.data);
};

// 获取项目是否同步过发票 
export const getProjectSynchronizeInvoices = () => {
  return get(`${base}/real-rstate/realEstateInvoice/getProjectSynchronizeInvoices`)
    .then(res => res.data);
};

// 获取发票税号,取数状态
export const realEstateInvoiceStatusAndTaxNo = () => {
  return get(`${base}/real-rstate/realEstateInvoice/getInfo`)
    .then(res => res.data);
};

// 发票部分
// 发票详情页面
// 查询进项公司
export const getEntryCompanyList = params => {
  return post(`${base}/real-rstate/invoiceTotal/entry/getCompanyList`, params)
    .then(res => res.data);
};
// 查询进项商品
export const getEntryInvoiceGoodsList = params => {
  return post(`${base}/real-rstate/invoiceTotal/entry/getInvoiceGoodsList`, params)
    .then(res => res.data);
};
// 查询进项品类
export const getEntryInvoiceCategoryList = params => {
  return post(`${base}/real-rstate/invoiceTotal/entry/getInvoiceCategoryList`, params)
    .then(res => res.data);
};
// 查询进项发票
export const getEntryInvoiceList = params => {
  return post(`${base}/real-rstate/invoiceTotal/entry/getInvoiceList`, params)
    .then(res => res.data);
};
// 查询进项每月进项
export const getEntryInvoiceMonthList = params => {
  return post(`${base}/real-rstate/invoiceTotal/entry/getInvoiceMonthList`, params)
    .then(res => res.data);
};

// 获取第三方接口需要的数据
export const comInspectData = (companyName, taxNo) => {
  return get(
      `${base}/open/commomInspect/getRealEstateRuleToken?companyName=${companyName}&taxNo=${taxNo}`
    )
    .then(res => res.data);
};

// 创建第三方检测记录
export const realEstateInvoiceThird = params => {
  return post(`${base}/real-rstate/realEstateInvoice/add`, params)
    .then(res => res.data);
};

// 开发成本发票数据
export const realEstateRelationInvoice = (constructionUnit, costId) => {
  return get(
      `${base}/real-rstate/realEstateRelationInvoice/getRelationInvoice?constructionUnit=${constructionUnit}&costId=${costId}`
    )
    .then(res => res.data);
};

// 关联发票
export const realEstateRelationInvoiceAdd = params => {
  return post(`${base}/real-rstate/realEstateRelationInvoice/add`, params)
    .then(res => res.data);
};

// 成本开发数据录入附件
export const realEstateCostAnnexId = (id) => {
  return get(
      `${base}/real-rstate/realEstateCostAnnex/getList?id=${id}`
    )
    .then(res => res.data);
};

// 创建房地产附件
export const realEstateCostAnnexFJAdd = params => {
  return post(`${base}/real-rstate/realEstateCostAnnex/add`, params)
    .then(res => res.data);
};

// 删除附件
export const realEstateCostAnnexFJDel = (id) => {
  return get(`${base}/real-rstate/realEstateCostAnnex/del?id=${id}`)
    .then(res => res.data);
};

// 成本统计接口
export const realEstateDevelopmentCostTotal = (id) => {
  return get(`${base}/real-rstate/realEstateDevelopmentCost/getCountCostList`)
    .then(res => res.data);
};

// export const realEstateCostAnnexFJAdd = params => {
//   return post(`${base}/real-rstate/realEstateCostAnnex/add`, params)
//     .then(res => res.data);
// };


// http://test.ai-tax.cn/real-rstate/realEstateIncome/delList
// http://test.ai-tax.cn/real-rstate/realEstateDevelopmentCost/delList
// http://test.ai-tax.cn/real-rstate/realEstateInterestExpense/delList
// http://test.ai-tax.cn/real-rstate/realEstatePayTaxes/delList


// 单独删除房地产收入房源信息表信息
export const realEstateIncomeDelAllSelect = idList => {
  return post(`${base}/real-rstate/realEstateIncome/delList`, idList)
    .then(res => res.data);
};

// 单独删除成本表
export const realEstateDevelopmentCostDelAllSelect = idList => {
  return post(`${base}/real-rstate/realEstateDevelopmentCost/delList`, idList)
    .then(res => res.data);
};

// 单独删除房地产利息支出表
export const realEstateInterestExpenseDelSelect = idList => {
  return post(`${base}/real-rstate/realEstateInterestExpense/delList`, idList)
    .then(res => res.data);
};

// 缴纳的税费 单独删除
export const realEstatePayTaxesDelAllSelect = (delList) => {
  return post(`${base}/real-rstate/realEstatePayTaxes/delList`, delList)
    .then(res => res.data);
};
