<template>
  <div class="fdc-container">
    <!-- <nav-bar /> -->
    <project class="status"></project>
    <index-status class="status"></index-status>
    <cost class="status"></cost>
    <test class="status"></test>
  </div>
</template>

<script>
import project from "./components/project";
import status from "./components/status";
import cost from "./components/cost";
import test from "./components/test";
// import NavBar from "@/layouts/HeaderAsideLayout/components/NavBar/NavBar";

export default {
  data() {
    return {};
  },
  components: {
    indexStatus: status,
    // NavBar,
    project,
    test,
    cost
  },

  methods: {},
};
</script>

<style lang="stylus" scoped>
.fdc-container {
  // padding-top: 60px;

  .status {
    margin: 16px 30px 16px 30px;
  }
}
</style>
