import { mapGetters } from "vuex";
let mixin = {
  computed: {
    ...mapGetters("ResultEditor", ["isEditableForAction"]),
    isEditable() {
      return true;
      //return this.isEditableForAction(this.$route.params.action);
    }
  }
};

export default mixin;
