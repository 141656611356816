<template>
  <div class="wrapper" :style="{height:height}">
    <div class="chart-wrapper" :style="{height:height}">
      <PieChart :colors="colors" :data="chartData" :key="JSON.stringify(chartData)"></PieChart>
    </div>

    <div class="legends" :style="{height:height}">
      <div class="legend" v-for="(item,index) in chartData" :key="index">
        <div class="title">
          <div class="box" :style="{'background':colors[index%colors.length]}"></div>
          {{item.name}}
        </div>
        <div class="value">{{item.value|percentage}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "./PieChart";
export default {
  name:'pie',
  props: {
    data: Object,
    height:{
      type:String,
      default:'362px'
    },
  },
  components: {
    PieChart
  },
  computed:{
    chartData(){
      const ret = [];
      for (const i in this.data.xLabels) {
        const label = this.data.xLabels[i];
        const value = this.data.series[0].data[i];
        ret.push({name:label,value});
      }
      return ret
    }
  },
  data() {
    return {
      colors: [
        "#C41D1D",
        "#C47C1D",
        "#C4C11D",
        "#1DC424",
        "#1D73C4",
        "#541DC4",
        "#599EF7",
        "#59F78C",
        "#F7A659",
        "#F75959",
        "#000000"
      ]
    };
  }
};
</script>

<style lang="stylus" scoped>
.wrapper {
  display: flex;
  width: 828px;
  height: 362px;

  .chart-wrapper {
    height: 362px;
    display: flex;
    align-items: center;
  }

  .legends {
    height: 362px;
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;

    .legend {
      width: 100%;
      color: #666;
      display: flex;
      justify-content: space-between;
      vertical-align: top;
      margin 6px 0;

      .box {
        display: inline-block;
        width: 10px;
        height: 18px;
        background: red;
        position: relative;
        top: 3px;
        margin-right: 5px;
      }

      .title {
        vertical-align: top;
      }

      .value {
        vertical-align: top;
      }
    }
  }
}
</style>
