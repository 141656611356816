<template>
  <div>
    <!-- 创建客户 -->
    <transition name="fades">
      <div class="containerMask" v-if="dialogTableVisible"></div>
    </transition>
    <transition name="fadee">
      <div
        class="container"
        v-if="dialogTableVisible"
        ref="projectBox"
        id="projectBoxs"
        v-loading="addLoading"
      >
        <div class="addTitle">修改手机{{next?'（1/2）':'（2/2）'}}</div>
        <div class="project-close" @click="closeDialog">×</div>
        <div v-if="next">
          <div class="project-name">
            <span class="project-named">*</span>原手机号码
          </div>
          <div class="project-input">
            <el-input placeholder="请输入" v-model="person.phone"></el-input>
            <span class="project-input-b" v-show="form1">请输入正确手机号</span>
          </div>
          <div class="project-name">
            <span class="project-named">*</span>短信验证码
          </div>
          <div class="project-input">
            <div class="project-input-code">
              <el-input placeholder="请输入" v-model="person.code"></el-input>
              <div
                class="get-code ease"
                @click="getCode"
                :class="[canClick ? '' : 'active', 'btnsendcode']"
              >{{content}}</div>
            </div>
            <span class="project-input-b" v-show="form2">短信验证码不能为空</span>
          </div>
        </div>
        <div v-if="!next">
          <div class="project-name">
            <span class="project-named">*</span>新手机号码
          </div>
          <div class="project-input">
            <el-input placeholder="请输入" v-model="person.newPhone"></el-input>
            <span class="project-input-b" v-show="form3">手机号不能为空</span>
          </div>
          <div class="project-name">
            <span class="project-named">*</span>短信验证码
          </div>
          <div class="project-input">
            <div class="project-input-code">
              <el-input placeholder="请输入" v-model="person.newCode"></el-input>
              <div
                class="get-code ease"
                @click="getCode2"
                :class="[canClick2 ? '' : 'active', 'btnsendcode']"
              >{{content2}}</div>
            </div>
            <span class="project-input-b" v-show="form4">短信验证码不能为空</span>
          </div>
        </div>
        <div class="project-btn ease" @click="nextTo" v-if="next">
          <div>下一步</div>
        </div>
        <div class="project-btn ease" @click="savePhone" v-else>
          <div>确定</div>
        </div>
      </div>
    </transition>
    <!-- <pagination class="paginationNew" :totalNumber="totalNumber" :selectArr="selectArr"></pagination> -->
  </div>
</template>

<script>
import { changePwdAi, getCodeAim, changePhoneAi, getCodeAi } from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import {
  veriFiItem,
  veriFiCustom,
  area,
  veriFiItemAdd
} from "@/pages/product/utils/limit.js";
import pagination from "@/pages/product/components/pagination.vue";

export default {
  components: {
    MainHeader,
    BreadCrumb,
    pagination
  },
  props: {
    uuid: [String, Number]
  },

  data() {
    return {
      title: "（1/2）",
      next: true,
      isEdit: false,
      addLoading: false,
      person: {
        newCode: "",
        newPhone: "",
        code: "",
        phone: ""
      },
      dialogTableVisible: false,
      loading: true,
      form1: false,
      form2: false,
      form3: false,
      form4: false,
      content: "获取验证码",
      content2: "获取验证码",
      totalTime: 60,
      totalTime2: 60,
      canClick: true,
      canClick2: true
    };
  },
  methods: {
    getCode() {
      if (!this.person.phone || !/^1[3456789]\d{9}$/.test(this.person.phone)) {
        this.form1 = true;
        return;
      }
      if (!this.canClick) {
        return;
      }
      let param = { mobile: this.person.phone, type: "RESETMOBILE" };
      getCodeAi(param).then(data => {
        if (data.msgCode !== "1") {
          this.$message.error(data.msgInfo);
          return;
        }
      });
      this.canClick = false;
      this.form1 = false;
      this.content = this.totalTime + "s后重发";
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重发";
        if (this.totalTime < 0) {
          window.clearInterval(clock);
          this.content = "重发验证码 ";
          this.totalTime = 60;
          this.canClick = true;
        }
      }, 1000);
    },

    getCode2() {
      if (
        !this.person.newPhone ||
        !/^1[3456789]\d{9}$/.test(this.person.newPhone)
      ) {
        this.form3 = true;
        return;
      }
      if (!this.canClick2) {
        return;
      }
      let param = { mobile: this.person.newPhone, type: "BINDINGMOBILE" };
      getCodeAi(param).then(data => {
        if (data.msgCode !== "1") {
          this.$message.error(data.msgInfo);
          return;
        }
      });
      this.canClick2 = false;
      this.form1 = false;
      this.content2 = this.totalTime2 + "s后重发";
      let clock = window.setInterval(() => {
        this.totalTime2--;
        this.content2 = this.totalTime2 + "s后重发";
        if (this.totalTime2 < 0) {
          window.clearInterval(clock);
          this.content2 = "重发验证码 ";
          this.totalTime2 = 60;
          this.canClick2 = true;
        }
      }, 1000);
    },

    nextTo() {
      if (!this.person.phone || !/^1[3456789]\d{9}$/.test(this.person.phone)) {
        this.form1 = true;
        return;
      }
      if (!this.person.code || !this.person.code.trim()) {
        this.form1 = false;
        this.form2 = true;
        return;
      }

      this.next = false;
    },
    savePhone() {
      if (
        !this.person.newPhone ||
        !/^1[3456789]\d{9}$/.test(this.person.newPhone)
      ) {
        this.form3 = true;
        return;
      }
      if (!this.person.newCode || !this.person.newCode.trim()) {
        this.form3 = false;
        this.form4 = true;
        return;
      }

      let params = {
        oldMobile: this.person.phone.trim(),
        oldCode: this.person.code.trim(),
        newMobile: this.person.newPhone.trim(),
        newCode: this.person.newCode.trim()
      };
      changePhoneAi(params).then(res => {
        if (res.msgCode == 1) {
          this.$message({
            message: "修改绑定手机号成功！",
            type: "success"
          });
          this.next = true;
          this.form1 = false;
          this.form2 = false;
          this.form3 = false;
          this.form4 = false;
          this.dialogTableVisible = false;
          this.person.code = "";
          this.person.newCode = "";
          this.person.phone = "";
          this.person.newPhone = "";
        } else {
          this.$message.error(res.msgInfo);
        }
      });
    },
    showDialog() {
      this.dialogTableVisible = true;
    },
    closeDialog() {
      this.next = true;
      this.form1 = false;
      this.form2 = false;
      this.form3 = false;
      this.form4 = false;
      this.dialogTableVisible = false;
      this.person.code = "";
      this.person.newCode = "";
      this.person.phone = "";
      this.person.newPhone = "";
      this.$emit("closeDialogs");
    },
    checkData(person) {
      if (!person.oldPassword) {
        this.form1 = true;
        return false;
      }
      if (!person.newPassword) {
        this.form1 = false;
        this.form2 = true;
        return false;
      }
      if (!person.newPasswordN) {
        this.form1 = false;
        this.form2 = false;
        this.form3 = true;
        return false;
      }
      if (person.newPassword != person.newPasswordN) {
        this.form3 = false;
        this.form4 = true;
        return false;
      }
      this.form1 = false;
      this.form2 = false;
      this.form3 = false;
      this.form4 = false;
      return true;
    }
  }
};
</script>

<style lang="stylus" scoped>
.containerMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1002;
}

.addTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  padding: 24px 0 20px 0;
}

.container {
  position: absolute;
  left: 50%;
  margin-left: -240px;
  top: 15vh;
  width: 480px;
  height: 312px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
}

.pagination {
  padding-top: 20px;
  text-align: right;
  margin-top: 20px;
}

>>> #projectBoxs .el-input {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs >>>.el-input >>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #projectBoxs .el-input__icon {
  line-height: 36px;
}

>>> #projectBoxs .el-cascader {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input, .addForms {
  position: relative;
}

.project-input-b {
  position: absolute;
  right: 40px;
  top: 40px;
  font-size: 12px;
  color: red;
}

.project-input .el-input {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.project-name {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 0 0 4px 32px;
}

.project-named {
  position: relative;
  top: 2px;
  margin-right: 4px;
  color: #f5222d;
}

.project-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  line-height: 68px;
  background: rgba(47, 84, 235, 1);
  box-shadow: 0px -1px 0px 0px rgba(47, 84, 235, 1);
  z-index: 1004;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.project-close {
  position: absolute;
  cursor: pointer;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  top: 6px;
  right: 20px;
}

.fadee-enter-active {
  transition: all 0.3s ease;
}

.fades-enter-active {
  transition: all 0.3s ease;
}

.fadee-leave-active {
  transition: all 0.2s ease;
}

.fades-leave-active {
  transition: all 0.3s ease;
}

.fadee-enter, .fadee-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.fades-enter, .fades-leave-to {
  opacity: 0;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  // min-height: 80vh;
  padding: 0 10px;
}

.btn-view {
  line-height: 28px;
  height: 28px;
  color: #2f54eb;
  margin-right: 10px;
  display: inline-block;
}

.btn-view:hover {
  color: rgb(8, 47, 202);
}

.filter-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .inputs-wrapper {
    width: 90%;

    .inputs-wraper-row {
      display: flex;
      // justify-content: flex-start;
      flex-wrap: wrap;

      .form-item {
        margin-right: 40px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .label {
          white-space: nowrap;
        }

        // flex-basis: 30%;
        display: flex;
      }
    }
  }

  .btn-look-up {
    margin-bottom: 16px;
    width: 70px;
    margin-right: 5px;
  }
}

.btn-blue {
  color: rgba(47, 84, 235, 1);
  cursor: pointer;
}

.done, .pedding, .delete, .perfect {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 7px;
  margin-bottom: 2px;
}

.done {
  background: rgba(82, 196, 26, 1);
}

.pedding {
  background: rgba(217, 217, 217, 1);
}

.delete {
  background: rgba(245, 34, 45, 1);
}

.perfect {
  background: rgba(250, 173, 20, 1);
}

.paginations {
  float: right;
  margin-right: 20px;
  margin-bottom: 30px;
}

.fixed-item {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 496px;
  height: 100%;
  z-index: 1000;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(251, 251, 251, 1);
  box-shadow: -4px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.fixed-content {
  width: 464px;
  height: 512px;
  background: rgba(240, 245, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(214, 228, 255, 1);
  margin: 16px 0 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.fixed-bottom {
  display: flex;
  width: 464px;
  height: 280px;
  background: rgba(240, 245, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(214, 228, 255, 1);
  margin: 8px 0 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.fixed-item-top {
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid rgba(218, 213, 232, 0.6);
}

.fixed-item-icon {
  width: 30px;
  height: 30px;
  padding: 20px 8px 16px 20px;
}

.content-title {
  margin: 16px 0 0 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
}

.content-text {
  margin: 4px 0 24px 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.content-tran {
  color: transparent;
}

.content-text-bottom {
  margin-bottom: 25px;
}

.content-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  line-height: 60px;
  background: rgba(47, 84, 235, 1);
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  cursor: pointer;
}

.content-btn:hover {
  background: rgba(47, 34, 235, 1);
}

.addItem {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 20px;
  cursor: pointer;
}

.addItem:hover {
  background: rgba(47, 34, 235, 1);
}

.project-btn:hover {
  background: rgba(47, 34, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

.cursion {
  cursor: pointer;
}

.editActive:before {
  content: '*';
  color: red;
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.content-isEdit {
  margin-bottom: 16px;
}

>>> #content-isEdit .el-input {
  width: 416px;
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #content-isEdit .el-cascader {
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input__icon {
  line-height: 36px;
}

.fixedItemEdit {
  height: 562px;
}

.detailContent {
  display: flex;
  align-item: center;
}

.detailcancel {
  width: 50%;
}

.detailcancel:after {
  content: '|';
  position: relative;
  left: 108px;
  top: 1px;
}

.detailSaves {
  width: 50%;
}

>>> .form-item .el-input__icon {
  line-height: 36px;
  height: 36px;
}

.paginationNew {
  float: right;
  margin-right: 30px;
  margin-bottom: 24px;
}

.project-input-code {
  display: flex;
}

.get-code {
  width: 118px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  margin-left: 8px;
  cursor: pointer;
  margin-right: 32px;
  padding: 0 20px;
  border: 1px solid rgba(47, 84, 235, 1);
}

.get-code:hover {
  background: rgba(47, 54, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.active {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(217, 217, 217, 1);
  color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
}

.active:hover {
  background: rgba(255, 255, 255, 1);
}
</style>