<template>
  <div>
    <bread-crumb>
      <el-breadcrumb-item :to="{ name: 'goods' }">产品中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'goods' }">服务订购</el-breadcrumb-item>
      <el-breadcrumb-item>确认订单</el-breadcrumb-item>
    </bread-crumb>
    <div class="submit-title">确认订单</div>
    <div class="submit-table">
      <div class="submit-t">服务订购</div>
      <el-table :data="info" style="width: 100%">
        <el-table-column width="14"></el-table-column>
        <el-table-column prop="goodName" label="行业" style="margin-left: 40px">
          <template slot-scope="scope">
            <img class="title-icon" :src="scope.row.faceImg" />
            <span class="title-left" style="margin-left: 16px">{{ scope.row.goodName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="服务类型">
          <template slot-scope="scope">
            <span class="title-left addred">{{ Number(scope.row.price).toFixed(2) }}/年</span>
            <span class="title-left">（{{ scope.row.useNumber>10000? "无限": scope.row.useNumber }}次）</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="应付金额(元)" width="140px">
          <template slot-scope="scope">
            <span class="addred">{{ Number(scope.row.price).toFixed(2) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="info-bottom">
        <div class="info-dis">
          订单优惠：
          <el-select v-model="activevalue" placeholder="请选择" @change="changeSele(activevalue)">
            <el-option
              v-for="item in options"
              :key="item.item"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="info-price">
          优惠金额：
          <span class="addred">{{Number(discountPrice).toFixed(2)}}元</span>
        </div>
        <div class="info-total">
          应付总计：
          <span class="addred">{{Number(priceShow).toFixed(2)}}元</span>
        </div>
      </div>
    </div>
    <div class="submit-price">
      <div class="submit-left">
        <!-- <div class="submit-check" @click="isActive = !isActive">
          <div :class="{ 'submit-circle': isActive }"></div>
        </div>-->
        <div class="submit-text"></div>
      </div>
      <div class="submit-right">
        <div class="submit-btn ease" @click="createdorder">立即支付</div>
      </div>
    </div>
  </div>
</template>

<script>
import { createOrder, someFull, useReduce } from "../../api/api";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  components: {
    BreadCrumb
  },
  data() {
    return {
      orderNum: null,
      activevalue: "",
      isActive: true,
      orderIds: "",
      isActive: true,
      options: [],
      value: "",
      discountPrice: 0,
      priceList: null,
      num: 0,
      priceShow: null,
      useDiscountId: null
    };
  },
  created() {
    if (localStorage.getItem("info")) {
      this.info = JSON.parse(localStorage.getItem("info"));
      localStorage.removeItem("info");
    }
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("info", JSON.stringify(this.info));
    });
    let refer = this.info.findIndex(item => {
      return item.referenceType == 2;
    });
    if (refer < 0) {
      this.refer = 1;
    } else {
      this.refer = this.info[refer].edition;
    }
    let goodLists = this.info.map(item => {
      return item.referenceType == 1 && item.goodId;
    });
    this.goodLists = goodLists.filter(item => {
      return item;
    });
    this.searchInfo();
  },
  methods: {
    // 使用优惠券
    changeSele(val) {
      this.useDiscountId = val[1];
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token,
        usercouponid: val[1],
        buyGoodsList: this.info
      };
      useReduce(params).then(res => {
        if (res.msgCode == 1) {
          this.priceShow = res.data.sumPrice;
          this.discountPrice = res.data.discountPrice;
        }
      });
    },

    // 查看可使用优惠券
    searchInfo() {
      let params = {
        token: JSON.parse(localStorage.getItem("token")).token,
        editionId: this.refer,
        buyGoodsList: this.info
      };
      someFull(params).then(res => {
        let data = res.data;
        let arrList = data.data;
        this.priceShow = data.sumPrice;
        this.discountPrice = data.discountPrice;
        arrList.forEach((item, index) => {
          this.options[index] = {
            label: null,
            value: null,
            index: null,
            item: null
          };
          if (item.discount != 1) {
            this.options[index].label =
              "满" + item.satisfy + "打" + Number(item.discount) * 10 + "折";
            this.options[index].value = [];
            this.options[index].value[0] = item.discount;
            this.options[index].value[1] = item.usercouponid;
            this.options[index].index = 0;
            this.options[index].item = index;
          } else {
            this.options[index].label = "满" + item.satisfy + "减" + item.price;
            this.options[index].value = [];
            this.options[index].value[0] = item.price;
            this.options[index].value[1] = item.usercouponid;
            this.options[index].index = 1;
            this.options[index].item = index;
          }
        });
        this.options.unshift({ label: "不使用优惠", value: "0" });
        this.info.reverse().reverse();
      });
    },
    // 创建支付订单
    createdorder() {
      const loading = this.$loading({
        lock: true,
        text: "正在创建订单，跳转支付...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      let params = {
        token: JSON.parse(localStorage.getItem("token")).token,
        buyGoodsList: this.info,
        name: "测试",
        useDiscountId: this.useDiscountId
      };
      createOrder(params).then(res => {
        if (res.msgCode == 1) {
          this.orderIds = res.data.id;
          this.orderNum = res.data.ordernumber;
          let result = res.data.totalAmount;
          setTimeout(() => {
            loading.close();
            localStorage.setItem("totalPrice", result);
            this.$router.push({
              path: "/product/pay",
              query: {
                id: this.orderIds,
                num: this.orderNum
              }
            });
          }, 500);
          localStorage.setItem("shopList", "");
          localStorage.setItem("nameList", "");
          localStorage.setItem("priceList", "");
        } else if (res.msgCode == "1002") {
          loading.close();
          this.$confirm(res.msgInfo, {
            confirmButtonText: "前往完善",
            showCancelButton: false,
            type: "warning"
          })
            .then(() => {
              this.$router.push({
                path: "/companyApply"
              });
            })
            .catch(() => {});
        } else if (res.msgCode == "1007") {
          loading.close();
          this.$confirm(res.msgInfo, {
            confirmButtonText: "前往完善",
            showCancelButton: false,
            type: "warning"
          })
            .then(() => {
              this.$router.push({
                path: "/setUser"
              });
            })
            .catch(() => {});
        } else if (res.msgCode == "1008") {
          loading.close();
          localStorage.setItem("shopList", "");
          localStorage.setItem("nameList", "");
          localStorage.setItem("priceList", "");
          this.$confirm(res.msgInfo, {
            confirmButtonText: "重新购买",
            showCancelButton: false,
            type: "warning"
          })
            .then(() => {
              this.$router.push({
                path: "/product/goods"
              });
            })
            .catch(() => {});
        } else {
          loading.close();
          this.$message({
            type: "info",
            message: "创建订单失败，请检查网络或咨询客服！"
          });
        }
      });
    },
    sum(arr) {
      return eval(arr.join("+"));
    },
    formatDateTes(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate();
      return Y + M + D;
    }
  }
};
</script>
<style lang="stylus" scoped>
.submit-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.8);
  padding: 8px 0 0 30px;
  font-weight: 600;
}

.submit-table {
  position: relative;
  // min-width: 1100px;
  margin: 30px 0 0 30px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  background: #fff;
  margin-right: 30px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 100px;
}

.title-icon {
  width: 28px;
  height: 28px;
}

>>>.cell {
  display: flex;
  align-items: center;
}

.submit-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 180px;
  height: 68px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  font-size: 14px;
  z-index: 999;
}

.submit-btn {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  margin-right: 30px;
  margin-left: 48px;
}

.ease {
  transition: all 0.3s;
}

.submit-btn:hover {
  background: rgba(98, 97, 242, 1);
}

>>>.el-select {
  width: 240px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-sizing: border-box;
}

>>>.el-input .el-input--suffix {
  width: 240px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-sizing: border-box;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-input__icon {
  line-height: 36px;
}

.submit-check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border: 1px solid rgba(116, 96, 238, 1);
  border-radius: 50%;
  cursor: pointer;
  margin-left: 30px;
  margin-right: 8px;
}

.submit-circle {
  width: 8px;
  height: 8px;
  background: rgba(116, 96, 238, 1);
  border-radius: 50%;
}

.submit-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.submit-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.addred {
  color: red;
}

.submit-text {
  color: rgba(0, 0, 0, 0.6);
}

div {
  user-select: none;
}

.submit-t {
  padding: 24px 0 20px 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
}

>>>.el-table__row {
  height: 52px !important;
}

.info-bottom {
  height: 112px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
}

.info-dis {
  position: absolute;
  right: 370px;
  bottom: 57px;
}

.info-price {
  display: inline-block;
  width: 170px;
  position: absolute;
  right: 34px;
  bottom: 68px;
}

.addred {
  color: red;
}

.info-total {
  position: absolute;
  display: inline-block;
  width: 170px;
  right: 34px;
  bottom: 30px;
}
</style>