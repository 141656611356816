<template>
  <div
    class="box-container box-containerNewHeight"
    id="projectBoxs"
    v-loading="addLoading"
  >
    <div class="addTitle">创建客户</div>
    <div class="project-close" @click="closeDialogBox">×</div>
    <div class="custom-overflow customOverflowX">
      <div class="project-name"><span class="project-named">*</span>纳税人识别号</div>
      <div class="project-input">
        <el-input
          placeholder="请输入"
          v-model.trim="custom.nsrsbh"
          maxlength="20"
          show-word-limit
          @blur="msgBlur"
        ></el-input>
        <span class="project-input-b" v-show="form[0]">纳税人识别号不能为空</span>
        <span class="project-input-b" v-show="form[1]"
          >最低15位最多20位数字+字母组合</span
        >
      </div>
      <div class="project-name"><span class="project-named">*</span>企业名称</div>
      <div class="project-input">
        <el-input
          placeholder="请输入"
          v-model.trim="custom.companyName"
          disabled
        ></el-input>
        <span class="project-input-b" v-show="form[2]">客户名称不能为空</span>
      </div>
      <div class="project-name"><span class="project-named">*</span>所属地区</div>
      <div class="project-input">
        <el-cascader filterable :options="cityList" v-model="custom.area"></el-cascader>
        <span class="project-input-b" v-show="form[3]">所属地区不能为空</span>
      </div>
      <div class="project-name"><span class="project-named">*</span>所属行业</div>
      <div class="project-input">
        <el-select v-model="custom.industry" placeholder="请选择" filterable>
          <el-option
            v-for="item in hyData"
            :key="item.hy_dm"
            :label="item.hymc"
            :value="item.hy_dm"
          ></el-option>
        </el-select>
        <span class="project-input-b" v-show="form[4]">行业不能为空</span>
      </div>
      <div class="project-name"><span class="project-named"></span>企业规模</div>
      <div class="project-input">
        <el-select v-model="custom.scale" placeholder="请选择">
          <el-option
            v-for="item in qyStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div>
        <div class="project-name"><span class="project-named"></span>业务员</div>
        <div class="project-input">
          <el-input
            placeholder="请输入"
            v-model="custom.salesman"
            maxlength="20"
            show-word-limit
          ></el-input>
        </div>
        <div class="project-name"><span class="project-named"></span>联系人</div>
        <div class="project-input">
          <el-input
            placeholder="请输入"
            v-model="custom.contact"
            maxlength="20"
            show-word-limit
          ></el-input>
        </div>
        <div class="project-name"><span class="project-named"></span>联系电话</div>
        <div class="project-input">
          <el-input placeholder="请输入" v-model="custom.contactNumber"></el-input>
        </div>
      </div>
    </div>
    <div class="project-btn ease">
      <div class="project-btnL ease" @click="addProjectBoth" v-if="haveTotal">
        创建客户并创建项目
      </div>
      <div class="project-btnR ease" @click="addProject">创建客户</div>
    </div>
  </div>
</template>

<script>
import { veriFiItem, area } from "@/pages/product/utils/limit.js";
import { addCustom, getCompanyMsgByTaxNo } from "@/api/api";
import { qyStatusList } from "../help.js";

export default {
  props: {
    haveTotal: [Boolean],
    hyData: [Object, Array],
    cityList: [Object, Array],
  },
  data() {
    return {
      addLoading: false,
      form: [0, 0, 0, 0],
      custom: {
        companyName: "",
      },
      qyStatusList: qyStatusList,
      companyList: {},
      checkTaxNo: false,
    };
  },
  methods: {
    msgBlur() {
      if (!this.custom.nsrsbh) return;
      this.getMsgByTaxNo();
    },
    getMsgByTaxNo() {
      getCompanyMsgByTaxNo(this.custom.nsrsbh).then((res) => {
        if (res.msgCode == 1) {
          this.companyList = res.data;
          this.custom.companyName = this.companyList.name;
          this.checkTaxNo = true;
        } else {
          this.checkTaxNo = false;
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
        }
      });
    },
    resetFormCheck() {
      this.form = [0, 0, 0, 0];
      let verification = veriFiItem(this.custom, 1);
      this.form[verification.code] = 1;
      this.form.reverse().reverse();
      if (!verification.status) {
        return false;
      } else {
        return true;
      }
    },
    closeDialogBox() {
      this.$emit("closeCreatedCustom");
    },
    addProjectBoth() {
      if (!this.haveTotal) {
        this.$message({
          message: "您未获得项目权限，无法创建项目",
          type: "warning",
        });
        return;
      }
      if (!this.resetFormCheck()) return;
      // if (!this.checkTaxNo) {
      //   this.$message({
      //     message: "请输入正确税号！",
      //     type: "warning",
      //   });
      //   return;
      // }
      this.addLoading = true;
      [this.custom.provinceCode, this.custom.cityCode, this.custom.districtCode] = [
        this.custom.area[0],
        this.custom.area[1],
        this.custom.area[2],
      ];
      getCompanyMsgByTaxNo(this.custom.nsrsbh).then((res) => {
        if (res.msgCode == 1) {
          addCustom(this.custom).then((res) => {
            this.addLoading = false;
            if (res.msgCode == 1) {
              sessionStorage.setItem("both", 1);
              this.$notify({
                title: "温馨提示",
                message: "添加客户成功",
                type: "success",
              });
              this.custom = {};
              this.$router.push({
                name: "project",
                query: {
                  bothCreate: 1,
                  consumerId: res.data.consumerId,
                  id: res.data.id,
                  name: res.data.companyName,
                },
              });
            }
          });
        } else {
          this.addLoading = false;
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
        }
      });
    },
    addProject() {
      if (!this.resetFormCheck()) return;
      // if (!this.checkTaxNo) {
      //   this.$message({
      //     message: "请输入正确税号！",
      //     type: "warning",
      //   });
      //   return;
      // }
      this.addLoading = true;
      [this.custom.provinceCode, this.custom.cityCode, this.custom.districtCode] = [
        this.custom.area[0],
        this.custom.area[1],
        this.custom.area[2],
      ];
      getCompanyMsgByTaxNo(this.custom.nsrsbh).then((res) => {
        if (res.msgCode == 1) {
          addCustom(this.custom).then((res) => {
            this.addLoading = false;
            if (res.msgCode == 1) {
              this.$notify({
                title: "温馨提示",
                message: "添加客户成功",
                type: "success",
              });
              this.custom = {};
              this.$emit("addCustomSuccess");
            }
          });
        } else {
          this.addLoading = false;
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../common.css';

.box-container {
  position: fixed;
  left: 50%;
  margin-left: -240px;
  top: 10vh;
  width: 480px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
}

.box-container >>>.el-select {
  width: 416px;
}

.box-container >>>.el-input {
  width: 416px;
}

.addTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  padding: 24px 0 20px 0;
}

.addTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  padding: 24px 0 20px 0;
}

.box-containerNewHeight {
  height: 450px;
  padding-bottom: 75px;
}

.customOverflowX {
  max-height: 375px;
  overflow-x: hidden;
}

.project-name {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 0 0 4px 32px;
}

.project-named {
  position: relative;
  top: 2px;
  margin-right: 4px;
  color: #f5222d;
}

.project-input, .addForms {
  position: relative;
}

.project-input-b {
  position: absolute;
  right: 40px;
  top: 40px;
  font-size: 12px;
  color: red;
}

.project-input .el-input {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-btn {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  line-height: 68px;
  background: rgba(47, 84, 235, 1);
  box-shadow: 0px -1px 0px 0px rgba(47, 84, 235, 1);
  z-index: 1004;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}

.project-btnL:after {
  content: '|';
  position: relative;
  left: 57px;
}

.project-btnL:hover, .project-btnR:hover {
  background: rgba(47, 44, 235, 1);
}

.project-btn div {
  flex: 1;
}

>>> #projectBoxs .el-input {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs >>>.el-input >>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #projectBoxs .el-input__icon {
  line-height: 36px;
}

>>> #projectBoxs .el-cascader {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-close {
  position: absolute;
  cursor: pointer;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  top: 6px;
  right: 20px;
}

.custom-overflow {
  margin-bottom: 90px;
}
</style>
