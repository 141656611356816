<template>
  <div class="load" element-loading-text="正在登录..." v-loading="loading"></div>
</template>

<script>
  import { mapMutations, mapState } from "vuex";
  import axios from "@/axios";
  const base = process.env.VUE_APP_API_ROOT;
  export default {
    data() {
      return {
        loading: true
      }
    },
    mounted() {
      this.getData();
    },
    methods: {
      ...mapMutations(["changeLogin"]),
      getData() {
        localStorage.setItem("authorization", this.$route.query.token);
        let token = this.$route.query.token;
        axios({
          method: "post", //请求方式
          url: `${base}/biz-admin/productDemonstrate/singleSignOn/tbdc`,
          headers: {
            'Authorization': token
          }
        }).then(data => {
          // console.log(data)
          // let data = res.data.data;
          // localStorage.setItem("user", JSON.stringify(data.user));
          // localStorage.setItem("token", JSON.stringify(data.token));
          // this.changeLogin({ authorization: data.token.token });
          // this.$router.push({ path: "/" });
          // 
          // 
          let result = data;
          let user = result.data;
          let token = result.headers.authorization;
          /* 是否是超级管理员进行存储 */
          let adminStatus = result.data.firmId;
          if (result.data.firmId == 1) {
            localStorage.setItem("adminStatus", JSON.stringify(adminStatus));
          }
          localStorage.setItem("user", JSON.stringify(user.data));
          localStorage.setItem("token", JSON.stringify(token));
          this.changeLogin({ authorization: token });
          this.$router.push({ name: "homePage" });
        })
      }
    }
  }
</script>

<style scoped>
  .load {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
</style>