<template>
  <div class="section-box-b">
    <div>
      <div class="title-content">发票数据采集操作流程</div>
      <div class="wrapper">
        <div class="item">
          <div class="order-circle">1</div>
          <div class="item-inner">
            <div class="title-wrapper">
              <div class="title">
                下载插件至-开票电脑
                <!-- <a
                  href="//shuian-file.oss-cn-hangzhou.aliyuncs.com/goods/%E6%8F%92%E4%BB%B6/AI%E7%A8%8E%E5%8A%A1%E5%B8%880.5.20200419_ex.exe"
                  class="btn-download"
                >下载插件至-开票电脑</a>-->
              </div>
            </div>
            <div class="content">点击<b>"右上角"</b>下载插件</div>
            <div class="content">即可下载发票取值工具</div>
          </div>
        </div>
        <div class="item">
          <div class="order-circle">2</div>
          <div class="item-inner">
            <div class="title-wrapper">
              <div class="title">安装并打开插件</div>
            </div>
            <div class="content"><b>"金税盘"</b>需安装在开票软件电脑内</div>
            <div class="content"><b>"税控盘"</b>安装在任意电脑即可</div>
          </div>
        </div>
        <div class="item">
          <div class="order-circle">3</div>
          <div class="item-inner">
            <div class="title-wrapper">
              <div class="title">金税盘/税控盘</div>
            </div>
            <div class="content">
              插入
              <b>"税盘"</b>
            </div>
            <div class="content">并输入证书密码</div>
          </div>
        </div>
        <div class="item itemRight">
          <div class="order-circle">4</div>
          <div class="item-inner">
            <div class="title-wrapper title-wrapper-p">
              <div class="title">数据读取</div>
            </div>
            <div class="content">
              <b>"运行插件"</b>
            </div>
            <div class="content">即可完成数据读取</div>
          </div>
        </div>
      </div>
      <div>
        <div class="player-container">
          <video-player
            class="vjs-custom-skin"
            :options="playerOptions"
          ></video-player>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//引入video样式
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
//引入hls.js
// import "videojs-contrib-hls.js/src/videojs.hlsjs";
export default {
  data() {
    return {
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        controls: true, //控制条
        preload: "auto", //视频预加载
        muted: false, //默认情况下将会消除任何音频。
        loop: false, //导致视频一结束就重新开始。
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src:
              "http://shuian-file.oss-cn-hangzhou.aliyuncs.com/user/fpqs.mp4?1", //你所放置的视频的地址，最好是放在服务器上
          },
        ],
        poster: "", //你的封面地址（覆盖在视频上面的图片）
        width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },
    };
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {
  display: flex;
  margin-top: 28px;
  justify-content: center;
  padding: 0 50px;

  // padding-left: 70px;
  .item {
    display: flex;
    width: 25%;
    padding: 0 16px;

    .order-circle {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      border: 1px solid #999;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      font-size: 14px;
    }

    .item-inner {
      margin-left: 5px;
      flex-grow: 1;
      padding-left: 8px;

      .title-wrapper {
        border-top: 1px solid #eee;

        .title {
          color: #333;
          font-size: 16px;
          font-weight: 600;
          background: #fff;
          position: relative;
          display: inline-block;
          top: -15px;
          padding-right: 16px;
          line-height: 32px;

          .btn-download {
            padding: 0 16px;
            height: 32px;
            background: rgba(47, 84, 235, 1);
            border-radius: 4px;
            color: #fff;
            line-height: 32px;
            text-align: center;
            display: block;
            font-size: 14px;
            font-weight: normal;
          }
        }

        position: relative;
        top: 15px;
      }

      .title-wrapper.highlighted {
        border-color: #2F54EB;
      }

      .content {
        color: #999;
        font-size: 14px;
        margin-top: 8px;

        b {
          color: #666;
        }
      }
    }
  }
}

.section-box-b {
  margin: 16px 30px 0;
  height: 850px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.title-wrapper-p {
  border-top: none !important;
}

.title-content {
  height: 54px;
  line-height: 54px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  padding-left: 16px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.form-wrapper .item {
  margin-top: 0;
}

.player-container {
  width: 1080px;
  height: 373px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 24px;
}

.wrapper .itemRight {
  width: 170px;
}
</style>