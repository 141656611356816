<template>
  <div class="btns">
    <button class="no-style switcher" :class="{actived:this.value=='chart'}"
            @click="handleItemClick('chart')">
      <i class="iconfont">&#xe637;</i>
    </button>
    <button class="no-style switcher" :class="{actived:this.value=='table'}"
            @click="handleItemClick('table')"><i class="iconfont">&#xe636;</i></button>
  </div>
</template>

<script>
import "@/pages/Result/ThreeYearResult/global.styl"

export default {
  name: "TinySwitcher",
  props: {
    value: String
  },
  methods: {
    handleItemClick(key) {
      this.$emit("input", key)
    }
  }
}
</script>

<style lang="stylus" scoped>
.btns
  .switcher
    cursor pointer
    color #E7E6EB

  .switcher.actived
    color #1762FF
</style>
