<template>
  <div>
    <div v-if="haveIndexAuth">
      <index-title
        class="title"
        :statistics="statistics"
        :indexToDoList="indexToDoList"
        v-if="haveData"
      ></index-title>
      <index-status class="status" v-if="haveData"></index-status>
      <start-check></start-check>
    </div>
    <div v-else class="noIndex">
      <div class="noIndexImg">
        <img src="@/assets/brand/noIndex.png" alt />
      </div>
      <div>正在登录...</div>
    </div>
  </div>
</template>

<script>
import title from "./components/title";
import status from "./components/status";
import startCheck from "./components/startCheck";
import { getIndex, indexToDo, byTokenGetUser } from "@/api/api.js";
import { validate } from "vee-validate";

export default {
  data() {
    return {
      statistics: null,
      indexToDoList: null,
      meList: null,
      haveTotal: false,
      roleList: null,
      haveData: true,
      haveIndexAuth: false,
      haveDataNo: true,
    };
  },
  components: {
    indexTitle: title,
    indexStatus: status,
    startCheck,
  },
  created() {
    // // 判断是否有首页权限
    // this.checkIndex(1);
    // // 判断是否有查看图表权限
    // this.checkIndexData(2);
    // // 判断是否有代办任务权限
    // this.checkIndexNo(3);
    this.tokenGetUser();
  },

  methods: {
    tokenGetUser() {
      let url = window.location.href;
      let newUrl = url.substr(url.indexOf("exteriorToken=") + 14);
      console.log(newUrl);
      if (url.indexOf("exteriorToken=") != -1) {
        localStorage.setItem("token", newUrl);
        localStorage.setItem("authorization", newUrl);
        byTokenGetUser().then((res) => {
          let user = res.data.user;
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("bindCode", 1030);
          localStorage.setItem("changeColor", 1030);
          this.$router.push({ name: "homePage" });
        });
      }
    },
    // 权限方法
    checkAuthMethod(id) {
      let getUserAuth = JSON.parse(localStorage.getItem("user")).authorities;
      if (getUserAuth.length == 0) {
        return "none";
      }
      this.roleList = JSON.parse(localStorage.getItem("user")).authorities[0].roleList;
      let roleListId = this.roleList.map((item) => {
        return item.id;
      });
      if (roleListId.indexOf(id) == -1) {
        return false;
      } else {
        return true;
      }
    },
    // 判断是否有首页权限
    checkIndex(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveIndexAuth = false;
      }
    },
    // 查看是否有数据图表权限
    checkIndexData(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveData = false;
      } else {
        getIndex(localStorage.getItem("authorization")).then((res) => {
          this.statistics = res.data;
        });
      }
    },
    // 查看是否有代办任务权限
    checkIndexNo(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveDataNo = false;
      } else {
        indexToDo(localStorage.getItem("authorization")).then((res) => {
          this.indexToDoList = res.data;
        });
      }
    },
  },
};
</script>

<style scoped>
.status {
  margin: 16px 30px 0 30px;
}

.noIndex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 792px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 24px 30px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.noIndexImg {
  width: 470px;
  height: 270px;
  margin-bottom: 16px;
}

.noIndexImg img {
  width: 100%;
  height: 100%;
}
</style>
