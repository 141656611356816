import axios from "@/axios";
const base = process.env.VUE_APP_API_ROOT_FK;
const base_wechat = process.env.VUE_APP_API_ROOT_WECHAT;

//第三方登录后注册
export const regirster3rdPartyAuth = async params => {
  const { mobile, verifyCode, openId, nickName, headImgUrl, unionId, regCode = "" } = params;
  const url = `${base}/thirdUser/wxRegister`;
  const res = await axios.post(url, {
    mobile,
    verifyCode,
    openId,
    unionId,
    regCode,
    nickName,
    headImgUrl
  });
  return res.data;
};

//根据userId获取用户信息
export const getUserByMobile = async mobile => {
  const url = `${base}/thirdUser/getUserByMobile`;
  const res = await axios.post(url, { mobile });
  return res.data;
};

//发送短信验证码
export const getSMSCode = params => {
  return axios.get(`${base}/bCompanyUser/code/WXREGISTER/` + params).then(res => res.data);
};

//发送切换绑定验证码
export const getBindSMSCode = mobile => {
  return axios.get(`${base}/bCompanyUser/code/CHANGEWX/` + mobile).then(res => res.data);
};

//微信切换校验验证码
export const verifyCodeForWx = async params => {
  const { mobile, verifyCode } = params;
  const url = `${base}/thirdUser/verifyCodeForWx`;
  const res = await axios.post(url, { mobile, verifyCode });
  return res.data;
};

//检查手机号绑定微信
export const checkIsBindWx = async mobile => {
  const url = `${base}/thirdUser/checkIsBindWx`;
  const res = await axios.post(url, { mobile });
  return res.data;
};

//微信公众号登录
export const getWxQRCode = async () => {
  const url = `${base_wechat}/wechat/getQRCode`;
  const res = await axios.get(url);
  return res.data;
};

//微信登录轮询
export const checkScanLogin = async sceneStr => {
  const url = `${base_wechat}/wechat/checkScanLogin`;
  const res = await axios.get(url, { params: { sceneStr:sceneStr } });
  return res.data;
};
