<template>
  <div>
    <div class="z-header">
      <el-breadcrumb separator="/" class="z-breadcrumb">
        <el-breadcrumb-item>指标模型</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/editor/menuData' }"
          >元数据管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>元数据详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="z-title">元数据详情</div>
    </div>
    <div class="z-detail" v-if="metaList">
      <div class="z-detail-title">元数据信息</div>
      <div class="z-detail-content">
        <div class="z-detail-left">
          <div class="z-detail-input">
            <div class="z-input-name">元数据表：</div>
            <el-select
              v-model="editData.metaTableKey"
              placeholder="请选择"
              v-if="!edit"
              @change="changeEdit"
            >
              <el-option
                v-for="item in tableList"
                :key="item.tableNameEn"
                :label="item.tableNameCn"
                :value="item.tableNameEn"
              >
              </el-option>
            </el-select>
            <div v-if="edit">{{ metaList.metaTableName }}</div>
          </div>
          <div class="z-detail-input">
            <div class="z-input-name">表字段：</div>
            <el-select
              v-model="editData.metaTableFieldKey"
              placeholder="请选择"
              v-if="!edit"
            >
              <el-option
                v-for="item in tableNameEnList"
                :key="item.mergeColumnEn"
                :label="item.mergeColumnCn"
                :value="item.mergeColumnEn"
              >
              </el-option>
            </el-select>
            <div v-if="edit">{{ metaList.metaTableField }}</div>
          </div>
          <div class="z-detail-input">
            <div class="z-input-name">类型：</div>
            <!-- <el-select
              v-model="editData.dataType"
              placeholder="请选择"
              v-if="!edit"
            >
              <el-option
                v-for="item in metaDataTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            <div>{{ typeData[metaList.dataType] }}</div>
          </div>
        </div>
        <div class="z-detail-left">
          <div class="z-detail-input">
            <div class="z-input-name">名称：</div>
            <el-input
              v-model.trim="editData.metaName"
              placeholder="请输入内容"
              v-if="!edit"
              maxlength="40"
              show-word-limit
            ></el-input>
            <div v-if="edit">{{ metaList.metaName }}</div>
          </div>
          <div class="z-detail-input">
            <div class="z-input-name">目录：</div>
            <el-cascader
              :options="getTreeList"
              :props="{ checkStrictly: true, expandTrigger: 'hover' }"
              v-model="editData.catalog"
              v-if="!edit"
              ref="refHandle"
            ></el-cascader>
            <div
              class="z-addMenu"
              @click="dialogTableVisible = true"
              v-if="!edit"
            >
              +新增
            </div>
            <div v-if="edit">{{ metaList.catalogName }}</div>
          </div>

          <div class="z-detail-inputF">
            <div class="z-input-name">描述：</div>
            <el-input
              type="textarea"
              :rows="2"
              v-model.trim="editData.metaDescription"
              placeholder="请输入内容"
              v-if="!edit"
              maxlength="100"
              show-word-limit
            ></el-input>
            <div v-if="edit">{{ metaList.metaDescription }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="z-editorDeital-footer">
      <div
        class="z-editorDeital-editBtn ease btn-right"
        @click="editMeta"
        v-if="edit"
      >
        编辑
      </div>
      <div
        class="z-editorDeital-editBtn ease btn-right"
        @click="hold"
        v-if="!edit"
      >
        保存
      </div>
      <div
        class="z-editorDeital-cancelBtn ease btn-right"
        v-if="!edit"
        @click="edit = true"
      >
        取消
      </div>
    </div>
    <!-- 新增元数据 -->
    <transition name="fades">
      <div
        class="containerMask"
        v-if="dialogTableVisible"
        @click="dialogTableVisible = false"
      ></div>
    </transition>
    <transition name="fadee">
      <add-menu
        v-if="dialogTableVisible"
        @closeAddBox="closeAddBox"
        @addSuccess="addRoleSuccess"
      ></add-menu>
    </transition>
  </div>
</template>

<script>
import addMenu from "./addmenu";
import {
  metaDetail,
  getMenu,
  updateMenu,
  getAllMeta,
  getMetaDetail,
} from "@/api/editor";
import {
  typeList,
  typeData,
  metaType,
  menuData,
  metaTypeList,
} from "@/utils/constant";
import { transformation } from "@/utils/constant";

export default {
  components: {
    addMenu,
  },
  data() {
    return {
      metaList: null,
      edit: true,
      dialogTableVisible: false,
      name: "",
      input: "",
      typeData: typeList,
      options: [],
      metaDataType: "",
      metaDataTypeList: metaTypeList,
      editData: null,
      getTreeList: null,
      tableList: null,
      tableNameEnList: null,
    };
  },
  mounted() {
    this.getData();
    this.getTree();
  },
  methods: {
    hold() {
      this.updateMenus();
    },
    editMeta() {
      this.getAllMetas();
      this.getMetaDetails(this.metaList.metaTableKey);
      this.editData = JSON.parse(JSON.stringify(this.metaList));
      this.editData.catalog = this.editData.catalog.split(",");
      this.edit = false;
    },
    getData() {
      let params = {
        metaId: this.$route.query.id,
      };
      metaDetail(params).then((res) => {
        if (res.msgCode == 1) {
          this.metaList = res.data;
        }
      });
    },
    closeAddBox() {
      this.dialogTableVisible = false;
    },
    addRoleSuccess() {
      this.getTree();
      this.dialogTableVisible = false;
    },
    back() {
      this.$router.go(-1);
    },
    getTree() {
      let params = {
        catalogType: "PF.YSJ",
        catalogSelectUse: false,
      };
      getMenu(params).then((res) => {
        if (res.msgCode == 1) {
          this.getTreeList = res.data;
          if (!this.getTreeList || this.getTreeList.length < 1) return;
          this.getTreeList = transformation(this.getTreeList);
        }
      });
    },
    updateMenus() {
      if (!this.editData.metaName) {
        this.$message({
          message: "名称不能为空！",
          type: "warning",
        });
        return;
      }
      let params = {
        catalog: this.editData.catalog.join(","),
        catalogId: this.editData.catalog[this.editData.catalog.length - 1],
        dataType: this.editData.dataType,
        entityType: "PF.YSJ",
        metaDescription: this.editData.metaDescription,
        metaId: this.editData.metaId,
        metaName: this.editData.metaName,
        metaTableFieldKey: this.editData.metaTableFieldKey,
        metaTableKey: this.editData.metaTableKey,
        updateUserId: JSON.parse(localStorage.getItem("user")).id,
        updateUserName: JSON.parse(localStorage.getItem("user")).realName,
      };
      updateMenu(params).then((res) => {
        if (res.msgCode == 1) {
          this.$message({
            message: "修改成功！",
            type: "success",
          });
          this.getData();
          this.edit = true;
        }
      });
    },
    getAllMetas() {
      getAllMeta().then((res) => {
        if (res.code == 200) {
          this.tableList = res.data;
        }
      });
    },
    changeEdit() {
      this.editData.metaTableFieldKey = "";
      this.getMetaDetails(this.editData.metaTableKey);
    },
    getMetaDetails(tableNameEn) {
      let params = {
        tableNameEn: tableNameEn,
      };
      getMetaDetail(params).then((res) => {
        if (res.code == 200) {
          this.tableNameEnList = res.data;
        }
      });
    },
  },
  watch: {
    editData: {
      handler() {
        if (this.$refs.refHandle) {
          this.$refs.refHandle.dropDownVisible = false; //监听值发生变化就关闭它
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="stylus">
.z-detail {
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 16px 30px 0 30px;
}

.z-detail-content {
  padding: 8px 0 24px 16px;
}

.z-detail-title {
  height: 54px;
  line-height: 54px;
  padding-left: 24px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #eeeeee;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.z-detail-left {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.z-detail-input {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 16px;
}

.z-input-name {
  width: 80px;
  text-align: right;
}

.z-addMenu {
  font-size: 14px;
  color: #2F54EB;
  margin-left: 8px;
  cursor: pointer;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-input__icon {
  line-height: 36px;
}

>>>.el-select {
  width: 400px;
}

>>>.el-input {
  width: 400px;
}

>>>.el-textarea {
  width: 400px;
}

.z-detail-inputF {
  display: flex;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 16px;
}
</style>