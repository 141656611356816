<template>
  <div>
    <!--标题-->
    <div class="head-wrapper">
      <!--标题的编辑弹出框-->
      <el-popover
        placement="top-start"
        width="400"
        trigger="manual"
        v-model="toolTipVisible"
        @show="handleTooltipShow"
      >
        <div class="form-control-wrapper">
          <div class="input-wrapper">
            <el-input v-model="titleInput" placeholder="请输入内容"></el-input>
          </div>

          <div class="btn-wrapper">
            <button class="btn btn-default" @click="handleBtnSaveClick">
              确定
            </button>
            <button class="btn btn-default" @click="handleBtnCancelClick">
              取消
            </button>
          </div>
        </div>
        <template slot="reference">
          <div class="head-level-0" :class="{ editable: isEditable }">
            <template v-if="(typeof indexes[0])=='number'">
              {{ chineseNumber(indexes[0] + 1) }}、
            </template>
            {{ node.titleName }}
          </div>

          <i
            class="icon iconfont iconbianji1 icon-head"
            v-if="isEditable"
            @click="handleHeadClick"
          ></i>
        </template>
      </el-popover>
    </div>

    <!--/标题的编辑弹出框-->

    <!--标题-->
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters,mapActions } from "vuex";
import mixin from "@/pages/SimpleResultEditor/mixin.js";

const nzhcn = require("nzh/cn");
import { cloneDeep } from "lodash";
export default {
  mixins: [mixin],
  props: {
    indexes: Array,
  },
  computed: {
    ...mapState("ResultEditor", ["treeData", "overall"]),
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes"]),
    node() {
      if (this.pos == "overall") {
  
        const overall = cloneDeep(this.overall);
        return overall;
      } else {
        return this.findTheNodeByIndexes(this.indexes);
      }
    },
    pos() {
      return this.indexes.join(".");
    },
  },
  methods: {
    ...mapActions("ResultEditor", ["refresh", "submit"]),
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes"]),
    //阿拉伯数字转中文
    chineseNumber(number) {
      return nzhcn.encodeS(number);
    },
    handleHeadClick() {
      if (!this.isEditable) {
        return;
      }
      this.toolTipVisible = true;
    },
    handleBtnCancelClick() {
      this.toolTipVisible = false;
    },
    handleBtnSaveClick() {
      this.toolTipVisible = false;

      //如果是综述则修改综述里的titleName

      if (this.pos == "overall") {
        const node = cloneDeep(this.node);
        node.titleName = this.titleInput;
        this.$store.commit("ResultEditor/setOverall", { data: node });
      } else {
        const nodeData = cloneDeep(this.node);
        nodeData.titleName = this.titleInput; //给节点设置新的titleName
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });
      }
      this.submit()
    },
    handleTooltipShow() {
      this.titleInput = this.node.titleName;
    },
  },
  data: function () {
    return {
      titleInput: "",
      toolTipVisible: false,
    };
  },
};
</script>

<style lang="stylus" scoped>
.head-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .icon-head {
    cursor: pointer;
    color: #2F54EB;
    margin-left: 16px;
    font-size: 22px;
  }

  .head-level-0 {
    background: #D6E4FF;
    border-radius: 16px;
    min-width: 356px;
    display: inline-block;
    padding: 0 30px;
    box-sizing: border-box;
    height: 42px;
    line-height: 42px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    margin: 0 auto;
  }

  .head-level-0.editable {
    cursor: pointer;
  }
}

.form-control-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .input-wrapper {
    flex-grow: 1;
  }

  .btn-wrapper {
    margin-left: 10px;
    display: flex;

    .btn {
      margin: 0 5px;
    }
  }
}
</style>