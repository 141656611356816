<template>
  <div>
    <el-dialog
      title="授权协议"
      width="820px"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <div class="content">
        <div>
          本《授权协议》适用于杭州税安科技有限公司（以下简称税安科技）提供的所有服务，在您使用税安科技相关服务之前，请您务必审慎阅读、充分理解本《授权协议》中与您的权益（可能）存在重大关系的条款，税安科技已采用加粗字体或下划线来特别提醒您，请您留意重点查阅。当您使用税安科技的服务时，您已同意本《授权协议》。请注意税安科技会不时地检查并更新政策，因此有关的措施会随之变化。税安科技恳请您定期光顾本页面，以确保对本《授权协议》最新版本始终保持了解。
        </div>
        <!-- <div class="title">我们如何收集和使用信息？</div>
        <div>
          您通过下载插件，并通过插入金税盘与输入金税盘账号密码的形式，授权我们采集您使用服务期间的财务数据，包括但不限于进销项发票数据。您通过填写电子税务局账号密码的方式，授权我们采集您使用服务期间的纳税申报表数据，包含但不限于所得税纳税申报表、增值税纳税申报表、财务报表。
        </div> -->
        <div class="title-1">1、数据收集</div>
        <div>
          （1）您通过填写电子税务局账号密码的方式，自愿授权税安科技采集您在使用服务期间的纳税申报数据，包含但不限于进销项发票数据、所得税纳税申报表、增值税纳税申报表、小税种纳税申报表、财务报等。
          <br />（2）您通过下载插件，并通过插入税盘和输入相关账号密码，自愿授权税安科技采集您在使用服务期间的发票数据，包括但不限于进销项发数据。
          <br />（3）您通过在平台上传纳税申报表，自愿授权税安科技采集您在使用服务期间的纳税申报数据和财务数据，包含但不限于所得税纳税申报表、增值税纳税申报表、小税种纳税申报表、财务报表等。数据内容包括但不限于您通过财务软件获取的数据、导入
          Excel表格所载明的 数据、通过 HTML 获取的数据、或通过手动填写的数据。
        </div>
        <div class="title-1">2、数据用途</div>
        <div>
          以上数据，将作为您所在的企业数据展示使用及风险数据评估使用。在您授权的情况下，以上数据可应用于税安科技其他产品或税安科技第三方合作伙伴。在您未授权的情况下，税安科技其他产品或第三方合作伙伴将无权使用您的数据。税安科技将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的信息来源合法的前提下，收集并使用您的这些信息，但仍需您同意第三方合作伙伴的相授权协议。
        </div>
        <div class="title-1">3、信息安全</div>
        <div>
          税安科技为您的信息提供相应的安全保障，以防止信息的丢失、不当使用、未经授权访问或披露。
        </div>
        <div>
          （1）税安科技严格遵守法律法规保护您的通信秘密。
          <br />（2）税安科技将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，使用加密技术（例如，TLS、SSL）、匿名化处理等手段来保护您的信息。
          <br />（3）税安科技建立专门的管理制度、流程和组织确保信息安全。例如，严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审查。
          <br />（4）若发生信息泄露等安全事件，税安科技会启动应急预案，阻止安全事件扩大，并以推送通知、公等形式告知您。
          <br />（5）尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入税安科技的服务所用的系统和通讯网络，有可能因税安科技可控范围外的因素而出现问题。因此，您应采取积极措施护您的信息安全。
        </div>
        <div class="title-1">4、免责声明</div>
        <div>
          （1）您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害以及社会事件如战争、动乱、政府行为等。出现上述情况时，税安科技将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失税安科技在法律允许的范围内免责。
          <br />（2）您理解并同意，您使用的产品中的部分服务由第三方独立开发并提供，在您选择使用此类服务之前，请您关注其中可能存在的风险，并遵守第三方制定的相应使用规则。本《授权协议》仅适用于税安科技所收集、保存、使用、共享、披露信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，税安科技对任何第三方使用由您提供的信息不承担任何责任。
          <br />（3）在任何情况下，税安科技及公司的董事、高级职员、雇员、关联公司、代理、承包商或许可方均不对由您对有关服务的使用或无法使用所引起的任何间接、附带、惩罚性、特殊或后果性的不可预期的损害赔偿承担责任。您已了解，您应自担服务使用风险，并且税安科技将按“现状”提供服务，服务“可能存在各种缺陷”且只提供“目前可用功能”。税安科技不保证服务所提供的信息准确性或时性。
        </div>
        <div class="title-1">5、其他</div>
        <div>
          （1）本协议自您确认并接受协议内容后之日起生效，如您未同意本协议，请勿进行后续操作，并立即停止授权。您将无法正常使用税安科技提供的服务。
          <br />（2）税安科技承诺，在您使用服务期间所采集的信息，您停止使用服务后税安科技也将及时删除或销毁此信息，不将您的信息挪作他用。
          <br />（3）当您因为《授权协议》或相关服务与税安科技产生任何纠纷时，双方应首先协商友好解决；若不能协商解决，您同意将争议提交至税安科技所在地人民法院管辖。
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    openDialog() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.content {
  /* width: 1200px;
  height: 1089px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px; */
  /* margin: 30px;
  padding: 22px 352px 22px 48px;
  box-sizing: border-box; */
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.5;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  margin: 24px 0 8px 0;
}

.title-1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  margin: 16px 0 8px 0;
}
</style>
