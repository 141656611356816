<template>
  <div class="content">
    <div class="logo">
      <img src="@/assets/brand/checkLogo.png" alt />
    </div>
    <div class="start">
      <div>
        一键智能检测企业的税务风险，帮助企业提前发现涉税隐患，降低涉
        <br />税风险，同时提供解决方案。
      </div>
      <div v-if="haveIndexCheck" class="btn ease" @click="add">立即检测</div>
    </div>
  </div>
</template>

<script>
import { getUUIDAi } from "@/api/api";
export default {
  data() {
    return {
      meList: null,
      haveIndexCheck: true,
    };
  },
  mounted() {
    // 查看是否有开始检测权限
    this.checkIndexCheck(15);
  },
  methods: {
    // 权限方法
    checkAuthMethod(id) {
      let getUserAuth = JSON.parse(localStorage.getItem("user")).authorities;
      if (getUserAuth.length == 0) {
        return "none";
      }
      this.roleList = JSON.parse(localStorage.getItem("user")).authorities[0].roleList;
      let roleListId = this.roleList.map((item) => {
        return item.id;
      });
      if (roleListId.indexOf(id) == -1) {
        return false;
      } else {
        return true;
      }
    },
    // 判断是否有首页权限
    checkIndexCheck(id) {
      if (this.checkAuthMethod(id) == "none") {
        return;
      }
      if (!this.checkAuthMethod(id)) {
        this.haveIndexCheck = false;
      }
    },

    async add() {
      sessionStorage.setItem("method", 1);
      // if (name == "test-hub") {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //const specId = this.specs[0].id;
      const uuidData = await getUUIDAi();
      if (uuidData.msgCode == 1) {
        this.loading.close();
        this.uuid = uuidData.data.uuid;
        this.$router.push({
          name: "choose-table",
          params: {
            productID: this.$store.state.goods.id,
            bankId: this.$store.state.goods.bankId,
            productName: "风险检测",
            uuid: this.uuid,
          },
        });
      } else {
        alert(uuidData.msgInfo);
        this.loading.close();
      }
    },
  },
  destroyed() {
    this.loading && this.loading.close();
  },
};
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 30px;
  height: 214px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-top: 16px;
  margin-bottom: 30px;
}

.logo {
  margin-right: 114px;
}

.start {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.4;
}

.btn {
  width: 480px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  color: #fff;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 30px;
  margin-top: 16px;
  cursor: pointer;
}

.btn:hover {
  background: rgba(47, 40, 235, 1);
}

.ease {
  transition: all 0.3s;
}
</style>
