<template>
  <div>
    <div class="chart-title">住宅类销售比率</div>
    <div :id="item.soldAmount" class="chart"></div>
  </div>
</template>

<script>
import echarts from "echarts";
export default {
  props: {
    item: [Object, Array],
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1048, name: "Search Engine" },
              { value: 735, name: "Direct" },
              { value: 580, name: "Email" },
              { value: 484, name: "Union Ads" },
              { value: 300, name: "Video Ads" },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    // this.pieChartData();
  },
  methods: {
    pieChartData() {
      var chartDom = document.getElementById(this.item.soldAmount);
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "item",
        },
        legend: { left: "left", orient: "vertical" },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1048, name: "Search Engine" },
              { value: 735, name: "Direct" },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="stylus" scoped>
.chart {
  width: 300px;
  height: 200px;
}

.chart-title {
  padding: 16px 0;
  text-align: center;
}
</style>
