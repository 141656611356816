<template>
  <div class="bill-confirm-c">
    <template v-if="userInfo == 1">
      <report-method
        @setName="changeName"
        ref="showReport"
        v-if="!isTaxAutoProcessing"
        @openlicense="openlicense"
        :deleteDataTypeNum="1"
        :zq="2022004"
        :uuid="uuid"
        :nsrsbhIndex="getBillData.nsrsbh"
        :thirdDataSource2="thirdDataSource"
      ></report-method>
      <div v-else>
        <div class="data-loading-wrapper">
          <img src="@/assets/test/data-loading.png" style="width: 465px" />
          <div class="text">数据读取中…</div>
        </div>
      </div>
    </template>
    <div class="bill-confirm" @click="handleBtnNext">提交</div>
    <licen ref="opendialog"></licen>
  </div>
</template>

<script>
import {
  useAuthorize,
  checkCompany,
  checkNew,
  breezeOuter,
  breezeOuterAi,
  addReportValue,
  findTaxAutoStatusByUuid,
  ptInvoiceBasicInfoCheck,
  //
  newShanxiBreeze,
} from "@/api/api";
import { cloneDeep } from "lodash";
import BreadCrumb from "@/components/BreadCrumb";
import { getUserTables, clearUploadedTableforTest } from "@/api/table";
import licen from "@/pages/Test/UploadTable/components/licen-dialog";

import {
  clearUploadedTableforTestAi,
  showBalanceDetail,
  useAuthorizeAi,
  deleteTableAi,
  getAreaMsg,
  zjBreezeLoginCreateTask,
  zjBreezeLoginCreateTaskBj,
} from "@/api/api";

import { loadTableData } from "@/api/table";

import { mapState, mapMutations } from "vuex";
import { zq2name, previousZq } from "@/utils/helper";
import reportMethod from "@/pages/Test/ChooseTable/components/report-method";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      thirdDataSource: "",
      getBillData: this.$route.query.info ? JSON.parse(this.$route.query.info) : {},
      uuid: this.$route.query.uuid,
      isTaxAutoProcessing: false,
      nameListArr: null,
      nameInfoList: "",
      identityValue: "",
      jsmobileCode: "",
      jscookies: "",
      jsinfo: "",
      canUpload: false,
      processTexting: true,
      processTexted: false,
      progressUpload: false,
      progressUploadStatus: "0",
      balanceName: "",
      showTables: false,
      tableData: [],
      tables: {},
      tipDialogVisible: false, //刚开始进入的提示框
      fullPage: true,
      isLoading: false,
      acknowledgeDialogVisible: false,
      uploadErrorDialogVisible: false, //控制无法识别的表的弹窗
      uploadErrors: [], //错误的表名及原因
      templateDownloadURL: "",
      hasItemsInList: false,
      actualZq: 0,
      zqs: [],
      showSDSDialog: false,
      userInfo: sessionStorage.getItem("method") || 1,
      checkParts: false,
      code: 9996,
      index: -1,
      step2Msg: null,
      valueMsg: "",
      area: "",
      bizNo: "",
      radioIndex: "",
      passwordInput: "",
      clickQr: "",
      index1Data: "",
      indexJylsh: "",
      mobile: "",
      mobileCode: "",
      showTableDetail: false,
      batchNo: "",
      // 山东
      czydm: "",
      // 山东
      //
      jsSelectRoleValueBoolean: false,
      //
      // 内蒙
      nmCookies: "",
      nmPhone: "",
      nmMobileCode: "",
      nmIdentityValue: "",
      nminfoData: "",
      nmotherData: "",
      // 新疆
      xjmobileCode: "",
      xjbdgxId: "",
      xjbsrType: "",
      xjcookies: "",
      xjtaxSiteUsername: "",
      xjvalue: "",
      buttonIndex: 1,
      // 重庆
      cqcookies: "",
      cqmobileCode: "",
      cqmobile: "",
      cqloginStatusOne: "",
      // 北京
      bjadditionalPassword: "",
      bjcookies: "",
      bjnameInfoList: "",
      bjvalue: "",
      // 20220810新增
      bjNewPassword: "",
      bjnewIdentCode: "",
      bjNewIndenty: "",
      //
      bjNewName: "",
      newBjToken: "",
      bjNewValue: "",
      // 辽宁
      lnmobile: "",
      lnVerifiTypeValue: "",
      lnmobileCode: "",
      lndtlSessionId: "",
      lndzswjTgc: "",
      // 广西
      gcmobileCode: "",
      gcVerifiTypeValue: "",
      gccookies: "",
      gcdjxh: "",
      gcczydm: "",
      gcsfmc: "",
      gcsfzjhm: "",
      // 河南
      hndqmobileCode: "",
      henanInfoListSelectValue: "",
      hndqCookies: "",
      hndqmobileCodeData: "",
      //
      henanInfoListSelectNext: false,
      loginStatusHN: "",
      nsrmcHN: "",
      useridHN: "",
      //重庆
      cqVerifiTypeNext: "",
      cqLoginType: "",
      cqmobileOne: "",
      cqVerifiTypeNextCode: false,
      // 湖南
      hunanCookies: "",
      hunanLoginStatus: "",
      hunanmobileCode: "",
      // 浙江新参数
      zjNewValue: "",
      newZjToken: "",
      newZjBsry: "",
      // 上海参数
      shpasswordInput: "",
      shBsrs: "",
      shRadio: "",
      shCookies: "",
      shmobileCode: "",
      // 陕西参数
      shanxiCookies: "",
      shanxiLoginData: "",
      shanxidqmobileCode: "",
      shanxiInfoListSelectValue: "",
      // 河北参数
      hebeiNameValue: "",
      hebeidqmobileCode: "",
      hebeiCookies: "",
      hebeiGetInfoBoolean: false,
      // 江西参数
      jiangxicookies: "",
      jiangxiData: "",
      jiangxinsrbh: "",
      jiangxinsrmc: "",
      jiangxidqmobileCode: "",
      jiangxiInfoListSelectValue: "",
      // 山西参数
      showShanXiPhoneData: "",
      showShanXiPhoneCode: "",
      sShanxiCookies: "",
      sShanxiloginStatus: "",
      // 福建
      fujianCookies: "",
      fujianPhoneCode: "",
      // 四川
      sichuanInfoData: "",
      sichuanOtherData: "",
      sichuanCookies: "",
      sichuanPhoneCode: "",
      sichuanMobile: "",
      // 天津
      tianJinMobile: "",
    };
  },
  components: {
    reportMethod,
    licen,
  },
  props: {
    zq: [Number, String],
    productName: String,
    productID: [Number, String],
    zqLocation: [Number, String],
    bankId: String,
    // uuid: String,
    dataPeriod: [Number, String],
    version: [Number, String],
    nsrsbhIndex: [Number, String],
    qykjzdIndex: [Number, String],
    selectvalueIndex: [Number, String],
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID", "SDSSelection"]),
  },
  mounted() {
    this.getDataType();
  },
  methods: {
    getDataType() {
      getAreaMsg(this.getBillData.taxNo).then((res) => {
        this.thirdDataSource = res.data.thirdDataSource;

        // this.thirdDataSource = "DB_KUANGU";
      });
    },
    openlicense() {
      this.$refs.opendialog.openDialog();
    },
    ...mapMutations("UploadTable", ["setCorrectDialogVisible"]),
    changeName(val) {
      this.taxSiteUsername = val.name;
      this.taxSitePwd = val.password;
      this.activeRead = val.active;
      this.index = val.index;
      this.step2Msg = val.step2Msg;
      this.valueMsg = val.value;
      this.area = val.province;
      this.bizNo = val.bizNo;
      this.radioIndex = val.radio;
      this.passwordInput = val.passwordInput;
      this.clickQr = val.clickQr;
      this.indexJylsh = val.indexJylsh;
      this.index1Data = val.index1Data;
      this.mobile = val.mobile;
      this.mobileCode = val.mobileCode;
      this.batchNo = val.batchNo;
      this.jsmobileCode = val.jsmobileCode;
      this.jscookies = val.jscookies;
      this.jsinfo = val.jsinfo;
      this.identityValue = val.identityValue;
      this.nameListArr = val.nameListArr;
      this.nameInfoList = val.nameInfoList;
      // 山东
      this.czydm = val.czydm;
      // 山东
      //
      this.jsSelectRoleValueBoolean = val.jsSelectRoleValueBoolean;
      //
      // 内蒙参数
      this.nmCookies = val.nmCookies;
      this.nmPhone = val.nmPhone;
      this.nmMobileCode = val.nmMobileCode;
      this.nmIdentityValue = val.nmIdentityValue;
      this.nminfoData = val.nminfoData;
      this.nmotherData = val.nmotherData;
      // 新疆
      this.xjmobileCode = val.xjmobileCode;
      this.xjbdgxId = val.xjbdgxId;
      this.xjbsrType = val.xjbsrType;
      this.xjcookies = val.xjcookies;
      this.xjtaxSiteUsername = val.xjtaxSiteUsername;
      this.xjvalue = val.xjvalue;
      // 重庆
      this.cqcookies = val.cqcookies;
      this.cqmobileCode = val.cqmobileCode;
      this.cqmobile = val.cqmobile;
      this.cqloginStatusOne = val.cqloginStatusOne;
      // 北京
      this.bjcookies = val.bjcookies;
      this.bjadditionalPassword = val.bjadditionalPassword;
      this.bjnameInfoList = val.bjnameInfoList;
      this.bjvalue = val.bjvalue;
      // 20220810新增
      this.bjNewPassword = val.bjNewPassword;
      this.bjnewIdentCode = val.bjnewIdentCode;
      //
      this.bjNewName = val.bjNewName;
      this.newBjToken = val.newBjToken;
      this.bjNewValue = val.bjNewValue;
      this.bjNewIndenty = val.bjNewIndenty;
      // 辽宁
      this.lnmobile = val.lnmobile;
      this.lnVerifiTypeValue = val.lnVerifiTypeValue;
      this.lnmobileCode = val.lnmobileCode;
      this.lndtlSessionId = val.lndtlSessionId;
      this.lndzswjTgc = val.lndzswjTgc;
      // 广西
      this.gcmobileCode = val.gcmobileCode;
      this.gcVerifiTypeValue = val.gcVerifiTypeValue;
      this.gccookies = val.gccookies;
      this.gcdjxh = val.gcdjxh;
      this.gcczydm = val.gcczydm;
      this.gcsfmc = val.gcsfmc;
      this.gcsfzjhm = val.gcsfzjhm;
      // 河南
      this.hndqmobileCode = val.hndqmobileCode;
      this.henanInfoListSelectValue = val.henanInfoListSelectValue;
      this.hndqCookies = val.hndqCookies;
      this.hndqmobileCodeData = val.hndqmobileCodeData;
      //
      this.henanInfoListSelectNext = val.henanInfoListSelectNext;
      this.loginStatusHN = val.loginStatusHN;
      this.nsrmcHN = val.nsrmcHN;
      this.useridHN = val.useridHN;
      //重庆
      this.cqVerifiTypeNext = val.cqVerifiTypeNext;
      this.cqLoginType = val.cqLoginType;
      this.cqmobileOne = val.cqmobileOne;
      this.cqVerifiTypeNextCode = val.cqVerifiTypeNextCode;
      // 湖南
      this.hunanCookies = val.hunanCookies;
      this.hunanLoginStatus = val.hunanLoginStatus;
      this.hunanmobileCode = val.hunandqmobileCode;
      //
      this.zjNewValue = val.zjNewValue;
      this.newZjToken = val.newZjToken;
      this.newZjBsry = val.newZjBsry;
      // 上海
      this.shpasswordInput = val.shpasswordInput;
      this.shBsrs = val.shBsrs;
      this.shRadio = val.shRadio;
      this.shCookies = val.shCookies;
      this.shmobileCode = val.shmobileCode;
      // 陕西
      this.shanxiCookies = val.shanxiCookies;
      this.shanxiLoginData = val.shanxiLoginData;
      this.shanxidqmobileCode = val.shanxidqmobileCode;
      this.shanxiInfoListSelectValue = val.shanxiInfoListSelectValue;
      // 河北
      this.hebeiNameValue = val.hebeiNameValue;
      this.hebeidqmobileCode = val.hebeidqmobileCode;
      this.hebeiCookies = val.hebeiCookies;
      this.hebeiGetInfoBoolean = val.hebeiGetInfoBoolean;
      // 江西
      this.jiangxicookies = val.jiangxicookies;
      this.jiangxiData = val.jiangxiData;
      this.jiangxinsrbh = val.jiangxinsrbh;
      this.jiangxinsrmc = val.jiangxinsrmc;
      this.jiangxidqmobileCode = val.jiangxidqmobileCode;
      this.jiangxiInfoListSelectValue = val.jiangxiInfoListSelectValue;
      // 山西
      this.showShanXiPhoneData = val.showShanXiPhoneData;
      this.showShanXiPhoneCode = val.showShanXiPhoneCode;
      this.sShanxiCookies = val.sShanxiCookies;
      this.sShanxiloginStatus = val.sShanxiloginStatus;
      // 福建
      this.fujianCookies = val.fujianCookies;
      this.fujianPhoneCode = val.fujianPhoneCode;
      // 四川
      this.sichuanInfoData = val.sichuanInfoData;
      this.sichuanOtherData = val.sichuanOtherData;
      this.sichuanCookies = val.sichuanCookies;
      this.sichuanPhoneCode = val.sichuanPhoneCode;
      this.sichuanMobile = val.sichuanMobile;
      // 天津
      this.tianJinMobile = val.tianJinMobile;
    },
    useauthorize(callback) {
      var uPattern = /^[a-zA-Z0-9]{15,20}$/;
      if (!uPattern.test(this.taxSiteUsername)) {
        this.$message("请输入15-20位账号！");
        return;
      }
      if (!this.taxSitePwd) {
        this.$message("密码不能为空！");
        return;
      }
      if (this.clickQr) {
        this.$alert("请点击验证按钮！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      // 浙江地区校验
      if (!this.valueMsg && this.index == 0) {
        this.$alert("请选择报税人员！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // // 宁波地区校验
      // if (!this.mobile && this.index == 1) {
      //   this.$alert("手机号不能为空！", "温馨提示", {
      //     confirmButtonText: "确定",
      //   });
      //   return;
      // }
      if (!this.mobileCode && this.index == 1) {
        this.$alert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      //
      if (!this.identityValue && this.index == 2) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (!this.jsSelectRoleValueBoolean && this.index == 2) {
        this.$alert("请选择人员！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (!this.jsmobileCode && this.index == 2) {
        this.$alert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // 如果是选择密码方式，密码不能为空
      if (!this.passwordInput && this.index == 0 && this.radioIndex == 2) {
        this.$alert("密码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 3 && !this.czydm) {
        this.$alert("请输入委托人手机号/身份证号码/操作员代码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 4 && !this.nmPhone) {
        this.$alert("手机号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 4 && !this.nmMobileCode) {
        this.$alert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 5 && !this.xjvalue) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 5 && !this.xjmobileCode) {
        this.$alert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 7 && !this.cqmobile) {
        this.$alert("用户名不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 7 && this.cqVerifiTypeNext) {
        this.$alert("请输入用户名之后，点击下一步", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (
        this.index == 7 &&
        this.cqLoginType == "smsAuthentication" &&
        !this.cqmobileOne
      ) {
        this.$alert("手机号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (
        this.index == 7 &&
        this.cqLoginType == "smsAuthentication" &&
        !this.cqmobileCode
      ) {
        this.$alert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // cqVerifiTypeNextCode
      if (this.index == 7 && this.cqVerifiTypeNextCode) {
        this.$alert("请输入手机号和验证码之后，点击下一步", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 7 && this.cqloginStatusOne == "sfChoose" && !this.cqmobileCode) {
        this.$alert("验证码不能为空", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // cqloginStatusOne
      if (this.index == 8 && !this.bjNewName && this.thirdDataSource != "DB_WF") {
        this.$alert("请输入姓名！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 8 && !this.bjvalue) {
        this.$alert("请选择办税人员！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 8 && !this.bjNewPassword) {
        this.$alert("手机号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 8 && !this.bjnewIdentCode) {
        this.$alert("身份证号不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 8 && !this.bjadditionalPassword) {
        this.$alert("密码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 9 && !this.lnVerifiTypeValue) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 9 && !this.lnmobile) {
        this.$alert("请输入手机号！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 9 && !this.lnmobileCode) {
        this.$alert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 10 && !this.gcVerifiTypeValue) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 10 && !this.gcmobileCode) {
        this.$alert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 11 && !this.henanInfoListSelectValue) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 11 && !this.hndqmobileCode) {
        this.$alert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 11 && this.henanInfoListSelectNext) {
        this.$alert("先点击下一步！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (
        this.index == 12 &&
        this.hunanLoginStatus == "sfChoose" &&
        !this.hunanmobileCode
      ) {
        this.$alert("验证码不能为空", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 13 && !this.shBsrs) {
        this.$alert("请选择办税人员！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 13 && this.shRadio == 1 && !this.shpasswordInput) {
        this.$alert("请输入密码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 13 && this.shRadio == 2 && !this.shmobileCode) {
        this.$alert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // shanxiInfoListSelectValue
      if (
        this.index == 14 &&
        !this.shanxiInfoListSelectValue &&
        this.thirdDataSource == "DB_WF"
      ) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      // 河北
      if (this.index == 15 && !this.hebeiGetInfoBoolean) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 15 && !this.hebeidqmobileCode) {
        this.$alert("验证码不能为空！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 16 && !this.jiangxiInfoListSelectValue) {
        this.$alert("请选择身份！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (this.index == 16 && !this.jiangxidqmobileCode) {
        this.$alert("请输入验证码！", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      if (!this.activeRead) {
        this.$alert("请阅读并勾选授权协议", "温馨提示", {
          confirmButtonText: "确定",
        });
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: "正在提交数据,请耐心等待！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      // 通用授权
      if (this.index == -1) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          authorizeResult: true,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          taxpayerId: this.taxSiteUsername,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          projectId: this.getBillData.id,
          batchNo: this.batchNo || "",
        };
        useAuthorizeAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 浙江地区授权
      if (this.index == 0) {
        if (this.thirdDataSource == "DB_WF") {
          let params = {
            // id: JSON.parse(localStorage.getItem("user")).companyId,
            id: this.getBillData.consumerEntity.id,
            nsrsbm: this.getBillData.consumerEntity.nsrsbm,
            uuid: this.uuid,
            tag: this.version,
            token: localStorage.getItem("authorization"),
            zq: this.zq,
            taxpayerId: this.taxSiteUsername,
            taxSiteUsername: this.taxSiteUsername,
            taxSitePwd: this.taxSitePwd,
            acwTc: this.step2Msg.acw_tc,
            aliyungfTc: this.step2Msg.aliyungf_tc,
            dtlSessionId: this.step2Msg.dtl_SESSION_ID,
            loginType: this.radioIndex == 1 ? "SMRZ" : "PWD",
            additionalUsername: this.valueMsg,
            additionalPassword: this.radioIndex == 1 ? this.bizNo : this.passwordInput,
            area: this.area,
            projectManager: JSON.parse(localStorage.getItem("user")).realName,
            projectManagerId: JSON.parse(localStorage.getItem("user")).id,
            projectId: this.getBillData.id,
            batchNo: this.batchNo,
            cookies: this.step2Msg,
            djxh: this.nameInfoList.djxh,
            nsrmc: this.nameInfoList.nsrmc,
            sfzjhm: this.nameInfoList.sfzjhm,
            sjhm: this.nameInfoList.sjhm,
            swjgDm: this.nameInfoList.swjgDm,
            type: this.nameInfoList.type,
            zjUuid: this.nameInfoList.uuid,
            xm: this.nameInfoList.xm,
          };
          breezeOuterAi(params).then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              callback();
            } else {
              this.$refs.showReport.getprovinceBath();
              this.$alert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        } else {
          let params = {
            czydm: this.zjNewValue,
            password: this.passwordInput,
            programId: this.uuid,
            token: this.newZjToken,
            zq: this.zq || "2022004",
            bsry: this.newZjBsry,
          };
          zjBreezeLoginCreateTask(params).then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              callback();
            } else {
              this.$refs.showReport.getprovinceBath();
              this.$alert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        }
      }

      // 宁波授权
      if (this.index == 1) {
        let params = {
          // id: JSON.parse(localStorage.getItem("user")).companyId,
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          // 
          taxpayerId: this.taxSiteUsername.trim(),
          taxSiteUsername: this.mobile.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          batchNo: this.batchNo,
          area: this.area,
          otherData: this.indexJylsh,
          cookies: this.index1Data,
          smsCode: this.mobileCode.trim(),
          // 
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          projectId: this.getBillData.id,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 江苏授权
      if (this.index == 2) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          taxpayerId: this.taxSiteUsername.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          smsCode: this.jsmobileCode.trim(),
          batchNo: this.batchNo,
          area: this.area,
          jsLoginType: "1",
          rybz: this.jsinfo.rybz,
          yxbz: this.jsinfo.yxbz,
          bz: this.jsinfo.bz,
          xm: this.jsinfo.xm,
          tel: this.jsinfo.tel,
          sjhm: this.jsinfo.sjhm,
          sfzh: this.jsinfo.sfzh,
          zjlx: this.jsinfo.zjlx,
          type: this.jsinfo.type,
          cookies: this.jscookies,
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          projectId: this.getBillData.id,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 山东授权
      if (this.index == 3) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          taxpayerId: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          batchNo: this.batchNo,
          area: this.area,
          czydm: this.czydm,
          taxSiteUsername: this.czydm,
          thirdDataSource: this.thirdDataSource,

          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          projectId: this.getBillData.id,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 内蒙授权
      if (this.index == 4) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          taxpayerId: this.taxSiteUsername.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          area: this.area,
          batchNo: this.batchNo,
          cookies: this.nmCookies,
          taxSiteUsername: this.nmPhone,
          infoData: this.nminfoData,
          otherData: this.nmotherData,
          smsCode: this.nmMobileCode,
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          projectId: this.getBillData.id,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 新疆授权
      if (this.index == 5) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          taxpayerId: this.taxSiteUsername.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.xjmobileCode,
          bdgxId: this.xjbdgxId,
          bsrType: this.xjbsrType,
          cookies: this.xjcookies,
          taxSiteUsername: this.xjtaxSiteUsername,
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          projectId: this.getBillData.id,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 重庆授权
      if (this.index == 7) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          taxpayerId: this.taxSiteUsername.trim(),
          taxSiteUsername: this.cqmobile.trim(),
          taxSitePwd: this.taxSitePwd.trim(),
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.cqmobileCode,
          cookies: this.cqcookies,
          loginStatus: this.cqloginStatusOne,
          projectManager: JSON.parse(localStorage.getItem("user")).realName,
          projectManagerId: JSON.parse(localStorage.getItem("user")).id,
          projectId: this.getBillData.id,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 北京授权
      if (this.index == 8) {
        if (this.thirdDataSource == "DB_WF") {
          let params = {
            id: this.getBillData.consumerEntity.id,
            nsrsbm: this.getBillData.consumerEntity.nsrsbm,
            uuid: this.uuid,
            tag: this.version,
            token: localStorage.getItem("authorization"),
            zq: this.zq,

            taxpayerId: this.taxSiteUsername,
            taxSiteUsername: this.taxSiteUsername,
            taxSitePwd: this.taxSitePwd,
            loginType: 1,
            additionalUsername: this.taxSiteUsername,
            additionalPassword: this.bjadditionalPassword,
            area: this.area,
            batchNo: this.batchNo,
            cookies: this.bjcookies,
            //
            xm: this.bjnameInfoList.xm,
            sjhm: this.bjnameInfoList.sjhm,
            sfzjhm: this.bjnameInfoList.zjhm,
            sfmc: this.bjnameInfoList.sfmc,
            key: this.bjnameInfoList.key,
          };
          breezeOuterAi(params).then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              callback();
            } else {
              this.$refs.showReport.getprovinceBath();
              this.$alert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        } else {
          let params = {
            bsryxm: this.bjNewName,
            czydm: this.bjNewValue,
            dlsf: this.bjNewIndenty,
            sfzjhm: this.bjnewIdentCode,
            sjhm: this.bjNewPassword,
            password: this.bjadditionalPassword,
            programId: this.uuid,
            token: this.newBjToken,
            zq: this.zq || "2022004",
          };
          zjBreezeLoginCreateTaskBj(params).then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              callback();
            } else {
              this.$refs.showReport.getprovinceBath();
              this.$alert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        }
      }

      // 辽宁授权
      if (this.index == 9) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          loginType: this.lnVerifiTypeValue,
          phone: this.lnmobile,
          smsCode: this.lnmobileCode,
          dtlSessionId: this.lndtlSessionId,
          dzswjTgc: this.lndzswjTgc,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 广西授权
      if (this.index == 10) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          smsCode: this.gcmobileCode,
          cookies: this.gccookies,
          djxh: this.gcdjxh,
          czydm: this.gcczydm,
          sfmc: this.gcsfmc,
          sfzjhm: this.gcsfzjhm,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 河南授权
      if (this.index == 11) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          additionalUsername: this.taxSiteUsername,
          additionalPassword: this.taxSitePwd,
          smsCode: this.hndqmobileCode,
          djxh: this.henanInfoListSelectValue,
          wsbsfwtsessionId: this.hndqCookies,
          data: this.hndqmobileCodeData,
          // 20221025新增
          loginStatus: this.loginStatusHN,
          nsrmc: this.nsrmcHN,
          bdgxId: this.useridHN,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 湖南授权
      if (this.index == 12) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,

          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          smsCode: this.hunanmobileCode,
          cookies: this.hunanCookies,
          loginStatus: this.hunanLoginStatus,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.kjzd);
            sessionStorage.setItem("companyName", res.data.companyName);
            callback(res);
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 上海授权
      if (this.index == 13) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          //
          // 验证码
          smsCode: this.shmobileCode,
          ssoLoginTgc: this.shCookies.sso_LOGIN_TGC,
          jsessionid: this.shCookies.jsessionid,
          tgc: this.shCookies.tgc,
          legalMobile: "",
          //
          bsrType: this.shBsrs.bsrType,
          bdgxId: this.shBsrs.bdgxId,
          loginType: this.shRadio,
          batchNo: this.batchNo,
          area: this.area,
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.shRadio == 1 ? this.shpasswordInput : this.taxSitePwd, // 二级密码
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 陕西授权
      if (this.index == 14) {
        if (this.thirdDataSource == "DB_WF") {
          let params = {
            id: this.getBillData.consumerEntity.id,
            nsrsbm: this.getBillData.consumerEntity.nsrsbm,
            uuid: this.uuid,
            tag: this.version,
            token: localStorage.getItem("authorization"),
            zq: this.zq,
            //
            taxpayerId: this.taxSiteUsername,
            taxSiteUsername: this.taxSiteUsername,
            taxSitePwd: this.taxSitePwd,
            area: this.area,
            cookies: this.shanxiCookies,
            data: this.shanxiLoginData ? JSON.stringify(this.shanxiLoginData) : "",
            smsCode: this.shanxidqmobileCode,
            batchNo: this.batchNo,
          };
          breezeOuterAi(params).then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              callback();
            } else {
              this.$refs.showReport.getprovinceBath();
              this.$alert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        } else {
          let params = {
            yzm: this.shanxidqmobileCode,
            programId: this.uuid,
            zq: this.zq,
          };
          newShanxiBreeze(params).then((res) => {
            loading.close();
            if (res.msgCode == 1) {
              callback();
            } else {
              this.$refs.showReport.getprovinceBath();
              this.$alert(res.msgInfo, "温馨提示", {
                confirmButtonText: "确定",
              });
              return false;
            }
          });
        }
      }

      // 河北授权
      if (this.index == 15) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          taxSiteUsername: this.hebeiNameValue,
          taxSitePwd: this.taxSitePwd,
          area: this.area,
          batchNo: this.batchNo,
          smsCode: this.hebeidqmobileCode,
          cookies: this.hebeiCookies,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 江西授权
      if (this.index == 16) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          cookies: this.jiangxicookies,
          data: JSON.stringify(this.jiangxiData),
          swjgDm: this.jiangxinsrbh,
          nsrmc: this.jiangxinsrmc,
          smsCode: this.jiangxidqmobileCode,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 山西
      if (this.index == 17) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          cookies: this.sShanxiCookies,
          phone: this.showShanXiPhoneData,
          smsCode: this.showShanXiPhoneCode,
          loginStatus: this.sShanxiloginStatus,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 福建
      if (this.index == 18) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          legalMobile: "", //手机号
          smsCode: this.fujianPhoneCode,
          dzswjTgc: this.fujianCookies.dzswj_TGC,
          tgc: this.fujianCookies.tgc,
          jsessionid: this.fujianCookies.jsessionid,
          route: this.fujianCookies.route,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 四川
      if (this.index == 19) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          smsCode: this.sichuanPhoneCode,
          infoData: this.sichuanInfoData,
          otherData: this.sichuanOtherData,
          cookies: this.sichuanCookies,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }

      // 天津
      if (this.index == 20) {
        let params = {
          id: this.getBillData.consumerEntity.id,
          nsrsbm: this.getBillData.consumerEntity.nsrsbm,
          uuid: this.uuid,
          tag: this.version,
          token: localStorage.getItem("authorization"),
          zq: this.zq,
          //
          taxpayerId: this.taxSiteUsername,
          area: this.area,
          batchNo: this.batchNo,
          taxSiteUsername: this.taxSiteUsername,
          taxSitePwd: this.taxSitePwd,
          //
          legalMobile: this.tianJinMobile,
        };
        breezeOuterAi(params).then((res) => {
          loading.close();
          if (res.msgCode == 1) {
            callback();
          } else {
            this.$refs.showReport.getprovinceBath();
            this.$alert(res.msgInfo, "温馨提示", {
              confirmButtonText: "确定",
            });
            return false;
          }
        });
      }
    },

    handleBtnNext() {
      this.useauthorize(() => {
        let consumerId = this.getBillData.consumerId;
        let programId = this.uuid;
        ptInvoiceBasicInfoCheck(consumerId, programId).then((res) => {
          if (res.code == 200) {
            this.$router.push({
              name: "billSuccess",
            });
          } else {
            this.$alert(res.message, "错误", {
              confirmButtonText: "确定",
              type: "error",
              callback: (action) => {
                this.$router.push({
                  name: "billList",
                });
              },
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.bill-confirm-c {
  position: relative;
  margin: 0 30px;
}
.bill-confirm {
  position: absolute;
  right: 30px;
  bottom: -55px;
  width: 96px;
  height: 36px;
  line-height: 36px;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
}
</style>
