export const statusList = [
    {
        status: 0,
        name: "待检测",
    },
    {
        status: 1,
        name: "已结束",
    },
    {
        status: 2,
        name: "进行中",
    },
    {
        status: 3,
        name: "已废除",
    },
];

export const kjzdList = {
    qykjzd: "企业会计制度",
    qykjzz: "企业会计准则",
    xqykjzz: "小企业会计准则",
};

export function formatDateTes(timestamp) {
    var date = new Date(timestamp);
    var Y = date.getFullYear() + "-";
    var M =
        (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
    var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
    var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return Y + M + D + " " + h + m + s;
};

export function formatDateTe(timestamp, index) {
    var date = new Date(timestamp);
    var Y = date.getFullYear() + "-";
    var M =
        (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
    var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
    var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    if (index == 0) {
        var dataForm = Y + M + D + " " + h + m + s;
    } else {
        var dataForm = Y + M + D;
    }
    return dataForm;
};