<template>
  <div>
    <!-- 创建客户 -->
    <transition name="fades">
      <div class="containerMask" v-if="dialogTableVisible"></div>
    </transition>
    <transition name="fadee">
      <div
        class="container"
        v-if="dialogTableVisible"
        ref="projectBox"
        id="projectBoxs"
        v-loading="addLoading"
      >
        <div class="addTitle">以下信息未完善，请补全后继续</div>
        <div class="project-close" @click="closeDialog">×</div>
        <div class="project-name"><span class="project-named">*</span>客户名称</div>
        <div class="project-input">
          <el-input
            placeholder="请输入"
            v-model="person.companyName"
            maxlength="20"
            show-word-limit
          ></el-input>
          <span class="project-input-b" v-show="form[0]">客户名称不能为空</span>
        </div>
        <div class="project-name"><span class="project-named">*</span>纳税人识别号</div>
        <div class="project-input">
          <el-input
            placeholder="请输入"
            v-model.trim="person.nsrsbh"
            maxlength="20"
            show-word-limit
          ></el-input>
          <span class="project-input-b" v-show="form[1]">纳税人识别号不能为空</span>
          <span class="project-input-b" v-show="form11"
            >最低15位最多20位数字+字母组合</span
          >
        </div>
        <div class="project-name"><span class="project-named">*</span>企业规模</div>
        <div class="project-input">
          <el-select v-model="person.scale" placeholder="请选择">
            <el-option
              v-for="item in qyStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <span class="project-input-b" v-show="form[2]">企业规模不能为空</span>
        </div>
        <div class="project-name"><span class="project-named">*</span>客户地区</div>
        <div class="project-input">
          <el-cascader :options="city" v-model="persons.area"></el-cascader>
          <span class="project-input-b" v-show="form[3]">客户地区不能为空</span>
        </div>
        <div class="project-name"><span class="project-named">*</span>会计制度</div>
        <div class="project-input">
          <el-select v-model="person.kjzd" placeholder="请选择">
            <el-option
              v-for="item in kjzd"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <span class="project-input-b" v-show="form[4]">会计制度不能为空</span>
        </div>

        <div class="project-name"><span class="project-named">*</span>客户行业</div>
        <div class="project-input">
          <el-select v-model="person.industry" placeholder="请选择" filterable>
            <el-option
              v-for="item in hyList"
              :key="item.hy_dm"
              :label="item.hymc"
              :value="item.hy_dm"
            ></el-option>
          </el-select>
          <span class="project-input-b" v-show="form[5]">行业不能为空</span>
        </div>
        <div class="project-btn ease" @click="addProjectForm">
          <div>创建客户</div>
        </div>
      </div>
    </transition>
    <!-- <pagination class="paginationNew" :totalNumber="totalNumber" :selectArr="selectArr"></pagination> -->
  </div>
</template>

<script>
import {
  getCustomListNew,
  getCustomListDetail,
  addCustom,
  editCustom,
  getTradeList,
  addCustomBoth,
} from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
import { regionData } from "@/dependencies/element-china-area-data.js";
import {
  veriFiItem,
  veriFiCustom,
  area,
  veriFiItemAdds,
} from "@/pages/product/utils/limit.js";
import pagination from "@/pages/product/components/pagination.vue";

export default {
  components: {
    MainHeader,
    BreadCrumb,
    pagination,
  },
  props: {
    uuid: [String, Number],
  },

  data() {
    return {
      form11: false,
      qyStatusList: [
        { value: "小微企业", label: "小微企业" },
        { value: "中型企业", label: "中型企业" },
        { value: "大型企业", label: "大型企业" },
      ],
      hyList: null,
      dataList: null,
      totalNumber: 10, //总数
      selectArr: ["10", "20", "50", "100"], //传入可切换的每页条数
      currentIndex: "",
      current: {},
      currentEdit: {},
      input: "",
      isEdit: false,
      addLoading: false,
      city: regionData,
      form: [0, 0, 0, 0, 0, 0, 0],
      addForm: [0, 0, 0, 0, 0, 0, 0],
      kjzd: [
        { value: "qykjzd", label: "企业会计制度" },
        { value: "qykjzz", label: "企业会计准则" },
        { value: "xqykjzz", label: "小企业会计准则" },
      ],
      kjzdList: {
        qykjzd: "企业会计制度",
        qykjzz: "企业会计准则",
        xqykjzz: "小企业会计准则",
      },
      person: {},
      persons: {},
      dialogTableVisible: false,
      totalElements: 0,
      taxAccessStatus: "",
      checkParts: false,
      firstTime: "2018-11-13",
      lastTime: "2099-12-30",
      pageno: 0,
      pagesize: 10,
      tag: "",
      goodsId: "",
      options1: [
        {
          value: "黄金糕",
          label: "黄金糕",
        },
        {
          value: "双皮奶",
          label: "双皮奶",
        },
        {
          value: "蚵仔煎",
          label: "蚵仔煎",
        },
        {
          value: "龙须面",
          label: "龙须面",
        },
        {
          value: "北京烤鸭",
          label: "北京烤鸭",
        },
      ],
      value1: "",
      filter: {
        name: "",
        status: "",
        time: [],
        source: "",
      },
      tableData: [],
      sources: [
        {
          value: "1",
          label: "已生成",
        },
        {
          value: "2",
          label: "待生成",
        },
        {
          value: "3",
          label: "已删除",
        },
        {
          value: "4",
          label: "待完善",
        },
        {
          value: "5",
          label: "生成出错",
        },
      ],
      showDetail: false,
      showEdit: true,
      loading: true,
      tableDataList: null,
      customItem: {},
      conversionArea: "",
    };
  },
  methods: {
    gettradeList() {
      getTradeList().then((res) => {
        let data = JSON.parse(res.data);
        this.hyList = data;
        let dataList = {};
        for (let i = 0; i < this.hyList.length; i++) {
          this.hyList[i].hymc = this.hyList[i].hy_dm + this.hyList[i].hymc;
          dataList[this.hyList[i].hy_dm] = this.hyList[i].hymc;
        }
        this.dataList = dataList;
      });
    },
    changeTime() {},
    search() {},
    reset() {},
    openDetail() {
      this.dialogTableVisible = true;
    },

    removeEdit() {
      this.isEdit = false;
      this.currentEdit = {};
    },

    detailSave() {
      this.isEdit = false;
    },

    addProjectForm() {
      this.form = [0, 0, 0, 0, 0, 0];
      let verification = veriFiItemAdds(this.person, this.persons);
      this.form[verification.code] = 1;
      this.form.reverse().reverse();
      if (!verification.status) return;

      if (
        this.person.nsrsbh.trim().length > 20 ||
        this.person.nsrsbh.trim().length < 15
      ) {
        this.form11 = true;
        return;
      }

      var uPattern = /^[a-zA-Z0-9_]{0,}$/;
      if (!uPattern.test(this.person.nsrsbh)) {
        this.form11 = true;
        return;
      }

      this.addLoading = true;
      this.person.provinceCode = this.persons.area[0];
      this.person.cityCode = this.persons.area[1];
      this.person.districtCode = this.persons.area[2];
      // delete this.persons.area;
      this.person.uuid = this.uuid;
      (this.person.projectManager = JSON.parse(localStorage.getItem("user")).realName),
        (this.person.projectManagerId = JSON.parse(localStorage.getItem("user")).id),
        addCustomBoth(this.person, localStorage.getItem("authorization")).then((res) => {
          if (res.msgCode == 1) {
            sessionStorage.setItem("nsrsbh", res.data.consumerEntity.nsrsbm);
            sessionStorage.setItem("qykjzd", res.data.consumerEntity.kjzd);
            // 存税号
            sessionStorage.setItem("manualNsrsbh", this.person.nsrsbh);
            this.dialogTableVisible = false;
            this.addLoading = false;
            this.$notify({
              title: "温馨提示",
              message: "补全信息成功！",
              type: "success",
            });
            this.$emit("supplement", {
              nsrsbh: res.data.consumerEntity.nsrsbm,
              kjzd: res.data.consumerEntity.kjzd,
            });
            this.person = {};
            // this.getcustomList();
          } else {
            this.addLoading = false;
            this.$message({
              message: res.msgInfo,
              type: "warning",
            });
          }
        });
    },

    closeDialog() {
      this.dialogTableVisible = false;
      this.$emit("closeDialogs");
    },

    addProject() {
      this.dialogTableVisible = true;
    },

    viewDetail(id) {
      this.showDetail = true;
      let params = {
        id: id,
        token: localStorage.getItem("authorization"),
      };
      getCustomListDetail(params).then((res) => {
        this.customItem = res.data;
        this.loading = false;
        this.conversionArea = area(
          [
            this.customItem.provinceCode,
            this.customItem.cityCode,
            this.customItem.districtCode,
          ],
          this.city
        );
      });
    },

    showHiddenDetail() {
      this.showDetail = false;
      this.isEdit = false;
    },

    formatDateTes(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) +
        "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + " " + h + m + s;
    },
  },
  created() {
    this.gettradeList();
  },
};
</script>

<style lang="stylus" scoped>
.containerMask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1002;
}

.addTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #FAAD14;
  line-height: 28px;
  padding: 24px 0 20px 0;
}

.container {
  position: absolute;
  left: 50%;
  margin-left: -240px;
  top: 5vh;
  width: 480px;
  height: 616px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
}

.pagination {
  padding-top: 20px;
  text-align: right;
  margin-top: 20px;
}

>>> #projectBoxs .el-input {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs >>>.el-input >>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>> #projectBoxs .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #projectBoxs .el-input__icon {
  line-height: 36px;
}

>>> #projectBoxs .el-cascader {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input, .addForms {
  position: relative;
}

.project-input-b {
  position: absolute;
  right: 40px;
  top: 40px;
  font-size: 12px;
  color: red;
}

.project-input .el-input {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.project-input .el-select {
  width: 416px;
  height: 36px;
  line-height: 36px;
  margin: 0 0 16px 32px;
}

.el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.project-name {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 0 0 4px 32px;
}

.project-named {
  position: relative;
  top: 2px;
  margin-right: 4px;
  color: #f5222d;
}

.project-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  line-height: 68px;
  background: rgba(47, 84, 235, 1);
  box-shadow: 0px -1px 0px 0px rgba(47, 84, 235, 1);
  z-index: 1004;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.project-close {
  position: absolute;
  cursor: pointer;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  top: 6px;
  right: 20px;
}

.fadee-enter-active {
  transition: all 0.3s ease;
}

.fades-enter-active {
  transition: all 0.3s ease;
}

.fadee-leave-active {
  transition: all 0.2s ease;
}

.fades-leave-active {
  transition: all 0.3s ease;
}

.fadee-enter, .fadee-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

.fades-enter, .fades-leave-to {
  opacity: 0;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  // min-height: 80vh;
  padding: 0 10px;
}

.btn-view {
  line-height: 28px;
  height: 28px;
  color: #2f54eb;
  margin-right: 10px;
  display: inline-block;
}

.btn-view:hover {
  color: rgb(8, 47, 202);
}

.filter-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .inputs-wrapper {
    width: 90%;

    .inputs-wraper-row {
      display: flex;
      // justify-content: flex-start;
      flex-wrap: wrap;

      .form-item {
        margin-right: 40px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;

        .label {
          white-space: nowrap;
        }

        // flex-basis: 30%;
        display: flex;
      }
    }
  }

  .btn-look-up {
    margin-bottom: 16px;
    width: 70px;
    margin-right: 5px;
  }
}

.btn-blue {
  color: rgba(47, 84, 235, 1);
  cursor: pointer;
}

.done, .pedding, .delete, .perfect {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 7px;
  margin-bottom: 2px;
}

.done {
  background: rgba(82, 196, 26, 1);
}

.pedding {
  background: rgba(217, 217, 217, 1);
}

.delete {
  background: rgba(245, 34, 45, 1);
}

.perfect {
  background: rgba(250, 173, 20, 1);
}

.paginations {
  float: right;
  margin-right: 20px;
  margin-bottom: 30px;
}

.fixed-item {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 496px;
  height: 100%;
  z-index: 1000;
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(251, 251, 251, 1);
  box-shadow: -4px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.fixed-content {
  width: 464px;
  height: 512px;
  background: rgba(240, 245, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(214, 228, 255, 1);
  margin: 16px 0 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.fixed-bottom {
  display: flex;
  width: 464px;
  height: 280px;
  background: rgba(240, 245, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(214, 228, 255, 1);
  margin: 8px 0 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.fixed-item-top {
  display: flex;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid rgba(218, 213, 232, 0.6);
}

.fixed-item-icon {
  width: 30px;
  height: 30px;
  padding: 20px 8px 16px 20px;
}

.content-title {
  margin: 16px 0 0 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
}

.content-text {
  margin: 4px 0 24px 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
}

.content-tran {
  color: transparent;
}

.content-text-bottom {
  margin-bottom: 25px;
}

.content-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  line-height: 60px;
  background: rgba(47, 84, 235, 1);
  font-size: 16px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  cursor: pointer;
}

.content-btn:hover {
  background: rgba(47, 34, 235, 1);
}

.addItem {
  width: 104px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  margin-left: 20px;
  cursor: pointer;
}

.addItem:hover {
  background: rgba(47, 34, 235, 1);
}

.project-btn:hover {
  background: rgba(47, 34, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

.cursion {
  cursor: pointer;
}

.editActive:before {
  content: '*';
  color: red;
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.content-isEdit {
  margin-bottom: 16px;
}

>>> #content-isEdit .el-input {
  width: 416px;
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input .el-input__count {
  color: rgba(0, 0, 0, 0.2);
}

>>> #content-isEdit .el-cascader {
  line-height: 36px;
  height: 36px;
}

>>> #content-isEdit .el-input__icon {
  line-height: 36px;
}

.fixedItemEdit {
  height: 562px;
}

.detailContent {
  display: flex;
  align-item: center;
}

.detailcancel {
  width: 50%;
}

.detailcancel:after {
  content: '|';
  position: relative;
  left: 108px;
  top: 1px;
}

.detailSaves {
  width: 50%;
}

>>> .form-item .el-input__icon {
  line-height: 36px;
  height: 36px;
}

.paginationNew {
  float: right;
  margin-right: 30px;
  margin-bottom: 24px;
}
</style>
