<template>
  <div>
    <div class="sideBar" @click="handleSideBarClick">
      展开修改记录
      <i class="el-icon-d-arrow-left icon"></i>
    </div>

    <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false">
      <CensorHistory :uuid="$route.params.uuid" :nsrsbh="$route.params.nsrsbh"></CensorHistory>
    </el-drawer>
  </div>
</template>

<script>
import CensorHistory from "../../../components/CensorHistory/CensorHistory";
export default {
  components: {
    CensorHistory
  },
  data() {
    return {
      drawer: false
    };
  },
  methods: {
    handleSideBarClick() {
      this.drawer = true;
    }
  }
};
</script>

<style lang="stylus" scoped>
.sideBar {
  width: 48px;
  height: 204px;
  background: rgba(250, 173, 20, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px 0px 0px 8px;
  position: fixed;
  right: 0;
  top: 50%;
  text-align: center;
  color: #fff;
  writing-mode: vertical-rl;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: translateY(-50%);
  letter-spacing: 5px;

  .icon {
    margin-top: 5px;
  }
}
</style>