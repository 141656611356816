<template>
  <div class="container">
    <div class="header">
      <div class="title">项目检测</div>
    </div>
    <div class="content">
      <div v-for="item in testList">
        <div v-if="item.show_status == 1" class="item">
          <router-link
            tag="a"
            target="_blank"
            :to="{
              name: 'result-detect-always',
              params: {
                uuid: item.uuid,
                nsrsbh: item.nsrsbh,
                action: 'edit',
              },
            }"
            class="cursion-item"
            ><span class="exe-report">风险检查报告</span>
            <span class="exe-time">{{ item.exe_time }}</span></router-link
          >
        </div>
        <div v-else class="item">
          <span class="exe-report">风险检查报告</span>
          <span class="exe-time">待生成</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bSuperiorProjectRuleListIndex } from "@/api/fri";

export default {
  data() {
    return {
      testList: null,
    };
  },
  mounted() {
    this.projectRuleList();
  },
  methods: {
    projectRuleList() {
      let params = {
        pageNo: 1,
        pageSize: 1000,
        projectSuperId:
          this.$route.query.id
      };
      bSuperiorProjectRuleListIndex(params).then((res) => {
        this.testList = res.data.content;
      });
    },
    toReport(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="stylus" scoped>
.container {
  background: rgba(255, 255, 255, 1);
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding-bottom: 20px;
  min-height: 90px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 54px;
    background: rgba(255, 255, 255, 1);
    border-bottom: 1px solid rgba(218, 213, 232, 0.6);

    .title {
      font-size: 18px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      margin-left: 24px;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    .item {
      position: relative;
      width: 120px;
      height: 120px;
      line-height: 120px;
      border: 1px solid rgba(218, 213, 232, 0.6);
      margin: 10px;
      border-radius: 8px;
      color: #C0C4CC;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;

      .exe-time {
        position: absolute;
        bottom: -75px;
        left: 5px;
      }
    }
  }
}
</style>
