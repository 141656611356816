<template>
  <div>
    <bread-crumb>
      <el-breadcrumb-item :to="{ name: 'goods' }">产品中心</el-breadcrumb-item>
      <el-breadcrumb-item>兑换记录</el-breadcrumb-item>
    </bread-crumb>
    <div class="submit-title">兑换记录</div>
    <div class="record-select">
      <div class="record-time">兑换时间：</div>
      <div class="block">
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="timeChange"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </div>
    </div>
    <div class="record-form">
      <el-table
        v-loading="loading"
        :data="tableData"
        element-loading-text="加载中..."
        style="width: 100%"
        :default-sort="{prop: 'date', order: 'descending'}"
      >
        <el-table-column width="24"></el-table-column>
        <el-table-column prop="changeTime" label="兑换时间" sortable :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="version" label="版本" :show-overflow-tooltip="true"></el-table-column>
        <!-- <el-table-column width="180" prop="vtimeCount" label="版本限制条件"></el-table-column> -->
        <!-- <el-table-column prop="riskName" label="风险库" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="180" prop="rtimeCount" label="风险库限制条件"></el-table-column>-->
        <el-table-column prop="expireTime" label="到期时间" sortable :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="count" label="版本兑换次数" width="120" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column width="20"></el-table-column>
      </el-table>
    </div>
    <div class="record-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/BreadCrumb";
import { agentRecord } from "../../api/api";
export default {
  components: {
    BreadCrumb
  },
  data() {
    return {
      value1: "",
      tableData: [],
      firstTime: "",
      lastTime: "",
      status: "8",
      loading: "true",
      total: 0,
      currentPage: 1,
      pageNo: 0,
      pageSize: 10
    };
  },
  created() {
    this.agentrecord();
  },
  methods: {
    handleSizeChange(val) {
      this.pageNo = val - 1;
      this.agentrecord();
    },
    handleCurrentChange(val) {
      this.pageNo = val - 1;
      this.agentrecord();
    },
    // 查看兑换记录
    agentrecord() {
      let params = {
        userId: JSON.parse(localStorage.getItem("token")).userId,
        startTime: this.firstTime,
        endTime: this.lastTime,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      agentRecord(params).then(res => {
        this.loading = false;
        if (res.msgCode == 1) {
          this.tableData = res.data.content;
          this.total = res.data.totalElements;
        } else {
          this.$message({
            type: "info",
            message: res.msgInfo
          });
        }
      });
    },
    timeChange(val) {
      if (!val) {
        this.firstTime = "";
        this.lastTime = "";
      } else {
        [this.firstTime, this.lastTime] = [val[0], val[1]];
      }
      this.agentrecord();
    }
  }
};
</script>
<style lang="stylus" scoped>
.submit-title {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.8);
  padding: 8px 0 0 30px;
  font-weight: 500;
}

.record-select {
  display: flex;
  align-items: center;
  // min-width: 1100px;
  height: 84px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 24px 30px 0 30px;
  color: rgba(0, 0, 0, 0.75);
}

>>>.el-select {
  width: 240px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-sizing: border-box;
}

>>>.el-input .el-input--suffix {
  width: 240px;
  height: 36px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  box-sizing: border-box;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-input__icon {
  line-height: 36px;
}

>>>.el-date-editor .el-range-separator {
  width: 8%;
}

>>>.el-input__icon {
  line-height: 28px;
}

>>>.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 260px;
}

.record-state {
  margin-left: 40px;
  margin-right: 8px;
}

.record-time {
  margin-left: 48px;
  margin-right: 8px;
}

.record-form {
  position: relative;
  bottom: 20px;
  // min-width: 1100px;
  margin: 36px 30px 0 30px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  overflow: hidden;
}

>>>.el-date-editor .el-range-separator {
  line-height: 28px;
}

>>>.el-date-editor .el-range__close-icon {
  line-height: 30px;
}

.icon {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: rgba(87, 197, 66, 1);
  border-radius: 50%;
  margin-right: 8px;
}

>>>.el-table__row {
  height: 52px !important;
}

>>>.el-table .cell {
  line-height: 1.1;
}

>>>.el-table tr td:last-child {
  margin-left: 50px;
}

.discount-btn {
  cursor: pointer;
  color: rgba(47, 84, 235, 1);
  font-size: 14px;
}

.discount-btn1 {
  margin-left: 12px;
}

.discount-btn2 {
  display: inline-block;
  background: rgba(47, 84, 235, 1);
  border-radius: 4px;
  color: #fff;
  padding: 6px 10px;
}

.record-hyName {
  padding-right: 30px;
}

.record-page {
  float: right;
  padding-bottom: 30px;
  padding-right: 30px;
}
</style>