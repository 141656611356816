function smoothNumber() {
  const history = {};

  const smooth = value => {
    let absUsed = false;
    let originValue = value;

    if (value < 0) {
      value = Math.abs(value);
      absUsed = true;
    }

    let i = Math.log10(value);

    if (i > 0) {
      i = i + 1;
    } else {
      i = 0;
    }

    const p = Math.pow(10, Math.abs(i));

    let outValue;
    if (absUsed) {
      outValue = -i - value / p;
      // if (outValue >= -0.1 && outValue < 0) {
      //   outValue = -0.1;
      // }
    } else {
      outValue = i + value / p;
      // if (outValue <= 0.1 && outValue > 0) {
      //   outValue = 0.1;
      // }
    }
    
    if (history[outValue] == null) {
      history[outValue] = originValue;
    }

    return outValue;
  };

  const unSmooth = value => {
    if (history[value] != null) {
      return history[value];
    } else {
      throw new Error("无法unsmooth未smooth的数字");
    }
  };

  return {
    smooth,
    unSmooth
  };
}

export default smoothNumber;
