import { render, staticRenderFns } from "./detailCustom.vue?vue&type=template&id=a706edf8&scoped=true&"
import script from "./detailCustom.vue?vue&type=script&lang=js&"
export * from "./detailCustom.vue?vue&type=script&lang=js&"
import style0 from "./detailCustom.vue?vue&type=style&index=0&id=a706edf8&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a706edf8",
  null
  
)

export default component.exports