<template>
  <div v-loading="loading">
    <div class="detail" v-if="dataInfo">
      <div class="detail-title">
        <img
          class="detail-title-icon"
          @click="showHiddenDetail"
          src="@/assets/test/arrow_right.png"
          alt
        />
        <div class="detail-title-middle">数据统计详情</div>
        <div class="detail-title-right" v-if="dataInfo.reviewer">审核人：{{dataInfo.reviewer}}</div>
        <div class="detail-title-right" v-else>审核人：暂无审核人员</div>
      </div>
      <div class="project overflow-xy">
        <div class="project-title">项目信息</div>
        <div class="custom-content">
          <div class="project-item-bottom">
            <span class="item-left">项目名称：</span>
            <span class="project-06">{{dataInfo.projectName}}</span>
          </div>
          <div class="project-item-bottom">
            <span class="item-left">项目经理：</span>
            <span class="project-06">{{dataInfo.projectManager}}</span>
          </div>
          <div class="project-item-bottom">
            <span class="item-left">项目周期：</span>
            <span class="project-06">
              {{dataInfo.time1}}
              <span v-if="dataInfo.time2">至 {{dataInfo.time2}}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="custom project overflow-xy">
        <div class="project-title">客户信息</div>
        <div class="custom-content">
          <div class="project-item-bottom">
            <span class="item-left">客户名称：</span>
            <span class="project-06">{{dataInfo.consumerEntity.companyName}}</span>
          </div>
          <div class="project-item-bottom">
            <span class="item-left">纳税人识别号：</span>
            <span class="project-06">{{dataInfo.consumerEntity.nsrsbh}}</span>
          </div>
          <!-- <div class="project-item-bottom">
            <span class="item-left">会计制度：</span>
            <span class="project-06">{{kjzdList[dataInfo.consumerEntity.kjzd]}}</span>
          </div> -->
          <div class="project-item-bottom">
            <span class="item-left">所属地区：</span>
            <span
              class="project-06"
              v-if="dataInfo.consumerEntity&&dataInfo.consumerEntity.provinceCode"
            >{{dataInfo.conversionAreas}}</span>
          </div>
          <div class="project-item-bottom">
            <span class="item-left">所属行业：</span>
            <span class="project-06">{{dataList[dataInfo.consumerEntity.industry]}}</span>
          </div>
          <div class="project-item-bottom">
            <span class="item-left">联系人：</span>
            <span class="project-06">{{dataInfo.consumerEntity.contact}}</span>
          </div>
          <div>
            <span class="item-left">联系电话：</span>
            <span class="project-06">{{dataInfo.consumerEntity.contactNumber}}</span>
          </div>
        </div>
      </div>
      <div class="project report overflow-xy" v-if="dataInfo.ptRuleListEntity">
        <div class="project-title">检测报告</div>
        <div class="custom-content">
          <div class="project-item-bottom">
            <span class="item-left">检测产品：</span>
            <span class="project-06">综合体检</span>
          </div>
          <div class="project-item-bottom">
            <span class="item-left">检测类型：</span>
            <span class="project-06">年度</span>
          </div>
          <div class="project-item-bottom">
            <span class="item-left">检测时间：</span>
            <span class="project-06">{{formatDateTe(dataInfo.ptRuleListEntity.exeTime)}}</span>
          </div>
          <div class="project-item-bottom">
            <span class="item-left">征期：</span>
            <span class="project-06">{{dataInfo.ptRuleListEntity.zqMc}}</span>
          </div>
        </div>
      </div>
      <div class="project report reviews" v-if="dataInfo.reviewer">
        <div class="custom-content">
          <div class="project-item-bottom item-left-top">
            <span class="item-left">审核人：</span>
            <span class="project-06">{{dataInfo.reviewer}}</span>
          </div>
          <div class="project-item-bottom">
            <span class="item-left">审核时间：</span>
            <span class="project-06">{{formatDateTe(dataInfo.reviewTime)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { regionData } from "@/dependencies/element-china-area-data.js";
import { veriFiItem, veriFiCustom, area } from "@/pages/product/utils/limit.js";
import { formatDateTe, kjzdList } from "../help.js";
export default {
  props: {
    dataInfo: [Object, Array],
    dataList: [Object, Array],
  },
  data() {
    return {
      loading: true,
      kjzdList: kjzdList
    };
  },
  methods: {
    showHiddenDetail() {
      this.$emit("hiddenDetail");
    },
    formatDateTe: formatDateTe,
  },
  watch: {
    dataInfo(val) {
      if (val) {
        this.loading = false;
      }
      this.dataInfo = val;
    },
  },
};
</script>

<style scoped>
.detail {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.detail-title {
  display: flex;
  align-items: center;
  width: 496px;
  height: 80px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(218, 213, 232, 0.6);
}

.detail-title-icon {
  width: 30px;
  height: 30px;
  padding: 20px 8px 16px 20px;
  cursor: pointer;
}

.detail-title-middle {
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.detail-title-right {
  flex: 1;
  text-align: right;
  margin-right: 24px;
  font-size: 14px;
  color: rgba(250, 173, 20, 1);
}

.project {
  width: 464px;
  background: rgba(250, 173, 20, 0.1);
  border-radius: 4px;
  border: 1px solid rgba(250, 173, 20, 0.24);
  margin: 16px 16px 8px 16px;
  padding-bottom: 15px;
}

.custom {
  background: rgba(240, 245, 255, 1);
  border: 1px solid rgba(214, 228, 255, 1);
  padding-bottom: 23px;
}

.report {
  background: rgba(82, 196, 26, 0.1);
  border: 1px solid rgba(82, 196, 26, 0.24);
}

.project-title {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 16px;
  margin-top: 15px;
}

.project-infos {
  margin-left: 16px;
  line-height: 20px;
}

.custom-content {
  margin-left: 6px;
  line-height: 20px;
}

.project-info {
  display: flex;
  align-items: center;
}

.project-item {
  width: 50%;
}

.project-item-bottom {
  margin-bottom: 8px;
}

.project-06 {
  color: rgba(0, 0, 0, 0.6);
}

.item-left {
  display: inline-block;
  width: 108px;
  text-align: right;
}

.item-left-top {
  margin-top: 15px;
}

.reviews {
  background: rgba(245, 34, 45, 0.04);
  border: 1px solid rgba(245, 34, 45, 0.16);
}

@media (max-height: 800px) {
  .project {
    max-height: 100px;
    overflow-x: hidden;
  }

  .custom {
    max-height: 200px;
    overflow-x: hidden;
  }
  .report {
    max-height: 120px;
    overflow-x: hidden;
  }
}

.overflow-xy::-webkit-scrollbar {
  /* 滚动条整体样式 */
  width: 10px; /* 高宽分别对应横竖滚动条的尺寸 */
  height: 1px;
  position: relative;
  right: 4px;
}

.overflow-xy::-webkit-scrollbar-thumb {
  /* 滚动条里面小方块 */
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.overflow-xy::-webkit-scrollbar-track {
  /* 滚动条里面轨道 */
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #ededed;
}
</style>