// 菜单配置
// headerMenuConfig：头部导航配置
// asideMenuConfig：侧边导航配置

const headerMenuConfig = [];

const asideMenuConfig = [{
    path: "/",
    name: "首页",
    icon: "iconfont iconshouye",
    id: 1,
    routeNames: ["homePage"]
  },
  {
    path: "/test/chooseTable",
    name: "风险检测",
    id: 15,
    routeNames: [
      "test-hub",
      "choose-table",
      "upload-table",
      "result",
      "test-invoice"
    ],
    icon: "iconfont iconjiancezhongxin"
  },
  {
    path: "/test/test-log",
    name: "检测记录",
    id: 16,
    routeNames: ["test-log", "reportBill"],
    icon: "iconfont iconlishijiance"
  },
  {
    path: "/",
    name: "账户管理",
    id: 30,
    children: [{
        path: "/account/account",
        name: "账户列表",
        id: 31,
        icon: "iconfont icona-zhanghaoliebiaojituanban",
        routeNames: ["account"]
      },
      {
        path: "/account/role",
        name: "角色管理",
        id: 36,
        icon: "iconfont icona-jiaoseguanlijituanban",
        routeNames: ["role"]
      },
    ]
  },
  {
    name: "帮助中心",
    path: "/helpAndCenter/index",
    id: 47,
    icon: "iconfont icona-daizhenggaishixiangjituanban",
    routeNames: ["helpAndCenter"]
  },
  {
    path: "/config/record",
    name: "日志记录",
    id: 45,
    icon: "iconfont icona-zhenggaijilujituanban",
    routeNames: ["configRecord"]
  }
];

export {
  headerMenuConfig,
  asideMenuConfig
};
