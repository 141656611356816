<template>
  <div class="containerss">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>品牌配置</el-breadcrumb-item>
          <el-breadcrumb-item>品牌设置</el-breadcrumb-item>
        </el-breadcrumb>
      </bread-crumb>
      <main-header>
        <template slot="title">品牌设置</template>
      </main-header>
    </div>
    <div class="flexContainer">
      <report-set :listData="listData" class="flexContainerR"></report-set>
      <logo-upload class="flexContainerL"></logo-upload>
    </div>
    <!-- <seal></seal> -->
  </div>
</template>

<script>
import logoUpload from "./components/logoUpload";
import reportSet from "./components/reportSet";
import { getLogo } from "@/api/api";
import BreadCrumb from "@/components/BreadCrumb";
import MainHeader from "@/pages/components/MainHeader";
// import seal from "./components/seal";

export default {
  components: {
    logoUpload,
    reportSet,
    BreadCrumb,
    MainHeader,
    // seal
  },
  data() {
    return {
      listData: null,
    };
  },
  created() {
    // this.getlogo();
  },
  methods: {
    getlogo() {
      getLogo(localStorage.getItem("authorization")).then((res) => {
        this.listData = res.data;
      });
    },
  },
};
</script>

<style scoped>
.containerss {
  /* width: 2000px; */
}

.title-content {
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

>>> .bread-crumb-wrapper {
  padding: 0;
  line-height: 20px;
  padding: 16px 0 16px 30px;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  margin-bottom: 20px;
}

.flexContainerL {
  /* flex: 1; */
  /* width: 50%; */
  /* min-width: 664px; */
}

.flexContainerR {
  /* flex: 1; */
  /* width: 50%; */
  margin-left: 20px;
}
</style>
