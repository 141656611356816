<template>
  <div>
    <div class="wrapper">
      <div class="content-wrapper">
        <div class="textarea-wrapper" v-if="isEditable">
          <el-tooltip
            :popper-class="popperClass"
            :manual="true"
            :value="isInputModified || isInputModifiedByMyself"
            content="此内容已修改"
            placement="top-end"
          >
            <el-input
              :class="{
                isModified: isInputModified,
                isModifiedByMyself: isInputModifiedByMyself,
              }"
              type="textarea"
              :disabled="!isEditable"
              :placeholder="isEditable?'请输入内容':'不显示'"
              v-model="dataText"
              maxlength="3000"
              show-word-limit
              class="textarea"
            ></el-input>
          </el-tooltip>
        </div>
        <div v-else class="text">
          {{dataText}}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import mixin from "@/pages/ResultEditor/mixin.js";

export default {
  name: "SimpleTextContent",
  mixins: [mixin],
  props: {
    indexes: Array
  },
  data: function () {
    return {};
  },
  watch: {},
  methods: {
    ...mapMutations("ResultEditor", ["setTreeDataByIndexes"])
  },
  computed: {
    ...mapGetters("ResultEditor", ["findTheNodeByIndexes","isCensorDone"]),
    pdfData() {
      return JSON.parse(this.data.pdfData);
    },
    data() {
      return this.findTheNodeByIndexes(this.indexes);
    },
    //共输入框使用
    dataText: {
      get() {
        return this.data.dataText;
      },
      set(value) {
        const nodeData = this.data;
        nodeData.dataText = value; //给节点设置新的dataText
        this.setTreeDataByIndexes({
          data: nodeData,
          indexes: this.indexes,
        });
      },
    },
    isInputModified() {
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.lastCensorSnapshot
          ? this.data.lastCensorSnapshot.includes("TextChange")||this.data.lastCensorSnapshot.includes("addChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.lastEditSnapshot
          ? this.data.lastEditSnapshot.includes("TextChange")||this.data.lastEditSnapshot.includes("addChange")
          : false;
      }
      return ret;
    },
    isInputModifiedByMyself() {
      if(this.isCensorDone){
        return false
      }
      let ret = false;

      if (this.$route.params.action == "edit") {
        ret = this.data.editSnapshot
          ? this.data.editSnapshot.includes("TextChange")||this.data.editSnapshot.includes("addChange")
          : false;
      } else if (this.$route.params.action == "censor") {
        ret = this.data.censorSnapshot
          ? this.data.censorSnapshot.includes("TextChange")||this.data.censorSnapshot.includes("addChange")
          : false;
      }
      return ret;
    },
    popperClass() {

      let ret = "";

      if(this.isCensorDone){
        return ret
      }

      if (this.isInputModifiedByMyself) {
        ret = "modified-tooltip-red";
      } else if (this.isInputModified) {
        ret = "modified-tooltip-yellow";
      }
      return ret;
    },
  }
};
</script>

<style lang="stylus" scoped>
.wrapper {
  background: rgba(251, 251, 251, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px 24px;
}

.content-wrapper {
  box-sizing: border-box;
  padding: 0 0 16px;
  .text{
    font-size 14px
  }
}

.item-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(245, 34, 45, 0.03);
  font-size: 14px;
  padding: 24px 28px 34px 18px;
}

.item-content {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.5;
}

.item-bottom {
  margin-top: 28px;
}

.item-bottom-btn {
  padding: 8px 16px;
  background: rgba(251, 251, 251, 1);
  border-radius: 4px;
  margin-right: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.item-i-margin {
  margin-right: 8px;
}

.item-right-title {
  line-height: 1.5;
  margin: 4px 0;
  display: flex;
  align-items: center;
}

.item-flex-l {
  display: inline-block;
  width: 85px;
  text-align: right;
}

.item-flex-1 {
  flex: 1;
  line-height: 1.5;
}

.item-color-l {
  color: rgba(0, 0, 0, 0.4);
}

.item-color-r {
  color: rgba(0, 0, 0, 0.8);
  flex-shrink: 0;
}

.item-color-b {
  color: rgba(47, 84, 235, 1);
  cursor: pointer;
}

.textarea-wrapper {
  margin: 16px;

  >>> .el-textarea__inner {
    height: 100px;
  }

  .isModified.textarea {
    >>> .el-textarea__inner {
      border: 1px solid #f5ad14 !important;
      border-color: #faad14 !important;
    }

    border-radius: 4px;
  }

  .isModifiedByMyself.textarea {
    >>> .el-textarea__inner {
      border: 1px solid rgba(245, 34, 45, 1) !important;
      border-color: rgba(245, 34, 45, 1) !important;
    }

    border-radius: 4px;
  }
}
</style>
