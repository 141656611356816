<template>
  <div class="z-container" v-loading="addLoading">
    <div class="z-titles">
      <div>{{ addName }}</div>
      <img
        @click="closeRole"
        class="z-close"
        src="@/assets/test/part-close.png"
        alt=""
      />
    </div>
    <div class="z-content">
      <transition-group tag="div" class="z-content-grop">
        <template v-if="this.typeAddType == 0">
          <div
            class="z-content-item ease"
            v-for="item in countList"
            :key="item.key"
            :data-index="item.key"
            draggable="true"
            @dragstart="handleDragStart($event, item)"
            @dragover.prevent="handleDragOver($event, item)"
            @dragenter="handleDragEnter($event, item)"
            @dragend="handleDragEnd($event, item)"
          >
            {{ item.name }}
            <div class="z-content-close" @click="deleteItem(item)">×</div>
          </div>
        </template>
        <template v-if="this.typeAddType == 1">
          <div
            class="z-content-item ease"
            v-for="item in countListBan"
            :key="item.key"
            :data-index="item.key"
            draggable="true"
            @dragstart="handleDragStart($event, item)"
            @dragover.prevent="handleDragOver($event, item)"
            @dragenter="handleDragEnter($event, item)"
            @dragend="handleDragEnd($event, item)"
          >
            {{
              item.name == " && "
                ? " and "
                : item.name && item.name == " || "
                ? " or "
                : item.name
            }}
            <div class="z-content-close" @click="deleteItem(item)">×</div>
          </div>
        </template>
        <template v-if="this.typeAddType == 3">
          <div
            class="z-content-item ease"
            v-for="item in countListModel"
            :key="item.key"
            :data-index="item.key"
            draggable="true"
            @dragstart="handleDragStart($event, item)"
            @dragover.prevent="handleDragOver($event, item)"
            @dragenter="handleDragEnter($event, item)"
            @dragend="handleDragEnd($event, item)"
          >
            {{ item.name }}
            <div class="z-content-close" @click="deleteItem(item)">×</div>
          </div>
        </template>
      </transition-group>
    </div>
    <div class="z-select">
      <div
        class="z-item"
        v-for="item in btnsItemListData"
        :key="item.id"
        @click="addItem(item)"
      >
        {{
          item.name == " && "
            ? " and "
            : item.name && item.name == " || "
            ? " or "
            : item.name
        }}
      </div>
    </div>
    <div class="z-pane" v-if="this.typeAddType != 3">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="元数据" name="first">
          <div class="z-tree">
            <div class="z-left-tree roleListOver">
              <el-tree
                :data="typeList"
                :props="defaultProps"
                @node-click="handleNodeClick"
              ></el-tree>
            </div>
            <div class="z-right-tree">
              <div class="z-right-input">
                <el-input
                  v-model.trim="inputContent"
                  placeholder="请输入"
                  clearable
                  @input="inputContentC"
                ></el-input>
                <img
                  class="z-input-icon"
                  src="@/assets/test/editorSearch.png"
                  alt=""
                  @click="searchMenudata"
                />
              </div>
              <div class="z-right-indexC fixed-content">
                <div v-for="(item, index) in nodeListData" :key="index">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.metaName"
                    placement="top-end"
                    :open-delay="500"
                  >
                    <div class="z-right-index" @click="addTarget(item)">
                      <img
                        class="z-right-hover"
                        src="@/assets/test/editorhover.png"
                        alt=""
                      />
                      <div class="z-right-text">{{ item.metaName }}</div>
                    </div>
                  </el-tooltip>
                </div>
                <div
                  class="noData"
                  v-if="!nodeListData || nodeListData.length < 1"
                >
                  暂无数据
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="指标" name="second">
          <div class="z-tree">
            <div class="z-left-tree roleListOver">
              <el-tree
                :data="typeList"
                :props="defaultProps"
                @node-click="handleNodeClick"
              ></el-tree>
            </div>
            <div class="z-right-tree">
              <div class="z-right-input">
                <el-input
                  v-model.trim="inputContent"
                  placeholder="请输入"
                  clearable
                  @input="inputContentC"
                ></el-input>
                <img
                  class="z-input-icon"
                  src="@/assets/test/editorSearch.png"
                  alt=""
                  @click="searchMenudata"
                />
              </div>
              <div class="z-right-indexC fixed-content">
                <div v-for="(item, index) in nodeListData" :key="index">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.metaName"
                    placement="top-end"
                    :open-delay="500"
                  >
                    <div class="z-right-index" @click="addTarget(item)">
                      <img
                        class="z-right-hover"
                        src="@/assets/test/editorhover.png"
                        alt=""
                      />
                      <div class="z-right-text">{{ item.metaName }}</div>
                    </div>
                  </el-tooltip>
                </div>
                <div
                  class="noData"
                  v-if="!nodeListData || nodeListData.length < 1"
                >
                  暂无数据
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="函数运算符" name="third">
          <div class="z-tree">
            <div class="z-left-tree">
              <el-tree
                :data="typeLists"
                :props="defaultProp"
                @node-click="handleNodeOper"
              ></el-tree>
            </div>
            <div class="z-right-tree">
              <div class="z-right-input">
                <el-input
                  v-model="inputContent"
                  placeholder="请输入"
                  clearable
                ></el-input>
                <img
                  class="z-input-icon"
                  src="@/assets/test/editorSearch.png"
                  alt=""
                />
              </div>
              <div class="z-right-indexC fixed-content">
                <div v-for="item in operList" :key="item.functionId">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.functionName"
                    placement="top-end"
                    :open-delay="500"
                  >
                    <div class="z-right-index" @click="addTarget(item)">
                      <img
                        class="z-right-hover"
                        src="@/assets/test/editorhover.png"
                        alt=""
                      />
                      <div class="z-right-text">
                        {{ item.functionDescription }}
                      </div>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="z-btn">
      <div class="z-btn-right z-btn-default ease" @click="confirmRole">
        确定
      </div>
      <div class="z-btn-left z-btn-default ease" @click="closeRole">取消</div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { itemList, itemListBan } from "./data.js";
import { checkCountList } from "./limt.js";
import {
  loadMeta,
  getMenu,
  getFunction,
  getFunctionDetail,
  metaDetail,
  searchMenuData,
} from "@/api/editor";

export default {
  props: {
    id: [String, Number],
    itemListData: [Object, Array],
    addName: [String],
    typeAddType: [Number, String],
    countListModelData: [Array],
    preNodeItem: null, //父节点选项
    list: [Object, Array],
    exceptionList: [Object, Array],
  },
  computed: {
    //加了父节点的按钮集合
    btnsItemListData() {
      let ret = this.itemListData ? [...this.itemListData] : null;

      if (this.preNodeItem) {
        ret.push(this.preNodeItem);
      }
      return ret;
    },
  },
  data() {
    return {
      typeList: [],
      nodeListData: null,
      countList: [],
      countListBan: [],
      inputContent: "",
      addLoading: false,
      items: itemListBan,
      activeName: "first",
      activeNameArr: {
        first: "PF.YSJ",
        second: "PF.ZB",
        third: 3,
      },
      defaultProps: {
        children: "childNode",
        label: "catalogName",
      },
      defaultProp: {
        children: "childNode",
        label: "functionType",
      },
      formulaItem: "",
      expression: "",
      relations: [],
      formulaItemAbn: "",
      relationsAbn: [],
      expressionAbn: "",
      dataRange: ["PF.YSJ", "PF.ZB"],
      countListModel: [],
      relationsModel: [],
      typeLists: null,
      operList: null,
    };
  },
  mounted() {
    if (this.countListModelData) {
      this.countListModel = cloneDeep(this.countListModelData);
    }

    this.$emit("changeTab", 0);
    this.getMenus("PF.YSJ");
    this.getfunction();
    this.getDetail();
  },
  methods: {
    getDetail() {
      let params = {
        metaId: this.id,
      };
      metaDetail(params).then((res) => {
        if (res.msgCode == 1) {
          this.targetDetail = res.data;
          this.indexData = JSON.parse(res.data.data);
          this.countList = this.indexData.list || [];
          //   this.countListBan = this.indexData.exceptionList;
          this.relations = this.indexData.relations;
          if (
            this.indexData.exceptionList &&
            this.indexData.exceptionList.length > 0 &&
            this.indexData.exceptionList[0]
          ) {
            this.countListBan = this.indexData.exceptionList;
            this.relationsAbn = this.indexData.zbProperty.exceptions[0].condition.relations;
          }
        }
      });
    },
    handleNodeOper(e) {
      let params = {
        functionType: e.functionType,
      };
      getFunctionDetail(params).then((res) => {
        this.operList = res.data;
      });
    },
    getfunction() {
      getFunction().then((res) => {
        this.typeLists = res.data;
      });
    },
    getMenus(type) {
      let params = {
        catalogType: type,
        catalogSelectUse: false,
      };
      getMenu(params).then((res) => {
        if (res.msgCode == 1) {
          this.typeList = res.data;
        }
      });
    },
    changeTab(index) {
      if (index == 2) {
        return;
      }
      this.getMenus(this.dataRange[index]);
    },
    addTarget(item) {
      //选择模型时，双击元数据和指标，传出item
      this.$emit("checkMeta", item);
      //选择模型时，双击元数据和指标，传出item
      let metaItem = {};
      if (item.functionId) {
        [metaItem.id, metaItem.key, metaItem.name] = [
          item.functionId,
          item.functionId,
          item.functionFormula,
        ];
      } else {
        [metaItem.id, metaItem.key, metaItem.name] = [
          item.metaId,
          item.metaId,
          item.metaName,
        ];
      }

      this.addItem(metaItem);
      // 指标编辑
      if (this.typeAddType == 0) {
        let relations = {
          entityId: item.metaId,
          entityType: item.entityType,
        };
        if (!item.metaId) return;
        this.relations.push(relations);
        // 异常情况
      } else if (this.typeAddType == 1) {
        let relations = {
          entityId: item.metaId,
          entityType: item.entityType,
        };
        if (!item.metaId) return;
        this.relationsAbn.push(relations);
        // 模型编辑
      } else if (this.typeAddType == 3) {
        let relations = {
          entityId: item.metaId,
          entityType: item.entityType,
        };
        this.relationsModel.push(relations);
      }
    },
    checkList() {
      this.formulaItemAbn = this.countListBan.map((item) => {
        return item.name;
      });
      this.expressionAbn = this.countListBan.map((item) => {
        if (item.key.length > 4) {
          return item.id;
        }
        return item.name;
      });
      // 校验公式是否符合规则
      // 拼接为字符串
      this.$emit("saveAbnItem", {
        formulaItemAbn: this.formulaItemAbn.join(""),
        relationsAbn: this.relationsAbn,
        expressionAbn: this.expressionAbn,
        dataList: this.countList,
        dataListAbn: this.countListBan,
      });
      this.$message({
        message: "添加成功！",
        type: "success",
      });
    },
    // 模型编辑器时候的新增指标
    checkListModel() {
      this.formulaItemMod = this.countListModel.map((item) => {
        return item.name;
      });
      this.expressionMod = this.countListModel.map((item) => {
        if (item.key.length > 4) {
          return item.id;
        }
        return item.name;
      });
      // 校验公式是否符合规则
      // 拼接为字符串
      this.$emit("saveModItem", {
        formulaItemMod: this.formulaItemMod.join(""),
        relationsMod: this.relationsMod,
        expressionMod: this.expressionMod,
        dataList: this.countList,
        dataListAbn: this.countListBan,
        dataListMod: this.countListModel,
      });

      this.$message({
        message: "添加成功！",
        type: "success",
      });
    },
    confirmRole() {
      console.log(this.typeAddType);
      // if (this.countListBan.length < 1 && this.typeAddType == 1) {
      //   this.$message({
      //     message: "请输入异常情况！",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (this.countList.length < 1 && this.typeAddType == 0) {
      //   this.$message({
      //     message: "请输入公式！",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if (this.countListModel.length < 1 && this.typeAddType == 3) {
      //   this.$message({
      //     message: "请输入公式！",
      //     type: "warning",
      //   });
      //   return;
      // }
      if (this.typeAddType == 1) {
        this.checkList();
        return;
      }
      if (this.typeAddType == 3) {
        this.checkListModel();
        return;
      }
      this.formulaItem = this.countList.map((item) => {
        return item.name;
      });
      this.expression = this.countList.map((item) => {
        if (item.key.length > 4) {
          return item.id;
        }
        return item.name;
      });
      if (!checkCountList(this.countList)) {
        return;
      }
      // 校验公式是否符合规则
      // 拼接为字符串
      this.$emit("saveItem", {
        formulaItem: this.formulaItem.join(""), //字符串公式，纯名称
        relations: this.relations, // 使用到的元数据和指标
        expression: this.expression, //带元数据和指标的数组，可转为带ID的名称
        dataList: this.countList, //指标数组
        dataListAbn: this.countListBan, //异常情况数组
      });
      // this.$message({
      //   message: "添加成功！",
      //   type: "success",
      // });
    },
    deleteItem(item) {
      // console.log(item);
      // console.log(this.relations);
      let id = item.key;
      let relationsId = item.id;
      if (this.typeAddType == 0) {
        let idx = this.countList.findIndex((item) => {
          return id == item.key;
        });
        let relationIdx = this.relations.findIndex((item) => {
          return relationsId == item.entityId;
        });
        if (relationIdx != -1) {
          this.relations.splice(relationIdx, 1);
        }
        if (idx != -1) {
          this.countList.splice(idx, 1);
        }
      } else if (this.typeAddType == 1) {
        let idx = this.countListBan.findIndex((item) => {
          return id == item.key;
        });
        let relationIdx = this.relationsAbn.findIndex((item) => {
          return relationsId == item.entityId;
        });
        if (relationIdx != -1) {
          this.relationsAbn.splice(relationIdx, 1);
        }
        if (idx != -1) {
          this.countListBan.splice(idx, 1);
        }
      } else if (this.typeAddType == 3) {
        let idx = this.countListModel.findIndex((item) => {
          return id == item.key;
        });
        let relationIdx = this.relationsMod.findIndex((item) => {
          return relationsId == item.entityId;
        });
        if (relationIdx != -1) {
          this.relationsMod.splice(relationIdx, 1);
        }
        if (idx != -1) {
          this.countListModel.splice(idx, 1);
        }
      }
    },
    closeRole() {
      this.$emit("closeAddBoxs");
    },
    inputContentC() {
      if (!this.inputContent) {
        let params = {
          catalogId: this.catalogIdCurrent,
        };
        loadMeta(params).then((res) => {
          if (res.msgCode == 1) {
            this.nodeListData = res.data;
          }
        });
      }
    },
    searchMenudata() {
      if (!this.inputContent) return;
      let params = {
        catalogType: this.activeNameArr[this.activeName],
        metaName: this.inputContent,
      };
      searchMenuData(params).then((res) => {
        if (res.msgCode == 1) {
          this.nodeListData = res.data;
        } else {
          this.$message({
            showClose: true,
            message: res.msgInfo,
            type: "error",
          });
        }
      });
    },
    handleClick(tab, event) {
      this.inputContent = "";
      if (tab.index == 2) {
        return;
      }
      this.getMenus(this.dataRange[tab.index]);
    },
    handleNodeClick(e) {
      this.catalogIdCurrent = e.catalogId;
      let params = {
        catalogId: this.catalogIdCurrent,
      };
      loadMeta(params).then((res) => {
        if (res.msgCode == 1) {
          this.nodeListData = res.data;
        }
      });
    },
    addAbn(item) {
      let newItem = this.clone(item);
      var newArr = "";
      let countLength = this.countListBan.filter((arr) => {
        return arr.key.toString().indexOf(newItem.key) > -1;
      });
      newItem.key = newItem.key + "-" + countLength.length;
      newItem.id = newItem.id;
      newItem.name = newItem.name;
      this.countListBan.push(newItem);
    },
    addModel(item) {
      let newItem = this.clone(item);
      var newArr = "";
      let countLength = this.countListModel.filter((arr) => {
        return arr.key.toString().indexOf(newItem.key) > -1;
      });
      newItem.key = newItem.key + "-" + countLength.length;
      newItem.id = newItem.id;
      newItem.name = newItem.name;
      this.countListModel.push(newItem);
    },
    addItem(item) {
      if (item.id == 23 && this.typeAddType == 0) {
        console.log("fdsfs");
        this.relations = [];
        this.countList = [];
        return;
      } else if (item.id == 23 && this.typeAddType == 1) {
        console.log("fdsfs222");
        this.relationsAbn = [];
        this.countListBan = [];
        return;
      } else if (item.id == 23 && this.typeAddType == 3) {
        this.countListModel = [];
        return;
      }
      if (this.typeAddType == 1) {
        this.addAbn(item);
        return;
      }
      if (this.typeAddType == 3) {
        this.addModel(item);
        return;
      }
      let newItem = this.clone(item);
      var newArr = "";
      let countLength = this.countList.filter((arr) => {
        return arr.key.toString().indexOf(newItem.key) > -1;
      });
      newItem.key = newItem.key + "-" + countLength.length;
      newItem.id = newItem.id;
      newItem.name = newItem.name;
      this.countList.push(newItem);
    },
    handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd(e, item) {
      this.dragging = null;
    },
    //首先把div变成可以放置的元素，即重写dragenter/dragover
    handleDragOver(e) {
      e.dataTransfer.dropEffect = "move"; // e.dataTransfer.dropEffect="move";//在dragenter中针对放置目标来设置!
    },
    handleDragEnter(e, item) {
      e.dataTransfer.effectAllowed = "move"; //为需要移动的元素设置dragstart事件
      if (item === this.dragging) {
        return;
      }
      if (this.typeAddType == 0) {
        const newItems = [...this.countList];
        const src = newItems.indexOf(this.dragging);
        const dst = newItems.indexOf(item);
        newItems.splice(dst, 0, ...newItems.splice(src, 1));
        this.countList = newItems;
      }
      if (this.typeAddType == 1) {
        const newItems = [...this.countListBan];
        const src = newItems.indexOf(this.dragging);
        const dst = newItems.indexOf(item);
        newItems.splice(dst, 0, ...newItems.splice(src, 1));
        this.countListBan = newItems;
      }
      if (this.typeAddType == 3) {
        const newItems = [...this.countListModel];
        const src = newItems.indexOf(this.dragging);
        const dst = newItems.indexOf(item);
        newItems.splice(dst, 0, ...newItems.splice(src, 1));
        this.countListModel = newItems;
      }
    },

    clone(obj) {
      if (obj === null) return null;
      if (typeof obj !== "object") return obj;
      if (obj.constructor === Date) return new Date(obj);
      var newObj = new obj.constructor(); //保持继承链
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          //不遍历其原型链上的属性
          var val = obj[key];
          newObj[key] = typeof val === "object" ? arguments.callee(val) : val; // 使用arguments.callee解除与函数名的耦合
        }
      }
      return newObj;
    },
  },
};
</script>

<style lang="stylus" scoped>
.z-container {
  position: absolute;
  left: 50%;
  margin-left: -400px;
  top: 6vh;
  width: 800px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1003;
  overflow: hidden;
}

.z-titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px 0 24px;
  height: 54px;
  background: #FFFFFF;
  box-shadow: 0px 1px 0px 0px #EEEEEE;
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.z-close {
  padding: 20px;
  cursor: pointer;
}

.z-content {
  display: flex;
  flex-wrap: wrap;
  margin: 24px;
  margin-bottom: 16px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  padding: 8px 0 12px 8px;
  min-height: 52px;
}

.z-content-grop {
  display: flex;
  flex-wrap: wrap;
}

.z-select {
  display: flex;
  flex-wrap: wrap;
  background: #F0F5FF;
  border-radius: 4px;
  margin: 0 24px;
  padding: 12px 16px 4px 16px;
  box-sizing: border-box;
}

.z-item {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 48px;
  padding: 0 13px;
  height: 28px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  margin-right: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  user-select: none;
}

.z-item:hover {
  background: #f0f5ff;
}

>>>.el-tabs__item {
  width: 120px;
  text-align: center;
}

.z-pane {
  margin: 6px 24px 0 24px;
}

.z-left-tree {
  float: left;
  width: 240px;
  background: #FFFFFF;
  margin-right: 8px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  max-height: 280px;
  overflow-x: hidden;
}

.z-right-tree {
  float: left;
  width: 472px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  vertical-align: top;
  // max-height: 240px;
  // overflow-x: hidden;
}

.z-right-indexC {
  max-height: 242px;
  overflow-x: hidden;
}

.z-tree {
  background: #FBFBFB;
  padding: 16px;
  box-shadow: 0px -1px 0px 0px #EEEEEE;
  border-radius: 4px 4px 4px 4px;
  overflow: hidden;
}

.z-right-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border-bottom: 1px solid #EEEEEE;
  box-sizing: border-box;
}

.z-input-icon {
  width: 14px;
  height: 14px;
  margin-right: 16px;
  cursor: pointer;
}

.z-right-index {
  display: flex;
  align-items: center;
  width: 472px;
  height: 36px;
  line-height: 36px;
  background: #FFFFFF;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.z-right-index:hover {
  background: #F0F5FF;
  color: #2F54EB;
}

.z-right-index:hover .z-right-hover {
  visibility: visible;
}

.z-right-hover {
  width: 14px;
  height: 14px;
  margin: 0 8px 0 16px;
  visibility: hidden;
}

.z-btn {
  height: 68px;
  background: #FFFFFF;
  box-shadow: 0px -1px 0px 0px #EEEEEE;
  margin-top: 24px;
}

>>>.el-tree {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

>>>.el-tabs__item {
  color: rgba(0, 0, 0, 0.4);
}

>>>.el-tabs__item.is-active {
  color: #2F54EB;
}

>>>.el-tabs__header {
  margin: 0;
}

>>>.z-right-input .el-input__inner {
  border: none;
}

.fixed-content::-webkit-scrollbar {
  /* 滚动条整体样式 */
  width: 4px; /* 高宽分别对应横竖滚动条的尺寸 */
  height: 1px;
  position: relative;
  right: 4px;
}

.fixed-content::-webkit-scrollbar-thumb {
  /* 滚动条里面小方块 */
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.2);
}

.fixed-content::-webkit-scrollbar-track {
  /* 滚动条里面轨道 */
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  background: #EDEDED;
}

.z-btn-default {
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}

.z-btn-left {
  width: 76px;
  background: #FFFFFF;
  border: 1px solid #2F54EB;
  float: right;
  background: #FFFFFF;
  color: #2F54EB;
  margin-top: 16px;
}

.z-btn-right {
  width: 76px;
  background: #2F54EB;
  float: right;
  color: #fff;
  margin: 16px 24px 0 16px;
}

.z-btn-left:hover {
  background: rgba(47, 84, 235, 0.08);
}

.z-btn-right:hover {
  background: rgba(47, 34, 235, 1);
}

.ease {
  transition: all 0.3s;
}

.z-content-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  background: #FFF;
  border-radius: 4px;
  border: 1px solid #FFF;
  margin-left: 4px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 4px;
}

.z-content-item:hover {
  background: #F0F5FF;
  border: 1px solid #ADC6FF;
  padding: 0 12px;
}

.z-content-item:hover .z-content-close {
  display: block;
}

>>>.el-input__inner {
  height: 36px;
  line-height: 36px;
}

>>>.el-tree-node__content {
  height: 36px;
}

.z-content-close {
  position: absolute;
  display: none;
  top: -4px;
  right: -4px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  border-radius: 50%;
  background: #ADC6FF;
  font-size: 6px;
  color: #fff;
}

.ease {
  transition: all 0.3s;
}

.noData {
  padding: 16px 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
}

.z-right-text {
  width: 390px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>