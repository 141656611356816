const gz_2017 = [
    {"name":"北京市","gongzi":"134994"},
    {"name":"上海市","gongzi":"130765"},
    {"name":"南京市","gongzi":"101502"},
    {"name":"深圳市","gongzi":"100173"},
    {"name":"广州市","gongzi":"98612"},
    {"name":"天津市","gongzi":"96965"},
    {"name":"杭州市","gongzi":"96670"},
    {"name":"宁波市","gongzi":"91705"},
    {"name":"长沙市","gongzi":"85187"},
    {"name":"济南市","gongzi":"84645"},
    {"name":"青岛市","gongzi":"83539"},
    {"name":"大连市","gongzi":"81884"},
    {"name":"珠海市","gongzi":"81014"},
    {"name":"武汉市","gongzi":"79684"},
    {"name":"成都市","gongzi":"79292"},
    {"name":"鄂尔多斯市","gongzi":"78166"},
    {"name":"乌鲁木齐市","gongzi":"78072"},
    {"name":"西安市","gongzi":"77774"},
    {"name":"廊坊市","gongzi":"77665"},
    {"name":"合肥市","gongzi":"77484"},
    {"name":"银川市","gongzi":"77206"},
    {"name":"东营市","gongzi":"76938"},
    {"name":"昆明市","gongzi":"76350"},
    {"name":"兰州市","gongzi":"75709"},
    {"name":"南宁市","gongzi":"75481"},
    {"name":"厦门市","gongzi":"75452"},
    {"name":"福州市","gongzi":"75133"},
    {"name":"马鞍山市","gongzi":"74921"},
    {"name":"沈阳市","gongzi":"74181"},
    {"name":"儋州市","gongzi":"74114"},
    {"name":"贵阳市","gongzi":"73939"},
    {"name":"西宁市","gongzi":"73540"},
    {"name":"长春市","gongzi":"73469"},
    {"name":"清远市","gongzi":"73323"},
    {"name":"重庆市","gongzi":"73272"},
    {"name":"佛山市","gongzi":"72712"},
    {"name":"南昌市","gongzi":"72686"},
    {"name":"太原市","gongzi":"72114"},
    {"name":"三亚市","gongzi":"71728"},
    {"name":"淮南市","gongzi":"71218"},
    {"name":"惠州市","gongzi":"70890"},
    {"name":"秦皇岛市","gongzi":"70753"},
    {"name":"郑州市","gongzi":"70486"},
    {"name":"包头市","gongzi":"69706"},
    {"name":"烟台市","gongzi":"68979"},
    {"name":"黄山市","gongzi":"68668"},
    {"name":"潍坊市","gongzi":"68139"},
    {"name":"海口市","gongzi":"68037"},
    {"name":"中山市","gongzi":"68034"},
    {"name":"宣城市","gongzi":"68012"},
    {"name":"石家庄市","gongzi":"67880"},
    {"name":"乌海市","gongzi":"67658"},
    {"name":"哈尔滨市","gongzi":"67542"},
    {"name":"滁州市","gongzi":"67264"},
    {"name":"梅州市","gongzi":"67186"},
    {"name":"淄博市","gongzi":"67003"},
    {"name":"沧州市","gongzi":"66984"},
    {"name":"芜湖市","gongzi":"66884"},
    {"name":"唐山市","gongzi":"66843"},
    {"name":"乌兰察布市","gongzi":"66332"},
    {"name":"江门市","gongzi":"66107"},
    {"name":"铜陵市","gongzi":"65944"},
    {"name":"韶关市","gongzi":"65739"},
    {"name":"桂林市","gongzi":"65694"},
    {"name":"鹰潭市","gongzi":"65632"},
    {"name":"肇庆市","gongzi":"65227"},
    {"name":"六安市","gongzi":"65070"},
    {"name":"呼伦贝尔市","gongzi":"65045"},
    {"name":"柳州市","gongzi":"64958"},
    {"name":"滨州市","gongzi":"64749"},
    {"name":"巴彦淖尔市","gongzi":"64696"},
    {"name":"淮北市","gongzi":"64335"},
    {"name":"张家界市","gongzi":"63884"},
    {"name":"保定市","gongzi":"63835"},
    {"name":"赤峰市","gongzi":"63809"},
    {"name":"防城港市","gongzi":"63484"},
    {"name":"日照市","gongzi":"63418"},
    {"name":"呼和浩特市","gongzi":"63084"},
    {"name":"湘潭市","gongzi":"62933"},
    {"name":"威海市","gongzi":"62344"},
    {"name":"张家口市","gongzi":"62338"},
    {"name":"池州市","gongzi":"62289"},
    {"name":"湛江市","gongzi":"62094"},
    {"name":"怀化市","gongzi":"62046"},
    {"name":"九江市","gongzi":"61938"},
    {"name":"济宁市","gongzi":"61909"},
    {"name":"汕头市","gongzi":"61868"},
    {"name":"东莞市","gongzi":"61619"},
    {"name":"河源市","gongzi":"61561"},
    {"name":"通辽市","gongzi":"61470"},
    {"name":"萍乡市","gongzi":"61464"},
    {"name":"茂名市","gongzi":"61442"},
    {"name":"株洲市","gongzi":"61418"},
    {"name":"蚌埠市","gongzi":"61191"},
    {"name":"郴州市","gongzi":"60820"},
    {"name":"益阳市","gongzi":"60754"},
    {"name":"北海市","gongzi":"60622"},
    {"name":"辽阳市","gongzi":"60361"},
    {"name":"赣州市","gongzi":"60337"},
    {"name":"承德市","gongzi":"60270"},
    {"name":"聊城市","gongzi":"60034"},
    {"name":"吉林市","gongzi":"59923"},
    {"name":"莱芜市","gongzi":"59740"},
    {"name":"泰安市","gongzi":"59653"},
    {"name":"阜阳市","gongzi":"59637"},
    {"name":"阳江市","gongzi":"59559"},
    {"name":"抚州市","gongzi":"59542"},
    {"name":"潮州市","gongzi":"59540"},
    {"name":"邢台市","gongzi":"59431"},
    {"name":"常德市","gongzi":"59399"},
    {"name":"新余市","gongzi":"59185"},
    {"name":"邯郸市","gongzi":"58917"},
    {"name":"德州市","gongzi":"58912"},
    {"name":"邵阳市","gongzi":"58665"},
    {"name":"上饶市","gongzi":"58376"},
    {"name":"枣庄市","gongzi":"58353"},
    {"name":"安庆市","gongzi":"58310"},
    {"name":"衡水市","gongzi":"58014"},
    {"name":"洛阳市","gongzi":"57863"},
    {"name":"梧州市","gongzi":"57618"},
    {"name":"三门峡市","gongzi":"57207"},
    {"name":"景德镇市","gongzi":"57191"},
    {"name":"抚顺市","gongzi":"56941"},
    {"name":"汕尾市","gongzi":"56810"},
    {"name":"亳州市","gongzi":"56530"},
    {"name":"四平市","gongzi":"56235"},
    {"name":"永州市","gongzi":"56212"},
    {"name":"吉安市","gongzi":"56109"},
    {"name":"宿州市","gongzi":"55971"},
    {"name":"衡阳市","gongzi":"55761"},
    {"name":"松原市","gongzi":"55434"},
    {"name":"宝鸡市","gongzi":"55419"},
    {"name":"菏泽市","gongzi":"55298"},
    {"name":"岳阳市","gongzi":"54893"},
    {"name":"锦州市","gongzi":"54892"},
    {"name":"阜新市","gongzi":"54331"},
    {"name":"铜川市","gongzi":"53785"},
    {"name":"宜春市","gongzi":"53568"},
    {"name":"临沂市","gongzi":"53440"},
    {"name":"娄底市","gongzi":"53301"},
    {"name":"许昌市","gongzi":"53268"},
    {"name":"渭南市","gongzi":"53081"},
    {"name":"朝阳市","gongzi":"52732"},
    {"name":"平顶山市","gongzi":"52728"},
    {"name":"通化市","gongzi":"52054"},
    {"name":"开封市","gongzi":"51951"},
    {"name":"南阳市","gongzi":"51871"},
    {"name":"咸阳市","gongzi":"51619"},
    {"name":"濮阳市","gongzi":"51319"},
    {"name":"安阳市","gongzi":"51098"},
    {"name":"焦作市","gongzi":"50980"},
    {"name":"本溪市","gongzi":"50881"},
    {"name":"漯河市","gongzi":"50681"},
    {"name":"辽源市","gongzi":"50474"},
    {"name":"周口市","gongzi":"50259"},
    {"name":"白山市","gongzi":"50230"},
    {"name":"鞍山市","gongzi":"50187"},
    {"name":"营口市","gongzi":"50074"},
    {"name":"信阳市","gongzi":"50013"},
    {"name":"驻马店市","gongzi":"49801"},
    {"name":"白城市","gongzi":"49749"},
    {"name":"新乡市","gongzi":"48886"},
    {"name":"铁岭市","gongzi":"47615"},
    {"name":"鹤壁市","gongzi":"46419"},
    {"name":"盘锦市","gongzi":"45890"},
    {"name":"丹东市","gongzi":"42230"},
    
    ]
    export default gz_2017;