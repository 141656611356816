import Vue from "vue";
//import Masker1 from "@/plugins/saalert/alert.vue";
import Masker1 from "@/directives/component/Masker1.vue";
import Masker2 from "@/directives/component/Masker2.vue";
import Masker3 from "@/directives/component/Masker3.vue";

// 注册一个全局自定义指令 `v-masker1`
Vue.directive("masker1", {
  bind: function(el, binding) {
    if (binding.value.show) {
      //聚焦元素
      el.style.position = "relative";
      Masker1.methods.handleViewMoreClick = () => {
        binding.value.onClick();
      };

      var MyComponent = Vue.extend(Masker1);
      const component = new MyComponent().$mount();
      el.appendChild(component.$el);
    }
  },
  // 当被绑定的元素插入到 DOM 中时……
  update: function(el, binding) {
    if (binding.value.show) {
      //聚焦元素
      el.style.position = "relative";
      Masker1.methods.handleViewMoreClick = () => {
        binding.value.onClick();
      };

      var MyComponent = Vue.extend(Masker1);
      const component = new MyComponent().$mount();
      el.appendChild(component.$el);
    }
  }
});

// 注册一个全局自定义指令 `v-masker1`
Vue.directive("masker2", {
  // 当被绑定的元素插入到 DOM 中时……
  update: function(el, binding) {
    if (binding.value.show) {
      //聚焦元素
      el.style.position = "relative";
      Masker2.methods.handleViewMoreClick = () => {
        binding.value.onClick();
      };

      var MyComponent = Vue.extend(Masker2);

      const component = new MyComponent().$mount();
      el.appendChild(component.$el);
    }
  }
});

// 注册一个全局自定义指令 `v-masker3` 试用版的遮罩
Vue.directive("masker3", {
  // 当被绑定的元素插入到 DOM 中时……
  update: function(el, binding) {
    if (binding.value.show) {
      //设置高度
      el.style.height = 3200 + "px";
      el.style.overflow = "hidden";

      el.style.position = "relative";
      Masker3.methods.handleViewMoreClick = () => {
        binding.value.onClick();
      };
      var MyComponent = Vue.extend(Masker3);

      const component = new MyComponent().$mount();
      el.appendChild(component.$el);
    }
  }
});
