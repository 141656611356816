import HeaderAsideLayout from "@/layouts/HeaderAsideLayout";
import NotFound from "@/pages/NotFound";
import Dashboard from "@/pages/Dashboard";
import homePage from "@/pages/index/index";
import MyTest from "@/pages/Test/MyTest";
import TTest from "@/pages/TTest/TTest";

import exHomePage from "@/pages/index/exHomePage";

import TestLog from "@/pages/Test/TestLog";

import ResultEditor from "@/pages/ResultEditor/ResultEditor";
import SimpleResultEditor from "@/pages/SimpleResultEditor/SimpleResultEditor";

import ChooseTable from "@/pages/Test/ChooseTable";
import Invoice from "@/pages/Test/Invoice";
import InvoiceUpload from "@/pages/Test/invoiceUpload/invoiceUpload";

const UploadTable = () => import("@/pages/Test/UploadTable");
import ManualTableList from "@/pages/Test/ManualTableList";

const ManualFill = () => import("@/pages/Test/ManualFill");

//登录注册
import Login from "@/pages/login/Login";
import loginBase from "@/pages/login/components/LoginBase";
import registerBase from "@/pages/login/components/registerBase";
import wxlogin from "@/pages/login/components/wxlogin";
import perfectInfo from "@/pages/login/components/perfectInfo";

//检测报告
import TestReports from "@/pages/Test/TestReports/testReports";
//用户设置
import UserSet from "@/pages/user/SetUser";
//企业认证
import companyApply from "@/pages/companyApply/companyApply";
// 小工具
import abnormal_tax from "@/pages/util/abnormal_tax";
import calculator_tax from "@/pages/util/calculator_tax";
import individual_tax from "@/pages/util/individual_tax";
import abnormal_msg from "@/pages/util/normalmsg";

import account from "@/pages/account/accountList";
import role from "@/pages/account/role";

//消息中心
import message from "@/pages/message/message";
//帮助中心
import helpCenter from "@/pages/help/help";

//帮助中心
import helpAndCenter from "@/pages/helpCenter/index";

//忘记密码
import forgetPassword from "@/pages/login/components/forgetPassword";

// 产品中心
import productsDiscount from "@/pages/product/discount";
import productsOrder from "@/pages/product/record";
import productsGoods from "@/pages/product/goods";
import productsSubmit from "@/pages/product/submit";
import productsPay from "@/pages/product/pay";
import productsInfo from "@/pages/product/info";
import productsAgent from "@/pages/product/agent";
import productsCustom from "@/pages/custom";
import productsProject from "@/pages/project";
import statistics from "@/pages/staticData";
import indexPage from "@/pages/index/index";
import configLogo from "@/pages/logoSet";
import configRecord from "@/pages/ournal";
// /config/record

// 客户管理
import consumerDistribution from "@/pages/custom/distribution/testLog.vue";

// 审核管理
import examine from "@/pages/examine";

//申报表

import Balance from "@/pages/Test/UploadTable/Balance";


// 流程补救
import invoiceFix from "@/pages/fixProcess/invoiceFix";
import UploadTableFix from "@/pages/Test/UploadTable/UploadTableFix";

//demo
//import demoRoutes from "./demoRoutes";

//第三方登录
import thirdPartAuth from "@/pages/3rdPartAuth";
import thirdPartRegister from "@/pages/3rdPartAuth/3rdPartRegister";

// 指标管理
import editorTarget from "@/pages/editorTarget/editorTargetList";
import editorDetail from "@/pages/editorTarget/editorDetail";
import addEditorDetail from "@/pages/editorTarget/addTargetPage";

// 目录管理
import menuList from "@/pages/menuList/menuList";
// 元数据管理
import metadataList from "@/pages/metadata/metadataList";
import metadataAdd from "@/pages/metadata/metadataAdd";
import metadataDetail from "@/pages/metadata/metadataDetail";
// 模型分类
import modelClass from "@/pages/modelClass/modelClass";

// pdf结果编辑器详情
import ResultDetail from "@/pages/SimpleResultEditor/components/ResultDetail";

// 小工具
import toolsAudit from "@/pages/tools/audit";
import authorization from "@/pages/tools/authorization";
import analysisResult from "@/pages/tools/result"
import analysisTable from "@/pages/tools/analysisTable"
import analysisSuccess from "@/pages/tools/success"
import businessCirclesQuery from "@/pages/tools/businessCirclesQuery"
import fuzzyQuery from "@/pages/tools/fuzzyQuery"
import changeRecord from "@/pages/tools/changeRecord"





// 小工具 发票查询
import billList from "@/pages/tools/bill/list";
import billDetail from "@/pages/tools/bill/detail";
import billGetData from "@/pages/tools/bill/uploadBill";
import billSuccess from "@/pages/tools/bill/billSuccess";

// 
import detailReportBill from "@/pages/tools/bill/report-bill";

const ManualFillBill = () => import("@/pages/Result/ThreeYearResult/bill/ManualFill/ManualFill");

// 房地产项目
import fdcProject from "@/pages/fdc/project-fdc";
import fdcAccount from "@/pages/fdc/account";
//
import fdcTestLog from "@/pages/fdc/ChooseTable/log.vue";
import fdcChooseTable from "@/pages/fdc/ChooseTable/ChooseTable.vue";
import fdcuploadTable from "@/pages/fdc/UploadTable/UploadTable.vue"
import fdcIndex from "@/pages/fdc/project-fdc/index/index";
// 房地产项目


// 报告入口进入的发票
import reportBill from "@/pages/Test/TestLog/reportInvoice";

// 生成出错
import noData from "@/pages/Result/noData";

// 
import yanshi from "@/pages/yanshi/index.vue";
// 

const routerConfig = [
  //  ...demoRoutes,
  {
    path: "/",
    component: HeaderAsideLayout,
    children: [{
      path: "/",
      component: homePage,
      name: "homePage"
    }]
  },

  {
    path: "/fdcIndex",
    component: fdcIndex,
    name: "fdcIndex"
  },
  {
    path: "/jinzhiReport/:programId",
    component: () =>
      import("@/pages/Result/GongshangquanjingReport/JinzhiReport"),
    name: "JinzhiReport",
    hidden: true,
  },
  {
    path: "/tools/changeRecord/:programId",
    component: changeRecord,
    name: "changeRecord"
  },
  {
    path: "/exHomePage",
    component: exHomePage,
    name: "exHomePage"
  },
  {
    path: "/passport",
    component: Login,
    name: "",
    hidden: true,
    children: [{
      path: "login",
      component: loginBase,
      name: "login"
    },
    {
      path: "register",
      component: registerBase,
      name: "register"
    },
    {
      path: "wxlogin",
      component: wxlogin,
      name: "wxlogin"
    },
    {
      path: "perfectInfo",
      component: perfectInfo,
      name: "perfectInfo"
    },
    {
      path: "forgetPassword",
      component: forgetPassword,
      name: "forgetPassword"
    }
    ]
  },
  {
    path: "/table",
    component: HeaderAsideLayout,
    children: [{
      path: "/table/basic",
      component: NotFound
    },
    {
      path: "/table/fixed",
      component: NotFound
    }
    ]
  },
  {
    path: "/admin/view-uploaded-tables/:bankId/:version/:nsrsbm/:zq/:uuid/:dataPeriod",
    name: "AdminUploadedTables",
    component: () => import('@/pages/admin/AdminUploadedTables'),
    props: true
  },
  {
    path: "/admin/test/product/:uuid/:productID/:bankId/:productName/manual-list/:zq/:zqLocation/fill/:templateID/:from",
    name: "admin-manual-fill-table",
    component: ManualFill,
    props: true
  },
  {
    path: "/test",
    component: HeaderAsideLayout,
    children: [{
        path: "/test/products",
        component: MyTest,
        name: "test-hub"
      },
      {
        path: "/test/chooseTable",
        name: "choose-table",
        component: ChooseTable,
        props: true
      },
      {
        path: "/test/product",
        name: "upload-table",
        component: UploadTable,
        props: true
      },
      {
        path: "/test/product/:uuid/:productID/:bankId/:productName/manual-list/:zq/:zqLocation",
        name: "upload-table-manual",
        component: ManualTableList,
        props: true
      },
      {
        path: "/test/product/:uuid/:productID/:bankId/:productName/manual-list/:zq/:zqLocation/fill/:templateID/:from",
        name: "manual-fill-table",
        component: ManualFill,
        props: true
      },
      {
        path: "/test/Balance/:uuid/:productID/:bankId/:productName/upload-list/:zq/:zqLocation/:dataPeriod/:selectvalueIndex/:version/:user/:userName?/:nsrsbhIndex?/:qykjzdIndex?",
        component: Balance,
        name: "test-Balance",
        props: true
      },
      {
        path: "/test/invoice/:uuid/:productID/:bankId/:productName/upload-list/:zq/:zqLocation/:dataPeriod/:selectvalueIndex/:version/:user/:userName?/:nsrsbhIndex?/:qykjzdIndex?",
        component: Invoice,
        name: "test-invoice",
        props: true
      },
      {
        path: "/test/InvoiceUpload/:uuid",
        component: InvoiceUpload,
        name: "test-InvoiceUpload",
        props: true
      },
      {
        path: "/test/test-log",
        component: TestLog,
        name: "test-log"
      },
      {
        path: "/test/TestReports",
        component: TestReports
      },
      {
        path: "/test/test-reportBill",
        component: reportBill,
        name: "reportBill"
      }
    ]
  },
  // 房地产项目检测
  {
    path: "/test",
    component: HeaderAsideLayout,
    children: [
      {
        path: "/test/product-fdc/:uuid/:productID/:bankId/:productName/:zqLocation?",
        name: "choose-table-fdc",
        component: fdcChooseTable,
        props: true
      },
      {
        path: "/test/product-fdc/:uuid/:productID/:bankId/:productName/upload-list/:zq/:zqLocation/:dataPeriod/:selectvalueIndex/:version/:user/:nsrsbhIndex?/:qykjzdIndex?",
        name: "upload-table-fdc",
        component: fdcuploadTable,
        props: true
      },
      {
        path: "/test/product-fdc/:uuid/:productID/:bankId/:productName/manual-list/:zq/:zqLocation",
        name: "upload-table-manual",
        component: ManualTableList,
        props: true
      },
      {
        path: "/test/product-fdc/:uuid/:productID/:bankId/:productName/manual-list/:zq/:zqLocation/fill/:templateID/:from",
        name: "manual-fill-table",
        component: ManualFill,
        props: true
      },
      {
        path: "/test/InvoiceUpload-fdc/:uuid/:nsrsbh",
        component: InvoiceUpload,
        name: "test-InvoiceUpload",
        props: true
      },
      {
        path: "/test/test-log-fdc",
        component: fdcTestLog,
        name: "test-log-fdc"
      },
      {
        path: "/test/test-noData",
        component: noData,
        name: "test-noData"
      }
    ]
  },
  // 房地产项目检测
  {
    path: "/setUser",
    component: HeaderAsideLayout,
    children: [{
      path: "/setUser",
      component: UserSet,
      name: "setUser"
    }]
  },
  //企业认证
  {
    path: "/companyApply",
    component: HeaderAsideLayout,
    children: [{
      path: "/companyApply",
      component: companyApply,
      name: "companyApply"
    }]
  },
  //小工具
  //企业认证
  {
    path: "/account",
    component: HeaderAsideLayout,
    children: [{
      path: "/account/account",
      component: account,
      name: "account"
    },
    {
      path: "/account/role",
      component: role,
      name: "role"
    }
    ]
  },
  {
    path: "/detailReportBill",
    component: detailReportBill,
    name: "detailReportBill"
  },
  // 小工具
  {
    path: "/tools",
    component: HeaderAsideLayout,
    children: [{
      path: "/tools/billList",
      component: billList,
      name: "billList"
    },
    {
      path: "/tools/billDetail",
      component: billDetail,
      name: "billDetail"
    },
    {
      path: "/tools/billGetData",
      component: billGetData,
      name: "billGetData"
    },
    {
      path: "/tools/billSuccess",
      component: billSuccess,
      name: "billSuccess"
    },
    {
      path: "/tools/toolsAudit",
      component: toolsAudit,
      name: "toolsAudit"
    },
    {
      path: "/tools/authorization",
      component: authorization,
      name: "authorization"
    },
    {
      path: "/tools/analysisResult",
      component: analysisResult,
      name: "analysisResult"
    },
    {
      path: "/tools/analysisTable",
      component: analysisTable,
      name: "analysisTable"
    },
    {
      path: "/tools/analysisSuccess",
      component: analysisSuccess,
      name: "analysisSuccess"
    },
    /*  */
    {
      path: "/tools/businessCirclesQuery",
      component: businessCirclesQuery,
      name: "businessCirclesQuery"
    },
    {
      path: "/tools/fuzzyQuery/:dataPeriod",
      component: fuzzyQuery,
      name: "fuzzyQuery"
    },
    /*  */
    {
      path: "/tools/view-uploaded-tables/:bankId/:version/:nsrsbm/:zq/:uuid/:dataPeriod",
      name: "toolsTables",
      component: () => import('@/pages/tools/components/table'),
      props: true
    },
    {
      path: "/tools/stampTax/basicInfo",
      component: () => import("@/pages/tools/stampTax/BasicInfo"),
      name: "stampTax-basicInfo"
    },
    {
      path: "/tools/stampTax/heTongTaiZhang/:programId",
      component: () => import("@/pages/tools/stampTax/HeTongTaiZhang"),
      props: true,
      name: "stampTax-heTongTaiZhang"
    },
    {
      path: "/tools/stampTax/yinHuashui/:programId",
      component: () => import("@/pages/tools/stampTax/YinHuashui"),
      props: true,
      name: "stampTax-yinHuashui"
    },
    {
      path: "/tools/stampTax/stampTaxDone/:programId",
      component: () => import("@/pages/tools/stampTax/StampTaxDone"),
      props: true,
      name: "stampTax-done"
    },
    {
      path: "/tools/stampTax/yinHuaShuiList",
      component: () => import("@/pages/tools/stampTax/YinHuaShuiList"),
      name: "stampTax-list"
    },
    {
      path: "/tools/stampTax/yinHuaShuiResult/:programId",
      component: () => import("@/pages/tools/stampTax/YinHuaShuiResult"),
      name: "stampTax-result",
      props: true
    },
    {
      path: "/tools/stampTax/ViewYinhuashuiTable/:templateID/:zq/:programId",
      component: () => import("@/pages/tools/stampTax/ViewYinhuashuiTable"),
      name: "ViewYinhuashuiTable",
      props: true
    },
    ]
  },
  //消息中心
  {
    path: "/message",
    component: HeaderAsideLayout,
    children: [{
      path: "/message/center",
      component: message
    }]
  },
  //帮助中心
  {
    path: "/helpCenter",
    component: helpCenter
  },

  {
    path: "/",
    component: HeaderAsideLayout,
    children: [{
      path: "/dashboard/analysis",
      component: Dashboard
    },
    {
      path: "/dashboard/monitor",
      component: NotFound
    },
    {
      path: "/dashboard/workplace",
      component: NotFound
    }
    ]
  },
  //产品中心
  {
    path: "/",
    component: HeaderAsideLayout,
    children: [{
      path: "/product/discount",
      name: "discount",
      component: productsDiscount
    },

    {
      path: "/product/record",
      name: "order",
      component: productsOrder
    },
    {
      path: "/product/goods",
      name: "goods",
      component: productsGoods
    },
    {
      path: "/product/submit",
      name: "submit",
      component: productsSubmit
    },
    {
      path: "/product/pay",
      name: "pay",
      component: productsPay
    },
    {
      path: "/product/info",
      name: "info",
      component: productsInfo
    },
    {
      path: "/product/agent",
      name: "agent",
      component: productsAgent
    },
    {
      path: "/product/custom",
      name: "custom",
      component: productsCustom
    },
    {
      path: "/custom/distribution",
      name: "customDistribution",
      component: consumerDistribution
    },
    {
      path: "/product/project",
      name: "project",
      component: productsProject
    },
    {
      path: "/product/statistics",
      name: "statistics",
      component: statistics
    },
    {
      path: "/index/index",
      name: "indexPage",
      component: indexPage
    },
    {
      path: "/config/logo",
      name: "configLogo",
      component: configLogo
    },
    {
      path: "/config/record",
      name: "configRecord",
      component: configRecord
    },
    {
      path: "/helpAndCenter/index",
      name: "helpAndCenter",
      component: helpAndCenter
    },
    ]
  },

  // 房地产项目
  {
    path: "/",
    component: HeaderAsideLayout,
    children: [{
      path: "/product-fdc/product",
      name: "fdcProject",
      component: fdcProject
    },
    {
      path: "/product-fdc/account",
      name: "fdcAccount",
      component: fdcAccount
    }
    ]
  },

  // 审核管理
  {
    path: "/",
    component: HeaderAsideLayout,
    children: [{
      path: "/examine/examine",
      name: "examine",
      component: examine
    }]
  },

  // 指标管理
  {
    path: "/",
    component: HeaderAsideLayout,
    children: [{
      path: "/editor/target",
      name: "editorTarget",
      component: editorTarget,
      meta: {
        keepAlive: true,
        isBack: false
      }
    },
    {
      path: "/editor/targetDetail",
      name: "editorDetail",
      component: editorDetail
    },
    {
      path: "/editor/addTargetDetail",
      name: "addEditorDetail",
      component: addEditorDetail
    }
    ]
  },

  // 目录管理
  {
    path: "/",
    component: HeaderAsideLayout,
    children: [{
      path: "/editor/menuList",
      name: "menuList",
      component: menuList
    }]
  },

  // 元数据管理
  {
    path: "/",
    component: HeaderAsideLayout,
    children: [{
      path: "/editor/menuData",
      name: "metadataList",
      component: metadataList
    },
    {
      path: "/editor/menuDataAdd",
      name: "metadataAdd",
      component: metadataAdd
    },
    {
      path: "/editor/menuDataDetail",
      name: "metadataDetail",
      component: metadataDetail
    }
    ]
  },

  {
    path: "/",
    component: HeaderAsideLayout,
    children: [{
      path: "/editor/modelClass",
      name: "modelClass",
      component: modelClass
    }]
  },

  // 补救流程invoiceFix
  {
    path: "/",
    component: HeaderAsideLayout,
    children: [{
      path: "/fix/invoiceFix",
      name: "invoiceFix",
      component: invoiceFix
    },
    {
      path: "/fix/UploadTableFix/:uuid/:productID/:bankId/:productName/upload-list/:zq/:zqLocation/:dataPeriod/:selectvalueIndex/:version/:user/:nsrsbhIndex?/:qykjzdIndex?/:index/:taxName",
      name: "UploadTableFix",
      component: UploadTableFix,
      props: true
    }
    ]
  },

  {
    path: "/product/discount",
    component: productsDiscount
  },
  //第三方登录用的页面
  {
    path: "/3rdPartAuth/:mobile",
    name: "thirdPartAuth",
    component: thirdPartAuth
  },
  //第三方首次登录用的页面
  {
    path: "/thirdpartregister",
    component: Login,
    name: "",
    hidden: true,
    children: [{
      path: "/thirdpartregister",
      component: Login,
      name: "",
      hidden: true,
      children: [{
        path: "/thirdpartregister",
        name: "thirdPartRegister",
        component: thirdPartRegister
      }]
    }]
  },
  {
    path: "/admin/:action/:uuid/:nsrsbh",
    component: () => import("@/pages/ResultEditor/Always"),
    props: true,
    children: [{
      path: "result-head",
      component: () => import("@/pages/ResultEditor/ResultHead"),
      name: "admin-result-head",
      props: true
    },
    {
      path: "result-head-middle-page/:type",
      component: () => import("@/pages/ResultEditor/ResultHeadMiddlePage"),
      name: "admin-result-head-middle-page",
      props: true
    },
    //pdf结果编辑器
    {
      path: "resultEditor",
      component: ResultEditor,
      name: "admin-ResultEditor",
      hidden: true
    },
    ]
  },
  {
    path: "/manual-fill-table-bill",
    name: "manual-fill-table-bill",
    component: ManualFillBill,
    props: true
  },
  {
    path: "/always/:action/:uuid/:nsrsbh?",
    component: () => import("@/pages/ResultEditor/Always"),
    props: true,
    children: [{
      path: "detect",
      component: () => import("@/pages/Result/Detect"),
      name: "result-detect-always",
      hidden: true,
    },
    {
      path: "ThreeYearResult",
      component: () => import("@/pages/Result/ThreeYearResult/ThreeYearResult"),
      name: "ThreeYearResult-always",
      hidden: true,
    }, {
      path: "result-head",
      component: () => import("@/pages/ResultEditor/ResultHead"),
      name: "result-head-always",
      props: true
    },
    {
      path: "result-head-middle-page/:type",
      component: () => import("@/pages/ResultEditor/ResultHeadMiddlePage"),
      name: "always-result-head-middle-page",
      props: true
    },
    //pdf结果编辑器
    {
      path: "resultEditor",
      component: ResultEditor,
      name: "ResultEditor",
      hidden: true
    },
    ]
  },

  //简版pdf结果编辑器
  {
    path: "/simpleResultEditor/:action/:uuid/:nsrsbh",
    component: SimpleResultEditor,
    name: "SimpleResultEditor",
    hidden: true
  },
  //pdf结果编辑器详情
  {
    path: "/resultDetail/:nsrsbh/:uuid/:action/:type/:contrastKey/:indexes",
    component: ResultDetail,
    name: "resultDetail",
    hidden: true,
    props: true
  },


  //指标编辑器
  // {
  //   path: "/index-editor",
  //   component: HeaderAsideLayout,
  //   name: "index-edit",
  //   meta: { title: "指标编辑器", icon: "el-icon-s-help" },
  //   children: [
  //     // {
  //     //   path: 'metaData',
  //     //   name: 'metaData',
  //     //   component: () => import('@/pages/metaData/MetaData'),
  //     //   meta: { title: '元数据', icon: 'table' }
  //     // },
  //     {
  //       path: "/index-editor/modelEdit/:modelId?",
  //       name: "modelEdit",
  //       component: () => import("@/pages/modelEdit/ModelEdit"),
  //       meta: { title: "模型编辑器", icon: "tree" },
  //       props: true
  //     },
  //     {
  //       path: "/index-editor/modelList",
  //       name: "modelList",
  //       component: () => import("@/pages/modelEdit/ModelList"),
  //       meta: { title: "模型编辑器", icon: "tree" }
  //     }
  //   ]
  // },
  {
    path: "/wordResultFrontGenerator/:from?/:uuid/:nsrsbh?",
    component: () =>
      import ("@/pages/pdfFrontGenerator/wordResult"),
    name: "wordResult",
    hidden: true
  },

  {
    path: "*",
    component: NotFound
  },
  {
    path: "/yanshi",
    component: yanshi
  }
];

export default routerConfig;
