<template>
  <div class="container">
    <div class="titleC">
      <div class="title">声明配置</div>
      <div class="title-btn ease" @click="editlogo">保存</div>
    </div>
    <div v-if="listData">
      <div class="set-title">报告声明：</div>
      <div class="set-flex">
        <el-input
          type="textarea"
          placeholder="请输入"
          v-model="listData.statement"
          maxlength="750"
          show-word-limit
          rows="8"
        ></el-input>
      </div>
      <div class="set-title">咨询热线：</div>
      <div class="set-flex set-flex-m">
        <el-input
          type="text"
          placeholder="请输入"
          v-model="listData.helpline"
          maxlength="20"
          show-word-limit
        ></el-input>
      </div>
      <div class="set-title">声明人：</div>
      <div class="set-flex set-flex-m">
        <el-input
          type="text"
          placeholder="请输入"
          v-model="listData.assertor"
          maxlength="50"
          show-word-limit
        ></el-input>
      </div>
      <div class="set-title">公章位置：</div>
      <div class="set-flex set-flex-m">
        <span
          ><el-radio v-model="listData.officialSealImageLocation" label="1"
            >底部</el-radio
          >
          <el-radio v-model="listData.officialSealImageLocation" label="2"
            >声明人右侧</el-radio
          ></span
        >
      </div>
    </div>

    <seal :listData="this.listData" @coverLogo="coverLogo"></seal>
    <div class="reset-tip">
      温馨提示：配置完成后，新的检测报告将按配置要求展示，配置前的检测报告维持原样。
    </div>
  </div>
</template>

<script>
import seal from "./seal";
import { editLogo, uploadToOss, getLogo } from "@/api/api";
export default {
  components: {
    seal,
  },
  data() {
    return {
      textarea: "",
      phone: "",
      email: "",
      person: "",
      listData: [],
      logoState: "",
      officialSealImageLocation: "1",
    };
  },
  created() {
    this.getlogo();
  },

  methods: {
    coverLogo(val) {
      this.listData.officialSealImage = val;
    },

    getlogo() {
      getLogo(localStorage.getItem("authorization")).then((res) => {
        this.listData = res.data;
        this.listData.officialSealImageLocation = String(
          res.data.officialSealImageLocation
        );
        if (res.data) {
          this.logoState = res.data.officialSealImage;
        }

        if (res.data == null) {
          this.listData = [];
        }
      });
    },

    editlogo() {
      let params = {
        id: this.listData.id,
        officialSealImage: this.listData.officialSealImage,
        statement: this.listData.statement,
        helpline: this.listData.helpline,
        email: this.listData.email,
        assertor: this.listData.assertor,
        officialSealImageLocation: this.listData.officialSealImageLocation,
      };
      editLogo(params, localStorage.getItem("authorization")).then((res) => {
        if (res.msgCode == 1) {
          this.$notify({
            title: "温馨提示",
            message: "保存声明配置成功！",
            type: "success",
          });
          this.getlogo();
        } else {
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
        }
      });
    },

    upload() {},
  },
};
</script>

<style scoped lang="stylus">
.container {
  position: relative;
  // height: 708px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  // width: 504px;
  float: left;
  margin-top: 16px;

  .reset-tip {
    position: absolute;
    left: 20px;
    bottom: 20px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.set-flex {
  display: flex;
  margin-bottom: 12px;
  margin-left: 24px;
}

.set-flex-m {
  align-items: center;
}

.set-flex-b {
  margin-bottom: 24px;
}

.set-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin-left: 24px;
  margin-right: 8px;
  margin-bottom: 4px;
}

.title {
}

.titleC {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  line-height: 54px;
  padding-left: 24px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  margin-bottom: 16px;
}

.title-btn {
  width: 76px;
  height: 32px;
  line-height: 32px;
  background: rgba(47, 84, 235, 1);
  border-radius: 3px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  margin-right: 24px;
}

.title-btn:hover {
  background: rgba(47, 64, 235, 1);
}

.ease {
  transition: all 0.3s;
}

>>> .el-textarea {
  width: calc(100% - 24px);
}

>>> .el-input {
  width: calc(100% - 24px);
}

>>> .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.logo-title {
  font-size: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  margin: 24px 0 24px 30px;
}

.logo-top {
  height: 243px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 30px;
}

.logo-topTitle {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 25px;
}

.logo-upload-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px dashed rgba(217, 217, 217, 1);
  margin-right: 24px;
  cursor: pointer;
}

.logo-upload-flex {
  display: flex;
}

.logo-upload-white {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin: 0 24px;
  margin-left: 14px;
  width: 80px;
  text-align: right;
  margin-right: 8px;
}

.el-icon-plus {
  color: rgba(0, 0, 0, 0.26);
}

.logo-upload-boxImg img {
  width: 100%;
}
</style>
