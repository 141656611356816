<template>
  <div>
    <div class="z-header">
      <el-breadcrumb separator="/" class="z-breadcrumb">
        <el-breadcrumb-item>指标模型</el-breadcrumb-item>
        <el-breadcrumb-item>指标管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="z-title">指标管理</div>
    </div>
    <div class="z-filters">
      <div class="z-filter">
        <div class="z-inputItem">
          <div>名称：</div>
          <el-input
            v-model.trim="name"
            clearable
            placeholder="请输入"
            @input="changeName"
            @keyup.enter.native="reset"
          ></el-input>
        </div>
        <div class="z-inputItem">
          <div class="statusWidth">添加人：</div>
          <el-input
            v-model.trim="person"
            clearable
            placeholder="请输入"
            @input="changePerson"
            @keyup.enter.native="reset"
          ></el-input>
        </div>
        <!-- <div class="z-inputItem metaType">
          <div>类型：</div>
          <el-select
            v-model="metaType"
            placeholder="请选择"
            clearable
            @clear="reset"
          >
            <el-option
              v-for="item in metaTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
        <div class="z-inputItem">
          <div>创建时间：</div>
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="reset"
          >
          </el-date-picker>
        </div>
        <div class="z-inputItem z-inputItems">
          <div>目录：</div>
          <el-cascader
            :options="homeMenu"
            :props="{ checkStrictly: true, expandTrigger: 'hover' }"
            clearable
            v-model="menu"
            @change="reset"
            ref="refHandle"
          ></el-cascader>
        </div>
        <div class="z-inputItem metaType">
          <div class="statusWidth">状态：</div>
          <el-select
            v-model="metaStatusName"
            placeholder="请选择"
            clearable
            @change="reset"
          >
            <el-option
              v-for="item in metaStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="z-search">
        <div class="z-searchBtn ease" @click="reset">搜索</div>
      </div>
    </div>
    <div class="z-addItem ease" @click="addTarget">添加指标</div>
    <div class="z-table">
      <el-table :data="tableDatas" style="width: 100%">
        <el-table-column width="14"> </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          min-width="14%"
          sortable
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="metaName"
          label="名称"
          min-width="20%"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="catalogName"
          label="目录"
          min-width="12%"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column label="类型" min-width="12%">
          <template slot-scope="scope">
            <div>{{ typeData[scope.row.dataType] }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="添加人" min-width="12%">
        </el-table-column>
        <el-table-column prop="name" label="状态" min-width="12%">
          <template slot-scope="scope">
            <div>{{ metaStatus[scope.row.status] }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="70">
          <template slot-scope="scope">
            <div class="z-table-show" @click="toDetail(scope.row.metaId)">
              查看
            </div>
          </template>
        </el-table-column>
        <el-table-column width="14"> </el-table-column>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageno"
        layout="total, prev, pager, next, jumper"
        :total="totalPages"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import addTarget from "./addTarget";
import { getTargetList, getMenu } from "@/api/editor";
import { formatDateTes } from "@/pages/metadata/help.js";
import {
  typeList,
  typeData,
  metaTypeList,
  metaStatusList,
  transformation,
} from "@/utils/constant";

export default {
  components: {
    addTarget,
  },
  data() {
    return {
      dialogTableVisible: false,
      name: "",
      person: "",
      time: "",
      menu: "",
      metaStatusName: "",
      metaType: "",
      dataType: "PF.YSJ",
      typeList: null,
      typeData: typeList,
      metaTypeList: metaTypeList,
      metaStatusList: metaStatusList,
      tableDatas: null,
      homeMenu: null,
      totalPages: 100,
      pageno: 1,
      metaStatus: ["草稿", "已保存", "已发布"],
    };
  },
  mounted() {
    this.getList();
    this.getPageMenu();
  },
  methods: {
    // 获取列表
    getList() {
      let params = {
        catalogId: this.menu[this.menu.length - 1],
        createUserName: this.person,
        endCreateTime: this.time ? this.time[1] : "",
        metaName: this.name,
        pageNo: this.pageno,
        pageSize: 10,
        startCreateTime: this.time ? this.time[0] : "",
        dataType: this.metaType,
        status: this.metaStatusName,
      };
      getTargetList(params).then((res) => {
        if (res.code == 200) {
          this.tableDatas = res.list;
          this.totalPages = res.pageTotal;
          for (let i = 0; i < this.tableDatas.length; i++) {
            this.tableDatas[i].createTime = formatDateTes(
              this.tableDatas[i].createTime
            );
          }
        }
      });
    },
    // 获取指标三级目录
    getPageMenu() {
      let params = {
        catalogType: "PF.ZB",
        catalogSelectUse: false,
      };
      getMenu(params).then((res) => {
        if (res.msgCode == 1) {
          this.homeMenu = res.data;
          if (!this.homeMenu || this.homeMenu.length < 1) return;
          this.homeMenu = transformation(this.homeMenu);
        }
      });
    },
    toDetail(id) {
      this.$router.push({
        path: "/editor/targetDetail",
        query: {
          id: id,
        },
      });
    },
    addTarget() {
      this.$router.push({ path: "/editor/addTargetDetail" });
    },
    reset() {
      this.pageno = 1;
      this.getList();
    },
    changePerson() {
      if (!this.person) {
        this.reset();
      }
    },
    changeName() {
      if (!this.name) {
        this.reset();
      }
    },
    handleCurrentChange(val) {
      this.pageno = val;
      this.getList();
    },
  },
  watch: {
    menu() {
      if (this.$refs.refHandle) {
        this.$refs.refHandle.dropDownVisible = false; //监听值发生变化就关闭它
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.path == "/editor/targetDetail") {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },
  activated() {
    if (!this.$route.meta.isBack) {
      this.menu = "";
      this.person = "";
      this.time = "";
      this.name = "";
      this.pageno = 1;
      this.metaType = "";
      this.metaStatusName = "";
      this.reset();
    }
    this.getList();
    this.$route.meta.isBack = false;
  },
};
</script>

<style scoped lang="stylus">
>>>.z-filters .el-input__inner {
  width: 240px;
  height: 36px;
  line-height: 36px;
}

>>>.z-filters .el-input {
  width: 240px;
  height: 36px;
  line-height: 36px;
}

>>>.z-filters .el-input__icon {
  line-height: 30px;
}

.z-inputItems >>>.el-input__icon {
  line-height: 36px;
}

>>>.z-filters .el-date-editor .el-range-separator {
  line-height: 30px;
}

>>>.metaType .el-input__icon {
  line-height: 36px;
}

>>>.el-date-editor .el-range__close-icon {
  width: 10px;
}

>>>.el-cascader {
  line-height: 36px;
}
</style>