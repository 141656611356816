import axios from "@/axios";

let editor = process.env.VUE_APP_API_ROOT_INDEX; //编辑器

// 获取分类列表
export const getClassList = (params) => {
  return axios
    .post(`${editor}/dcmp/class/findClassList`, params)
    .then(res => res.data);
};

// 新增分类
export const addPfClass = (params) => {
  return axios
    .post(`${editor}/dcmp/class/addPfClass`, params)
    .then(res => res.data);
};

//删除单个元数据或指标
export const deletePfClass = (params) => {
  return axios
    .get(`${editor}/dcmp/class/deletePfClass?id=${params.id}`)
    .then(res => res.data);
};

// 查看分类
export const findOnePfClass = (params) => {
  return axios
    .get(`${editor}/dcmp/class/findOnePfClass?id=${params.id}`)
    .then(res => res.data);
};

// 修改分类
export const updatePfClass = (params) => {
  return axios
    .post(`${editor}/dcmp/class/updatePfClass`, params)
    .then(res => res.data);
};

// 获取目录列表
export const getMenuList = (params) => {
  return axios
    .post(`${editor}/dcmp/catalog/findPfMetaCatalogList`, params)
    .then(res => res.data);
};

// 获取目录树
export const getMenu = (params) => {
  return axios
    .get(`${editor}/dcmp/catalog/loadTree?catalogType=${params.catalogType}&catalogSelectUse=${params.catalogSelectUse}`)
    .then(res => res.data);
};

// 新增目录
export const addMenuList = (params) => {
  return axios
    .post(`${editor}/dcmp/catalog/addPfMetaCatalog`, params)
    .then(res => res.data);
};

// 查看目录详情
export const getMenuDetail = (params) => {
  return axios
    .get(`${editor}/dcmp/catalog/findPfMetaCatalog?catalogId=${params.catalogId}`)
    .then(res => res.data);
};

// 指标列表
export const getTargetList = (params) => {
  return axios
    .post(`${editor}/dcmp/meta/findIndexList`, params)
    .then(res => res.data);
};

// 指标列表
export const getDataList = (params) => {
  return axios
    .post(`${editor}/dcmp/meta/findPfMetaDataList`, params)
    .then(res => res.data);
};

// 获取公式
export const getFunction = () => {
  return axios
    .get(`${editor}/dcmp/formula/loadFunctionType`)
    .then(res => res.data);
};

// 获取公式详情
export const getFunctionDetail = (params) => {
  return axios
    .get(`${editor}/dcmp/formula/loadFormula?functionType=${params.functionType}`)
    .then(res => res.data);
};

// 查找所有元素
export const getAllMeta = () => {
  return axios
    .get(`${editor}/dcmp/tabExcel/findAllTableExcel`)
    .then(res => res.data);
};

// 通过元素ID查找对应表字段
export const getMetaDetail = (params) => {
  return axios
    .get(`${editor}/dcmp/tabExcel/findTableFields?tableNameEn=${params.tableNameEn}`)
    .then(res => res.data);
};

// 新增元数据
export const addMetaData = (params) => {
  return axios
    .post(`${editor}/dcmp/meta/addPfMetaData`, params)
    .then(res => res.data);
};

//删除单个元数据或指标
export const deleteMeta = (params) => {
  return axios
    .get(`${editor}/dcmp/meta/deletePfMetaData?metaId=${params.metaId}`)
    .then(res => res.data);
};

// 查看元数据详情
export const metaDetail = (params) => {
  return axios
    .get(`${editor}/dcmp/meta/findPfMetaData?metaId=${params.metaId}`)
    .then(res => res.data);
};

// 修改元数据
export const updateMenu = (params) => {
  return axios
    .post(`${editor}/dcmp/meta/updatePfMetaData`, params)
    .then(res => res.data);
};

// 新增指标(保存草稿)
export const addIndex = (params) => {
  return axios
    .post(`${editor}/dcmp/meta/addIndex`, params)
    .then(res => res.data);
};

// 新增指标(发布)
export const addReleaseIndex = (params) => {
  return axios
    .post(`${editor}/dcmp/meta/releaseIndex`, params)
    .then(res => res.data);
};

// 修改指标
export const updateIndex = (params) => {
  return axios
    .post(`${editor}/dcmp/meta/updateIndex`, params)
    .then(res => res.data);
};

// 获取目录下详情
export const loadMeta = (params) => {
  return axios
    .get(`${editor}/dcmp/meta/loadMetaData?catalogId=${params.catalogId}`)
    .then(res => res.data);
};

// 搜索目录下参数
export const searchMenuData = (params) => {
  return axios
    .get(`${editor}/dcmp/meta/loadMetaData?catalogType=${params.catalogType}&metaName=${params.metaName}`)
    .then(res => res.data);
};

// 修改目录详情
export const updateMenuDetail = (params) => {
  return axios
    .post(`${editor}/dcmp/catalog/updatePfMetaCatalog`, params)
    .then(res => res.data);
};

// 查看当前元数据或指标是否被占用
export const findPfMetaDataOccupy = (params) => {
  return axios
    .get(`${editor}/dcmp/meta/findPfMetaDataOccupy?entityType=${params.entityType}&metaId=${params.metaId}`)
    .then(res => res.data);
};

// 删除单个目录
export const deletePfMetaCatalog = (params) => {
  return axios
    .get(`${editor}/dcmp/catalog/deletePfMetaCatalog?catalogId=${params.catalogId}`)
    .then(res => res.data);
};

// 查看当前节点元素构成
export const findRecursionElement = (params) => {
  return axios
    .get(`${editor}/dcmp/meta/findRecursionElement?entityType=${params.entityType}&metaId=${params.nodeEntityCode}`)
    .then(res => res.data);
};