import component from "./alert.vue";
import Vue from "vue";

const Constructor = Vue.extend(component);
let instance = null;

const Saalert = {};

Saalert.install = function(Vue, options) {
  Vue.prototype.$saalert = function() {
    return new Promise((resolve, reject) => {
      instance ||
        (instance = new Constructor({
          el: document.createElement("div"),
          methods: {
            handleCancelClick() {
              this.dialogVisible = false;
              reject();
            },
            handleConfirmClick() {
              this.dialogVisible = false;
              resolve();
            },
            handleClosed(done) {
              done();
              reject();
            }
          }
        }));
      instance.options = options;
      document.body.appendChild(instance.$el);
    });
  };
};

export default Saalert;
